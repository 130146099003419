import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import {
  addFileToSubtaskByDropPromiseCreator,
  ganttWeekViewSelector,
} from 'store/dashboard';
import {
  clearGanttStateAction,
  msInPixelSelector,
  setDropFromProjectAction,
  setMouseOffsetAction,
  setSubtaskNameAction,
  setTaskIdAction,
  subtaskItemIdSelector,
} from 'store/gantt';
// redux
import { location } from 'store/router';
import { validateUploadFile } from 'utils/helpers';
// utils
import { getHelpsData } from 'utils/helpersSubtasks';
// component
import SubtaskItem from 'components/SubtaskItem';
import Preview from 'components/SubtaskViewComponents/Preview';
import { FileLoadingProgress } from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
import { MAX_FILE_SIZE_MB, PROJECT_UPLOAD_FILE_TYPES } from 'lib/constants';
// icons
import { Upload } from 'lib/icons';
import moment from 'moment-timezone';
// styles
import theme from 'styles/theme';
import {
  FileDragHover,
  FileDragHoverInner,
  SubtaskContainer,
  SubtaskWrap,
} from './styles';
import {
  DragTypeEnum,
  IChecklist,
  IProjectResponseDto,
  ISubtaskDto,
  SubtaskStatusesEnum,
} from 'types';

interface ISubtaskProps {
  data?: ISubtaskDto;
  onDoubleClick: (
    _id: string,
    startDate: moment.Moment,
    dueDate: moment.Moment
  ) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  toggleParent?: (visible: boolean) => void;
  color?: string;
  invoice?: string;
  taskName?: string;
  projectId?: string;
  taskId?: string;
  index?: any;
  project?: IProjectResponseDto;
  notAvailableProject?: any;
  setShouldUpdate?: (update: boolean) => void;
  locationId?: string;
  moveSubtasks: (dragIndex: number, hoverIndex: number) => void;
  saveSubtasksOrder: () => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  taskOrder?: any;
  isProjectViewTaskMode?: boolean;
  isProjectEditing?: boolean;
}

const Subtask = ({
  data,
  taskId,
  openCheckResult,
  toggleParent,
  onDoubleClick,
  setShouldUpdate,
  locationId,
  moveSubtasks,
  index,
  saveSubtasksOrder,
  notAvailableProject,
  project,
  updateChecklist,
  isProjectViewTaskMode,
  isProjectEditing,
  ...rest
}: ISubtaskProps) => {
  const msInPixel = useSelector(msInPixelSelector);
  const refOrder = useRef(null);
  const {
    status,
    isApproveRequired,
    resource,
    assignmentDate,
    startDate,
    dueDate,
    generatedApiKey,
    _id,
  } = data;
  const routerLocation = useSelector(location);
  const isWeek = useSelector(ganttWeekViewSelector);

  const setTaskId = useActions(setTaskIdAction);
  const setSubtaskName = useActions(setSubtaskNameAction);
  const setDropFromProject = useActions(setDropFromProjectAction);
  const setMouseOffset = useActions(setMouseOffsetAction);
  const clearGanttState = useActions(clearGanttStateAction);

  const addFileToSubtaskByDrop = useActionsRoutines(
    addFileToSubtaskByDropPromiseCreator
  );

  const needAttention =
    status === SubtaskStatusesEnum.ON_REVIEW && isApproveRequired;
  const assignedDate = moment(assignmentDate).format('L hh:mm a');
  const hasResource = !!resource;

  const highlightSubtaskId = useSelector(subtaskItemIdSelector);

  const [isHighlighted, setIsHighlighted] = useState(false);
  const scrollToRef = useRef(null);

  const [isDragEnter, setIsDragEnter] = useState(false);

  const [dragItemStartId, setDragItemStartId] = useState('');

  const [isAttachmentsLoading, setAttachmentsLoading] = useState(false);

  useEffect(() => {
    if (data._id === highlightSubtaskId) {
      setIsHighlighted(true);
      scrollToRef.current.scrollIntoView();
    }
  }, [data._id, highlightSubtaskId]);

  const { title, width, item, mouseOffset } = getHelpsData(
    rest,
    data,
    isWeek,
    msInPixel
  );

  const subtaskOrderDragType = `subtaskOrder${taskId}`;

  const [{ isDragging }, dragOrder, previewOrder] = useDrag({
    type: subtaskOrderDragType,
    item: () => {
      setIsHighlighted(false);
      setDragItemStartId(_id);
      return { ...item, index };
    },
    canDrag: () => !notAvailableProject,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (dragItemStartId !== _id) {
        saveSubtasksOrder();
      }
    },
  });
  const [, dropOrder] = useDrop({
    accept: subtaskOrderDragType,
    hover(itemD: any, monitor) {
      if (!refOrder.current) {
        return;
      }
      const dragIndex = itemD.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = refOrder.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSubtasks(dragIndex, hoverIndex);
      itemD.index = hoverIndex;
    },
  });

  const isDashboard = routerLocation.pathname === '/dashboard';

  const isStatusAllowedToDrag =
    status === SubtaskStatusesEnum.TO_DO ||
    status === SubtaskStatusesEnum.NOT_READY;

  const canDrag =
    isStatusAllowedToDrag &&
    !generatedApiKey &&
    isDashboard &&
    !notAvailableProject;

  // drag to asignee
  const [, drag, preview] = useDrag({
    type: DragTypeEnum.subtask,
    item: () => {
      // subtask name is needed for tasks/items mode in project to open task accordion
      setSubtaskName(data.name);
      setTaskId(taskId);
      setDropFromProject(true);
      setMouseOffset(mouseOffset);
      setTimeout(() => toggleParent(false), 0);
      const currentTask = project.tasks.find(task => task._id === taskId);
      // add additional data for assignee task from project to gantt
      return {
        ...item,
        taskId,
        invoice: project.invoice,
        taskName: currentTask.name,
        subtaskName: data.name,
      };
    },
    canDrag: () => canDrag,

    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        setDropFromProject(false);
        clearGanttState(true);
      }
      toggleParent(true);
      setTimeout(() => {
        setTaskId(undefined);
        setSubtaskName(undefined);
      });
    },
  });

  dragOrder(dropOrder(refOrder));

  const opacity = isDragging ? 0 : 1;

  const onSelectFiles = useCallback(
    fileList => {
      const { allowedFiles } = validateUploadFile({
        files: fileList,
        allowedTypes: PROJECT_UPLOAD_FILE_TYPES,
        maxFileSize: MAX_FILE_SIZE_MB,
      });
      setIsDragEnter(false);
      if (isAttachmentsLoading) return;

      if (!allowedFiles.length) {
        return;
      }
      setAttachmentsLoading(true);
      const formData = new FormData();
      allowedFiles.forEach(item => formData.append('files', item));
      addFileToSubtaskByDrop({
        formData,
        _id,
        setAttachmentsLoading,
      }).catch(err => console.error(err));
    },
    [_id, addFileToSubtaskByDrop, isAttachmentsLoading]
  );

  const onDragLeave = e => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragEnter(false);
  };

  const onDragEnter = e => {
    if (isDragEnter) return;
    if (e.dataTransfer.types.includes('Files')) {
      setIsDragEnter(true);
    }
  };

  const handleDrop = useCallback(
    acceptedFiles => onSelectFiles(acceptedFiles),
    [onSelectFiles]
  );
  const handleDoubleClick = useCallback(
    e => {
      e.stopPropagation();
      onDoubleClick(_id, moment(startDate), moment(dueDate));
    },
    [onDoubleClick, _id, startDate, dueDate]
  );

  const handleClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <SubtaskContainer
      id="subtask-container"
      ref={scrollToRef}
      style={{ scrollMarginTop: '150px' }}
    >
      <Preview
        color={rest.color}
        width={width}
        title={title}
        ref={preview}
        item={item}
      />
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <SubtaskWrap
            {...getRootProps()}
            onClick={handleClick}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
          >
            {isAttachmentsLoading && (
              <FileLoadingProgress
                strokeColor={{
                  from: theme.colors.mainDarkBlue,
                  to: theme.colors.mainDarkBlue,
                }}
                percent={100}
                status="active"
                showInfo={false}
              />
            )}
            <input
              {...getInputProps()}
              accept={PROJECT_UPLOAD_FILE_TYPES.join(',')}
            />
            <FileDragHover isDragHover={!isAttachmentsLoading && isDragEnter}>
              <FileDragHoverInner>
                <Upload color={theme.colors.white} />
                <div>Drop files to attach</div>
              </FileDragHoverInner>
            </FileDragHover>
            <SubtaskItem
              isProjectViewTaskMode={isProjectViewTaskMode}
              isHighlighted={isHighlighted}
              opacity={opacity}
              previewOrder={previewOrder as any}
              onDoubleClick={handleDoubleClick}
              taskId={taskId}
              needAttention={needAttention}
              drag={drag as any}
              refOrder={refOrder as any}
              setShouldUpdate={setShouldUpdate}
              data={data}
              notAvailableProject={notAvailableProject}
              openCheckResult={openCheckResult}
              locationId={locationId}
              generatedApiKey={generatedApiKey}
              projectId={rest.projectId}
              project={project}
              hasResource={hasResource}
              resource={resource}
              assignedDate={assignedDate}
              updateChecklist={updateChecklist}
              isProjectEditing={isProjectEditing}
            />
          </SubtaskWrap>
        )}
      </Dropzone>
    </SubtaskContainer>
  );
};

export default Subtask;
