import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 24px;
`;

export const DescriptionContainerNumber = styled.span`
  font-weight: 600;
`;

export const ConflictsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Conflict = styled(Description)`
  background: ${({ theme }) => theme.colors.grayLight};
  border-radius: 10px;
  margin-bottom: 0;
  padding: 16px;
`;

export const ConflictProjectName = styled(DescriptionContainerNumber)``;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;
