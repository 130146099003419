import {
  AccountName,
  CaptionWrapper,
  CustomerName,
  InfoFrom,
  Invoice,
  Title,
} from './styles';

interface ICaptionProps {
  invoice: string;
  prefix: string;
  customer: string;
  account: string;
}

const Caption = ({ invoice, prefix, customer, account }: ICaptionProps) => (
  <CaptionWrapper>
    <Title>
      <Invoice>
        {invoice}
        {prefix}
      </Invoice>
      <InfoFrom>
        <AccountName> {account}</AccountName>
        <CustomerName> • {customer}</CustomerName>
      </InfoFrom>
    </Title>
  </CaptionWrapper>
);

export default Caption;
