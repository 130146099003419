import styled, { css } from 'styled-components';

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  .ScrollbarsCustom-Track {
    top: 0 !important;
    width: 4px !important;
    height: calc(100% - 10px) !important;
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -26px;
    right: -26px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const Wrap = styled.div`
  line-height: 1;
  margin-left: 12px;
  margin-right: 12px;

  .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
  }

  .ant-badge-multiple-words {
    padding: 0 4px;
  }

  .ant-badge-count {
    top: 5px;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 160.09%;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const MessageTitle = styled.div`
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const AvatarWrap = styled.div`
  padding-top: 5px;
`;

export const MessageWrapper = styled.div`
  font-weight: 500;
  margin-bottom: 7px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Message = styled.div<{
  $isShowFullMessage: boolean;
}>`
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  ${({ $isShowFullMessage }) =>
    !$isShowFullMessage &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 10px;
`;

export const TaskBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.grayDark};
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 11px;
  min-height: 28px;
`;

export const TaskInvoice = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const TaskName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BottomItems = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.6;
  margin-top: 6px;
  justify-content: space-between;
`;

export const ReplyButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const TimeWrap = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
`;

export const CollapseIconWrapper = styled.div<{
  $isShowText: boolean;
}>`
  cursor: pointer;
  margin: 0 4px 0 5px;
  &:hover svg {
    fill: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  ${({ $isShowText }) =>
    $isShowText &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Content = styled.div`
  width: 295px;
`;

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
`;

export const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
