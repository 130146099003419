export const SortBy = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 16H7V14H1V16ZM1 4V6H19V4H1ZM1 11H13V9H1V11Z"
      fill="#737F94"
    />
  </svg>
);
