import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isProjectCreateModalOpenSelector,
  setCreateProjectModalOpen,
} from 'store/dashboard';
// redux
import { workOrdersPromiseCreator } from 'store/inbound';
// components
import Main from './Main';
import Modals from './Modals';
// utils
import { useActions, useActionsRoutines } from 'hooks';
// styles
import { HeaderStyled } from 'pages/NavBar/styles';
import { InboundModalTypesEnum } from 'types';

interface INavBar {
  isSalesManager: boolean;
}

const NavBar = ({ isSalesManager }: INavBar) => {
  const toggleModal = useActions(setCreateProjectModalOpen);
  const isOpenModal = useSelector(isProjectCreateModalOpenSelector);

  const [isOpenInboundModal, setOpenInboundModal] = useState(false);
  const [isLoadingModal, setLoadingModal] = useState(false);
  const [openInboundType, setOpenInboundType] = useState(null);

  const getWorkOrders = useActionsRoutines(workOrdersPromiseCreator);
  const createProject = useCallback(() => {
    toggleModal(true);
  }, [toggleModal]);

  const openInboundModal = useCallback(() => {
    setOpenInboundModal(true);
    setLoadingModal(true);
    getWorkOrders()
      .then(() => {
        setOpenInboundType(InboundModalTypesEnum.WORK_ORDERS);
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingModal(false));
  }, [setOpenInboundModal, setLoadingModal, getWorkOrders]);

  const closeDraftModal = useCallback(() => {
    toggleModal(false);
    setOpenInboundModal(false);
  }, [toggleModal, setOpenInboundModal]);

  return (
    <HeaderStyled className="header">
      <Modals
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        setOpenInboundModal={setOpenInboundModal}
        isOpenInboundModal={isOpenInboundModal}
        setLoadingModal={setLoadingModal}
        isLoadingModal={isLoadingModal}
        setOpenInboundType={setOpenInboundType}
        openInboundType={openInboundType}
        openInboundModal={openInboundModal}
        closeDraftModal={closeDraftModal}
      />
      <Main
        isSalesManager={isSalesManager}
        openInboundModal={openInboundModal}
        createProject={createProject}
      />
    </HeaderStyled>
  );
};

export default memo(NavBar);
