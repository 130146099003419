import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get sizes
export const sizes = createRoutine('depot/sizes');
export const sizesPromiseCreator = promisifyRoutine(sizes);
// get size
export const getSizeById = createRoutine('depot/getSizeById');
export const getSizeByIdPromiseCreator = promisifyRoutine(getSizeById);
// add sizes
export const addSize = createRoutine('depot/addSize');
export const addSizePromiseCreator = promisifyRoutine(addSize);
// edit size
export const editSize = createRoutine('depot/editSize');
export const editSizePromiseCreator = promisifyRoutine(editSize);
// delete size
export const deleteSize = createRoutine('depot/deleteSize');
export const deleteSizePromiseCreator = promisifyRoutine(deleteSize);
