import moment from 'moment-timezone';
import theme from 'styles/theme';
import { v4 as uuidv4 } from 'uuid';
import {
  IGanttResourceDto,
  ISubtaskDto,
  LogItemDto,
  SubtaskStatusesEnum,
  WorkingTimeDto,
} from 'types';

const defaultWorkingTime = {
  from: { hours: 0, minutes: 0 },
  to: { hours: 0, minutes: 0 },
};

export const defaultGroup = {
  id: 0,
  title: '',
  photoUrl: '',
  needReview: false,
  subtaskTypes: [],
  shift: undefined,
  stackItems: true,
  workingTime: defaultWorkingTime,
};

export const defaultItem = (
  startPeriod: moment.Moment,
  endPeriod: moment.Moment,
  groupId?: string,
  i?: string | number
) => ({
  id: groupId ? `${groupId}${i}` : 0,
  type: 'subtask',
  group: groupId || 0,
  title: '',
  changelog: [],
  start_time: startPeriod.valueOf(),
  end_time: endPeriod.valueOf(),
  isCanMove: false,
  canMove: false,
  canResize: false,
  canChangeGroup: false,
  needReview: false,
  subtaskTypeId: null,
  taskId: null,
  color: theme.colors.white,
  isDefault: true,
});

export const getActualChangelog = (subtask: ISubtaskDto) => {
  let index = 0;
  for (let i = subtask?.changelog?.length - 1; i >= 0; i--) {
    if (
      subtask?.changelog[i].status === SubtaskStatusesEnum.TO_DO &&
      subtask?.changelog[i].isStartPoint
    ) {
      index = i;
      break;
    }
  }

  return subtask?.changelog
    .slice(index + 1, subtask?.changelog?.length)
    .filter(
      item =>
        item?.status &&
        (item?.status !== SubtaskStatusesEnum.TO_DO ||
          item?.isRequestToStart) &&
        item?.status !== SubtaskStatusesEnum.NOT_READY &&
        item?.status !== SubtaskStatusesEnum.FAILED
    );
};

export const getSubtaskPosition = (
  isWeek: boolean,
  subtask: ISubtaskDto,
  startPeriod: moment.Moment,
  endPeriod: moment.Moment,
  workingTime: WorkingTimeDto,
  subtaskDone: boolean,
  changelogLength: number
) => {
  const isDailyView = !isWeek;
  const today = isWeek ? moment().endOf('day') : moment();

  let isCropedRight, isCropedLeft;
  let subtaskStartDate = isWeek
    ? moment(subtask?.startDate).startOf('day')
    : moment(subtask?.startDate);
  let subtaskEndDate = moment(subtask?.dueDate).endOf('day');
  const isInvalidStartDate = subtaskStartDate.isAfter(endPeriod);
  const isStartedBeforePeriod = subtaskStartDate.isBefore(startPeriod, 'day');

  // start
  if (isInvalidStartDate) return null;
  if (isStartedBeforePeriod) subtaskStartDate = moment(startPeriod);

  // end
  if (subtaskDone || isDailyView) {
    subtaskEndDate = moment(subtask?.dueDate);
  }

  // in Progress subtask at the current day
  if (
    subtaskEndDate.isSameOrBefore(today) &&
    !subtaskDone &&
    !!changelogLength
  ) {
    subtaskEndDate = moment(today);
  }

  const isInvalidEndDate = subtaskEndDate.isBefore(startPeriod);

  // is subtask ending on the another day
  if (isInvalidEndDate) return null;
  if (subtaskEndDate.isAfter(endPeriod)) subtaskEndDate = moment(endPeriod);

  const actualChangelog = getActualChangelog(subtask);
  const actualChangelogLastItem = actualChangelog[actualChangelog.length - 1];

  const intervalChangelog = subtask?.changelog?.filter(item =>
    moment(item.createdAt).isBetween(startPeriod, endPeriod, undefined, '[]')
  );

  const intervalChangelogLastItem =
    intervalChangelog[intervalChangelog.length - 1];
  const intervalChangelogProgressItems = intervalChangelog.filter(
    item => item.status === SubtaskStatusesEnum.IN_PROGRESS
  );

  const prevIntervalsChangelog = subtask?.changelog?.filter(item =>
    moment(item.createdAt).isBefore(startPeriod)
  );
  const prevIntervalsChangelogLastItem =
    prevIntervalsChangelog[prevIntervalsChangelog.length - 1];

  if (!isWeek) {
    const workingTimeFrom = workingTime?.from;
    const workingTimeEnd = workingTime?.to;
    const workingDayStart = moment(startPeriod)
      .hours(workingTimeFrom?.hours)
      .minutes(workingTimeFrom?.minutes)
      .seconds(0)
      .milliseconds(0);
    const workingDayEnd = moment(startPeriod)
      .hours(workingTimeEnd?.hours)
      .minutes(workingTimeEnd?.minutes)
      .seconds(0)
      .milliseconds(0);

    if (prevIntervalsChangelogLastItem?.status === SubtaskStatusesEnum.PAUSED) {
      subtaskStartDate =
        intervalChangelogProgressItems?.length &&
        moment(intervalChangelogProgressItems[0]?.createdAt).isBefore(
          workingDayStart
        )
          ? moment(intervalChangelogProgressItems[0]?.createdAt)
          : moment(workingDayStart);

      // is started on the previous day and add cropping on the left
      isCropedLeft = true;
    }

    if (
      intervalChangelogLastItem?.status === SubtaskStatusesEnum.PAUSED &&
      moment(intervalChangelogLastItem.createdAt).isBetween(
        startPeriod,
        endPeriod,
        undefined,
        '[]'
      )
    ) {
      subtaskEndDate = moment(intervalChangelogLastItem.createdAt).isBefore(
        workingDayEnd
      )
        ? moment(workingDayEnd)
        : moment(intervalChangelogLastItem.createdAt);

      // is ended on the next day and add cropping on the right
      isCropedRight = true;
    }
    if (
      ((!intervalChangelog?.length &&
        prevIntervalsChangelogLastItem?.status ===
          SubtaskStatusesEnum.PAUSED) ||
        (actualChangelogLastItem?.status === SubtaskStatusesEnum.PAUSED &&
          moment(actualChangelogLastItem?.createdAt).isBefore(
            intervalChangelogLastItem?.createdAt
          ))) &&
      subtaskEndDate.isAfter(workingDayEnd)
    ) {
      subtaskEndDate = moment(workingDayEnd);
      isCropedRight = true;
    }

    if (
      moment(startPeriod).isSame(today.startOf('day')) &&
      actualChangelogLastItem?.status !== SubtaskStatusesEnum.TO_DO &&
      actualChangelogLastItem?.status !== SubtaskStatusesEnum.NOT_READY &&
      actualChangelogLastItem?.status !== SubtaskStatusesEnum.DONE &&
      actualChangelog?.length
    ) {
      subtaskEndDate = moment();
      isCropedRight = false;
    }
  }

  return { subtaskStartDate, subtaskEndDate, isCropedRight, isCropedLeft };
};

export const getGroup = (item: IGanttResourceDto, needReview: boolean) => ({
  id: item._id,
  title: `${item.firstName || ''} ${item.lastName || ''}`,
  photoUrl: item.photoUrl,
  needReview,
  subtaskTypes: item?.subtaskTypes || [],
  stackItems: true,
  shift: item?.shift,
  forSend: item,
  workingTime: item.workingTime,
  busyDates: item.busyDates,
  group: item?.group,
});

export const getResourceGroup = (group, count: number) => ({
  id: group._id,
  title: group?.name,
  stackItems: true,
  isParent: true,
  order: group?.order,
  root: true,
  count,
  photoUrl: '',
  needReview: false,
  subtaskTypes: [],
  shift: group?.shift,
  workingTime: defaultWorkingTime,
});

export const getGanttSubtask = (
  item,
  subtask,
  changelog,
  canMove,
  subtaskNeedReview,
  start,
  end,
  leftBorder,
  rightBorder
) => {
  const titleSubtask = `${subtask.invoice || ''}${subtask.prefix || ''} • ${
    subtask.taskOrder ? `#${subtask.taskOrder}` : ''
  } ${subtask.taskName || ''} • ${subtask.subtaskName || ''}`;
  const titleStandalone = subtask.subtaskName;
  const title = subtask?.isStandalone ? titleStandalone : titleSubtask;
  const popover = `${title} • ${
    typeof subtask.percent !== 'undefined' ? `${subtask.percent}%` : ''
  } • ${subtask.status || ''}`;

  return {
    id: uuidv4(),
    subtaskId: subtask.subtaskId,
    type: 'subtask',
    group: item._id,
    groupData: item,
    title,
    changelog,
    start_time: moment(start).valueOf(),
    end_time: moment(end).valueOf(),
    subtaskStart: moment(subtask?.startDate).valueOf(),
    subtaskEnd: moment(subtask?.dueDate.valueOf()),
    actualHours: subtask.actualHours || 0,
    plannedHours: subtask.plannedHours,
    templateHours: subtask.templateHours || 0,
    color: subtask.color,
    projectId: subtask.projectId,
    status: subtask.status,
    percent: subtask.percent,
    popover,
    isCanMove: canMove,
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    needReview: subtaskNeedReview,
    subtaskTypeId: subtask.subtaskTypeId,
    taskId: subtask.taskId,
    isStandalone: subtask?.isStandalone,
    leftBorder,
    rightBorder,
    workingTime: item.workingTime,
    hasFiles: !!subtask?.files?.length,
    hasNotes: !!subtask?.notes,
    hasComments: subtask.hasComments,
    hasImages: !!subtask?.images?.length,
    taskName: subtask.taskName,
    subtaskName: subtask.subtaskName,
    invoice: subtask.invoice,
  };
};

export const getSubtaskGanttChangelog = (
  subtaskChangelog: LogItemDto[],
  start: moment.Moment,
  end: moment.Moment
) =>
  subtaskChangelog.reduce((acc, item, index) => {
    const nextItem = subtaskChangelog[index + 1];
    const itemCreated = moment(item.createdAt);
    const nextItemCreated = nextItem ? moment(nextItem.createdAt) : moment(end);

    if (
      (itemCreated.isBefore(moment(start)) &&
        nextItemCreated.isBefore(moment(start))) ||
      (itemCreated.isAfter(moment(end)) &&
        (nextItemCreated.isAfter(moment(end)) || !nextItem)) ||
      !item?.createdAt
    ) {
      return [...acc];
    }

    const startOfSubtask =
      index === 0 || itemCreated.isBefore(moment(start))
        ? moment(start)
        : moment(itemCreated);
    const endOfSubtask =
      !nextItem || nextItemCreated.isAfter(moment(end))
        ? moment(end)
        : moment(nextItemCreated);
    const diff = endOfSubtask.diff(startOfSubtask);

    return [
      ...acc,
      {
        diff,
        status: item.status,
      },
    ];
  }, []);

export const isOnReview = (subtask: ISubtaskDto) => {
  const length = subtask?.changelog?.length;
  const index = length > 0 ? length - 1 : 0;
  const lastStatus = subtask?.changelog[index];

  return lastStatus?.status === SubtaskStatusesEnum.ON_REVIEW;
};
