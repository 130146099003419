import { ResendButton, ResendWrapper } from './styles';

interface IResendAuthCode {
  handleSubmit: () => void;
}

const ResendAuthCode = ({ handleSubmit }: IResendAuthCode): JSX.Element => (
  <ResendWrapper>
    Didn’t receive a code?{' '}
    <ResendButton type="link" onClick={handleSubmit}>
      Resend it
    </ResendButton>
  </ResendWrapper>
);

export default ResendAuthCode;
