import { WarningIcon } from 'lib/icons';
import { IconWrapper, Title, Wrapper } from './styles';

interface IWarningMessage {
  companyName: string;
}

const WarningMessage = ({ companyName }: IWarningMessage): JSX.Element => (
  <Wrapper>
    <IconWrapper>
      <WarningIcon />
    </IconWrapper>
    <div>
      <Title>Warning!</Title>
      <div>
        By registering new company <b> you'll lose access to {companyName}.</b>{' '}
        You can't undo this action.
      </div>
    </div>
  </Wrapper>
);

export default WarningMessage;
