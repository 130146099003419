import styled from 'styled-components';
import { Row } from 'antd';

export const ContainerWrapper = styled(Row)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  padding: 15px 28px 11px 18px;
  margin-bottom: 11px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 11px;
`;

export const TooltipWrapper = styled.div`
  cursor: not-allowed;
  & .ant-btn {
    pointer-events: none;
  }
`;
