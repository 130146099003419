import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { downloadPdfPromiseCreator } from 'store/sharing';
// components
import Preview from 'components/PreviewAttachment';
import { useActionsRoutines } from 'hooks';
import { IMAGES_MIME_TYPES } from 'lib/constants';
// icons
import { PaperClip } from 'lib/icons';
// styles
import { ItemWrap } from './styles';
// helpers
import { IAttachmentInfo } from 'types';

const FileItem = ({ file, isPopover }: IFileItemProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const { filePath: href } = file;
  const isImage = useMemo(
    () => IMAGES_MIME_TYPES.includes(file.mimetype),
    [file]
  );

  const openPreview = useCallback(
    e => {
      e.stopPropagation();
      if (!file.fileUrl || !isImage) return;
      setIsPreviewOpen(true);
    },
    [file, isImage]
  );

  const closePreview = useCallback(e => {
    e.stopPropagation();
    setIsPreviewOpen(false);
  }, []);

  const handleDownloadPdf = useCallback(() => {
    if (isPopover ? href && !isImage : href) {
      downloadPdf({ data: href, originalName: file.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file.originalName, downloadPdf, isPopover, isImage]);

  return (
    <ItemWrap onClick={openPreview}>
      <Preview
        showPreview={isPreviewOpen}
        closePreview={e => closePreview(e)}
        file={file}
      />
      <PaperClip />
      <a onClick={handleDownloadPdf}>{file.originalName}</a>
    </ItemWrap>
  );
};

interface IFileItemProps {
  file: Partial<IAttachmentInfo>;
  isPopover?: boolean;
}

export default memo(FileItem, isEqual);
