import { SVGProps } from 'react';

function CheckCircleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1.334a6.674 6.674 0 00-6.666 6.667 6.674 6.674 0 006.667 6.666 6.674 6.674 0 006.666-6.666 6.674 6.674 0 00-6.666-6.667zm0 12a5.34 5.34 0 01-5.333-5.333 5.34 5.34 0 015.334-5.334 5.34 5.34 0 015.333 5.334 5.34 5.34 0 01-5.333 5.333z"
        fill="#172B4D"
      />
      <path
        d="M6.665 9.059l-1.532-1.53-.942.944 2.476 2.47 4.47-4.471-.942-.943-3.53 3.53z"
        fill="#172B4D"
      />
    </svg>
  );
}

export default CheckCircleIcon;
