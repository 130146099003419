import { USER_MAIN_ROLES, UpdatedRolesEnum, UserRolesEnum } from 'types';

export const getRole = roles => {
  let mainRole;
  switch (true) {
    case roles.indexOf(UserRolesEnum.SUPER_ADMIN) !== -1:
      mainRole = USER_MAIN_ROLES.SUPER_ADMIN;
      break;
    case roles.indexOf(UserRolesEnum.ADMIN) !== -1:
      mainRole = USER_MAIN_ROLES.ADMIN;
      break;
    case roles.indexOf(UserRolesEnum.SALES_MANAGER) !== -1:
      mainRole = USER_MAIN_ROLES.SALES_MANAGER;
      break;
    case roles.indexOf(UserRolesEnum.PROJECT_MANAGER) !== -1:
      mainRole = USER_MAIN_ROLES.PROJECT_MANAGER;
      break;
    case roles.indexOf(UserRolesEnum.SCHEDULER) !== -1:
      mainRole = USER_MAIN_ROLES.SCHEDULER;
      break;
    default:
      mainRole = USER_MAIN_ROLES.ADMIN;
  }
  return { mainRole };
};

export const isSuperAdminRole = roles =>
  !!roles?.find(role => role === UpdatedRolesEnum.SUPER_ADMIN);
