import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import {
  createReport,
  deleteReport,
  getReportsList,
  profitPerEmployees,
  profitPerInvoice,
  profitPerResource,
  setInvoiceSearch,
  setReportsDates,
  setReportsSearch,
  updateReport,
} from './actions';

const reportsReducer = createReducer(initialState.reports, {
  [getReportsList.SUCCESS]: (state, action) => {
    state.reportsList = action.payload;
  },
  [deleteReport.SUCCESS]: (state, action) => {
    state.reportsList = state.reportsList.filter(
      report => report._id !== action.payload
    );
  },
  [createReport.SUCCESS]: (state, action) => {
    const updatedList = [...state.reportsList, action.payload];
    state.reportsList = updatedList;
  },
  [updateReport.SUCCESS]: (state, { payload }) => {
    state.reportsList = state.reportsList.map(report => {
      if (payload._id === report._id) {
        return {
          ...report,
          ...payload.data,
        };
      } else {
        return report;
      }
    });
  },
  [profitPerInvoice.SUCCESS]: (state, action) => {
    state.profitPerInvoice = action.payload;
  },
  [profitPerEmployees.SUCCESS]: (state, action) => {
    state.profitPerEmployees = action.payload;
  },
  [profitPerResource.SUCCESS]: (state, action) => {
    state.profitPreResource = action.payload;
  },
  [String(setReportsDates)]: (state, action) => {
    state.reportsDates = action.payload;
  },
  [String(setReportsSearch)]: (state, action) => {
    state.search = action.payload;
  },
  [String(setInvoiceSearch)]: (state, action) => {
    state.invoiceSearch = action.payload;
  },
});

export { reportsReducer as reducer };
