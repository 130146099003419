export enum RoleEnum {
  SuperAdministrator = 'Super Administrator',
  Administrator = 'Administrator',
  ProjectManager = 'Project manager',
  Scheduler = 'Scheduler',
  SalesManager = 'Sales manager',
}

export enum UserRolesEnum {
  SUPER_ADMIN = RoleEnum.SuperAdministrator,
  ADMIN = RoleEnum.Administrator,
  PROJECT_MANAGER = RoleEnum.ProjectManager,
  SCHEDULER = RoleEnum.Scheduler,
  SALES_MANAGER = RoleEnum.SalesManager,
}

export const USER_MAIN_ROLES = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  PROJECT_MANAGER: 'manager',
  SCHEDULER: 'scheduler',
  PROD_AND_DEPOT_MANAGER: 'managerAndDepotManager',
  SALES_MANAGER: 'salesManager',
};

export enum UpdatedRolesEnum {
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
}
