// styles
import { useCallback, useEffect, useState } from 'react';
import { setHighlightedSubtaskAction } from 'store/gantt';
import ReadMore from 'components/common/ReadMore/ReadMore';
import { useActions } from 'hooks';
import update from 'immutability-helper';
import SubtaskItem from 'pages/Dashboard/Project/ProjectView/Tasks/TaskInfo/TaskBlock/BodyBlock/SubtaskItem';
import { message } from 'utils/message';
import { List } from 'antd';
import { Description, InfoWrap } from './styles';
import { IChecklist, IProjectResponseDto, IProjectTaskDto } from 'types';

interface IBodyContainerProps {
  isOpenInfo: boolean;
  item: IProjectTaskDto;
  notAvailableProject: boolean;
  project: IProjectResponseDto;
  toggleParent: (visible: boolean) => void;
  editProject: ({
    id,
    data,
    skipContainerUniqueness,
  }: {
    id: string;
    data: IProjectResponseDto;
    skipContainerUniqueness: boolean;
  }) => Promise<void>;
  setShouldUpdate: (update: boolean) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  color: string;
  invoice: string;
  locationId: string;
}
const BodyContainer = ({
  isOpenInfo,
  item,
  project,
  notAvailableProject,
  toggleParent,
  editProject,
  openCheckResult,
  color,
  setShouldUpdate,
  invoice,
  locationId,
  updateChecklist,
}: IBodyContainerProps) => {
  const { _id } = item;

  const setHighlightedSubtask = useActions(setHighlightedSubtaskAction);
  const [subtasksList, setSubtasksList] = useState(item?.subtasks);
  const [isOrderChanged, setIsOrderChanged] = useState(false);

  useEffect(() => {
    setSubtasksList(item?.subtasks);
  }, [item]);

  const onDoubleClick = (_id, startDate, dueDate) => {
    if (notAvailableProject) return;
    setHighlightedSubtask({ _id, startDate, dueDate });
    toggleParent(false);
  };

  const saveSubtasksOrder = useCallback(() => {
    if (!isOrderChanged) return;
    const newTasks = project.tasks.map(item => {
      if (item._id === _id) {
        return { ...item, subtasks: subtasksList };
      }
      return item;
    });

    const newData = {
      ...project,
      tasks: newTasks,
    };
    editProject({
      id: project._id,
      data: newData,
      skipContainerUniqueness: true,
    })
      .then(() => message.success())
      .catch(err => {
        console.error(err);
        setSubtasksList(item?.subtasks);
      })
      .finally(() => setIsOrderChanged(false));
  }, [subtasksList, isOrderChanged, item?.subtasks, _id, project, editProject]);

  const moveSubtasks = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSubtask = subtasksList[dragIndex];
      const newSubtasks = update(subtasksList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSubtask],
        ],
      });
      setSubtasksList(
        newSubtasks.map((item, index) => ({ ...item, order: +index + 1 }))
      );
      setIsOrderChanged(dragIndex !== hoverIndex);
    },
    [subtasksList]
  );

  if (!isOpenInfo) return null;

  return (
    <InfoWrap>
      {item?.description && (
        <Description>
          <ReadMore limit={200} text={item.description} />
        </Description>
      )}

      <div>
        {subtasksList?.length > 0 && (
          <List
            dataSource={subtasksList}
            rowKey={(item: any) => item._id}
            renderItem={(item, index) => (
              <SubtaskItem
                data={item}
                index={index}
                color={color}
                taskName={item?.name}
                taskOrder={'container'}
                taskId={_id}
                toggleParent={toggleParent}
                onDoubleClick={onDoubleClick}
                moveSubtasks={moveSubtasks}
                projectId={project._id}
                project={project}
                openCheckResult={openCheckResult}
                setShouldUpdate={setShouldUpdate}
                locationId={locationId}
                invoice={invoice}
                saveSubtasksOrder={saveSubtasksOrder}
                notAvailableProject={notAvailableProject}
                updateChecklist={updateChecklist}
              />
            )}
          />
        )}
      </div>
    </InfoWrap>
  );
};

export default BodyContainer;
