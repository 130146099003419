import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getOnlyDigits } from 'utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/Button';
import { NumberInput } from 'components/common/NumberInput';
import { DuplicateSchema } from 'validation';
import * as Styled from './styles';

interface DuplicatePopoverContentProps {
  onConfirm: (amount: number, setIsLoading?: any) => void;
  count: number;
}

interface DuplicateInput {
  amount: number;
}

export const DuplicatePopoverContent: FC<DuplicatePopoverContentProps> = ({
  onConfirm,
  count,
}) => {
  const { handleSubmit, control } = useForm<DuplicateInput>({
    mode: 'onBlur',
    resolver: yupResolver(DuplicateSchema),
    context: { count },
    shouldUnregister: true,
    defaultValues: { amount: 1 },
  });

  const onSubmit = useCallback(
    (data: DuplicateInput) => onConfirm(data.amount),
    [onConfirm]
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Styled.InputWrapper>
        <Styled.Label>Amount of copies</Styled.Label>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <NumberInput
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              noicon="true"
              width="100%"
              height="30px"
              placeholder="0"
              error={error?.message}
              parser={getOnlyDigits}
            />
          )}
          name="amount"
          control={control}
        />
      </Styled.InputWrapper>
      <Button htmlType="submit" label="Confirm" height="38px" width="100%" />
    </form>
  );
};
