import { SubtaskStatusesEnum } from 'types';

export const DESKTOP_WIDTH = 800;
export const DESKTOP_WIDTH_PX = `${DESKTOP_WIDTH}px`;

export const NO_REQUEST_SUBTASK_STATUSES = [
  SubtaskStatusesEnum.ON_REVIEW,
  SubtaskStatusesEnum.NOT_READY,
  SubtaskStatusesEnum.DONE,
];
