import { useEffect, useState } from 'react';
// components
import { Error } from 'components/common';
// icons
import { CheckMark } from 'lib/icons';
// styles
import { ColorItem, ColorsList } from './styles';

interface IColor {
  _id: string;
  color: string;
}

interface IColorsProps {
  colors?: IColor[];
  value?: string;
  onChange?: (color: IColor) => void;
  error?: string;
}

const Colors = ({ colors, value, onChange, error }: IColorsProps) => {
  const [activeColor, setActiveColor] = useState(value);
  const onItemClick = color => {
    setActiveColor(color);
    onChange(color);
  };
  useEffect(() => {
    setActiveColor(value);
  }, [value]);
  return (
    <ColorsList>
      {colors.map(item => (
        <ColorItem
          onClick={() => onItemClick(item.color)}
          key={item._id}
          color={item.color}
        >
          {activeColor === item.color && <CheckMark />}
        </ColorItem>
      ))}
      {error && <Error>{error}</Error>}
    </ColorsList>
  );
};

export default Colors;
