import { css } from 'styled-components';

export const commonInputStyles = css<{
  defaultValue?: string;
  value?: string;
  width?: string;
  height?: string;
  error?: boolean;
  disabled?: boolean;
}>`
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  &:hover {
    border-color: ${({ theme }) => theme.colors.lightBlue};
    box-shadow: none;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    box-shadow: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.mutedInputTextColor};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  ${props =>
    (props.defaultValue || props.value) &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `};
  width: ${props => props.width};
  height: ${props => props.height};
  ${props =>
    props.error &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.danger};
      }
    `}
  ${props =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.grayLight};
      border-color: ${({ theme }) => theme.colors.grayDark};
    `}
`;
