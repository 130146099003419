import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  flex-wrap: wrap;
`;

export const Titile = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  span {
    color: ${({ theme }) => theme.colors.lightBlue};
  }
`;
