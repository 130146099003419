import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const sharing = (r: IInitialState) => r.sharing;
export const projectForCustomerSelector = createSelector(
  sharing,
  r => r.projectForCustomer
);

export const systemNotificationsPageSelector = createSelector(
  sharing,
  r => r.systemNotifications.meta.page
);

export const systemNotificationsPagesCountSelector = createSelector(
  sharing,
  r => r.systemNotifications.meta.pages
);

export const systemNotificationsSelector = createSelector(
  sharing,
  r => r.systemNotifications.data
);

export const systemNotificationsObjectSelector = createSelector(
  sharing,
  r => r.systemNotifications
);

export const systemNotificationsUnreadCountSelector = createSelector(
  sharing,
  r => r.systemNotificationsUnreadCount
);

export const isShowOnlyUnreadNotificationMessagesSelector = createSelector(
  sharing,
  r => r.isShowOnlyUnreadMessages
);

export const isSystemNotificationsOpenedSelector = createSelector(
  sharing,
  r => r.isSystemNotificationsOpened
);
