import { CSSProperties, RefObject } from 'react';
import { Error, Label } from 'components/common';
import { RequiredSign } from 'components/common/RequiredSign';
import { AutoCompleteProps } from 'antd';
import { AutoCompleteStyled } from './styles';

interface IAutocompleteProps extends AutoCompleteProps {
  label?: string | JSX.Element;
  name?: string;
  error?: string;
  id?: string;
  width?: string;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
  isRequired?: boolean;
  open?: boolean;
  defaultActiveFirstOption?: boolean;
  inputref?: RefObject<HTMLInputElement>;
}

export const Autocomplete = (props: IAutocompleteProps) => (
  <div style={{ ...props.style, width: props.width }}>
    {props.label && (
      <Label>
        {props.label}
        {props.isRequired && <RequiredSign> *</RequiredSign>}
      </Label>
    )}
    <AutoCompleteStyled
      {...props}
      // @ts-ignore
      ref={props?.inputref}
      id={props.id || props.name}
      getPopupContainer={trigger => trigger.parentNode}
    />
    {props.error && <Error>{props.error}</Error>}
  </div>
);
