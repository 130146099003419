import { PayloadAction, createReducer, current } from '@reduxjs/toolkit';
import { createProjectFormWorkOrder } from 'store/inbound';
import initialState from 'store/initialState';
import * as Sharing from 'store/sharing';
import {
  getDraftProject,
  getProjectFiles,
  prepareProjectData,
  prepareProjects,
  updateProjectsItemFields,
} from './helpers';
import * as Actions from './actions';
import {
  MIN_PROJECTS_CONTAINER_HEIGHT,
  MIN_PROJECTS_CONTAINER_HEIGHT_EMPTY_PROJECTS,
} from './constants';
import storageManager from 'utils/storageManager';
import {
  CloneDataPayload,
  CloneType,
  ISubtaskDto,
  ProjectFileUploadedDto,
} from 'types';

const dashboard = createReducer(initialState.dashboard, {
  [String(Actions.setProjectViewMode)]: (state, { payload }) => {
    storageManager.setProjectViewMode(payload);
    state.projectViewMode = payload;
  },
  [String(Actions.setAssignConflictData)]: (state, action) => {
    state.assignConflictData = [...state.assignConflictData, action.payload];
  },
  [String(Actions.clearAssignConflictData)]: state => {
    state.assignConflictData = [];
  },
  [Actions.gantt.SUCCESS]: (state, action) => {
    state.gantt = action.payload;
  },
  [Actions.projects.SUCCESS]: (state, action) => {
    const currentState = current(state);
    if (!currentState.isFirstProjectsRequestLoaded) {
      state.isFirstProjectsRequestLoaded = true;
    }
    state.projects = action.payload.projects;
    state.projectsTotal = action.payload.total;
  },
  [String(Actions.setProjectFilesLoader)]: (state, action) => {
    state.projectFilesLoader = action.payload;
  },
  [String(Actions.removeProjects)]: state => {
    state.projects = [];
    state.projectsTotal = 0;
  },

  [String(Actions.setCreateProjectModalOpen)]: (state, action) => {
    state.isProjectCreateModalOpen = action.payload;
  },

  [Actions.project.SUCCESS]: (state, action) => {
    state.project = action.payload;
  },
  [Actions.deleteProjectFile.SUCCESS]: (state, { payload }) => {
    const currentState = current(state);
    const updatedProjects = currentState.projects.map(project => {
      if (project._id === payload.id) {
        const updatedFiles = project.files.filter(
          file => !payload.fileArray.includes(file.filePath)
        );

        return {
          ...project,
          attachmentsCount: updatedFiles.length,
          files: updatedFiles,
        };
      } else {
        return project;
      }
    });
    state.projects = updatedProjects;
  },
  [Actions.subtasksUnreadMessagesCount.SUCCESS]: (state, { payload }) => {
    const unreadMessagesExist = !!payload.data.list.length;
    if (payload.isStandalone) {
      state.standaloneSubtaskUnreadCount = unreadMessagesExist
        ? payload.data.list[0].count
        : 0;
    } else {
      const unreadMessagesCount = payload.data.list.reduce(
        (prev, curr) => ({
          ...prev,
          [curr._id]: curr.count,
        }),
        {}
      );
      const prevState = state.subtasksUnreadMessagesCount;

      state.subtasksUnreadMessagesCount = unreadMessagesExist
        ? {
            ...prevState,
            ...unreadMessagesCount,
          }
        : { ...prevState, [payload.subtasksId]: 0 };
    }
  },

  [Actions.partialProjectUpdate.SUCCESS]: (state, action) => {
    const updatedPoject = action?.payload;

    state.projects = state.projects.map(project =>
      project._id === updatedPoject?._id
        ? {
            ...project,
            status: updatedPoject?.status,
          }
        : project
    );

    state.project = {
      ...state.project,
      status: updatedPoject?.status,
    };
  },

  [Actions.postProject.SUCCESS]: (state, action) => {
    state.project = action.payload;
  },
  [Actions.postDuplicateProject.SUCCESS]: (state, action) => {
    state.project = action.payload;
  },
  [Actions.putProject.SUCCESS]: (state, action) => {
    const updatedProject = action?.payload?.project;
    const updatedProjectListItem = action?.payload?.projectListItem;
    const isSameProjectFiles = action?.payload?.isSameProjectFiles;
    const isSameSubtasksFiles = action?.payload?.isSameSubtasksFiles;
    const isSameCover = updatedProject.coverUrl === state.project.coverUrl;
    const currentLocation = storageManager.getLocation();

    if (currentLocation === action.payload.project.locationId) {
      state.projects = state.projects.map(project =>
        project._id === updatedProjectListItem._id
          ? {
              ...updatedProjectListItem,
              attachmentsCount: updatedProjectListItem.files.length,
              files: isSameProjectFiles
                ? project?.files
                : updatedProjectListItem?.files,
              cover: isSameProjectFiles
                ? isSameCover
                  ? project?.cover
                  : project?.files?.find(
                      file => updatedProjectListItem.coverUrl === file.filePath
                    )
                : undefined,
            }
          : project
      );
    } else {
      state.projects = state.projects.filter(
        project => project._id !== action.payload.project._id
      );
    }

    state.project = {
      ...updatedProject,
      files: isSameProjectFiles ? state.project?.files : updatedProject?.files,
      cover: isSameProjectFiles
        ? state.project?.files?.find(
            file => updatedProject.coverUrl === file.filePath
          )
        : undefined,
      tasks: isSameSubtasksFiles
        ? getProjectFiles(
            JSON.parse(JSON.stringify(state.project)),
            updatedProject
          )?.tasks
        : updatedProject.tasks,
    };
  },
  [Actions.putAttachmentsById.SUCCESS]: (
    state,
    action: PayloadAction<ProjectFileUploadedDto[]>
  ) => {
    state.project.files = action.payload;
  },
  [String(Actions.projectsSortBy)]: (state, action) => {
    state.projectsSortBy = action.payload;
  },
  [String(Actions.containerSizesFilter)]: (state, action) => {
    state.containerSizesFilter = action.payload;
  },
  [String(Actions.containerSkusFilter)]: (state, action) => {
    state.containerSkusFilter = action.payload;
  },
  [String(Actions.patchProjectWeight)]: (
    state,
    { payload: { id, weight } }
  ) => {
    state.projects = state.projects
      .map(project => (project._id === id ? { ...project, weight } : project))
      .sort((a, b) => a.weight - b.weight);
  },
  [Actions.unassignSubtask.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },

  [String(Actions.ganttDates)]: (state, action) => {
    state.ganttDates = { ...state.ganttDates, ...action.payload };
  },
  [String(Actions.ganttWeekView)]: (state, action) => {
    state.ganttWeekView = action.payload;
  },
  [createProjectFormWorkOrder.SUCCESS]: (state, action) => {
    state.project = action.payload;
  },

  [Actions.subtaskDetails.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload
    );
  },

  [Actions.approveSubtask.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Actions.rejectSubtask.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Actions.markSubtaskAsToDo.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Actions.markSubtaskAsDone.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Actions.markSubtaskAsActive.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      prepareProjectData(action.payload?.project)
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Actions.markSubtaskAsPaused.SUCCESS]: (state, action) => {
    if (!action.payload.project.isConflict) {
      state.project = getProjectFiles(
        JSON.parse(JSON.stringify(state.project)),
        action.payload?.project
      );

      state.projects = updateProjectsItemFields(
        JSON.parse(JSON.stringify(state.projects)),
        JSON.parse(JSON.stringify(state.project)),
        action.payload?.projectListItem
      );
    }
  },

  [String(Actions.updateSubtaskChangelog)]: (state, action) => {
    const payload = action?.payload;

    const project = {
      ...state.project,
      tasks: state?.project?.tasks?.map(task => {
        if (
          task?.subtasks?.find(subtask => subtask?._id === payload.subtaskId)
        ) {
          const subtasks = task?.subtasks?.map(subtask => {
            if (subtask._id === payload.subtaskId) {
              const changelog = subtask?.changelog?.map(logItem => {
                if (logItem?._id === payload.logItemId) {
                  return {
                    ...logItem,
                    createdAt: payload?.createdAt,
                    percent: payload?.percent,
                  };
                }

                return logItem;
              });
              let percent;

              const changelogLastItem = changelog?.sort(function (a, b) {
                if (a?.createdAt < b?.createdAt) {
                  return -1;
                }
                if (a?.createdAt > b?.createdAt) {
                  return 1;
                }
                return 0;
              })[changelog.length - 1];

              if (
                changelog.length &&
                changelogLastItem?._id === payload.logItemId
              ) {
                percent = changelogLastItem?.percent;
              }
              return {
                ...subtask,
                changelog,
                percent: percent || subtask?.percent,
              };
            }

            return subtask;
          });

          return {
            ...task,
            subtasks,
          };
        }

        return task;
      }),
    };

    state.project = project;
  },
  [Actions.markSubtaskAsResumed.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },

  [Actions.markSubtaskAsNotReady.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.project
    );

    state.projects = updateProjectsItemFields(
      JSON.parse(JSON.stringify(state.projects)),
      JSON.parse(JSON.stringify(state.project)),
      action.payload?.projectListItem
    );
  },
  [Sharing.getToken.SUCCESS]: (state, action) => {
    state.project = { ...state.project, generatedApiKey: action.payload };
  },
  [Sharing.deleteToken.SUCCESS]: state => {
    state.project = { ...state.project, generatedApiKey: undefined };
  },
  [String(Actions.projectsAfterDraft)]: (state, action) => {
    state.projects = prepareProjects(state.projects, action.payload);
  },
  [String(Actions.projectDraftData)]: (state, action) => {
    state.project = getDraftProject(action.payload);
  },
  [Actions.getStandaloneSubtask.SUCCESS]: (state, action) => {
    state.standaloneSubtask = action.payload;
  },
  [Actions.putStandaloneSubtask.SUCCESS]: (state, action) => {
    state.standaloneSubtask = action.payload;
  },
  [String(Actions.clearStandaloneSubtask)]: state => {
    state.standaloneSubtask = {} as ISubtaskDto;
  },
  [String(Actions.setScreenDistribution)]: (state, action) => {
    state.screenDistribution = action.payload;
  },
  [String(Actions.splitScreen)]: (state, { payload }) => {
    state.isScreenSplited = payload.isScreenSplitted;
  },
  [String(Actions.setMinProjectsContainerHeight)]: (state, action) => {
    let height: number;
    if (action.payload === 0) {
      height = MIN_PROJECTS_CONTAINER_HEIGHT_EMPTY_PROJECTS;
    } else {
      height =
        action.payload >= MIN_PROJECTS_CONTAINER_HEIGHT
          ? +action.payload + 25
          : MIN_PROJECTS_CONTAINER_HEIGHT;
    }

    state.minProjectsContainerHeight = height;
  },
  [String(Actions.setProjectsContainerHeight)]: (state, action) => {
    state.projectsContainerHeight = action.payload;
  },
  [Actions.revokeSubtask.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload
    );
  },
  [Actions.delegateSubtask.SUCCESS]: (state, action) => {
    state.project = getProjectFiles(
      JSON.parse(JSON.stringify(state.project)),
      action.payload
    );
  },
  [Actions.addFileToSubtaskByDrop.SUCCESS]: (state, action) => {
    state.project = action.payload;
  },
  [String(Actions.insertSubtaskDraftFilesBuffer)]: (state, action) => {
    const currentState = current(state);

    // avoid adding the same file to the buffer
    const filteredSubtaskFilesBuffer = currentState.subtaskFilesBuffer.filter(
      item =>
        typeof item === 'string'
          ? item !== action.payload
          : !item.paths.includes(action.payload.paths[0])
    );

    state.subtaskFilesBuffer = filteredSubtaskFilesBuffer;
    state.subtaskDraftFilesBuffer = [
      ...state.subtaskDraftFilesBuffer,
      action.payload,
    ];
  },
  [String(Actions.insertSubtaskFilesBuffer)]: (state, action) => {
    const currentState = current(state);

    // avoid adding the same file to the buffer
    const filteredSubtaskDraftFilesBuffer =
      currentState.subtaskDraftFilesBuffer.filter(item =>
        typeof item === 'string'
          ? item !== action.payload
          : !item.paths.includes(action.payload.paths[0])
      );

    state.subtaskDraftFilesBuffer = filteredSubtaskDraftFilesBuffer;
    state.subtaskFilesBuffer = [...state.subtaskFilesBuffer, action.payload];
  },
  [String(Actions.insertSubtaskTemplateFilesBuffer)]: (state, action) => {
    const currentState = current(state);

    // avoid adding the same file to the buffer
    const filteredSubtaskDraftFilesBuffer =
      currentState.subtaskDraftFilesBuffer.filter(item =>
        typeof item === 'string'
          ? item !== action.payload
          : !item.paths.includes(action.payload.paths[0])
      );

    state.subtaskDraftFilesBuffer = filteredSubtaskDraftFilesBuffer;
    state.subtaskTemplateFilesBuffer = [
      ...state.subtaskTemplateFilesBuffer,
      action.payload,
    ];
  },
  [String(Actions.filterSubtaskDraftFilesBuffer)]: (state, action) => {
    state.subtaskDraftFilesBuffer = state.subtaskDraftFilesBuffer.filter(item =>
      item.paths
        ? item.paths.indexOf(action.payload) === -1
        : item !== action.payload
    );
  },
  [String(Actions.clearSubtaskFilesBuffer)]: state => {
    state.subtaskFilesBuffer = [];
  },
  [String(Actions.clearSubtaskDraftFilesBuffer)]: state => {
    state.subtaskDraftFilesBuffer = [];
  },
  [String(Actions.clearSubtaskTemplateFilesBuffer)]: state => {
    state.subtaskTemplateFilesBuffer = [];
  },
  [String(Actions.clearAllSubtaskFilesBuffers)]: state => {
    state.subtaskFilesBuffer = [];
    state.subtaskDraftFilesBuffer = [];
    state.subtaskTemplateFilesBuffer = [];
  },
  [Actions.getSubtaskMessages.SUCCESS]: (state, { payload }) => {
    state.messages = payload.messages.data.reverse();
    state.openedSubtaskChatId = payload.chatId;
  },
  [String(Actions.clearSubtaskMessages)]: state => {
    state.messages = [];
    state.openedSubtaskChatId = undefined;
  },
  [Actions.deleteSubtaskMessage.SUCCESS]: (state, action) => {
    const updatedMessages = state.messages.reduce((acc, message) => {
      if (message._id === action.payload) {
        return acc;
      }
      if (message.replyToMessage?._id === action.payload) {
        return [
          ...acc,
          {
            ...message,
            replyToMessage: undefined,
          },
        ];
      }
      return [...acc, message];
    }, []);
    state.messages = updatedMessages;
  },
  [Actions.updateSubtaskMessage.SUCCESS]: (state, action) => {
    state.messages = state.messages.map(message => {
      if (message?.replyToMessage?._id === action.payload._id) {
        return {
          ...message,
          replyToMessage: {
            ...message.replyToMessage,
            body: action.payload.body,
          },
        };
      }
      if (message._id === action.payload._id) {
        return {
          ...message,
          body: action.payload.body,
          updatedAt: action.payload.updatedAt,
        };
      }
      return message;
    });
  },
  [String(Actions.openSubtaskChat)]: (state, action) => {
    state.openedSubtaskChatId = action.payload;
  },
  [String(Actions.closeSubtaskChat)]: state => {
    state.openedSubtaskChatId = undefined;
  },
  [String(Actions.checklistSocketMarkAsCompleted)]: (state, action) => {
    state.project = {
      ...state.project,
      // @ts-ignore
      tasks: state.project?.tasks?.map(task => ({
        ...task,
        subtasks: task.subtasks?.map(subtask =>
          subtask._id === action.payload?.subtaskId
            ? {
                ...subtask,
                checklist: subtask?.checklist.map(checkItem =>
                  checkItem?._id === action.payload?.checklistItemId
                    ? {
                        ...checkItem,
                        isCompleted: true,
                      }
                    : checkItem
                ),
              }
            : subtask
        ),
      })),
    };
  },
  [String(Actions.checklistSocketMarkAsUncompleted)]: (state, action) => {
    state.project = {
      ...state.project,
      // @ts-ignore
      tasks: state.project?.tasks?.map(task => ({
        ...task,
        subtasks: task.subtasks?.map(subtask =>
          subtask._id === action.payload?.subtaskId
            ? {
                ...subtask,
                checklist: subtask?.checklist.map(checkItem =>
                  checkItem?._id === action.payload?.checklistItemId
                    ? {
                        ...checkItem,
                        isCompleted: false,
                      }
                    : checkItem
                ),
              }
            : subtask
        ),
      })),
    };
  },
  [String(Actions.checklistStandaloneSocketMarkAsCompleted)]: (
    state,
    action
  ) => {
    state.standaloneSubtask = {
      ...state.standaloneSubtask,
      // @ts-ignore
      checklist: state.standaloneSubtask?.checklist?.map(checkItem =>
        checkItem?._id === action.payload?.checklistItemId
          ? {
              ...checkItem,
              isCompleted: true,
            }
          : checkItem
      ),
    };
  },
  [String(Actions.setGanttItemsAction)]: (state, { payload }) => {
    state.gantt.items = payload;
  },
  [String(Actions.setGanttGroupsAction)]: (state, { payload }) => {
    state.gantt.groups = payload;
  },
  [String(Actions.checklistStandaloneSocketMarkAsUncompleted)]: (
    state,
    action
  ) => {
    state.standaloneSubtask = {
      ...state.standaloneSubtask,
      // @ts-ignore
      checklist: state.standaloneSubtask?.checklist?.map(checkItem =>
        checkItem?._id === action.payload?.checklistItemId
          ? {
              ...checkItem,
              isCompleted: false,
            }
          : checkItem
      ),
    };
  },
  [String(Actions.setCloneDataAction)]: (
    state,
    { payload }: { payload: CloneDataPayload }
  ) => {
    const { isClone, cloneType, data } = payload;
    if (isClone) {
      const currentState = current(state);
      const cloneFilePaths = [];
      if (cloneType === CloneType.Project) {
        currentState.project.tasks.forEach(task => {
          task.subtasks.forEach(subtask => {
            subtask.files.forEach(file => {
              cloneFilePaths.push(file.filePath);
            });
          });
        });
      }
      if (cloneType === CloneType.Template) {
        data.subtasks.forEach(subtask => {
          subtask.files.forEach(file => {
            cloneFilePaths.push(file.filePath);
          });
        });
      }
      state.isCloneProject = true;
      state.cloneFiles = cloneFilePaths;
    } else {
      state.isCloneProject = false;
      state.cloneFiles = [];
    }
  },
});

export { dashboard as reducer };
