import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
// redux
import {
  addTasksPromiseCreator,
  containerOptionsSelector,
  subtaskTypesSelector,
  updateTaskPromiseCreator,
} from 'store/admin';
import { companySelector } from 'store/company';
import { isCloneProjectSelector, setCloneDataAction } from 'store/dashboard';
import { isFilesLoadingSelector } from 'store/loading';
import { locationsSelector } from 'store/predefined';
import { getModalTitle, getRequestData, getSubtasks, getType } from './helpers';
import { hasEmptyProperty } from 'utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import ContainerForm from 'components/ContainerForm';
// components
import TaskForm from 'components/TaskForm';
import { BUTTON_TYPES, Button, SortBySelect } from 'components/common';
// utils
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
// icons
import { Duplicate } from 'lib/icons';
import { message } from 'utils/message';
import { ContainerTemplateSchema, TaskCommonSchema } from 'validation';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// styles
import {
  ButtonCloneStyled,
  ButtonSaveWrapper,
  LocationSelectContainer,
  SwitchStyled,
  SwitchTextItem,
  SwitchWrapper,
  Title,
  TooltipItem,
} from './styles';
import { CloneType, IContainer, ITaskDto } from 'types';

interface ITaskFormModalProps {
  onSubmit: () => void;
  subtaskTypesHandler?: ({
    noLoader,
    allLocations,
    location,
  }: {
    noLoader?: boolean;
    allLocations?: boolean;
    location?: string;
  }) => void;
  search?: string;
  projectId: string;
  data?: ITaskDto | IContainer;
}

const TaskFormModal = ({
  onSubmit,
  data,
  search,
  subtaskTypesHandler,
  projectId,
}: ITaskFormModalProps) => {
  const isFilesLoading = useSelector(isFilesLoadingSelector);
  const subtaskTypes = useSelector(subtaskTypesSelector);
  const locations = useSelector(locationsSelector);
  const { isContainersEnabled } = useSelector(companySelector);
  const containerOptions = useSelector(containerOptionsSelector);

  const [isContainer, setIsContainer] = useState(false);
  const [isNewContainer, setIsNewContainer] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [locationId, setLocation] = useState(data?.locationId);
  const addHandler = useActionsRoutines(addTasksPromiseCreator);
  const updateHandler = useActionsRoutines(updateTaskPromiseCreator);
  const setCloneData = useActions(setCloneDataAction);
  const isCloneProject = useSelector(isCloneProjectSelector);

  const form = useForm<any>({
    resolver: yupResolver(
      isContainer ? ContainerTemplateSchema : TaskCommonSchema
    ),
    context: { isSkuRequired: true, isTemplate: true },
    defaultValues: { ...data },
    shouldUnregister: true,
  });
  const { setValue, reset, getValues } = form;

  const isEdit = !!data?._id;
  const memoizedData = useMemoCompare(data);
  const locationsWithDefault = useMemo(
    () => [
      {
        value: undefined,
        label: 'All locations',
      },
      ...locations,
    ],
    [locations]
  );
  const isContainerAvailable = !hasEmptyProperty(containerOptions);
  const modalTitle = getModalTitle(isCloneProject, isEdit);

  useEffect(() => {
    if (data?.type === 'container') setIsContainer(true);
  }, [setIsContainer, data?.type]);

  useEffect(() => {
    if (isEdit && data?.locationId) {
      subtaskTypesHandler({
        noLoader: false,
        allLocations: false,
        location: data.locationId,
      });
    }
  }, [isEdit, data?.locationId, subtaskTypesHandler]);

  const handleSubmit = useCallback(
    formData => {
      setIsSaveLoading(true);
      const requestData = getRequestData(formData, isContainer);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _id, updatedAt, createdAt, order, ...addData } = requestData;
      const isUpdate = memoizedData?._id && !isCloneProject;
      const type = getType(memoizedData, isContainer, isUpdate);
      const sendData = isUpdate ? requestData : addData;
      const subtasks = getSubtasks(isUpdate, addData, requestData);
      const params = { name: search };
      const payload = {
        ...sendData,
        type,
        relatedIds: data?.relatedIds || undefined,
        subtasks,
        locationId: locationId ? locationId : undefined,
      };

      const updatePayload = { id: memoizedData?._id, data: payload };
      const update = async () => updateHandler({ data: updatePayload, params });
      const add = async () => addHandler({ data: payload, params });
      const request = isUpdate ? update() : add();

      request
        .then(() => {
          onSubmit();
          message.success(
            isUpdate
              ? 'The item has been successfully updated'
              : 'The item has been successfully created'
          );
          setIsSaveLoading(false);
        })
        .catch(() => setIsSaveLoading(false));
    },
    [
      updateHandler,
      addHandler,
      memoizedData,
      onSubmit,
      isContainer,
      locationId,
      search,
      isCloneProject,
      data?.relatedIds,
    ]
  );

  const onCloneClick = useCallback(() => {
    setCloneData({
      isClone: true,
      cloneType: CloneType.Template,
      data: memoizedData,
    });
    setValue('sku', '');
  }, [setValue, setCloneData, memoizedData]);

  const handleSetLocation = useCallback(
    location => {
      setLocation(location);
      subtaskTypesHandler({
        noLoader: false,
        allLocations: !location,
        location,
      });
    },
    [subtaskTypesHandler]
  );

  const handleSetIsContainer = useCallback(() => {
    setIsContainer(!isContainer);
    reset({ ...getValues(), subtasks: undefined });
    setIsNewContainer(!isContainer);
  }, [isContainer, reset, getValues]);

  useEffect(
    () => () => setCloneData({ isClone: false }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Title>
        {modalTitle}
        <LocationSelectContainer>
          <SortBySelect
            options={locationsWithDefault}
            showSearch={false}
            onChange={handleSetLocation}
            placeholder="All locations"
            autoClearSearchValue={true}
            value={locationId}
          />
        </LocationSelectContainer>
        {isEdit && !isCloneProject && (
          <ButtonCloneStyled
            kind={BUTTON_TYPES.PRIMARY}
            icon={<Duplicate />}
            label="Duplicate"
            onClick={onCloneClick}
          />
        )}
      </Title>
      {!isEdit && !!isContainersEnabled && (
        <SwitchWrapper>
          <SwitchTextItem isActive={!isContainer}>Common</SwitchTextItem>
          <SwitchStyled
            onClick={handleSetIsContainer}
            disabled={!isContainerAvailable}
          />

          <SwitchTextItem isActive={isContainer}>
            {isContainerAvailable ? (
              'Container'
            ) : (
              <TooltipItem>
                <Tooltip title="Depot settings is required">
                  Container <ExclamationCircleOutlined />
                </Tooltip>
              </TooltipItem>
            )}
          </SwitchTextItem>
        </SwitchWrapper>
      )}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          {isContainer ? (
            <ContainerForm
              isTemplate
              subtasksInWrap
              containerOptions={containerOptions}
              isEdit={isEdit}
              item={memoizedData}
              errors={form?.formState?.errors}
              subtaskTypes={subtaskTypes}
              isNewContainer={isNewContainer}
              projectId={projectId}
              isInProject
            />
          ) : (
            <TaskForm
              subtasksInWrap
              isTemplate
              index={0}
              item={memoizedData}
              isEdit={isEdit}
              errors={form?.formState?.errors}
              subtaskTypes={subtaskTypes}
              projectId={projectId}
            />
          )}
          <ButtonSaveWrapper>
            <Button
              loading={isSaveLoading}
              disabled={isFilesLoading}
              label="Save"
              htmlType="submit"
              height="55px"
              width="100%"
            />
          </ButtonSaveWrapper>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(TaskFormModal, isEqual);
