import styled, { css } from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const Wrap = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayDark};
  padding: 14px 12px 14px 18px;
  @media (max-width: 425px) {
    padding-left: 10px;
  }
`;

export const ActionsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Action = styled.div<{
  $disabled?: boolean;
}>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 16px;
  color: ${({ color, theme }) => color || theme.colors.mainDarkBlue};
  &:hover,
  &:focus {
    outline: none;
  }
  & > span {
    margin-left: 5px;
  }
  ${({ $disabled, theme }) => {
    if ($disabled) {
      return css`
        color: ${theme.colors.lightBlue};
        cursor: not-allowed;
        svg {
          fill: ${theme.colors.lightBlue};
        }
      `;
    }
  }}
  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`;

export const LoadingOutlinedStyled = styled(LoadingOutlined)`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Files = styled.div``;
