import { CSSProperties } from 'react';
import { defaultPlaceHolders } from './constants';
import { Error, Label } from 'components/common';
import { TimeRangePickerProps } from 'antd';
// styles
import { StyledRangePicker, Wrap } from './styles';

interface IDoubleTimePickerProps extends TimeRangePickerProps {
  placeholders?: string[];
  error?: string;
  label?: string;
  width?: string;
  style?: CSSProperties;
  value?: any;
  disabled?: boolean;
}

export const DoubleTimePicker = ({
  placeholders,
  onChange,
  error,
  label,
  ...rest
}: IDoubleTimePickerProps) => (
  <Wrap>
    {label && <Label>{label}</Label>}
    <StyledRangePicker
      onChange={onChange}
      getPopupContainer={trigger => trigger.parentNode}
      placeholder={placeholders || defaultPlaceHolders}
      use12Hours={true}
      format="h:mm a"
      {...rest}
    />
    {error && <Error>error</Error>}
  </Wrap>
);
