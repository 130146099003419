import { memo } from 'react';
import { CheckboxProps, Tooltip } from 'antd';
// styles
import { CheckboxStyled, Label, Wrap } from './styles';

interface ICheckboxProps extends CheckboxProps {
  valueName?: string;
  checked?: boolean;
  short?: boolean;
  crossChecked?: boolean;
  withTooltip?: boolean;
  label?: string | JSX.Element;
  disabled?: boolean;
}

const CheckboxComponent = (props: ICheckboxProps) => (
  <Wrap>
    <CheckboxStyled {...props} />
    {props.withTooltip ? (
      <Tooltip
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        placement="topLeft"
        title={props.label}
      >
        <Label
          id="label"
          crossChecked={props.crossChecked}
          short={props.short}
          checked={props.checked}
        >
          {props.label}
        </Label>
      </Tooltip>
    ) : (
      <Label
        id="label"
        crossChecked={props.crossChecked}
        short={props.short}
        checked={props.checked}
      >
        {props.label}
      </Label>
    )}
  </Wrap>
);

CheckboxComponent.defaultProps = {
  withTooltip: true,
};

export const Checkbox = memo(CheckboxComponent);
