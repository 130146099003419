import { IEnsureLocationsParams } from 'store/predefined';
import api from './axios.instance';
import { AxiosRequestConfig } from 'axios';
import {
  ActionWithSubtaskResponseDto,
  AddGroupResourceDto,
  BaseFileUploadResDto,
  ChangePasswordBodyDto,
  ChangelogSeparateListResponseDto,
  ChecklistTemplateItemDto,
  ColorDto,
  CompanyDto,
  CompanyResponseDto,
  ConditionDto,
  ContainerOptionsDto,
  CreateChecklistTemplateDto,
  CreateChecklistTemplateItemDto,
  CreateCompanyBodyDto,
  CreateCompanyResponseDto,
  CreateEmailTemplateDto,
  CreateLocationDto,
  CreateRecurringTaskDto,
  CreateTaskNotificationDto,
  EmailDto,
  EmailTemplateDto,
  EmployeesProgressItemResponseDto,
  EmployeesValueDto,
  FileUploadResDto,
  ForgotPasswordBodyDto,
  GanttResourceDto,
  GeneratedApiKeyDto,
  GradeDto,
  IAssignSubtaskPayload,
  IAttachmentPayload,
  IEnsureAvatarPayload,
  IEnsureCreateProjectFormWorkOrderPayload,
  IEnsureCurrentUserAvatarPayload,
  IEnsureDeleteLocationPayload,
  IEnsureDeleteSubtaskMessagePayload,
  IEnsureDivideTasksPayload,
  IEnsureEditConditionPayload,
  IEnsureEditGradePayload,
  IEnsureEditSizePayload,
  IEnsureEditeTruckingCompanyPayload,
  IEnsureImportCsvPayload,
  IEnsureMarkChecklistPayload,
  IEnsureMarkSubtaskAsToDoPayload,
  IEnsurePatchLocationPayload,
  IEnsurePutLocationPayload,
  IEnsurePutProjectPayload,
  IEnsurePutStandaloneSubtaskPayload,
  IEnsurePutSubtasksAttachmentsPayload,
  IEnsureRejectSubtaskPayload,
  IEnsureResetPasswordPayload,
  IEnsureSendMessageReplyPayload,
  IEnsureSendSubtaskMessagePayload,
  IEnsureSubtaskLogItemsPayload,
  IEnsureUpdateChecklistItemPayload,
  IEnsureUpdateSubtaskMessagePayload,
  IEnsureUploadPhotoPayload,
  IEnsureVerifyEmailPayload,
  IExportTasksCsvPayload,
  IGetGanttPayload,
  IGetRelatedTasksPayload,
  IGetSystemsNotificationsParams,
  IInvoiceSearchParams,
  IMarkSubtaskAsActivePayload,
  IMarkSubtaskAsPausedPayload,
  IMarkSubtaskAsResumedPayload,
  IProfitPreEmployeesOptions,
  IProfitPreEmployeesParams,
  IProfitPreInvoiceParams,
  IProjectResponseDto,
  IReportItem,
  IResizeSubtaskPayload,
  IResourceTimesheetsExcelOptions,
  ISubtaskDto,
  ISubtaskTypeDto,
  ITaskDto,
  ITimesheetsParams,
  IUpdateLogItemsPayload,
  InvoiceProfitDto,
  ListResourceResponseDto,
  LocationDto,
  LogItemDto,
  LoginBodyDto,
  MarkSubtaskAsPausedPayload,
  PartialUpdateCompanyReqDto,
  PartialUpdateProjectDto,
  PartialUpdateTaskDto,
  ProjectDto,
  ProjectInvoicesResponseDto,
  ProjectsListResponseDto,
  RegisterBodyDto,
  RegisterByInviteBodyDto,
  RegisterResponseDto,
  ResourceDetailedResponseDto,
  ResourceDto,
  ResourceGroupDto,
  ResourcesTimeSheetsResultDto,
  ResponseRecurringTaskDto,
  SearchTitlesItemResponseDto,
  SendInviteBodyDto,
  ShiftClosestDatesDto,
  ShiftEditBodyDto,
  ShiftsEditResponseDto,
  SizeDto,
  SubtaskChatMessageDto,
  SubtaskChatMessagesResponseListDto,
  SubtaskResponseDto,
  SystemNotificationResponseListDto,
  TaskNotificationDto,
  TaskUsageDto,
  TransferUserRoleDto,
  TruckingCompanyDto,
  UnreadCountDto,
  UnreadMessagesCountsResDto,
  UpdateEmailTemplateDto,
  UpdateResourceDto,
  UpdateResourceGroupOrderDto,
  UpdateStatusResultDto,
  UpdateTaskNotificationDto,
  UpdateUserReqDto,
  UserDto,
  UserInviteDetailsForRegistrationDto,
  UserInviteListItemResponseDto,
  UserInviteResponseDto,
  UserPartialUpdateDto,
  WorkOrderDto,
  WorkOrderResponseDto,
  WorkOrdersNumberDto,
} from 'types';

export default {
  system: {
    employeesValues: async () =>
      api.get<EmployeesValueDto[]>('/system/employees-values'),
  },
  auth: {
    signIn: async () => api.get<UserDto>('/users/current'),
    register: async (data: RegisterBodyDto) =>
      api.post<RegisterResponseDto>('/auth/register', data),
    registerWithInvite: async (data: RegisterByInviteBodyDto) =>
      api.post<RegisterResponseDto>('/auth/register-by-invite', data),
    login: async (data: LoginBodyDto) => api.post('/auth/login', data),
    verifyEmail: async ({ userId, code }: IEnsureVerifyEmailPayload) =>
      api.patch(`/users/${userId}/confirm-email`, { code }),
    validateInviteToken: async ({ token }: { token: string }) =>
      api.post('/auth/validate-email', { token }),
    resendCode: async (email: string) =>
      api.post('/auth/resend-confirmation-code', email),
  },
  getRoles: async () => api.get<string[]>('/users/roles'),
  user: {
    forgotPassword: async (data: ForgotPasswordBodyDto) =>
      api.post<void>('/users/forgot-password', data),
    changePassword: async (data: ChangePasswordBodyDto) =>
      api.patch<void>('/users/current/change-password', data),
    resetPassword: async ({ token, password }: IEnsureResetPasswordPayload) =>
      api.post(`/users/restore-password?token=${token}`, { password }),
    patchCurrentUser: async (data: UserPartialUpdateDto) =>
      api.patch<UserDto>(`/users/current`, data),
    getUsers: async params =>
      api.get<UserDto[]>('/users?sort=-firstName', params),
    postAvatar: async ({ id, file }: IEnsureAvatarPayload) =>
      api.post<FileUploadResDto>(`/users/${id}/upload`, file),
    postCurrentUserAvatar: async ({ file }: IEnsureCurrentUserAvatarPayload) =>
      api.post<FileUploadResDto>(`/users/current/upload`, file),
    getUser: async (id: string) => api.get<UserDto>(`/users/${id}`),
    putUser: async ({ id, data }: { id: string; data: UpdateUserReqDto }) =>
      api.put<UserDto>(`/users/${id}`, data),
    patchUser: async ({
      id,
      data,
    }: {
      id: string;
      data: UserPartialUpdateDto;
    }) => api.patch<UserDto>(`/users/${id}`, data),
    deleteUser: async (id: string) => api.delete<void>(`/users/${id}`),
    superAdmin: {
      delete: async (data: TransferUserRoleDto) =>
        api.delete<void>('/users/current', { data }),
      transfer: async (data: TransferUserRoleDto) =>
        api.patch<void>(
          `users/current/roles/super-administrator/transfer`,
          data
        ),
    },
  },
  locations: {
    checkAssignedData: async (id: string) =>
      api.get<{ hasAssignedData: boolean }>(
        `/locations/${id}/has-assigned-data`
      ),
    getLocations: async (params: AxiosRequestConfig<IEnsureLocationsParams>) =>
      api.get<LocationDto[]>('/locations', params),
    postLocation: async (data: CreateLocationDto) =>
      api.post<LocationDto>('/locations', data),
    getLocation: async (id: string) => api.get<LocationDto>(`/locations/${id}`),
    putLocation: async ({ id, data }: IEnsurePutLocationPayload) =>
      api.put<LocationDto>(`/locations/${id}`, data),
    patchLocation: async ({ id, data }: IEnsurePatchLocationPayload) =>
      api.patch<LocationDto>(`/locations/${id}`, data),
    deleteLocation: async ({
      id,
      transferTo = '',
    }: IEnsureDeleteLocationPayload) =>
      api.delete<string>(`/locations/${id}${transferTo}`),
    uploadMap: async ({ file }: { file: FormData }) =>
      api.put<FileUploadResDto>(`/locations/upload/map`, file),
    deleteMap: async (params: AxiosRequestConfig<{ path: string }>) =>
      api.delete<void>(`/locations/map`, params),
    uploadMapByLocation: async ({ id, file }: { id: string; file: FormData }) =>
      api.patch<FileUploadResDto>(`/locations/${id}/upload/map`, file),
    deleteMapByLocation: async (id: string) =>
      api.delete<string>(`/locations/${id}/map`),
  },
  invitations: {
    getInvitationsList: async () =>
      api.get<UserInviteListItemResponseDto[]>('/users/invitations/list'),
    inviteUser: async (data: SendInviteBodyDto) =>
      api.post<UserInviteResponseDto>('/users/invitations/send', data),
    deleteInvite: async (id: string) =>
      api.delete<void>(`/users/invitations/${id}`),
    getInvitationsDetails: async () =>
      api.get<UserInviteDetailsForRegistrationDto>(
        `/users/invitations/details-for-registration`
      ),
  },
  company: {
    getReportsList: async () => api.get<IReportItem[]>('/company/report-urls'),
    updateReport: async ({
      data,
      _id,
    }: {
      data: Partial<IReportItem>;
      _id: string;
    }) => api.patch<IReportItem>(`/company/report-urls/${_id}`, data),
    deleteReport: async ({ id }: { id: string }) =>
      api.delete(`/company/report-urls/${id}`),
    createReport: async (data: IReportItem) =>
      api.post<IReportItem>('/company/report-urls', data),
    getCompany: async () => api.get<CompanyResponseDto>('/company'),
    putFavicon: async (file: FormData) =>
      api.put<string>('/company/favicon', file),
    putCompany: async (data: CompanyDto) =>
      api.put<CompanyResponseDto>('/company', data),
    patchCompany: async (data: PartialUpdateCompanyReqDto) =>
      api.patch<CompanyResponseDto>('/company', data),
    postLogo: async (file: FormData) =>
      api.post<FileUploadResDto>(`/company/upload`, file),
    delete: async () => api.delete<void>('/company'),
    restore: async () => api.patch<void>('/company/restore'),
    registerNewCompany: async (data: CreateCompanyBodyDto) =>
      api.post<CreateCompanyResponseDto>('/company', data),
  },
  subtaskTypes: {
    subtaskTypes: async (params: AxiosRequestConfig) =>
      api.get<ISubtaskTypeDto[]>(
        '/subtask-types?sort=name&populate=checklistTemplate',
        params
      ),
    subtaskTypesDefaults: async () =>
      api.get<ISubtaskTypeDto[]>('/subtask-types/container-defaults'),
    postSubtaskTypes: async (data: ISubtaskTypeDto) =>
      api.post<ISubtaskTypeDto>('/subtask-types', data),
    getSubtaskType: async (id: string) =>
      api.get<ISubtaskTypeDto[]>(
        `/subtask-types/${id}?populate=checklistTemplate`
      ),
    putSubtaskType: async ({
      id,
      data,
    }: {
      id: string;
      data: ISubtaskTypeDto;
    }) => api.put<ISubtaskTypeDto>(`/subtask-types/${id}`, data),
    deleteSubtaskType: async (id: string) =>
      api.delete<void>(`/subtask-types/${id}`),
  },
  tasks: {
    tasks: async (params: AxiosRequestConfig) =>
      api.get<ITaskDto[]>('/tasks?sort=name', params),
    postTask: async (data: ITaskDto) => api.post<ITaskDto>('/tasks', data),
    putTask: async ({ id, data }: { id: string; data: ITaskDto }) =>
      api.put<ITaskDto>(`/tasks/${id}`, data),
    patchTask: async ({
      id,
      data,
      populate,
    }: {
      id: string;
      data: PartialUpdateTaskDto;
      populate: string;
    }) =>
      api.patch<ITaskDto>(
        `/tasks/${id}${populate ? `?populate=${populate}` : ''}`,
        data
      ),
    deleteTask: async (id: string) => api.delete<void>(`/tasks/${id}`),
    getTaskById: async (id: string) => api.get<ITaskDto>(`/tasks/${id}`),
    getRelatedTasks: async ({ id, fields }: IGetRelatedTasksPayload) =>
      api.get<ITaskDto[]>(`/tasks/${id}/related`, { params: { fields } }),
    containerOptions: async () =>
      api.get<ContainerOptionsDto>('/containers/options'),
    defaultContainerTemplate: async () =>
      api.get<ITaskDto>('/tasks/default-container-template'),
    exportTasksCsv: async ({ type, search }: IExportTasksCsvPayload) =>
      api.get<ITaskDto>(
        `/tasks-csv/export?type=${type}${search ? `&search=${search}` : ''}`,
        {
          responseType: 'blob',
        }
      ),
    exportSampleTasksCsv: async ({ type }: IExportTasksCsvPayload) =>
      api.get<ITaskDto>(`/tasks-csv/export-sample?type=${type}`, {
        responseType: 'blob',
      }),
    importTasksCsv: async (payload: IEnsureImportCsvPayload) =>
      api.post(`/tasks-csv/import`, payload.formData, {
        onUploadProgress: event => {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          payload.onProgress(percentCompleted);
        },
      }),
  },
  tasksNotifications: {
    getById: async (id: string) =>
      api.get<TaskNotificationDto>(`/task-notifications/${id}`),
    update: async (id: string, data: UpdateTaskNotificationDto) =>
      api.put(`/task-notifications/${id}`, data),
    create: async (data: CreateTaskNotificationDto) =>
      api.post<TaskNotificationDto>('/task-notifications', data),
    delete: async (id: string) => api.delete<void>(`/task-notifications/${id}`),
  },
  resources: {
    resources: async (params: AxiosRequestConfig) =>
      api.get<ListResourceResponseDto[]>('/resources?sort=fullName', params),
    putResource: async ({
      id,
      data,
    }: {
      id: string;
      data: UpdateResourceDto;
    }) => api.put<ListResourceResponseDto>(`/resources/${id}`, data),
    getResourceById: async (id: string) =>
      api.get<ResourceDetailedResponseDto>(`/resources/${id}`),
    postResource: async (data: ResourceDto) =>
      api.post<ListResourceResponseDto>('/resources', data),
    postResourcePhoto: async ({ id, file }: IEnsureUploadPhotoPayload) =>
      api.post<BaseFileUploadResDto>(`/resources/${id}/upload`, file),
    deleteResources: async (id: string) => api.delete(`/resources/${id}`),
    markAsActiveResource: async (id: string) =>
      api.patch(`/resources/${id}/mark-as-active`),
    markAsInactiveResource: async (id: string) =>
      api.patch(`/resources/${id}/mark-as-inactive`),
  },
  groups: {
    groups: async (params: AxiosRequestConfig) =>
      api.get<ResourceGroupDto[]>('/resource-groups', params),
    putGroup: async ({ id, data }: { id: string; data: ResourceGroupDto }) =>
      api.put<ResourceGroupDto>(`/resource-groups/${id}`, data),
    getGroup: async (id: string) =>
      api.get<ResourceGroupDto>(`/resource-groups/${id}`),
    postGroup: async (data: ResourceGroupDto) =>
      api.post<ResourceGroupDto>('/resource-groups', data),
    deleteGroup: async (id: string) =>
      api.delete<void>(`/resource-groups/${id}`),
    deleteResource: async (id: string) =>
      api.delete<void>(`/resources/${id}/group`),
    groupsOrder: async (data: UpdateResourceGroupOrderDto) =>
      api.patch<ResourceGroupDto>(`/resource-groups/order`, data),
    addResource: async ({
      id,
      data,
    }: {
      id: string;
      data: AddGroupResourceDto;
    }) => api.patch<ListResourceResponseDto>(`/resources/${id}/group`, data),
  },
  emailTemplates: {
    getEmailTemplates: async (params: AxiosRequestConfig) =>
      api.get<EmailTemplateDto>('/email-templates', params),
    getEmailTemplateById: async (id: string) =>
      api.get<EmailTemplateDto>(`/email-templates/${id}`),
    postEmailTemplate: async (data: CreateEmailTemplateDto) =>
      api.post<EmailTemplateDto>('/email-templates', data),
    updateEmailTemplate: async ({
      id,
      data,
    }: {
      id: string;
      data: UpdateEmailTemplateDto;
    }) => api.put<EmailTemplateDto>(`/email-templates/${id}`, data),
    deleteEmailTemplate: async (id: string) =>
      api.delete(`/email-templates/${id}`),
  },
  projects: {
    getProjects: async ({
      currentLocationId,
      sort,
      search,
      limit,
      skip,
      searchAll,
      tasksSort,
      containerSizes = [],
      containerSkus = [],
    }) =>
      api.get<ProjectsListResponseDto[]>(
        `/projects?${sort}&locationId=${currentLocationId}&search=${search}&limit=${limit}&skip=${skip}&sortInNested={"task.status": "${tasksSort}"}${searchAll ? '&archived=true&deleted=true' : ''
        }${containerSizes.length
          ? `&containerSize=${containerSizes.join(',')}`
          : ''
        }${containerSkus.length ? `&containerSku=${containerSkus.join(',')}` : ''
        }`
      ),
    getGantt: async ({
      currentLocationId,
      startDate,
      dueDate,
      search,
    }: IGetGanttPayload) =>
      api.get<GanttResourceDto>(
        `/projects/gantt?locationId=${currentLocationId}&startDate<=${dueDate}&dueDate>=${startDate}`,
        { params: { search } }
      ),
    getProject: async ({ id }: { id: string }) =>
      api.get<IProjectResponseDto>(`/projects/${id}`),
    postProject: async ({
      data,
      skipContainerUniqueness = false,
    }: {
      data: ProjectDto;
      skipContainerUniqueness?: boolean;
    }) =>
      api.post<IProjectResponseDto>(
        `/projects?skipContainerUniqueness=${skipContainerUniqueness}`,
        data
      ),
    postDuplicateProject: async ({
      data,
      skipContainerUniqueness = false,
    }: {
      data: ProjectDto;
      skipContainerUniqueness?: boolean;
    }) =>
      api.post<IProjectResponseDto[]>(
        `/projects/duplicate?skipContainerUniqueness=${skipContainerUniqueness}`,
        data
      ),
    putProject: async ({
      id,
      data,
      skipContainerUniqueness = false,
    }: IEnsurePutProjectPayload) =>
      api.put<IProjectResponseDto>(
        `/projects/${id}?skipContainerUniqueness=${skipContainerUniqueness}`,
        data
      ),
    patchProject: async ({
      id,
      data,
    }: {
      id: string;
      data: PartialUpdateProjectDto;
    }) => api.patch<IProjectResponseDto>(`/projects/${id}`, data),
    putAttachments: async (data: FormData[]) =>
      api.put<FileUploadResDto[]>(`/projects/files`, data),
    putAttachmentsById: async ({
      id,
      data,
    }: {
      id: string;
      data: FormData[];
    }) => api.put(`/projects/${id}/files`, data),
    deleteAttachments: async (params: AxiosRequestConfig<IAttachmentPayload>) =>
      api.delete<void>(`/projects/files?${params}`),
    markProjectAsDeleted: async (id: string) =>
      api.delete<void>(`/projects/${id}`),
    markProjectAsDone: async (id: string) =>
      api.patch<IProjectResponseDto>(`/projects/${id}/done`),
    markProjectAsArchived: async (id: string) =>
      api.patch<IProjectResponseDto>(`/projects/${id}/archived`),
    unarchiveProject: async (id: string) =>
      api.patch<IProjectResponseDto>(`/projects/${id}/unarchived`),
    deleteProjectFile: async ({ id, params }: { id: string; params: any }) =>
      api.delete<void>(`/projects/${id}/files`, { params }),
    invoicesSearch: async (params: AxiosRequestConfig<IInvoiceSearchParams>) =>
      api.get<ProjectInvoicesResponseDto>(`/projects/invoices/search`, params),
    restoreProject: async (id: string) =>
      api.patch<void>(`/projects/${id}/restore`),
  },
  subtasks: {
    getLogItems: async ({ id, sort }: IEnsureSubtaskLogItemsPayload) =>
      api.get<ChangelogSeparateListResponseDto>(
        `/subtasks/${id}/log-items?sort=${sort}`,
        undefined
      ),
    updateLogItems: async ({ id, logItemId, data }: IUpdateLogItemsPayload) =>
      api.patch<LogItemDto>(`/subtasks/${id}/log-items/${logItemId}`, data),
    assignSubtask: async ({ id, data, params }: IAssignSubtaskPayload) =>
      api.patch<ISubtaskDto>(`/subtasks/${id}/assign`, data, { params }),
    resizeSubtask: async ({ id, data }: IResizeSubtaskPayload) =>
      api.patch<ISubtaskDto>(`/subtasks/${id}/resize`, data),
    unassignSubtask: async (id: string) =>
      api.patch<IProjectResponseDto>(`/subtasks/${id}/unassign`),
    markSubtaskAsToDo: async ({ id }: IEnsureMarkSubtaskAsToDoPayload) =>
      api.patch<IProjectResponseDto>(`/subtasks/${id}/reset`),
    markSubtaskAsDone: async ({ id, params }) =>
      api.patch<IProjectResponseDto>(`/subtasks/${id}/done`, null, { params }),
    markSubtaskAsActive: async ({ id, params }: IMarkSubtaskAsActivePayload) =>
      api.patch<IProjectResponseDto>(`subtasks/${id}/active`, null, { params }),
    markSubtaskAsPaused: async ({
      id,
      params,
      percent,
    }: IMarkSubtaskAsPausedPayload) =>
      api.patch<IProjectResponseDto>(
        `subtasks/${id}/mark-as-paused`,
        { percent },
        { params }
      ),
    markSubtaskAsResumed: async ({
      id,
      params,
    }: IMarkSubtaskAsResumedPayload) =>
      api.patch<IProjectResponseDto>(`subtasks/${id}/mark-as-resumed`, null, {
        params,
      }),
    putSubtasksAttachments: async (
      data: IEnsurePutSubtasksAttachmentsPayload
    ) => api.put<FileUploadResDto>(`/subtasks/files`, data),
    deleteSubtaskFilesById: async ({
      id,
      params,
    }: {
      id: string;
      params: IAttachmentPayload;
    }) => api.delete<void>(`/subtasks/${id}/files`, { params }),
    deleteSubtaskFiles: async (params: IAttachmentPayload) =>
      api.delete<void>(`/subtasks/files`, { params }),
    markSubtaskAsNotReady: async (id: string) =>
      api.patch<IProjectResponseDto>(`subtasks/${id}/block`),
    addFileToSubtask: async ({ id, data }: { id: string; data: FormData[] }) =>
      api.put<SubtaskResponseDto>(`subtasks/${id}/files`, data),
    resourceExcel: async options =>
      api.get<string>(`/subtasks/generate-excel`, options),
    resourceExcelAll: async options =>
      api.get(`/subtasks/generate-excel-all`, options),
    deleteSubtasksChatMessages: async (params: { subtasksIds: string[] }) =>
      api.delete<void>('/subtasks/chat-messages', { params }),
    subtasksUnreadMessagesCount: async (body: { subtasksIds: string[] }) =>
      api.post<UnreadMessagesCountsResDto>(
        `/subtasks/chat-messages/unread/counts`,
        body
      ),
  },
  subtaskChatMessages: {
    get: async (id: string) =>
      api.get<SubtaskChatMessagesResponseListDto>(
        `/subtasks/${id}/chat-messages?limit=1000&sort=-createdAt`
      ),
    send: async ({ id, ...data }: IEnsureSendSubtaskMessagePayload) =>
      api.post<SubtaskChatMessageDto>(`/subtasks/${id}/chat-messages`, {
        ...data,
      }),
    sendReply: async ({ subtaskId, ...data }: IEnsureSendMessageReplyPayload) =>
      api.post<SubtaskChatMessageDto>(
        `/subtasks/${subtaskId}/chat-messages`,
        data
      ),
    delete: async (id: string) => api.delete(`/subtasks/${id}/chat-messages`),
    deleteMessage: async ({
      subtaskId,
      messageId,
    }: IEnsureDeleteSubtaskMessagePayload) =>
      api.delete<void>(`/subtasks/${subtaskId}/chat-messages/${messageId}`),
    updateMessage: async ({
      subtaskId,
      messageId,
      body,
    }: IEnsureUpdateSubtaskMessagePayload) =>
      api.patch<SubtaskChatMessageDto>(
        `/subtasks/${subtaskId}/chat-messages/${messageId}`,
        { body }
      ),
    markAsSeen: async (subtaskId: string) =>
      api.patch<void>(`/subtasks/${subtaskId}/chat-messages/mark-as-seen`),
  },
  subtaskChecklist: {
    get: async (subtaskTypeId: string) =>
      api.get<ChecklistTemplateItemDto[]>(
        `/checklist-templates/${subtaskTypeId}`
      ),
    create: async ({ subtaskTypeId, title }: CreateChecklistTemplateItemDto) =>
      api.post<ChecklistTemplateItemDto>(`/checklist-templates/`, {
        subtaskTypeId,
        title,
      }),
    bulk: async ({ checklist }: CreateChecklistTemplateDto) =>
      api.post<ChecklistTemplateItemDto>(`/checklist-templates/bulk`, {
        checklist,
      }),
    update: async ({
      id,
      title,
      position,
    }: IEnsureUpdateChecklistItemPayload) =>
      api.patch(`/checklist-templates/${id}`, { title, position }),
    delete: async (id: string) =>
      api.delete<void>(`/checklist-templates/${id}`),
    markAsCompleted: async ({
      subtaskId,
      checklistId,
    }: IEnsureMarkChecklistPayload) =>
      api.patch<UpdateStatusResultDto>(
        `/subtasks/${subtaskId}/checklist-items/${checklistId}/mark-as-completed`
      ),
    markAsUncompleted: async ({
      subtaskId,
      checklistId,
    }: IEnsureMarkChecklistPayload) =>
      api.patch<UpdateStatusResultDto>(
        `/subtasks/${subtaskId}/checklist-items/${checklistId}/mark-as-uncompleted`
      ),
    search: async (search: string) =>
      api.get<SearchTitlesItemResponseDto[]>(
        `/checklist-items/search?text=${search}`
      ),
  },
  systemNotifications: {
    get: async (params: AxiosRequestConfig<IGetSystemsNotificationsParams>) =>
      api.get<SystemNotificationResponseListDto[]>(
        '/system-notifications',
        params
      ),
    markAsRead: async (id: string) =>
      api.patch<UnreadCountDto>(`/system-notifications/${id}/mark-as-read`),
    markAsUnread: async (id: string) =>
      api.patch<UnreadCountDto>(`/system-notifications/${id}/mark-as-unread`),
    markAllAsRead: async () => api.patch(`/system-notifications/mark-as-read`),
    unreadCount: async () =>
      api.get<UnreadCountDto>(`/system-notifications/unread-count`),
    delete: async (id: string) => api.delete(`/system-notifications/${id}`),
  },
  result: {
    getResult: async (id: string) =>
      api.get<string[]>(`/subtasks/${id}/results`),
    patchApprove: async (id: string) =>
      api.patch<IProjectResponseDto>(`/subtasks/${id}/approve`),
    patchReject: async ({ id, data }: IEnsureRejectSubtaskPayload) =>
      api.patch<IProjectResponseDto>(`/subtasks/${id}/reject`, data),
  },
  inbound: {
    getWorkOrders: async (params: AxiosRequestConfig<{ locationId: string }>) =>
      api.get<WorkOrderDto[]>(`/work-orders`, params),
    getWorkOrder: async (id: string) =>
      api.get<WorkOrderResponseDto>(`/work-orders/${id}`),
    divideTasks: async ({ id, data }: IEnsureDivideTasksPayload) =>
      api.post<WorkOrderDto[]>(`/work-orders/${id}/bulk`, data),
    getWorkOrdersInfo: async (
      params: AxiosRequestConfig<{ locationId: string }>
    ) => api.get<WorkOrdersNumberDto>(`/work-orders/number`, params),
    createProjectFromWorkOrder: async ({
      id,
      data,
      skipContainerUniqueness = false,
    }: IEnsureCreateProjectFormWorkOrderPayload) =>
      api.post<IProjectResponseDto>(
        `/work-orders/${id}/project?skipContainerUniqueness=${skipContainerUniqueness}`,
        data
      ),
    deleteWorkOrder: async (id: string) =>
      api.delete<void>(`/work-orders/${id}`),
  },
  colors: {
    getColors: async () =>
      api.get<ColorDto>('/depot-settings/colors?sort=color'),
    postColors: async (data: ColorDto) =>
      api.post<ColorDto>('/depot-settings/colors', data),
    deleteColor: async (id: string) =>
      api.delete<void>(`/depot-settings/colors/${id}`),
  },
  sizes: {
    getSizes: async () => api.get<SizeDto[]>('/depot-settings/sizes?sort=type'),
    getSizeById: async (id: string) =>
      api.get<SizeDto>(`/depot-settings/sizes/${id}`),
    postSize: async (data: SizeDto) =>
      api.post<SizeDto>('/depot-settings/sizes', data),
    putSize: async ({ id, data }: IEnsureEditSizePayload) =>
      api.put(`/depot-settings/sizes/${id}`, data),
    deleteSize: async (id: string) =>
      api.delete<void>(`/depot-settings/sizes/${id}`),
  },
  conditions: {
    getConditions: async () =>
      api.get<ConditionDto[]>('/depot-settings/conditions?sort=type'),
    getConditionById: async (id: string) =>
      api.get<ConditionDto>(`/depot-settings/conditions/${id}`),
    postCondition: async (data: ConditionDto) =>
      api.post<ConditionDto>('/depot-settings/conditions', data),
    putCondition: async ({ id, data }: IEnsureEditConditionPayload) =>
      api.put<ConditionDto>(`/depot-settings/conditions/${id}`, data),
    deleteCondition: async (id: string) =>
      api.delete<void>(`/depot-settings/conditions/${id}`),
  },
  grades: {
    getGrades: async () =>
      api.get<GradeDto[]>('/depot-settings/grades?sort=type'),
    getGradeById: async (id: string) =>
      api.get<GradeDto>(`/depot-settings/grades/${id}`),
    postGrade: async (data: GradeDto) =>
      api.post<GradeDto>('/depot-settings/grades', data),
    putGrade: async ({ id, data }: IEnsureEditGradePayload) =>
      api.put<GradeDto>(`/depot-settings/grades/${id}`, data),
    deleteGrade: async (id: string) =>
      api.delete<void>(`/depot-settings/grades/${id}`),
  },
  truckingCompanies: {
    getTruckingCompanies: async () =>
      api.get<TruckingCompanyDto[]>('/depot-settings/companies?sort=company'),
    getTruckingCompanyById: async (id: string) =>
      api.get<TruckingCompanyDto>(`/depot-settings/companies/${id}`),
    postTruckingCompany: async (data: TruckingCompanyDto) =>
      api.post<TruckingCompanyDto>('/depot-settings/companies', data),
    putTruckingCompany: async ({
      id,
      data,
    }: IEnsureEditeTruckingCompanyPayload) =>
      api.put<TruckingCompanyDto>(`/depot-settings/companies/${id}`, data),
    deleteTruckingCompany: async (id: string) =>
      api.delete<void>(`/depot-settings/companies/${id}`),
  },
  inventory: {
    getInventory: async (params: AxiosRequestConfig<{ search: string }>) =>
      api.get<TaskUsageDto[]>('/inventory', params),
    inventoryPdf: async (options: AxiosRequestConfig<{ search: string }>) =>
      api.get<void>('/inventory/pdf', options),
  },
  sharingProject: {
    getToken: async (id: string) =>
      api.post<GeneratedApiKeyDto>(`/projects/${id}/sharing`),
    getGardByToken: async (id: string) =>
      api.get<IProjectResponseDto>(`/projects/${id}/sharing`),
    deleteToken: async (id: string) =>
      api.delete<void>(`/projects/${id}/sharing`),
    sendLink: async (data: EmailDto) => api.post<void>(`/mail`, data),
  },
  subcontractors: {
    delegate: async (id: string) =>
      api.patch<GeneratedApiKeyDto>(`/subtasks/${id}/delegate`),
    revoke: async (id: string) => api.patch<void>(`/subtasks/${id}/revoke`),
    getDelegatedSubtask: async (id: string) =>
      api.get<SubtaskResponseDto>(`/subcontractors/subtasks/${id}`),
    startSubtask: async (id: string) =>
      api.patch<ISubtaskDto>(`/subcontractors/subtasks/${id}/start`),
    attachToSubtask: async ({ id, data }: { id: string; data: FormData }) =>
      api.patch<ISubtaskDto>(`/subcontractors/subtasks/${id}/attach`, data),
    sendSubtaskToReview: async (id: string) =>
      api.patch<ISubtaskDto>(`/subcontractors/subtasks/${id}/validate`),
    pause: async ({ id, percent }: MarkSubtaskAsPausedPayload) =>
      api.patch<ActionWithSubtaskResponseDto>(
        `/subcontractors/subtasks/${id}/pause`,
        { percent }
      ),

    markSubtaskAsDone: async (id: string) =>
      api.patch<ISubtaskDto>(`/subcontractors/subtasks/${id}/finish`),
  },
  standaloneSubtasks: {
    postSubtask: async (data: any) =>
      api.post<ISubtaskDto>(
        `/standalone-subtasks`,
        { ...data.data },
        { params: data.params }
      ),
    getSubtask: async (id: string) =>
      api.get<SubtaskResponseDto>(`/standalone-subtasks/${id}`),
    putSubtask: async ({ id, data }: IEnsurePutStandaloneSubtaskPayload) =>
      api.put<ISubtaskDto>(`/standalone-subtasks/${id}`, data),
    deleteSubtask: async (id: string) =>
      api.delete<ISubtaskDto>(`/standalone-subtasks/${id}`),
  },
  recurringTasks: {
    postRecurringTask: async (data: CreateRecurringTaskDto) =>
      api.post<ResponseRecurringTaskDto>('recurring-tasks', data),
    getRecurringTasks: async params =>
      api.get<ResponseRecurringTaskDto>('recurring-tasks', params),
    getRecurringTaskById: async (id: string) =>
      api.get<ResponseRecurringTaskDto>(`recurring-tasks/${id}`),
    putRecurringTask: async ({
      id,
      data,
    }: {
      id: string;
      data: CreateRecurringTaskDto;
    }) => api.put<ResponseRecurringTaskDto>(`/recurring-tasks/${id}`, data),
    deleteRecurringTask: async (id: string) =>
      api.delete<void>(`/recurring-tasks/${id}`),
  },
  // timesheets
  timesheets: {
    getTimesheets: async (params: AxiosRequestConfig<ITimesheetsParams>) =>
      api.get<ResourcesTimeSheetsResultDto>(
        '/shifts/resources-timesheets',
        params
      ),
    getResourceTimesheets: async ({
      id,
      params,
    }: {
      id: string;
      params: AxiosRequestConfig<ITimesheetsParams>;
    }) =>
      api.get<ResourcesTimeSheetsResultDto>(
        `/shifts/resources-timesheets/${id}`,
        { params }
      ),
    getShiftClosestDates: async ({
      id,
      params,
    }: {
      id: string;
      params: AxiosRequestConfig<{ locationId: string }>;
    }) =>
      api.get<ShiftClosestDatesDto>(`/shifts/closest-dates/${id}`, { params }),
    editResourceTime: async ({
      id,
      data,
    }: {
      id: string;
      data: ShiftEditBodyDto;
    }) => api.patch<ShiftsEditResponseDto>(`/shifts/${id}`, data),
    deleteShift: async (id: string) => api.delete<void>(`/shifts/${id}`),
    getResourceTimesheetsExcel: async ({
      id,
      options,
    }: {
      id: string;
      options: AxiosRequestConfig<IResourceTimesheetsExcelOptions>;
    }) =>
      api.get<void>(`/shifts/resources-timesheets/${id}/export/excel`, options),
    getTimesheetsExcel: async (
      options: AxiosRequestConfig<IProfitPreEmployeesOptions>
    ) => api.get<void>('/shifts/resources-timesheets/export/excel', options),
  },
  // reports
  reports: {
    getProfitPreInvoice: async (
      params: AxiosRequestConfig<IProfitPreInvoiceParams>
    ) => api.get<InvoiceProfitDto>('/reports/profit-per-invoice', params),
    getProfitPreEmployees: async (
      params: AxiosRequestConfig<IProfitPreEmployeesParams>
    ) =>
      api.get<EmployeesProgressItemResponseDto>(
        '/reports/planned-vs-actual-time-per-employee',
        params
      ),
    getResourceReport: async ({
      id,
      params,
    }: {
      id: string;
      params: AxiosRequestConfig<ITimesheetsParams>;
    }) =>
      api.get<EmployeesProgressItemResponseDto>(
        `/reports/planned-vs-actual-time-per-employee/${id}`,
        { params }
      ),
  },
  files: {
    download: async (filePath: string) =>
      api.get<void>('/storage/download', {
        params: { path: filePath },
        responseType: 'blob',
      }),
  },
};
