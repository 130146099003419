import { SVGProps } from 'react';

export function BarcodeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={9}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.571 0A.571.571 0 000 .571V8.43C0 8.744.256 9 .571 9H15.43A.571.571 0 0016 8.429V.57A.571.571 0 0015.429 0H.57zM3 1h1v7H3V1zm4 0H6v7h1V1zm2 0h1v7H9V1zm4 0h-1v7h1V1z"
        fill="#172B4D"
      />
    </svg>
  );
}
