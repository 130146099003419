import { useMemo } from 'react';
import Modal from 'components/Modal';
import { getFilePreviewUrl } from 'lib/constants';
import { getProjectCoverUrl } from 'utils/images';
import { IAttachmentInfo } from 'types';

interface IPreviewProps {
  closePreview: (e) => void;
  showPreview: boolean;
  file?: Partial<IAttachmentInfo>;
  isBlob: boolean;
}
const Preview = ({
  closePreview,
  showPreview,
  file,
  isBlob,
}: IPreviewProps) => {
  const isPdf =
    !file?.fileUrl || !file?.originalName
      ? false
      : file.originalName.endsWith('.pdf');

  const showIframe = isBlob || isPdf;

  const previewUrl = useMemo(
    () => getFilePreviewUrl(file, getProjectCoverUrl),
    [file]
  );

  return (
    <Modal
      visible={showPreview}
      onCancel={closePreview}
      width={showIframe ? 1200 : 'auto'}
      zIndex={2000}
      padding="31px 57px 40px"
    >
      {showIframe ? (
        <iframe
          title="download-pdf"
          src={file?.fileUrl || null}
          // @ts-ignore
          align="middle"
          frameBorder={0}
          height="750px"
          width="100%"
          scrolling="no"
        >
          Press me: <a href={file?.fileUrl || null}>Download PDF</a>
        </iframe>
      ) : (
        <img
          style={{ maxHeight: '80vh', height: '100%', maxWidth: '100%' }}
          src={previewUrl || null}
          alt={'file'}
        />
      )}
    </Modal>
  );
};

Preview.defaultProps = {
  closePreview: null,
  showPreview: false,
  file: {},
  isBlob: false,
};

export default Preview;
