import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import { currentLocation, userSelector } from 'store/auth';
import * as A from 'store/auth/actions';
import { companySelector, ensureCompany } from 'store/company';
import { actions } from 'store/loading';
import * as Router from 'store/router';
import * as Sharing from 'store/sharing';
import { NOT_VALID_INVITATION } from './constants';
import Api from 'utils/api';
import { message } from 'utils/message';
import storageManager from 'utils/storageManager';
import {
  ForgotPasswordBodyDto,
  IEnsureResetPasswordPayload,
  IEnsureVerifyEmailPayload,
  LoginBodyDto,
  RegisterBodyDto,
  RegisterByInviteBodyDto,
  RegisterResponseDto,
  UserDto,
} from 'types';

export function* ensureSignInFailure() {
  yield put(push(Router.ROUTES.HOME));
}

export function* ensureLoginUser() {
  try {
    const location = yield select(Router.location);
    const {
      query: { jwt },
    } = location;

    yield storageManager.setToken(jwt);
    yield call(ensureSignIn);
  } catch (err) {
    yield put(A.signIn.failure(err));
  }
}

export function* ensureSharingProject() {
  try {
    const {
      params: { id, jwt },
    } = yield select(Router.matchRoutes);
    storageManager.setApiKey(jwt);
    const data = yield call(Api.sharingProject.getGardByToken, id);
    yield put(Sharing.getProjectByToken.success(data));
  } catch (err) {
    yield put(Sharing.getProjectByToken.failure());
    yield message.error(['Invalid link']);
  }
}

export function* ensureCurrentLocation() {
  const currentStoreLocation = storageManager.getLocation();
  const user = yield select(userSelector);
  const company = yield select(companySelector);
  const companyLocation = company.locations.find(
    location => location._id === currentStoreLocation
  );

  if (!currentStoreLocation || !companyLocation) {
    yield put(currentLocation(user.locationId));
    storageManager.setLocation(user.locationId);
  } else {
    yield put(currentLocation(currentStoreLocation));
  }

  yield call(Sharing.ensureGetSystemNotificationsUnreadCount);
}

export function* ensureSignIn() {
  try {
    const jwt = storageManager.getToken();
    if (jwt) {
      const data: UserDto = yield call(Api.auth.signIn);
      const location = yield select(Router.location);
      const locationCompany = Router.ROUTES.SETTINGS_COMPANY_INFO;
      if (locationCompany !== location?.pathname) yield call(ensureCompany);
      yield put(A.signIn.success(data));
    }
  } catch (err) {
    yield put(A.signIn.failure(err));
  } finally {
    yield put(actions.loading(false));
  }
}

export function* ensureSignOut() {
  try {
    storageManager.clearAuthData();
    yield put(A.signOut.success());
  } catch (err) {
    yield put(A.signOut.failure(err));
  }
}

export function* ensureSignUp({
  payload,
}: {
  payload: RegisterBodyDto;
  type: typeof A.signUp.TRIGGER;
}) {
  try {
    const data = yield call(Api.auth.register, payload);
    yield put(A.signUp.success(data));
  } catch (err) {
    yield put(A.signUp.failure(err));
  }
}

export function* ensureLogin({
  payload,
}: {
  payload: LoginBodyDto;
  type: typeof A.login.TRIGGER;
}) {
  try {
    const data = yield call(Api.auth.login, payload);
    yield put(A.login.success(data));
  } catch (err) {
    yield put(A.login.failure(err));
  }
}

export function* ensureForgotPassword({
  payload,
}: {
  payload: ForgotPasswordBodyDto;
  type: typeof A.forgotPassword.TRIGGER;
}) {
  try {
    const data = yield call(Api.user.forgotPassword, payload);
    yield put(A.forgotPassword.success(data));
  } catch (err) {
    yield put(A.forgotPassword.failure(err));
  }
}

export function* ensureResetPassword({
  payload,
}: {
  payload: IEnsureResetPasswordPayload;
  type: typeof A.resetPassword.TRIGGER;
}) {
  try {
    yield call(Api.user.resetPassword, payload);
    yield put(A.resetPassword.success());
  } catch (err) {
    yield put(A.resetPassword.failure(err));
  }
}

export function* ensureVerifyEmail({
  payload,
}: {
  payload: IEnsureVerifyEmailPayload;
  type: typeof A.verifyEmail.TRIGGER;
}) {
  try {
    yield call(Api.auth.verifyEmail, payload);
    yield put(A.verifyEmail.success());
  } catch (err) {
    yield put(A.verifyEmail.failure(err));
  }
}

export function* ensureValidateInviteToken({
  payload,
}: {
  payload: string;
  type: typeof A.validateInvite.TRIGGER;
}) {
  try {
    storageManager.setApiKey(payload);
    const data = yield call(Api.invitations.getInvitationsDetails);

    yield put(A.validateInvite.success(data));
  } catch (err) {
    yield put(
      A.validateInvite.failure({
        ...err,
        data: { ...err.data, message: NOT_VALID_INVITATION },
      })
    );
  }
}

export function* ensureResendVerificationCode({
  payload,
}: {
  payload: string;
  type: typeof A.resendVerificationCode.TRIGGER;
}) {
  try {
    yield call(Api.auth.resendCode, payload);
    yield put(A.resendVerificationCode.success());
  } catch (err) {
    yield put(A.resendVerificationCode.failure(err));
  }
}

export function* ensureSignUpWithInvite({
  payload,
}: {
  payload: RegisterByInviteBodyDto;
  type: typeof A.signUpWithInvite.TRIGGER;
}) {
  try {
    const data: RegisterResponseDto = yield call(
      Api.auth.registerWithInvite,
      payload
    );
    yield storageManager.removeApiKey();
    yield put(A.signUpWithInvite.success(data));
  } catch (err) {
    yield put(A.signUpWithInvite.failure(err));
  }
}
