import { CSSProperties, memo } from 'react';
// components
import { Error, Label } from 'components/common';
// icons
import { Cross } from 'lib/icons';
import { TextAreaProps } from 'antd/lib/input';
// styles
import { TextAreaStyled } from './styles';

interface ITextAreaProps extends TextAreaProps {
  placeholder?: string;
  defaultValue?: string;
  label?: string | Element;
  name?: string;
  value?: string | number;
  width?: string;
  disabled?: boolean;
  style?: CSSProperties;
  error?: string;
  rows?: number;
  closeClick?: () => void;
}

export const TextArea = memo(
  ({
    placeholder,
    defaultValue,
    label,
    name,
    onChange,
    value,
    width,
    error,
    disabled,
    style,
    rows = 4,
    closeClick,
  }: ITextAreaProps) => (
    <div style={{ ...style, width, position: 'relative' }}>
      {label && <Label>{label}</Label>}
      {closeClick && (
        <Cross
          style={{
            width: 9,
            height: 9,
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          color="#737F94"
          onClick={closeClick}
        />
      )}
      <TextAreaStyled
        {...{
          rows,
          autoSize: {
            minRows: rows,
            maxRows: rows,
          },
          type: 'text',
          defaultValue,
          placeholder,
          name,
          id: name,
          onChange,
          value,
          width,
          error,
          disabled,
        }}
      />
      {error && <Error>{error}</Error>}
    </div>
  )
);
