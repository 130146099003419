import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* emailTemplatesSaga() {
  yield all([
    fork(W.watchGetTemplates),
    fork(W.watchCreateTemplate),
    fork(W.watchDeleteTemplate),
    fork(W.watchGetTemplateById),
    fork(W.watchUpdateTemplate),
  ]);
}
