import styled, { css } from 'styled-components';

const cellStyles = css<{
  inResource?: boolean;
  width?: number;
  flexEnd?: boolean;
  isTotal?: boolean;
  leghter?: boolean;
}>`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  display: flex;
  box-sizing: border-box;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
  padding: 0 16px 0 16px;
  align-items: center;
  min-height: 100% !important;
  overflow-wrap: anywhere;
  font-size: 13px;
  /* height: 47px; */
  line-height: 16px;
  color: ${({ leghter, theme }) =>
    leghter ? theme.colors.lightBlue : theme.colors.mainDarkBlue};
  font-weight: ${({ isTotal }) => (isTotal ? 'bold' : 'normal')};
  ${({ inResource, theme }) =>
    inResource &&
    css`
      color: ${theme.colors.lightBlue};
    `}/* ${css`
    @media (max-width: 1615px) {
      width: 90px;
    }
  `} */
`;

export const Cell = styled.div`
  ${cellStyles}

  .ant-select-selector {
    padding: 0 7px !important;
  }
`;

export const HeaderCell = styled.div<{
  inResource?: boolean;
  width?: number;
  flexEnd?: boolean;
  isTotal?: boolean;
  leghter?: boolean;
}>`
  ${cellStyles}
  font-weight: 600;
  padding: 20px 16px 11px 16px;
`;
