import { call, put, select } from 'redux-saga/effects';
import { companiesSelector } from 'store/depot';
import { actions } from 'store/loading';
import * as Actions from './actions';
import Api from 'utils/api';
import {
  IEnsureEditeTruckingCompanyPayload,
  ILoaderPayload,
  TruckingCompanyDto,
} from 'types';

export function* ensureTruckingCompanies({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof Actions.truckingCompanies.TRIGGER;
}) {
  const companiesList = yield select(companiesSelector);
  const noLoader = payload?.noLoader || companiesList?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.truckingCompanies.getTruckingCompanies);
    yield put(Actions.truckingCompanies.success(data));
  } catch (err) {
    yield put(Actions.truckingCompanies.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureTruckingCompaniesById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getTruckingCompanyById.TRIGGER;
}) {
  try {
    const data = yield call(
      Api.truckingCompanies.getTruckingCompanyById,
      payload
    );
    yield put(Actions.getTruckingCompanyById.success(data));
  } catch (err) {
    yield put(Actions.getTruckingCompanyById.failure(err));
  }
}

export function* ensureAddTruckingCompany({
  payload,
}: {
  payload: TruckingCompanyDto;
  type: typeof Actions.addTruckingCompany.TRIGGER;
}) {
  try {
    const res = yield call(Api.truckingCompanies.postTruckingCompany, payload);
    yield call(ensureTruckingCompanies, {
      payload: { noLoader: true },
      type: Actions.truckingCompanies.TRIGGER,
    });
    yield put(Actions.addTruckingCompany.success(res));
  } catch (err) {
    yield put(Actions.addTruckingCompany.failure(err));
  }
}

export function* ensureDeleteTruckingCompany({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteTruckingCompany.TRIGGER;
}) {
  try {
    yield call(Api.truckingCompanies.deleteTruckingCompany, payload);
    yield call(ensureTruckingCompanies, {
      payload: { noLoader: true },
      type: Actions.truckingCompanies.TRIGGER,
    });
    yield put(Actions.deleteTruckingCompany.success());
  } catch (err) {
    yield put(Actions.deleteTruckingCompany.failure(err));
  }
}

export function* ensureEditeTruckingCompany({
  payload,
}: {
  payload: IEnsureEditeTruckingCompanyPayload;
  type: typeof Actions.editTruckingCompany.TRIGGER;
}) {
  try {
    const data = yield call(Api.truckingCompanies.putTruckingCompany, payload);
    yield call(ensureTruckingCompanies, {
      payload: { noLoader: true },
      type: Actions.truckingCompanies.TRIGGER,
    });
    yield put(Actions.editTruckingCompany.success(data));
  } catch (err) {
    yield put(Actions.editTruckingCompany.failure(err));
  }
}
