import { CSSProperties, memo } from 'react';
import { RequiredSign } from '../RequiredSign';
import { Error, Label } from 'components/common';
import { InputProps } from 'antd';
// styles
import { InputStyled } from './styles';

interface IInputProps extends InputProps {
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  label?: string | JSX.Element;
  name?: string;
  value?: string | number;
  width?: string;
  height?: string;
  disabled?: boolean;
  isRequired?: boolean;
  autocomplete?: boolean;
  bordered?: boolean;
  style?: CSSProperties;
  error?: string;
  id?: string;
  className?: string;
  maxLength?: number;
}

export const Input = memo(
  ({
    placeholder,
    defaultValue,
    label,
    name,
    onChange,
    value,
    width,
    height,
    error,
    disabled,
    style,
    id,
    maxLength,
    className,
    isRequired,
    onFocus,
    onBlur,
    onKeyDown,
    bordered,
    type = 'text',
  }: IInputProps) => (
    <div style={{ ...style, width }}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {isRequired && <RequiredSign> *</RequiredSign>}
        </Label>
      )}
      <InputStyled
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        {...{
          type,
          defaultValue,
          placeholder,
          bordered,
          name,
          maxLength,
          id: id || name,
          onChange,
          value,
          width,
          height,
          error,
          disabled,
          autoComplete: 'off',
          className,
        }}
      />
      {error && <Error>{error}</Error>}
    </div>
  )
);
