import { SVGProps } from 'react';

function SubDirectoryArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={11}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.666 8l-4 4-.946-.947 2.393-2.387H.667v-8H2v6.667h6.113L5.72 4.946l.947-.947 4 4z"
        fill="#737F94"
      />
    </svg>
  );
}

export default SubDirectoryArrowIcon;
