import { Tooltip } from 'antd';
import { ReplyBody } from './styles';

interface ReplyMessageBodyProps {
  replyMessage: string;
}

const LETTERS_LIMIT = 100;

const ReplyMessageBody = ({ replyMessage }: ReplyMessageBodyProps) => (
  <>
    {replyMessage.length >= LETTERS_LIMIT ? (
      <Tooltip
        title={replyMessage}
        placement="left"
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        <ReplyBody>{replyMessage.slice(0, LETTERS_LIMIT).trim()}...</ReplyBody>
      </Tooltip>
    ) : (
      <ReplyBody>{replyMessage}</ReplyBody>
    )}
  </>
);

export default ReplyMessageBody;
