import { Dispatch, FC, memo } from 'react';
import isEqual from 'react-fast-compare';
import { TaskActions } from 'components/TaskActions';
import { TaskShortInfo } from 'components/TaskShortInfo';
import ReadMore from 'components/common/ReadMore/ReadMore';
import EditTask from 'pages/Dashboard/Project/ProjectView/Tasks/EditTask';
import BodyBlock from 'pages/Dashboard/Project/ProjectView/Tasks/TaskInfo/TaskBlock/BodyBlock';
import { Divider } from 'antd';
import * as Styled from './styles';
import { IProjectTaskDto, TaskInfoProps } from 'types';

interface SubtaskModeTaskProps extends TaskInfoProps {
  task: IProjectTaskDto;
  tasksList: IProjectTaskDto[];
  index: number;
  isProjectEditing?: boolean;
  setEditData: Dispatch<any>;
  toggleItemTemplateModal: () => void;
}

const TaskComponent: FC<SubtaskModeTaskProps> = ({
  task,
  openNotify,
  setIsOpenEdit,
  tasksList,
  index,
  isProjectEditing,
  setEditData,
  toggleItemTemplateModal,
  ...props
}) => {
  const findOriginalIndex = tasksList.findIndex(item => item._id === task._id);
  const findOriginalTask = tasksList[findOriginalIndex];

  if (props.openTaskIndex === index) {
    return (
      <EditTask
        index={findOriginalIndex}
        item={findOriginalTask}
        isDisabledDelete={props.isDisabledDelete}
        removeTask={props.removeTask}
        subtaskTypes={props.subtaskTypes}
        onSubmit={props.onSubmit}
        notAvailableProject={props.notAvailableProject}
        setIsOpenEdit={setIsOpenEdit}
        isSingle={props.isProjectView}
        projectId={props.project?._id}
      />
    );
  }

  return (
    <Styled.TaskWrapper>
      <Styled.Top>
        <Styled.TaskHeader>
          <div>
            <Styled.TaskTitle>{task.name}</Styled.TaskTitle>
            <div>
              <TaskShortInfo
                isOpenInfo={true}
                sku={task.sku}
                cost={task.cost}
                price={task.price}
                quantity={task.quantity}
              />
            </div>
          </div>
          <Styled.Actions>
            <TaskActions
              tasksCount={tasksList.length}
              item={findOriginalTask}
              index={index}
              openNotify={openNotify}
              setIsOpenEdit={setIsOpenEdit}
              isProjectEditing={isProjectEditing}
              setEditData={setEditData}
              toggleItemTemplateModal={toggleItemTemplateModal}
            />
          </Styled.Actions>
        </Styled.TaskHeader>
        {task.description && (
          <Styled.Description>
            <ReadMore limit={197} text={task.description} />
          </Styled.Description>
        )}
      </Styled.Top>
      <Divider />
      <BodyBlock
        item={task}
        editProject={props.editProject}
        index={findOriginalIndex}
        setIsOpenEdit={setIsOpenEdit}
        color={props.color}
        invoice={props.invoice}
        project={props.project}
        openCheckResult={props.openCheckResult}
        toggleParent={props.toggleParent}
        isOpenInfo={true}
        setShouldUpdate={props.setShouldUpdate}
        locationId={props.locationId}
        notAvailableProject={props.notAvailableProject}
        updateChecklist={props.updateChecklist}
        isProjectViewTaskMode
        isProjectEditing={isProjectEditing}
      />
    </Styled.TaskWrapper>
  );
};

export const Task = memo(TaskComponent, isEqual);
