export const ProjectsIcon = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6V19.97L7.5 20H6.78H6L2 19.97V6H12ZM16 0H5.99C4.89 0 4 0.9 4 2H14C15.1 2 16 2.9 16 4V17L18 18V2C18 0.9 17.1 0 16 0ZM12 4H2C0.9 4 0 4.9 0 6V20C0 21.1046 0.895431 22 2 22H6.78H12C13.1046 22 14 21.1046 14 20V6C14 4.9 13.1 4 12 4Z"
        fill="#C8DBFC"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
