import { BUTTON_TYPES, ConfirmationPopover } from 'components/common';
// constants
import { PROJECTS_STATUSES } from 'lib/constants/projects';
import { DoubleCheckIcon } from 'lib/icons';
import { SetStatusPopover } from 'pages/Dashboard/Project/ProjectsList/Item/ProjectContent/SetStatusPopover';
// styles
import { ButtonStyled, Status, StatusWrap, UndoButton } from './styles';
import { IProjectResponseDto } from 'types';

interface IStatusBlockProps {
  data: IProjectResponseDto;
  markProjectAsReadyLoading: boolean;
  notAvailableProject: boolean;
  markAsReady: () => void;
  restoreProject: () => void;
  isRestoreProjectLoading: boolean;
}

const StatusBlock = ({
  data: { status },
  data,
  markProjectAsReadyLoading,
  markAsReady,
  notAvailableProject,
  restoreProject,
  isRestoreProjectLoading,
}: IStatusBlockProps) => (
  <StatusWrap>
    <SetStatusPopover data={data} notAvailableProject={notAvailableProject} />

    {status !== PROJECTS_STATUSES.DONE && !notAvailableProject && (
      <ConfirmationPopover
        loading={markProjectAsReadyLoading}
        text="Are you sure?"
        onOk={markAsReady}
      >
        <ButtonStyled
          label="Mark project as ready"
          icon={<DoubleCheckIcon />}
        />
      </ConfirmationPopover>
    )}
    {status === PROJECTS_STATUSES.DELETED && (
      <ConfirmationPopover
        loading={isRestoreProjectLoading}
        text="Are you sure?"
        onOk={restoreProject}
      >
        <UndoButton kind={BUTTON_TYPES.SECONDARY} label="Undo" />
      </ConfirmationPopover>
    )}
  </StatusWrap>
);

export default StatusBlock;
