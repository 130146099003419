import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import * as Actions from './actions';
import { ICompanyResponseDto } from 'types';

const company = createReducer(initialState.company, {
  [Actions.administrators.SUCCESS]: (state, action) => {
    state.administrators = action.payload.sort((a, b) =>
      a.isActive > b.isActive ? 1 : -1
    );
  },

  [Actions.getEmployeesValues.SUCCESS]: (state, action) => {
    state.employeesValues = action.payload.map(item => ({
      _id: item._id,
      type: item.title,
      value: item._id,
    }));
  },
  [Actions.updateUserAdmin.SUCCESS]: (state, action) => {
    state.admin = action.payload;
  },
  [Actions.uploadFavicon.SUCCESS]: (state, { payload }) => {
    state.companyInfo = { ...state.companyInfo, faviconUrl: payload.fileUrl };
  },
  [Actions.getUserAdmin.SUCCESS]: (state, action) => {
    state.admin = action.payload;
  },

  [String(Actions.clearUserAdmin)]: state => {
    state.admin = null;
  },
  [Actions.updateAvatarAdmin.SUCCESS]: (state, action) => {
    state.admin = { ...state.admin, photoUrl: action.payload?.fileUrl };
  },
  [Actions.getCompany.SUCCESS]: (state, action) => {
    state.companyInfo = action.payload;
  },
  [Actions.uploadLogo.SUCCESS]: (state, action) => {
    state.companyInfo = {
      ...state.companyInfo,
      logoUrl: action.payload?.fileUrl,
    };
  },
  [Actions.updateCompany.SUCCESS]: (
    state,
    { payload }: { payload: ICompanyResponseDto }
  ) => {
    state.companyInfo = payload;
  },
  [Actions.restoreCompany.SUCCESS]: state => {
    state.companyInfo = {
      ...state.companyInfo,
      deleted: false,
    };
  },
  [Actions.registerNewCompany.SUCCESS]: (state, { payload }) => {
    state.companyInfo = {
      ...payload.company,
      locations: [{ ...payload.location }],
    };
  },
});

export { company as reducer };
