import styled from 'styled-components';

export const InfoWrap = styled.div`
  padding: 0 17px 9px;
  user-select: none;
`;

export const Description = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 16px;
`;
