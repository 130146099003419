import { useSelector } from 'react-redux';
import RestoreModal from './RestoreModal/RestoreModal';
import { signOut, userSelector } from 'store/auth';
import { companySelector, restoreCompanyPromiseCreator } from 'store/company';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActions } from 'hooks';
import { useRequest } from 'hooks/useRequest';
import {
  CompanyInfo,
  CompanyLogo,
  Content,
  DividerItem,
  Text,
  Title,
} from './styles';

const Restore = () => {
  const handleLogOut = useActions(signOut);
  const [handleRestoreCompany, isRestoring] = useRequest(
    restoreCompanyPromiseCreator
  );
  const { firstName, lastName } = useSelector(userSelector);
  const company = useSelector(companySelector);

  return (
    <AuthWrapper isLarge>
      <Content>
        <Title>
          Welcome {firstName} {lastName}
        </Title>
        <CompanyInfo>
          {company.logoUrl && <CompanyLogo src={company.logoUrl} />}
          {company.name}
        </CompanyInfo>
        <Text>
          Your company account has been deleted. If you want to restore company
          information, click the restore button and follow the instructions.
        </Text>
        <Button
          label="Restore company"
          loading={isRestoring}
          kind={BUTTON_TYPES.DEFAULT}
          fontSize="14px"
          height="50px"
          space="75px"
          width="320px"
          style={{ marginBottom: 10 }}
          onClick={handleRestoreCompany}
        />
        <Button
          label="Back to Log In"
          kind={BUTTON_TYPES.SECONDARY}
          fontSize="14px"
          height="50px"
          space="75px"
          width="320px"
          onClick={handleLogOut}
        />
        <DividerItem />
        <RestoreModal companyName={company.name} />
      </Content>
    </AuthWrapper>
  );
};

export default Restore;
