interface IDefaultAvatarProps {
  size?: string;
}

export const DefaultAvatar = ({
  size = '22',
}: IDefaultAvatarProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{}}
      d="M10 12C9.1 12 8.3 11.7 7.6 11.3C5.9 10.2 5 7.9 5 5C5 2.2 7.2 0 10 0C12.8 0 15 2.2 15 5C15 7.9 14.1 10.2 12.4 11.3C11.7 11.7 10.9 12 10 12ZM10 2C8.3 2 7 3.3 7 5C7 7.2 7.6 8.9 8.7 9.6C9.5 10.1 10.5 10.1 11.3 9.6C12.4 8.9 13 7.2 13 5C13 3.3 11.7 2 10 2Z"
      fill="#737F94"
    />
    <path
      d="M10 22.0001C7.7 22.0001 0 21.7001 0 18.0001C0 14.3001 2.7 13.0001 5.7 12.1001C5.9 12.0001 6.7 11.7001 7 10.2001L9 10.6001C8.7 12.4001 7.7 13.5001 6.3 14.0001C3.3 15.0001 2 15.8001 2 18.0001C2 19.1001 6.2 20.0001 10 20.0001C13.8 20.0001 18 19.1001 18 18.0001C18 15.8001 16.7 15.0001 13.7 13.9001C12.3 13.4001 11.4 12.3001 11 10.5001L13 10.1001C13.3 11.6001 14.1 11.9001 14.4 12.0001C17.4 13.0001 20.1 14.2001 20.1 17.9001C20 18.9001 19.3 22.0001 10 22.0001Z"
      fill="#737F94"
    />
  </svg>
);
