import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const ArrowIconWrap = styled.span<{
  $isOpen?: boolean;
}>`
  border-radius: 50%;
  background: ${theme.colors.grayLight};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: all 0.2s;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;
