import { SVGProps } from 'react';

export const DoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={7}
    height={6}
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.541 4.229L1.113 2.748.5 3.384 2.541 5.5 6.75 1.136 6.137.5 2.54 4.229z" />
  </svg>
);
