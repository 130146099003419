import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const getDelegatedSubtask = createRoutine(
  'subcontractors/getDelegatedSubtask'
);
export const getDelegatedSubtaskPromiseCreator =
  promisifyRoutine(getDelegatedSubtask);

export const startSubtask = createRoutine('subcontractors/startSubtask');
export const startSubtaskPromiseCreator = promisifyRoutine(startSubtask);

export const sendSubtaskToReview = createRoutine(
  'subcontractors/sendSubtaskToReview'
);
export const sendSubtaskToReviewPromiseCreator =
  promisifyRoutine(sendSubtaskToReview);

export const markSubtaskAsDone = createRoutine(
  'subcontractors/markSubtaskAsDone'
);
export const markSubtaskAsDonePromiseCreator =
  promisifyRoutine(markSubtaskAsDone);

export const markSubtaskAsPaused = createRoutine(
  'subcontractors/markSubtaskAsPaused'
);
export const markSubtaskAsPausedPromiseCreator =
  promisifyRoutine(markSubtaskAsPaused);

export const attachToSubtask = createRoutine('subcontractors/attachToSubtask');
export const attachToSubtaskPromiseCreator = promisifyRoutine(attachToSubtask);
