export const placeholdersOptions = [
  {
    placeholder: 'PROJECT_LINK',
    name: 'Project link',
  },
  {
    placeholder: 'INVOICE_NUMBER',
    name: 'Order number',
  },
  {
    placeholder: 'PROJECT_DUE_DATE',
    name: 'Project Due Date',
  },
  {
    placeholder: 'INVOICE_STATUS',
    name: 'Order Status',
  },
  {
    placeholder: 'TASK_NAME',
    name: 'Item Name',
  },
  {
    placeholder: 'SKU_NUMBER',
    name: 'SKU number',
  },
  {
    placeholder: 'SUBTASK_NAME',
    name: 'Task Name',
  },
  {
    placeholder: 'SUBTASK_LINK',
    name: 'Subtask Link',
  },
  {
    placeholder: 'ASSIGNED_RESOURCE',
    name: 'Assignee Name (Resource)',
  },
];
