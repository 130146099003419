import { FC, MouseEvent, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { Button } from '../Button';
import { BUTTON_TYPES } from '../Button/constants';
import PopoverWrapper from '../PopoverWrapper/PopoverWrapper';
import { DuplicatePopoverContent } from './elements/DuplicatePopoverContent';
import { MAX_SUBTASKS_COUNT } from 'lib/constants';
import { CloneProject } from 'lib/icons';

interface DuplicateButtonProps {
  count: number;
  onConfirm: (amount: number) => void;
  title?: string;
  isEdit?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
}

export const DuplicateButton: FC<DuplicateButtonProps> = ({
  count,
  onConfirm,
  isEdit,
  isLoading,
  title = 'Duplicate task',
  disabled,
  tooltipTitle,
}) => {
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);

  const handleStopPropagation = useCallback(
    (e: MouseEvent) => e.stopPropagation(),
    []
  );
  const handleConfirm = useCallback(
    (amount: number) => {
      setIsVisiblePopover(false);
      onConfirm(amount);
    },
    [onConfirm, setIsVisiblePopover]
  );

  if (count >= MAX_SUBTASKS_COUNT) return null;

  const button = <Button
    onClick={handleStopPropagation}
    kind={isEdit ? BUTTON_TYPES.ACTION : BUTTON_TYPES.PRIMARY}
    icon={<CloneProject />}
    label="Duplicate"
    height={isEdit ? '20px' : '28px'}
    loading={isLoading}
    lineheight="0px"
    space="0px"
    padding={isEdit ? '0' : '0 5px'}
    disabled={disabled}
  />;

  return (
    <PopoverWrapper
      title={title}
      content={
        <DuplicatePopoverContent onConfirm={handleConfirm} count={count} />
      }
      width={215}
      isVisible={isVisiblePopover}
      setIsVisible={setIsVisiblePopover}
    >
      <Tooltip
        title={tooltipTitle}
        overlayInnerStyle={{ textAlign: 'center' }}
      >
        {disabled ? <div>{button}</div> : button}
      </Tooltip>
    </PopoverWrapper>
  );
};
