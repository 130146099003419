import moment from 'moment-timezone';
import { Divider, Tooltip } from 'antd';
import {
  DateCreated,
  InfoBlock,
  InfoBlockData,
  InfoBlockName,
  InfoBlocksWrap,
  InfoTitle,
  InvoiceStatus,
} from './styles';

interface IInfoProps {
  startDate: string;
  estimatedTimeOfArrival: string | null;
  dueDate: string;
  bookingNumber: string;
  totalActualHours: number;
  invoiceStatus?: string;
  fullName: string;
  createdAt: string;
}

const Info = ({
  startDate,
  estimatedTimeOfArrival,
  dueDate,
  bookingNumber,
  totalActualHours,
  invoiceStatus,
  fullName,
  createdAt,
}: IInfoProps): JSX.Element => {
  const createdDate = moment(createdAt).format('L hh:mm a');

  return (
    <InfoBlocksWrap>
      <InfoTitle>Details</InfoTitle>
      <InfoBlock>
        <InfoBlockName>Actual hours</InfoBlockName>
        <InfoBlockData>{totalActualHours} hrs</InfoBlockData>
      </InfoBlock>
      {bookingNumber && (
        <InfoBlock>
          <InfoBlockName>Booking number</InfoBlockName>
          <InfoBlockData>{bookingNumber}</InfoBlockData>
        </InfoBlock>
      )}
      <InfoBlock>
        <InfoBlockName>Terms</InfoBlockName>
        <InfoBlockData>
          {startDate && `${moment(startDate).format('L')} -`}{' '}
          {moment(dueDate).format('L')}
        </InfoBlockData>
      </InfoBlock>
      {estimatedTimeOfArrival && (
        <InfoBlock>
          <InfoBlockName>Estimated Time of Arrival</InfoBlockName>
          <InfoBlockData>
            {moment(estimatedTimeOfArrival).format('D MMM (ddd)')}
          </InfoBlockData>
        </InfoBlock>
      )}
      {invoiceStatus && (
        <InfoBlock>
          <InfoBlockName>Status of invoice</InfoBlockName>
          <InfoBlockData>
            <InvoiceStatus>
              {invoiceStatus.length > 13 ? (
                <Tooltip
                  trigger="click"
                  placement="topLeft"
                  title={invoiceStatus}
                >
                  {invoiceStatus.slice(0, 13)}...
                </Tooltip>
              ) : (
                invoiceStatus
              )}
            </InvoiceStatus>
          </InfoBlockData>
        </InfoBlock>
      )}
      <Divider />
      <InfoBlock>
        <InfoBlockName>Created by</InfoBlockName>
        <InfoBlockData>
          {fullName}
          <DateCreated>{createdDate}</DateCreated>
        </InfoBlockData>
      </InfoBlock>
    </InfoBlocksWrap>
  );
};

export default Info;
