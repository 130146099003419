import { FC } from 'react';
import { BadgeItem } from './styles';

interface BadgeWrapperProps {
  count: number;
  overflowCount?: number;
}

const BadgeWrapper: FC<BadgeWrapperProps> = ({
  count,
  overflowCount = 9,
  children,
}) => (
  <BadgeItem count={count} overflowCount={overflowCount}>
    {children}
  </BadgeItem>
);

export default BadgeWrapper;
