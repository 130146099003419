import styled from 'styled-components';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export const NotesTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const NotesText = styled(Paragraph)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  .ant-typography-expand {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.lightDeepBlue};
  }
`;

export const NotesBlock = styled.div``;
