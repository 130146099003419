import { isValidPhoneNumber } from './helpers';
import {
  ValidationError,
  array,
  boolean,
  number,
  object,
  ref,
  string,
} from 'yup';

export const employees = string().required('Required!');

export const phoneNumber = string()
  .required('Required!')
  .test('validPhoneNumber', 'Invalid phone number', val =>
    isValidPhoneNumber(val)
  );

export const password = string()
  .trim()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters')
  .max(24, 'Password must be less than 24 characters')
  .matches(
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()+])[a-zA-Z\d!@#$%^&*()+]+$/,
    // eslint-disable-next-line max-len
    'Should not contain space, should contain at least one letter, should contain at least one number, should contain at least one special character !@#$%^&*()+'
  );

export const firstName = string()
  .trim()
  .max(100, 'First name must be at most 100 characters')
  .required('Required!');

export const lastName = string()
  .trim()
  .max(100, 'Last name must be at most 100 characters')
  .required('Required!');

export const email = string()
  .email('Invalid email address')
  .required('Email is required!');

export const companyName = string()
  .trim()
  .required('Company name is required!');

export const verifyPassword = string()
  .oneOf([ref('password'), null], 'Passwords must match')
  .required('Required!');

export const jobTitle = string()
  .matches(
    /^[ A-Za-z_@./#&+-]*$/,
    // eslint-disable-next-line @typescript-eslint/quotes
    "Should contain only letters, space or ' - ' "
  )
  .max(50, 'Maximum number of symbols is 50')
  .trim();

export const locationId = string().required('Location is required!');

export const notes = string()
  .trim()
  .max(1000, 'Notes must be at most 1000 characters');

export const sku = string().when('$isSkuRequired', (isSkuRequired, schema) => {
  if (isSkuRequired) return schema.required('SKU is required!');
  else return schema.nullable();
});

export const projectPrice = number().max(99999, 'Max 99999');

export const plannedHours = number()
  .min(0.01, 'Should be greater than 0')
  .max(999.99, 'Should be less than 999.99')
  .required('Required!')
  .typeError('Hours is required!');

export const itemName = string()
  .max(100, 'Name must be at most 100 characters')
  .trim()
  .required('Name is required!');

export const checklist = array().of(
  object().shape({
    _id: string(),
    title: string(),
    isChecked: boolean(),
  })
);

export const roles = object().test('rolesTest', null, obj => {
  const isNotEmpty = Object.values(obj).some(item => !!item);
  if (isNotEmpty) return true;
  return new ValidationError('Role is required!', null, 'roles');
});

export const availableLocations = object().test(
  'availableLocationsTest',
  null,
  obj => {
    const isNotEmpty = Object.values(obj).some(item => !!item);
    if (isNotEmpty) return true;

    return new ValidationError(
      'Access to location is required!',
      null,
      'availableLocations'
    );
  }
);

export const description = string()
  .trim()
  .max(1000, 'Description must be at most 1000 characters');
