import { useSelector } from 'react-redux';
import {
  markAllSystemNotificationsReadPromiseCreator,
  systemNotificationsUnreadCountSelector,
} from 'store/sharing';
import { useRequest } from 'hooks/useRequest';
import { ButtonItem } from './styles';

const MarkAllAsReadButton = (): JSX.Element => {
  const unreadCount = useSelector(systemNotificationsUnreadCountSelector);
  const [markAllAsRead, loading] = useRequest(
    markAllSystemNotificationsReadPromiseCreator
  );

  if (unreadCount === 0) return null;

  const handleReadAll = async () => {
    try {
      await markAllAsRead();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ButtonItem type="text" onClick={handleReadAll} loading={loading}>
      Mark all as read
    </ButtonItem>
  );
};

export default MarkAllAsReadButton;
