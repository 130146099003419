export const RESPONSE_ERRORS = {
  email: {
    server: `You can't invite this user as is already exists in another company`,
    client:
      'You can not invite this user, this user is already registered in another company',
  },

  auth: {
    server: 'You have to confirm your email address',
  },
  resetPassword: {
    server: 'Restore password token has been expired',
    client: 'Your password reset link has expired',
  },
};
