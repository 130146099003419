import { Wrap } from './styles';

interface ILoaderProps {
  loading?: boolean;
}

export const Loader = ({ loading }: ILoaderProps) =>
  loading ? (
    <Wrap>
      <div className="loadingio-spinner-dual-ball-3ddxoao3mxm">
        <div className="ldio-sqh4xjnahxf">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Wrap>
  ) : null;
