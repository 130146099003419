import { ReactNode, memo } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isFilesLoadingSelector } from 'store/loading';
import { Loader } from 'components/common';
import { noop } from 'lodash';
import { ModalProps } from 'antd/lib/modal';
import { ModalStyled } from './styles';

interface IModal {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  padding?: string;
  paddingHeader?: string;
}

const Modal = ({
  children,
  loading,
  padding,
  onCancel,
  ...rest
}: ModalProps & IModal) => {
  const isFilesLoading = useSelector(isFilesLoadingSelector);
  const onCancelFn = isFilesLoading ? noop : onCancel;

  return (
    <ModalStyled
      loading={loading}
      maskTransitionName="none"
      centered
      destroyOnClose
      footer={null}
      padding={loading ? '0' : padding}
      onCancel={onCancelFn}
      {...rest}
    >
      <Loader loading={loading} />
      {!loading && children}
    </ModalStyled>
  );
};

export default memo(Modal, isEqual);
