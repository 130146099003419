import styled, { css } from 'styled-components';
// components
import { Button } from 'components/common';

export const Wrap = styled.div<{
  isDrawer?: boolean;
}>`
  display: flex;
  flex-direction: column;
  max-width: 755px;
  ${props =>
    props.isDrawer &&
    css`
      padding: 20px;
    `}
`;

export const ButtonEditStyled = styled(Button)<{
  light?: string;
}>`
  padding: 0 11px;
  height: 26px;
  ${props =>
    props.light === 'true' &&
    css`
      background-color: ${({ theme }) => theme.colors.grayLight};
    `}
  svg {
    margin-right: 5px;
  }
`;

export const ButtonSaveStyled = styled(Button)`
  padding: 0 26px;
  height: 26px;
`;
