export const Linked = () => (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.425 3.75C1.425 2.4675 2.4675 1.425 3.75 1.425H6.75V0H3.75C1.68 0 0 1.68 0 3.75C0 5.82 1.68 7.5 3.75 7.5H6.75V6.075H3.75C2.4675 6.075 1.425 5.0325 1.425 3.75ZM4.5 4.5H10.5V3H4.5V4.5ZM11.25 0H8.25V1.425H11.25C12.5325 1.425 13.575 2.4675 13.575 3.75C13.575 5.0325 12.5325 6.075 11.25 6.075H8.25V7.5H11.25C13.32 7.5 15 5.82 15 3.75C15 1.68 13.32 0 11.25 0Z"
      fill="#172B4D"
    />
  </svg>
);
