import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* subcontractorsSaga() {
  yield all([
    fork(W.watchGetDelegatedSubtask),
    fork(W.watchStartSubtask),
    fork(W.watchSendSubtaskToReview),
    fork(W.watchMarkSubtaskAsDone),
    fork(W.watchAttachToSubtask),
    fork(W.watchMarkSubtaskAsPaused),
  ]);
}
