import { ITaskDto } from 'types';

interface ShouldDeleteFileFromStorageProps {
  filePath: string;
  cloneFiles: string[];
  tasks: ITaskDto[];
}

export const shouldDeleteFileFromStorage = ({
  filePath,
  cloneFiles,
  tasks,
}: ShouldDeleteFileFromStorageProps): boolean => {
  const isDuplicateFileExist =
    tasks.filter(task =>
      task.subtasks.some(subtask =>
        subtask.files.some(file => file.filePath === filePath)
      )
    ).length > 1;

  if (isDuplicateFileExist) return false;

  return !cloneFiles?.includes(filePath);
};
