import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import initialState from 'store/initialState';
// redux
import configureStore from 'store/store';
// utils
import * as serviceWorker from './serviceWorker';
import moment from 'moment-timezone';
// components
import App from 'pages/App';
import sentryInit from 'sentry';
import GlobalStyle from 'styles/GlobalStyle';
// styles
import theme from 'styles/theme';
import history from 'utils/history';
import storageManager from 'utils/storageManager';

sentryInit();

moment.tz.setDefault(storageManager.getTimezone());
const store = configureStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
