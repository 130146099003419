import { getWholeDay, getWholeWeek } from 'utils/helpers';
import moment from 'moment';
import { IGanttSettingProps } from 'types';

const useDayWeekPicker = (setDates: (props: IGanttSettingProps) => any) => {
  const handlePickDay = (date: moment.Moment) =>
    setDates({ ...getWholeDay(date), week: false });

  const handlePickWeek = (_, dates) => {
    setDates({ ...getWholeWeek(dates[0]), week: true });
  };

  return { handlePickDay, handlePickWeek };
};

export default useDayWeekPicker;
