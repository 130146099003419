import { invertBy } from 'lodash';
import { TaskTypeEnum } from 'types';

export const getCommonTasks = tasks =>
  tasks.filter(item => item.type === TaskTypeEnum.Common);

export const getContainerTasks = tasks =>
  tasks.filter(item => item.type === TaskTypeEnum.Container);

export const prepareData = (containerTasks, commonTasks, values, data) => {
  const newValues = invertBy(values);

  const newData = containerTasks.map((container, index) => {
    const newCommonTasksIds = newValues[container.mapId];

    const newCommonTasks = commonTasks.filter(task =>
      newCommonTasksIds?.some(item => item === task.mapId)
    );
    const newPrefix = `[${Number(index) + 1}/${containerTasks.length}]`;
    return {
      ...data,
      prefix: newPrefix,
      isDivisionNeeded: false,
      tasks: [container, ...newCommonTasks],
    };
  });
  return newData;
};
