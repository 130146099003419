import { useCallback, useMemo, useState } from 'react';
import { downloadPdfPromiseCreator } from 'store/sharing';
// components
import PreviewAttachment from 'components/PreviewAttachment';
import { useActionsRoutines } from 'hooks';
import { getFilePreviewUrl } from 'lib/constants';
import { FileIcon } from 'lib/icons';
import { getProjectFilePreviewUrl } from 'utils/images';
// styles
import { FileInfoName, FileItemTopRight, Preview, PreviewWrap } from './styles';
import { ProjectFileUploadedDto } from 'types';

interface IFileProps {
  file: ProjectFileUploadedDto;
}

const File = ({ file }: IFileProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const { filePath: href } = file;
  const previewUrl = useMemo(
    () => getFilePreviewUrl(file, getProjectFilePreviewUrl),
    [file]
  );

  const closePreview = e => {
    e.stopPropagation();
    setShowPreview(false);
  };

  const openPreview = e => {
    e.stopPropagation();
    setShowPreview(true);
  };

  const handleDownloadPdf = useCallback(() => {
    if (href) {
      downloadPdf({ data: href, originalName: file.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file.originalName, downloadPdf]);

  return (
    <FileItemTopRight>
      <PreviewAttachment
        showPreview={showPreview}
        closePreview={closePreview}
        file={file}
      />
      <PreviewWrap>
        {previewUrl ? (
          <Preview src={previewUrl} onClick={openPreview} />
        ) : (
          <FileIcon />
        )}
      </PreviewWrap>
      <a onClick={handleDownloadPdf}>
        <FileInfoName ellipsis={{ rows: 2 }}>{file?.originalName}</FileInfoName>
      </a>
    </FileItemTopRight>
  );
};

export default File;
