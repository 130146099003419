import { FC, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { DangerText, MenuItem, MenuPopoverWrap, MenuText } from './styles';
import { LoadingOutlinedStyled } from 'components/common/SelectInput/styles';

interface IPopoverContentProps {
  deleteMessage: () => void;
  editMessage?: () => void;
  isDeleting: boolean;
  handleClosePopover: () => void;
  replyMessage: () => void;
}

const PopoverContent: FC<IPopoverContentProps> = ({
  editMessage,
  deleteMessage,
  isDeleting,
  handleClosePopover,
  replyMessage,
}) => {
  const projectWrapper = document.querySelector('.ant-drawer-body');

  const handleProjectScrolling = useCallback(() => {
    handleClosePopover();
    if (projectWrapper) {
      projectWrapper.removeEventListener('scroll', handleProjectScrolling);
    }
  }, [handleClosePopover, projectWrapper]);
  useEffect(() => {
    if (projectWrapper) {
      projectWrapper.addEventListener('scroll', handleProjectScrolling);
      return () => {
        projectWrapper.removeEventListener('scroll', handleProjectScrolling);
      };
    }
  }, [handleProjectScrolling, projectWrapper]);

  const handleReplyMessage = () => {
    handleClosePopover();
    replyMessage();
  };
  return (
    <MenuPopoverWrap onClick={e => e.stopPropagation()}>
      {editMessage && (
        <MenuItem onClick={editMessage}>
          <MenuText>Edit</MenuText>
        </MenuItem>
      )}

      <MenuItem onClick={handleReplyMessage}>
        <MenuText>Reply</MenuText>
      </MenuItem>

      <MenuItem onClick={deleteMessage}>
        <DangerText>Delete</DangerText>
        {isDeleting && (
          <Spin
            indicator={
              <LoadingOutlinedStyled spin style={{ fontSize: '17px' }} />
            }
            style={{ marginLeft: '20px' }}
          />
        )}
      </MenuItem>
    </MenuPopoverWrap>
  );
};

export default PopoverContent;
