import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';
// redux
import { signOut } from 'store/auth';
import initialState from 'store/initialState';
import * as adminStore from './admin';
import * as authStore from './auth';
import * as companyStore from './company';
import * as dashboardStore from './dashboard';
import * as depotStore from './depot';
import * as ganttStore from './gantt';
import * as inboundStore from './inbound';
import * as inventoryStore from './inventory';
import * as loadingStore from './loading';
import * as predefinedStore from './predefined';
import * as reportsStore from './reports';
import * as sharingStore from './sharing';
import * as subcontractorsStore from './subcontractors';
import * as timesheetsStore from './timesheets';
import history from 'utils/history';

function reducer(state, action) {
  return rootReducer(combineReducer(state, action), action);
}

const combineReducer = combineReducers({
  loading: loadingStore.reducer,
  gantt: ganttStore.reducer,
  auth: authStore.reducer,
  predefined: predefinedStore.reducer,
  company: companyStore.reducer,
  admin: adminStore.reducer,
  dashboard: dashboardStore.reducer,
  inbound: inboundStore.reducer,
  depot: depotStore.reducer,
  inventory: inventoryStore.reducer,
  timesheets: timesheetsStore.reducer,
  sharing: sharingStore.reducer,
  subcontractors: subcontractorsStore.reducer,
  reports: reportsStore.reducer,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === signOut.success().type) {
    return {
      ...initialState,
      router: state.router,
      auth: { ...initialState.auth, isAuthorized: false },
    };
  } else {
    return state;
  }
};

export default reducer;
