import { useEffect, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { currentLocationSelector } from 'store/auth';
import { projectSelector } from 'store/dashboard';
// redux
import { locationsSelector } from 'store/predefined';
// utils
import { getLocation } from '../helpers';
// components
import { SelectInput, TitleCreateProject } from 'components/common';
// styles
import { HeaderStyled } from './styles';

interface IHeaderProps {
  isEdit?: boolean;
  isClone?: boolean;
  isDraft?: boolean;
  cloneCount?: number;
}

const Header = ({
  isEdit,
  isClone,
  isDraft,
  cloneCount,
}: IHeaderProps): JSX.Element => {
  const locations = useSelector(locationsSelector, isEqual);
  const currentLocationId = useSelector(currentLocationSelector);
  const currentProject = useSelector(projectSelector, isEqual);
  const locationId = useMemo(
    () => getLocation(locations, currentLocationId),
    [locations, currentLocationId]
  );
  const isAnySubtaskAssigned = useMemo(
    () =>
      currentProject.tasks?.some(task =>
        task.subtasks.some(subtask => subtask.resource)
      ),
    [currentProject.tasks]
  );

  const isNewProject = !isClone && !isDraft && !isEdit;
  const disableEditLocation = isAnySubtaskAssigned && !isNewProject && !isClone;

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const title = isClone
    ? `Duplicate Project (${cloneCount})`
    : isEdit && !isDraft
    ? 'Edit Project'
    : 'Create Project';

  useEffect(() => {
    if (!getValues('locationId') && locationId) {
      setValue('locationId', locationId);
    }
  }, [getValues, locationId, setValue]);

  return (
    <HeaderStyled>
      <TitleCreateProject>{title}</TitleCreateProject>
      <Controller
        render={({ field }) => (
          <SelectInput
            disabled={disableEditLocation}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            options={locations}
            showSearch
            placeholder="Select location"
            error={errors?.locationId?.message}
            width="150px"
            height="30px"
          />
        )}
        name="locationId"
        control={control}
        defaultValue={locationId}
      />
    </HeaderStyled>
  );
};

export default Header;
