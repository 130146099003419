import { all, fork } from 'redux-saga/effects';
import { invitationsSaga } from 'store/admin/invitations/sagas';
import * as W from './watchers';

export function* companyAdministratorsSaga() {
  yield all([
    fork(W.watchAdministrators),
    fork(W.watchDeleteUsers),
    fork(W.watchGetUserAdmin),
    fork(W.watchPutUserAdmin),
    fork(W.watchAvatarAdmin),
    fork(invitationsSaga),
  ]);
}
