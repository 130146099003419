import React, {
  LegacyRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isFilesLoadingSelector } from 'store/loading';
import { DuplicateProjectsTasks } from 'components/DuplicateProjectsTasks';
// components
import TaskForm from 'components/TaskForm';
// icons
import { ArrowDown } from 'lib/icons';
import DeleteButton from 'pages/Dashboard/Project/common/DeleteButton';
// styles
import {
  CollapseStyled,
  HeaderContent,
  HeaderContentButtons,
  HeaderStyled,
  MainTitle,
  RightCol,
  TaskBody,
  Title,
  TitleInfo,
} from './styles';
import {
  HookFormRecord,
  IProject,
  IProjectTaskDto,
  ISubtaskTypeDto,
} from 'types';

interface IItemProps {
  item: IProjectTaskDto;
  subtaskTypes: ISubtaskTypeDto[];
  remove: (index: number) => void;
  name: string;
  projectId: string;
  project: IProject;
  index: number;
  order: number;
  watchData: any;
  tasksCount: number;
  isEdit: boolean;
  appendTask?: (task: IProjectTaskDto[]) => void;
  appendContainer?: (task: IProjectTaskDto) => void;
  taskRef: LegacyRef<HTMLDivElement>;
}

const Item = ({
  item,
  subtaskTypes,
  remove,
  name,
  index,
  order,
  watchData,
  isEdit,
  appendTask,
  appendContainer,
  taskRef,
  projectId,
  project,
  tasksCount,
}: IItemProps) => {
  const [isClose, toggleClose] = useState(false);
  const isFilesLoading = useSelector(isFilesLoadingSelector);

  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();

  useEffect(() => {
    register(`${name}order` as HookFormRecord);
    setValue(`${name}order` as HookFormRecord, item.order);
  }, [setValue, register, item, name]);

  const collapsedTitle = useMemo(
    () => `${index + 1} ${(watchData && watchData[index]?.name) || ''}`,
    [watchData, index]
  );

  const collapsedInfo = useMemo(() => {
    const subtaskCount = (watchData && watchData[index]?.subtasks?.length) || 0;
    const sku = (watchData && watchData[index]?.sku) || '';
    const cost = (watchData && watchData[index]?.cost) || 0;
    const price = (watchData && watchData[index]?.price) || 0;

    return `${subtaskCount} ${subtaskCount > 1 ? 'task(s)' : 'task'}   ${
      sku && `• ${sku}`
    } • $${cost}/${price}`;
  }, [watchData, index]);

  const handleOpenTask = useCallback(() => toggleClose(true), [toggleClose]);
  const handleCloseTask = useCallback(() => toggleClose(false), [toggleClose]);
  const handleDeleteTask = useCallback(
    e => {
      e.preventDefault();
      remove(index);
    },
    [remove, index]
  );

  return useMemo(
    () => (
      <div ref={errors?.tasks?.[index] && taskRef}>
        <CollapseStyled key={item._id}>
          <HeaderStyled
            onClick={handleCloseTask}
            style={{ display: isClose ? 'flex' : 'none' }}
          >
            <Title>{collapsedTitle}</Title>
            <RightCol>
              <TitleInfo>{collapsedInfo}</TitleInfo>
              <ArrowDown />
            </RightCol>
          </HeaderStyled>
          <div style={{ display: isClose ? 'none' : 'block' }}>
            <HeaderStyled className="with-border" onClick={handleOpenTask}>
              <HeaderContent>
                <MainTitle>Item {index + 1}</MainTitle>
                <HeaderContentButtons>
                  <DuplicateProjectsTasks
                    task={watchData[index]}
                    tasksCount={tasksCount}
                    handler={appendTask}
                  />
                  <DeleteButton
                    disabled={isFilesLoading}
                    title={'Are you sure you want to delete this item?'}
                    remove={handleDeleteTask}
                  />
                </HeaderContentButtons>
              </HeaderContent>
            </HeaderStyled>
            <TaskBody>
              <TaskForm
                isEdit={isEdit}
                item={item}
                index={index}
                name={name}
                isInProject
                errors={errors?.tasks?.[index]}
                subtaskTypes={subtaskTypes}
                appendTask={appendTask}
                order={order}
                projectId={projectId}
                project={project}
                appendContainer={appendContainer}
              />
            </TaskBody>
          </div>
        </CollapseStyled>
      </div>
    ),
    [
      appendTask,
      errors,
      collapsedInfo,
      collapsedTitle,
      handleCloseTask,
      handleDeleteTask,
      handleOpenTask,
      index,
      isClose,
      isEdit,
      item,
      name,
      subtaskTypes,
      taskRef,
      projectId,
      appendContainer,
      isFilesLoading,
      order,
      project,
      tasksCount,
      watchData,
    ]
  );
};

export default memo(Item, isEqual);
