import styled from 'styled-components';
import theme from 'styles/theme';
import { Modal } from 'antd';

export const ModalItem = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
`;
export const SubTitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  color: ${theme.colors.lightBlue};
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
