import styled, { css } from 'styled-components';

export const MainInfo = styled.div`
  user-select: none;
  & > div {
    margin-bottom: 10px;
  }
`;

export const MainInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .TaskTitleInput {
    @media (max-width: 755px) {
      .ant-select-selector {
        width: 100% !important;
      }
    }
  }
`;

export const Subtasks = styled.div<{
  subtasksInWrap?: boolean;
}>`
  ${props =>
    props.subtasksInWrap &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.grayDark};
      background-color: ${({ theme }) => theme.colors.grayLight};
      border-radius: 6px;
      padding: 18px 6px 23px 17px;
    `}
`;

export const SubtasksList = styled.div``;
