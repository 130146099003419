import styled from 'styled-components';
import { Radio } from 'antd';

export const RadioButtonStyled = styled.div`
  &:focus {
    outline: none;
  }
  .ant-radio-button-wrapper {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    background-color: ${({ theme }) => theme.colors.white} !important;
    margin-right: 5px;
    border: 1px solid ${({ theme }) => theme.colors.grayDark} !important;
    border-radius: 4px !important;
    height: 26px;
    min-width: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    margin-bottom: 5px;
    outline: none;
    box-shadow: none;
    &:focus {
      outline: none;
    }
    &:focus-within {
      box-shadow: none !important;
    }
    &:after {
      width: 0;
      padding: 0;
      height: 0;
    }
    & span:focus {
      outline: none;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
    box-shadow: none;
  }
`;

export const RadioGroupStyled = styled(Radio.Group)`
  white-space: normal !important;
  display: flex;
  flex-wrap: wrap;
`;
