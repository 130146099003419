import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import { Button, TextArea } from 'components/common';
import { PercentButtonsGroup } from 'components/common/PercentButtonsGroup';
import { SubtaskResultSchema } from 'validation';
import { PercentWrap, Title, Wrap } from './styles';

interface IReasonProps {
  setReject?: SubmitHandler<FieldValues>;
  isLoadingSend?: boolean;
}

const Reason = ({ setReject, isLoadingSend }: IReasonProps) => {
  const form = useForm({
    resolver: yupResolver(SubtaskResultSchema),
    shouldUnregister: true,
  });

  return (
    <form onSubmit={form.handleSubmit(setReject)}>
      <Wrap>
        <Title>Reason</Title>
        <Controller
          render={({ field }) => (
            <TextArea
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              placeholder="Write your reason..."
              rows={5}
              error={form?.formState?.errors?.message?.message}
            />
          )}
          name="message"
          control={form.control}
        />
        <PercentWrap>
          <Controller
            render={({ field }) => (
              <PercentButtonsGroup onChange={field.onChange} />
            )}
            name="percent"
            control={form.control}
          />
        </PercentWrap>
        <Button
          label="Submit"
          height="55px"
          htmlType="submit"
          style={{ marginTop: 19 }}
          loading={isLoadingSend}
        />
      </Wrap>
    </form>
  );
};

export default Reason;
