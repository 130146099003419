import { memo } from 'react';
import isEqual from 'react-fast-compare';
import EmptyComponent from './EmptyComponent';
import { ConfigProvider } from 'antd';

interface IEmptyListProps {
  onClick?: () => void;
  label?: string;
  width?: string;
  children?: JSX.Element | JSX.Element[];
  noButton?: boolean;
  hidden?: boolean;
  noImage?: boolean;
  buttonEmpty?: Element;
}

const EmptyList = ({
  onClick,
  label,
  width = '140px',
  children,
  buttonEmpty,
  noButton,
  hidden,
  noImage = false,
}: IEmptyListProps) => (
  <ConfigProvider
    renderEmpty={() =>
      hidden ? null : (
        <EmptyComponent
          onClick={onClick}
          label={label}
          width={width}
          buttonEmpty={buttonEmpty}
          noButton={noButton}
          noImage={noImage}
        />
      )
    }
  >
    {children}
  </ConfigProvider>
);

export default memo(EmptyList, isEqual);
