import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const dashboard = (r: IInitialState) => r.dashboard;
export const ganttDatesSelector = createSelector(dashboard, r => r.ganttDates);
export const ganttWeekViewSelector = createSelector(
  dashboard,
  r => r.ganttWeekView
);
export const projectsSelector = createSelector(dashboard, r => r.projects);
export const projectFilesLoaderSelector = createSelector(
  dashboard,
  r => r.projectFilesLoader
);
export const projectSelector = createSelector(dashboard, r => r.project);
export const ganttSelector = createSelector(dashboard, r => r.gantt);
export const ganttItemsSelector = createSelector(dashboard, r => r.gantt.items);
export const standaloneSubtaskSelector = createSelector(
  dashboard,
  r => r.standaloneSubtask
);
export const projectsSortBySelector = createSelector(
  dashboard,
  r => r.projectsSortBy
);
export const containerSizesFilterSelector = createSelector(
  dashboard,
  r => r.containerSizesFilter
);
export const containerSkusFilterSelector = createSelector(
  dashboard,
  r => r.containerSkusFilter
);
export const screenDistributionSelector = createSelector(
  dashboard,
  r => r.screenDistribution
);
export const splitScreenSelector = createSelector(
  dashboard,
  r => r.isScreenSplited
);
export const projectsTotalSelector = createSelector(
  dashboard,
  r => r.projectsTotal
);
export const projectsContainerHeightSelector = createSelector(
  dashboard,
  r => r.projectsContainerHeight
);

export const subtaskFilesBufferSelector = createSelector(
  dashboard,
  r => r.subtaskFilesBuffer
);

export const subtaskDraftFilesBufferSelector = createSelector(
  dashboard,
  r => r.subtaskDraftFilesBuffer
);

export const subtaskTemplateFilesBufferSelector = createSelector(
  dashboard,
  r => r.subtaskTemplateFilesBuffer
);

export const subtaskMessagesSelector = createSelector(
  dashboard,
  r => r.messages
);

export const openedSubtaskChatIdSelector = createSelector(
  dashboard,
  r => r.openedSubtaskChatId
);

export const subtasksUnreadMessagesCountSelector = createSelector(
  dashboard,
  r => r.subtasksUnreadMessagesCount
);

export const standaloneUnreadMessagesSelector = createSelector(
  dashboard,
  r => r.standaloneSubtaskUnreadCount
);

export const minProjectsContainerHeightSelector = createSelector(
  dashboard,
  r => r.minProjectsContainerHeight
);

export const isProjectCreateModalOpenSelector = createSelector(
  dashboard,
  r => r.isProjectCreateModalOpen
);

export const isFirstProjectsRequestLoadedSelector = createSelector(
  dashboard,
  r => r.isFirstProjectsRequestLoaded
);

export const assignConflictDataSelector = createSelector(
  dashboard,
  r => r.assignConflictData
);

export const projectViewModeSelector = createSelector(
  dashboard,
  r => r.projectViewMode
);

export const isCloneProjectSelector = createSelector(
  dashboard,
  r => r.isCloneProject
);

export const cloneFilesSelector = createSelector(dashboard, r => r.cloneFiles);
