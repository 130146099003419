import { SVGProps } from 'react';

interface IDeleteProjectProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export function TrashIcon({ size = 16 }: IDeleteProjectProps) {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.335 13.334c0 .735.598 1.333 1.334 1.333h6.666c.736 0 1.334-.598 1.334-1.333v-8h1.333V4.001h-2.667V2.667c0-.735-.598-1.333-1.333-1.333h-4c-.735 0-1.333.598-1.333 1.333v1.334H2.002v1.333h1.333v8zM6.002 2.667h4v1.334h-4V2.667zm-.667 2.667h6l.001 8H4.669v-8h.666z"
        fill="#FF3939"
      />
      <path
        d="M6.002 6.666h1.333v5.333H6.002V6.666zm2.667 0h1.333v5.333H8.669V6.666z"
        fill="#FF3939"
      />
    </svg>
  );
}
