import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isSalesManagerSelector } from 'store/auth';
// redux
import {
  screenDistributionSelector,
  setScreenDistribution,
  splitScreenSelector,
} from 'store/dashboard';
import { location } from 'store/router';
// utils
import { useActions } from 'hooks';
// constants
import theme from 'styles/theme';
// icons
import { DownOutlined, UpOutlined } from '@ant-design/icons';
// styles
import { Wrapper } from './styles';
import { ScreenDistributionTypesEnum } from 'types';

const FullScreenSwitch = () => {
  const isSales = useSelector(isSalesManagerSelector);
  const isSplited = useSelector(splitScreenSelector);
  const currentDistribution = useSelector(screenDistributionSelector);
  const currentLocation = useSelector(location);

  const action = useActions(setScreenDistribution);

  const isHalf = useMemo(
    () => currentDistribution === ScreenDistributionTypesEnum.HALF,
    [currentDistribution]
  );

  const isProject = useMemo(
    () => currentDistribution === ScreenDistributionTypesEnum.FULL_PROJECTS,
    [currentDistribution]
  );

  const setHalfScreen = useCallback(
    () => action(ScreenDistributionTypesEnum.HALF),
    [action]
  );
  const setGanttView = useCallback(
    () => action(ScreenDistributionTypesEnum.FULL_GANTT),
    [action]
  );
  const setProjectsView = useCallback(
    () => action(ScreenDistributionTypesEnum.FULL_PROJECTS),
    [action]
  );

  useEffect(() => {
    if (isSales) {
      setProjectsView();
    }
  }, [isSales, setProjectsView]);

  const onClick = useCallback(() => {
    if (isHalf) {
      return setProjectsView();
    }

    if (isProject) {
      return setGanttView();
    }

    setHalfScreen();
  }, [isHalf, isProject, setGanttView, setProjectsView, setHalfScreen]);

  if (isSales || currentLocation.pathname !== '/dashboard') return null;

  return (
    <Wrapper onClick={onClick} isSplited={isSplited}>
      <UpOutlined
        style={{
          color: `${isHalf || isProject
              ? theme.colors.mainDarkBlue
              : theme.colors.grayDark
            } `,
        }}
      />
      <DownOutlined
        style={{
          color: `${isHalf || !isProject
              ? theme.colors.mainDarkBlue
              : theme.colors.grayDark
            } `,
        }}
      />
    </Wrapper>
  );
};

export default FullScreenSwitch;
