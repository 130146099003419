import { getFileSize } from 'utils/helpers';
import { COMMON_TASK_STATUSES, PROJECTS_STATUSES } from 'lib/constants';
import {
  EmptyTaskWithIndex,
  IAttachmentInfo,
  IProjectResponseDto,
  IProjectTaskDto,
  ISubtaskDto,
  ITaskDto,
  SubtaskModeList,
  SubtaskStatusesEnum,
  TaskTypeEnum,
} from 'types';

export const prepareTasks = (tasks: IProjectTaskDto[]) =>
  tasks?.map(item => {
    const subtasks = item.subtasks ? item.subtasks : [];
    return {
      ...item,
      subtasks,
    };
  });

export const prepareAddFileData = (
  file: IAttachmentInfo,
  value: IAttachmentInfo
) => ({
  filePath: file.filePath,
  fileUrl: file.fileUrl,
  mimetype: file.mimetype,
  previewPath: file.previewPath,
  previewUrl: file.previewUrl,
  originalName: value.name,
  size: getFileSize(value.size),
  isCover: false,
});

export const prepareEditProject = (
  data: IProjectResponseDto,
  file,
  filesForSend: IAttachmentInfo[],
  removeCover: boolean
) => ({
  id: data._id,
  data: {
    ...data,
    coverUrl: removeCover ? '' : file?.fileUrl,
    files: filesForSend,
  },
});
export const prepareRemoveTask = (
  data: IProjectResponseDto,
  editFilesData: IAttachmentInfo[],
  index: number
) => {
  const newTasksArray = data.tasks.filter(
    (item, itemIndex) => itemIndex !== index
  );
  const tasksForSend = newTasksArray.map((item, itemIndex: number) => ({
    ...item,
    order: item.order && itemIndex + 1,
  }));
  return {
    id: data._id,
    data: {
      ...data,
      coverUrl: data?.cover?.previewPath,
      tasks: tasksForSend,
      files: editFilesData,
    },
  };
};

export const getDataForSend = (
  formData: ITaskDto,
  data: IProjectResponseDto,
  filesForSend: IAttachmentInfo[],
  index: number,
  isNewContainer?: boolean
) => {
  const isContainer = formData.type === TaskTypeEnum.Container;
  const editedItem = isContainer
    ? {
        ...formData,
        container: { ...formData },
      }
    : formData;

  const shouldStatusChange = formData?.subtasks?.some(
    subtask => subtask.status !== SubtaskStatusesEnum.DONE
  );

  const preparedData = { ...data, tasks: data.tasks };
  const tasksValues = isNewContainer
    ? [editedItem, ...preparedData.tasks]
    : [
        ...preparedData.tasks.slice(0, index),
        editedItem,
        ...preparedData.tasks.slice(index + 1),
      ];

  const preparedTasks = tasksValues.map(task => ({
    ...task,
    subtasks: task.subtasks.map(subtask => ({
      ...subtask,
      templateHours: subtask.templateHours ?? 0,
    })),
  }));

  return {
    ...data,
    status: shouldStatusChange ? PROJECTS_STATUSES.IN_PROGRESS : data.status,
    coverUrl: data?.cover?.previewPath,
    tasks: preparedTasks,
    files: filesForSend,
  };
};

export const getSubtaskToolTipTitle = (subtask: ISubtaskDto) => {
  const text = `${subtask.order}. ${subtask.name} - ${subtask.status}`;
  const resource = subtask?.resource
    ? `${subtask.resource.firstName} ${subtask.resource.lastName}`
    : 'No resource';

  return `${text} - ${resource}`;
};

export const isNotCompleted = (data: IProjectResponseDto) =>
  data.tasks.find(
    item =>
      item.type === TaskTypeEnum.Common &&
      item.status !== COMMON_TASK_STATUSES.DONE
  );

export const groupTasksBySubtask = (
  tasksList: IProjectTaskDto[],
  subtasksTypesObject: Record<string, number>
): SubtaskModeList[] => {
  const subtaskNames = [];
  return tasksList
    .reduce((acc, task, index) => {
      const { subtasks, ...rest } = task;
      if (task.type === TaskTypeEnum.Container) return acc;
      subtasks.forEach(subtask => {
        if (!subtaskNames.includes(subtask.name)) {
          subtaskNames.push(subtask.name);
          const newItem = {
            subtaskName: subtask.name,
            order: subtasksTypesObject[subtask.subtaskTypeId] ?? 0,
            subtasksIds: [subtask._id],
            tasks: [{ ...rest, subtasks: [subtask] }],
            subtasks: [{ ...subtask, taskName: rest.name, taskIndex: index }],
          };
          acc.push(newItem);
        } else {
          const index = acc.findIndex(
            item => item.subtaskName === subtask.name
          );
          acc[index].subtasks.push({
            ...subtask,
            order: subtasksTypesObject[subtask.subtaskTypeId] ?? 0,
            taskName: rest.name,
            taskIndex: index,
          });
          acc[index].tasks.push({ ...rest, subtasks: [subtask] });
          acc[index].subtasksIds = [...acc[index].subtasksIds, subtask._id];
        }
      });

      return acc;
    }, [] as SubtaskModeList[])
    .sort((a, b) => {
      const sort = x => (x === 0 ? Infinity : x);

      return sort(a.order) - sort(b.order);
    });
};

export const getEmptyCommonTasks = (tasks: IProjectTaskDto[]) =>
  tasks.reduce((acc, task, index) => {
    if (task.subtasks.length === 0 && task.type === TaskTypeEnum.Common) {
      acc.push({ ...task, index });
    }
    return acc;
  }, [] as EmptyTaskWithIndex[]);
