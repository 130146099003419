import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// redux
import { containerOptionsSelector } from 'store/admin';
import { hasEmptyProperty } from 'utils/helpers';
// components
import ContainerForm from 'components/ContainerForm';
import { Button, TitleText } from 'components/common';
import DeleteButton from 'pages/Dashboard/Project/common/DeleteButton';
import { Tooltip } from 'antd';
// styles
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SectionTitle } from '../styles';
import { ContainerHeader, ContainerWrapper, TooltipWrapper } from './styles';
import { ISubtaskTypeDto } from 'types';

interface IContainerProps {
  toggleContainerView?: (isShow: boolean) => void;
  setIsWorkOrderContainer?: (container: boolean) => void;
  isInbound?: boolean;
  defaultValues?: any;
  appendContainer?: () => void;
  showContainer: boolean;
  onDelete?: () => void;
  subtaskTypes?: ISubtaskTypeDto[];
  projectId?: string;
}
const Container = ({
  toggleContainerView,
  setIsWorkOrderContainer,
  isInbound,
  defaultValues,
  showContainer,
  appendContainer,
  subtaskTypes,
  projectId,
  onDelete,
}: IContainerProps) => {
  const containerOptions = useSelector(containerOptionsSelector, isEqual);
  const form = useFormContext();
  const [isNewContainer, setIsNewContainer] = useState(false);
  const { unregister, trigger } = form;

  const isContainerAvailable = useMemo(
    () => !hasEmptyProperty(containerOptions),
    [containerOptions]
  );

  const removeContainer = useCallback(() => {
    unregister([
      'container.condition',
      'container.size',
      'container.color',
      'container.grade',
      'container.sku',
      'container.name',
      'container.description',
      'container',
    ]);

    toggleContainerView(false);
    setIsWorkOrderContainer(false);

    if (onDelete) onDelete();

    void trigger();
  }, [
    toggleContainerView,
    unregister,
    trigger,
    setIsWorkOrderContainer,
    onDelete,
  ]);

  const handleAppendContainer = useCallback(() => {
    appendContainer();
    setIsNewContainer(true);
  }, [appendContainer, setIsNewContainer]);

  return (
    <>
      {!showContainer ? (
        isContainerAvailable ? (
          <Button
            onClick={handleAppendContainer as any}
            label="Add Container"
            height="38px"
            width="100%"
          />
        ) : (
          <Tooltip title="Depot settings is required">
            <TooltipWrapper>
              <Button
                label="Add Container"
                height="38px"
                width="100%"
                disabled
                icon={<ExclamationCircleOutlined />}
              />
            </TooltipWrapper>
          </Tooltip>
        )
      ) : (
        <div>
          <SectionTitle>Container</SectionTitle>
          <ContainerWrapper>
            <ContainerHeader>
              <TitleText style={{ whiteSpace: 'nowrap' }}>
                Add Container
              </TitleText>
              <DeleteButton
                title={'Are you sure you want to delete this container?'}
                remove={removeContainer}
              />
            </ContainerHeader>
            <ContainerForm
              setIsWorkOrderContainer={setIsWorkOrderContainer}
              item={
                !isNewContainer && defaultValues?.container
                  ? defaultValues?.container
                  : {}
              }
              errors={form?.formState?.errors?.container}
              name={'container.'}
              subtaskTypes={subtaskTypes}
              containerOptions={containerOptions}
              isNewContainer={isNewContainer}
              isInProject
              isInbound={isInbound}
              projectId={projectId}
            />
          </ContainerWrapper>
        </div>
      )}
    </>
  );
};

export default memo(Container, isEqual);
