import { CSSProperties, memo } from 'react';
import { Link } from 'react-router-dom';
// components
import { Button } from './Button';
import { BUTTON_TYPES } from './Button/constants';
import { ButtonProps } from 'antd';

interface ILinkButtonProps {
  to: string;
}
interface IButtonProps extends ButtonProps {
  kind?: BUTTON_TYPES;
  label?: string;
  icon?: JSX.Element;
  height?: string;
  width?: string;
  space?: string;
  fontSize?: string;
  lineheight?: string;
  onClick?: any;
  style?: CSSProperties;
}

export const LinkButton = memo(
  ({ to, ...props }: ILinkButtonProps & IButtonProps) => (
    <Link to={to}>
      <Button {...props} />
    </Link>
  )
);
