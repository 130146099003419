import styled from 'styled-components';
import theme from 'styles/theme';
import { Switch } from 'antd';

export const ToggleShowUnread = styled.div`
  display: flex;
  gap: 7px;
`;

export const SwitchItem = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${theme.colors.primary};
    .ant-switch-loading-icon {
      color: ${theme.colors.primary};
    }
  }
`;
