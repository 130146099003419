import styled from 'styled-components';

export const TasksActionsStyled = styled.div`
  display: flex;
  gap: 15px;
  margin-right: 15px;

  .ant-popover-inner-content {
    padding: 0;
  }
`;
