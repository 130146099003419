import { useSelector } from 'react-redux';
import {
  clearSystemNotifications,
  getSystemNotificationsPromiseCreator,
  isShowOnlyUnreadNotificationMessagesSelector,
  setShowOnlyUnreadNotificationMessages,
} from 'store/sharing';
import { useActions } from 'hooks';
import { useRequest } from 'hooks/useRequest';
import { SwitchItem, ToggleShowUnread } from './styles';

const UnreadSwitcher = (): JSX.Element => {
  const isShowOnlyUnreadNotifications = useSelector(
    isShowOnlyUnreadNotificationMessagesSelector
  );
  const [getSystemNotifications, isLoading] = useRequest(
    getSystemNotificationsPromiseCreator
  );
  const clearNotifications = useActions(clearSystemNotifications);

  const toggleShowUnreadMessages = useActions(
    setShowOnlyUnreadNotificationMessages
  );

  const handleSwitchUnreadNotifications = async (showUnread: boolean) => {
    try {
      clearNotifications();
      await getSystemNotifications({
        isRefetch: true,
        onlyUnread: showUnread,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const toggleSwitchShowUnread = () => {
    toggleShowUnreadMessages(!isShowOnlyUnreadNotifications);
    handleSwitchUnreadNotifications(!isShowOnlyUnreadNotifications).catch(
      console.error
    );
  };

  return (
    <ToggleShowUnread>
      Only show unread
      <SwitchItem
        loading={isLoading}
        onChange={toggleSwitchShowUnread}
        checked={isShowOnlyUnreadNotifications}
      />
    </ToggleShowUnread>
  );
};

export default UnreadSwitcher;
