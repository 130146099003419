// components
import { Dispatch, SetStateAction } from 'react';
import { IValuesObject } from '..';
import Item from './Item';
// styles
import {
  ContainerLables,
  ContainerLablesItem,
  TableTitle,
  Wrap,
} from './styles';
import { IWorkOrdersTasks } from 'types';

interface IDivideTableProps {
  containerTasks: IWorkOrdersTasks[];
  commonTasks: IWorkOrdersTasks[];
  values: IValuesObject;
  setValues: Dispatch<SetStateAction<IValuesObject>>;
}

const DivideTable = ({
  containerTasks,
  commonTasks,
  values,
  setValues,
}: IDivideTableProps): JSX.Element => (
  <Wrap>
    <ContainerLables>
      {containerTasks.map(container => (
        <ContainerLablesItem key={container.mapId}>
          <TableTitle>{container.name}</TableTitle>
        </ContainerLablesItem>
      ))}
    </ContainerLables>
    {commonTasks.map(task => (
      <Item
        key={task.mapId}
        containerTasks={containerTasks}
        item={task}
        values={values}
        setValues={setValues}
      />
    ))}
  </Wrap>
);

export default DivideTable;
