import styled from 'styled-components';

export const PopoverContent = styled.div<{
  $width?: number;
}>`
  width: ${({ $width }) => ($width ? `${$width}px` : '302px')};
  cursor: auto;
`;

export const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
`;
export const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 0 2px;
`;
