import * as yup from 'yup';

const resourceGroupSchema = yup.object().shape({
  name: yup
    .string()
    .max(100, 'Name must be at most 100 characters')
    .trim()
    .required('Name is required!'),
});

export default resourceGroupSchema;
