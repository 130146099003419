import theme from 'styles/theme';

interface IBellProps {
  color?: string;
  size?: number;
}
export const Bell = ({ color, size = 6 }: IBellProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 14.4825V10C20.25 5.97875 17.5187 2.59125 13.8187 1.5725C13.4525 0.65 12.5575 0 11.5 0C10.4425 0 9.5475 0.65 9.18125 1.5725C5.48125 2.5925 2.75 5.97875 2.75 10V14.4825L0.61625 16.6163C0.38125 16.85 0.25 17.1675 0.25 17.5V20C0.25 20.6912 0.80875 21.25 1.5 21.25H21.5C22.1912 21.25 22.75 20.6912 22.75 20V17.5C22.75 17.1675 22.6188 16.85 22.3838 16.6163L20.25 14.4825ZM20.25 18.75H2.75V18.0175L4.88375 15.8837C5.11875 15.65 5.25 15.3325 5.25 15V10C5.25 6.55375 8.05375 3.75 11.5 3.75C14.9462 3.75 17.75 6.55375 17.75 10V15C17.75 15.3325 17.8812 15.65 18.1162 15.8837L20.25 18.0175V18.75ZM11.5 25C13.1388 25 14.5087 23.9575 15.0225 22.5H7.9775C8.49125 23.9575 9.86125 25 11.5 25Z"
      fill={color || theme.colors.mainDarkBlue}
    />
  </svg>
);
