import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import { globalSearch, locations, projectsAllFilter, roles } from './actions';
import { LocationDto } from './../../types/backend.types';

const predefined = createReducer(initialState.predefined, {
  [locations.SUCCESS]: (state, action) => {
    state.locations = action.payload.map(
      (item: LocationDto & { _id: string }) => ({
        ...item,
        label: item.name,
        value: item._id,
      })
    );
  },
  [roles.SUCCESS]: (state, action) => {
    state.roles = action.payload.map((item: string) => ({
      label: item[0].toUpperCase() + item.slice(1),
      value: item,
    }));
  },
  [String(globalSearch)]: (state, action) => {
    state.globalSearch = action.payload;
  },
  [String(projectsAllFilter)]: (state, action) => {
    state.searchAllProjects = action.payload;
  },
});

export { predefined as reducer };
