import styled from 'styled-components';
import { Button } from 'components/common';

export const Content = styled.div``;

export const ButtonsWrap = styled.div`
  display: flex;
  gap: 5px;
`;
export const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 12px;
`;

export const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 28px;
`;

export const Container = styled.div`
  .ant-popover {
    padding: 0;
    max-width: 250px;
    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-popover-inner-content {
    border: 1px solid #00000012;
    border-radius: 4px;
  }
  .ant-popover-inner {
    box-shadow: none;
  }
`;
