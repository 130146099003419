import { SubtaskBlock } from './SubtaskBlock';
import { ISubtask } from 'types';

export const SubtasksBlock = ({
  subtasks,
  form,
  template,
  recipientsList,
  templatesList,
}) =>
  subtasks?.map((subtask: ISubtask, index) => (
    <SubtaskBlock
      key={subtask._id}
      index={index}
      form={form}
      template={template}
      subtask={subtask}
      templatesList={templatesList}
      recipientsList={recipientsList}
    />
  ));
