// components
import { ChangeEvent } from 'react';
import { BUTTON_TYPES, ConfirmationPopover } from 'components/common';
// styles
import { ButtonDeleteStyled } from './styles';

interface IDeleteButtonProps {
  remove: (e?: ChangeEvent) => void;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
}

const DeleteButton = ({
  remove,
  title,
  loading,
  disabled = false,
}: IDeleteButtonProps): JSX.Element => (
  <ConfirmationPopover loading={loading} text={title} onOk={remove}>
    <ButtonDeleteStyled
      disabled={disabled}
      kind={BUTTON_TYPES.DANGER}
      ant-click-animating-without-extra-node="false"
      label="Delete"
      space="7px"
    />
  </ConfirmationPopover>
);

export default DeleteButton;
