import styled from 'styled-components';
import { Row, Typography } from 'antd';

export const PageWrap = styled.div`
  height: 100vh;
  display: block;
  background-color: ${({ theme }) => theme.colors.grayLight};
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 992px) and (orientation: portrait) {
    padding-bottom: 100px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 746px;
  height: 100vh;
  padding: 3% 10px 2% 10px;
  margin: 0 auto;
  @media (max-width: 992px) {
    height: auto;
  }
`;

export const TitleWrap = styled(Row)`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  flex-wrap: initial;
  min-height: 99px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  width: 100%;
  padding: 27px 5px;
`;

export const Title = styled(Typography.Paragraph)`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 5px !important;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  text-align: center;
  white-space: normal;
  word-break: break-all;
`;

export const Description = styled(Typography.Paragraph)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: 0 !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Body = styled(Row)`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    flex-grow: 0;
    overflow: visible;
  }
`;

export const ProjectInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 40%;
  @media (max-width: 992px) {
    width: 100%;
    flex-grow: 0;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-grow: 0;
  width: 100%;
  justify-content: center;
  min-height: 93px;
`;
