interface IDeleteProjectProps {
  size?: number;
}

export const DeleteProject = ({
  size = 20,
}: IDeleteProjectProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#FFE1E1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3333 8V14.6667H7V8H12.3333ZM11.3333 4H8L7.33333 4.66667H5V6H14.3333V4.66667H12L11.3333 4ZM13.6667 6.66667H5.66667V14.6667C5.66667 15.4 6.26667 16 7 16H12.3333C13.0667 16 13.6667 15.4 13.6667 14.6667V6.66667Z"
      fill="#FF3939"
    />
  </svg>
);
