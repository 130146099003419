import styled from 'styled-components';
import { Button } from 'components/common';
import { Layout, Row } from 'antd';

export const Wrap = styled.div`
  @media (max-width: 700px) {
    margin: 0 -20px;
  }
`;

export const LayoutStyled = styled(Layout)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
`;

export const TaskWrapper = styled(Row)`
  flex-direction: column;
  margin: 11px 0 0;
  padding: 11px 0 0;
  background: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 12px;
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: 12px 0;
`;

export const TasksList = styled.div``;

export const ButtonStyled = styled(Button)`
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;
