import styled from 'styled-components';

export const CloseWrapper = styled.div`
  width: 24px;
  height: 24px;
  background: #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`;
