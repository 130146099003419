import { SVGProps } from 'react';

export const QRCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.3336 5.79733C14.9649 5.79733 14.6669 5.49866 14.6669 5.13066V1.97067C14.6669 1.61933 14.3809 1.33333 14.0303 1.33333H10.8703C10.5016 1.33333 10.2036 1.03467 10.2036 0.666666C10.2036 0.298666 10.5016 0 10.8703 0H14.0303C15.1169 0 16.0003 0.883999 16.0003 1.97067V5.13066C16.0003 5.49866 15.7023 5.79733 15.3336 5.79733Z"
      fill="#172B4D"
    />
    <path
      d="M0.666666 5.79733C0.298 5.79733 0 5.49866 0 5.13066V1.97067C0 0.883999 0.883333 0 1.97 0H5.13C5.49866 0 5.79666 0.298666 5.79666 0.666666C5.79666 1.03467 5.49866 1.33333 5.13 1.33333H1.97C1.61933 1.33333 1.33333 1.61933 1.33333 1.97067V5.13066C1.33333 5.49866 1.03533 5.79733 0.666666 5.79733Z"
      fill="#172B4D"
    />
    <path
      d="M14.0303 16H10.8703C10.5016 16 10.2036 15.7013 10.2036 15.3333C10.2036 14.9653 10.5016 14.6666 10.8703 14.6666H14.0303C14.3809 14.6666 14.6669 14.3806 14.6669 14.0293V10.8693C14.6669 10.5013 14.9649 10.2026 15.3336 10.2026C15.7023 10.2026 16.0003 10.5013 16.0003 10.8693V14.0293C16.0003 15.116 15.1169 16 14.0303 16Z"
      fill="#172B4D"
    />
    <path
      d="M5.13 16H1.97C0.883333 16 0 15.116 0 14.0293V10.8693C0 10.5013 0.298 10.2026 0.666666 10.2026C1.03533 10.2026 1.33333 10.5013 1.33333 10.8693V14.0293C1.33333 14.3806 1.61933 14.6666 1.97 14.6666H5.13C5.49866 14.6666 5.79666 14.9653 5.79666 15.3333C5.79666 15.7013 5.49866 16 5.13 16Z"
      fill="#172B4D"
    />
    <path
      d="M6.31883 7.43937H3.00016C2.6315 7.43937 2.3335 7.1407 2.3335 6.7727V3.00004C2.3335 2.63204 2.6315 2.33337 3.00016 2.33337H6.31883C6.68749 2.33337 6.98549 2.63204 6.98549 3.00004V6.7727C6.98549 7.1407 6.68683 7.43937 6.31883 7.43937ZM3.66683 6.10604H5.65216V3.66671H3.66683V6.10604Z"
      fill="#172B4D"
    />
    <path
      d="M12.9998 13.6667H9.83317C9.4645 13.6667 9.1665 13.368 9.1665 13V10.8787C9.1665 10.5107 9.4645 10.212 9.83317 10.212C10.2018 10.212 10.4998 10.5107 10.4998 10.8787V12.3333H12.3332V10.3333H11.5452C11.1765 10.3333 10.8785 10.0347 10.8785 9.66667C10.8785 9.29867 11.1765 9 11.5452 9H12.9998C13.3685 9 13.6665 9.29867 13.6665 9.66667V13C13.6665 13.368 13.3685 13.6667 12.9998 13.6667Z"
      fill="#172B4D"
    />
    <path
      d="M13 6.39404H10.2726C9.90396 6.39404 9.60596 6.09537 9.60596 5.72737V3.00004C9.60596 2.63204 9.90396 2.33337 10.2726 2.33337H13C13.3686 2.33337 13.6666 2.63204 13.6666 3.00004V5.72737C13.6666 6.09537 13.3686 6.39404 13 6.39404ZM10.9393 5.06071H12.3333V3.66671H10.9393V5.06071Z"
      fill="#172B4D"
    />
    <path
      d="M8.31882 9.42411H3.00016C2.6315 9.42411 2.3335 9.12545 2.3335 8.75745C2.3335 8.38945 2.6315 8.09078 3.00016 8.09078H7.65216V3.60612C7.65216 3.23812 7.95016 2.93945 8.31882 2.93945C8.68749 2.93945 8.98549 3.23812 8.98549 3.60612V8.75745C8.98549 9.12545 8.68682 9.42411 8.31882 9.42411Z"
      fill="#172B4D"
    />
    <path
      d="M10.1667 9.25738C9.798 9.25738 9.5 8.95871 9.5 8.59071V7.68205C9.5 7.31405 9.798 7.01538 10.1667 7.01538H13.1667C13.5353 7.01538 13.8333 7.31405 13.8333 7.68205C13.8333 8.05005 13.5353 8.34871 13.1667 8.34871H10.8333V8.59071C10.8333 8.95871 10.5353 9.25738 10.1667 9.25738Z"
      fill="#172B4D"
    />
    <path
      d="M8.01481 12.8006C7.64614 12.8006 7.34814 12.502 7.34814 12.134V10.6193C7.34814 10.2513 7.64614 9.95264 8.01481 9.95264C8.38348 9.95264 8.68148 10.2513 8.68148 10.6193V12.134C8.68148 12.502 8.38348 12.8006 8.01481 12.8006Z"
      fill="#172B4D"
    />
    <path
      d="M6.16683 13.6668H3.00016C2.6315 13.6668 2.3335 13.3681 2.3335 13.0001V10.5761C2.3335 10.2081 2.6315 9.90942 3.00016 9.90942H6.16683C6.53549 9.90942 6.83349 10.2081 6.83349 10.5761V13.0001C6.83349 13.3681 6.53549 13.6668 6.16683 13.6668ZM3.66683 12.3334H5.50016V11.2428H3.66683V12.3334Z"
      fill="#172B4D"
    />
  </svg>
);
