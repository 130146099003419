import { SVGProps } from 'react';

function CollapseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={8}
      fill="#172B4D"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.293.293L6 4.586 1.707.293.293 1.707 6 7.414l5.707-5.707L10.293.293z" />
    </svg>
  );
}

export default CollapseIcon;
