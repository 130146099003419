import { SVGProps } from 'react';

export function ProjectSettingsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.332 2.236H2.665c-.735 0-1.333.598-1.333 1.334v1.333c0 .49.27.917.667 1.148v6.852c0 .735.598 1.333 1.333 1.333h9.333c.736 0 1.334-.598 1.334-1.333V6.051a1.33 1.33 0 00.666-1.148V3.57c0-.736-.598-1.334-1.333-1.334zM2.665 3.57h10.667l.001 1.333H2.665V3.57zm.667 9.333V6.236h9.333l.002 6.667H3.332z" />
      <path d="M5.332 7.57h5.333v1.333H5.332V7.569z" />
    </svg>
  );
}
