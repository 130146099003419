import { memo } from 'react';
import MailIcon from 'lib/icons/MailIcon';
import { Container, Content, IconWrapper, Text, Title } from './styles';

interface IInviteBlockProps {
  companyName: string;
}

const InviteBlock = ({ companyName }: IInviteBlockProps): JSX.Element => (
  <Container>
    <IconWrapper>
      <MailIcon />
    </IconWrapper>
    <Content>
      <Title>You was invited to {companyName}</Title>
      <Text>Please complete fields to create your account</Text>
    </Content>
  </Container>
);

export default memo(InviteBlock);
