import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* inboundRoot() {
  yield all([
    fork(W.watchWorkOrders),
    fork(W.watchWorkOrder),
    fork(W.watchDivideTasks),
    fork(W.watchWorkOrdersInfo),
    fork(W.watchCreateProjectFormWorkOrder),
    fork(W.watchDeleteWorkOrders),
  ]);
}
