import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* depotCompaniesSaga() {
  yield all([
    fork(W.watchTruckingCompanies),
    fork(W.watchAddTruckingCompany),
    fork(W.watchDeleteTruckingCompany),
    fork(W.watchEditeTruckingCompany),
    fork(W.watchTruckingCompaniesById),
  ]);
}
