import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/company';
import * as E from './workers';

export function* watchAdministrators() {
  yield call(E.ensureAdministrators, {
    type: Actions.administrators.TRIGGER,
  });
  yield takeLatest(Actions.administrators.TRIGGER, E.ensureAdministrators);
}

export function* watchDeleteUsers() {
  yield takeLatest(Actions.deleteUser.TRIGGER, E.ensureDeleteUser);
}

export function* watchGetUserAdmin() {
  yield takeLatest(Actions.getUserAdmin.TRIGGER, E.ensureGetUserAdmin);
}

export function* watchPutUserAdmin() {
  yield takeLatest(Actions.updateUserAdmin.TRIGGER, E.ensurePutUserAdmin);
}

export function* watchPatchUserAdmin() {
  yield takeLatest(Actions.patchUserAdmin.TRIGGER, E.ensurePatchUserAdmin);
}

export function* watchAvatarAdmin() {
  yield takeLatest(Actions.updateAvatarAdmin.TRIGGER, E.ensureAvatarAdmin);
}
