import { Link as BaseLink } from 'react-router-dom';
import styled from 'styled-components';
import { Button as BaseButton } from 'components/common';

export const Wrapper = styled.div`
  padding: 18px 18px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  max-width: 764px;
  width: 100%;
  margin-bottom: 22px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 16px;
`;

export const Button = styled(BaseButton)`
  font-size: 14px;
  padding: 9px 26px;
`;

export const Link = styled(BaseLink)`
  display: inline-block;
`;
