import { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';

const useAutocomplete = (
  getOptionsForAutocomplete: Function,
  typeSearch: string,
  nameSearch: string,
  isInvoice?: boolean,
  isChecklist?: boolean
) => {
  const [options, setOptions] = useState([]);
  const params: any = {};

  if (typeSearch) {
    params.type = typeSearch;
  }

  const getOptions = useCallback(
    val => {
      getOptionsForAutocomplete({
        [nameSearch]:
          isInvoice || isChecklist
            ? val
            : `/${val.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')}/gi`,
        populate: 'related',
        ...params,
      })
        .then(res => {
          const options = res.map(item =>
            isInvoice
              ? {
                  value: item,
                  key: item,
                  label: item,
                }
              : {
                  ...item,
                  value: isChecklist ? item.title : item._id,
                  key: isChecklist ? item.title : item._id,
                  label: item[isChecklist ? 'title' : nameSearch],
                }
          );
          setOptions(options);
        })
        .catch(err => console.error(err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getOptionsForAutocomplete,
      typeSearch,
      setOptions,
      nameSearch,
      isInvoice,
      isChecklist,
    ]
  );

  const delayedQuery = useRef(
    debounce(val => {
      if (val) getOptions(val);
      else setOptions([]);
    }, 300)
  ).current;

  const onSearch = useCallback(
    searchText => delayedQuery(searchText),
    [delayedQuery]
  );

  const onFieldChange = useCallback(
    (value, option, onChange, setOptionsField) => {
      if (option?.value) {
        onChange(option);
        setOptionsField([]);
        delayedQuery(option.label);
        return option.label;
      }
      return value;
    },
    [delayedQuery]
  );

  return [
    onSearch as Function,
    options as any,
    setOptions as Function,
    onFieldChange as Function,
  ];
};

export default useAutocomplete;
