import { ITagProps } from 'components/SubtasksTags/Tag';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { IResource, ISubtaskDto } from 'types';

const isSafari = /^((?!chrome|android).)*safari/i.test(
  window.navigator.userAgent
);

export const getHelpsData = (
  props: Partial<ITagProps>,
  subtaskData: ISubtaskDto,
  isWeek: boolean,
  msInPixel: number
) => {
  const title = `${props.invoice} • #${props.taskOrder} ${
    subtaskData.taskName ? subtaskData.taskName : props.taskName
  } • ${subtaskData.name}`;
  const width = isWeek ? Math.round((23 * 60 * 60 * 1000) / msInPixel) : 300;
  const mouseOffset = !isWeek && isSafari ? 300 : 0;
  const item = {
    id: uuidv4(),
    subtaskId: subtaskData._id,
    type: 'subtask',
    title,
    actualHours: subtaskData.actualHours || 0,
    templateHours: subtaskData.templateHours || 0,
    plannedHours: subtaskData.plannedHours,
    color: props.color,
    projectId: props.projectId,
    isCanMove: true,
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    needReview: false,
    subtaskTypeId: subtaskData.subtaskTypeId,
    hasFiles: !!subtaskData?.files?.length,
    hasNotes: !!subtaskData?.notes,
    hasImages: !!subtaskData?.fileUrls?.length,
  };
  return { title, width, item, mouseOffset };
};

export const getDataForAssign = (resource: IResource) => {
  const { workingTime } = resource;
  const startDate = moment().set({
    hour: workingTime.from.hours,
    minute: workingTime.from.minutes,
    second: 0,
    millisecond: 0,
  });
  return { startDate, resource };
};
