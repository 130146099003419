import { all, fork } from 'redux-saga/effects';
import * as W from './watchers';

export function* subtaskTypesSaga() {
  yield all([
    fork(W.watchUpdateSubtaskType),
    fork(W.watchGetSubtaskType),
    fork(W.watchDeleteSubtaskType),
    fork(W.watchAddChecklistItem),
    fork(W.watchAddBulkChecklist),
    fork(W.watchGetChecklistItem),
    fork(W.watchUpdateChecklistItem),
    fork(W.watchDeleteChecklistItem),
  ]);
}
