import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 36px;
  color: ${({ theme }) => theme.colors.lightBlue};
  input {
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;
export const Title = styled.h1`
  margin: 0 0 9px;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 17px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 420px) {
    flex-direction: column;
    gap: 15px;
    .ant-btn {
      width: 100%;
    }
  }
`;

export const VerificationWrapper = styled.div`
  margin-bottom: 24px;
  @media (max-width: 420px) {
    display: flex;
    justify-content: center;
    .styles_react-code-input__CRulA {
    }
  }

  input {
    width: 48px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    border-radius: 6px;
    margin: 2px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.fullBlack};
    font-family: 'Inter', sans-serif;
  }
  input:last-child {
    border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
  }

  .code-wrapper {
    width: 100% !important;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > div > input:focus {
      outline: none;
      border: 2px solid ${({ theme }) => theme.colors.primary};
      box-shadow: 0px 0px 0px 2px rgba(253, 88, 0, 0.13);
      caret-color: black;
    }
    @media (max-width: 420px) {
      width: 180px !important;
      & > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
`;
