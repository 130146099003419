import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* tasksSaga() {
  yield all([
    fork(W.watchGetTasks),
    fork(W.watchDeleteTask),
    fork(W.watchGetTaskById),
    fork(W.watchAddTasks),
    fork(W.watchGetRelatedTasks),
  ]);
}
