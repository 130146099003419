import { all, fork, select, take } from 'redux-saga/effects';
import { resourcesSaga, subtaskTypesSaga } from 'store/admin';
import {
  currentLocation,
  currentLocationSelector,
  isSalesManagerSelector,
} from 'store/auth';
import { companyAdministratorsSaga } from 'store/company';
import * as Sharing from 'store/sharing';
import * as TasksWatchers from '../project/tasks/watchers';
import * as W from './watchers';
import { connect } from 'sagas/socketSaga';

export function* dashboardSaga() {
  const currentStoreLocation = yield select(currentLocationSelector);
  if (!currentStoreLocation) {
    yield take(currentLocation);
  }
  const isSales = yield select(isSalesManagerSelector);
  if (isSales) {
    yield all([
      fork(W.watchProjects),
      fork(Sharing.sharingSaga),
      fork(connect),
    ]);
  } else {
    yield all([
      fork(W.watchSubtasksUnreadMessagesCount),
      fork(W.watchProjects),
      fork(TasksWatchers.watchAddTasks),
      fork(W.watchGantt),
      fork(W.watchResizeSubtask),
      fork(W.watchUnassignSubtask),
      fork(W.watchResultSubtask),
      fork(W.watchApproveSubtask),
      fork(W.watchRejectSubtask),
      fork(W.watchStandaloneSubtask),
      fork(W.watchResourceExcel),
      fork(W.watchSubtaskDetails),
      fork(W.watchSetScreenDistribution),
      fork(W.watchGetTaskNotification),
      fork(W.watchCreateTaskNotification),
      fork(W.watchUpdateTaskNotification),
      fork(W.watchDeleteTaskNotification),
      fork(connect),
    ]);
  }
}

export function* mainRoot() {
  yield all([
    fork(W.watchPostProject),
    fork(W.watchPostDuplicateProject),
    fork(W.watchSubtaskLogItems),
    fork(W.watchUpdateSubtaskLogItem),
    fork(W.watchPutProject),
    fork(W.watchPatchProjectWeight),
    fork(W.watchPutAttachments),
    fork(W.watchPutAttachmentsById),
    fork(W.watchDeleteAttachments),
    fork(W.watchPartialProjectUpdate),
    fork(W.watchAddFileToProjectByDrop),
    fork(W.watchAddFileToSubtaskByDrop),
    fork(W.watchMarkSubtaskAsToDo),
    fork(W.watchMarkSubtaskAsDone),
    fork(W.watchMarkSubtaskAsActive),
    fork(W.watchMarkSubtaskAsNotReady),
    fork(W.watchMarkSubtaskAsPaused),
    fork(W.watchMarkSubtaskAsResumed),
    fork(W.watchAssignSubtask),
    fork(W.watchProject),
    fork(W.watchPutSubtasksAttachments),
    fork(W.watchDeleteSubtaskFilesById),
    fork(W.watchDeleteSubtaskFiles),
    fork(W.watchDeleteProjectFile),
    fork(W.watchInvoicesSearch),
    fork(W.watchDelegateSubtask),
    fork(W.watchRevokeSubtask),
    fork(W.watchGetSubtaskMessages),
    fork(W.watchSendMessageReply),
    fork(W.watchSendSubtaskMessage),
    fork(W.watchDeleteSubtaskMessages),
    fork(W.watchDeleteSubtaskMessage),
    fork(W.watchUpdateSubtaskMessage),
    fork(W.WatchMarkSubtaskMessagesAsSeen),
    fork(W.watchForceAssignResource),
    fork(W.watchMarkChecklistCompleted),
    fork(W.watchMarkChecklistUncompleted),
    fork(W.watchSearchChecklistItems),
    fork(W.watchDeleteChatMessagesBySubtaskIds),
    fork(resourcesSaga),
    fork(companyAdministratorsSaga),
    fork(Sharing.sharingSaga),
    fork(subtaskTypesSaga),
  ]);
}
