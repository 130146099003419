import { SVGProps } from 'react';
import theme from 'styles/theme';

export const Xerox = ({
  color = theme.colors.lightBlue,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 5C18.66 5 20 6.34 20 8V14H16V18H4V14H0V8C0 6.34 1.34 5 3 5H4V0H16V5H17ZM6 2V5H14V2H6ZM14 16V12H6V16H14ZM16 12H18V8C18 7.45 17.55 7 17 7H3C2.45 7 2 7.45 2 8V12H4V10H16V12ZM16 9.5C15.4477 9.5 15 9.05228 15 8.5C15 7.94772 15.4477 7.5 16 7.5C16.5523 7.5 17 7.94772 17 8.5C17 9.05228 16.5523 9.5 16 9.5Z"
      fill={color}
    />
  </svg>
);
