import styled from 'styled-components';
import theme from 'styles/theme';

export const Top = styled.div<{
  withDnD?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.grayDark};
  padding: ${({ withDnD }) =>
    withDnD ? '4px 8px 4px 6px' : '4px 8px 4px 10px'};
  .ant-popover-inner-content {
    padding: 8px;
  }
`;

export const MainInfo = styled.div`
  padding: 5px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DragAndDropWrap = styled.div<any>`
  opacity: 0.7;
  margin: 5px 8px 0 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Subtask = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

export const MainInfoText = styled.div<{
  $isProjectViewTaskMode: boolean;
}>`
  color: ${({ theme, $isProjectViewTaskMode }) =>
    $isProjectViewTaskMode
      ? theme.colors.mainDarkBlue
      : theme.colors.lightText};
  font-weight: ${({ $isProjectViewTaskMode }) =>
    $isProjectViewTaskMode ? 500 : 400};
  font-size: 12px;
  line-height: 15px;
`;

export const MainInfoRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`;

export const AvatarWrapper = styled.div`
  margin-right: 8px;
`;
