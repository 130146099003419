import { forwardRef } from 'react';
import { useDragLayer } from 'react-dnd';
// utils
import { getItemStyles } from 'components/SubtaskViewComponents/helpers';
import { HasDescriptions, HasFiles, HasPhoto } from 'lib/icons';
// styles
import { PreviewInner, PreviewWrap } from './styles';
import { DragTypeEnum } from 'types';

interface IPreviewProps {
  color?: string;
  width?: number;
  title?: string;
  item?: {
    hasFiles?: boolean;
    hasNotes?: boolean;
    hasImages?: boolean;
  };
}
const Preview = forwardRef(
  ({ color, width, title, item }: IPreviewProps, ref) => {
    const { isDragging, initialOffset, clientOffset } = useDragLayer(
      monitor => ({
        initialOffset: monitor.getInitialSourceClientOffset(),
        clientOffset: monitor.getClientOffset(),
        isDragging:
          monitor.getItemType() === DragTypeEnum.subtask &&
          monitor.isDragging(),
      })
    );

    if (!isDragging) return null;

    return (
      <PreviewWrap
        ref={ref}
        width={width}
        style={getItemStyles(initialOffset, clientOffset)}
      >
        <PreviewInner color={color}>
          <span>
            {title}
            {item?.hasFiles && (
              <HasFiles
                style={{ marginLeft: 8, marginBottom: -3 }}
                color={color}
              />
            )}
            {item?.hasNotes && (
              <HasDescriptions
                style={{ marginLeft: 8, marginBottom: -3 }}
                color={color}
              />
            )}
            {item?.hasImages && (
              <HasPhoto
                style={{ marginLeft: 8, marginBottom: -3 }}
                color={color}
              />
            )}
          </span>
        </PreviewInner>
      </PreviewWrap>
    );
  }
);

export default Preview;
