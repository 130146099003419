import styled from 'styled-components';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const ReplyInput = styled(Input.TextArea)`
  resize: none;
  margin-top: 2px;
  display: flex;
  padding: 5px 25px 5px 10px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  width: 100%;
  outline: none;
  line-height: 2.5;
  min-height: 32px !important;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    box-shadow: none;
  }
`;

export const ReplyWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Line = styled.div`
  width: 13px;
  height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-top: none;
  border-right: none;
  margin-right: 6px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const SendIconWrap = styled.div<{
  isActive?: boolean;
}>`
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  position: absolute;
  right: 11px;
  bottom: 7px;
  height: 18px;
`;

export const Loader = styled(LoadingOutlined)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
