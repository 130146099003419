export const Download = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.944 9.112C16.507 5.67 13.559 3 10 3C7.244 3 4.85 4.611 3.757 7.15C1.609 7.792 0 9.82 0 12C0 14.757 2.243 17 5 17H6V15H5C3.346 15 2 13.654 2 12C2 10.596 3.199 9.243 4.673 8.984L5.254 8.882L5.446 8.324C6.149 6.273 7.894 5 10 5C12.757 5 15 7.243 15 10V11H16C17.103 11 18 11.897 18 13C18 14.103 17.103 15 16 15H14V17H16C18.206 17 20 15.206 20 13C20 11.119 18.695 9.538 16.944 9.112Z"
      fill="#172B4D"
    />
    <path d="M11 12V8H9V12H6L10 17L14 12H11Z" fill="#172B4D" />
  </svg>
);
