import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  gap: 8px;
`;

export const EditorLabel = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  padding: 10px 0 3px 0;
`;

export const FormWrapper = styled.div`
  .templates-editor-toolbar .rdw-colorpicker-modal {
    width: 200px;
    height: auto;
  }

  .rdw-link-modal {
    height: auto;
  }
`;

export const Title = styled.div`
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const LocationSelectContainer = styled.div`
  margin-left: 10px;
`;
