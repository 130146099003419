import { memo, useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import { userSelector } from 'store/auth';
import {
  clearSubtaskMessages,
  deleteSubtaskMessageCreator,
  getSubtaskMessagesCreator,
  sendSubtaskMessageCreator,
  subtaskMessagesSelector,
  updateSubtaskMessageCreator,
} from 'store/dashboard';
import Message from './Message';
import ReplyMessageBody from './ReplyMessage/ReplyMessage';
import { BUTTON_TYPES, Button } from 'components/common';
import CloseButton from 'components/common/CloseButton/CloseButton';
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { Cross } from 'lib/icons';
import { Send } from 'lib/icons/Send';
import SubDirectoryArrowIcon from 'lib/icons/SubDirectoryArrowIcon';
import theme from 'styles/theme';
import { Spin } from 'antd';
// styled
import {
  ArrowWrapper,
  CloseWrap,
  CloseWrapper,
  ContentWrap,
  InputContainer,
  InputWrap,
  ReplyContent,
  ReplyCreator,
  ReplyForInput,
  SendIconWrap,
  TextAreaItem,
  TextAreaWrapper,
  Title,
  TitleWrap,
  Wrap,
} from './styles';
import { LoadingOutlinedStyled } from 'components/common/SelectInput/styles';
import { ISubtaskEditMessage } from 'types';

const CHAT_HEIGHT = 216;
const ICON_SIZE = 18;
interface IChatContentProps {
  subtaskId: string;
  handleCloseChat: () => void;
  isInProject?: boolean;
  isProjectDeleted: boolean;
  isProjectArchived: boolean;
}
const ChatContentComponent = ({
  subtaskId,
  handleCloseChat,
  isInProject = false,
  isProjectDeleted,
  isProjectArchived,
}: IChatContentProps) => {
  const user = useSelector(userSelector);
  const messages = useSelector(subtaskMessagesSelector);
  const [text, setText] = useState('');
  const [scrollToReplyId, setScrollToReplyId] = useState(null);
  const [editItem, setEditItem] = useState<ISubtaskEditMessage | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [messagesInit, setMessagesInit] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [dataForReply, setDataForReply] = useState(null);

  const sendMessage = useActionsRoutines(sendSubtaskMessageCreator);
  const getMessages = useActionsRoutines(getSubtaskMessagesCreator);
  const deleteMessage = useActionsRoutines(deleteSubtaskMessageCreator);
  const editMessage = useActionsRoutines(updateSubtaskMessageCreator);
  const clearMessages = useActions(clearSubtaskMessages);

  const scrollRef = useRef<any>();
  const memoizedDataForReply = useMemoCompare(dataForReply);

  const handleClearReplyMessage = useCallback(() => {
    setDataForReply(null);
    setText('');
  }, []);

  useEffect(() => {
    if (memoizedDataForReply) inputRef.current.focus();
  }, [memoizedDataForReply]);

  const requestMessages = useCallback(
    async () =>
      getMessages({ subtaskId, isInProject }).catch(err => console.error(err)),
    [subtaskId, getMessages, isInProject]
  );

  const handleChangeText = useCallback(e => setText(e?.target?.value), []);
  const sendText = useCallback(() => {
    if (text.trim().length === 0) {
      setText('');
      return;
    }
    if (text) {
      setText('');
      setIsMessageSending(true);

      if (editItem) {
        editMessage({
          subtaskId: editItem?.subtaskId,
          messageId: editItem?.message?._id,
          body: text.trim(),
        })
          .catch(e => console.error(e))
          .finally(() => {
            setIsMessageSending(false);
            setEditItem(undefined);
          });
      } else {
        const baseData = {
          id: subtaskId,
          body: text.trim(),
        };
        const sendData = memoizedDataForReply
          ? {
              ...baseData,
              replyToMessageId: memoizedDataForReply.message._id,
            }
          : baseData;
        sendMessage(sendData)
          .then(async () =>
            requestMessages().finally(() =>
              scrollRef?.current?.scrollToBottom()
            )
          )
          .finally(() => {
            if (memoizedDataForReply) handleClearReplyMessage();
            setIsMessageSending(false);
          });
      }
    }
  }, [
    text,
    editItem,
    memoizedDataForReply,
    editMessage,
    handleClearReplyMessage,
    requestMessages,
    sendMessage,
    subtaskId,
    scrollRef,
  ]);

  const handleEnterClick = useCallback(
    e => {
      if (!isMessageSending && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        sendText();
      }
    },
    [isMessageSending, sendText]
  );

  const handleStopPropagation = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    return;
  }, []);

  useEffect(() => {
    setMessagesInit(true);

    requestMessages().finally(() => {
      setMessagesInit(false);
      scrollRef?.current?.scrollToBottom();
    });

    return () => clearMessages();
  }, [subtaskId, clearMessages, requestMessages, scrollRef]);

  useEffect(() => {
    scrollRef?.current?.scrollToBottom();
  }, [messages, scrollRef]);

  const handleSetEditItem = useCallback(item => {
    setText(item?.message?.body);
    setEditItem(item);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setText(undefined);
    setEditItem(undefined);
  }, []);

  const handleStartScroll = useCallback(() => setIsScrolling(true), []);

  const handleStopScroll = useCallback(() => setIsScrolling(false), []);

  const isYou = dataForReply?.message.creator._id === user._id;
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Wrap onDoubleClick={handleStopPropagation} messagesInit={messagesInit}>
      <ContentWrap $isInProject={isInProject}>
        <TitleWrap>
          <Title>Chat</Title>
          <CloseWrap>
            <CloseButton onClick={handleCloseChat} />
          </CloseWrap>
        </TitleWrap>
        <Scrollbar
          onScrollStart={handleStartScroll}
          onScrollStop={handleStopScroll}
          noScrollX
          ref={scrollRef}
          style={{
            height: `${CHAT_HEIGHT}px`,
          }}
        >
          {!messagesInit ? (
            messages?.map((message, index) => (
              <Message
                isProjectArchived={isProjectArchived}
                isProjectDeleted={isProjectDeleted}
                scrollToReplyId={scrollToReplyId}
                setScrollToReplyId={setScrollToReplyId}
                isDataForReplyExists={!!dataForReply}
                setDataForReply={setDataForReply}
                isScrolling={isScrolling}
                key={message._id}
                message={message}
                messages={messages}
                user={user}
                index={index}
                subtaskId={subtaskId}
                editItem={editItem}
                setEditMessage={handleSetEditItem}
                deleteMessage={deleteMessage}
              />
            ))
          ) : (
            <LoadingOutlinedStyled style={{ fontSize: '40px' }} spin />
          )}
        </Scrollbar>
      </ContentWrap>
      <InputWrap>
        <InputContainer>
          {!!dataForReply && (
            <ReplyForInput>
              <ArrowWrapper>
                <SubDirectoryArrowIcon />
              </ArrowWrapper>

              <ReplyContent>
                <ReplyCreator>
                  {isYou
                    ? 'You'
                    : `${dataForReply.message.creator.firstName} 
                  ${dataForReply.message.creator.lastName}`}
                </ReplyCreator>
                <ReplyMessageBody replyMessage={dataForReply.message.body} />
              </ReplyContent>

              <CloseWrapper onClick={handleClearReplyMessage}>
                <Cross size={10} color={theme.colors.lightBlue} />
              </CloseWrapper>
            </ReplyForInput>
          )}
          <TextAreaWrapper>
            <TextAreaItem
              ref={inputRef}
              value={text}
              onChange={handleChangeText}
              placeholder="Type your message here..."
              onKeyDown={handleEnterClick}
              autoSize={{ maxRows: 5 }}
              disabled={isProjectDeleted || isProjectArchived}
            />
            <SendIconWrap isActive={Boolean(text)}>
              {!isMessageSending ? (
                <Send
                  onClick={sendText}
                  color={
                    text ? theme.colors.mainDarkBlue : theme.colors.lightBlue
                  }
                  size={ICON_SIZE}
                />
              ) : (
                <Spin
                  indicator={
                    <LoadingOutlinedStyled
                      style={{ fontSize: `${ICON_SIZE}px` }}
                      spin
                    />
                  }
                />
              )}
            </SendIconWrap>
          </TextAreaWrapper>
          {editItem && (
            <Button
              kind={BUTTON_TYPES.LINK}
              type="link"
              label="cancel editing"
              style={{
                padding: 0,
                justifyContent: 'flex-start',
                fontSize: '12px',
              }}
              onClick={handleCancelEdit}
            />
          )}
        </InputContainer>
      </InputWrap>
    </Wrap>
  );
};

export const ChatContent = memo(ChatContentComponent, isEqual);
