import { TASK_STATUSES } from './../../lib/constants/tasks';
import { PARTIAL_PROJECT_UPDATE_TYPES } from 'lib/constants';
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IProjectResponseDto,
  ITaskDto,
  SortTasksByEnum,
  StateEnum,
} from 'types';

export const prepareProjects = (
  projects: IProjectResponseDto[],
  payload: IProjectResponseDto[]
) => {
  const existProjects = projects.filter(item => !item.isDraft);
  return [...payload, ...existProjects];
};
export const TaskStatusSequence = [
  StateEnum.ToDo,
  StateEnum.InProgress,
  StateEnum.Done,
];
export const prepareProjectData = (data: IProjectResponseDto | IProjectResponseDto[]) => {
  const project = Array.isArray(data) ? data[0] : data;

  const allSubtasksCount = project.tasks.reduce(
    (count, item) => Number(count) + Number(item.subtasks.length),
    0
  );

  return {
    ...project,
    isUntracked: !allSubtasksCount,
  };
};

export const getDraftProject = (id: string) => {
  const draftProjects = JSON.parse(storageManager.getProjects()) || [];
  return draftProjects.find(item => item._id === id) || {};
};

export const getPartialProjectUpdateRequest = (
  type: PARTIAL_PROJECT_UPDATE_TYPES
) => {
  switch (type) {
    case PARTIAL_PROJECT_UPDATE_TYPES.DONE:
      return Api.projects.markProjectAsDone;
    case PARTIAL_PROJECT_UPDATE_TYPES.DELETE:
      return Api.projects.markProjectAsDeleted;
    case PARTIAL_PROJECT_UPDATE_TYPES.UNARCHIVE:
      return Api.projects.unarchiveProject;
    case PARTIAL_PROJECT_UPDATE_TYPES.RESTORE:
      return Api.projects.restoreProject;
    default:
      return Api.projects.markProjectAsArchived;
  }
};

export const updateGeneratedKey = (subtaskId, value, project, taskId) => {
  const task = project.tasks.find(item => item._id === taskId);
  const newTask = {
    ...task,
    subtasks: task.subtasks.map(subtask => {
      if (subtask._id === subtaskId) {
        return {
          ...subtask,
          generatedApiKey: value,
        };
      }
      return subtask;
    }),
  };
  const newProject = {
    ...project,
    tasks: project.tasks.map(item => {
      if (item._id === taskId) {
        return newTask;
      }
      return item;
    }),
  };
  return newProject;
};

export const updateProjectAfterAddingSubtasksFiles = (
  project,
  taskId,
  files,
  _id
) => {
  const task = project.tasks.find(item => item._id === taskId);
  const newLinetItem = {
    ...task,
    subtasks: task.subtasks.map(subtask => {
      if (subtask._id === _id) {
        return {
          ...subtask,
          files,
        };
      }
      return subtask;
    }),
  };
  const newProject = {
    ...project,
    tasks: project.tasks.map(item => {
      if (item._id === taskId) {
        return newLinetItem;
      }
      return item;
    }),
  };
  return newProject;
};

export const getProjectFiles = (
  prevProject: IProjectResponseDto,
  nextProject: IProjectResponseDto
) => ({
  ...nextProject,
  files: prevProject?.files,
  cover: prevProject?.cover,
  tasks: nextProject?.tasks.map(task => ({
    ...task,
    subtasks: task?.subtasks?.map(subtask => {
      const subtaskData = prevProject?.tasks
        ?.find(prevProjectTask => prevProjectTask._id === task._id)
        ?.subtasks?.find(
          prevProjectSubtask => prevProjectSubtask?._id === subtask?._id
        );

      return subtaskData
        ? {
          ...subtask,
          files: subtaskData?.files,
        }
        : subtask;
    }),
  })),
});

export const updateProjectsItemFields = (projects, prevProject, nextProject) =>
  projects.map(project =>
    project._id === nextProject._id ? nextProject : project
  );

export const sortTasks = (tasks: ITaskDto[], sortBy) => {
  let sortedTasks: ITaskDto[] = [];
  const tasksClone: ITaskDto[] = JSON.parse(JSON.stringify(tasks));

  const isAllInactive = tasksClone.every(t => t.status === TASK_STATUSES.TO_DO);

  if (isAllInactive) return tasksClone;

  const {
    startedTasks,
    notStartedTasks,
  }: {
    startedTasks: ITaskDto[];
    notStartedTasks: ITaskDto[];
  } = tasksClone.reduce(
    (acc, cur) => {
      if (cur.status === TASK_STATUSES.TO_DO) {
        acc.notStartedTasks.push(cur);
      } else {
        acc.startedTasks.push(cur);
      }
      return acc;
    },
    {
      startedTasks: [],
      notStartedTasks: [],
    }
  );

  sortedTasks = startedTasks?.sort((a, b) =>
    TaskStatusSequence.indexOf(a?.status) <
      TaskStatusSequence.indexOf(b?.status)
      ? sortBy === SortTasksByEnum.FromCompletedToNotStarted
        ? 1
        : -1
      : sortBy === SortTasksByEnum.FromCompletedToNotStarted
        ? -1
        : 1
  );
  if (sortBy === SortTasksByEnum.FromNotStartedToCompleted) {
    sortedTasks.unshift(...notStartedTasks);
  } else {
    sortedTasks.push(...notStartedTasks);
  }
  return sortedTasks;
};
