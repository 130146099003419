import styled from 'styled-components';

export const TaskWrapper = styled.div`
  padding: 11px 0;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08),
    0px 2px 4px -2px rgba(24, 39, 75, 0.12);
  margin-bottom: 8px;
`;

export const Top = styled.div`
  padding: 0 16px;
`;

export const TaskHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TaskTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 4px;
`;

export const Description = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
`;

export const Actions = styled.div`
  display: flex;
  gap: 4px;
  button {
    margin-right: 0;
  }
`;
