interface ITriangleProps {
  color?: string;
}

export const Triangle = ({ color = 'B9BFC9' }: ITriangleProps): JSX.Element => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33325 4.1665L4.16659 -0.000162601L-8.10623e-05 4.1665H8.33325Z"
      fill={color}
    />
  </svg>
);
