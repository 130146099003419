import { FC, memo } from 'react';
import isEqual from 'react-fast-compare';
import Modal from 'components/Modal';
import { BUTTON_TYPES, Button } from 'components/common';
import { Actions, Conflict, ConflictProjectName, ConflictsWrapper, Description, DescriptionContainerNumber } from './styles';
import { IProjectConflict } from 'types';

interface IProjectConflictsModalProps {
  open: boolean;
  containerNumber: string;
  conflicts: IProjectConflict[];
  onCancel?: () => void;
  onContinue?: () => void;
}

const ProjectConflictsModalComponent: FC<IProjectConflictsModalProps> = ({
  conflicts,
  open,
  containerNumber,
  onCancel,
  onContinue,
}) => (
  <Modal
    destroyOnClose
    visible={open}
    width="428px"
    bodyStyle={{ padding: '12px 20px 20px' }}
    paddingHeader="20px 20px 0 20px"
    title="Warning!"
    onCancel={onCancel}
  >
    <Description>
      Looks like container{' '}
      <DescriptionContainerNumber>{containerNumber}</DescriptionContainerNumber>
      {' '}is already used
    </Description>
    <ConflictsWrapper>
      {conflicts.map(({ _id, invoice, location, name }) => (
        <Conflict key={_id}>
          <ConflictProjectName>{name}</ConflictProjectName>{' '}
          {invoice} {location}
        </Conflict>
      ))}
    </ConflictsWrapper>
    <Actions>
      <Button
        kind={BUTTON_TYPES.PRIMARY}
        label="Cancel"
        width="48%"
        height="55px"
        onClick={onCancel}
      />
      <Button
        kind={BUTTON_TYPES.DEFAULT}
        label="Proceed"
        width="48%"
        height="55px"
        onClick={onContinue}
      />
    </Actions>
  </Modal>
);

export const ProjectConflictsModal = memo(
  ProjectConflictsModalComponent,
  isEqual
);
