import styled from 'styled-components';
import { Menu } from 'antd';

export const MenuStyled = styled(Menu)`
  --white: ${({ theme }) => theme.colors.white};
  --grayLight: ${({ theme }) => theme.colors.grayLight};
  --mainDarkBlue: ${({ theme }) => theme.colors.mainDarkBlue};
  --primary: ${({ theme }) => theme.colors.primary};
  --lightBlue: ${({ theme }) => theme.colors.lightBlue};
  background: var(--white);
  border-bottom: 1px solid var(--grayLight) !important;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 0 10px;
  &:before {
    content: unset;
  }
  & .ant-menu-item {
    width: 226px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-sizing: content-box;
    color: var(--lightBlue) !important;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: none !important;
    &:hover {
      color: var(--mainDarkBlue) !important;
      border: none;
      box-shadow: none;
      box-sizing: content-box;
    }
    &:after {
      content: unset;
    }
    &:focus {
      outline: none;
    }
    &:active {
      background: inherit;
    }
  }
  @media (max-width: 1700px) {
    &&& .ant-menu-item {
      padding: 0 5px;
      width: 200px;
    }
  }
  &&& .ant-menu-item-selected {
    color: var(--mainDarkBlue) !important;
  }
  & > .ant-menu-item-selected .ant-menu-title-content:after {
    content: '';
    height: 3px;
    width: 100%;
    background: var(--primary) !important;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
