import { useCallback, useState } from 'react';
import { ButtonStyled } from './styles';

interface IReadMore {
  text: string;
  limit: number;
}

const CHARACTERS_LIMIT = 200;

const ReadMore = ({ text, limit }: IReadMore): JSX.Element => {
  const [isExpand, setExpand] = useState<boolean>(false);
  const toggleExpand = useCallback(() => {
    setExpand(!isExpand);
  }, [isExpand]);
  return (
    <>
      {CHARACTERS_LIMIT < text.length ? (
        <>
          {isExpand ? text : text.slice(0, limit).trim().concat('...')}
          <ButtonStyled onClick={toggleExpand}>
            {isExpand ? 'Read Less' : 'Read More'}
          </ButtonStyled>
        </>
      ) : (
        <>{text}</>
      )}
    </>
  );
};

export default ReadMore;
