export const AddPerson = ({ size = 20, color = '#172B4D' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.625 10C14.5588 10 16.125 8.43375 16.125 6.5C16.125 4.56625 14.5588 3 12.625 3C10.6912 3 9.125 4.56625 9.125 6.5C9.125 8.43375 10.6912 10 12.625 10ZM12.625 4.75C13.5875 4.75 14.375 5.5375 14.375 6.5C14.375 7.4625 13.5875 8.25 12.625 8.25C11.6625 8.25 10.875 7.4625 10.875 6.5C10.875 5.5375 11.6625 4.75 12.625 4.75ZM12.625 11.75C10.2887 11.75 5.625 12.9225 5.625 15.25V17H19.625V15.25C19.625 12.9225 14.9613 11.75 12.625 11.75ZM7.375 15.25C7.5675 14.62 10.2712 13.5 12.625 13.5C14.9875 13.5 17.7 14.6287 17.875 15.25H7.375ZM4.75 12.625V10H7.375V8.25H4.75V5.625H3V8.25H0.375V10H3V12.625H4.75Z"
      fill={color}
    />
  </svg>
);
