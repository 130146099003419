import { SVGProps } from 'react';

function EditIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.658 1.342l2 2-1.524 1.525-2-2 1.524-1.525zM5.333 10.667h2l4.858-4.858-2-2-4.858 4.858v2z"
        fill="#172B4D"
      />
      <path
        d="M12.667 12.667H5.439c-.018 0-.036.006-.053.006-.022 0-.044-.006-.067-.006H3.333V3.333h4.565L9.231 2H3.333C2.598 2 2 2.597 2 3.333v9.334C2 13.403 2.598 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V6.888l-1.333 1.333v4.446z"
        fill="#172B4D"
      />
    </svg>
  );
}

export default EditIcon;
