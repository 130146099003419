import { SVGProps } from 'react';

function RepeatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10273_18955)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.667V0l-1.76 1.76A5.958 5.958 0 006 0a6 6 0 106 6h-1.333A4.672 4.672 0 016 10.667 4.672 4.672 0 011.333 6 4.672 4.672 0 016 1.333c1.287 0 2.453.527 3.3 1.367L7.333 4.667H12z"
          fill="#737F94"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10273_18955">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RepeatIcon;
