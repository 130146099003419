import {
  FocusEvent,
  LegacyRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// utils
// @ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import { DayPickerInputProps } from 'react-day-picker/types/Props';
import { useSelector } from 'react-redux';
// redux
import { companySelector } from 'store/company';
import { isMobileDevice } from 'utils/helpers';
// components
import { Error, Label } from 'components/common';
// icons
import { Calendar } from 'lib/icons';
// styles
import { DatePickerInputStyled, Wrapper } from './styles';

const InputComponent = forwardRef((props: Pick<IDatePicker, 'error'>, ref) => (
  <label>
    <DatePickerInputStyled error={!!props.error}>
      <Calendar className="calendar-icon" />
      <input {...props} ref={ref as LegacyRef<HTMLInputElement>} />
    </DatePickerInputStyled>
  </label>
));

interface IMobileDatepicker {
  onChange: (e: FocusEvent<HTMLDivElement>) => void;
}

const MobileDatepicker = forwardRef((props: IMobileDatepicker, ref) => {
  const handleChange = useCallback(
    e => {
      props.onChange(e.target.value);
      e.target.placeholder = '';
    },
    [props]
  );

  return (
    <DatePickerInputStyled {...props}>
      <Calendar className="calendar-icon" />
      <input
        {...props}
        type="date"
        style={{ marginRight: 0 }}
        ref={ref as LegacyRef<HTMLInputElement>}
        placeholder="mm/dd/yyyy"
        onChange={handleChange}
      ></input>
    </DatePickerInputStyled>
  );
});

interface IDatePicker {
  width?: string;
  error: string;
  placeholder?: string;
  label: string | Element;
  name: string;
  isRequired?: boolean;
  onChange: (day: Date) => void;
  className?: string;
}

export const Datepicker = memo(
  ({
    label,
    error,
    placeholder = 'mm/dd/yyyy',
    onChange,
    value,
    isRequired,
    ...props
  }: DayPickerInputProps & IDatePicker) => {
    const company = useSelector(companySelector);
    const isMobile = useMemo(() => isMobileDevice.any(), []);

    const dayPickerProps = {
      showWeekNumbers: true,
      todayButton: 'Today',
      showOutsideDays: true,
      weekdaysShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      firstDayOfWeek: company?.weekStartDay || 0,
    };

    return (
      <Wrapper>
        {label && (
          <Label>
            {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : ''}
          </Label>
        )}
        {isMobile ? (
          <MobileDatepicker {...props} onChange={onChange}></MobileDatepicker>
        ) : (
          <DayPickerInput
            {...props}
            value={value && new Date(value)}
            component={InputComponent}
            format="MM/DD/YYYY"
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={placeholder}
            onDayChange={onChange}
            dayPickerProps={dayPickerProps}
            inputProps={{ error }}
          />
        )}
        {error && <Error>{error}</Error>}
      </Wrapper>
    );
  }
);
