import { memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
// redux
import { putProjectPromiseCreator } from 'store/dashboard';
import { prepareEditProject } from '../helpers';
// utils
import { useActionsRoutines } from 'hooks';
// components
import ProjectFiles from 'pages/Dashboard/Project/common/ProjectFiles';
import { message } from 'utils/message';
import { IAttachmentInfo, IProjectResponseDto } from 'types';

interface IFilesProps {
  data?: IProjectResponseDto;
  editFilesData: any;
  notAvailableProject?: boolean;
  isDrawer?: boolean;
}

const Files = ({
  data,
  editFilesData,
  isDrawer,
  notAvailableProject,
}: IFilesProps) => {
  const editProject = useActionsRoutines(putProjectPromiseCreator);

  const [coverFile, setCoverFile] = useState<Partial<IAttachmentInfo>>({});

  const setFileAsCover = useCallback(
    async (file, removeCover) => {
      setCoverFile(removeCover ? {} : file);
      const filesForSend = removeCover
        ? editFilesData.map(file => ({ ...file, isCover: false }))
        : editFilesData.map(currentFile => ({
            ...currentFile,
            isCover: currentFile.filePath === file.filePath,
          }));

      const projectData = prepareEditProject(
        data,
        file,
        filesForSend,
        removeCover
      );

      return editProject(projectData)
        .then()
        .catch(err => console.error(err));
    },
    [editFilesData, editProject, data]
  );

  const onDeleteFile = useCallback(
    file => {
      if (coverFile.filePath === file.filePath) {
        setCoverFile({});
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success();
    },
    [coverFile.filePath]
  );

  return (
    <ProjectFiles
      isDrawer={isDrawer}
      files={editFilesData}
      projectId={data._id}
      isDirectly
      setCoverFile={setFileAsCover}
      deleteFiles={onDeleteFile}
      notAvailableProject={notAvailableProject}
    />
  );
};

export default memo(Files, isEqual);
