import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  clearSystemNotifications,
  setShowOnlyUnreadNotificationMessages,
  systemNotificationsSelector,
} from 'store/sharing';
import MarkAllAsReadButton from './MarkAllAsReadButton/MarkAllAsReadButton';
import NotificationList from './NotificationList/NotificationList';
import UnreadSwitcher from './UnreadSwitcher/UnreadSwitcher';
import {
  ButtonWrapper,
  NotificationHeader,
  NotificationsContainer,
  SpinWrapper,
  Title,
} from './style';
import { useActions } from 'hooks';
import { Spin } from 'antd';
import { LoadingOutlinedStyled } from 'components/SubtaskActions/styles';

interface INotificationsContentProps {
  setVisible: (visible: boolean) => void;
  onLocationChange: (value: string) => void;
  getSystemNotifications: () => Promise<void>;
  markSystemNotificationsRead: (id: string) => Promise<void>;
}

export const NotificationsContent = ({
  setVisible,
  onLocationChange,
  getSystemNotifications,
  markSystemNotificationsRead,
}: INotificationsContentProps) => {
  const notifications = useSelector(systemNotificationsSelector);
  const clearNotify = useActions(clearSystemNotifications);
  const toggleShowUnreadMessages = useActions(
    setShowOnlyUnreadNotificationMessages
  );

  useEffect(
    () => () => {
      toggleShowUnreadMessages(false);
      clearNotify();
    },
    [clearNotify, toggleShowUnreadMessages]
  );

  return (
    <NotificationsContainer>
      <NotificationHeader>
        <Title>Notifications</Title>
        <UnreadSwitcher />
      </NotificationHeader>
      <ButtonWrapper>
        <MarkAllAsReadButton />
      </ButtonWrapper>
      {!!notifications ? (
        <NotificationList
          setVisible={setVisible}
          onLocationChange={onLocationChange}
          getSystemNotifications={getSystemNotifications}
          markSystemNotificationsRead={markSystemNotificationsRead}
          notifications={notifications}
        />
      ) : (
        <SpinWrapper>
          <Spin
            indicator={<LoadingOutlinedStyled />}
            spinning={true}
            size="large"
          />
        </SpinWrapper>
      )}
    </NotificationsContainer>
  );
};
