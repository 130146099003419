import { takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/predefined';
import * as E from './workers';

export function* watchLocations() {
  yield takeLatest(Actions.locations.TRIGGER, E.ensureLocations);
}

export function* watchLocation() {
  yield takeLatest(Actions.getLocation.TRIGGER, E.ensureGetLocation);
}

export function* watchPutLocation() {
  yield takeLatest(Actions.putLocation.TRIGGER, E.ensurePutLocation);
}

export function* watchPatchLocation() {
  yield takeLatest(Actions.patchLocation.TRIGGER, E.ensurePatchLocation);
}

export function* watchPostLocations() {
  yield takeLatest(Actions.postLocation.TRIGGER, E.ensurePostLocation);
}

export function* watchDeleteLocations() {
  yield takeLatest(Actions.deleteLocation.TRIGGER, E.ensureDeleteLocation);
}

export function* watchRoles() {
  yield takeLatest(Actions.roles.TRIGGER, E.ensureRoles);
}

export function* watchUploadMapByLocation() {
  yield takeLatest(
    Actions.uploadMapByLocation.TRIGGER,
    E.ensureUploadMapByLocation
  );
}

export function* watchUploadMap() {
  yield takeLatest(Actions.uploadMap.TRIGGER, E.ensureUploadMap);
}

export function* watchDeleteMap() {
  yield takeLatest(Actions.deleteMap.TRIGGER, E.ensureDeleteMap);
}

export function* watchDeleteMapByLocation() {
  yield takeLatest(
    Actions.deleteMapByLocation.TRIGGER,
    E.ensureDeleteMapByLocation
  );
}

export function* watchCheckLocationHasAssignedData() {
  yield takeLatest(
    Actions.checkLocationHasAssignedData.TRIGGER,
    E.ensureCheckLocationHasAssignedData
  );
}
