export const Like = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip01213)">
      <path
        d="M24.375 30H1.875C1.62873 30.0001 1.38485 29.9517 1.1573 29.8576C0.929755 29.7634 0.723001 29.6253 0.548864 29.4511C0.374726 29.277 0.23662 29.0702 0.142442 28.8427C0.0482636 28.6151 -0.000139369 28.3713 3.01411e-07 28.125V13.125C-0.000139369 12.8787 0.0482636 12.6349 0.142442 12.4073C0.23662 12.1798 0.374726 11.973 0.548864 11.7989C0.723001 11.6247 0.929755 11.4866 1.1573 11.3924C1.38485 11.2983 1.62873 11.2499 1.875 11.25H8.53384C11.2148 8.24022 11.25 5.27528 11.25 1.875C11.2499 1.62873 11.2983 1.38485 11.3924 1.1573C11.4866 0.929754 11.6247 0.723001 11.7989 0.548862C11.973 0.374725 12.1798 0.236618 12.4073 0.142441C12.6349 0.0482635 12.8787 -0.000139236 13.125 0C21.0242 0 22.2675 4.00625 22.4634 7.5H24.375C25.8663 7.50165 27.2961 8.09482 28.3506 9.14935C29.4052 10.2039 29.9983 11.6337 30 13.125V24.375C29.9983 25.8663 29.4052 27.2961 28.3506 28.3506C27.2961 29.4052 25.8663 29.9983 24.375 30ZM3.75 26.25H7.5V15H3.75V26.25ZM26.25 13.125C26.2494 12.6279 26.0516 12.1514 25.7001 11.7999C25.3486 11.4484 24.8721 11.2506 24.375 11.25H20.625C20.3787 11.2501 20.1349 11.2017 19.9073 11.1076C19.6798 11.0134 19.473 10.8753 19.2989 10.7011C19.1247 10.527 18.9866 10.3202 18.8924 10.0927C18.7983 9.86515 18.7499 9.62127 18.75 9.375C18.75 6.09741 18.75 4.24803 14.9798 3.83787C14.8883 7.02394 14.3518 10.4736 11.25 13.8757V26.25H24.375C24.8721 26.2494 25.3486 26.0516 25.7001 25.7001C26.0516 25.3486 26.2494 24.8721 26.25 24.375V13.125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip01213">
        <rect
          width="30"
          height="30"
          transform="matrix(1 0 0 -1 0 30)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
