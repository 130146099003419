import * as yup from 'yup';
import { employees, phoneNumber } from './baseSchema';

const RegisterNewCompanySchema = yup.object().shape({
  companyName: yup.string().trim().required('Required!'),
  employees,
  companyPhoneNumber: phoneNumber,
  // TODO - add this back in when we have a terms and conditions
  // acceptTerms: yup
  //   .boolean()
  //   .oneOf([true], 'You must accept the terms and conditions'),
});

export default RegisterNewCompanySchema;
