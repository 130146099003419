import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import { getInventory } from './actions';

const inventory = createReducer(initialState.inventory, {
  [getInventory.SUCCESS]: (state, action) => {
    state.inventoryList = action.payload;
  },
});

export { inventory as reducer };
