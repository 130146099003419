import { ChangeEvent, memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import AvatarBlock from 'components/SubtaskViewComponents/AvatarBlock';
import { SetStatusPopover } from 'components/SubtaskViewComponents/SetStatusPopover';
import { BUTTON_TYPES, Button } from 'components/common';
import { DragAndDrop } from 'lib/icons';
import {
  AvatarWrapper,
  DragAndDropWrap,
  MainInfo,
  MainInfoLeft,
  MainInfoRight,
  MainInfoText,
  Subtask,
  Top,
} from './styles';
import {
  GeneratedApiKeyDto,
  IProjectResponseDto,
  ISubtaskItemData,
  SubtaskStatusesEnum,
} from 'types';

interface ITopPanelProps {
  data?: ISubtaskItemData;
  setShouldUpdate?: (update: boolean) => void;
  notAvailableProject?: boolean;
  openCheckResult?: (_id: string, percent: number, isDone?: boolean) => void;
  refOrder?: Element;
  hasResource: boolean;
  assignedDate: string;
  withDnD: boolean;
  project: IProjectResponseDto;
  locationId: string;
  projectId?: string;
  generatedApiKey?: GeneratedApiKeyDto;
  isProjectViewTaskMode?: boolean;
}

const TopPanel = ({
  data,
  openCheckResult,
  setShouldUpdate,
  notAvailableProject,
  refOrder,
  hasResource,
  withDnD,
  project,
  projectId,
  locationId,
  assignedDate,
  generatedApiKey,
  isProjectViewTaskMode,
}: ITopPanelProps) => {
  const {
    _id,
    name,
    order,
    isApproveRequired,
    isPhotoRequired,
    waitForRequiredPhotos,
    fileUrls,
    status,
  } = data;

  const handleWrapClick = (e: ChangeEvent) => e.stopPropagation();
  const handleCheckResult = useCallback(
    () => openCheckResult(_id, data?.percent),
    [openCheckResult, data?.percent, _id]
  );

  const handleSeeResult = useCallback(
    () => openCheckResult(_id, data?.percent, true),
    [openCheckResult, _id, data?.percent]
  );

  return (
    <Top withDnD={withDnD}>
      <MainInfo>
        {!notAvailableProject && !isProjectViewTaskMode && (
          <DragAndDropWrap
            style={{ marginRight: 8, marginTop: 5, cursor: 'pointer' }}
            onClick={handleWrapClick}
            ref={refOrder}
          >
            <DragAndDrop />
          </DragAndDropWrap>
        )}
        <MainInfoLeft>
          {!isProjectViewTaskMode && (
            <Subtask>
              <span>
                #{order} {name}
              </span>
            </Subtask>
          )}

          <MainInfoText $isProjectViewTaskMode={isProjectViewTaskMode}>
            {(isApproveRequired || isPhotoRequired) && (
              <>
                {isApproveRequired && 'Approve'}
                {isApproveRequired && isPhotoRequired && ' & '}
                {isPhotoRequired && 'Photo'} Required
                {(isApproveRequired || isPhotoRequired) && ' •  '}
              </>
            )}

            {hasResource || generatedApiKey
              ? `Assigned ${assignedDate}`
              : 'Not assigned yet'}
          </MainInfoText>
        </MainInfoLeft>
      </MainInfo>
      <MainInfoRight>
        <AvatarWrapper>
          <AvatarBlock
            hasGeneratedApiKey={!!generatedApiKey}
            data={data}
            locationId={locationId}
            projectId={projectId}
            notAvailableProject={notAvailableProject}
            minStartDate={project?.startDate}
          />
        </AvatarWrapper>
        {status === SubtaskStatusesEnum.DONE &&
          !!fileUrls.length &&
          isPhotoRequired &&
          !waitForRequiredPhotos && (
            <Button
              kind={BUTTON_TYPES.SECONDARY}
              label="Open photo"
              onClick={handleSeeResult}
              style={{ padding: '0', fontSize: 12 }}
              width="92px"
              height="28px"
            />
          )}

        {status === SubtaskStatusesEnum.ON_REVIEW &&
          (isApproveRequired || isPhotoRequired) &&
          !notAvailableProject &&
          (waitForRequiredPhotos ? (
            <Button
              kind={BUTTON_TYPES.SECONDARY}
              label="Photos Loading..."
              disabled={true}
              style={{
                padding: '0 5px',
                marginRight: 10,
                fontSize: 12,
                width: 'auto',
              }}
              width="100px"
              height="28px"
            />
          ) : (
            <Button
              width="94px"
              height="28px"
              label="Check result"
              fontSize="14px"
              onClick={handleCheckResult}
              style={{ padding: '0', fontSize: 12 }}
            />
          ))}

        <SetStatusPopover
          data={data}
          projectStatus={project.status}
          notAvailableProject={notAvailableProject}
          setShouldUpdate={setShouldUpdate}
        />
      </MainInfoRight>
    </Top>
  );
};

export default memo(TopPanel, isEqual);
