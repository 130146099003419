import {
  SUBTASK_STATUSES_INVERTED,
  SUBTASK_STATUSES_PROPERTIES,
} from 'lib/constants/subtasks';
import { Notes, NotesTitle, Status, SubTitle, Title, Wrap } from './styles';
import { ISubtaskResponseDto } from 'types';

const MainInfo = ({
  name,
  taskName,
  taskOrder,
  notes,
  status,
  taskDescription,
}: ISubtaskResponseDto): JSX.Element => {
  const statusKey = SUBTASK_STATUSES_INVERTED[status];
  const statusPreset = SUBTASK_STATUSES_PROPERTIES[statusKey];
  return (
    <Wrap>
      <Title>
        #{taskOrder} {taskName}
      </Title>
      <SubTitle>{name}</SubTitle>
      <Status preset={statusPreset}>{statusPreset.value}</Status>
      {taskDescription && (
        <>
          <NotesTitle> Task description</NotesTitle>
          <Notes style={{ marginBottom: 5 }}>{taskDescription}</Notes>
        </>
      )}
      {notes && (
        <>
          <NotesTitle>Subtask Notes</NotesTitle>
          <Notes>{notes}</Notes>
        </>
      )}
    </Wrap>
  );
};

export default MainInfo;
