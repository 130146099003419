import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
// redux
import {
  ganttWeekViewSelector,
  resourceExcelPromiseCreator,
} from 'store/dashboard';
import { location } from 'store/router';
import { BUTTON_TYPES } from 'components/common';
// utils
import { useActionsRoutines } from 'hooks';
// icons
import { Xerox } from 'lib/icons';
// styles
import { ButtonStyled } from './styles';

const PrintAllButton = () => {
  const isGanttWeekView = useSelector(ganttWeekViewSelector);
  const currentLocation = useSelector(location);
  const getFile = useActionsRoutines(resourceExcelPromiseCreator);
  const [isLoading, setIsLoading] = useState(false);
  const isVisible =
    !isGanttWeekView && currentLocation.pathname === '/dashboard';

  const onButtonClick = useCallback(() => {
    setIsLoading(true);
    getFile()
      .then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel',
        });
        const objectUrl = window.URL.createObjectURL(blob);
        window.location = objectUrl as any;
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false));
  }, [getFile]);

  return isVisible ? (
    <ButtonStyled
      kind={BUTTON_TYPES.SECONDARY}
      space="0px"
      loading={isLoading}
      icon={<Xerox />}
      onClick={onButtonClick}
    />
  ) : null;
};

export default PrintAllButton;
