import { call, put, select } from 'redux-saga/effects';
import { currentLocationSelector } from 'store/auth';
// redux
import * as Actions from 'store/inventory';
import { actions } from 'store/loading';
// utils
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import { IEnsureGetInventoryPayload } from 'types';

export function* ensureGetInventory({
  payload,
}: {
  payload?: IEnsureGetInventoryPayload;
  type: typeof Actions.getInventory.TRIGGER;
}) {
  try {
    if (!payload?.noLoader) yield put(actions.pageLoading(true));
    const currentLocationId = yield select(currentLocationSelector);
    const storageLocationId = yield storageManager.getLocation();
    const params = {
      ...payload?.params,
      locationId: currentLocationId || storageLocationId,
    };
    const data = yield call(Api.inventory.getInventory, { params });
    yield put(Actions.getInventory.success(data));
  } catch (err) {
    yield put(Actions.getInventory.failure(err));
  } finally {
    if (!payload?.noLoader) yield put(actions.pageLoading(false));
  }
}

export function* ensureInventoryPdf() {
  try {
    const currentLocationId = yield select(currentLocationSelector);
    const storageLocationId = yield storageManager.getLocation();
    const params = {
      locationId: currentLocationId || storageLocationId,
    };
    const data = yield call(Api.inventory.inventoryPdf, {
      params,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
    yield put(Actions.inventoryPdf.success(data));
  } catch (err) {
    yield put(Actions.inventoryPdf.failure(err));
  }
}
