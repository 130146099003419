export const Excel = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 14H11V5H14L10 0L6 5H9V14Z" fill="#737F94" />
    <path
      d="M3 20H17C18.103 20 19 19.103 19 18V9C19 7.897 18.103 7 17 7H13V9H17V18H3V9H7V7H3C1.897 7 1 7.897 1 9V18C1 19.103 1.897 20 3 20Z"
      fill="#737F94"
    />
  </svg>
);
