import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const ButtonsGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
