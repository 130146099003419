import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
  .addNewTaskBtn {
    width: 100%;
    height: 38px;
    margin: 11px 0;
  }
`;

export const TaskWrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 6px;
  margin-bottom: 5px;
`;
