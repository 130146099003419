import { SVGProps } from 'react';

export const Delete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="10" r="10" fill="#DFE1E6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.64645 7.64645C7.84171 7.45119 8.15829 7.45119 8.35356 7.64645L10 9.2929L11.6465 7.64645C11.8417 7.45119 12.1583 7.45119 12.3535 7.64645C12.5488 7.84171 12.5488 8.15829 12.3535 8.35356L10.7071 10L12.3535 11.6465C12.5488 11.8417 12.5488 12.1583 12.3535 12.3535C12.1583 12.5488 11.8417 12.5488 11.6465 12.3535L10 10.7071L8.35356 12.3535C8.15829 12.5488 7.84171 12.5488 7.64645 12.3535C7.45119 12.1583 7.45119 11.8417 7.64645 11.6465L9.2929 10L7.64645 8.35356C7.45119 8.15829 7.45119 7.84171 7.64645 7.64645Z"
      fill="#172B4D"
    />
  </svg>
);
