export const SIDE_BAR_WIDTH = 175;
export const STATUS_CELL_WIDTH = 123;
export const NAME_CELL_WIDTH = 154;
export const PERCENT_CELL_WIDTH = 100;
export const ACTION_CELL_WIDTH = 250;
export const EDIT_CELL_WIDTH = 115;
const PADDING_LEFT_RIGHT = 36 * 2;

export const TABLE_WIDTH =
  SIDE_BAR_WIDTH +
  STATUS_CELL_WIDTH +
  NAME_CELL_WIDTH +
  PERCENT_CELL_WIDTH +
  ACTION_CELL_WIDTH +
  EDIT_CELL_WIDTH +
  PADDING_LEFT_RIGHT;

export const TABLE_INNER_WIDTH =
  SIDE_BAR_WIDTH +
  STATUS_CELL_WIDTH +
  NAME_CELL_WIDTH +
  PERCENT_CELL_WIDTH +
  ACTION_CELL_WIDTH +
  EDIT_CELL_WIDTH;

export const COLUMNS = [
  { label: 'Status', value: 'status' },
  { label: 'Action', value: 'action' },
  { label: 'Percent', value: 'percent' },
  { label: 'Full name', value: 'name' },
  { label: '', value: '' },
];
