import { DoubleArrow } from 'lib/icons';
import { ExpandWrap } from './styles';

interface iExpandProps {
  changeExpand: () => void;
  isExpandedAll: boolean;
}

const Expand = ({ changeExpand, isExpandedAll }: iExpandProps) => {
  const text = isExpandedAll ? 'Сollapse all' : 'Expand all';
  const handleClick = () => {
    changeExpand();
  };
  return (
    <ExpandWrap isExpandedAll={isExpandedAll} onClick={handleClick}>
      {isExpandedAll && <DoubleArrow />}
      {text}
      {!isExpandedAll && <DoubleArrow />}
    </ExpandWrap>
  );
};

export default Expand;
