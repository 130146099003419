import styled from 'styled-components';

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  overflow: visible;
  width: 1108px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
  &:last-child {
    border-bottom: none;
    border-radius: 0px 0px 5px 5px;
  }
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const TableBodyCol = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  padding: 16px 0px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.table-body-col-date {
    font-weight: 600;
    width: 121px;
    justify-content: center;
    border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
  }
  &.table-body-col-invoice {
    padding-left: 43px;
    width: 197px;
    span {
      max-width: 187px;
    }
  }
  &.table-body-col-client {
    width: 225px;
    span {
      max-width: 215px;
    }
  }
  &.table-body-col-company {
    width: 235px;
    span {
      max-width: 225px;
    }
  }
  &.table-body-col-owner {
    width: 237px;
    span {
      max-width: 225px;
    }
  }
  &.table-body-col-atached {
    margin-right: 40px;
    svg {
      margin-top: 1px;
      margin-right: 4px;
    }
  }
`;

export const DivideIconWrap = styled.div`
  position: absolute;
  left: -30px;
  top: 15px;
`;

export const RemoveButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
