import styled from 'styled-components';
import { Spin } from 'antd';

export const TableWrap = styled.div`
  padding: 36px 11px 12px 11px;
`;

export const ActualTableWrap = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #172b4d;
  padding-bottom: 20px;
`;

export const SpinItem = styled(Spin)`
  &.ant-spin.ant-spin-spinning {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: unset;
  }
`;
