import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { ContainerTaskStatusesEnum as STATUSES } from 'types';

export const Heading = styled.div<{
  isOpen?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 9px 17px;
  width: 100%;
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-bottom: 0;
    `}
`;

export const HeadingTop = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  min-height: 23px;
  display: flex;
  margin-top: 2px;
  width: 100%;
  justify-content: space-between;
`;
export const HeadingRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeadingBottom = styled.div``;

export const Status = styled.div<{
  status: STATUSES;
}>`
  height: 23px;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-left: 7px;
  white-space: nowrap;
  ${props =>
    (props.status === STATUSES.DONE || props.status === STATUSES.DELIVERED) &&
    css`
      color: ${({ theme }) => theme.colors.eucalyptus};
      background-color: ${({ theme }) => theme.colors.lightGreen};
    `}

  ${props =>
    (props.status === STATUSES.REQUESTED ||
      props.status === STATUSES.REQUESTED_FOR_TAKE_OUT) &&
    css`
      color: ${({ theme }) => theme.colors.lightDeepBlue};
      background-color: ${({ theme }) => theme.colors.lightTurquoise};
    `}
  ${props =>
    props.status === STATUSES.TO_DO &&
    css`
      color: ${({ theme }) => theme.colors.darkYellow2};
      background-color: ${({ theme }) => theme.colors.lightYellow2};
    `}
`;

export const Tag = styled.div<{
  backgroundColor?: string;
}>`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  padding: 2px 10px;
  margin-right: 4px;
  border-radius: 5px;
  margin-top: 5px;
  ${({ theme, backgroundColor }) => css`
    color: ${theme.colors.white};
    background-color: ${backgroundColor};
  `};
`;

export const ContainerShortInfo = styled.div`
  padding-top: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-right: 11px;
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  @media (max-width: 551px) {
    max-width: 120px;
  }
`;

export const ShortInfo = styled.div`
  white-space: nowrap;
  margin-right: 29px;
  margin-bottom: 6px;
`;

export const InfoName = styled.span`
  color: ${theme.colors.lightBlue};
`;
export const InfoDate = styled.span`
  margin-left: 4px;
`;
