import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { signOut } from 'store/auth';
import { companySelector } from 'store/company';
import { ROUTES } from 'store/router';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActions } from 'hooks';
import { Back, LogOut } from 'lib/icons';
import SideBarMenu from 'pages/SideBar/Menu';
import theme from 'styles/theme';
import { Col } from 'antd';
import {
  Bottom,
  DividerItem,
  IconWrapper,
  LinkItem,
  SiderStyled,
} from './styles';
import { ISideBarItem } from 'types';

interface ISideBarMenuProps {
  siderItems: ISideBarItem[];
}

const SideBar = ({ siderItems }: ISideBarMenuProps) => {
  const company = useSelector(companySelector);
  const signOutHandler = useActions(signOut);
  const items = useMemo(
    () =>
      siderItems.flatMap(item =>
        !company?.showZohoReports && item.label === 'Reports' ? [] : [item]
      ),
    [siderItems, company]
  );

  const logOut = useCallback(() => signOutHandler(), [signOutHandler]);

  return (
    <SiderStyled width={174}>
      <Col>
        <LinkItem to={ROUTES.DASHBOARD}>
          <IconWrapper>
            <Back fill={theme.colors.white} />
          </IconWrapper>
          Settings
        </LinkItem>
        <DividerItem />

        <SideBarMenu siderItems={items} />
      </Col>
      <Bottom>
        {/* TODO Add developer space link */}
        {/* <DeveloperLink>
          <CodeIcon />
          <DeveloperText>
            Developer Space
            <NewTabIconWrapper>
              <NewTabIcon />
            </NewTabIconWrapper>
          </DeveloperText>
        </DeveloperLink> */}

        <Button
          icon={<LogOut />}
          onClick={logOut}
          label="Log Out"
          kind={BUTTON_TYPES.LOGOUT}
          space="16px"
          width="100%"
          type={BUTTON_TYPES.LINK}
        />
      </Bottom>
    </SiderStyled>
  );
};

export default SideBar;
