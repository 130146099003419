import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get colors
export const colors = createRoutine('depot/colors');
export const colorsPromiseCreator = promisifyRoutine(colors);
// add color
export const addColor = createRoutine('depot/addColor');
export const addColorPromiseCreator = promisifyRoutine(addColor);
// delete color
export const deleteColor = createRoutine('depot/deleteColor');
export const deleteColorPromiseCreator = promisifyRoutine(deleteColor);
