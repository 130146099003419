import styled from 'styled-components';
import theme from 'styles/theme';

export const DatePickerInputStyled = styled.div`
  position: relative;
  margin: 0 10px;
  background: ${({ theme }) => theme.colors.grayLight};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  align-items: center;
  padding: 0 13px;
  height: 36px;
  & > input {
    padding-left: 9px;
    border: none;
    cursor: default;
    box-shadow: unset;
    background: inherit;
    width: 113px;
    box-sizing: border-box;
    text-align: center;
    &:focus,
    &:hover {
      outline: none;
    }
  }
`;

export const Wrap = styled.div`
  cursor: pointer;

  & .DayPicker-Day--selected {
    position: relative;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
`;
