import { all, fork } from 'redux-saga/effects';
import * as W from './watchers';
import * as E from './workers';

export function* companyInfoSaga() {
  yield all([
    fork(E.ensureCompany),
    fork(W.watchUpdateLogo),
    fork(W.watchUploadFavicon),
  ]);
}
