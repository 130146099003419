import Item from './Item';
import { ListStyled, Title, Wrap } from './styles';
import { FileUploadResDto } from 'types';

interface IFilesProps {
  files: FileUploadResDto[];
}
const Files = ({ files }: IFilesProps) => (
  <Wrap>
    <Title>Files</Title>
    <ListStyled
      dataSource={files}
      renderItem={(file: FileUploadResDto) => <Item file={file} />}
    />
  </Wrap>
);

export default Files;
