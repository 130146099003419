import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const inbound = (r: IInitialState) => r.inbound;
export const workOrdersSelector = createSelector(inbound, r => r.workOrders);
export const workOrdersInfoSelector = createSelector(
  inbound,
  r => r.workOrdersInfo
);
export const workOrderSelector = createSelector(inbound, r => r.workOrder);
