import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const company = (r: IInitialState) => r.company;
export const administratorsSelector = createSelector(
  company,
  r => r.administrators
);
export const companySelector = createSelector(company, r => r.companyInfo);
export const adminSelector = createSelector(company, r => r.admin);
export const employeesValuesSelector = createSelector(
  company,
  r => r.employeesValues
);
