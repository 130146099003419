import * as yup from 'yup';
import { email, firstName, lastName, phoneNumber } from './baseSchema';
import moment from 'moment';

const resourceSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  workingTime: yup.array().required('Working time is required!'),
  autoPause: yup
    .date()
    .nullable()
    .test(
      'autoPause',
      'Auto pause time should be less than resource not working time',
      function (val) {
        if (!val) return true;
        const workingTime = this.parent.workingTime;
        const workingMinutes = workingTime[1].diff(workingTime[0], 'minutes');
        const notWorkingMinutes = 24 * 60 - workingMinutes;
        const autoPauseHour = moment(val).hours();
        const autoPauseMinutes = moment(val).minutes();
        const autoPauseTimeInMinutes = autoPauseHour * 60 + autoPauseMinutes;
        const notWorkingHours = Math.floor(notWorkingMinutes / 60);
        const notWorkingTime = `${
          notWorkingHours < 10 ? `0${notWorkingHours}` : notWorkingHours
        }:${
          notWorkingMinutes % 60 < 10
            ? `0${notWorkingMinutes % 60}`
            : notWorkingMinutes % 60
        }`;
        if (notWorkingMinutes <= autoPauseTimeInMinutes) {
          return this.createError({
            path: 'autoPause',
            message: `Auto pause time should be less than ${notWorkingTime} `,
          });
        }
        return true;
      }
    ),
});

export default resourceSchema;
