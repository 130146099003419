import { ProjectViewMode } from 'types';

export const projectViewModeOptions = [
  {
    label: 'Tasks/Items',
    value: ProjectViewMode.Tasks,
  },
  {
    label: 'Items/Tasks',
    value: ProjectViewMode.Items,
  },
];
