export const ChecklistMenu = () => (
  <svg
    width="21"
    height="14"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z"
      fill="#172B4D"
    />
  </svg>
);
