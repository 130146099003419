interface IPencilWithLineProps {
  width?: string;
  height?: string;
  className?: string;
}

export const PencilWithLine = ({
  className,
  width = '20',
  height = '20',
}: IPencilWithLineProps): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1481 5.74679C15.5182 5.37666 15.7219 4.88512 15.7219 4.36224C15.7219 3.83937 15.5182 3.34783 15.1481 2.9777L13.5951 1.42474C13.225 1.05462 12.7334 0.850952 12.2106 0.850952C11.6877 0.850952 11.1961 1.05462 10.827 1.42376L0.416504 11.802V16.125H4.73757L15.1481 5.74679ZM12.2106 2.80929L13.7645 4.36127L12.2076 5.91227L10.6547 4.36029L12.2106 2.80929ZM2.37484 14.1666V12.6147L9.26817 5.74287L10.8211 7.29583L3.92877 14.1666H2.37484ZM0.416504 18.0833H16.0832V20.0416H0.416504V18.0833Z"
      fill="#172B4D"
    />
  </svg>
);
