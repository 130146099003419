import { IChecklist } from 'types';

export const prepareChecklist = (checklist: IChecklist[]): IChecklist[] =>
  checklist.map((checkItem: IChecklist) =>
    checkItem?.isNew
      ? {
          title: checkItem.title,
          isCompleted: checkItem.isCompleted,
        }
      : {
          _id: checkItem._id,
          title: checkItem.title,
          isCompleted: checkItem.isCompleted,
        }
  );
