import { MouseEvent, memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  getStandaloneSubtaskCreator,
  openSubtaskChat,
  projectPromiseCreator,
} from 'store/dashboard';
import {
  setSubtaskItemIdAction,
  setSubtaskNameAction,
  setTaskIdAction,
  toggleDrawerAction,
  toggleStandaloneSubtaskAction,
} from 'store/gantt';
import { location } from 'store/router';
import { getAvatarProps } from 'utils/helpers';
import DeleteNotification from './DeleteNotification/DeleteNotification';
import Reply from './Reply/Reply';
import ToggleViewNotification from './ToggleViewNotification/ToggleViewNotification';
import {
  ActionsButtons,
  AvatarWrap,
  BottomItems,
  CollapseIconWrapper,
  Content,
  ItemWrap,
  Message,
  MessageTitle,
  MessageWrapper,
  ReplyButton,
  TaskBlock,
  TaskInvoice,
  TaskName,
  TimeWrap,
  TitleContainer,
} from './style';
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import ReplyIcon from 'lib/icons/ReplyIcon';
import TwoArrowsIcon from 'lib/icons/TwoArrowsIcon';
import moment from 'moment-timezone';
import storageManager from 'utils/storageManager';
import { AvatarStyled } from 'components/SubtaskChangelogForm/Table/Row/styles';

const NotificationItemComponent = ({
  item,
  setVisible,
  onLocationChange,
  showWaypoint,
  getSystemNotifications,
  markSystemNotificationsRead,
}) => {
  const [isShowReply, setIsShowReply] = useState(false);
  const [isShowFullMessage, setIsShowFullMessage] = useState(false);
  const { pathname } = useSelector(location);
  const currentLocation = storageManager.getLocation();
  const history = useHistory();
  const openChat = useActions(openSubtaskChat);
  const toggleStandaloneSubtask = useActions(toggleStandaloneSubtaskAction);
  const setTaskId = useActions(setTaskIdAction);
  const setSubtaskName = useActions(setSubtaskNameAction);
  const toggleDrawer = useActions(toggleDrawerAction);
  const setSubtaskItemId = useActions(setSubtaskItemIdAction);
  const getProjectByIdHandler = useActionsRoutines(projectPromiseCreator);
  const getStandaloneSubtask = useActionsRoutines(getStandaloneSubtaskCreator);

  const memoizedHistory = useMemoCompare(history);
  const sender = useMemo(() => item?.sender, [item]);
  const senderFullName = useMemo(
    () => `${sender?.firstName || ''} ${sender?.lastName || ''}`,
    [sender]
  );

  const {
    fullName: avatarName,
    color,
    background,
  } = getAvatarProps(senderFullName);
  const avatarStyles = { color, background };

  const openSubtask = useCallback(() => {
    setVisible(false);
    if (!item.isSeen) {
      markSystemNotificationsRead(item?._id).catch(e => console.error(e));
    }

    getProjectByIdHandler({ id: item?.project?._id, noLoader: true })
      .then(project => {
        if (pathname !== '/dashboard') memoizedHistory.push('/dashboard');

        if (project?.locationId !== currentLocation) {
          onLocationChange(project?.locationId);
        }

        if (project.isStandalone) {
          getStandaloneSubtask(item?.project?.task?.subtask?._id)
            .catch(err => console.error(err))
            .finally(() => toggleStandaloneSubtask(true));
        } else {
          // Open task in drawer
          setSubtaskName(item?.project?.task?.subtask?.name);
          setTaskId(item?.project?.task?._id);
          toggleDrawer(true);
          setTimeout(() => {
            setTaskId(undefined);
            setSubtaskName(undefined);
          }, 5000);
        }

        openChat(item?.project?.task?.subtask?._id);
      })
      .catch(err => {
        console.error(err);
        toggleDrawer(false);
        setTaskId(undefined);
        setSubtaskItemId(undefined);
      });
  }, [
    item,
    pathname,
    currentLocation,
    memoizedHistory,
    getProjectByIdHandler,
    getStandaloneSubtask,
    markSystemNotificationsRead,
    onLocationChange,
    openChat,
    setSubtaskItemId,
    setTaskId,
    setSubtaskName,
    setVisible,
    toggleDrawer,
    toggleStandaloneSubtask,
  ]);

  const handleToggleReply = () => setIsShowReply(!isShowReply);

  const isShowCollapse = item.text.length > 40;

  const toggleShowFullMessage = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsShowFullMessage(!isShowFullMessage);
  };

  return (
    <ItemWrap>
      <AvatarWrap>
        <AvatarStyled
          shape="square"
          size={44}
          className="avatar"
          src={sender?.photoUrl}
          style={avatarStyles}
        >
          {avatarName}
        </AvatarStyled>
      </AvatarWrap>
      <Content>
        <TitleContainer>
          <MessageTitle>New message from {senderFullName} </MessageTitle>
          <ToggleViewNotification
            isSeen={item.isSeen}
            notificationId={item._id}
          />
        </TitleContainer>
        <MessageWrapper>
          <Message $isShowFullMessage={isShowFullMessage}>{item.text}</Message>
          {isShowCollapse && (
            <CollapseIconWrapper
              $isShowText={isShowFullMessage}
              onClick={toggleShowFullMessage}
            >
              <TwoArrowsIcon />
            </CollapseIconWrapper>
          )}
        </MessageWrapper>
        <TaskBlock onClick={openSubtask}>
          <TaskInvoice
            style={{ display: item?.project?.invoice ? 'flex' : 'none' }}
          >
            {item?.project?.invoice}
          </TaskInvoice>
          <TaskName
            style={{ display: item?.project?.task?.name ? 'flex' : 'none' }}
          >
            {item?.project?.task?.name}
          </TaskName>
          <TaskName>{item?.project?.task?.subtask?.name}</TaskName>
        </TaskBlock>
        {isShowReply && (
          <Reply
            subtaskId={item.project.task.subtask._id}
            replyToMessageId={item.project.task.subtask.chatMessageId}
            setIsShowReply={setIsShowReply}
          />
        )}
        <BottomItems>
          <TimeWrap>
            {moment(item.createdAt).isSame(moment(), 'day')
              ? moment(item.createdAt).fromNow()
              : moment(item?.createdAt).format('L hh:mm a')}
          </TimeWrap>
          <ActionsButtons>
            <ReplyButton onClick={handleToggleReply}>
              <ReplyIcon />
              Quick reply
            </ReplyButton>
            <DeleteNotification notificationId={item._id} />
          </ActionsButtons>
        </BottomItems>
      </Content>
      {showWaypoint && <Waypoint onEnter={getSystemNotifications} />}
    </ItemWrap>
  );
};

export const NotificationItem = memo(NotificationItemComponent, isEqual);
