import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { TemplateSelect } from '../TemplateSelect';
import { Checkbox, SelectInput } from 'components/common';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { SelectorsWrap } from './styles';

interface IStatusCheckboxProps {
  name: string;
  form: any;
  isChecked: boolean;
  itemTemplate?: any;
  label: string;
  recipientsList: any[];
  templatesList: any[];
  onChange: (name: string, checkboxStatus: string) => void;
  index?: number;
  isSubtask?: boolean;
}
const StatusCheckboxComponent = ({
  name = '',
  form,
  isChecked,
  itemTemplate,
  label,
  recipientsList,
  templatesList,
  onChange,
  index,
  isSubtask = false,
}: IStatusCheckboxProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [customRecipientsOptions, setCustomRecipientsOptions] = useState([]);
  const [mappedTemplate, setTemplate] = useState(itemTemplate);
  const [errorMessage, setErrorMessage] = useState('');
  const { setValue, trigger, formState } = form;
  const statusName = useMemo(() => {
    const statusArr = name.split('.');
    return name.split('.')[statusArr.length - 1];
  }, [name]);

  const memoizedCustomRecipientsOptions = useMemoCompare(
    customRecipientsOptions
  );
  const templateError = isSubtask
    ? formState?.errors?.subtasks &&
      formState?.errors?.subtasks[index] &&
      formState?.errors?.subtasks[index][statusName]?.template?.body?.message
    : formState?.errors &&
      formState?.errors[statusName]?.template?.body?.message;

  const recipientsError = isSubtask
    ? formState?.errors?.subtasks &&
      formState?.errors?.subtasks[index] &&
      formState?.errors?.subtasks[index][statusName]?.recipients?.message
    : formState?.errors && formState?.errors[statusName]?.recipients?.message;

  const emailTemplatesOptions = useMemo(() => {
    const templates = templatesList.map(templateItem => ({
      value: templateItem?._id,
      key: templateItem?._id,
      label: templateItem?.title,
    }));

    return templates;
  }, [templatesList]);

  const recipientsOptions = useMemo(() => {
    const uniqueEmails = [];
    const uniqueRecipients = [];

    [
      ...memoizedCustomRecipientsOptions,
      ...recipientsList.map(emailItem => ({
        value: emailItem?.email,
        key: emailItem?.email,
        label: emailItem?.email,
      })),
    ].forEach(item => {
      if (!uniqueEmails.find(email => email === item?.key)) {
        uniqueEmails.push(item?.key);
        uniqueRecipients.push(item);
      }
    });

    return uniqueRecipients;
  }, [memoizedCustomRecipientsOptions, recipientsList]);

  const defaultRecipiends = useMemo(
    () =>
      (mappedTemplate &&
        mappedTemplate[statusName] &&
        mappedTemplate[statusName]?.recipients &&
        recipientsOptions
          .filter(item =>
            mappedTemplate[statusName]?.recipients?.find(
              el => el?.email === item.key
            )
          )
          .map(item => item.key)) ||
      [],
    [mappedTemplate, recipientsOptions, statusName]
  );

  const defaultTemplate = useMemo(() => {
    const template =
      mappedTemplate &&
      mappedTemplate[statusName] &&
      mappedTemplate[statusName]?.template;

    return template?._id;
  }, [mappedTemplate, statusName]);

  useEffect(() => {
    if (!!mappedTemplate) {
      const template = mappedTemplate[statusName]?.template;
      const recipients = mappedTemplate[statusName]?.recipients;

      if (template) {
        setSelectedTemplate({
          _id: template?._id,
          title: template?.title,
          body: template?.body,
        });
      }

      const customRecipients = recipients?.filter(
        item => !item?.firstName && !item?.lastName
      );

      if (customRecipients?.length) {
        setCustomRecipientsOptions(
          customRecipients.map(item => ({
            key: item?.email,
            value: item?.email,
            label: item?.email,
          }))
        );
      }
    }
  }, [mappedTemplate, statusName]);

  const handleCheck = useCallback(
    e => {
      const checkboxStatus = e.target.checked;

      if (!checkboxStatus) {
        setSelectedTemplate(undefined);
        setTemplate(undefined);
      }

      onChange(name, checkboxStatus);
      return checkboxStatus;
    },
    [onChange, name]
  );

  const handleTemplateSelect = useCallback(
    id => {
      const templateSettings = templatesList.find(item => item?._id === id);

      setSelectedTemplate(templateSettings);

      setValue(`${name}.template.body`, templateSettings?.body);
      setValue(`${name}.template.title`, templateSettings?.title);

      trigger();
      return id;
    },
    [trigger, setValue, templatesList, name]
  );

  const handleTemplateEdit = useCallback(
    editedItem => {
      const templateListItem = templatesList.find(
        item => item?._id === editedItem?.id
      );

      setSelectedTemplate({
        ...templateListItem,
        title: editedItem?.title,
        body: editedItem?.body,
      });

      setValue(`${name}.template.body`, editedItem?.body);
      setValue(`${name}.template.title`, editedItem?.title);

      trigger();
    },
    [setValue, trigger, name, templatesList]
  );

  const handleRecipientsSelect = useCallback(
    emailList => {
      const recipients = emailList.map(email => {
        const recipientObj = recipientsList?.find(
          item => item?.email === email
        );

        return recipientObj
          ? {
              email,
              firstName: recipientObj?.firstName,
              lastName: recipientObj?.lastName,
            }
          : { email, firstName: undefined, lastName: undefined };
      });

      setValue(`${name}.recipients`, recipients);
      trigger();
    },
    [trigger, setValue, name, recipientsList]
  );

  const handleAddRecipient = useCallback(
    email => {
      setCustomRecipientsOptions([
        ...customRecipientsOptions,
        { key: email, value: email, label: email },
      ]);
    },
    [customRecipientsOptions]
  );

  const handleGetPopupContainer = useCallback(
    trigger => trigger.parentNode,
    []
  );

  const handleFilterTemplate = useCallback(
    (searchVal, option) =>
      option?.label?.toUpperCase().indexOf(searchVal.toUpperCase()) !== -1,
    []
  );

  const handleTemplateSearch = value => {
    const isMatch = !!templatesList.find(item => item.title == value);
    if (value == '') {
      setErrorMessage('');
    } else if (!isMatch) {
      setErrorMessage('Please select one of the options');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <>
      <Checkbox checked={isChecked} label={label} onChange={handleCheck} />
      {isChecked ? (
        <SelectorsWrap id="statusCheckboxSelectors">
          <TemplateSelect
            width="172px"
            height="24px"
            placeholder="Select template"
            options={emailTemplatesOptions}
            showSearch
            editItem={selectedTemplate}
            defaultValue={defaultTemplate}
            value={selectedTemplate?.title}
            onChange={handleTemplateSelect}
            onSearch={handleTemplateSearch}
            onTemplateEdit={handleTemplateEdit}
            error={errorMessage || templateError}
            filterOption={handleFilterTemplate}
            getPopupContainer={handleGetPopupContainer}
          />
          <SelectInput
            name={`${name}.recipients`}
            width="260px"
            height="24px"
            placeholder="Email to"
            options={recipientsOptions}
            showSearch
            createType="email"
            isMultiple
            maxTagCount="responsive"
            isAllowCreate
            createValidation={email =>
              yup
                .object()
                .shape({
                  email: yup.string().email('invalid'),
                })
                .isValidSync({ email })
            }
            defaultValue={defaultRecipiends}
            onChange={handleRecipientsSelect}
            error={recipientsError}
            onCreate={handleAddRecipient}
            getPopupContainer={handleGetPopupContainer}
          />
        </SelectorsWrap>
      ) : null}
    </>
  );
};

export const StatusCheckbox = StatusCheckboxComponent;
