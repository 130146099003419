import styled, { css } from 'styled-components';
import { Layout } from 'antd';

const LayoutStyled = styled(Layout)<{ $sharing?: boolean }>`
  &.main {
    height: 100%;
  }
  ${({ $sharing }) =>
    !$sharing &&
    css`
      overflow: auto;
      flex: 1;
      background: ${({ theme }) => theme.colors.white};
      &.main {
        width: 100%;
        min-width: 1740px;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .rnc__notification-item {
        box-shadow: none;
      }
    `}
`;

export const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LayoutStyled;
