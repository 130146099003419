import styled from 'styled-components';
import { Divider } from 'antd';

export const BlockContainer = styled.div`
  padding: 0 38px;
`;

export const DividerItem = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 10px 0;
  }
`;

export const ButtonsWrapper = styled(BlockContainer)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 420px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
`;
