import styled from 'styled-components';
import { List } from 'antd';

export const Wrap = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 14px 6px 14px 14px;
  margin-right: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  width: 60%;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100%;
    flex-grow: 0;
    overflow: hidden;
  }
`;
export const Caption = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const ListStyled = styled(List)`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  min-height: 61px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 19px 11px 7px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 3px;
`;

export const ListRow = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.mutedText};
  font-weight: 400;
`;
