import { useCallback, useMemo } from 'react';
import { TASK_STATUSES } from 'lib/constants/tasks';
import {
  Percent,
  ProgressRow,
  ProgressSegment,
  ProgressStyled,
  Title,
} from './styles';

interface IProgressBarProps {
  item?: any;
  withTitle?: boolean;
  isOpen?: boolean;
  isLarge?: boolean;
}

const ProgressBar = ({
  item,
  withTitle,
  isOpen,
  isLarge,
}: IProgressBarProps) => {
  const sumProgress =
    +item.doneCount +
    +item.inProgressCount +
    +item.onReviewCount +
    +item.todoCount +
    +item.notReadyCount;

  const name = `#${item.order} ${item.name}`;

  const getPercent = useCallback(
    number => {
      if (item?.status === TASK_STATUSES.DONE) return 0;
      return (number * 100) / sumProgress;
    },
    [item, sumProgress]
  );

  const getSuccessPercent = useCallback(
    number => {
      if (item?.status === TASK_STATUSES.DONE) return 100;
      return (number * 100) / sumProgress;
    },
    [item, sumProgress]
  );

  const percent = getPercent(
    Number(item.inProgressCount) +
      Number(item.doneCount) +
      Number(item.onReviewCount)
  );
  const successPercent = getSuccessPercent(item.doneCount);
  const inProgressPercent = (item.inProgressCount * 100) / sumProgress;
  const onReviewPercent = (item.onReviewCount * 100) / sumProgress;
  const roundPercent = Math.round(successPercent);
  const percentShow = useMemo(
    () => (roundPercent === 100 || !sumProgress ? 'Done' : `${roundPercent}%`),
    [roundPercent, sumProgress]
  );

  const large = useMemo(() => (isLarge ? 'true' : ''), [isLarge]);

  return (
    <>
      {withTitle && (
        <Title large={large} isOpen={isOpen}>
          {name}
        </Title>
      )}
      {!isOpen && (
        <ProgressRow large={large}>
          <ProgressStyled>
            <ProgressSegment type="Done" width={successPercent} />
            <ProgressSegment type="In progress" width={inProgressPercent} />
            <ProgressSegment type="On review" width={onReviewPercent} />
          </ProgressStyled>
          {isLarge && (
            <Percent roundPercent={roundPercent}>{percentShow}</Percent>
          )}
        </ProgressRow>
      )}
    </>
  );
};

export default ProgressBar;
