import { takeEvery, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/company';
import * as E from './workers';

export function* watchCompany() {
  yield takeLatest(Actions.getCompany.TRIGGER, E.ensureCompany);
}

export function* watchUploadFavicon() {
  yield takeLatest(Actions.uploadFavicon.TRIGGER, E.ensureUploadFavicon);
}

export function* watchPatchCompany() {
  yield takeEvery(Actions.patchCompany.TRIGGER, E.ensurePatchCompany);
}

export function* watchUpdateLogo() {
  yield takeLatest(Actions.uploadLogo.TRIGGER, E.ensureUpdateLogo);
}

export function* watchDeleteCompany() {
  yield takeLatest(Actions.deleteCompany.TRIGGER, E.ensureDeleteCompany);
}

export function* watchDeleteSuperAdmin() {
  yield takeLatest(Actions.deleteSuperAdmin.TRIGGER, E.ensureDeleteSuperAdmin);
}

export function* watchTransferCompany() {
  yield takeLatest(Actions.transferCompany.TRIGGER, E.ensureTransferCompany);
}

export function* watchRestoreCompany() {
  yield takeLatest(Actions.restoreCompany.TRIGGER, E.ensureRestoreCompany);
}

export function* watchRegisterNewCompany() {
  yield takeLatest(
    Actions.registerNewCompany.TRIGGER,
    E.ensureRegisterNewCompany
  );
}

export function* watchGetEmployeesValues() {
  yield takeLatest(
    Actions.getEmployeesValues.TRIGGER,
    E.ensureGetEmployeesValues
  );
}
