import * as yup from 'yup';
import { email, firstName, jobTitle, lastName, locationId } from './baseSchema';

const editProfileSchema = yup.object().shape({
  firstName,
  lastName,
  jobTitle,
  email,
  locationId,
});

export default editProfileSchema;
