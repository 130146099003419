import * as yup from 'yup';

const emailTemplatesSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(20, 'Title must be at most 20 characters')
    .required('Title is required!'),
  body: yup
    .string()
    .trim()
    .max(1000, 'Description must be at most 1000 characters')
    .required('Template is required!'),
});

export default emailTemplatesSchema;
