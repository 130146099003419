import { HeaderCell } from '../../common';
import {
  ACTION_CELL_WIDTH,
  EDIT_CELL_WIDTH,
  NAME_CELL_WIDTH,
  PERCENT_CELL_WIDTH,
  STATUS_CELL_WIDTH,
} from '../../constants';
import { SortIcon } from 'lib/icons';
import { SideBar, SidebarTitle, Wrap } from './styles';

interface IHeaderProps {
  toggleSort: () => void;
  sortDirection: boolean;
}

const Header = ({ toggleSort, sortDirection }: IHeaderProps) => (
  <Wrap>
    <SideBar onClick={toggleSort}>
      <SortIcon direction={sortDirection} />
      <SidebarTitle>Timestamp</SidebarTitle>
    </SideBar>
    <HeaderCell width={STATUS_CELL_WIDTH} inResource>
      Status
    </HeaderCell>
    <HeaderCell width={ACTION_CELL_WIDTH} inResource>
      Action
    </HeaderCell>
    <HeaderCell width={PERCENT_CELL_WIDTH} inResource>
      Percent
    </HeaderCell>
    <HeaderCell width={NAME_CELL_WIDTH} inResource>
      Performed by
    </HeaderCell>
    <HeaderCell width={EDIT_CELL_WIDTH} inResource></HeaderCell>
  </Wrap>
);

export default Header;
