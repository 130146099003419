import { END, eventChannel } from 'redux-saga';
import { call, cancelled, fork, put, select, take } from 'redux-saga/effects';
import * as Dashboard from 'store/dashboard';
import * as Gantt from 'store/gantt';
import * as Sharing from 'store/sharing';
import { getToken } from 'utils/helpers';
import { eventEnum } from 'lib/constants/enumsForSockets';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_SOCKET || '';

function createSocketChannel(jwt) {
  let isClosing = false;
  let socket;
  let timer;

  return eventChannel(emit => {
    function createWs() {
      socket = new WebSocket(API_URL);
      socket.onopen = () => {
        if (timer) {
          clearTimeout(timer);
        }

        socket.send(
          JSON.stringify({
            event: 'user-authentication',
            data: { jwt },
          })
        );
      };

      socket.onmessage = event => {
        let msg = null;
        try {
          msg = JSON.parse(event.data);
          if (msg.event === eventEnum.unauthorized) isClosing = true;
        } catch (e) {
          console.error(`Error parsing : ${event.data}`);
        }
        if (msg) emit(msg);
      };

      socket.onerror = () => {
        if (timer) {
          clearTimeout(timer);
        }
      };

      socket.onclose = () => {
        if (isClosing) return emit(END);

        timer = setTimeout(() => {
          createWs();
        }, 5000);
      };
    }

    createWs();

    return () => {
      socket.onmessage = null;
      isClosing = true;
      socket.close();
    };
  });
}

function* listenForSocketMessages(jwt) {
  let socketChannel;

  try {
    socketChannel = yield call(createSocketChannel, jwt);
    while (true) {
      // wait for a message from the channel, without 'while' sockets will not work
      const payload = yield take(socketChannel);
      const { event, data } = payload;
      const project = yield select(Dashboard.projectSelector);
      const standaloneSubtask = yield select(
        Dashboard.standaloneSubtaskSelector
      );
      const ganttPeriod = yield select(Dashboard.ganttDatesSelector);
      const needUpdateProject =
        project?._id && data?.projectId && project?._id === data?.projectId;
      const needUpdateSubtask =
        standaloneSubtask?._id &&
        data?.subtaskId &&
        project?._id === data?.subtaskId;
      const needGanttUpdate =
        ganttPeriod &&
        data?.startDate &&
        moment(ganttPeriod?.startDate).valueOf() <=
          moment(data?.dueDate).valueOf() &&
        moment(ganttPeriod?.endDate).valueOf() >=
          moment(data?.startDate).valueOf();

      switch (event) {
        // all projects - project - gantt for period
        case eventEnum.resized:
        case eventEnum.started:
        case eventEnum.updated:
        case eventEnum.patched:
        case eventEnum.finished:
        case eventEnum.subtaskPaused:
        case eventEnum.subtaskResumed:
        case eventEnum.reverted:
        case eventEnum.approved: {
          if (needGanttUpdate) {
            yield put(Dashboard.gantt.trigger({ noLoader: true }));
          }
          if (needUpdateProject) {
            yield put(Dashboard.project.trigger({ id: data?.projectId }));
          }
          yield put(
            Dashboard.projects.trigger({ getAll: true, noLoader: true })
          );
          break;
        }

        case eventEnum.subtaskLogItemUpdated:
        case eventEnum.undone: {
          yield put(Dashboard.subtaskDetails.trigger(data?.projectId));
          yield put(Dashboard.gantt.trigger({ noLoader: true }));
          break;
        }

        // project - gantt for period
        case eventEnum.assigned:
        case eventEnum.unassigned:
        case eventEnum.attached:
        case eventEnum.validated:
        case eventEnum.subtaskUndone:
        case eventEnum.rejected: {
          if (needGanttUpdate) {
            yield put(Dashboard.gantt.trigger({ noLoader: true }));
          }
          if (needUpdateProject) {
            yield put(Dashboard.project.trigger({ id: data?.projectId }));
          }
          break;
        }

        // all projects - project
        case eventEnum.requestedForTakeIn:
        case eventEnum.markAsDelivered:
        case eventEnum.requestForTakeOut: {
          if (needUpdateProject) {
            yield put(Dashboard.project.trigger({ id: data?.projectId }));
          }
          yield put(
            Dashboard.projects.trigger({ getAll: true, noLoader: true })
          );
          break;
        }

        // all projects
        case eventEnum.created: {
          yield put(
            Dashboard.projects.trigger({ getAll: true, noLoader: true })
          );
          break;
        }

        case eventEnum.messageAdded: {
          yield checkMessages(data?.subtaskId);

          const isStandaloneSubtaskOpen = yield select(
            Gantt.isOpenStandaloneSubtaskSelector
          );
          const isProjectOpen = yield select(Gantt.isOpenDrawerSelector);

          if (isProjectOpen) {
            yield put(
              Dashboard.subtasksUnreadMessagesCount.trigger({
                subtasksIds: [data?.subtaskId],
                isStandalone: false,
              })
            );
          }

          if (
            standaloneSubtask._id === data?.subtaskId &&
            isStandaloneSubtaskOpen
          ) {
            yield put(
              Dashboard.subtasksUnreadMessagesCount.trigger({
                subtasksIds: [data?.subtaskId],
                isStandalone: true,
              })
            );
          }
          break;
        }

        case eventEnum.messageDeleted:
        case eventEnum.messageUpdated: {
          yield checkMessages(data?.subtaskId);
          break;
        }

        case eventEnum.systemNotificationCreated:
        case eventEnum.systemNotificationUpdated:
        case eventEnum.systemNotificationDeleted: {
          const isSystemNotificationsOpened = yield select(
            Sharing.isSystemNotificationsOpenedSelector
          );
          if (isSystemNotificationsOpened) {
            yield put(Sharing.syncNotifications.trigger());
          }

          break;
        }

        case eventEnum.systemNotificationsCountUpdated: {
          yield put(Sharing.getSystemNotificationsUnreadCount.trigger());
          break;
        }

        // gantt
        case eventEnum.standaloneSubtaskCreated:
        case eventEnum.standaloneSubtaskDeleted: {
          if (needGanttUpdate) {
            yield put(Dashboard.gantt.trigger({ noLoader: true }));
          }
          break;
        }

        // standalone subtasks
        case eventEnum.standaloneSubtaskUpdated: {
          if (needGanttUpdate) {
            yield put(Dashboard.gantt.trigger({ noLoader: true }));
          }
          if (needUpdateSubtask) {
            yield put(Dashboard.getStandaloneSubtask.trigger(data?.subtaskId));
          }
          break;
        }

        case eventEnum.unauthorized: {
          socketChannel.close();
          break;
        }

        case eventEnum.checklistMarkedAsCompleted: {
          const { subtaskId, checklistItemId } = data;

          if (standaloneSubtask?._id) {
            yield put(
              Dashboard.checklistStandaloneSocketMarkAsCompleted.trigger({
                subtaskId,
                checklistItemId,
              })
            );
          } else if (project?._id) {
            yield put(
              Dashboard.checklistSocketMarkAsCompleted.trigger({
                subtaskId,
                checklistItemId,
              })
            );
          }

          break;
        }

        case eventEnum.checklistMarkedAsUncompleted: {
          const { subtaskId, checklistItemId } = data;

          if (standaloneSubtask?._id) {
            yield put(
              Dashboard.checklistStandaloneSocketMarkAsUncompleted.trigger({
                subtaskId,
                checklistItemId,
              })
            );
          } else if (project?._id) {
            yield put(
              Dashboard.checklistSocketMarkAsUncompleted.trigger({
                subtaskId,
                checklistItemId,
              })
            );
          }

          break;
        }

        default:
          break;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error while connecting to the WebSocket', error);
  } finally {
    if (yield cancelled()) {
      // close the channel
      socketChannel.close();
      // eslint-disable-next-line no-console
      console.log('WebSocket closed');
    } else {
      // eslint-disable-next-line no-console
      console.log('WebSocket disconnected');
    }
  }
}

export function* connect() {
  const jwt = getToken();

  if (jwt) {
    yield fork(listenForSocketMessages, jwt);
  }
}

function* checkMessages(subtaskId) {
  const openedSubtaskChatId = yield select(
    Dashboard.openedSubtaskChatIdSelector
  );

  if (openedSubtaskChatId === subtaskId) {
    yield put(
      Dashboard.getSubtaskMessages.trigger({
        subtaskId,
      })
    );
  }
}
