import styled from 'styled-components';

export const ConflictContainer = styled.div``;

export const ConflictTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const ConflictName = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 12px;
  box-sizing: border-box;
  align-items: center;
  background: #f4f5f7;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const ConflictDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 24px;
`;
