import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get groups
export const groups = createRoutine('admin/groups');
// change order
export const groupsOrder = createRoutine('admin/groupsOrder');
export const groupsOrderPromiseCreator = promisifyRoutine(groupsOrder);
// group data
export const getGroup = createRoutine('admin/getGroup');
export const getGroupPromiseCreator = promisifyRoutine(getGroup);
// add group
export const addGroup = createRoutine('admin/addGroup');
export const addGroupPromiseCreator = promisifyRoutine(addGroup);
// update group
export const updateGroup = createRoutine('admin/updateGroup');
export const updateGroupPromiseCreator = promisifyRoutine(updateGroup);
// delete group
export const deleteGroup = createRoutine('admin/deleteGroup');
export const deleteGroupPromiseCreator = promisifyRoutine(deleteGroup);
// add resource to group
export const addResourceToGroup = createRoutine('admin/addResourceToGroup');
export const addResourceToGroupCreator = promisifyRoutine(addResourceToGroup);
// delete resource from group
export const removeResourceFromGroup = createRoutine(
  'admin/removeResourceFromGroup'
);
export const removeResourceFromGroupCreator = promisifyRoutine(
  removeResourceFromGroup
);
