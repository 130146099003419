import styled, { css } from 'styled-components';
// styles
import { commonInputStyles } from 'components/common/commonInputStyles';
// component
import { InputNumber } from 'antd';

export const InputStyled = styled(InputNumber)<any>`
  ${commonInputStyles};
  position: relative;
  padding: 10px;
  width: ${props => props.width};
  height: ${props => props.height};
  overflow: hidden;
  ${({ value, defaultValue }) =>
    ![null, undefined].includes(defaultValue || value) &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `}

  .ant-input-number-input-wrap {
    width: 100%;
  }

  .ant-input-number-input {
    height: auto;
    padding: 0 25px 0 0;
  }
  &.ant-input-number-focused {
    box-shadow: none;
    outline: none;
  }
  &&& .ant-input-number-handler-wrap {
    background-color: ${({ theme }) => theme.colors.grayDark};
    width: 30px;
    border: none;
    opacity: 1;
  }
  &&& .ant-input-number-handler .ant-input-number-handler-up,
  .ant-input-number-handler-down,
  .ant-input-number-handler-up:hover,
  .ant-input-number-handler-down:hover {
    border: none;
    height: 50% !important;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.grayDark};
  }
  .ant-input-number-handler-up:focus,
  .ant-input-number-handler-down:focus {
    outline: none;
  }
  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 50%;
  }

  ${props =>
    props.noicon &&
    css`
      &&& .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input {
        padding: 0;
      }
    `}
`;
