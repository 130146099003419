import { useState } from 'react';
import { useSelector } from 'react-redux';
// redux
import {
  deleteWorkOrdersPromiseCreator,
  workOrdersSelector,
} from 'store/inbound';
// components
import Search from './Search';
import Table from './Table';
// constants
import { SORT_TYPES } from './constants';
// utils
import { useActionsRoutines } from 'hooks';
// styles
import { Heading, Titile, Wrap } from './styles';

interface IWorkOrdersProps {
  openDivideTasks: (id: string) => void;
  getWorkOrders: ({
    invoice,
    sort,
  }: {
    invoice: string;
    sort: SORT_TYPES;
  }) => void;
  openCreateModal: (id: string) => void;
}

export const WorkOrders = ({
  openDivideTasks,
  getWorkOrders,
  openCreateModal,
}: IWorkOrdersProps): JSX.Element => {
  const data = useSelector(workOrdersSelector);
  const deleteWorkOrders = useActionsRoutines(deleteWorkOrdersPromiseCreator);

  const [search, setSearch] = useState('');
  const [isDateFilterUp, setIsDateFilterUp] = useState(false);

  const onDateFilterChange = () => {
    setIsDateFilterUp(!isDateFilterUp);
    getWorkOrders({
      invoice: search,
      sort: !isDateFilterUp ? SORT_TYPES.UP : SORT_TYPES.DOWN,
    });
  };

  const deleteWorkOrder = async (id: string) =>
    deleteWorkOrders({
      id,
      params: {
        invoice: search,
        sort: isDateFilterUp ? SORT_TYPES.UP : SORT_TYPES.DOWN,
      },
    }).catch(err => {
      console.error(err);
    });

  return (
    <Wrap>
      <Heading>
        <Titile>
          Inbound WO <span>({data.length})</span>
        </Titile>
        <Search setSearch={setSearch} isDateFilterUp={isDateFilterUp} />
      </Heading>
      <Table
        deleteWorkOrder={deleteWorkOrder}
        data={data}
        isDateFilterUp={isDateFilterUp}
        onDateFilterChange={onDateFilterChange}
        openDivideTasks={openDivideTasks}
        openCreateModal={openCreateModal}
      />
    </Wrap>
  );
};
