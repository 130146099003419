import { createSlice } from '@reduxjs/toolkit';

export const loading = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
    isTabLoading: false,
    isPageLoading: 0,
    isFilesLoading: false,
  },
  reducers: {
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
    tabLoading: (state, action) => {
      state.isTabLoading = action.payload;
    },
    pageLoading: (state, action) => {
      state.isPageLoading = action.payload
        ? state.isPageLoading + 1
        : state.isPageLoading - 1;
    },
    isFilesLoading: (state, action) => {
      state.isFilesLoading = action.payload;
    },
  },
});

export const { actions, reducer } = loading;
