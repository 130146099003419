import { SVGProps } from 'react';
import theme from 'styles/theme';

function TwoArrowsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={10}
      height={12}
      fill={theme.colors.lightBlue}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.175 5.184L5 9.14l3.825-3.957L10 6.402l-5 5.184-5-5.184 1.175-1.218z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.175 0L5 3.958 8.825 0 10 1.218 5 6.403 0 1.218 1.175 0z"
      />
    </svg>
  );
}

export default TwoArrowsIcon;
