import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 40px;
  color: ${({ theme }) => theme.colors.lightBlue};
  input {
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;
export const Title = styled.h1`
  margin: 0 0 3px;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 17px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const EmailBlock = styled.div`
  margin-bottom: 10px;
`;
