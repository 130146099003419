import { takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/inbound';
import * as E from './workers';

export function* watchWorkOrdersInfo() {
  yield takeLatest(Actions.workOrdersInfo.TRIGGER, E.ensureWorkOrdersInfo);
}

export function* watchWorkOrders() {
  yield takeLatest(Actions.workOrders.TRIGGER, E.ensureWorkOrders);
}

export function* watchWorkOrder() {
  yield takeLatest(Actions.workOrder.TRIGGER, E.ensureWorkOrder);
}

export function* watchDivideTasks() {
  yield takeLatest(Actions.divideTasks.TRIGGER, E.ensureDivideTasks);
}

export function* watchCreateProjectFormWorkOrder() {
  yield takeLatest(
    Actions.createProjectFormWorkOrder.TRIGGER,
    E.ensureCreateProjectFormWorkOrder
  );
}

export function* watchDeleteWorkOrders() {
  yield takeLatest(Actions.deleteWorkOrders.TRIGGER, E.ensureDeleteWorkOrders);
}
