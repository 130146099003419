import { call, put, select } from 'redux-saga/effects';
import { updateUser, userSelector } from 'store/auth';
import * as Actions from 'store/company';
import { actions } from 'store/loading';
import Api from 'utils/api';
import {
  FileUploadResDto,
  IEnsureAdministratorsPayload,
  IEnsureAvatarPayload,
  IEnsureDeleteUserPayload,
  IEnsurePatchUserAdminPayload,
  IEnsurePutUserAdminPayload,
  UserDto,
} from 'types';

export function* ensureAvatarAdmin({
  payload,
}: {
  payload: IEnsureAvatarPayload;
  type: typeof Actions.updateAvatarAdmin.TRIGGER;
}) {
  try {
    const data: FileUploadResDto = yield call(Api.user.postAvatar, payload);
    yield put(Actions.updateAvatarAdmin.success(data));
  } catch (err) {
    yield put(Actions.updateAvatarAdmin.failure(err));
  }
}

export function* ensureAdministrators({
  payload,
}: {
  payload?: IEnsureAdministratorsPayload;
  type: typeof Actions.administrators.TRIGGER;
}) {
  const users = yield select(Actions.administratorsSelector);
  const noLoader = payload?.noLoader || (!payload?.search && users?.length);
  try {
    let params: unknown;

    if (payload?.search) {
      params = {
        filter: {
          $or: [{ firstName: payload?.search }, { lastName: payload?.search }],
        },
      };
    }

    if (!noLoader) yield put(actions.tabLoading(true));
    const data: UserDto[] = yield call(Api.user.getUsers, {
      params,
    });
    yield put(Actions.administrators.success(data));
  } catch (err) {
    yield put(Actions.administrators.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureDeleteUser({
  payload,
}: {
  payload: IEnsureDeleteUserPayload;
  type: typeof Actions.deleteUser.TRIGGER;
}) {
  try {
    yield call(Api.user.deleteUser, payload?.id);
    yield call(ensureAdministrators, {
      payload: { search: payload?.stringForSearch, noLoader: true },
      type: typeof Actions.administrators.TRIGGER,
    });
    yield put(Actions.deleteUser.success());
  } catch (err) {
    yield put(Actions.deleteUser.failure(err));
  }
}

export function* ensureGetUserAdmin({
  payload,
}: {
  payload: string;
  type: typeof Actions.getUserAdmin.TRIGGER;
}) {
  try {
    const data: UserDto = yield call(Api.user.getUser, payload);

    yield put(Actions.getUserAdmin.success(data));
  } catch (err) {
    yield put(Actions.getUserAdmin.failure(err));
  }
}

export function* ensurePutUserAdmin({
  payload,
}: {
  payload: IEnsurePutUserAdminPayload;
  type: typeof Actions.updateUserAdmin.TRIGGER;
}) {
  try {
    const { data, id, stringForSearch, file } = payload;
    if (file) {
      yield call(ensureAvatarAdmin, {
        payload: { file, id },
        type: Actions.updateAvatarAdmin.TRIGGER,
      });
    }
    const res = yield call(Api.user.putUser, { data, id });

    yield call(ensureAdministrators, {
      payload: { search: stringForSearch, noLoader: true },
      type: typeof Actions.administrators.TRIGGER,
    });

    const currentUser = yield select(userSelector);

    if (currentUser._id === id) {
      yield put(updateUser.success(res));
    }
    yield put(Actions.updateUserAdmin.success(res));
  } catch (err) {
    yield put(Actions.updateUserAdmin.failure(err));
  }
}

export function* ensurePatchUserAdmin({
  payload,
}: {
  payload: IEnsurePatchUserAdminPayload;
  type: typeof Actions.patchUserAdmin.TRIGGER;
}) {
  try {
    const { data, id } = payload;
    const res: UserDto = yield call(Api.user.patchUser, { data, id });

    yield put(Actions.patchUserAdmin.success(res));
  } catch (err) {
    yield put(Actions.patchUserAdmin.failure(err));
  }
}
