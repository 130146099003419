import styled, { css } from 'styled-components';
// components
import { Checkbox } from 'antd';

export const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox {
    border-radius: 3px;
    font-size: 12px;
    &:hover {
      .ant-checkbox-inner {
        border: 1px solid ${({ theme }) => theme.colors.mainSuccess};
      }
    }
  }
  .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.disabled};
  }

  .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.mainSuccess};
      border-color: ${({ theme }) => theme.colors.mainSuccess};
    }
    &:after {
      border: 1px solid ${({ theme }) => theme.colors.mainSuccess};
    }
  }
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid ${({ theme }) => theme.colors.mainSuccess};
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.mainSuccess};
  }
`;

export const Wrap = styled.div`
  display: flex;
`;

export const Label = styled.div<{
  crossChecked?: boolean;
  checked?: boolean;
  short?: boolean;
}>`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-top: 3px;
  margin-left: 8px;
  text-decoration: ${props =>
    props.crossChecked && props.checked ? 'line-through' : ''};
  ${props =>
    props.short &&
    css`
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
