import * as yup from 'yup';
import {
  email,
  employees,
  firstName,
  jobTitle,
  lastName,
  password,
  phoneNumber,
  verifyPassword,
} from './baseSchema';

const SignUpSchema = yup.object().shape({
  companyName: yup.string().trim().required('Required!'),
  jobTitle,
  email,
  employees: yup.string().when('$isUserInvited', (isUserInvited, schema) => {
    if (!isUserInvited) return employees;
    else return schema.nullable();
  }),
  companyPhoneNumber: yup
    .string()
    .when('$isUserInvited', (isUserInvited, schema) => {
      if (!isUserInvited) return phoneNumber;
      else return schema.nullable();
    }),
  firstName,
  lastName,
  password: yup.string().when('$isGoogleSignUp', (isGoogleSignUp, schema) => {
    if (!isGoogleSignUp) return password;
    else return schema.nullable();
  }),
  verifyPassword: yup
    .string()
    .when('$isGoogleSignUp', (isGoogleSignUp, schema) => {
      if (!isGoogleSignUp) return verifyPassword;
      else return schema.nullable();
    }),
  // TODO - add this back in when we have a terms and conditions
  // acceptTerms: yup
  //   .boolean()
  //   .oneOf([true], 'You must accept the terms and conditions'),
});

export default SignUpSchema;
