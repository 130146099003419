import styled from 'styled-components';
import theme from 'styles/theme';
import { Badge } from 'antd';

export const BadgeItem = styled(Badge)`
  & .ant-badge-count {
    height: 16px;
    min-width: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: 600;
    border: 1px solid ${theme.colors.white};
  }
`;
