import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  closeSubtaskChat,
  markSubtaskMessagesAsSeenPromiseCreator,
  openedSubtaskChatIdSelector,
  standaloneUnreadMessagesSelector,
  subtasksUnreadMessagesCountPromiseCreator,
  subtasksUnreadMessagesCountSelector,
} from 'store/dashboard';
import { ChatContent } from './ChatContent';
import BadgeWrapper from 'components/BadgeWrapper/BadgeWrapper';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
import { Comment } from 'lib/icons';
import { Popover } from 'antd';
import { ChatWrap, MessagesCoutner } from './styles';

const Chat = ({
  data,
  isInProject = false,
  isProjectDeleted = false,
  isProjectArchived = false,
}) => {
  const openedSubtaskChatId = useSelector(openedSubtaskChatIdSelector);
  const markMessagesAsSeen = useActionsRoutines(
    markSubtaskMessagesAsSeenPromiseCreator
  );
  const subtaskUnreadMessageCount = useActionsRoutines(
    subtasksUnreadMessagesCountPromiseCreator
  );
  const closeChat = useActions(closeSubtaskChat);
  const [isVisible, setIsVisible] = useState(false);

  // TODO update any
  const unreadMessages = useSelector(
    isInProject
      ? (subtasksUnreadMessagesCountSelector as any)
      : standaloneUnreadMessagesSelector
  );

  const unreadCount = useMemo(
    () => (isInProject ? unreadMessages[data._id] : unreadMessages),
    [isInProject, unreadMessages, data._id]
  );

  const updateUnreadMessages = useCallback(async () => {
    try {
      await markMessagesAsSeen(data._id);
      await subtaskUnreadMessageCount({
        subtasksIds: [data._id],
        isStandalone: !isInProject,
      });
    } catch (error) {
      console.error(error);
    }
  }, [data._id, markMessagesAsSeen, isInProject, subtaskUnreadMessageCount]);

  const handleCloseChat = useCallback(async () => {
    setIsVisible(false);
    closeChat();
    if (unreadCount && unreadCount > 0) await updateUnreadMessages();
  }, [unreadCount, closeChat, updateUnreadMessages]);

  const handleVisibleChange = useCallback(
    async newValue => {
      setIsVisible(newValue);

      if (!newValue) await handleCloseChat();
    },
    [handleCloseChat]
  );

  const handleGetPopupContainer = useCallback(
    triggerNode =>
      isInProject
        ? document.getElementById('subtask-container')
        : triggerNode.parentNode,
    [isInProject]
  );

  const isPopoverVisible = isVisible || openedSubtaskChatId === data?._id;

  return (
    <Popover
      visible={isPopoverVisible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={handleGetPopupContainer}
      content={
        <ChatContent
          subtaskId={data?._id}
          handleCloseChat={handleCloseChat}
          isInProject={isInProject}
          isProjectDeleted={isProjectDeleted}
          isProjectArchived={isProjectArchived}
        />
      }
      destroyTooltipOnHide
      overlayClassName="ChatPopover"
      placement="topRight"
      trigger="click"
    >
      <ChatWrap>
        {data?.comments?.length > 1 && (
          <MessagesCoutner>{data.comments.length}</MessagesCoutner>
        )}
        <Button
          onClick={null}
          kind={BUTTON_TYPES.PRIMARY}
          icon={
            <BadgeWrapper count={unreadCount}>
              <Comment />
            </BadgeWrapper>
          }
          width="36px"
          height="28px"
          lineheight="0px"
          space="0px"
          padding="0px"
        />
      </ChatWrap>
    </Popover>
  );
};

export default Chat;
