import { TABLE_HEADER_COLUMNS } from '../../constants';
import DateFilter from './DateFilter';
import { TableHeaderCol, TableHeaderWrap } from './styles';
import { TableHeaderColumnsEnum } from 'types';

interface ITableHeaderProps {
  isDateFilterUp: boolean;
  onDateFilterChange: () => void;
}

const TableHeader = ({
  isDateFilterUp,
  onDateFilterChange,
}: ITableHeaderProps): JSX.Element => (
  <TableHeaderWrap>
    {TABLE_HEADER_COLUMNS.map((item: TableHeaderColumnsEnum) => (
      <TableHeaderCol colName={item} key={item}>
        {item === 'Date' ? (
          <DateFilter
            isDateFilterUp={isDateFilterUp}
            onChange={onDateFilterChange}
          />
        ) : (
          item
        )}
      </TableHeaderCol>
    ))}
  </TableHeaderWrap>
);

export default TableHeader;
