interface IClocksProps {
  size?: string;
  color?: string;
}

export const Clocks = ({
  size = '12',
  color = '#172B4D',
}: IClocksProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.994 0C2.682 0 0 2.688 0 6C0 9.312 2.682 12 5.994 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 5.994 0ZM6 10.8C3.348 10.8 1.2 8.652 1.2 6C1.2 3.348 3.348 1.2 6 1.2C8.652 1.2 10.8 3.348 10.8 6C10.8 8.652 8.652 10.8 6 10.8ZM6.3 3H5.4V6.6L8.55 8.49L9 7.752L6.3 6.15V3Z"
      fill={color}
    />
  </svg>
);
