import { Dispatch, SetStateAction, useRef } from 'react';
// redux
import { workOrders } from 'store/inbound';
// utils
import { replaceForRequest } from 'utils/helpers';
// constants
import { SORT_TYPES } from '../constants';
// components
import { SearchInput } from 'components/common';
// hooks
import { useActions } from 'hooks';
import { debounce } from 'lodash';

interface ISearchProps {
  setSearch: Dispatch<SetStateAction<string>>;
  isDateFilterUp: boolean;
}

const Search = ({ setSearch, isDateFilterUp }: ISearchProps): JSX.Element => {
  const getWorkOrders = useActions(workOrders);

  const delayedQuery = useRef(
    debounce((val, sort) => {
      const invoice = replaceForRequest(val);
      getWorkOrders({ invoice, sort });
      setSearch(replaceForRequest(val));
    }, 500)
  ).current;

  const findWorkOrder = e => {
    const sort = isDateFilterUp ? SORT_TYPES.UP : SORT_TYPES.DOWN;
    delayedQuery(e.target.value, sort);
  };

  return <SearchInput onChange={findWorkOrder} />;
};

export default Search;
