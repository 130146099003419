import { call, put } from 'redux-saga/effects';
import * as Actions from './actions';
import Api from 'utils/api';
import { SendInviteBodyDto } from 'types';

export function* ensureGetInvitationsList() {
  try {
    const data = yield call(Api.invitations.getInvitationsList);
    yield put(Actions.getInvitationsList.success(data));
  } catch (err) {
    yield put(Actions.getInvitationsList.failure(err));
  }
}

export function* ensureInviteUser({
  payload,
}: {
  payload: SendInviteBodyDto;
  type: typeof Actions.inviteUser.TRIGGER;
}) {
  try {
    const response = yield call(Api.invitations.inviteUser, payload);

    yield put(Actions.inviteUser.success(response));
  } catch (err) {
    yield put(Actions.inviteUser.failure(err));
  }
}

export function* ensureDeleteInvite({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteInvite.TRIGGER;
}) {
  try {
    yield call(Api.invitations.deleteInvite, payload);
    yield put(Actions.deleteInvite.success(payload));
  } catch (err) {
    yield put(Actions.deleteInvite.failure(err));
  }
}
