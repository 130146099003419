import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* groupsSaga() {
  yield all([
    fork(W.watchGetGroups),
    fork(W.watchGetGroup),
    fork(W.watchAddGroup),
    fork(W.watchAddResourceToGroup),
    fork(W.watchDeleteGroup),
    fork(W.watchUpdateGroup),
    fork(W.watchRemoveResourceFromGroup),
    fork(W.watchOrderGroup),
  ]);
}
