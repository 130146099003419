import styled from 'styled-components';

export const ProjectActionButtons = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 16px;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;
