import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Info } from 'lib/icons/Info';
import theme from 'styles/theme';
import * as Styled from './styles';
import { GeneratedApiKeyDto } from 'types';

interface TaskRedirectProps {
  nextSubtaskId?: string;
  generatedApiKey?: GeneratedApiKeyDto;
  isTaskFailed: boolean;
  showUpload?: boolean;
}

export const TaskRedirect: FC<TaskRedirectProps> = ({
  nextSubtaskId,
  generatedApiKey,
  isTaskFailed,
  showUpload,
}) => {
  const redirectLink = `/subtasks/${nextSubtaskId}/sharing/${generatedApiKey?.value}`;

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <div>
          <Info color={theme.colors.primary} width="24" height="24" />
        </div>
        <div>
          <Styled.Title>Your task has been closed</Styled.Title>
          <Styled.Text>
            {isTaskFailed ? (
              <>
                Oops! We noticed that the task you recently closed has marked as
                failed. A new task has been assigned to you, task is available
                by link below
              </>
            ) : (
              <>
                Because the work was paused and been marked as partially
                completed by owner. However, we’ve created a new task that you
                can work on instead
                {showUpload ? ' and you still can upload photo result.' : '.'}
              </>
            )}
          </Styled.Text>
          <Link to={redirectLink}>
            <Styled.Button label="Click here to open the new task" />
          </Link>
        </div>
      </Styled.Content>
    </Styled.Wrapper>
  );
};
