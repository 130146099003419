import styled, { css } from 'styled-components';
import { Button } from 'components/common';
import { Row } from 'antd';

export const RowStyled = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  max-width: 100%;
`;

const buttonStyle = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border: none;
  &:hover,
  &:focus {
    border: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ButtonFailed = styled(Button)`
  ${buttonStyle};
  background-color: ${({ theme }) => theme.colors.danger};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.darkDanger};
  }
`;

export const ButtonApprove = styled(Button)`
  ${buttonStyle};
  background-color: ${({ theme }) => theme.colors.eucalyptus};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.mainSuccess};
  }
`;
