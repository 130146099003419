import styled, { css } from 'styled-components';
import { DESKTOP_WIDTH_PX } from '../constants';

export const Wrap = styled.div`
  padding: 17px 13px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 44px;
  max-width: 764px;
  margin-bottom: 12px;
  width: 100%;
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    padding: 28px 31px;
    margin-top: 53px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 9px;
`;

export const NotesTitle = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 4px;
`;

export const Notes = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Status = styled.div<{ preset: any }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 1px 5px;
  display: inline-flex;
  border-radius: 3px;
  margin-bottom: 10px;
  ${({ preset }) => css`
    color: ${preset.color};
    background-color: ${preset.background};
  `}
`;
