import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { cancel, fork, select, takeEvery } from 'redux-saga/effects';
import * as Groups from 'store/admin/groups/sagas';
import * as Auth from 'store/auth';
import * as CompanyInfo from 'store/company/info/sagas';
import * as Dashboard from 'store/dashboard/sagas';
import * as Colors from 'store/depot/colors/sagas';
import * as TruckingCompanies from 'store/depot/companies/sagas';
import * as Conditions from 'store/depot/conditions/sagas';
import * as Grades from 'store/depot/grades/sagas';
import * as Sizes from 'store/depot/sizes/sagas';
import * as Inventory from 'store/inventory/sagas';
// sagas
import * as Profile from 'store/profile/sagas';
import * as RecurringTasks from 'store/project/recurringTasks/sagas';
import * as Tasks from 'store/project/tasks/sagas';
import { reportsSaga } from 'store/reports';
import { ROUTES, query as querySelector } from 'store/router';
import * as Subcontractors from 'store/subcontractors/sagas';
import * as Timesheets from 'store/timesheets/sagas';

const path = [
  { path: ROUTES.DASHBOARD, fn: Dashboard.dashboardSaga, exact: true },
  { path: ROUTES.SETTINGS_PROFILE, fn: Profile.profileSaga, exact: false },
  { path: ROUTES.LOGIN, fn: Auth.ensureLoginUser, exact: true },
  { path: ROUTES.REGISTER, fn: Auth.watchSignUp, exact: true },
  { path: ROUTES.HOME, fn: Auth.watchLogin, exact: true },
  { path: ROUTES.FORGOT_PASSWORD, fn: Auth.watchForgotPassword, exact: true },
  { path: ROUTES.RESET_PASSWORD, fn: Auth.watchResetPassword, exact: true },
  { path: ROUTES.EMAIL_VERIFICATION, fn: Auth.watchVerifyEmail, exact: true },

  {
    path: '/projects/:id/sharing/:jwt',
    fn: Auth.ensureSharingProject,
    exact: true,
  },
  {
    path: '/subtasks/:id/sharing/:jwt',
    fn: Subcontractors.subcontractorsSaga,
    exact: true,
  },

  {
    path: ROUTES.SETTINGS_COMPANY_INFO,
    fn: CompanyInfo.companyInfoSaga,
    exact: true,
  },

  { path: ROUTES.SETTINGS_PROJECT_ITEMS, fn: Tasks.tasksSaga, exact: true },
  { path: ROUTES.SETTINGS_REPORTS, fn: reportsSaga, exact: false },
  {
    path: ROUTES.SETTINGS_PROJECT_RECURRING_TASKS,
    fn: RecurringTasks.recurringTasksSaga,
    exact: true,
  },

  {
    path: ROUTES.SETTINGS_ADMIN_RESOURCES,
    exact: true,
  },
  { path: ROUTES.SETTINGS_ADMIN_GROUPS, fn: Groups.groupsSaga, exact: true },
  {
    path: ROUTES.SETTINGS_ADMIN_EMAIL_TEMPLATES,
    exact: true,
  },
  { path: ROUTES.INVENTORY, fn: Inventory.inventorySaga, exact: true },

  {
    path: ROUTES.SETTINGS_DEPOT_CONTAINERS,
    fn: Tasks.tasksSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_DEPOT_COLORS,
    fn: Colors.depotColorsSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_DEPOT_SIZES,
    fn: Sizes.depotSizesSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_DEPOT_CONDITIONS,
    fn: Conditions.depotConditionsSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_DEPOT_GRADES,
    fn: Grades.depotGradesSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_DEPOT_TRUCKING_COMPANIES,
    fn: TruckingCompanies.depotCompaniesSaga,
    exact: true,
  },
  {
    path: ROUTES.SETTINGS_TIME_SHEETS,
    fn: Timesheets.timesheetsSaga,
    exact: true,
  },
];

let task;

function* routeMatcher({ payload }) {
  const { location } = payload;
  for (const item of path) {
    const match = matchPath(location.pathname, {
      exact: 'exact' in item ? item.exact : true,
      strict: false,
      ...item,
    });
    if (task) yield cancel(task);
    const query = yield select(querySelector);
    if (match !== null && item?.fn) {
      // @ts-ignore
      task = yield fork(item?.fn, { ...location, query }, match);
      break;
    }
  }
}

export default function* navigator() {
  // @ts-ignore
  yield takeEvery(LOCATION_CHANGE, routeMatcher);
}
