import styled from 'styled-components';
import { Row } from 'antd';

export const CollapseStyled = styled(Row)`
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 6px;
  flex: 1;
  margin-bottom: 10px;
  &:first-child {
    margin-top: 15px;
  }
`;

export const HeaderStyled = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 14px 47px 14px 22px;
  &.with-border {
    position: relative;
    padding: 0;
    height: 47px;
    & > button {
      margin-right: 17px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 95%;
      height: 1px;
      bottom: 0;
      left: 3%;
      background-color: ${({ theme }) => theme.colors.grayDark};
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  width: 100%;
`;

export const HeaderContentButtons = styled.div`
  display: flex;
  gap: 15px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  word-break: break-all;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const MainTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  height: 100%;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const RightCol = styled(Row)`
  align-items: center;
  & > svg {
    position: absolute;
    right: 20px;
    top: 43%;
  }
`;

export const TitleInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  word-break: break-all;
  color: ${({ theme }) => theme.colors.lightBlue};
  mix-blend-mode: normal;
`;

export const TaskBody = styled.div`
  padding: 9px 13px 17px 15px;
`;
