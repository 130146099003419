import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Item = styled.div`
  padding: 0 8px;
`;

export const IconWrapper = styled.div`
  margin-right: 9px;
  display: flex;
`;

export const ItemWrapper = styled.div<{ $isActive: boolean }>`
  user-select: none;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin: 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
  color: ${theme.colors.white};
  svg {
    fill: ${theme.colors.white};
  }
  &:hover {
    background-color: ${theme.colors.primary};
    border-radius: 6px;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.primary};
    `}
`;
