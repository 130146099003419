import styled from 'styled-components';

export const CaptionWrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const Invoice = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-right: 10px;
  white-space: nowrap;
`;

export const InfoFrom = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  min-width: 0;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const AccountName = styled.span`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CustomerName = styled.span`
  white-space: nowrap;
  margin-left: 4px;
`;
