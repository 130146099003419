// components
import { Item } from './Item';
import { Error } from 'components/common';
import { Radio, RadioGroupProps } from 'antd';
// styles
import { RadioButtonStyled, RadioGroupStyled } from './styles';

interface IRadioGroupProps extends RadioGroupProps {
  options?: any[];
  name?: string;
  defaultValue?: string;
  error?: string;
  value?: string;
}
export const RadioGroup = ({
  options,
  name,
  error,
  defaultValue,
  ...rest
}: IRadioGroupProps) => (
  <>
    <RadioGroupStyled
      name={name}
      defaultValue={defaultValue}
      buttonStyle="solid"
      {...rest}
    >
      {options.map((option, i) =>
        option.description ? (
          <Item option={option} key={i} />
        ) : (
          <RadioButtonStyled key={option._id}>
            <Radio.Button value={option.value ? option.value : option.type}>
              {option.type}
            </Radio.Button>
          </RadioButtonStyled>
        )
      )}
    </RadioGroupStyled>
    {error && <Error>{error}</Error>}
  </>
);
