import { v4 as uuidv4 } from 'uuid';

export const getOptions = (options, defaultValue: string) => {
  if (!defaultValue) return options;
  const isDefaultValueInOptions = options.some(
    option => option.type === defaultValue
  );
  if (isDefaultValueInOptions) return options;
  const deletedOption = {
    type: defaultValue,
    description: 'This option is deleted',
    _id: uuidv4(),
  };
  return [...options, deletedOption];
};

export const getColors = (colors, defaultColor: string) => {
  if (!defaultColor) return colors;
  const isDefaultColorInColors = colors.some(
    item => item.color === defaultColor
  );
  if (isDefaultColorInColors) return colors;
  const deletedColor = {
    color: defaultColor,
    _id: uuidv4(),
  };
  return [...colors, deletedColor];
};
