import { CSSProperties } from 'react';
import { RequiredSign } from '../RequiredSign';
import { Error, Label } from 'components/common';
import { AutoCompleteProps } from 'antd';
import { AutoCompleteStyled } from './styles';

interface IAutocompleteProps extends AutoCompleteProps {
  label?: string | JSX.Element;
  name?: string;
  value?: string;
  className?: string;
  dropdownClassName?: string;
  error?: string;
  id?: string;
  width?: string;
  height?: string;
  placeholder?: string;
  style?: CSSProperties;
  isRequired?: boolean;
  loading?: boolean;
  selectwidth?: string;
}

export const Autocomplete = (props: IAutocompleteProps) => {
  const { isRequired, ...rest } = props;
  return (
    <div style={{ ...props.style, width: props.width }}>
      {props.label && (
        <Label>
          {props.label}
          {isRequired && <RequiredSign> *</RequiredSign>}
        </Label>
      )}
      <AutoCompleteStyled
        {...rest}
        id={props.id || props.name}
        getPopupContainer={trigger => trigger.parentNode}
      />
      {props.error && <Error>{props.error}</Error>}
    </div>
  );
};
