import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* depotSizesSaga() {
  yield all([
    fork(W.watchGetSizes),
    fork(W.watchAddSize),
    fork(W.watchEditeSize),
    fork(W.watchDeleteSize),
    fork(W.watchGetSizeById),
  ]);
}
