import { SVGProps } from 'react';

function EmptyPersonIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={27}
        height={27}
        rx={13.5}
        stroke="#DFE1E6"
        strokeDasharray="2 2"
      />
      <path
        d="M11 10.332c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm8.333 9.667H20v-.667a4.673 4.673 0 00-4.667-4.667h-2.666A4.672 4.672 0 008 19.332v.667h11.333z"
        fill="#DFE1E6"
      />
      <path
        d="M23 14.166a5.84 5.84 0 00-5.834 5.833 5.84 5.84 0 005.833 5.834 5.84 5.84 0 005.834-5.834 5.84 5.84 0 00-5.834-5.833zm2.916 6.417h-2.333v2.333h-1.167v-2.333h-2.333v-1.167h2.333v-2.333h1.167v2.333h2.333v1.167z"
        fill="#DFE1E6"
      />
    </svg>
  );
}

export default EmptyPersonIcon;
