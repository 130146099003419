import { SplitIcon } from 'lib/icons';
import theme from 'styles/theme';
import { SubtaskStatusesEnum } from 'types';

export const SUBTASK_STATUSES_LIST = Object.values(SubtaskStatusesEnum);

export const SUBTASK_STATUSES_INVERTED = Object.keys(
  SubtaskStatusesEnum
).reduce((obj, key) => {
  obj[SubtaskStatusesEnum[key]] = key;
  return obj;
}, {});

export const SUBTASK_STATUSES_PROPERTIES = {
  TO_DO: {
    value: SubtaskStatusesEnum.TO_DO,
    color: theme.colors.darkYellow2,
    background: theme.colors.whiteYellow,
    icon: undefined,
    border: '',
  },
  PAUSED: {
    value: SubtaskStatusesEnum.PAUSED,
    color: theme.colors.textGreyDark,
    background: theme.colors.textGrey,
    border: `1px solid ${theme.colors.grayDark}`,
    icon: SplitIcon,
  },
  IN_PROGRESS: {
    value: SubtaskStatusesEnum.IN_PROGRESS,
    color: theme.colors.lightDeepBlue,
    background: theme.colors.lightTurquoise,
    icon: undefined,
    border: '',
  },
  ON_REVIEW: {
    value: SubtaskStatusesEnum.ON_REVIEW,
    color: theme.colors.orange,
    background: theme.colors.lightOrange,
    icon: undefined,
    border: '',
  },
  DONE: {
    value: SubtaskStatusesEnum.DONE,
    color: theme.colors.eucalyptus,
    background: theme.colors.lightEucalyptus,
    icon: undefined,
    border: '',
  },
  NOT_READY: {
    value: SubtaskStatusesEnum.NOT_READY,
    color: theme.colors.textGreyDark,
    background: theme.colors.textGrey,
    border: '',
    icon: undefined,
  },
  FAILED: {
    value: SubtaskStatusesEnum.FAILED,
    color: theme.colors.danger,
    background: theme.colors.lightDanger,
    icon: undefined,
    border: '',
  },
};

export const SUBTASK_STATUSES_PROPERTIES_LIST = Object.values(
  SUBTASK_STATUSES_PROPERTIES
);

export const CHANGE_TO_SUBTASK_STATUSES = [
  SUBTASK_STATUSES_PROPERTIES.TO_DO,
  SUBTASK_STATUSES_PROPERTIES.DONE,
  SUBTASK_STATUSES_PROPERTIES.IN_PROGRESS,
  SUBTASK_STATUSES_PROPERTIES.NOT_READY,
  SUBTASK_STATUSES_PROPERTIES.PAUSED,
];

export const CHANGE_TO_SUBTASK_STATUSES_SUBCONTRACTORS = [
  SUBTASK_STATUSES_PROPERTIES.TO_DO,
  SUBTASK_STATUSES_PROPERTIES.DONE,
  SUBTASK_STATUSES_PROPERTIES.IN_PROGRESS,
  SUBTASK_STATUSES_PROPERTIES.NOT_READY,
];
