import * as yup from 'yup';
import { MAX_DUPLICATES_COUNT, MAX_SUBTASKS_COUNT } from 'lib/constants';

const DuplicateSchema = yup.object().shape({
  amount: yup
    .number()
    .required('Amount is required!')
    .positive()
    .when('$count', ($count: number, schema) => {
      const maxCount =
        $count >= MAX_DUPLICATES_COUNT
          ? MAX_SUBTASKS_COUNT - $count
          : MAX_DUPLICATES_COUNT;
      return schema
        .max(maxCount, `The amount can not be more than ${maxCount}`)
        .typeError('Amount is required!');
    }),
});

export default DuplicateSchema;
