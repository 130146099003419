import styled from 'styled-components';
import { Row } from 'antd';

export const HeaderStyled = styled(Row)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 13px;
  .ant-select-selector {
    border-radius: 6px !important;
  }
`;
