import styled from 'styled-components';
import { Spin } from 'antd';

export const SpinItem = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
`;

export const EmployeesField = styled.div`
  margin-bottom: 31px;
`;

export const Wrapper = styled.div`
  & .ant-radio-group {
    display: flex;
    justify-content: space-between;
  }
  & .ant-radio-button-wrapper {
    min-width: 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
  }
  & .ant-radio-button {
    height: 26px;
  }
  & .ant-radio-button-checked span {
    color: white;
  }

  @media (max-width: 420px) {
    & .ant-radio-button-wrapper {
      margin: 0 5px 5px 0;
    }
    & .ant-radio-group {
      justify-content: flex-start;
    }
  }
`;

export const Terms = styled.div`
  margin-bottom: 20px;
`;

export const TermsLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;
