// components
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Modal from 'components/Modal';
import CreateProject from 'pages/Dashboard/Project/CreateProject';
import ProjectView from 'pages/Dashboard/Project/ProjectView';

interface IProjectModalProps {
  closeModal?: () => void;
  isOpenModal?: boolean;
  isOpenProjectViewModal?: boolean;
  isEditModal?: boolean;
  onCloseEditModal?: () => void;
  setCloseModal?: Dispatch<SetStateAction<boolean>>;
  closeDraftModal: () => void;
  openEdit?: (visible: boolean) => void;
  setCloneCount: (amount: number) => void;
  toggleModal?: (visible: boolean) => void;
  toggleProjectViewModal?: Dispatch<SetStateAction<boolean>>;
  files?: any[];
  setFiles?: (files: FormData[]) => void;
  isClone?: boolean;
  cloneCount: number;
}

const ProjectModal = ({
  closeModal,
  isOpenModal,
  isOpenProjectViewModal,
  isEditModal,
  onCloseEditModal,
  setCloseModal,
  openEdit,
  setCloneCount,
  toggleModal,
  toggleProjectViewModal,
  files,
  setFiles,
  isClone,
  cloneCount,
  closeDraftModal,
}: IProjectModalProps) => (
  <Modal
    visible={isOpenModal}
    onCancel={closeModal}
    width={760}
    padding={isOpenProjectViewModal ? '20px' : '20px 47px 33px 47px'}
  >
    {isOpenProjectViewModal ? (
      <ProjectView
        closeModal={isEditModal ? onCloseEditModal : closeModal}
        openEdit={openEdit}
        setCloneCount={setCloneCount}
        toggleParent={toggleModal}
      />
    ) : (
      <CreateProject
        onSubmit={isEditModal ? onCloseEditModal : toggleProjectViewModal}
        files={files}
        setFiles={setFiles}
        setCloseParent={setCloseModal}
        isEdit={isEditModal}
        isClone={isClone}
        cloneCount={cloneCount}
        closeDraftModal={closeDraftModal}
      />
    )}
  </Modal>
);

export default ProjectModal;
