import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from 'store/rootReducer';
import rootSaga from 'sagas';

export default (preloadedState = {}, history) => {
  const devMode = process.env.NODE_ENV === 'development';
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(history);
  const middleware = [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
    routerMiddleware,
  ];

  const store = configureStore({
    reducer,
    devTools: devMode,
    middleware,
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};
