import { call, put, select } from 'redux-saga/effects';
import { conditionsSelector } from 'store/depot';
import { actions } from 'store/loading';
import * as Actions from './actions';
import Api from 'utils/api';
import {
  ConditionDto,
  IEnsureEditConditionPayload,
  ILoaderPayload,
} from 'types';

export function* ensureGetConditions({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof Actions.conditions.TRIGGER;
}) {
  const conditionsList = yield select(conditionsSelector);
  const noLoader = payload?.noLoader || conditionsList?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.conditions.getConditions);
    yield put(Actions.conditions.success(data));
  } catch (err) {
    yield put(Actions.conditions.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetConditionById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getConditionById.TRIGGER;
}) {
  try {
    const data = yield call(Api.conditions.getConditionById, payload);
    yield put(Actions.getConditionById.success(data));
  } catch (err) {
    yield put(Actions.getConditionById.failure(err));
  }
}

export function* ensureAddCondition({
  payload,
}: {
  payload: ConditionDto;
  type: typeof Actions.addCondition.TRIGGER;
}) {
  try {
    const res = yield call(Api.conditions.postCondition, payload);
    yield call(ensureGetConditions, {
      payload: { noLoader: true },
      type: Actions.conditions.TRIGGER,
    });
    yield put(Actions.addCondition.success(res));
  } catch (err) {
    yield put(Actions.addCondition.failure(err));
  }
}

export function* ensureDeleteCondition({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteCondition.TRIGGER;
}) {
  try {
    yield call(Api.conditions.deleteCondition, payload);
    yield call(ensureGetConditions, {
      payload: { noLoader: true },
      type: Actions.conditions.TRIGGER,
    });
    yield put(Actions.deleteCondition.success());
  } catch (err) {
    yield put(Actions.deleteCondition.failure(err));
  }
}

export function* ensureEditCondition({
  payload,
}: {
  payload: IEnsureEditConditionPayload;
  type: typeof Actions.editCondition.TRIGGER;
}) {
  try {
    const data = yield call(Api.conditions.putCondition, payload);
    yield call(ensureGetConditions, {
      payload: { noLoader: true },
      type: Actions.conditions.TRIGGER,
    });
    yield put(Actions.editCondition.success(data));
  } catch (err) {
    yield put(Actions.editCondition.failure(err));
  }
}
