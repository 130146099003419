import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* depotConditionsSaga() {
  yield all([
    fork(W.watchGetConditions),
    fork(W.watchAddCondition),
    fork(W.watchDeleteCondition),
    fork(W.watchEditeCondition),
    fork(W.watchGetConditionById),
  ]);
}
