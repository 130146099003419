import { call, put, select } from 'redux-saga/effects';
import { resourcesSelector, showAllResourcesSelector } from 'store/admin';
import { actions } from 'store/loading';
// redux
import * as Actions from './actions';
// utils
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureAddResourcePayload,
  IEnsureDeleteResourcesPayload,
  IEnsureGetResourcesListPayload,
  IEnsureUpdateResourcePayload,
  IEnsureUploadPhotoPayload,
  ListResourceResponseDto,
} from 'types';

interface IEnsureGetResourcesPayload {
  params?: any;
  noLoader?: boolean;
}
export function* ensureGetResources({
  payload = {},
}: {
  payload?: IEnsureGetResourcesPayload;
  type: typeof Actions.resources.TRIGGER;
}) {
  yield put(Actions.resources.request());
  const resources = yield select(resourcesSelector);
  const locationId = storageManager.getLocation();
  const showAllResources = yield select(showAllResourcesSelector);
  const noLoader = payload?.noLoader || (!payload?.params && resources?.length);
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.resources.resources, {
      params: {
        ...payload.params,
        ...(!showAllResources && { locationId }),
      },
    });
    yield put(Actions.resources.success(data));
  } catch (err) {
    yield put(Actions.resources.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureDeleteResources({
  payload,
}: {
  payload: IEnsureDeleteResourcesPayload;
  type: typeof Actions.deleteResource.TRIGGER;
}) {
  try {
    yield call(Api.resources.deleteResources, payload.id);
    yield call(ensureGetResources, {
      payload: { params: payload.params, noLoader: true },
      type: Actions.resources.TRIGGER,
    });
    yield put(Actions.deleteResource.success());
  } catch (err) {
    yield put(Actions.deleteResource.failure(err));
  }
}

export function* ensureGetResourceById({
  payload,
}: {
  payload?: string;
  type: typeof Actions.getResourceById.TRIGGER;
}) {
  try {
    if (payload) {
      const data = yield call(Api.resources.getResourceById, payload);
      yield put(Actions.getResourceById.success(data));
    } else {
      yield put(Actions.getResourceById.success());
    }
  } catch (err) {
    yield put(Actions.getResourceById.failure(err));
  }
}

export function* ensureUpdateResource({
  payload,
}: {
  payload: IEnsureUpdateResourcePayload;
  type: typeof Actions.updateResource.TRIGGER;
}) {
  try {
    const {
      params,
      data: { id, data, file },
    } = payload;
    if (file) {
      yield call(ensureUploadPhoto, {
        payload: { id, file },
        type: Actions.uploadPhoto.TRIGGER,
      });
    }
    const res = yield call(Api.resources.putResource, { id, data });
    yield call(ensureGetResources, {
      payload: { params, noLoader: true },
      type: Actions.resources.TRIGGER,
    });
    yield put(Actions.updateResource.success(res));
  } catch (err) {
    yield put(Actions.updateResource.failure(err));
  }
}

export function* ensureAddResource({
  payload,
}: {
  payload: IEnsureAddResourcePayload;
  type: typeof Actions.addResource.TRIGGER;
}) {
  try {
    const {
      params,
      data: { data, file },
    } = payload;
    const res = yield call(Api.resources.postResource, data);
    if (file) {
      const id = res._id;
      yield call(ensureUploadPhoto, {
        payload: { id, file },
        type: Actions.uploadPhoto.TRIGGER,
      });
    }
    yield call(ensureGetResources, {
      payload: { params, noLoader: true },
      type: Actions.resources.TRIGGER,
    });
    yield put(Actions.addResource.success(res));
  } catch (err) {
    yield put(Actions.addResource.failure(err));
  }
}

export function* ensureUploadPhoto({
  payload,
}: {
  payload: IEnsureUploadPhotoPayload;
  type: typeof Actions.uploadPhoto.TRIGGER;
}) {
  try {
    yield call(Api.resources.postResourcePhoto, payload);
    yield put(Actions.uploadPhoto.success());
  } catch (err) {
    yield put(Actions.uploadPhoto.failure(err));
  }
}

export function* ensureGetResourcesList({
  payload,
}: {
  payload?: IEnsureGetResourcesListPayload;
  type: typeof Actions.resourcesList.TRIGGER;
}) {
  try {
    const locationId = payload?.locationId || storageManager.getLocation();
    const params = {
      ...payload,
      locationId,
    };
    const data: ListResourceResponseDto[] = yield call(
      Api.resources.resources,
      {
        params,
      }
    );
    const result = data.map(item => ({
      ...item,
      subtaskTypes: item.subtaskTypes?.map(subtaskType => subtaskType._id),
    }));
    yield put(Actions.resourcesList.success(result));
  } catch (err) {
    yield put(Actions.resourcesList.failure(err));
  }
}

export function* ensureMarkAsActiveResource({
  payload,
}: {
  payload: string;
  type: typeof Actions.markAsActiveResource.TRIGGER;
}) {
  try {
    yield call(Api.resources.markAsActiveResource, payload);
    yield put(Actions.markAsActiveResource.success(payload));
  } catch (err) {
    yield put(Actions.markAsActiveResource.failure(err));
  }
}

export function* ensureMarkAsInactiveResource({
  payload,
}: {
  payload: string;
  type: typeof Actions.markAsInactiveResource.TRIGGER;
}) {
  try {
    yield call(Api.resources.markAsInactiveResource, payload);
    yield put(Actions.markAsInactiveResource.success(payload));
  } catch (err) {
    yield put(Actions.markAsInactiveResource.failure(err));
  }
}
