import { SVGProps } from 'react';
import theme from 'styles/theme';

export function DoubleCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill={theme.colors.eucalyptus}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.596 9.161l3.162 2.414 5.077-5.803-1.004-.877-4.256 4.864L2.404 8.1l-.808 1.06zm12.906-3.39l-1.004-.876-4.246 4.852-.502-.401-.834 1.041 1.498 1.199 5.088-5.814z" />
    </svg>
  );
}
