import styled, { css } from 'styled-components';
// constants
import { SIDE_BAR_WIDTH } from '../../constants';
import { Avatar } from 'antd';

export const Wrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
  display: flex;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
  &:last-child {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
  }
`;

export const SideBar = styled.div<{
  isEdit?: boolean;
}>`
  border-right: 1px solid #e5e5e5;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  min-height: 100% !important;
  min-width: ${SIDE_BAR_WIDTH}px;
  padding: ${({ isEdit }) =>
    isEdit ? '9px 17px 8px 11px' : '11px 17px 11px 22px'};
  width: ${SIDE_BAR_WIDTH}px;
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
`;

export const Left = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const RemoveButtonWrap = styled.div`
  margin-left: 5px;
`;

export const Status = styled.div<any>`
  align-items: center;
  border-radius: 4px;
  cursor: default;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 18px;
  justify-content: space-around;
  line-height: 13px;
  margin-right: 5px;
  padding: 1px 8px;
  width: 84px;
  svg {
    margin-right: 3px;
  }
  ${({ statusPreset }) => css`
    background-color: ${statusPreset.background};
    border: ${statusPreset.border};
    color: ${statusPreset.color};
  `}
`;

export const AvatarStyled = styled(Avatar)<{
  hover?: string;
}>`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  img {
    transition: all 0.3s;
    filter: ${props => props.hover === 'true' && 'blur(2px)'};
  }
  svg {
    margin-top: 5px;
  }
`;

export const AvatarWrap = styled.div`
  padding-right: 10px;
`;
