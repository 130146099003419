import { useEffect, useState } from 'react';
import { putProjectPromiseCreator } from 'store/dashboard';
// components
import StatusSelect from './StatusSelect';
import { useActionsRoutines } from 'hooks';
import { message } from 'utils/message';
// constants
import { IProjectResponseDto, ProjectStatusesEnum } from 'types';

interface ISetStatusProps {
  data: IProjectResponseDto;
  status?: string;
  handleVisibleChange: (value: boolean) => void;
}

const SetStatus = ({ status, data, handleVisibleChange }: ISetStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newStatus, setNewStatus] = useState<ProjectStatusesEnum | null>(null);

  const editProject = useActionsRoutines(putProjectPromiseCreator);

  const onStatusChange = (value: ProjectStatusesEnum) => {
    setNewStatus(value);
  };

  const updateStatus = async () => {
    setIsLoading(true);
    try {
      await editProject({
        id: data._id,
        data: {
          ...data,
          status: newStatus,
        },
      });
    } catch {
      error => message.error(error);
    } finally {
      setIsLoading(false);
      handleVisibleChange(false);
      message.success(`Successfully   updated to '${newStatus}'`);
    }
  };

  useEffect(() => {
    if (newStatus !== null) {
      updateStatus();
    }
  }, [newStatus]);

  return (
    <StatusSelect
      onChange={onStatusChange}
      status={status}
      isLoading={isLoading}
    />
  );
};

export default SetStatus;
