import { useCallback, useMemo, useState } from 'react';
import { downloadPdfPromiseCreator } from 'store/sharing';
// components
import PreviewAttachment from 'components/PreviewAttachment';
import { useActionsRoutines } from 'hooks';
import { getFilePreviewUrl } from 'lib/constants';
// icons
import { FileIcon } from 'lib/icons';
import { getProjectFilePreviewUrl } from 'utils/images';
// styles
import { FileName, Preview, PreviewWrap, Wrap } from './styles';
import { FileUploadResDto } from 'types';

interface IItemProps {
  file: FileUploadResDto;
}

const Item = ({ file }: IItemProps): JSX.Element => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const { filePath: href } = file;

  const closePreview = e => {
    e.stopPropagation();
    setIsPreviewOpen(false);
  };

  const onWrapClick = useCallback(
    () => setIsPreviewOpen(true),
    [setIsPreviewOpen]
  );
  const previewUrl = useMemo(
    () => getFilePreviewUrl(file, getProjectFilePreviewUrl),
    [file]
  );

  const handleDownloadPdf = useCallback(() => {
    if (href) {
      downloadPdf({ data: href, originalName: file.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file.originalName, downloadPdf]);

  return (
    <Wrap>
      <PreviewAttachment
        showPreview={isPreviewOpen}
        closePreview={e => closePreview(e)}
        file={file}
      />
      <PreviewWrap onClick={onWrapClick}>
        {previewUrl ? <Preview src={previewUrl} /> : <FileIcon />}
      </PreviewWrap>
      <div>
        <FileName
          onClick={handleDownloadPdf}
          style={{ cursor: href ? 'pointer' : 'default' }}
        >
          {file.originalName}
        </FileName>
      </div>
    </Wrap>
  );
};

export default Item;
