import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { ChecklistForm } from 'components/ChecklistForm';
import { BUTTON_TYPES, Button } from 'components/common';
import CheckCircleIcon from 'lib/icons/CheckCircleIcon';
import { Popover } from 'antd';
import { ButtonStyled } from './styles';
import { LoadingOutlinedStyled } from 'components/SubtaskActions/styles';
import { IChecklist } from 'types';

interface IChecklistButtonProps {
  updateChecklist: Function;
  checklist: Array<IChecklist>;
  isRecurring: boolean;
  id: string;
  projectId: string;
  inAdmin?: boolean;
  isProjectDeleted?: boolean;
}

const ChecklistButton = ({
  updateChecklist,
  checklist,
  isRecurring,
  projectId,
  id,
  inAdmin,
  isProjectDeleted = false,
}: IChecklistButtonProps): JSX.Element => {
  const [isChecklistPopoverVisible, setChecklistPopoverVisible] =
    useState(false);
  const [isChecklistLoading, setIsChecklistLoading] = useState(false);

  const countChecklistNotFinishedItems = useMemo(() => {
    if (!checklist) return '';
    const result = checklist.filter(
      checklistItem => !checklistItem.isCompleted
    ).length;
    return result === 0 ? '' : String(result);
  }, [checklist]);

  const handleUpdateChecklist = useCallback(
    (subtaskId, newChecklist) => {
      setIsChecklistLoading(true);
      updateChecklist(subtaskId, newChecklist).finally(() => {
        setIsChecklistLoading(false);
      });
    },
    [updateChecklist]
  );

  const handleCloseChecklistPopover = useCallback(
    () => setChecklistPopoverVisible(false),
    []
  );
  const getPopupContainer = useCallback(triggerNode => triggerNode, []);
  const handleChecklistVisibleChange = useCallback(
    visible => setChecklistPopoverVisible(visible),
    []
  );

  const checklistPopover = useCallback(
    () => (
      <ChecklistForm
        handleCloseChecklist={handleCloseChecklistPopover}
        onFinishEditing={handleUpdateChecklist}
        items={checklist}
        subtaskId={id}
        disableCheckbox={isRecurring || inAdmin}
        projectId={projectId}
        isProjectDeleted={isProjectDeleted}
      />
    ),
    [
      id,
      isRecurring,
      checklist,
      inAdmin,
      projectId,
      handleCloseChecklistPopover,
      handleUpdateChecklist,
      isProjectDeleted,
    ]
  );

  return (
    <div>
      {!isChecklistLoading && (
        <Popover
          visible={isChecklistPopoverVisible}
          onVisibleChange={handleChecklistVisibleChange}
          content={checklistPopover}
          destroyTooltipOnHide
          getPopupContainer={getPopupContainer}
          trigger="click"
          placement="topRight"
        >
          <span>
            <ButtonStyled
              $countChecklistNotFinishedItems={countChecklistNotFinishedItems}
              onClick={null}
              kind={BUTTON_TYPES.PRIMARY}
              icon={<CheckCircleIcon />}
              label={countChecklistNotFinishedItems}
              height="28px"
              lineheight="0px"
              space="0px"
              padding="0 5px"
            />
          </span>
        </Popover>
      )}
      {isChecklistLoading && (
        <Button
          onClick={null}
          kind={BUTTON_TYPES.PRIMARY}
          icon={
            <LoadingOutlinedStyled
              spin
              style={{ fontSize: '14px', width: '21px' }}
            />
          }
          width="36px"
          height="28px"
          lineheight="0px"
          space="0px"
          padding="0px"
        />
      )}
    </div>
  );
};

export default memo(ChecklistButton, isEqual);
