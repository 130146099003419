import { Controller } from 'react-hook-form';
// helpers
import { getColors, getOptions } from './helpers';
// components
import Colors from './Colors';
import { RadioGroup } from 'components/common';
// styles
import { Info, InfoItem, InfoItemTitle } from './styles';
import { ContainerOptionsDto, HookFormRecord, IProjectTaskDto } from 'types';

interface ContainerOptionsDtoProps {
  errors?: any;
  control?: any;
  name?: string;
  containerOptions: ContainerOptionsDto;
  item: IProjectTaskDto;
}

const ContainerOptions = ({
  errors,
  control,
  item,
  name = '',
  containerOptions,
}: ContainerOptionsDtoProps) => {
  const { colors, grades, sizes, conditions } = containerOptions;
  const conditionsDefaultValue = item?.container?.condition;
  const sizesDefaultValue = item?.container?.size;
  const gradesDefaultValue = item?.container?.grade;
  const colorsDefaultValue = item?.container?.color;

  return (
    <Info>
      {conditions?.length > 0 && (
        <InfoItem>
          <InfoItemTitle>
            Condition <span style={{ color: 'red' }}>*</span>
          </InfoItemTitle>
          <Controller
            name={`${name}condition` as HookFormRecord}
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={field.value}
                name={field.name}
                options={getOptions(conditions, conditionsDefaultValue)}
                onChange={event => field.onChange(event.target.value)}
                error={errors?.condition?.message}
              />
            )}
            defaultValue={conditionsDefaultValue}
          />
        </InfoItem>
      )}
      {sizes?.length > 0 && (
        <InfoItem>
          <InfoItemTitle>
            Size <span style={{ color: 'red' }}>*</span>
          </InfoItemTitle>
          <Controller
            name={`${name}size` as HookFormRecord}
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={field.value}
                name={field.name}
                options={getOptions(sizes, sizesDefaultValue)}
                onChange={event => field.onChange(event.target.value)}
                error={errors?.size?.message}
              />
            )}
            defaultValue={sizesDefaultValue}
          />
        </InfoItem>
      )}
      {grades?.length > 0 && (
        <InfoItem>
          <InfoItemTitle>
            Grade <span style={{ color: 'red' }}>*</span>
          </InfoItemTitle>
          <Controller
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={field.value}
                name={field.name}
                options={getOptions(grades, gradesDefaultValue)}
                onChange={event => field.onChange(event.target.value)}
                error={errors?.grade?.message}
              />
            )}
            name={`${name}grade` as HookFormRecord}
            defaultValue={gradesDefaultValue}
          />
        </InfoItem>
      )}
      {colors?.length > 0 && (
        <InfoItem>
          <InfoItemTitle>
            Color <span style={{ color: 'red' }}>*</span>
          </InfoItemTitle>
          <Controller
            control={control}
            render={({ field }) => (
              <Colors
                value={field.value}
                colors={getColors(colors, colorsDefaultValue)}
                onChange={value => field.onChange(value)}
                error={errors?.color?.message}
              />
            )}
            name={`${name}color` as HookFormRecord}
            defaultValue={colorsDefaultValue}
          />
        </InfoItem>
      )}
    </Info>
  );
};

export default ContainerOptions;
