import styled from 'styled-components';
import { Button } from 'components/common';

export const ButtonStyled = styled(Button)<{
  $countChecklistNotFinishedItems: string;
}>`
  min-width: 36px;
  width: auto;
  & > svg {
    display: flex;
    margin-right: ${({ $countChecklistNotFinishedItems }) =>
      $countChecklistNotFinishedItems ? '5px' : '0'};
  }
`;
