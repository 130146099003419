import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { userSelector } from 'store/auth';
import { companySelector } from 'store/company';
import { isTabLoadingSelector } from 'store/loading';
// redux
import { routes } from 'store/router/routes';
// components
import { Loader } from 'components/common';
// utils
import { buildRoutes } from 'utils/buildRoutes';
// styles
import { Wrap } from './styles';
// constants
import { USER_MAIN_ROLES } from 'types';

const Main = () => {
  const tabIsLoading = useSelector(isTabLoadingSelector);
  const user = useSelector(userSelector);
  const company = useSelector(companySelector);
  const getUserRoutesType = () => {
    if (user?.roles.length > 1) return USER_MAIN_ROLES.PROD_AND_DEPOT_MANAGER;
    return user?.mainRole;
  };

  return (
    <Suspense
      fallback={
        <Wrap>
          <Loader loading />
        </Wrap>
      }
    >
      <Switch>
        {buildRoutes(
          routes[getUserRoutesType()](tabIsLoading),
          company?.isContainersEnabled
        )}
        <Redirect to={'/dashboard'} />
      </Switch>
    </Suspense>
  );
};

export default Main;
