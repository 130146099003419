import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { sendMessageReplyPromiseCreator } from 'store/dashboard';
import {
  InputWrapper,
  Line,
  Loader,
  ReplyInput,
  ReplyWrapper,
  SendIconWrap,
} from './style';
import { useOnClickOutside } from 'hooks';
import { useRequest } from 'hooks/useRequest';
import { Send } from 'lib/icons/Send';
import theme from 'styles/theme';
import { message } from 'utils/message';
import { Spin } from 'antd';

interface IReplyProps {
  setIsShowReply: Dispatch<SetStateAction<boolean>>;
  subtaskId: string;
  replyToMessageId: string;
}

const Reply = ({
  setIsShowReply,
  subtaskId,
  replyToMessageId,
}: IReplyProps): JSX.Element => {
  const [sendReply, isReplySending] = useRequest(
    sendMessageReplyPromiseCreator
  );
  const [text, setText] = useState('');

  const handleClickOutside = () => {
    const selectedText = window.getSelection()?.toString();
    if (!selectedText) {
      setIsShowReply(false);
    }
  };
  const ref = useOnClickOutside(handleClickOutside);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChangeText = useCallback(e => setText(e?.target?.value), []);

  const handleSendReply = useCallback(async () => {
    try {
      if (text.length === 0) return;
      if (text.trim().length === 0) {
        setText('');
        inputRef.current?.focus();
        return;
      }
      await sendReply({ body: text, replyToMessageId, subtaskId });
      setIsShowReply(false);
      await message.success('Reply sent!');
    } catch (error) {
      console.error(error);
    }
  }, [replyToMessageId, sendReply, setIsShowReply, subtaskId, text]);

  const handleEnterClick = useCallback(
    async e => {
      if (!isReplySending && e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        await handleSendReply();
      }
    },
    [isReplySending, handleSendReply]
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <ReplyWrapper>
      <Line />
      <InputWrapper ref={ref}>
        <ReplyInput
          value={text}
          ref={inputRef}
          onChange={handleChangeText}
          placeholder="Type your reply here..."
          onKeyDown={handleEnterClick}
          autoSize={{ maxRows: 5 }}
        />
        <SendIconWrap isActive={!!text}>
          {!isReplySending ? (
            <Send
              onClick={handleSendReply}
              color={text ? theme.colors.mainDarkBlue : theme.colors.lightBlue}
              size={15}
            />
          ) : (
            <Spin indicator={<Loader spin />} />
          )}
        </SendIconWrap>
      </InputWrapper>
    </ReplyWrapper>
  );
};

export default memo(Reply, isEqual);
