import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/timesheets';
import * as E from './workers';

export function* watchTimesheets() {
  yield call(E.ensureTimesheets);
  yield takeLatest(Actions.timesheets.TRIGGER, E.ensureTimesheets);
}

export function* watchResourceTimesheets() {
  yield takeLatest(
    Actions.resourceTimesheets.TRIGGER,
    E.ensureResourceTimesheets
  );
}

export function* watchEditResourceTime() {
  yield takeLatest(Actions.editResourceTime.TRIGGER, E.ensureEditResourceTime);
}

export function* watchDeleteShift() {
  yield takeLatest(Actions.deleteShift.TRIGGER, E.ensureDeleteShift);
}

export function* watchGetShiftClosestDates() {
  yield takeLatest(
    Actions.getShiftClosestDates.TRIGGER,
    E.ensureGetShiftClosestDates
  );
}

export function* watchGetTimesheetsExcel() {
  yield takeLatest(
    Actions.getTimesheetsExcel.TRIGGER,
    E.ensureGetTimesheetsExcel
  );
}

export function* watchGetResourceTimesheetsExcel() {
  yield takeLatest(
    Actions.getResourceTimesheetsExcel.TRIGGER,
    E.ensureGetResourceTimesheetsExcel
  );
}
