import { memo, useCallback, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import {
  systemNotificationsPageSelector,
  systemNotificationsPagesCountSelector,
} from 'store/sharing';
import { NotificationItem } from '../NotificationItem';
import Empty from 'components/Empty/EmptyComponent';
import moment from 'moment';
import { Spin } from 'antd';
import { EmptyWrapper, NotificationDate, SpinWrapper } from './styles';
import { LoadingOutlinedStyled } from 'components/common/SelectInput/styles';
import { ISystemNotifications } from 'types';

interface INotificationListProps {
  setVisible: (visible: boolean) => void;
  onLocationChange: (value: string) => void;
  markSystemNotificationsRead: (id: string) => Promise<void>;
  notifications: ISystemNotifications[];
  getSystemNotifications: () => Promise<void>;
}

const TODAY = moment();
const YESTERDAY = moment().subtract(1, 'days');

const NotificationList = ({
  setVisible,
  onLocationChange,
  markSystemNotificationsRead,
  notifications,
  getSystemNotifications,
}: INotificationListProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const page = useSelector(systemNotificationsPageSelector);
  const pagesCount = useSelector(systemNotificationsPagesCountSelector);
  const scrollRef = useRef();

  const handleGetNotifications = useCallback(() => {
    setIsLoading(true);

    getSystemNotifications()
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }, [getSystemNotifications]);

  const groupedNotifications = useMemo(
    () =>
      notifications.reduce((groupedByDate, notification) => {
        const date = moment(notification.createdAt).format('MM/DD/YYYY');
        if (!groupedByDate[date]) groupedByDate[date] = [];
        groupedByDate[date].push(notification);
        return groupedByDate;
      }, {}),
    [notifications]
  );
  const groupedNotificationsKeys = Object.keys(groupedNotifications);

  return (
    <Scrollbar
      noScrollX
      ref={scrollRef}
      style={{
        height: '465px',
      }}
    >
      {!!groupedNotificationsKeys.length ? (
        groupedNotificationsKeys.map((key, i) => {
          const notificationsList = groupedNotifications[key];
          return (
            <div key={key}>
              <NotificationDate>
                {moment(key).isSame(TODAY, 'day')
                  ? 'Today'
                  : moment(key).isSame(YESTERDAY, 'day')
                  ? 'Yesterday'
                  : key}
              </NotificationDate>

              {notificationsList?.map?.((item, index) => (
                <NotificationItem
                  key={item?._id}
                  showWaypoint={
                    Object.keys(groupedNotifications).length === i + 1 &&
                    notificationsList.length - 1 === index &&
                    page < pagesCount
                  }
                  getSystemNotifications={handleGetNotifications}
                  setVisible={setVisible}
                  item={item}
                  onLocationChange={onLocationChange}
                  markSystemNotificationsRead={markSystemNotificationsRead}
                />
              ))}
            </div>
          );
        })
      ) : (
        <EmptyWrapper>
          <Empty description="You've read all your notifications." />
        </EmptyWrapper>
      )}
      <SpinWrapper>
        <Spin indicator={<LoadingOutlinedStyled />} spinning={isLoading} />
      </SpinWrapper>
    </Scrollbar>
  );
};

export default memo(NotificationList, isEqual);
