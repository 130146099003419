import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { EmailTemplateContainer } from './EmailTemplateContainer';
import Modal from 'components/Modal';
import { IEmailTemplate } from 'types';

interface IEmailTemplateModal {
  item: IEmailTemplate;
  isOpen: boolean;
  onCloseModal: ({
    id,
    title,
    body,
  }?: {
    id?: string;
    title?: string;
    body?: string;
  }) => void;
  isTemplateModal?: boolean;
}
const EmailTemplateModalComponent = ({
  item,
  isOpen,
  onCloseModal,
  isTemplateModal,
}: IEmailTemplateModal) => {
  const handleCloseModal = useCallback(() => onCloseModal(), [onCloseModal]);

  return (
    <Modal
      visible={isOpen}
      width="865px"
      onCancel={handleCloseModal}
      padding="10px 24px 24px 24px"
    >
      {isOpen && (
        <EmailTemplateContainer
          item={item}
          isOpen={isOpen}
          onCloseModal={onCloseModal}
          isTemplateModal={isTemplateModal}
        />
      )}
    </Modal>
  );
};

export const EmailTemplateModal = memo(EmailTemplateModalComponent, isEqual);
