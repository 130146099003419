import { call, put, select } from 'redux-saga/effects';
import { sizesSelector } from 'store/depot';
import { actions } from 'store/loading';
// redux
import { addSize, deleteSize, editSize, getSizeById, sizes } from './actions';
// utils
import Api from 'utils/api';
import { IEnsureEditSizePayload, ILoaderPayload, SizeDto } from 'types';

export function* ensureGetSizes({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof sizes.TRIGGER;
}) {
  const sizesList = yield select(sizesSelector);
  const noLoader = payload?.noLoader || sizesList?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.sizes.getSizes);
    yield put(sizes.success(data));
  } catch (err) {
    yield put(sizes.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetSizeById({
  payload,
}: {
  payload: string;
  type: typeof getSizeById.TRIGGER;
}) {
  try {
    const data = yield call(Api.sizes.getSizeById, payload);
    yield put(getSizeById.success(data));
  } catch (err) {
    yield put(getSizeById.failure(err));
  }
}

export function* ensureAddSize({
  payload,
}: {
  payload: SizeDto;
  type: typeof addSize.TRIGGER;
}) {
  try {
    const res = yield call(Api.sizes.postSize, payload);
    yield call(ensureGetSizes, {
      payload: { noLoader: true },
      type: sizes.TRIGGER,
    });
    yield put(addSize.success(res));
  } catch (err) {
    yield put(addSize.failure(err));
  }
}

export function* ensureDeleteSize({
  payload,
}: {
  payload: string;
  type: typeof deleteSize.TRIGGER;
}) {
  try {
    yield call(Api.sizes.deleteSize, payload);
    yield call(ensureGetSizes, {
      payload: { noLoader: true },
      type: sizes.TRIGGER,
    });
    yield put(deleteSize.success());
  } catch (err) {
    yield put(deleteSize.failure(err));
  }
}

export function* ensureEditSize({
  payload,
}: {
  payload: IEnsureEditSizePayload;
  type: typeof editSize.TRIGGER;
}) {
  try {
    const data = yield call(Api.sizes.putSize, payload);
    yield call(ensureGetSizes, {
      payload: { noLoader: true },
      type: sizes.TRIGGER,
    });
    yield put(editSize.success(data));
  } catch (err) {
    yield put(editSize.failure(err));
  }
}
