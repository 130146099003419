import * as React from 'react';

function MailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.666.333H2.333C1.414.333.667 1.081.667 2v10c0 .919.747 1.666 1.666 1.666h13.334c.919 0 1.666-.747 1.666-1.666V2c0-.92-.747-1.667-1.666-1.667zm0 1.667v.426L9 7.61 2.333 2.426V2h13.334zM2.333 12V4.536l6.155 4.788a.828.828 0 001.024 0l6.154-4.788.002 7.464H2.333z"
        fill="#F96302"
      />
    </svg>
  );
}

export default MailIcon;
