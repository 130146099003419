import { IContainer, IProjectTaskDto, ITaskDto, TaskTypeEnum } from 'types';

export const getRequestData = (formData, isContainer: boolean) => {
  if (!isContainer) {
    return formData;
  }
  return {
    ...formData,
    container: {
      condition: formData.condition,
      size: formData.size,
      grade: formData.grade,
      color: formData.color,
      num: formData.num || '',
      zone: formData.zone || '',
    },
  };
};

export const getType = (
  data: IContainer | ITaskDto,
  isContainer: boolean,
  isUpdate: boolean
) => {
  if (isUpdate) return data?.type;
  return isContainer ? TaskTypeEnum.Container : TaskTypeEnum.Common;
};

export const getSubtasks = (
  isUpdate: boolean,
  addData,
  requestData: IProjectTaskDto
) => {
  if (isUpdate) return requestData?.subtasks || [];
  const addSubtasksData = addData?.subtasks?.map(item => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, ...subtaskToAdd } = item;

    return subtaskToAdd;
  });
  return addSubtasksData || [];
};

export const getModalTitle = (isCloneModal: boolean, isEdit: boolean) => {
  if (isCloneModal) {
    return 'Clone item';
  } else {
    return isEdit ? 'Edit item' : 'Create item';
  }
};
