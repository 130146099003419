import { Link } from 'react-router-dom';
import { IconWrapper, Item, ItemWrapper } from './styles';
import { ISideBarItem } from 'types';

interface IMenuItemProps {
  item: ISideBarItem;
  selectedKeys: string;
}

const MenuItem = ({ item, selectedKeys }: IMenuItemProps): JSX.Element => {
  const { key, label, Icon } = item;
  const isItemActive = selectedKeys === key;
  return (
    <Item>
      {isItemActive ? (
        <ItemWrapper $isActive={isItemActive}>
          {Icon && <IconWrapper>{Icon}</IconWrapper>}
          {label}
        </ItemWrapper>
      ) : (
        <Link to={key}>
          <ItemWrapper $isActive={isItemActive}>
            {Icon && <IconWrapper>{Icon}</IconWrapper>}
            {label}
          </ItemWrapper>
        </Link>
      )}
    </Item>
  );
};

export default MenuItem;
