import { createGlobalStyle } from 'styled-components';
import LeftArrow from '../lib/svg/left.svg';
import RightArrow from '../lib/svg/right.svg';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
  }
  
  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
  }
  
  p {
    margin:  0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.mainDarkBlue};    
  }
  
  #root {
    margin: 0 auto;
    min-height: 400px;
    height: 100%;
    overflow: auto;

    @media(min-width: 1365px) {
      &::-webkit-scrollbar {
        display: none;
      }
   }
  }
  
  .ant-message {
    top: auto;
    bottom: 16px;
  }
  
  .ant-message-notice > .ant-message-notice-content {
    background: ${({ theme }) => theme.colors.mainDarkBlue};
    box-shadow: 0 10px 23px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
  
  .ant-message-custom-content {
    min-height: 31px;
    min-width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.white};
    & > svg {
      margin-right: 16px;
    }
  }

  .ant-picker-ok {
    button {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      color: ${({ theme }) => theme.colors.white} !important;
      border: none;
      box-shadow: none;
    }
  }
  .ant-picker-now-btn {
    color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }

  .emailTemplateEditor {
    height: 152px!important;
    border: 1px solid #F1F1F1;
    padding: 10px;
  }

  .ChatMenuPopover .ant-popover-inner-content {
    padding:  0;
  }

  .NotificationsPopover {
    .ant-popover-inner{
        min-width:420px;
      }
    .ant-popover-inner-content {
      padding: 12px 26px;
     
    }
  }
  .ant-input-number .anticon svg,  .ant-select .anticon svg  {
    margin-right: 0;
  }

  .full-height {
    height: 100%;
  }
  
  .avatar{
    border-radius: 6px;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    text-shadow: none;

  }
  .ant-tabs .ant-tabs-tab{
    color:  ${({ theme }) => theme.colors.lightBlue};
    padding: 12px 50px;
    font-weight: 500;
  }
  .ant-tabs-nav-wrap .ant-tabs-tab:hover {
    color:  ${({ theme }) => theme.colors.mainDarkBlue};
  }
  .ant-tabs-ink-bar {
    border-bottom: 3px solid  ${({ theme }) => theme.colors.primary} ;
  }
  /* DayPicker styles */

.DayPicker {
  display: inline-block;
  background: ${theme.colors.white};
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 14px 21px 20px 22px;
}

.DayPicker:focus,
.DayPicker-wrapper:focus,
.DayPicker-WeekNumber:focus,
.DayPicker-Day:focus,
.DayPicker-NavButton:focus,
.DayPicker-Weekday:focus {
  outline: none;
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #172b4d;
}

.DayPicker-NavButton {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  background-image: url(${LeftArrow});
  margin-right: 33px;
}

.DayPicker-NavButton--next {
  background-image: url(${RightArrow});
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  line-height: 22px;
  text-align: left;
  margin-bottom: 16px;
}

.DayPicker-Weekdays {
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding-bottom: 8px;
  text-align: center;
  font-size: 9px;
  line-height: 11px;
  color: ${theme.colors.lightBlue};
  cursor: pointer;
  min-width: 26px;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

abbr[title],
abbr[data-original-title] {
  cursor: pointer;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: ${theme.colors.mainDarkBlue};
  border-radius: 50%;
  height: 26px;
  box-sizing: border-box;
}

.DayPicker-WeekNumber {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  background: ${theme.colors.grayDark};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.mainDarkBlue};
  min-width: 19px;
}

.DayPicker-WeekNumber:hover {
  font-size: 11px;
  font-weight: 700;
}

.DayPicker-Week:first-child > .DayPicker-WeekNumber {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.DayPicker-Week:last-child > .DayPicker-WeekNumber {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.mainDarkBlue};
  cursor: pointer;
}

.DayPicker-TodayButton:focus {
  outline: none;
}

/* Default modifiers */

.DayPicker-Day--today {
  background: transparent;
  font-weight: 900;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: ${theme.colors.primary};
}

.DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  font-weight: 700;
  color: ${theme.colors.fullBlack};
  font-size: 11px;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: ${theme.colors.white};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

`;

export default GlobalStyle;
