import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { StatusCheckbox } from '../StatusCheckbox';
import { v4 as uuidv4 } from 'uuid';
import { BlockItemName, BlockWrap } from '../styles';

const statusesList = ['statusComplete', 'statusInProgress', 'statusNotStarted'];

const TaskBlockComponent = ({
  task,
  form,
  template,
  templatesList,
  recipientsList,
}) => {
  const { register, setValue, unregister } = form;
  const [statuses, setStatuses] = useState({
    statusComplete: false,
    statusInProgress: false,
    statusNotStarted: false,
    all: false,
  });

  const mappedTemplate = useMemo(() => {
    if (template) {
      const statusComplete = template?.statusComplete
        ? {
            ...template?.statusComplete,
            template: { ...template?.statusComplete?.template, _id: uuidv4() },
          }
        : undefined;
      const statusInProgress = template?.statusInProgress
        ? {
            ...template?.statusInProgress,
            template: {
              ...template?.statusInProgress?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const statusNotStarted = template?.statusNotStarted
        ? {
            ...template?.statusNotStarted,
            template: {
              ...template?.statusNotStarted?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const all = template?.all
        ? {
            ...template?.all,
            template: { ...template?.all?.template, _id: uuidv4() },
          }
        : undefined;

      return {
        statusComplete,
        statusInProgress,
        statusNotStarted,
        all,
      };
    } else {
      return undefined;
    }
  }, [template]);

  useEffect(() => {
    if (mappedTemplate) {
      const statusComplete = mappedTemplate.statusComplete;
      const statusInProgress = mappedTemplate.statusInProgress;
      const statusNotStarted = mappedTemplate.statusNotStarted;
      const all = mappedTemplate.all;

      if (statusComplete) {
        register(`statusComplete`);
        register(`statusComplete.template`);
        register(`statusComplete.template.originalId`);
        register(`statusComplete.template.title`);
        register(`statusComplete.template.body`);
        register(`statusComplete.recipients`);
        setValue(`statusComplete`, statusComplete);
      }

      if (statusInProgress) {
        register(`statusInProgress`);
        register(`statusInProgress.template.originalId`);
        register(`statusInProgress.template.title`);
        register(`statusInProgress.template.body`);
        register(`statusInProgress.recipients`);
        setValue(`statusInProgress`, statusInProgress);
      }

      if (statusNotStarted) {
        register(`statusNotStarted`);
        register(`statusNotStarted.template`);
        register(`statusNotStarted.template.originalId`);
        register(`statusNotStarted.template.title`);
        register(`statusNotStarted.template.body`);
        register(`statusNotStarted.recipients`);
        setValue(`statusNotStarted`, statusNotStarted);
      }

      if (all) {
        register(`all`);
        register(`all.template`);
        register(`all.template.originalId`);
        register(`all.template.title`);
        register(`all.template.body`);
        register(`all.recipients`);
        setValue(`all`, all);
      }

      setStatuses({
        statusComplete: !!statusComplete,
        statusInProgress: !!statusInProgress,
        statusNotStarted: !!statusNotStarted,
        all: !!all,
      });
    }
  }, [mappedTemplate, register, setValue, unregister]);

  const handleCheck = useCallback(
    (name, checkboxStatus) => {
      const statusArr = name.split('.');
      const statusName = statusArr[statusArr.length - 1];

      if (checkboxStatus) {
        register(`${name}.template`);
        register(`${name}.template.originalId`);
        register(`${name}.template.title`);
        register(`${name}.template.body`);
        register(`${name}.recipients`);

        const isAll = statusName.toLocaleLowerCase().includes('all');

        if (isAll) {
          statusesList.forEach(status => {
            unregister(`${status}.template`);
            unregister(`${status}.template.originalId`);
            unregister(`${status}.template.title`);
            unregister(`${status}.template.body`);
            unregister(`${status}.recipients`);
            unregister(status);
          });

          setStatuses({
            statusComplete: false,
            statusInProgress: false,
            statusNotStarted: false,
            all: checkboxStatus,
          });

          return checkboxStatus;
        } else if (!isAll && statuses.all) {
          unregister('all.template');
          unregister('all.template.originalId');
          unregister('all.template.title');
          unregister('all.template.body');
          unregister('all.recipients');
          unregister('all');

          setStatuses(statuses => ({
            ...statuses,
            [statusName]: checkboxStatus,
            all: false,
          }));
          return checkboxStatus;
        }
      } else {
        unregister(`${name}.template`);
        unregister(`${name}.template.originalId`);
        unregister(`${name}.template.title`);
        unregister(`${name}.template.body`);
        unregister(`${name}.recipients`);
        unregister(name);
      }

      setStatuses(statuses => ({ ...statuses, [statusName]: checkboxStatus }));
      return checkboxStatus;
    },
    [statuses.all, register, unregister]
  );

  return (
    <BlockWrap>
      <BlockItemName>
        #{task?.order} {task?.name}
      </BlockItemName>
      <StatusCheckbox
        name="statusComplete"
        label="When the item status is Complete"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusComplete}
        onChange={handleCheck}
      />
      <StatusCheckbox
        name="statusInProgress"
        label="When the item status is In progress"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusInProgress}
        onChange={handleCheck}
      />
      <StatusCheckbox
        name="statusNotStarted"
        label="When the item status is Not started"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusNotStarted}
        onChange={handleCheck}
      />
      <StatusCheckbox
        name="all"
        label="All"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.all}
        onChange={handleCheck}
      />
    </BlockWrap>
  );
};

export const TaskBlock = memo(TaskBlockComponent, isEqual);
