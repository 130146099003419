import { Dispatch, Fragment, SetStateAction, useState } from 'react';
// helpers
import { getChangeToStatuses } from '../helpers';
// icons
import { Spin } from 'antd';
// styles
import {
  LoaderWrap,
  LoadingOutlinedStyled,
  Status,
  Title,
  Wrap,
} from './styles';
import { SubtaskStatusesEnum } from 'types';

interface IStatusSelectProps {
  status: string;
  onChange: (
    value: string,
    setIsLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
}
const StatusSelect = ({ status, onChange }: IStatusSelectProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const statusItems = getChangeToStatuses(status);

  return (
    <Wrap onMouseDown={e => e.preventDefault()}>
      <Title>Change status to:</Title>
      {!isLoading ? (
        statusItems.map(item => {
          const ItemIcon = item.icon;
          return (
            <Fragment key={item.value}>
              <Status
                color={item.color}
                background={item.background}
                border={item.border}
                onClick={() => onChange(item.value, setIsLoading)}
              >
                {item.icon && <ItemIcon />}

                {item.value === SubtaskStatusesEnum.PAUSED
                  ? 'Split'
                  : item.value}
              </Status>
            </Fragment>
          );
        })
      ) : (
        <LoaderWrap>
          <Spin indicator={<LoadingOutlinedStyled spin />} />
        </LoaderWrap>
      )}
    </Wrap>
  );
};

export default StatusSelect;
