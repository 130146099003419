import { IProjectResponseDto, ProjectViewMode } from 'types';

const AUTH_HEADERS = ['Authorization', 'currentLocation', 'x-api-key'];

class StorageManager {
  setProjectViewMode(mode: ProjectViewMode) {
    localStorage.setItem('projectViewMode', mode);
  }
  getProjectViewMode() {
    return localStorage.getItem('projectViewMode');
  }
  setToken(jwt: string) {
    localStorage.setItem('Authorization', `Bearer ${jwt}`);
  }

  getToken() {
    return localStorage.getItem('Authorization');
  }

  setApiKey(jwt: string) {
    localStorage.setItem('x-api-key', `${jwt}`);
  }
  removeApiKey() {
    delete localStorage['x-api-key'];
  }

  setLocation(location: string) {
    localStorage.setItem('currentLocation', location);
  }

  getLocation() {
    return localStorage.getItem('currentLocation');
  }

  setTimezone(timezone: string) {
    localStorage.setItem('currentTimezone', timezone);
  }

  getTimezone() {
    return localStorage.getItem('currentTimezone');
  }

  setProjects(projects: IProjectResponseDto[]) {
    localStorage.setItem('projects', JSON.stringify(projects));
  }

  getProjects() {
    return localStorage.getItem('projects');
  }

  setAuthData(data) {
    if (data) {
      AUTH_HEADERS.forEach(key => {
        localStorage[key] = data[key];
      });
    }
  }

  getAuthData() {
    const data = {};
    const signupToken = localStorage.Signup;

    if (signupToken) {
      return {
        Authorization: signupToken,
      };
    } else {
      AUTH_HEADERS.forEach(key => {
        data[key] = localStorage[key];
      });
    }
    return data;
  }

  clearAuthData() {
    [...AUTH_HEADERS].forEach(key => delete localStorage[key]);
  }
}

export default new StorageManager();
