import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PasswordRequirements from '../../../../../components/Auth/PasswordRequirements/PasswordRequirements';
import { Input } from 'components/common';
import { InputPassword } from 'components/common/InputPassword/InputPassword';

interface IEmailPasswordBlockProps {
  isGoogleSignUp?: boolean;
  email: string | string[];
  isUserInvited: boolean;
}

const EmailPasswordBlock = ({
  isGoogleSignUp,
  email,
  isUserInvited,
}: IEmailPasswordBlockProps): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <>
      <Controller
        render={({ field }) => (
          <Input
            disabled={!!email || isUserInvited}
            type="email"
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="Email"
            isRequired
            width="100%"
            height="30px"
            style={{ paddingBottom: '15px' }}
            placeholder="Type your email"
            error={errors?.email?.message}
          />
        )}
        name="email"
        control={control}
      />
      {!isGoogleSignUp && (
        <>
          <Controller
            render={({ field }) => (
              <InputPassword
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Password"
                isRequired
                width="100%"
                height="30px"
                style={{ paddingBottom: '15px' }}
                placeholder="Type your password"
                error={errors?.password?.message}
              />
            )}
            name="password"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <InputPassword
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Confirm password"
                isRequired
                autocomplete={false}
                width="100%"
                height="30px"
                style={{ paddingBottom: '15px' }}
                placeholder="Confirm password"
                error={errors?.verifyPassword?.message}
              />
            )}
            name="verifyPassword"
            control={control}
          />
          <PasswordRequirements />
        </>
      )}
    </>
  );
};

export default memo(EmailPasswordBlock);
