import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetGrades() {
  yield call(E.ensureGetGrades, {
    type: Actions.grades.TRIGGER,
  });
  yield takeLatest(Actions.grades.TRIGGER, E.ensureGetGrades);
}

export function* watchAddGrade() {
  yield takeLatest(Actions.addGrade.TRIGGER, E.ensureAddGrade);
}

export function* watchGetGradeById() {
  yield takeLatest(Actions.getGradeById.TRIGGER, E.ensureGetGradeById);
}

export function* watchEditeGrade() {
  yield takeLatest(Actions.editGrade.TRIGGER, E.ensureEditGrade);
}

export function* watchDeleteGrade() {
  yield takeLatest(Actions.deleteGrade.TRIGGER, E.ensureDeleteGrade);
}
