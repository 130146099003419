import styled from 'styled-components';
// components
import { Select } from 'antd';

export const SortBySelectWrap = styled.div`
  display: flex;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
`;

export const Prefix = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export const SelectStyled = styled(Select)<{
  withprefix?: string;
  width?: string;
}>`
  width: ${({ withprefix, width }) =>
    withprefix === 'true' ? '200px' : width || '130px'};

  display: flex;
  align-items: center;
  justify-content: space-between;
  &&& .ant-select-selector {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    padding-left: ${({ withprefix }) => withprefix === 'true' && '95px'};
    margin-top: 1px;
  }
  &&& .ant-select-selection-item,
  &&& .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    opacity: 1;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin: auto 0;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  &&& .ant-select-selection-search {
    display: flex;
    align-items: center;
    & > input {
      height: 100%;
    }
  }
`;

export const StyledMenu = styled.div`
  div {
    &::-webkit-scrollbar {
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.grayDark};
      border-radius: 23px;
      width: 357px;
    }
  }
  padding: 11px 0;
  border-radius: 4px;
  .ant-select-item {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    padding: 10px;
  }
  .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const IconWrap = styled.div`
  margin-right: 8px;
  margin-left: 8px;
`;
