import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Waypoint } from 'react-waypoint';
import { Error, Label } from 'components/common';
import { SelectProps, Spin } from 'antd';
// styles
import {
  Create,
  Loading,
  LoadingOutlinedStyled,
  NoResult,
  SelectStyled,
  SelectWraper,
  StyledMenu,
} from './styles';

const { Option } = SelectStyled;

export interface ISelectInputProps extends SelectProps {
  placeholder?: string;
  name?: string;
  options?: any[];
  label?: string | Element;
  width?: string;
  height?: string;
  isAllowCreate?: boolean;
  disabled?: boolean;
  onCreate?: (searchValue: string) => void;
  isReturnLabel?: boolean;
  isCreateLoading?: boolean;
  withWaypoint?: boolean;
  isRequired?: boolean;
  loading?: boolean;
  fetchMore?: (index: number) => void;
  limit?: number;
  onSelectMenuClick?: () => void;
  error?: any;
  value?: number | string;
  showSearch?: boolean;
  defaultValue?: any;
  isMultiple?: boolean;
  createType?: string;
  maxTagCount?: number | 'responsive';
  createValidation?: (searchValue: string) => void;
}

const SelectInputComponent = ({
  options,
  label,
  isAllowCreate,
  onCreate,
  isCreateLoading,
  error,
  isReturnLabel,
  value,
  withWaypoint,
  fetchMore,
  onSearch,
  limit,
  loading,
  onSelectMenuClick,
  isRequired,
  isMultiple,
  createType,
  getPopupContainer,
  createValidation,
  ...rest
}: ISelectInputProps) => {
  const val = useMemo(
    () => (value || value === 0 ? value : undefined),
    [value]
  );
  const [searchValue, setSearchValue] = useState('');

  const filterOptions = useCallback(
    (input, option) =>
      option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    []
  );

  const handleGetPopupContainer = useCallback(
    trigger => trigger.parentNode,
    []
  );
  const dropDawnRender = useCallback(
    menu => (
      <StyledMenu onClick={onSelectMenuClick}>
        {loading ? (
          <Loading>
            <Spin indicator={<LoadingOutlinedStyled spin />} />
          </Loading>
        ) : (
          <>{menu}</>
        )}
      </StyledMenu>
    ),
    [onSelectMenuClick, loading]
  );

  const filterOption = useMemo(
    () => (onSearch ? false : filterOptions),
    [onSearch, filterOptions]
  );

  const NotFoundContent = useMemo(() => {
    let isCreateValid;
    let createError;
    if (isAllowCreate) {
      isCreateValid =
        !createValidation || (searchValue && createValidation(searchValue));
      createError = !isCreateValid ? `${createType} is invalid` : '';
    }
    return (
      <>
        <NoResult>There no result</NoResult>
        {isAllowCreate && !!searchValue.length && (
          <Create
            isValid={isCreateValid}
            onClick={() => onCreate(searchValue)}
          >
            {isCreateLoading && (
              <Spin indicator={<LoadingOutlinedStyled spin />} />
            )}
            {!isCreateLoading &&
              (createError ? (
                createError
              ) : (
                <>
                  +{' '}
                  <span>
                    Create "{searchValue}" {createType ? createType : 'task'}
                  </span>
                </>
              ))}
          </Create>
        )}
      </>
    );
  }, [
    isAllowCreate,
    onCreate,
    searchValue,
    createType,
    isCreateLoading,
    createValidation,
  ]);

  return (
    <SelectWraper>
      {label && (
        <div>
          <Label>
            {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : ''}
          </Label>
        </div>
      )}
      <SelectStyled
        {...rest}
        value={val}
        error={error}
        optionFilterProp="label"
        filterOption={filterOption}
        notFoundContent={NotFoundContent}
        defaultActiveFirstOption={false}
        getPopupContainer={getPopupContainer || handleGetPopupContainer}
        onSearch={(searchText: string) => {
          setSearchValue(searchText);
          if (onSearch) onSearch(searchText);
        }}
        dropdownRender={dropDawnRender}
        mode={isMultiple ? 'multiple' : ''}
      >
        {options.map((option, index, array) => (
          <Option
            key={option.value}
            value={isReturnLabel ? option.label : option.value}
            label={option.label}
          >
            {option.label}
            {withWaypoint &&
              array.length + 1 > limit &&
              index === array.length - 30 && (
                // @ts-ignore
                <Waypoint onEnter={() => fetchMore(index)} />
              )}
          </Option>
        ))}
      </SelectStyled>
      {error && <Error>{error}</Error>}
    </SelectWraper>
  );
};

SelectInputComponent.defaultProps = {
  placeholder: 'Search',
  isAllowCreate: false,
  isReturnLabel: false,
  isCreateLoading: false,
};

export const SelectInput = memo(SelectInputComponent, isEqual);
