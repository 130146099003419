import { SVGProps } from 'react';

export function ReportsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10411_7637)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2 4H12v1.6H7.2V4zm0 3.2H12v1.6H7.2V7.2zm0 3.2H12V12H7.2v-1.6zM4 4h1.6v1.6H4V4zm0 3.2h1.6v1.6H4V7.2zm0 3.2h1.6V12H4v-1.6zM14.48.8H1.52c-.4 0-.72.32-.72.72v12.96c0 .32.32.72.72.72h12.96c.32 0 .72-.4.72-.72V1.52c0-.4-.4-.72-.72-.72zm-.88 12.8H2.4V2.4h11.2v11.2z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10411_7637">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
