import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const HoursContainer = styled.div`
  position: relative;
`;

export const HoursContent = styled.div`
  display: 'flex';
  flex-direction: column;
`;

export const HoursItem = styled.div`
  display: flex;
  padding-bottom: 4px;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const Title = styled.div`
  color: ${theme.colors.mainDarkBlue};
  margin-right: 13px;
`;

export const Value = styled.div`
  color: #737f94;
`;

export const HoursWrap = styled.div<{
  isMobile?: boolean;
  $isReassignment?: boolean;
}>`
  height: 28px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  min-width: ${({ isMobile }) => (isMobile ? '46px' : '91px')};
  cursor: pointer;
  justify-content: space-between;
  background: ${theme.colors.grayLight};
  border-radius: 6px;
  padding: 0 9px 0 11px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  span {
    margin-left: 6px;
    margin-right: 9px;
  }
  ${({ $isReassignment }) =>
    $isReassignment &&
    css`
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.grayDark};
    `}
`;

export const ClockWrap = styled.div`
  display: inline-flex;
  width: 12px;
  height: 12px;
`;

export const SelectValue = styled.span<{ isMobile?: boolean }>`
  display: inline-flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const DoubleArrowWrap = styled.div<{ isOpen?: boolean }>`
  display: inline-flex;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
  svg {
    scale: 0.8;
  }
`;

export const Time = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;
