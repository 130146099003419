import { useCallback, useEffect, useState } from 'react';
import { setHighlightedSubtaskAction } from 'store/gantt';
// components
import SubtaskItem from './SubtaskItem';
import { TaskShortInfo } from 'components/TaskShortInfo';
import ReadMore from 'components/common/ReadMore/ReadMore';
import { useActions } from 'hooks';
import update from 'immutability-helper';
// utils
import { message } from 'utils/message';
import { List } from 'antd';
import { Content, Description, ItemInfo, Subtasks } from './styles';
import {
  IChecklist,
  IProjectResponseDto,
  IProjectTaskDto,
  ISubtaskDto,
} from 'types';

interface IBodyBlockProps {
  item?: IProjectTaskDto;
  index: number;
  setIsOpenEdit: () => void;
  color?: string;
  invoice?: string;
  isOpenInfo: boolean;
  toggleParent?: (visible: boolean) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  setShouldUpdate: (update: boolean) => void;
  locationId?: string;
  project: IProjectResponseDto;
  editProject: ({
    id,
    data,
    skipContainerUniqueness,
  }: {
    id: string;
    data: IProjectResponseDto;
    skipContainerUniqueness: boolean;
  }) => Promise<void>;
  notAvailableProject?: boolean;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  isProjectViewTaskMode?: boolean;
  isProjectEditing?: boolean;
}

const BodyBlock = ({
  item,
  color,
  invoice,
  openCheckResult,
  toggleParent,
  isOpenInfo,
  setShouldUpdate,
  locationId,
  editProject,
  project,
  notAvailableProject,
  updateChecklist,
  isProjectViewTaskMode,
  isProjectEditing,
}: IBodyBlockProps) => {
  const {
    name,
    sku,
    cost,
    price,
    description,
    quantity,
    subtasks,
    order,
    _id,
  } = item;

  const [subtasksList, setSubtasksList] = useState(subtasks);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const setHighlightedSubtask = useActions(setHighlightedSubtaskAction);

  useEffect(() => {
    setSubtasksList(subtasks);
  }, [subtasks]);

  const onDoubleClick = (_id, startDate, dueDate) => {
    if (notAvailableProject) return;
    setHighlightedSubtask({ _id, startDate, dueDate });
    toggleParent(false);
  };

  const saveSubtasksOrder = useCallback(() => {
    if (!isOrderChanged) return;
    const newTasks = project.tasks.map(item => {
      if (item._id === _id) {
        return { ...item, subtasks: subtasksList };
      }
      return item;
    });

    const newData = {
      ...project,
      tasks: newTasks,
    };
    editProject({
      id: project._id,
      data: newData as IProjectResponseDto,
      skipContainerUniqueness: true,
    })
      .then(() => message.success())
      .catch(err => {
        console.error(err);
        setSubtasksList(subtasks);
      })
      .finally(() => setIsOrderChanged(false));
  }, [subtasksList, isOrderChanged, subtasks, _id, project, editProject]);

  const moveSubtasks = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSubtask = subtasksList[dragIndex];
      const newSubtasks = update(subtasksList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSubtask],
        ],
      });
      setSubtasksList(
        newSubtasks.map((item, index: number) => ({
          ...item,
          order: index + 1,
        }))
      );
      setIsOrderChanged(dragIndex !== hoverIndex);
    },
    [subtasksList]
  );

  const handleClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  if (!isOpenInfo) return null;

  const showInfo = cost || price || sku;

  return (
    <Content onClick={handleClick}>
      {!isProjectViewTaskMode && (
        <ItemInfo>
          {showInfo && (
            <TaskShortInfo
              isOpenInfo={isOpenInfo}
              sku={sku}
              cost={cost}
              price={price}
              quantity={quantity}
            />
          )}
          {description && (
            <Description>
              <ReadMore limit={200} text={description} />
            </Description>
          )}
        </ItemInfo>
      )}

      <Subtasks>
        {subtasksList.length > 0 && (
          <List
            dataSource={subtasksList}
            rowKey={(item: ISubtaskDto) => item._id}
            renderItem={(item: ISubtaskDto, index) => (
              <SubtaskItem
                isProjectViewTaskMode={isProjectViewTaskMode}
                data={item}
                index={index}
                toggleParent={toggleParent}
                color={color}
                invoice={invoice}
                taskName={name}
                taskOrder={order}
                taskId={_id}
                onDoubleClick={onDoubleClick}
                moveSubtasks={moveSubtasks}
                projectId={project._id}
                openCheckResult={openCheckResult}
                project={project}
                setShouldUpdate={setShouldUpdate}
                locationId={locationId}
                saveSubtasksOrder={saveSubtasksOrder}
                notAvailableProject={notAvailableProject}
                updateChecklist={updateChecklist}
                isProjectEditing={isProjectEditing}
              />
            )}
          />
        )}
      </Subtasks>
    </Content>
  );
};

export default BodyBlock;
