import * as yup from 'yup';

const publicLinkSchema = yup.object().shape({
  to: yup
    .string()
    .required('Email is required!')
    .email('Invalid email address'),
  subject: yup
    .string()
    .trim()
    .required('Email notes is required!')
    .max(300, 'Notes must be at most 300 characters'),
});

export default publicLinkSchema;
