import { SVGProps } from 'react';

export function CompanyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={15}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4 3.2h-3.2V1.6L9.6 0H6.4L4.8 1.6v1.6H1.6C.72 3.2 0 3.92 0 4.8v4c0 .6.32 1.104.8 1.384V12.8c0 .888.712 1.6 1.6 1.6h11.2c.888 0 1.6-.712 1.6-1.6v-2.624c.472-.28.8-.792.8-1.376v-4c0-.88-.72-1.6-1.6-1.6zm-8-1.6h3.2v1.6H6.4V1.6zM1.6 4.8h12.8v4h-4V6.4H5.6v2.4h-4v-4zm7.2 4.8H7.2V8h1.6v1.6zm4.8 3.2H2.4v-2.4h3.2v.8h4.8v-.8h3.2v2.4z"
      />
    </svg>
  );
}
