import * as yup from 'yup';

const taskNotificationSchema = yup.object().shape({
  taskId: yup.string().trim(),
  statusComplete: yup
    .object()
    .shape({
      template: yup.object().shape({
        body: yup.string().trim().required('Template is required!'),
      }),
      recipients: yup
        .array()
        .min(1, 'At least one recipient is required!')
        .required('Recipients are required!'),
    })
    .default(undefined),
  statusInProgress: yup
    .object()
    .shape({
      template: yup.object().shape({
        body: yup.string().trim().required('Template is required!'),
      }),
      recipients: yup
        .array()
        .min(1, 'At least one recipient is required!')
        .required('Recipients are required!'),
    })
    .default(undefined),
  statusNotStarted: yup
    .object()
    .shape({
      template: yup.object().shape({
        body: yup.string().trim().required('Template is required!'),
      }),
      recipients: yup
        .array()
        .min(1, 'At least one recipient is required!')
        .required('Recipients are required!'),
    })
    .default(undefined),
  all: yup
    .object()
    .shape({
      template: yup.object().shape({
        body: yup.string().trim().required('Template is required!'),
      }),
      recipients: yup
        .array()
        .min(1, 'At least one recipient is required!')
        .required('Recipients are required!'),
    })
    .default(undefined),
  subtasks: yup.array().of(
    yup.object().shape({
      statusDone: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      statusFailed: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      statusOnReview: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      statusInProgress: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      statusToDo: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      resourceWasAssigned: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      statuallsDone: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
      all: yup
        .object()
        .shape({
          template: yup.object().shape({
            body: yup.string().trim().required('Template is required!'),
          }),
          recipients: yup
            .array()
            .min(1, 'At least one recipient is required!')
            .required('Recipients are required!'),
        })
        .default(undefined),
    })
  ),
});

export default taskNotificationSchema;
