import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 40px;
`;

export const ContainerLables = styled.div`
  margin-left: 264px;
  display: flex;
`;

export const ContainerLablesItem = styled.div`
  width: 193.5px;
  min-width: 193.5px;
  padding: 5px 21px;
`;

export const TableTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 8px;
`;
