import { MouseEvent, MutableRefObject, useCallback, useState } from 'react';
import { PopoverContent } from './PopoverContent';
// components
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

interface IPopover {
  text?: string;
  onOk?: any;
  loading?: boolean;
  triggerParent?: MutableRefObject<any>;
  onVisibleChange?: (visible: boolean) => void;
  showPopover?: boolean;
}

export const ConfirmationPopover = ({
  children,
  text,
  onOk,
  onVisibleChange,
  loading,
  triggerParent,
  placement,
  showPopover = true,
}: PopoverProps & IPopover) => {
  const [isVisible, setIsVisible] = useState(false);

  const onCancel = useCallback(
    e => {
      e.stopPropagation();
      setIsVisible(false);
      if (onVisibleChange) {
        onVisibleChange(false);
      }
    },
    [onVisibleChange, setIsVisible]
  );

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (typeof onOk === 'object' && typeof onOk?.then === 'function') {
      onOk(e).then(() => setIsVisible(false));
    } else {
      onOk(e);
    }
  };

  const handleVisibleChange = useCallback(
    visible => {
      setIsVisible(visible);
      if (onVisibleChange) {
        onVisibleChange(visible);
      }
    },
    [setIsVisible, onVisibleChange]
  );

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      setIsVisible(true);
    },
    [setIsVisible]
  );

  const getPopup = useCallback(
    trigger => (triggerParent ? triggerParent.current : trigger.parentNode),
    [triggerParent]
  );

  const handlePressEnter = e => {
    if (e.key === 'Enter' && isVisible) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Popover
      getPopupContainer={getPopup}
      placement={placement}
      destroyTooltipOnHide
      content={
        <PopoverContent
          loading={loading}
          text={text}
          onOk={handleSubmit}
          onCancel={onCancel}
        />
      }
      // @ts-ignore
      onClick={onClick}
      visible={isVisible && showPopover}
      onVisibleChange={handleVisibleChange}
      trigger="click"
    >
      <div onKeyDown={handlePressEnter}>{children}</div>
    </Popover>
  );
};

ConfirmationPopover.defaultProps = {
  placement: 'rightTop',
};
