import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get profit per invoice
export const profitPerInvoice = createRoutine('reports/profitPerInvoice');
export const profitPerInvoicePromiseCreator =
  promisifyRoutine(profitPerInvoice);

// get profit per employees
export const profitPerEmployees = createRoutine('reports/profitPerEmployees');
export const profitPerEmployeesPromiseCreator =
  promisifyRoutine(profitPerEmployees);
// get profit per employees
export const profitPerResource = createRoutine('reports/profitPerResource');
export const profitPerResourcePromiseCreator =
  promisifyRoutine(profitPerResource);
// dates
export const setReportsDates = createRoutine('reports/setReportsDates');
// search
export const setReportsSearch = createRoutine('reports/setReportsSearch');
// invoice search
export const setInvoiceSearch = createRoutine('reports/setInvoiceSearch');

// reports CRUD
export const getReportsList = createRoutine('reports/getReportList');
export const getReportsListPromiseCreator = promisifyRoutine(getReportsList);

export const deleteReport = createRoutine('reports/deleteReport');
export const deleteReportPromiseCreator = promisifyRoutine(deleteReport);

export const createReport = createRoutine('reports/postReport');
export const createReportPromiseCreator = promisifyRoutine(createReport);

export const updateReport = createRoutine('reports/updateReport');
export const updateReportPromiseCreator = promisifyRoutine(updateReport);
