import { Redirect, Route, Switch } from 'react-router-dom';

export function buildRoutes(routes, isDepotSettingsEnabled) {
  return routes.map((route, index) => {
    if (route.path === '/settings/depot' && !isDepotSettingsEnabled) {
      return null;
    }

    if (route.routes) {
      const routesSwitch = (
        <Switch>
          {buildRoutes(route.routes, isDepotSettingsEnabled)}
          {route.redirect && <Redirect to={route.redirect} />}
        </Switch>
      );
      return (
        <Route path={route.path} key={index} exact={route.exact}>
          {route.wrapper ? route.wrapper(routesSwitch) : routesSwitch}
        </Route>
      );
    }
    return (
      <Route
        key={index}
        component={route.component}
        exact={route.exact}
        path={route.path}
      />
    );
  });
}
