import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get tasks
export const tasks = createRoutine('admin/tasks');
export const getTaskById = createRoutine('admin/getTaskById');
export const setTask = createAction('admin/setTask');
export const getTaskByIdPromiseCreator = promisifyRoutine(getTaskById);
export const getRelatedTasks = createRoutine('admin/getRelatedTasks');
export const setRelatedTasks = createAction('admin/setRelatedTasks');
export const getRelatedTasksPromiseCreator = promisifyRoutine(getRelatedTasks);
// add tasks
export const addTasks = createRoutine('admin/addTasks');
export const addTasksPromiseCreator = promisifyRoutine(addTasks);
// update tasks
export const updateTask = createRoutine('admin/updateTask');
export const updateTaskPromiseCreator = promisifyRoutine(updateTask);
// partial update tasks
export const patchTask = createRoutine('admin/patchTask');
export const patchTaskPromiseCreator = promisifyRoutine(patchTask);
// delete task
export const deleteTask = createRoutine('admin/deleteTask');
export const deleteTaskPromiseCreator = promisifyRoutine(deleteTask);
// get container options
export const containerOptions = createRoutine('admin/containerOptions');
export const containerOptionsPromiseCreator =
  promisifyRoutine(containerOptions);
// autocomplete
export const autocomplete = createRoutine('admin/autocomplete');
export const autocompletePromiseCreator = promisifyRoutine(autocomplete);
// default container template
export const defaultContainerTemplate = createRoutine(
  'admin/defaultContainerTemplate'
);
// export tasks csv report
export const exportTasksCsv = createRoutine('admin/exportTasksCsv');
export const exportTasksCsvPromiseCreator = promisifyRoutine(exportTasksCsv);
export const exportSampleCsv = createRoutine('admin/exportSampleCsv');
export const exportSampleCsvPromiseCreator = promisifyRoutine(exportSampleCsv);

// import tasks csv
export const importTasksCsv = createRoutine('admin/importTasksCsv');
export const importTasksCsvPromiseCreator = promisifyRoutine(importTasksCsv);

// import errors
export const clearCsvImportErrors = createRoutine('admin/clearCsvImportErrors');
export const clearCsvInvalidRowNumbersErrors = createRoutine(
  'admin/clearCsvInvalidRowNumbersErrors'
);
