import styled from 'styled-components';
// components
import { Button } from 'components/common';

export const Wrap = styled.div`
  width: 320px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    margin-right: 8px;
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(props => <Button {...props} />)`
  width: 70px !important;
  margin: 0 !important;
  margin-right: 8px !important;
  height: 40px !important;
`;

export const OkButton = styled(props => <Button {...props} />)`
  width: 88px !important;
  margin: 0 !important;
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  height: 40px !important;
`;
