import { SVGProps } from 'react';
import theme from 'styles/theme';

interface ISplitIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const SplitIcon = ({
  color = theme.colors.textGreyDark,
  size = 13,
  ...props
}: ISplitIconProps) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.333.666l1.526 1.527-1.92 1.92.947.946 1.92-1.92 1.527 1.527v-4h-4zm-2.667 0h-4v4l1.527-1.527 3.14 3.134v5.06h1.333V5.726L3.139 2.193 4.666.666z" />
  </svg>
);
