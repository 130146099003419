import styled from 'styled-components';
import theme from 'styles/theme';
import { Progress } from 'antd';

export const ProgressItem = styled(Progress)`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-progress-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${theme.colors.lightBlue};
  }
  .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
  .ant-progress-text {
    width: auto;
  }
`;
