import { call, put, select } from 'redux-saga/effects';
import * as Actions from 'store/sharing';
import { isSalesManagerSelector } from '../auth';
import { ensureProjects, projects } from '../dashboard';
import {
  isShowOnlyUnreadNotificationMessagesSelector,
  systemNotificationsObjectSelector,
  systemNotificationsPageSelector,
} from './selectors';
import Api from 'utils/api';
import {
  EmailDto,
  IEnsureDownloadPdfPayload,
  IEnsureGetSystemNotificationsPayload,
} from 'types';

const NOTIFICATION_LIMIT = 50;

export function* ensureSyncNotifications() {
  try {
    const onlyUnread = yield select(
      isShowOnlyUnreadNotificationMessagesSelector
    );

    const notificationsObj = yield select(systemNotificationsObjectSelector);
    const sizeLimit = Math.ceil(
      notificationsObj.data.length / NOTIFICATION_LIMIT
    );
    const params = {
      page: 1,
      limit:
        notificationsObj.data.length <= NOTIFICATION_LIMIT
          ? NOTIFICATION_LIMIT
          : sizeLimit * NOTIFICATION_LIMIT,
      ...(onlyUnread && { onlyUnread }),
    };
    const data = yield call(Api.systemNotifications.get, { params });

    yield put(Actions.syncNotifications.success(data));
  } catch (err) {
    yield put(Actions.syncNotifications.failure(err));
  }
}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

export function* ensureGetToken({
  payload,
}: {
  payload: string;
  type: typeof Actions.getToken.TRIGGER;
}) {
  try {
    const isSales = yield select(isSalesManagerSelector);
    const data = yield call(Api.sharingProject.getToken, payload);
    if (isSales) {
      yield call(ensureProjects, {
        payload: {
          refetch: true,
          noLoader: true,
        },
        type: projects.TRIGGER,
      });
    }
    yield put(Actions.getToken.success(data));
  } catch (err) {
    yield put(Actions.getToken.failure(err));
  }
}

export function* ensureGetProjectByToken({
  payload,
}: {
  payload: string;
  type: typeof Actions.getProjectByToken.TRIGGER;
}) {
  try {
    const data = yield call(Api.sharingProject.getGardByToken, payload);
    yield put(Actions.getProjectByToken.success(data));
  } catch (err) {
    yield put(Actions.getProjectByToken.failure(err));
  }
}

export function* ensureDeleteToken({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteToken.TRIGGER;
}) {
  try {
    const isSales = yield select(isSalesManagerSelector);
    const data = yield call(Api.sharingProject.deleteToken, payload);
    if (isSales) {
      yield call(ensureProjects, {
        payload: {
          refetch: true,
          noLoader: true,
        },
        type: projects.TRIGGER,
      });
    }
    yield put(Actions.deleteToken.success(data));
  } catch (err) {
    yield put(Actions.deleteToken.failure(err));
  }
}

export function* ensureSendLink({
  payload,
}: {
  payload: EmailDto;
  type: typeof Actions.sendLink.TRIGGER;
}) {
  try {
    const data = yield call(Api.sharingProject.sendLink, payload);
    yield put(Actions.sendLink.success(data));
  } catch (err) {
    yield put(Actions.sendLink.failure(err));
  }
}

export function* ensureGetSystemNotifications({
  payload,
}: {
  payload?: IEnsureGetSystemNotificationsPayload;
  type: typeof Actions.getSystemNotifications.TRIGGER;
}) {
  try {
    const page = yield select(systemNotificationsPageSelector);
    const notificationsObj = yield select(systemNotificationsObjectSelector);
    const params = {
      page: payload?.isRefetch ? 1 : Number(page) + 1,
      limit: NOTIFICATION_LIMIT,
      ...(payload?.onlyUnread && { onlyUnread: payload.onlyUnread }),
    };
    const data = yield call(Api.systemNotifications.get, { params });
    let mappedData = {};

    if (payload?.isRefetch) {
      mappedData = data;
    } else {
      mappedData = {
        ...data,
        data: [...notificationsObj.data, ...data.data],
      };
    }

    yield put(Actions.getSystemNotifications.success(mappedData));
  } catch (err) {
    yield put(Actions.getSystemNotifications.failure(err));
  }
}

export function* ensureMarkSystemNotificationAsRead({
  payload,
}: {
  payload: string;
  type: typeof Actions.markSystemNotificationsRead.TRIGGER;
}) {
  try {
    const data = yield call(Api.systemNotifications.markAsRead, payload);
    yield put(
      Actions.markSystemNotificationsRead.success({
        count: data.count,
        id: payload,
      })
    );

    const onlyUnread = yield select(
      isShowOnlyUnreadNotificationMessagesSelector
    );
    if (onlyUnread) {
      yield call(ensureSyncNotifications);
    }
  } catch (err) {
    yield put(Actions.markSystemNotificationsRead.failure(err));
  }
}

export function* ensureMarkSystemNotificationAsUnread({
  payload,
}: {
  payload: string;
  type: typeof Actions.markSystemNotificationsUnread.TRIGGER;
}) {
  try {
    const data = yield call(Api.systemNotifications.markAsUnread, payload);
    yield put(
      Actions.markSystemNotificationsUnread.success({
        count: data.count,
        id: payload,
      })
    );
    const onlyUnread = yield select(
      isShowOnlyUnreadNotificationMessagesSelector
    );
    if (onlyUnread) {
      yield call(ensureSyncNotifications);
    }
  } catch (err) {
    yield put(Actions.markSystemNotificationsUnread.failure(err));
  }
}

export function* ensureMarkAllSystemNotificationAsRead() {
  try {
    yield call(Api.systemNotifications.markAllAsRead);
    yield put(Actions.markAllSystemNotificationsRead.success());
  } catch (err) {
    yield put(Actions.markAllSystemNotificationsRead.failure(err));
  }
}

export function* ensureGetSystemNotificationsUnreadCount() {
  try {
    const data = yield call(Api.systemNotifications.unreadCount);
    yield put(
      Actions.getSystemNotificationsUnreadCount.success(data?.count || 0)
    );
  } catch (err) {
    yield put(Actions.getSystemNotificationsUnreadCount.failure(err));
  }
}

function saveFile(blob: Blob, filename: string) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

export function* ensureDownloadPdf({
  payload,
}: {
  payload: IEnsureDownloadPdfPayload;
  type: typeof Actions.downloadPdf.TRIGGER;
}) {
  try {
    const data = yield call(Api.files.download, payload.data);
    saveFile(data, payload.originalName);

    yield put(Actions.downloadPdf.success(data));
  } catch (err) {
    yield put(Actions.downloadPdf.failure(err));
  }
}

export function* ensureDeleteSystemNotification({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteSystemNotification.TRIGGER;
}) {
  try {
    const data = yield call(Api.systemNotifications.delete, payload);
    yield put(Actions.deleteSystemNotification.success(data.count));
    yield call(ensureSyncNotifications);
  } catch (err) {
    yield put(Actions.deleteSystemNotification.failure(err));
  }
}
