interface IWarningIcon {
  color?: string;
}

export const WarningIcon = ({ color = '#FF3939' }: IWarningIcon) => (
  <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.856 16.589c-.445-.949-.98-2.156-1.604-3.278-.623-1.207-1.247-2.329-1.782-3.364-1.07-1.897-2.762-4.312-4.01-6.037-.445-.604-1.158-1.035-1.96-1.035S9.985 3.22 9.54 3.91a103.226 103.226 0 00-4.01 6.037c-1.159 1.984-2.496 4.744-3.387 6.642-.356.69-.267 1.552.09 2.156.445.69 1.158 1.035 1.96 1.121 2.139.087 5.08.259 7.307.259 2.228 0 5.257-.172 7.307-.259.802 0 1.515-.431 1.96-1.121.357-.604.446-1.466.09-2.156zm-10.07-6.555c0-.345.268-.69.714-.69.445 0 .713.258.713.69v3.45c0 .345-.268.69-.713.69-.446 0-.713-.259-.713-.69v-3.45zm.714 6.9c-.446 0-.891-.431-.891-.863 0-.431.445-.862.89-.862.446 0 .892.431.892.862s-.446.863-.891.863z"
      fill={color}
    />
  </svg>
);
