import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const PhoneWrapper = styled.div<{
  $isDirty?: boolean;
  $error: string;
  $width: string;
  $height: string;
  $disabled: boolean;
}>`
  && .react-tel-input {
    font-family: 'Inter', sans-serif;
    border-radius: 6px;
    border: 1px solid ${theme.colors.mutedInputBorderColor};

    ${({ $isDirty }) =>
      $isDirty && `border:1px solid  ${theme.colors.mainDarkBlue}`};

    ${({ $error }) =>
      $error
        ? css`
            border: 1px solid ${theme.colors.red};
          `
        : css`
            &:hover {
              border: 1px solid ${theme.colors.mainDarkBlue};
            }
          `}

    & .form-control {
      border: 1px solid transparent;
      height: ${({ $height }) => ($height ? `${$height}` : '30px')};
      width: ${({ $width }) => ($width ? `${$width}` : '100%')};
      font-size: 12px;
      border-radius: 6px;

      &.invalid-number {
        background-color: inherit;
      }
    }

    & .flag-dropdown {
      border-left: transparent;
      border-top: transparent;
      border-bottom: transparent;
      border-radius: 6px 0 0 6px;
      background-color: ${theme.colors.grayLight};
      &.invalid-number {
        border-color: transparent;
      }
      .selected-flag {
        border: 1px solid #d9d9d9;
        border-left: transparent;
        border-top: transparent;
        border-bottom: transparent;
        border-radius: 6px 0 0 6px;
      }
    }
  }

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        &&& .react-tel-input {
          border-color: transparent;
          .form-control {
            background: ${theme.colors.disabled};
            border: 1px solid #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
          }
        }
      `;
    }
  }}
`;
