import * as yup from 'yup';
import { description, itemName, sku } from './baseSchema';

const containerSchema = yup.object().shape({
  name: itemName,
  sku,
  description,
  condition: yup.string().required('Condition is required!'),
  size: yup.string().required('Size is required!'),
  grade: yup.string().required('Grade is required!'),
  color: yup.string().required('Color is required!'),
  subtasks: yup.array().of(
    yup.object().shape({
      order: yup.number().required('Required!').typeError('Required!'),
      plannedHours: yup
        .number()
        .min(0.01, 'Should be greater than 0')
        .max(999.99, 'Should be less than 999.99')
        .required('Hours is required!')
        .typeError('Hours is required!'),
      name: yup.string().required('Task Name is required!'),
      notes: yup
        .string()
        .trim()
        .max(1000, 'Notes must be at most 1000 characters'),
    })
  ),
});

export default containerSchema;
