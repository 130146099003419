// components
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { Row } from 'antd';

interface IMainProps {
  isSalesManager: boolean;
  openInboundModal: () => void;
  createProject: () => void;
}

const Main = ({
  openInboundModal,
  createProject,
  isSalesManager,
}: IMainProps) => (
  <Row className="row">
    <LeftPanel />
    <RightPanel
      isSalesManager={isSalesManager}
      openInboundModal={openInboundModal}
      createProject={createProject}
    />
  </Row>
);

export default Main;
