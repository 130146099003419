import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* depotGradesSaga() {
  yield all([
    fork(W.watchGetGrades),
    fork(W.watchAddGrade),
    fork(W.watchDeleteGrade),
    fork(W.watchEditeGrade),
    fork(W.watchGetGradeById),
  ]);
}
