import { FC } from 'react';
import { EyeIcon } from 'lib/icons';
import theme from 'styles/theme';
import * as Styled from './styles';

export const SwitchPrefix: FC = () => (
  <Styled.SwitchPrefix>
    <EyeIcon color={theme.colors.lightBlue} />
    Mode:
  </Styled.SwitchPrefix>
);
