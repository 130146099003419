import { takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/auth';
import * as E from './workers';

export function* watchAvatar() {
  yield takeLatest(Actions.addAvatar.TRIGGER, E.ensureAvatar);
}

export function* watchGetProfile() {
  yield takeLatest(Actions.getProfile.TRIGGER, E.ensureGetProfile);
}

export function* watchUpdateUser() {
  yield takeLatest(Actions.updateUser.TRIGGER, E.ensureUpdateUser);
}

export function* watchChangePassword() {
  yield takeLatest(Actions.changePassword.TRIGGER, E.ensureChangePassword);
}
