import styled from 'styled-components';
import { SIDE_BAR_WIDTH } from '../../constants';

export const Wrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
  padding: 0;
  display: flex;
  /* height: 47px; */
`;

export const SideBar = styled.div`
  width: ${SIDE_BAR_WIDTH}px;
  min-width: ${SIDE_BAR_WIDTH}px;
  color: ${({ theme }) => theme.colors.lightBlue};
  font-weight: 600;
  font-size: 13px;
  border-right: 1px solid #e5e5e5;
  padding: 20px 17px 11px 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SidebarTitle = styled.div`
  display: inline-flex;
  margin-left: 6px;
`;

export const Left = styled.div`
  display: flex;
`;
