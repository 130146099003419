import styled from 'styled-components';
// styles
import { commonInputStyles } from 'components/common/commonInputStyles';
// components
import { Input } from 'antd';

export const InputPasswordStyled = styled(Input.Password)<any>`
  ${commonInputStyles}
  box-shadow: none;
  input {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: inherit;
  }
  & .ant-input {
    transition: background-color 600000s 0s, color 600000s 0s;
    &:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    font-size: 12px;
    &::placeholder {
      color: ${({ theme }) => theme.colors.mutedInputTextColor};
    }
  }
`;

export const InputPasswordWrapper = styled.div`
  div:last-of-type {
    white-space: initial;
  }
`;
