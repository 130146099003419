import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  a {
    font-size: 14px;
    line-height: 17px;
    margin: 0 5px;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    @media (max-width: 505px) {
      max-width: 200px;
    }
    @media (max-width: 443px) {
      max-width: 110px;
    }
  }
`;
