import { all, call, fork } from 'redux-saga/effects';
import * as Groups from 'store/admin/groups';
import * as W from './watchers';

export function* resourcesSaga() {
  yield all([
    fork(W.watchGetResources),
    fork(W.watchDeleteResources),
    fork(W.watchResourceById),
    fork(W.watchAddResource),
    fork(W.watchUpdateResource),
    fork(W.watchUploadPhoto),
    fork(W.watchMarkAsActiveResource),
    fork(W.watchMarkAsInactiveResource),
    call(Groups.ensureGetGroups, { type: Groups.groups.TRIGGER }),
  ]);
}
