import { SVGProps } from 'react';
import theme from 'styles/theme';

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill={theme.colors.mainDarkBlue}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166508 1.54261L1.36133 0.354493L8 6.99987L1.35462 13.6453L0.166509 12.4571L5.62377 6.99987L0.166508 1.54261Z"
    />
  </svg>
);
