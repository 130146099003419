import { useCallback, useState } from 'react';
import { Radio, Tooltip } from 'antd';
// styles
import { RadioButtonStyled } from './styles';

interface IOption {
  _id: string;
  description: string;
  type: string;
}

interface IItemProps {
  option: IOption;
}

export const Item = ({ option }: IItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleVisibleChange = val => setIsOpen(val);
  const handleMouseLeave = () => setIsOpen(false);
  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );
  return (
    <Tooltip
      key={option._id}
      getPopupContainer={handleGetPopupContainer}
      placement="topLeft"
      title={option.description}
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
    >
      <RadioButtonStyled onMouseLeave={handleMouseLeave}>
        <Radio.Button value={option.type}>{option.type}</Radio.Button>
      </RadioButtonStyled>
    </Tooltip>
  );
};
