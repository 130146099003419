import { FC, memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { BUTTON_TYPES, Button } from 'components/common';
import { Divide, PaperClip, Remove } from 'lib/icons';
import moment from 'moment-timezone';
import { DivideIconWrap, Item, RemoveButtonWrap, TableBodyCol } from './styles';
import { IWorkOrderDto } from 'types';

interface ITableBodyItem {
  item: IWorkOrderDto;
  openDivideTasks: (id: string) => void;
  openCreateModal: (id: string) => void;
  deleteWorkOrder: (id: string) => Promise<void>;
}

const TableBodyItem: FC<ITableBodyItem> = ({
  item,
  openDivideTasks,
  openCreateModal,
  deleteWorkOrder,
}) => {
  const [isLoading, setLoading] = useState(false);
  const onItemClick = () =>
    item.isDivisionNeeded
      ? openDivideTasks(item._id)
      : openCreateModal(item._id);

  const handleRemoveItem = useCallback(
    e => {
      setLoading(true);
      e.stopPropagation();
      deleteWorkOrder(item._id).finally(() => setLoading(false));
    },
    [item._id, deleteWorkOrder]
  );

  return (
    <Item onClick={onItemClick}>
      {item.isDivisionNeeded && (
        <DivideIconWrap>
          <Divide />
        </DivideIconWrap>
      )}
      <TableBodyCol className="table-body-col-date">
        {moment(item.createdAt).format('L')}
      </TableBodyCol>
      <TableBodyCol className="table-body-col-invoice">
        <span>
          {item.invoice}
          {item.prefix}
        </span>
      </TableBodyCol>
      <TableBodyCol className="table-body-col-owner">
        <span>{item.ownerEmail}</span>
      </TableBodyCol>
      <TableBodyCol className="table-body-col-client">
        <span>{item.account}</span>
      </TableBodyCol>
      <TableBodyCol className="table-body-col-company">
        <span>{item.customer}</span>
      </TableBodyCol>
      <TableBodyCol className="table-body-col-atached">
        <PaperClip />
        {item.filesCount}
      </TableBodyCol>
      <RemoveButtonWrap>
        <Button
          onClick={handleRemoveItem}
          loading={isLoading}
          kind={BUTTON_TYPES.DANGER_ICON}
          icon={<Remove />}
          space="0"
          style={{ minWidth: 28, minHeight: 28, padding: 0 }}
        />
      </RemoveButtonWrap>
    </Item>
  );
};

export default memo(TableBodyItem, isEqual);
