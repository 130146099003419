import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { forgotPasswordPromiseCreator } from 'store/auth';
import { ROUTES } from 'store/router';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button, Input } from 'components/common';
import { useRequest } from 'hooks/useRequest';
import { message } from 'utils/message';
import { ForgotPasswordSchema } from 'validation';
import { ButtonsWrapper, Container, EmailBlock, Text, Title } from './styles';

interface IForgotPasswordInput {
  email: string;
}

const SUCCESS_MESSAGE =
  'Email with reset password link has been sent to your email address';

const ForgotPassword = (): JSX.Element => {
  const [forgotPassword, isLoading] = useRequest(forgotPasswordPromiseCreator);
  const history = useHistory();
  const form = useForm<IForgotPasswordInput>({
    mode: 'onBlur',
    resolver: yupResolver(ForgotPasswordSchema),

    shouldUnregister: true,
  });
  const handleSubmit: SubmitHandler<IForgotPasswordInput> = async ({
    email,
  }) => {
    try {
      await forgotPassword({ email });
      await message.success(SUCCESS_MESSAGE);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <AuthWrapper>
      <Container>
        <Title>Forgot password?</Title>
        <Text>
          To reset your password enter the email associated with your account
          below
        </Text>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <EmailBlock>
            <Controller
              render={({ field }) => (
                <Input
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  name={field.name}
                  isRequired
                  autocomplete={false}
                  width="100%"
                  height="50px"
                  style={{ paddingBottom: '15px' }}
                  placeholder="Email"
                  error={errors?.email?.message}
                />
              )}
              name="email"
              control={control}
            />
          </EmailBlock>
          <ButtonsWrapper>
            <Button
              onClick={() => history.push(ROUTES.HOME)}
              kind={BUTTON_TYPES.SECONDARY}
              label="Cancel"
              width="155px"
              height="50px"
              fontSize="16px"
            />
            <Button
              loading={isLoading}
              htmlType="submit"
              kind={BUTTON_TYPES.DEFAULT}
              label="Continue"
              width="155px"
              height="50px"
            />
          </ButtonsWrapper>
        </form>
      </Container>
    </AuthWrapper>
  );
};

export default ForgotPassword;
