import { CheckBordered } from 'lib/icons';
import theme from 'styles/theme';
import { SubtaskStatusesEnum } from 'types';

const getInProgressTitle = (isApproveRequired, isPhotoRequired) => {
  if (!isApproveRequired && !isPhotoRequired) return 'Finish';
  return isPhotoRequired ? 'Upload photo of result' : 'Send to review';
};

export const getButtonsProperties = (
  status,
  isApproveRequired,
  isPhotoRequired
) => {
  switch (status) {
    case SubtaskStatusesEnum.IN_PROGRESS:
      return {
        label: getInProgressTitle(isApproveRequired, isPhotoRequired),
        background: theme.colors.lightDeepBlue,
        color: theme.colors.white,
      };
    case SubtaskStatusesEnum.PAUSED:
      return {
        label: getInProgressTitle(isApproveRequired, isPhotoRequired),
        background: theme.colors.textGrey,
        color: theme.colors.white,
      };
    case SubtaskStatusesEnum.ON_REVIEW:
      return {
        label: 'Task is on review, we will notify you!',
        background: theme.colors.lightOrange,
        color: theme.colors.orange,
      };
    case SubtaskStatusesEnum.DONE:
      return {
        label: 'Done, Good Work!',
        background: theme.colors.lightGreen,
        color: theme.colors.eucalyptus,
        border: true,
        Icon: CheckBordered,
      };
    case SubtaskStatusesEnum.NOT_READY:
      return {
        label: 'This task is not ready yet',
        background: theme.colors.textGrey,
        color: theme.colors.textGreyDark,
      };
    default:
      return {
        label: 'Start task',
        background: theme.colors.primary,
        color: theme.colors.white,
      };
  }
};
