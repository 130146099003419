import styled from 'styled-components';
import { Row } from 'antd';

export const Wrap = styled(Row)`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const PercentWrap = styled.div`
  margin: 10px 0;
`;
