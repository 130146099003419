import * as yup from 'yup';
import { checklist, notes } from './baseSchema';

const recurringTaskSchema = yup.object().shape({
  hours: yup
    .number()
    .min(0.01, 'Should be greater than 0')
    .required('Hours is required!')
    .typeError('Hours is required!'),
  name: yup.string().required('Task Name is required!'),
  notes,
  checklist,
});

export default recurringTaskSchema;
