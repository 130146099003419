import styled from 'styled-components';
// styles
import { commonSelectStyles } from 'components/common/commonSelectStyles';
// components
import { Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const SelectStyled = styled(Select)<any>`
  ${commonSelectStyles};
`;

export const StyledMenu = styled.div`
  padding: 11px 0 2px;
  border-radius: 4px;
  .ant-select-item {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 10px;
  }
  .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const Create = styled.div<{
  isValid?: boolean;
}>`
  color: ${({ theme, isValid }) =>
    isValid ? theme.colors.mainDarkBlue : 'red'};
  background-color: ${({ theme }) => theme.colors.grayLight};
  display: flex;
  justify-content: center;
  padding: 15px 12px;
  cursor: ${({ isValid }) => (isValid ? 'pointer' : 'default')};
  span {
    text-decoration: underline;
  }
`;

export const LoadingOutlinedStyled = styled(LoadingOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: 12px;
  padding-bottom: 8px;
`;

export const SelectWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NoResult = styled.div`
  padding-bottom: 10px;
`;
