import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const getToken = createRoutine('sharing/getToken');
export const getTokenPromiseCreator = promisifyRoutine(getToken);
export const getProjectByToken = createRoutine('sharing/getProjectByToken');
export const deleteToken = createRoutine('sharing/deleteToken');
export const deleteTokenPromiseCreator = promisifyRoutine(deleteToken);
export const sendLink = createRoutine('sharing/sendLink');
export const sendLinkPromiseCreator = promisifyRoutine(sendLink);
export const getSystemNotificationsUnreadCount = createRoutine(
  'sharing/getSystemNotificationsUnreadCount'
);
export const deleteSystemNotification = createRoutine(
  'sharing/deleteSystemNotification'
);
export const deleteSystemNotificationPromiseCreator = promisifyRoutine(
  deleteSystemNotification
);

export const syncNotifications = createRoutine('sharing/syncNotifications');

export const setShowOnlyUnreadNotificationMessages = createAction(
  'sharing/setShowOnlyUnreadNotificationMessages'
);

export const clearSystemNotifications = createAction(
  'sharing/clearSystemNotifications'
);

export const toggleSystemNotifications = createAction(
  'sharing/toggleSystemNotifications'
);

export const getSystemNotificationsUnreadCountPromiseCreator = promisifyRoutine(
  getSystemNotificationsUnreadCount
);
export const getSystemNotifications = createRoutine(
  'sharing/getSystemNotifications'
);
export const getSystemNotificationsPromiseCreator = promisifyRoutine(
  getSystemNotifications
);

export const markSystemNotificationsRead = createRoutine(
  'sharing/markSystemNotificationsRead'
);
export const markSystemNotificationsReadPromiseCreator = promisifyRoutine(
  markSystemNotificationsRead
);
export const markSystemNotificationsUnread = createRoutine(
  'sharing/markSystemNotificationsUnread'
);
export const markSystemNotificationsUnreadPromiseCreator = promisifyRoutine(
  markSystemNotificationsUnread
);

export const markAllSystemNotificationsRead = createRoutine(
  'sharing/markAllSystemNotificationsRead'
);
export const markAllSystemNotificationsReadPromiseCreator = promisifyRoutine(
  markAllSystemNotificationsRead
);
export const downloadPdf = createRoutine('sharing/downloadPdf');
export const downloadPdfPromiseCreator = promisifyRoutine(downloadPdf);
