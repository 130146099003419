import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { subtaskTypesSelector, updateTask } from 'store/admin';
import { currentLocationSelector } from 'store/auth';
import { companySelector } from 'store/company';
import {
  clearAllSubtaskFilesBuffers,
  containerSizesFilterSelector,
  containerSkusFilterSelector,
  postDuplicateProjectPromiseCreator,
  postProjectPromiseCreator,
  projectSelector,
  projectsAfterDraft,
  putProjectPromiseCreator,
} from 'store/dashboard';
import { toggleDrawerAction } from 'store/gantt';
// redux
import {
  createProjectFormWorkOrderPromiseCreator,
  workOrderSelector,
} from 'store/inbound';
import { isFilesLoadingSelector } from 'store/loading';
import { searchSelector } from 'store/predefined';
import {
  coverFiles,
  emptyTask,
  filesForSend,
  getContainer,
  getDefaultValues,
  getTasks,
  taskCountError,
} from './helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import ProjectFiles from '../common/ProjectFiles';
import Container from './Container';
import { MAIN_INFO_INPUTS } from './constants';
import { ProjectConflictsModal } from 'components/ProjectConflictsModal';
import { BUTTON_TYPES, Button, Error } from 'components/common';
// hooks
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { MAX_TASKS_COUNT } from 'lib/constants';
// constants
import { PROJECTS_STATUSES } from 'lib/constants/projects';
import Header from 'pages/Dashboard/Project/CreateProject/Header';
// components
import MainInfo from 'pages/Dashboard/Project/CreateProject/MainInfo';
import Tasks from 'pages/Dashboard/Project/CreateProject/Task';
import { draftProject, removeDraftProject } from 'utils/draftHelpers';
import { prepareDuplicateTaskData } from 'utils/prepareDuplicateTaskData';
// utils
import { ProjectSchema } from 'validation';
// styles
import {
  ButtonStyled,
  LayoutStyled,
  TaskWrapper,
  TasksList,
  Wrap,
} from './styles';
import {
  IAttachmentInfo,
  IProjectConflict,
  IProjectResponseDto,
  IProjectTaskDto,
  ServerErrorsEnum,
  TaskTypeEnum,
} from 'types';

interface ICreateProjectProps {
  onSubmit: (submit?: boolean) => void;
  files?: Partial<IAttachmentInfo>[];
  setFiles?: (files: FormData[]) => void;
  setCloseParent?: (close: boolean) => void;
  closeDraftModal?: () => void;
  isEdit?: boolean;
  isClone?: boolean;
  cloneCount?: number;
  isCreateFromInbound?: boolean;
}

const CreateProject = ({
  onSubmit,
  files,
  setFiles,
  isCreateFromInbound: isInbound,
  setCloseParent,
  isEdit,
  isClone,
  cloneCount,
  closeDraftModal,
}: ICreateProjectProps) => {
  const isFilesLoading = useSelector(isFilesLoadingSelector);
  const searchBy = useSelector(searchSelector);
  const company = useSelector(companySelector);
  const currentLocationId = useSelector(currentLocationSelector);
  const { isContainersEnabled } = useSelector(companySelector, isEqual);
  const subtaskTypes = useSelector(subtaskTypesSelector, isEqual);
  // const containerSizes = useSelector(containerSizesFilterSelector, isEqual);
  const containerSkus = useSelector(containerSkusFilterSelector, isEqual);
  const [isWorkOrderContainer, setIsWorkOrderContainer] = useState(false);

  // TODO update any
  const defaultData: any = useSelector(
    isInbound ? (workOrderSelector as any) : projectSelector,
    isEqual
  );
  const withDefaultValues = isEdit || isInbound;
  const defaultValues = useMemo(
    () => getDefaultValues(defaultData, withDefaultValues, isClone),
    [defaultData, withDefaultValues, isClone]
  );
  const isDraft = useMemo(
    () => withDefaultValues && defaultData?.isDraft,
    [defaultData, withDefaultValues]
  );
  const titleSaveButton = useMemo(
    () => (isEdit && !isDraft && !isClone ? 'Save Changes' : 'Create Project'),
    [isEdit, isDraft, isClone]
  );

  const [editDataFiles, setEditDataFiles] = useState(defaultValues?.files);

  const onSetFiles = useMemo(
    () => (withDefaultValues ? setEditDataFiles : setFiles),
    [withDefaultValues, setEditDataFiles, setFiles]
  );
  const projectFiles = useMemo(
    () => (withDefaultValues ? editDataFiles : files),
    [withDefaultValues, editDataFiles, files]
  );
  const containerDefaultValue = useMemo(
    () => withDefaultValues && defaultValues?.container,
    [withDefaultValues, defaultValues]
  );

  const [isLoading, setLoading] = useState(false);
  const [newContainer, setNewContainer] = useState();
  const [showContainer, toggleContainerView] = useState(containerDefaultValue);
  const [isSaveDisabled, setDisabled] = useState(!withDefaultValues);
  const [order, setOrder] = useState(0);
  const [coverFile, setCoverFile] = useState(defaultValues?.cover || {});
  const [projectConflicts, setProjectConflicts] = useState<IProjectConflict[]>(
    []
  );
  const updateContainer = useActions(updateTask);
  const updateProjectsAfterDraft = useActions(projectsAfterDraft);
  const subtaskFilesBuffersClear = useActions(clearAllSubtaskFilesBuffers);
  const toggleDrawer = useActions(toggleDrawerAction);
  const createNewProject = useActionsRoutines(postProjectPromiseCreator);
  const duplicateProject = useActionsRoutines(
    postDuplicateProjectPromiseCreator
  );
  const editProject = useActionsRoutines(putProjectPromiseCreator);
  const createProjectFormWorkOrder = useActionsRoutines(
    createProjectFormWorkOrderPromiseCreator
  );
  const form = useForm({
    resolver: yupResolver(ProjectSchema),
    context: { isSkuRequired: company?.isSkuRequired },
    defaultValues,
    shouldUnregister: true,
  });

  const { watch, formState, setValue, register, setError, getValues } = form;
  const isDirtyFieldExist = useMemo(
    () => !!Object.keys(formState.dirtyFields).length,
    [formState.dirtyFields]
  );

  const isDirty = useMemo(
    () => isDirtyFieldExist || !!files?.length || showContainer,
    [isDirtyFieldExist, files, showContainer]
  );
  const showSaveDraft = useMemo(
    () => isDirty && (!isEdit || isClone || defaultValues?.isDraft),
    [isDirty, isEdit, isClone, defaultValues]
  );

  const mainInfoRef = useRef(null);
  const containerRef = useRef(null);
  const taskRef = useRef(null);

  useEffect(() => {
    const errorsLists = Object.keys(formState.errors);
    if (MAIN_INFO_INPUTS.includes(errorsLists[0])) {
      mainInfoRef.current.scrollIntoView();
    } else if (errorsLists.includes(TaskTypeEnum.Container)) {
      containerRef.current.scrollIntoView();
    } else if (formState.errors.tasks) {
      taskRef.current.scrollIntoView();
    }
  }, [formState]);

  useEffect(() => {
    register('ownerEmail');
    register('generatedApiKey');
    register('isArchived');
    setValue('ownerEmail', defaultValues.ownerEmail);
    setValue('generatedApiKey', defaultValues.generatedApiKey);
    setValue('isArchived', defaultValues.isArchived);
  }, [register, setValue, defaultValues]);

  const tasks = useFieldArray({
    control: form.control,
    name: 'tasks',
  });
  const {
    append: appendFormTask,
    remove: removeFormTask,
    fields: tasksFormFields,
  } = tasks;

  const checkValidation = useCallback(
    data => {
      let errorsCount = 0;
      if (!data.tasks?.length) {
        errorsCount++;
        // @ts-ignore
        setError(...taskCountError);
      }
      return errorsCount;
    },
    [setError]
  );

  const request = useCallback(
    async (rest, newData, skipContainerUniqueness = false) => {
      try {
        const data = { ...rest, ...newData };
        const id = defaultValues?._id;
        const status = PROJECTS_STATUSES.NOT_STARTED;
        const dataWithStatus = { ...data, status };
        const cloneData = {
          cloneCount,
          ...dataWithStatus,
          tasks: dataWithStatus?.tasks?.map(task => ({
            ...task,
            subtasks: task?.subtasks?.map(subtask =>
              Object.keys(subtask)?.reduce(
                (acc, key) =>
                  key !== 'actualHours' ? { ...acc, [key]: subtask[key] } : acc,
                {}
              )
            ),
          })),
        };

        if (isInbound) {
          return await createProjectFormWorkOrder({
            id,
            data: dataWithStatus,
            skipContainerUniqueness,
          });
        }
        if (isEdit && !isClone && !isDraft) {
          return await editProject({
            id,
            data: { ...defaultData, ...data, notes: data.notes || '' },
            skipContainerUniqueness,
          });
        }

        return isClone
          ? await duplicateProject({
              data: cloneData,
              skipContainerUniqueness,
            })
          : await createNewProject({
              data: dataWithStatus,
              skipContainerUniqueness,
            });
      } catch (error) {
        if (error.data.errorCode === ServerErrorsEnum.CONTAINER_NUMBER_EXISTS) {
          setProjectConflicts(error.data.conflicts);
        }

        throw error;
      }
    },
    [
      isInbound,
      defaultValues,
      isEdit,
      isClone,
      isDraft,
      defaultData,
      createProjectFormWorkOrder,
      createNewProject,
      editProject,
    ]
  );

  const handleSubmit = useCallback(
    (skipContainerUniqueness = false) =>
      data => {
        if (checkValidation(data)) return;
        setLoading(true);
        const { container, ...rest } = data;
        const resultContainer = getContainer(container);
        const tasks = getTasks(rest);
        const withContainer = showContainer && resultContainer;
        const tasksWithContainer = [...tasks, resultContainer];
        const resultTasks = withContainer ? tasksWithContainer : tasks;
        const newData = {
          tasks: resultTasks,
          coverUrl: coverFile.fileUrl || '',
          files: projectFiles,
        };

        const containerData = {
          data: resultContainer,
          id: container?.updateId,
        };
        removeDraftProject(
          defaultValues?._id,
          currentLocationId,
          searchBy,
          containerSkus
        );
        request(rest, newData, skipContainerUniqueness)
          .then((response: IProjectResponseDto) => {
            const isSameLocation = response.locationId === currentLocationId;
            subtaskFilesBuffersClear();

            window.history.replaceState(
              null,
              null,
              `/dashboard${isSameLocation ? `?projectId=${response._id}` : ''}`
            );

            setLoading(false);
            onSetFiles([]);
            onSubmit(isSameLocation);
            if (isClone) toggleDrawer(isSameLocation);
            if (isWorkOrderContainer) updateContainer({ data: containerData });
            // if (!isSameLocation) closeDraftModal();
          })
          .catch(e => {
            setLoading(false);
            console.error(e);
          });
      },
    [
      coverFile,
      projectFiles,
      isWorkOrderContainer,
      subtaskFilesBuffersClear,
      request,
      showContainer,
      isClone,
      onSubmit,
      defaultValues,
      checkValidation,
      currentLocationId,
      onSetFiles,
      searchBy,
      updateContainer,
      toggleDrawer,
      closeDraftModal,
      containerSkus,
    ]
  );

  const handleCancel = useCallback(() => setProjectConflicts([]), []);
  const handleContinue = useCallback(() => {
    setProjectConflicts([]);
    void form.handleSubmit(handleSubmit(true))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  const setFileAsCover = useCallback(
    async (file, removeCover) => {
      setCoverFile(removeCover ? {} : file);
      onSetFiles(coverFiles(projectFiles, file, removeCover));

      return Promise.resolve();
    },
    [projectFiles, onSetFiles]
  );

  const addFiles = useCallback(
    (files, values) => {
      const newFiles = files.map((file, index) =>
        filesForSend(file, values[index])
      );
      onSetFiles([...projectFiles, ...newFiles]);
    },
    [projectFiles, onSetFiles]
  );

  const deleteFile = useCallback(
    file => {
      if (coverFile.fileUrl === file.fileUrl) setCoverFile({});
      const isPreview = file?.fileUrl === defaultData?.cover?.fileUrl;
      if (isEdit && isPreview) setCoverFile({ fileUrl: '' });
      onSetFiles(
        projectFiles.filter(item =>
          typeof item === 'string' ? item : item.filePath !== file.filePath
        )
      );
    },
    [onSetFiles, isEdit, defaultData, projectFiles, setCoverFile, coverFile]
  );

  const appendTask = useCallback(
    (newTasks: IProjectTaskDto[] | undefined) => {
      const data = newTasks
        ? newTasks.map(prepareDuplicateTaskData)
        : emptyTask(order);

      appendFormTask(data);
      setDisabled(false);
      setOrder(order + 1);
    },
    [appendFormTask, setOrder, order, setDisabled]
  );

  const appendContainer = useCallback(() => {
    toggleContainerView(true);
  }, []);

  const tasksWatch: IProjectTaskDto[] = useMemoCompare(watch('tasks'));

  const removeTask = useCallback(
    index => {
      removeFormTask(index);
      if (tasksFormFields.length < 2) setDisabled(true);
    },
    [setDisabled, removeFormTask, tasksFormFields]
  );

  const prepareDraft = useCallback(() => {
    const formData = getValues();
    const tasks = formData.tasks.map((item, i: number) => ({
      ...item,
      type: 'common',
      order: i + 1,
    }));
    if (formData.container) {
      tasks.push({ ...formData.container, type: 'container' });
    }

    return draftProject(
      tasks,
      getValues(),
      projectFiles,
      defaultValues?._id,
      searchBy,
      currentLocationId,
      containerSkus
    );
  }, [
    projectFiles,
    defaultValues?._id,
    searchBy,
    currentLocationId,
    getValues,
    containerSkus,
  ]);

  const saveDraft = useCallback(() => {
    const payload = prepareDraft();
    subtaskFilesBuffersClear();
    updateProjectsAfterDraft(payload);
    closeDraftModal();
    if (setFiles) setFiles([]);
  }, [
    prepareDraft,
    updateProjectsAfterDraft,
    closeDraftModal,
    setFiles,
    subtaskFilesBuffersClear,
  ]);

  const deleteDraft = useCallback(() => {
    const payload = removeDraftProject(
      defaultValues?._id,
      currentLocationId,
      searchBy,
      containerSkus
    );
    subtaskFilesBuffersClear();
    updateProjectsAfterDraft(payload);
    closeDraftModal();
    if (setFiles) setFiles([]);
  }, [
    defaultValues?._id,
    searchBy,
    currentLocationId,
    setFiles,
    updateProjectsAfterDraft,
    closeDraftModal,
    subtaskFilesBuffersClear,
    containerSkus,
  ]);

  const handleAppendContainer = useCallback(
    container => {
      setNewContainer({ ...defaultValues, container });
      toggleContainerView(true);
    },
    [defaultValues]
  );

  const handleDeleteContainer = useCallback(
    () => setNewContainer(undefined),
    []
  );

  const tasksList = useMemo(
    () =>
      tasksFormFields.map((item: any, index) => (
        <Tasks
          isEdit={isEdit}
          item={item}
          name={`tasks[${index}].`}
          key={item.id}
          watchData={tasksWatch}
          subtaskTypes={subtaskTypes}
          index={index}
          taskRef={taskRef}
          remove={removeTask}
          order={order}
          appendTask={appendTask}
          projectId={defaultValues._id}
          project={defaultValues}
          appendContainer={handleAppendContainer}
          tasksCount={showContainer ? tasksWatch.length + 1 : tasksWatch.length}
        />
      )),
    [
      tasksFormFields,
      isEdit,
      removeTask,
      subtaskTypes,
      tasksWatch,
      appendTask,
      order,
      defaultValues,
      handleAppendContainer,
      showContainer,
    ]
  );

  const handleAppendTask = useCallback(
    () => appendTask(undefined),
    [appendTask]
  );

  const handleSubmitForm = useCallback(
    data => {
      const skipContainerUniqueness = isEdit
        ? !!(
            data.container?.num &&
            data.container?.num === defaultValues.container?.container?.num
          )
        : false;

      handleSubmit(skipContainerUniqueness)(data);
    },
    [defaultValues.container?.container?.num, handleSubmit, isEdit]
  );

  return (
    <Wrap>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <Header
            isEdit={isEdit}
            isClone={isClone}
            isDraft={isDraft}
            cloneCount={cloneCount}
          />
          <LayoutStyled>
            <MainInfo
              mainInfoRef={mainInfoRef}
              errors={form?.formState?.errors}
            />
            <TaskWrapper>
              {!!isContainersEnabled && (
                <div ref={containerRef}>
                  <Container
                    toggleContainerView={toggleContainerView}
                    setIsWorkOrderContainer={setIsWorkOrderContainer}
                    defaultValues={newContainer || defaultValues}
                    showContainer={showContainer}
                    appendContainer={appendContainer}
                    subtaskTypes={subtaskTypes}
                    isInbound={isInbound}
                    onDelete={handleDeleteContainer}
                  />
                </div>
              )}

              {form?.formState?.errors?.taskCount && (
                <Error>{form?.formState?.errors?.taskCount?.message}</Error>
              )}
              <TasksList>{tasksList}</TasksList>
              {tasksWatch.length < MAX_TASKS_COUNT && (
                <Button
                  disabled={isFilesLoading}
                  onClick={handleAppendTask}
                  label="Add Item"
                  height="38px"
                  style={{ margin: '11px 0' }}
                />
              )}
            </TaskWrapper>
            <ProjectFiles
              files={projectFiles}
              setFiles={addFiles}
              setCoverFile={setFileAsCover}
              deleteFiles={deleteFile}
              setCloseParent={setCloseParent}
              setDisabled={setDisabled}
              isClone={isClone}
            />
            <Button
              label={titleSaveButton}
              htmlType="submit"
              height="55px"
              style={{ marginTop: 10 }}
              disabled={isFilesLoading || isSaveDisabled || !tasksList.length}
              loading={isLoading}
            />
            {showSaveDraft && (
              <ButtonStyled
                onClick={saveDraft}
                label="Save to draft"
                kind={BUTTON_TYPES.LINK}
                type="link"
              />
            )}
            {isDraft && (
              <Button
                onClick={deleteDraft}
                label="Delete draft"
                kind={BUTTON_TYPES.LOGOUT}
                type={BUTTON_TYPES.LINK}
                style={{
                  alignSelf: 'center',
                  width: '100px',
                  marginTop: '15px',
                }}
              />
            )}
          </LayoutStyled>
        </form>
      </FormProvider>
      {!!projectConflicts.length && (
        <ProjectConflictsModal
          open
          conflicts={projectConflicts}
          containerNumber={getValues('container.num')}
          onCancel={handleCancel}
          onContinue={handleContinue}
        />
      )}
    </Wrap>
  );
};

export default memo(CreateProject);
