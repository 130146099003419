export const ArrowDown = () => (
  <svg
    width="10"
    height="5"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5978 0.581048L14.9253 1.81336L7.50029 8.66028L0.0752936 1.80643L1.40279 0.581048L7.50029 6.20951L13.5978 0.581048Z"
      fill="#172B4D"
    />
  </svg>
);
