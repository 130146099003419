import { FC } from 'react';
import { passwordRequirements } from '../constants';
import { List } from './styles';

const PasswordRequirements: FC = () => (
  <List>
    {passwordRequirements.map(listItem => (
      <li key={listItem}>{listItem}</li>
    ))}
  </List>
);

export default PasswordRequirements;
