import { call, put, select } from 'redux-saga/effects';
import { colorsSelector } from 'store/depot';
import { actions } from 'store/loading';
import { addColor, colors, deleteColor } from './actions';
import Api from 'utils/api';
import { ColorDto, ILoaderPayload } from 'types';

export function* ensureGetColors({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof colors.TRIGGER;
}) {
  const colorsList = yield select(colorsSelector);
  const noLoader = payload?.noLoader || colorsList?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.colors.getColors);
    yield put(colors.success(data));
  } catch (err) {
    yield put(colors.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureAddColor({
  payload,
}: {
  payload: ColorDto;
  type: typeof addColor.TRIGGER;
}) {
  try {
    const res = yield call(Api.colors.postColors, payload);
    yield call(ensureGetColors, {
      payload: { noLoader: true },
      type: colors.TRIGGER,
    });
    yield put(addColor.success(res));
  } catch (err) {
    yield put(addColor.failure(err));
  }
}

export function* ensureDeleteColor({
  payload,
}: {
  payload: string;
  type: typeof deleteColor.TRIGGER;
}) {
  try {
    yield call(Api.colors.deleteColor, payload);
    yield call(ensureGetColors, {
      payload: { noLoader: true },
      type: colors.TRIGGER,
    });
    yield put(deleteColor.success());
  } catch (err) {
    yield put(deleteColor.failure(err));
  }
}
