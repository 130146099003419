import { call, put, select } from 'redux-saga/effects';
import { groupsSelector } from 'store/admin';
import { actions } from 'store/loading';
import * as Actions from './actions';
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureAddGroupPayload,
  IEnsureAddResourceToGroupPayload,
  IEnsureDeleteGroupPayload,
  IEnsureDeleteResourceFromGroupPayload,
  IEnsureGetGroupsPayload,
  IEnsureUpdateGroupPayload,
  UpdateResourceGroupOrderDto,
} from 'types';

export function* ensureGetGroups({
  payload = {},
}: {
  payload?: IEnsureGetGroupsPayload;
  type: typeof Actions.groups.TRIGGER;
}) {
  const locationId = payload?.locationId || storageManager.getLocation();
  const groups = yield select(groupsSelector);
  const noLoader =
    typeof payload?.noLoader !== 'undefined'
      ? payload?.noLoader
      : !payload?.params && groups?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.groups.groups, {
      params: {
        ...payload.params,
        locationId,
      },
    });
    yield put(Actions.groups.success(data));
  } catch (err) {
    yield put(Actions.groups.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetGroupById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getGroup.TRIGGER;
}) {
  try {
    const data = yield call(Api.groups.getGroup, payload);
    yield put(Actions.getGroup.success(data));
  } catch (err) {
    yield put(Actions.getGroup.failure(err));
  }
}

export function* ensureDeleteGroup({
  payload,
}: {
  payload: IEnsureDeleteGroupPayload;
  type: typeof Actions.deleteGroup.TRIGGER;
}) {
  try {
    yield call(Api.groups.deleteGroup, payload.id);
    yield call(ensureGetGroups, {
      payload: { params: payload.params, noLoader: true },
      type: Actions.groups.TRIGGER,
    });
    yield put(Actions.deleteGroup.success());
  } catch (err) {
    yield put(Actions.deleteGroup.failure(err));
  }
}

export function* ensureOrderGroup({
  payload,
}: {
  payload: UpdateResourceGroupOrderDto;
  type: typeof Actions.groupsOrder.TRIGGER;
}) {
  try {
    yield call(Api.groups.groupsOrder, payload);
    yield call(ensureGetGroups, {
      payload: { noLoader: true },
      type: Actions.groups.TRIGGER,
    });
    yield put(Actions.groupsOrder.success());
  } catch (err) {
    yield put(Actions.groupsOrder.failure(err));
  }
}

export function* ensureUpdateGroup({
  payload,
}: {
  payload: IEnsureUpdateGroupPayload;
  type: typeof Actions.updateGroup.TRIGGER;
}) {
  try {
    const { params, id, data } = payload;
    yield call(Api.groups.putGroup, { id, data });
    yield call(ensureGetGroups, {
      type: Actions.groups.TRIGGER,
      payload: { params, noLoader: true },
    });
    yield put(Actions.updateGroup.success());
  } catch (err) {
    yield put(Actions.updateGroup.failure(err));
  }
}

export function* ensureAddGroup({
  payload,
}: {
  payload: IEnsureAddGroupPayload;
  type: typeof Actions.addGroup.TRIGGER;
}) {
  try {
    const { params, data } = payload;
    yield call(Api.groups.postGroup, data);
    yield call(ensureGetGroups, {
      payload: { params, noLoader: true },
      type: Actions.groups.TRIGGER,
    });
    yield put(Actions.addGroup.success());
  } catch (err) {
    yield put(Actions.addGroup.failure(err));
  }
}

export function* ensureAddResourceToGroup({
  payload,
}: {
  payload: IEnsureAddResourceToGroupPayload;
  type: typeof Actions.addResourceToGroup.TRIGGER;
}) {
  try {
    const { params, ...rest } = payload;
    yield call(Api.groups.addResource, rest);
    yield call(ensureGetGroups, {
      payload: { params, noLoader: true },
      type: Actions.groups.TRIGGER,
    });
    yield put(Actions.addResourceToGroup.success());
  } catch (err) {
    yield put(Actions.addResourceToGroup.failure(err));
  }
}

export function* ensureDeleteResourceFromGroup({
  payload,
}: {
  payload: IEnsureDeleteResourceFromGroupPayload;
  type: typeof Actions.removeResourceFromGroup.TRIGGER;
}) {
  try {
    const { id } = payload;
    yield call(Api.groups.deleteResource, id);
    yield put(Actions.removeResourceFromGroup.success(id));
  } catch (err) {
    yield put(Actions.removeResourceFromGroup.failure(err));
  }
}
