import { createSelector } from '@reduxjs/toolkit';
import { IInitialState, USER_MAIN_ROLES } from 'types';

const auth = (r: IInitialState) => r.auth;
export const userSelector = createSelector(auth, r => r?.user);
export const isAuthorizedSelector = createSelector(auth, r => r?.isAuthorized);
export const hasErrorSelector = createSelector(auth, r => r?.hasError);
export const currentLocationSelector = createSelector(
  auth,
  r => r.currentLocation
);
export const storeTimezoneSelector = createSelector(
  auth,
  r => r.locationTimezone
);

export const isAdminSelector = createSelector(
  auth,
  r =>
    r.user.mainRole === USER_MAIN_ROLES.SUPER_ADMIN ||
    r.user.mainRole === USER_MAIN_ROLES.ADMIN
);
export const isProjectManagerSelector = createSelector(
  auth,
  r => r.user.mainRole === USER_MAIN_ROLES.PROJECT_MANAGER
);

export const isSuperAdminSelector = createSelector(
  auth,
  r => r.user.mainRole === USER_MAIN_ROLES.SUPER_ADMIN
);

export const isSalesManagerSelector = createSelector(
  auth,
  r => r.user.mainRole === USER_MAIN_ROLES.SALES_MANAGER
);

export const isSchedulerSelector = createSelector(
  auth,
  r => r.user.mainRole === USER_MAIN_ROLES.SCHEDULER
);
