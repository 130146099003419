import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayLight};
  min-height: 100%;
  width: 100%;
  padding: 38px 0;
`;

export const Container = styled.div<{ isLarge?: boolean }>`
  max-width: ${({ isLarge }) => (isLarge ? '568px' : '396px')};
  width: 100%;
`;

export const LogoItem = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const BoxWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px 0;
`;

export const MessageWrapper = styled(BoxWrapper)`
  margin-bottom: 10px;
`;
