import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'store/router';
import WarningMessage from './WarningMessage/WarningMessage';
import { BUTTON_TYPES, Button } from 'components/common';
import { ButtonWrapper, ModalItem, SubTitle, Title } from './styles';

interface IRestoreModal {
  companyName: string;
}

const RestoreModal = ({ companyName }: IRestoreModal): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleRegisterCompany = () => {
    history.push(ROUTES.REGISTER_NEW_COMPANY);
  };

  return (
    <>
      <Button
        label="Register new company"
        kind={BUTTON_TYPES.SECONDARY}
        fontSize="13px"
        height="36px"
        space="75px"
        width="224px"
        onClick={showModal}
      />
      <ModalItem
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="420px"
        centered
      >
        <Title>Register new company?</Title>
        <SubTitle>Are you sure, you want to create new company?</SubTitle>
        <WarningMessage companyName={companyName} />
        <ButtonWrapper>
          <Button
            label="Cancel"
            kind={BUTTON_TYPES.SECONDARY}
            fontSize="14px"
            height="50px"
            space="75px"
            width="185px"
            onClick={handleCancel}
          />
          <Button
            label="I am sure, Register"
            kind={BUTTON_TYPES.DEFAULT}
            fontSize="14px"
            height="50px"
            space="75px"
            width="185px"
            onClick={handleRegisterCompany}
          />
        </ButtonWrapper>
      </ModalItem>
    </>
  );
};

export default RestoreModal;
