interface ISortIconProps {
  direction: boolean;
}

export const SortIcon = ({ direction }: ISortIconProps) => (
  <div
    style={{
      display: 'inline-flex',
      flexDirection: 'column',
      transform: direction ? 'none' : 'rotate(180deg)',
    }}
  >
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33325 4.1665L4.16659 -0.000162601L-8.10623e-05 4.1665H8.33325Z"
        fill="#737F94"
      />
    </svg>

    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L4 4L8 0H0Z"
        fill="#737F94"
      />
    </svg>
  </div>
);
