// icons
import { Check, Error } from 'lib/icons';
import { message as antMessage } from 'antd';

export const message = {
  success: (content = 'Changes Saved!') =>
    antMessage.success({ content, icon: <Check /> }),
  error: (errors = ['Failed!']) =>
    antMessage.error({
      content: (
        <div onClick={() => document.execCommand('copy')}>
          {errors.map((item, i) => (
            <span key={i}>
              {item}
              <br />
            </span>
          ))}
        </div>
      ),
      icon: <Error />,
    }),
};
