import { call, put, select } from 'redux-saga/effects';
import * as Router from 'store/router';
// redux
import * as Actions from 'store/subcontractors';
// utils
import Api from 'utils/api';
import { message } from 'utils/message';
import storageManager from 'utils/storageManager';
import {
  IEnsureAttachToSubtaskPayload,
  MarkSubtaskAsPausedPayload,
} from 'types';

export function* ensureGetDelegatedSubtask() {
  try {
    yield put(Actions.getDelegatedSubtask.request());
    const {
      params: { id, jwt },
    } = yield select(Router.matchRoutes);
    storageManager.setApiKey(jwt);

    const { subcontractors } = yield select();
    if (id === subcontractors.delegatedNextSubtask._id) {
      yield put(Actions.getDelegatedSubtask.success());
    } else {
      const data = yield call(Api.subcontractors.getDelegatedSubtask, id);
      yield put(Actions.getDelegatedSubtask.success(data));
    }
  } catch (err) {
    yield put(Actions.getDelegatedSubtask.failure());
    yield message.error(['Invalid link']);
  }
}

export function* ensureStartSubtask({
  payload,
}: {
  payload: string;
  type: typeof Actions.startSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.startSubtask, payload);
    yield put(Actions.startSubtask.success(data));
  } catch (err) {
    yield put(Actions.startSubtask.failure(err));
  }
}

export function* ensureSendSubtaskToReview({
  payload,
}: {
  payload: string;
  type: typeof Actions.sendSubtaskToReview.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.sendSubtaskToReview, payload);
    yield put(Actions.sendSubtaskToReview.success(data));
  } catch (err) {
    yield put(Actions.sendSubtaskToReview.failure(err));
  }
}

export function* ensureMarkSubtaskAsDone({
  payload,
}: {
  payload: string;
  type: typeof Actions.markSubtaskAsDone.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.markSubtaskAsDone, payload);
    yield put(Actions.markSubtaskAsDone.success(data));
  } catch (err) {
    yield put(Actions.markSubtaskAsDone.failure(err));
  }
}

export function* ensureMarkSubtaskAsPaused({
  payload,
}: {
  payload: MarkSubtaskAsPausedPayload;
  type: typeof Actions.markSubtaskAsPaused.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.pause, payload);

    yield put(Actions.markSubtaskAsPaused.success(data));
  } catch (err) {
    yield put(Actions.markSubtaskAsPaused.failure(err));
  }
}

export function* ensureAttachToSubtask({
  payload,
}: {
  payload: IEnsureAttachToSubtaskPayload;
  type: typeof Actions.attachToSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.attachToSubtask, payload);
    yield put(Actions.attachToSubtask.success(data));
  } catch (err) {
    yield put(Actions.attachToSubtask.failure(err));
  }
}
