import { SVGProps } from 'react';

export function DepotIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.25 9.75H3V15c0 .827.673 1.5 1.5 1.5h9c.827 0 1.5-.673 1.5-1.5V9.75h.75a.75.75 0 00.53-1.28L9.53 1.72a.75.75 0 00-1.06 0L1.72 8.47a.75.75 0 00.53 1.28zM9 3.31l4.5 4.5V15h-9V7.811l4.5-4.5z" />
      <path d="M8.063 6.772a3.078 3.078 0 00-1.181.672.312.312 0 00-.07.381.65.65 0 01-.574.988.312.312 0 00-.284.244c-.057.226-.083.46-.079.693.004.226.03.451.079.672a.312.312 0 00.271.247.681.681 0 01.588 1.034.313.313 0 00.069.363c.337.308.742.535 1.18.662a.313.313 0 00.35-.115c.35-.485.863-.494 1.195 0a.313.313 0 00.35.128 3.107 3.107 0 001.162-.672.313.313 0 00.069-.384.65.65 0 01.566-.997.314.314 0 00.293-.235 3.206 3.206 0 000-1.397.313.313 0 00-.312-.243.625.625 0 01-.547-.988.312.312 0 00-.06-.381 3.173 3.173 0 00-1.19-.672.313.313 0 00-.353.128.656.656 0 01-1.163 0 .313.313 0 00-.36-.128zM9 8.5A1.25 1.25 0 119 11a1.25 1.25 0 010-2.5z" />
    </svg>
  );
}
