import { useMemo } from 'react';
import { getCreatedAtStr } from './helpers';
import { CreatedAt } from './styles';

const TIME_FORMAT = 'h:mm:ss a';

export const MessageTime = ({ message }) => {
  const { createdAt, updatedAt } = message;
  const isUpdated = useMemo(
    () =>
      getCreatedAtStr(createdAt, TIME_FORMAT) !==
      getCreatedAtStr(updatedAt, TIME_FORMAT),
    [createdAt, updatedAt]
  );
  const createdAtStr = useMemo(
    () => getCreatedAtStr(isUpdated ? updatedAt : createdAt),
    [createdAt, updatedAt, isUpdated]
  );
  return (
    <CreatedAt>
      {createdAtStr}
      {isUpdated ? (
        <span>
          <pre
            style={{
              fontSize: '4px',
              lineHeight: '12px',
              verticalAlign: 'middle',
              display: 'inline-block',
              margin: 0,
            }}
          >
            {' '}
            ●{' '}
          </pre>
          Edited
        </span>
      ) : (
        ''
      )}
    </CreatedAt>
  );
};
