import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetResources() {
  yield call(E.ensureGetResources, { type: Actions.resources.TRIGGER });
  yield takeLatest(Actions.resources.TRIGGER, E.ensureGetResources);
}

export function* watchDeleteResources() {
  yield takeLatest(Actions.deleteResource.TRIGGER, E.ensureDeleteResources);
}

export function* watchResourceById() {
  yield takeLatest(Actions.getResourceById.TRIGGER, E.ensureGetResourceById);
}

export function* watchUpdateResource() {
  yield takeLatest(Actions.updateResource.TRIGGER, E.ensureUpdateResource);
}

export function* watchAddResource() {
  yield takeLatest(Actions.addResource.TRIGGER, E.ensureAddResource);
}

export function* watchUploadPhoto() {
  yield takeLatest(Actions.uploadPhoto.TRIGGER, E.ensureUploadPhoto);
}

export function* watchGetResourcesList() {
  yield takeLatest(Actions.resourcesList.TRIGGER, E.ensureGetResourcesList);
}

export function* watchMarkAsActiveResource() {
  yield takeLatest(
    Actions.markAsActiveResource.TRIGGER,
    E.ensureMarkAsActiveResource
  );
}

export function* watchMarkAsInactiveResource() {
  yield takeLatest(
    Actions.markAsInactiveResource.TRIGGER,
    E.ensureMarkAsInactiveResource
  );
}
