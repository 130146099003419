export const Dislike = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip01212)">
      <path
        d="M24.375 3.01411e-07H1.875C1.62873 -0.000139369 1.38485 0.0482636 1.1573 0.142442C0.929755 0.23662 0.723001 0.374726 0.548864 0.548864C0.374726 0.723001 0.23662 0.929755 0.142442 1.1573C0.0482636 1.38485 -0.000139369 1.62873 3.01411e-07 1.875V16.875C-0.000139369 17.1213 0.0482636 17.3651 0.142442 17.5927C0.23662 17.8202 0.374726 18.027 0.548864 18.2011C0.723001 18.3753 0.929755 18.5134 1.1573 18.6076C1.38485 18.7017 1.62873 18.7501 1.875 18.75H8.53384C11.2148 21.7598 11.25 24.7247 11.25 28.125C11.2499 28.3713 11.2983 28.6151 11.3924 28.8427C11.4866 29.0702 11.6247 29.277 11.7989 29.4511C11.973 29.6253 12.1798 29.7634 12.4073 29.8576C12.6349 29.9517 12.8787 30.0001 13.125 30C21.0242 30 22.2675 25.9937 22.4634 22.5H24.375C25.8663 22.4983 27.2961 21.9052 28.3506 20.8506C29.4052 19.7961 29.9983 18.3663 30 16.875V5.625C29.9983 4.13367 29.4052 2.70389 28.3506 1.64935C27.2961 0.594818 25.8663 0.00165458 24.375 3.01411e-07ZM3.75 3.75H7.5V15H3.75V3.75ZM26.25 16.875C26.2494 17.3721 26.0516 17.8486 25.7001 18.2001C25.3486 18.5516 24.8721 18.7494 24.375 18.75H20.625C20.3787 18.7499 20.1349 18.7983 19.9073 18.8924C19.6798 18.9866 19.473 19.1247 19.2989 19.2989C19.1247 19.473 18.9866 19.6798 18.8924 19.9073C18.7983 20.1349 18.7499 20.3787 18.75 20.625C18.75 23.9026 18.75 25.752 14.9798 26.1621C14.8883 22.9761 14.3518 19.5264 11.25 16.1243V3.75H24.375C24.8721 3.75065 25.3486 3.9484 25.7001 4.29989C26.0516 4.65138 26.2494 5.12792 26.25 5.625V16.875Z"
        fill={'white'}
      />
    </g>
    <defs>
      <clipPath id="clip01212">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
