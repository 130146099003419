import styled from 'styled-components';
import { Modal } from 'antd';

export const ShortCutsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 13px;
  width: 33px;
  height: 33px;
  background: ${({ theme }) => theme.colors.grayLight};
  border-radius: 10px;
`;

export const ModalItem = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 5px;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
`;

export const ShortCutItem = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 13px;
  gap: 20px;
`;

export const ShortCutTitle = styled.div`
  flex: 2;
`;

export const ShortCutCombination = styled.div`
  flex: 1;
`;
