import styled from 'styled-components';
import { Divider as BaseDivider, List as BaseList } from 'antd';

export const TaskModeItemWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 8px;
  padding: 10px 0 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Count = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 8px;
`;

export const List = styled(BaseList)`
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
    display: flex;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  padding: 0 16px;
`;

export const Divider = styled(BaseDivider)`
  margin: 16px 0;
`;

export const ItemWrapper = styled.div`
  padding: 16px 16px 0;
`;
