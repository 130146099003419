import { Cross } from 'lib/icons';
import theme from 'styles/theme';
import { CloseWrapper } from './styles';

interface ICloseButtonProps {
  onClick: () => void;
}

const CloseButton = ({ onClick }: ICloseButtonProps): JSX.Element => (
  <CloseWrapper onClick={onClick}>
    <Cross
      color={theme.colors.lightBlue}
      style={{ cursor: 'pointer' }}
      size={12}
    />
  </CloseWrapper>
);

export default CloseButton;
