import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const loading = (r: IInitialState) => r.loading;
export const isLoadingSelector = createSelector(loading, r => r.isLoading);
export const isTabLoadingSelector = createSelector(
  loading,
  r => r.isTabLoading
);
export const isPageLoadingSelector = createSelector(
  loading,
  r => r.isPageLoading
);
export const isFilesLoadingSelector = createSelector(
  loading,
  r => r.isFilesLoading
);
