import { SVGProps } from 'react';
import theme from 'styles/theme';

export const NextArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={13}
    fill={theme.colors.lightBlue}
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.5 0L5.35167 1.14833L9.89083 5.6875H0V7.3125H9.89083L5.35167 11.8517L6.5 13L13 6.5L6.5 0Z" />
  </svg>
);
