import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  createEmailTemplatePromiseCreator,
  updateEmailTemplatePromiseCreator,
} from 'store/admin';
import { locationsSelector } from 'store/predefined';
import { yupResolver } from '@hookform/resolvers/yup';
import Placeholders from '../Placeholders/Placeholders';
import {
  BUTTON_TYPES,
  Button,
  Error,
  Input,
  SortBySelect,
} from 'components/common';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useActionsRoutines } from 'hooks';
import { EmailTemplatesSchema } from 'validation';
import {
  ButtonsWrap,
  EditorLabel,
  FormWrapper,
  LocationSelectContainer,
  Title,
} from './styles';
import { IEmailTemplate } from 'types';

interface IEmailTemplateContainer {
  item: IEmailTemplate;
  isOpen: boolean;
  onCloseModal: ({
    id,
    title,
    body,
  }?: {
    id?: string;
    title?: string;
    body?: string;
  }) => void;
  isTemplateModal?: boolean;
}

type bodyType = {
  title: string;
  body: string;
  locationId?: string;
};
const EmailTemplateContainerComponent = ({
  item,
  isOpen,
  onCloseModal,
  isTemplateModal,
}: IEmailTemplateContainer) => {
  const [locationId, setLocation] = useState(item?.locationId);
  const [isTitleTouched, setIsTitleTouched] = useState(false);
  const [isBodyTouched, setIsBodyTouched] = useState(false);
  const locations = useSelector(locationsSelector);
  const editEmailTemplate = useActionsRoutines(
    updateEmailTemplatePromiseCreator
  );
  const createEmailTemplate = useActionsRoutines(
    createEmailTemplatePromiseCreator
  );

  const form = useForm<{ title: string; body: string; locationId?: string }>({
    resolver: yupResolver(EmailTemplatesSchema),
    defaultValues: {
      title: item?.title,
      body: item?.body,
    },
    shouldUnregister: true,
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isSaving, setIsSaving] = useState(false);
  const { setValue, register, trigger, reset } = form;
  const isEdit = useMemo(() => !!item, [item]);
  const title = useMemo(
    () => (isEdit ? 'Edit template' : 'Create new template'),
    [isEdit]
  );
  const locationsOptions = useMemo(
    () => [{ value: undefined, label: 'All locations' }, ...locations],
    [locations]
  );

  const handleSave = useCallback(
    formData => {
      if (isTemplateModal) {
        onCloseModal({
          id: item?._id,
          title: formData?.title,
          body: formData?.body,
        });
      } else {
        const request = isEdit ? editEmailTemplate : createEmailTemplate;
        const body: bodyType = {
          title: formData?.title,
          body: formData?.body,
        };

        if (locationId) body.locationId = locationId;

        const params = isEdit ? { id: item?._id, body } : { body };

        setIsSaving(true);
        request(params)
          .then(() => onCloseModal())
          .finally(() => setIsSaving(false));
      }
    },
    [
      isEdit,
      isTemplateModal,
      item,
      locationId,
      createEmailTemplate,
      editEmailTemplate,
      onCloseModal,
    ]
  );

  useEffect(() => {
    register('title');
    register('body');

    setValue('title', item?.title);

    if (!isTemplateModal) {
      register('locationId');
      setLocation(item?.locationId);
      setValue('locationId', locationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, item, isTemplateModal, register, setValue]);

  useEffect(() => {
    if (isEdit) {
      setValue('body', item?.body);
      const blocksFromHTML = convertFromHTML(item?.body);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    } else {
      setEditorState(EditorState.createEmpty());
      setValue('body', '');
    }
  }, [isOpen, isEdit, item, setValue]);

  useEffect(() => {
    if (isBodyTouched) void trigger('body');
  }, [isBodyTouched, trigger]);

  useEffect(() => {
    if (isTitleTouched) {
      void trigger('title').catch(error => console.error(error));
    }
  }, [isTitleTouched, trigger]);

  const onEditorStateChange = useCallback(
    newEditorState => {
      const isEmpty =
        newEditorState.getCurrentContent().getPlainText().trim().length < 1;

      if (!isEmpty) {
        const htmlJSON = draftToHtml(
          convertToRaw(newEditorState.getCurrentContent())
        );

        setValue('body', htmlJSON);
      } else {
        setValue('body', '');
      }

      // eslint-disable-next-line no-underscore-dangle
      if (newEditorState?._immutable?.lastChangeType) setIsBodyTouched(true);

      setEditorState(newEditorState);
    },
    [setValue]
  );

  const onEmailTitleChange = useCallback(
    e => {
      setValue('title', e.target.value);
      setIsTitleTouched(true);
    },
    [setValue]
  );

  const handleCloseModal = useCallback(() => {
    reset();
    onCloseModal();
  }, [reset, onCloseModal]);

  const handlePreventOuterFormSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleSetLocation = useCallback(
    location => {
      setLocation(location);
      setValue('locationId', location);
    },
    [setValue]
  );

  return (
    <FormProvider {...form}>
      <FormWrapper onSubmit={handlePreventOuterFormSubmit}>
        <form onSubmit={form.handleSubmit(handleSave)}>
          <Title>
            {title}
            {!isTemplateModal && (
              <LocationSelectContainer>
                <SortBySelect
                  options={locationsOptions}
                  showSearch={false}
                  onChange={handleSetLocation}
                  placeholder="All locations"
                  autoClearSearchValue={true}
                  value={locationId}
                />
              </LocationSelectContainer>
            )}
          </Title>
          <Input
            label="Email title"
            defaultValue={item?.title}
            onChange={onEmailTitleChange}
            error={
              form?.formState?.errors &&
              form?.formState?.errors.title &&
              (form?.formState?.errors.title.message as any)
            }
          />
          <EditorLabel>Email description</EditorLabel>
          <Editor
            toolbar={{ options: ['inline', 'colorPicker', 'link'] }}
            editorState={editorState}
            toolbarClassName="templates-editor-toolbar"
            wrapperClassName="templatesEditorWrapper"
            editorClassName="emailTemplateEditor"
            onEditorStateChange={onEditorStateChange}
            toolbarCustomButtons={[<Placeholders />]}
          />
          <Error>
            {form?.formState?.errors &&
              form?.formState?.errors.body &&
              form?.formState?.errors.body.message}
          </Error>
          <ButtonsWrap>
            <Button
              kind={BUTTON_TYPES.SECONDARY}
              onClick={handleCloseModal}
              label="Cancel"
              height="43px"
              width="186px"
            />
            <Button
              kind={BUTTON_TYPES.DEFAULT}
              loading={isSaving}
              label="Save"
              height="43px"
              htmlType="submit"
              width="186px"
            />
          </ButtonsWrap>
        </form>
      </FormWrapper>
    </FormProvider>
  );
};

export const EmailTemplateContainer = memo(
  EmailTemplateContainerComponent,
  isEqual
);
