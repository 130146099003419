import styled, { css } from 'styled-components';
import moment from 'moment';
// components
import { DatePicker } from 'antd';

export const DatePickerStyled = styled(DatePicker)<{
  [key: string]: any;
}>`
  margin-bottom: ${({ widthMarginBottom }) => widthMarginBottom && 19}px;
  padding: 5px;
  height: ${({ height }) => `${height || 30}px`};
  width: ${({ width }) => width && `${width}px`};

  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-radius: 6px;
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }
  input {
    color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.lightBlue};
  }
  &.ant-picker-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  .ant-picker-active-bar {
    background-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  ${props =>
    (props.defaultValue || props.value) &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `};
`;

export const PickerInput = styled.div`
  z-index: 2;
`;

export const MobileWrapper = styled.div`
  position: relative;
`;

export const DatePickerMobileStyled = styled.input<{
  widthMarginBottom?: boolean;
  disabledDate: (value: moment.Moment) => boolean;
}>`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }
  input {
    color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.lightBlue};
  }
  ${props =>
    (props.defaultValue || props.value) &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `};
`;
