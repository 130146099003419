export const Archive = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" />
    <path
      d="M14.853 6.646L13.3535 5.1465C13.26 5.0525 13.133 5 13 5H7C6.867 5 6.74 5.0525 6.6465 5.1465L5.147 6.646C5.056 6.7365 5 6.8615 5 7V13.5C5 14.0515 5.4485 14.5 6 14.5H14C14.5515 14.5 15 14.0515 15 13.5V7C15 6.8615 14.944 6.7365 14.853 6.646ZM7.207 6H12.793L13.293 6.5H6.707L7.207 6ZM6 13.5V7.5H14L14.001 13.5H6Z"
      fill="#172B4D"
    />
    <path d="M11 8.5H9V10H7.5L10 12.5L12.5 10H11V8.5Z" fill="#172B4D" />
  </svg>
);
