import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* recurringTasksSaga() {
  yield all([
    fork(W.watchGetRecurringTasks),
    fork(W.watchPostRecurringTask),
    fork(W.watchDeleteRecurringTask),
    fork(W.watchPutRecurringTask),
    fork(W.watchGetRecurringTaskById),
  ]);
}
