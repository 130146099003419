import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* sharingSaga() {
  yield all([
    fork(W.watchGetToken),
    fork(W.watchDeleteToken),
    fork(W.watchSendLink),
    fork(W.watchGetSystemNotifications),
    fork(W.watchMarkSystemNotificationAsRead),
    fork(W.watchMarkSystemNotificationAsUnread),
    fork(W.watchGetSystemNotificationUnreadCount),
    fork(W.watchDownloadPdf),
    fork(W.watchDeleteSystemNotification),
    fork(W.watchMarkAllSystemNotificationAsRead),
    fork(W.watchEnsureSyncNotifications),
  ]);
}
