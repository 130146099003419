import { call, put, select } from 'redux-saga/effects';
// redux
import { userSelector } from 'store/auth';
import { ensureCompany } from 'store/company';
import * as Actions from 'store/predefined';
import { isAdminSelector, isProjectManagerSelector } from './../auth/selectors';
// constants
import Api from 'utils/api';
import {
  CreateLocationDto,
  FileUploadResDto,
  IEnsureDeleteLocationPayload,
  IEnsureDeleteMapPayload,
  IEnsurePatchLocationPayload,
  IEnsurePutLocationPayload,
  IEnsureUploadMapByLocationPayload,
  IEnsureUploadMapPayload,
  LocationDto,
} from 'types';

export function* ensureRoles() {
  try {
    const data = yield call(Api.getRoles);
    yield put(Actions.roles.success(data));
  } catch (err) {
    yield put(Actions.roles.failure(err));
  }
}

export interface IEnsureLocationsParams {
  sort: string;
  userId: string | null;
}

export function* ensureLocations() {
  try {
    const { _id } = yield select(userSelector);
    const isAdmin = yield select(isAdminSelector);
    const isProjectManager = yield select(isProjectManagerSelector);

    const showAllLocations = !isAdmin && !isProjectManager;
    const params: IEnsureLocationsParams = {
      sort: 'name',
      userId: showAllLocations ? _id : null,
    };
    const data: LocationDto[] = yield call(Api.locations.getLocations, {
      params,
    });
    yield put(Actions.locations.success(data));
  } catch (err) {
    yield put(Actions.locations.failure(err));
  }
}

export function* ensureCheckLocationHasAssignedData({
  payload,
}: {
  payload: string;
  type: typeof Actions.checkLocationHasAssignedData.TRIGGER;
}) {
  try {
    const data = yield call(Api.locations.checkAssignedData, payload);
    yield put(Actions.checkLocationHasAssignedData.success(data));
  } catch (err) {
    yield put(Actions.checkLocationHasAssignedData.failure(err));
  }
}

export function* ensureGetLocation({
  payload,
}: {
  payload: string;
  type: typeof Actions.getLocation.TRIGGER;
}) {
  try {
    const data = yield call(Api.locations.getLocation, payload);
    yield put(Actions.getLocation.success(data));
  } catch (err) {
    yield put(Actions.getLocation.failure(err));
  }
}

export function* ensurePostLocation({
  payload,
}: {
  payload: CreateLocationDto;
  type: typeof Actions.postLocation.TRIGGER;
}) {
  try {
    const data: LocationDto = yield call(Api.locations.postLocation, payload);
    yield call(ensureLocations);
    yield call(ensureCompany);
    yield put(Actions.postLocation.success(data));
  } catch (err) {
    yield put(Actions.postLocation.failure(err));
  }
}

export function* ensurePutLocation({
  payload,
}: {
  payload: IEnsurePutLocationPayload;
  type: typeof Actions.putLocation.TRIGGER;
}) {
  try {
    const data: LocationDto = yield call(Api.locations.putLocation, payload);
    yield call(ensureLocations);
    yield put(Actions.putLocation.success(data));
  } catch (err) {
    yield put(Actions.putLocation.failure(err));
  }
}

export function* ensurePatchLocation({
  payload,
}: {
  payload: IEnsurePatchLocationPayload;
  type: typeof Actions.patchLocation.TRIGGER;
}) {
  try {
    const data: LocationDto = yield call(Api.locations.patchLocation, payload);

    yield put(Actions.patchLocation.success(data));
  } catch (err) {
    yield put(Actions.patchLocation.failure(err));
  }
}

export function* ensureDeleteLocation({
  payload,
}: {
  payload: IEnsureDeleteLocationPayload;
  type: typeof Actions.deleteLocation.TRIGGER;
}) {
  try {
    yield call(Api.locations.deleteLocation, payload);
    yield call(ensureLocations);
    yield put(Actions.deleteLocation.success());
  } catch (err) {
    yield put(Actions.deleteLocation.failure(err));
  }
}

export function* ensureUploadMapByLocation({
  payload,
}: {
  payload: IEnsureUploadMapByLocationPayload;
  type: typeof Actions.uploadMapByLocation.TRIGGER;
}) {
  try {
    const formData = new FormData();
    formData.append('file', payload.file);
    const requestData = {
      id: payload.id,
      file: formData,
    };

    const data: FileUploadResDto = yield call(
      Api.locations.uploadMapByLocation,
      requestData
    );
    yield call(ensureLocations);
    yield put(Actions.uploadMapByLocation.success(data));
  } catch (err) {
    yield put(Actions.uploadMapByLocation.failure(err));
  }
}

export function* ensureUploadMap({
  payload,
}: {
  payload: IEnsureUploadMapPayload;
  type: typeof Actions.uploadMap.TRIGGER;
}) {
  try {
    const formData = new FormData();
    formData.append('file', payload.file);
    const data: FileUploadResDto = yield call(Api.locations.uploadMap, {
      file: formData,
    });
    yield put(Actions.uploadMap.success(data));
  } catch (err) {
    yield put(Actions.uploadMap.failure(err));
  }
}

export function* ensureDeleteMapByLocation({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteMapByLocation.TRIGGER;
}) {
  try {
    yield call(Api.locations.deleteMapByLocation, payload);
    yield call(ensureLocations);
    yield put(Actions.deleteMapByLocation.success());
  } catch (err) {
    yield put(Actions.deleteMapByLocation.failure(err));
  }
}

export function* ensureDeleteMap({
  payload,
}: {
  payload: IEnsureDeleteMapPayload;
  type: typeof Actions.deleteMap.TRIGGER;
}) {
  try {
    const params = {
      path: payload.filePath,
    };
    const data = yield call(Api.locations.deleteMap, { params });
    yield put(Actions.deleteMap.success(data));
  } catch (err) {
    yield put(Actions.deleteMap.failure(err));
  }
}
