import styled from 'styled-components';
import { Divider, List } from 'antd';

export const TaskWrap = styled.div`
  margin: 12px 0 8px;
`;

export const TasksTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 6px;
`;

export const ListStyled = styled(List)`
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
    display: flex;
    flex-direction: column;
  }
`;

export const DividerItem = styled(Divider)`
  margin: 10px 0 16px;
`;

export const ContainerWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
