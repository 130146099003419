import styled from 'styled-components';

const defaultMaxWidth = 490;

export const MobileMarginTopWrap = styled.div<{
  maxWidth?: number;
}>`
  @media (max-width: ${({ maxWidth }) => maxWidth || defaultMaxWidth}px) {
    margin-top: 5px;
  }
`;
