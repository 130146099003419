import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { AssignErrorResponse, CloneDataPayload, ProjectViewMode } from 'types';

export const ganttDates = createAction<any>('dashboard/ganttDates');
export const deleteProjectCover = createAction<any>(
  'dashboard/deleteProjectCover'
);
export const ganttWeekView = createAction<boolean>('dashboard/ganttWeekView');
export const projects = createRoutine('dashboard/projects');
export const removeProjects = createAction('dashboard/removeProjects');

export const projectsPromiseCreator = promisifyRoutine(projects);
export const setProjectFilesLoader = createAction<boolean>(
  'dashboard/setProjectFilesLoader'
);
export const project = createRoutine('dashboard/project');
export const setProjectsContainerHeight = createAction(
  'dashboard/projectsContainerHeight'
);
export const setMinProjectsContainerHeight = createAction(
  'dashboard/minProjectsContainerHeight'
);
export const projectPromiseCreator = promisifyRoutine(project);
export const projectsSortBy = createRoutine('dashboard/projectsSortBy');
export const containerSizesFilter = createRoutine(
  'dashboard/containerSizesFilter'
);
export const containerSkusFilter = createRoutine('dashboard/skusFilter');
// putch Projects
export const partialProjectUpdate = createRoutine(
  'dashboard/partialProjectUpdate'
);
export const partialProjectUpdatePromiseCreator =
  promisifyRoutine(partialProjectUpdate);

export const updateSubtaskChangelog = createAction(
  'dashboard/updateSubtaskChangelog'
);
export const invoicesSearch = createRoutine('dashboard/invoicesSearch');
export const invoicesSearchPromiseCreator = promisifyRoutine(invoicesSearch);
// get Gantt
export const gantt = createRoutine('dashboard/gantt');
export const setGanttItemsAction = createAction('dashboard/setGanttItems');
export const setGanttGroupsAction = createAction('dashboard/setGanttGroups');

export const ganttPromiseCreator = promisifyRoutine(gantt);
// post Project
export const postProject = createRoutine('dashboard/postProject');
export const postProjectPromiseCreator = promisifyRoutine(postProject);
// duplicate Project
export const postDuplicateProject = createRoutine('dashboard/postDuplicateProject');
export const postDuplicateProjectPromiseCreator = promisifyRoutine(
  postDuplicateProject
);
// put Project
export const putProject = createRoutine('dashboard/putProject');
export const putProjectPromiseCreator = promisifyRoutine(putProject);
// weight
export const patchProjectWeight = createRoutine('dashboard/patchProjectWeight');
export const patchProjectWeightPromiseCreator =
  promisifyRoutine(patchProjectWeight);

export const checklistSocketMarkAsCompleted = createRoutine(
  'dashboard/checklistSocketMarkAsCompleted'
);
export const checklistSocketMarkAsUncompleted = createRoutine(
  'dashboard/checklistSocketMarkAsUncompleted'
);

export const checklistStandaloneSocketMarkAsCompleted = createRoutine(
  'dashboard/checklistStandaloneSocketMarkAsCompleted'
);
export const checklistStandaloneSocketMarkAsUncompleted = createRoutine(
  'dashboard/checklistStandaloneSocketMarkAsUncompleted'
);

export const checklistSearchItems = createRoutine(
  'dashboard/checklistSearchItems'
);
export const checklistSearchItemsPromiseCreator =
  promisifyRoutine(checklistSearchItems);

// attachments
export const addFileToProjectByDrop = createRoutine(
  'dashboard/addFileToProjectByDrop'
);
export const addFileToProjectByDropPromiseCreator = promisifyRoutine(
  addFileToProjectByDrop
);
export const deleteProjectFile = createRoutine('dashboard/deleteProjectFile');
export const deleteProjectFilePromiseCreator =
  promisifyRoutine(deleteProjectFile);

export const putAttachments = createRoutine('dashboard/putAttachments');
export const putAttachmentsPromiseCreator = promisifyRoutine(putAttachments);
export const putAttachmentsById = createRoutine('dashboard/putAttachmentsById');
export const putAttachmentsByIdPromiseCreator =
  promisifyRoutine(putAttachmentsById);
export const deleteAttachments = createRoutine('dashboard/deleteAttachments');
export const deleteAttachmentsPromiseCreator =
  promisifyRoutine(deleteAttachments);
export const putSubtasksAttachments = createRoutine(
  'dashboard/putSubtasksAttachments'
);
export const putSubtasksAttachmentsPromiseCreator = promisifyRoutine(
  putSubtasksAttachments
);
export const deleteSubtaskFilesById = createRoutine(
  'dashboard/deleteSubtaskFilesById'
);
export const deleteSubtaskFilesByIdPromiseCreator = promisifyRoutine(
  deleteSubtaskFilesById
);
export const deleteSubtaskFiles = createRoutine('dashboard/deleteSubtaskFiles');
export const deleteSubtaskFilesPromiseCreator =
  promisifyRoutine(deleteSubtaskFiles);

// files deleted during draft
export const filterSubtaskDraftFilesBuffer = createAction(
  'dashboard/filterSubtaskDraftFilesBuffer'
);
export const clearSubtaskDraftFilesBuffer = createAction(
  'dashboard/clearSubtaskDraftFilesBuffer'
);
export const insertSubtaskDraftFilesBuffer = createAction(
  'dashboard/insertSubtaskDraftFilesBuffer'
);

// files deleted during editing created form
export const insertSubtaskFilesBuffer = createAction(
  'dashboard/insertSubtaskFilesBuffer'
);
export const clearSubtaskFilesBuffer = createAction(
  'dashboard/clearSubtaskFilesBuffer'
);

// files deleted
export const insertSubtaskTemplateFilesBuffer = createAction(
  'dashboard/insertSubtaskTemplateFilesBuffer'
);
export const filtertSubtaskTemplateFilesBuffer = createAction(
  'dashboard/filtertSubtaskTemplateFilesBuffer'
);
export const clearSubtaskTemplateFilesBuffer = createAction(
  'dashboard/clearSubtaskTemplateFilesBuffer'
);

export const clearAllSubtaskFilesBuffers = createAction(
  'dashboard/clearAllSubtaskFilesBuffers'
);

// subtasks unread messages count
export const subtasksUnreadMessagesCount = createRoutine(
  'dashboard/subtasksUnreadMessagesCount'
);
export const subtasksUnreadMessagesCountPromiseCreator = promisifyRoutine(
  subtasksUnreadMessagesCount
);

// assignSubtask
export const assignSubtask = createRoutine('dashboard/assignSubtask');
export const assignSubtaskPromiseCreator = promisifyRoutine(assignSubtask);
// resizeSubtask
export const resizeSubtask = createRoutine('dashboard/resizeSubtask');
export const resizeSubtaskPromiseCreator = promisifyRoutine(resizeSubtask);
// unassignSubtask
export const unassignSubtask = createRoutine('dashboard/unassignSubtask');
export const unassignSubtaskPromiseCreator = promisifyRoutine(unassignSubtask);

// change subtask status flow
export const markSubtaskAsToDo = createRoutine('dashboard/markSubtaskAsToDo');
export const markSubtaskAsToDoPromiseCreator =
  promisifyRoutine(markSubtaskAsToDo);
export const markSubtaskAsDone = createRoutine('dashboard/markSubtaskAsDone');
export const markSubtaskAsDonePromiseCreator =
  promisifyRoutine(markSubtaskAsDone);
export const markSubtaskAsActive = createRoutine(
  'dashboard/markSubtaskAsActive'
);
export const markSubtaskAsActivePromiseCreator =
  promisifyRoutine(markSubtaskAsActive);
export const markSubtaskAsNotReady = createRoutine(
  'dashboard/markSubtaskAsNotReady'
);
export const markSubtaskAsNotReadyPromiseCreator = promisifyRoutine(
  markSubtaskAsNotReady
);
export const markSubtaskAsPaused = createRoutine(
  'dashboard/markSubtaskAsPaused'
);
export const markSubtaskAsPausedPromiseCreator =
  promisifyRoutine(markSubtaskAsPaused);
export const markSubtaskAsResumed = createRoutine(
  'dashboard/markSubtaskAsResumed'
);
export const markSubtaskAsResumedPromiseCreator =
  promisifyRoutine(markSubtaskAsResumed);

export const markSubtaskChecklistCompleted = createRoutine(
  'dashboard/markSubtaskChecklistCompleted'
);
export const markSubtaskChecklistCompletedPromiseCreator = promisifyRoutine(
  markSubtaskChecklistCompleted
);

export const markSubtaskChecklistUncompleted = createRoutine(
  'dashboard/markSubtaskChecklistUncompleted'
);
export const markSubtaskChecklistUncompletedPromiseCreator = promisifyRoutine(
  markSubtaskChecklistUncompleted
);

export const subtaskDetails = createRoutine('dashboard/subtaskDetails');
export const subtaskDetailsPromiseCreator = promisifyRoutine(subtaskDetails);

// subtask logs
export const subtaskLogItems = createRoutine('dashboard/subtaskLogItems');
export const subtaskLogItemsPromiseCreator = promisifyRoutine(subtaskLogItems);
export const updateSubtaskLogItem = createRoutine(
  'dashboard/updateSubtaskLogItem'
);
export const updateSubtaskLogItemPromiseCreator =
  promisifyRoutine(updateSubtaskLogItem);

// subtask result flow
export const resultSubtask = createRoutine('dashboard/resultSubtask');
export const resultSubtaskPromiseCreator = promisifyRoutine(resultSubtask);
export const approveSubtask = createRoutine('dashboard/approveSubtask');
export const approveSubtaskPromiseCreator = promisifyRoutine(approveSubtask);
export const rejectSubtask = createRoutine('dashboard/rejectSubtask');
export const rejectSubtaskPromiseCreator = promisifyRoutine(rejectSubtask);
// delegateSubtask
export const delegateSubtask = createRoutine('dashboard/delegateSubtask');
export const delegateSubtaskPromiseCreator = promisifyRoutine(delegateSubtask);
export const revokeSubtask = createRoutine('dashboard/revokeSubtask');
export const revokeSubtaskPromiseCreator = promisifyRoutine(revokeSubtask);
// subtask files
export const addFileToSubtaskByDrop = createRoutine(
  'dashboard/addFileToSubtaskByDrop'
);
export const addFileToSubtaskByDropPromiseCreator = promisifyRoutine(
  addFileToSubtaskByDrop
);
// draft
export const projectsAfterDraft = createAction('dashboard/projectsAfterDraft');
export const projectDraftData = createAction('dashboard/projectDraftData');
// standalone subtasks
export const clearStandaloneSubtask = createAction(
  'dashboard/clearStandaloneSubtask'
);
export const getStandaloneSubtask = createRoutine(
  'dashboard/getStandaloneSubtask'
);
export const getStandaloneSubtaskCreator =
  promisifyRoutine(getStandaloneSubtask);
export const postStandaloneSubtask = createRoutine(
  'dashboard/postStandaloneSubtask'
);
export const postStandaloneSubtaskCreator = promisifyRoutine(
  postStandaloneSubtask
);
export const putStandaloneSubtask = createRoutine(
  'dashboard/putStandaloneSubtask'
);
export const putStandaloneSubtaskCreator =
  promisifyRoutine(putStandaloneSubtask);
export const deleteStandaloneSubtask = createRoutine(
  'dashboard/deleteStandaloneSubtask'
);
export const deleteStandaloneSubtaskCreator = promisifyRoutine(
  deleteStandaloneSubtask
);
// excel
export const resourceExcel = createRoutine('dashboard/resourceExcel');
export const resourceExcelPromiseCreator = promisifyRoutine(resourceExcel);
// screenDistribution
export const setScreenDistribution = createRoutine(
  'dashboard/setScreenDistribution'
);

export const splitScreen = createAction('dashboard/splitScreen');

export const getTaskNotification = createRoutine(
  'dashboard/getTaskNotification'
);
export const getTaskNotificationCreator = promisifyRoutine(getTaskNotification);

export const createTaskNotification = createRoutine(
  'dashboard/createTaskNotification'
);
export const createTaskNotificationCreator = promisifyRoutine(
  createTaskNotification
);

export const updateTaskNotification = createRoutine(
  'dashboard/updateTaskNotification'
);
export const updateTaskNotificationCreator = promisifyRoutine(
  updateTaskNotification
);

export const deleteTaskNotification = createRoutine(
  'dashboard/deleteTaskNotification'
);
export const deleteTaskNotificationCreator = promisifyRoutine(
  deleteTaskNotification
);

// chat
export const openSubtaskChat = createRoutine('dashboard/openSubtaskChat');
export const closeSubtaskChat = createRoutine('dashboard/closeSubtaskChat');
export const getSubtaskMessages = createRoutine('dashboard/getSubtaskMessages');
export const clearSubtaskMessages = createRoutine(
  'dashboard/clearSubtaskMessages'
);
export const getSubtaskMessagesCreator = promisifyRoutine(getSubtaskMessages);
export const sendSubtaskMessage = createRoutine('dashboard/sendSubtaskMessage');
export const sendSubtaskMessageCreator = promisifyRoutine(sendSubtaskMessage);

export const sendMessageReply = createRoutine('dashboard/sendMessageReply');
export const sendMessageReplyPromiseCreator =
  promisifyRoutine(sendMessageReply);

export const deleteSubtaskMessages = createRoutine(
  'dashboard/deleteSubtaskMessages'
);
export const deleteSubtaskMessagesCreator = promisifyRoutine(
  deleteSubtaskMessages
);
export const deleteSubtaskMessage = createRoutine(
  'dashboard/deleteSubtaskMessage'
);
export const deleteSubtaskMessageCreator =
  promisifyRoutine(deleteSubtaskMessage);
export const updateSubtaskMessage = createRoutine(
  'dashboard/updateSubtaskMessage'
);
export const updateSubtaskMessageCreator =
  promisifyRoutine(updateSubtaskMessage);

export const deleteChatMessagesBySubtaskIds = createRoutine(
  'dashboard/deleteChatMessagesBySubtaskIds'
);

export const deleteChatMessagesBySubtaskIdsCreator = promisifyRoutine(
  deleteChatMessagesBySubtaskIds
);

export const markSubtaskMessagesAsSeen = createRoutine(
  'dashboard/markSubtaskMessagesAsSeen'
);

export const markSubtaskMessagesAsSeenPromiseCreator = promisifyRoutine(
  markSubtaskMessagesAsSeen
);

export const setCreateProjectModalOpen = createAction(
  'dashboard/setCreateProjectModalOpen'
);

export const setAssignConflictData = createAction<AssignErrorResponse>(
  'dashboard/setAssignConflictData'
);

export const clearAssignConflictData = createAction(
  'dashboard/clearAssignConflictData'
);

export const forceAssignResource = createRoutine(
  'dashboard/forceAssignResource'
);

export const forceAssignResourcePromiseCreator =
  promisifyRoutine(forceAssignResource);

export const setProjectViewMode = createAction<ProjectViewMode>(
  'dashboard/setProjectViewMode'
);

export const setCloneDataAction = createAction<CloneDataPayload>(
  'dashboard/setCloneData'
);
