import * as yup from 'yup';

const depotSettingsSchema = yup.object().shape({
  type: yup
    .string()
    .trim()
    .max(10, 'Must be at most 10 characters!')
    .required('Name is required!'),
  description: yup.string().trim(),
});

export default depotSettingsSchema;
