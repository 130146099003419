import { matchPath } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import queryString from 'query-string';

const ROUTES = [
  { path: '/login/:jwt' },
  { path: '/projects/:id/sharing/:jwt' },
  { path: '/subtasks/:id/sharing/:jwt' },
];

function matchRoute(locations) {
  for (const item of ROUTES) {
    const match = matchPath(locations.pathname, {
      exact: 'exact' in item ? (item as any).exact : true,
      strict: false,
      ...item,
    });
    if (match !== null) {
      return match;
    }
  }
  return null;
}

const routing = r => r.router;
export const location = createSelector(routing, r => r.location);
export const search = createSelector(location, r => r.search);
export const query = createSelector(search, s =>
  queryString.parse(s, { arrayFormat: 'bracket' })
);
export const stateRouter = createSelector(location, r => r.state);
export const matchRoutes = createSelector(location, matchRoute);
