import styled from 'styled-components';
import { Divider } from 'antd';

export const Title = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin: 15px 0 18px;
  text-align: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  max-width: 500px;
  margin-bottom: 18px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  padding-top: 10px;
`;

export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  padding: 12px 24px;
  border: 1px solid #dfe1e6;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  margin: 0 auto 18px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
export const CompanyLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
`;

export const DividerItem = styled(Divider)`
  margin: 31px 0 19px;
`;
