import BodyContainer from './BodyContainer';
import HeaderContainer from './HeaderContainer';
import { ContainerWrap } from './styles';
import { IChecklist, IProjectResponseDto, IProjectTaskDto } from 'types';

interface IViewContainerProps {
  item: IProjectTaskDto;
  index: number;
  isOpenInfo: boolean;
  setIsOpen?: (index: number) => void;
  isCompleted?: boolean;
  setIsOpenInfo?: (isOpen: boolean) => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  setShouldUpdate: (update: boolean) => void;
  notAvailableProject?: boolean;
  project?: IProjectResponseDto;
  toggleParent?: (visible: boolean) => void;
  editProject: ({
    id,
    data,
  }: {
    id: string;
    data: IProjectResponseDto;
  }) => Promise<void>;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  color: string;
  invoice: string;
  locationId: string;
}

const ViewContainer = ({
  index,
  isOpenInfo,
  setIsOpen,
  item,
  project,
  toggleParent,
  notAvailableProject,
  editProject,
  openCheckResult,
  setShouldUpdate,
  setIsOpenInfo,
  color,
  invoice,
  locationId,
  updateChecklist,
}: IViewContainerProps) => {
  const showCollapse = !!item.description || !!item.subtasks.length;
  return (
    <ContainerWrap>
      <HeaderContainer
        setIsOpen={setIsOpen}
        index={index}
        isOpenInfo={isOpenInfo}
        setIsOpenInfo={setIsOpenInfo}
        item={item}
        showCollapse={showCollapse}
        notAvailableProject={notAvailableProject}
      />
      <BodyContainer
        isOpenInfo={isOpenInfo}
        item={item}
        notAvailableProject={notAvailableProject}
        project={project}
        toggleParent={toggleParent}
        editProject={editProject}
        openCheckResult={openCheckResult}
        setShouldUpdate={setShouldUpdate}
        color={color}
        invoice={invoice}
        locationId={locationId}
        updateChecklist={updateChecklist}
      />
    </ContainerWrap>
  );
};

export default ViewContainer;
