import { ChangeEvent, useMemo } from 'react';
// components
import { DivideTasks, WorkOrders } from '../../Inbound';
// constants
import Modal from 'components/Modal';
import CreateProject from 'pages/Dashboard/Project/CreateProject';
import ProjectView from 'pages/Dashboard/Project/ProjectView';
import { IAttachmentInfo, InboundModalTypesEnum } from 'types';

interface IInboundModalProps {
  openInboundModalType?: string;
  closeInboundModal: () => void;
  isOpenInboundModal: boolean;
  isLoadingModal: boolean;
  getWorkOrders: () => void;
  openDivideTasks: (id: string) => void;
  openCreateModal: (id: string) => void;
  isEditModal: boolean;
  onCloseEditModal: () => void;
  openInboundModal?: (type: InboundModalTypesEnum | null) => void;
  files?: Partial<IAttachmentInfo>[];
  setFiles: (files: FormData[]) => void;
  setCloseModal: (close: boolean) => void;
  openInboundEdit: (visible: boolean) => void;
  setCloneCount: (amount: number) => void;
  toggleModal: (visible: boolean) => void;
  closeDraftModal: () => void;
  closeInboundCreateModal: () => void;
  isClone?: boolean;
}

const InboundModal = ({
  openInboundModalType,
  closeInboundModal,
  isOpenInboundModal,
  isLoadingModal,
  getWorkOrders,
  openDivideTasks,
  openCreateModal,
  isEditModal,
  onCloseEditModal,
  openInboundModal,
  files,
  setFiles,
  setCloseModal,
  openInboundEdit,
  setCloneCount,
  toggleModal,
  closeInboundCreateModal,
  isClone,
  closeDraftModal,
}: IInboundModalProps) => {
  const modalWidth = useMemo(() => {
    switch (openInboundModalType) {
      case InboundModalTypesEnum.CREATE:
      case InboundModalTypesEnum.DETAILS:
        return 760;
      case InboundModalTypesEnum.WORK_ORDERS:
        return 1200;
      case InboundModalTypesEnum.DIVIDE_TASKS:
        return 745;
      default:
        return 760;
    }
  }, [openInboundModalType]);

  const modalPadding = useMemo(() => {
    switch (openInboundModalType) {
      case InboundModalTypesEnum.DETAILS:
        return '20px';
      default:
        return '30px 47px 25px';
    }
  }, [openInboundModalType]);

  const renderContent = useMemo(() => {
    switch (openInboundModalType) {
      case InboundModalTypesEnum.DIVIDE_TASKS:
        return <DivideTasks goBack={openInboundModal} />;
      case InboundModalTypesEnum.CREATE:
        return (
          <CreateProject
            onSubmit={isEditModal ? onCloseEditModal : closeInboundCreateModal}
            files={files}
            setFiles={setFiles}
            setCloseParent={setCloseModal}
            isCreateFromInbound={!isEditModal}
            isEdit={isEditModal}
            isClone={isClone}
            closeDraftModal={closeDraftModal}
          />
        );
      case InboundModalTypesEnum.DETAILS:
        return (
          <ProjectView
            closeModal={isEditModal ? onCloseEditModal : closeInboundModal}
            openEdit={openInboundEdit}
            toggleParent={toggleModal}
            setCloneCount={setCloneCount}
          />
        );

      default:
        return (
          <WorkOrders
            getWorkOrders={getWorkOrders}
            openDivideTasks={openDivideTasks}
            openCreateModal={openCreateModal}
          />
        );
    }
  }, [
    closeDraftModal,
    closeInboundCreateModal,
    closeInboundModal,
    files,
    getWorkOrders,
    isClone,
    isEditModal,
    onCloseEditModal,
    openCreateModal,
    openDivideTasks,
    openInboundEdit,
    openInboundModal,
    openInboundModalType,
    setCloseModal,
    setFiles,
    toggleModal,
  ]);
  return (
    <Modal
      onCancel={closeInboundModal}
      visible={isOpenInboundModal}
      width={modalWidth}
      padding={modalPadding}
      loading={isLoadingModal}
    >
      {renderContent}
    </Modal>
  );
};

export default InboundModal;
