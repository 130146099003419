import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import * as A from './actions';
import * as E from './workers';

export function* watchProjects() {
  yield fork(E.ensureProjects, {
    type: A.projects.TRIGGER,
  });
  yield takeLatest(A.projects.TRIGGER, E.ensureProjects);
}

export function* watchGantt() {
  yield fork(E.ensureGantt, {
    type: A.gantt.TRIGGER,
  });
  yield takeLatest(A.gantt.TRIGGER, E.ensureGantt);
}

export function* watchProject() {
  yield takeLatest(A.project.TRIGGER, E.ensureProject);
}

export function* watchPostProject() {
  yield takeLatest(A.postProject.TRIGGER, E.ensurePostProject);
}

export function* watchPostDuplicateProject() {
  yield takeLatest(
    A.postDuplicateProject.TRIGGER, E.ensurePostDuplicateProject
  );
}

export function* watchPutProject() {
  yield takeLatest(A.putProject.TRIGGER, E.ensurePutProject);
}

export function* watchPatchProjectWeight() {
  yield takeLatest(A.patchProjectWeight.TRIGGER, E.ensurePatchProjectWeight);
}

export function* watchPutAttachments() {
  yield takeLatest(A.putAttachments.TRIGGER, E.ensurePutAttachments);
}

export function* watchPutAttachmentsById() {
  yield takeLatest(A.putAttachmentsById.TRIGGER, E.ensurePutAttachmentsById);
}

export function* watchDeleteAttachments() {
  yield takeLatest(A.deleteAttachments.TRIGGER, E.ensureDeleteAttachments);
}

export function* watchAssignSubtask() {
  yield takeEvery(A.assignSubtask.TRIGGER, E.ensureAssignSubtask);
}

export function* watchResizeSubtask() {
  yield takeLatest(A.resizeSubtask.TRIGGER, E.ensureResizeSubtask);
}

export function* watchUnassignSubtask() {
  yield takeLatest(A.unassignSubtask.TRIGGER, E.ensureUnassignSubtask);
}

export function* watchResultSubtask() {
  yield takeLatest(A.resultSubtask.TRIGGER, E.ensureResultSubtask);
}

export function* watchApproveSubtask() {
  yield takeLatest(A.approveSubtask.TRIGGER, E.ensureApproveSubtask);
}

export function* watchRejectSubtask() {
  yield takeLatest(A.rejectSubtask.TRIGGER, E.ensureRejectSubtask);
}

export function* watchSubtaskDetails() {
  yield takeLatest(A.subtaskDetails.TRIGGER, E.ensureSubtask);
}

export function* watchSubtaskLogItems() {
  yield takeLatest(A.subtaskLogItems.TRIGGER, E.ensureSubtaskLogItems);
}

export function* watchUpdateSubtaskLogItem() {
  yield takeLatest(
    A.updateSubtaskLogItem.TRIGGER,
    E.ensureUpdateSubtaskLogItem
  );
}

export function* watchPartialProjectUpdate() {
  yield takeLatest(
    A.partialProjectUpdate.TRIGGER,
    E.ensurePartialProjectUpdate
  );
}

export function* watchAddFileToProjectByDrop() {
  yield takeLatest(
    A.addFileToProjectByDrop.TRIGGER,
    E.ensureAddFileToProjectByDrop
  );
}

export function* watchAddFileToSubtaskByDrop() {
  yield takeLatest(
    A.addFileToSubtaskByDrop.TRIGGER,
    E.ensureAddFileToSubtaskByDrop
  );
}

export function* watchMarkSubtaskAsToDo() {
  yield takeLatest(A.markSubtaskAsToDo.TRIGGER, E.ensureMarkSubtaskAsToDo);
}

export function* watchMarkSubtaskAsDone() {
  yield takeLatest(A.markSubtaskAsDone.TRIGGER, E.ensureMarkSubtaskAsDone);
}

export function* watchMarkSubtaskAsActive() {
  yield takeLatest(A.markSubtaskAsActive.TRIGGER, E.ensureMarkSubtaskAsActive);
}

export function* watchMarkSubtaskAsPaused() {
  yield takeLatest(A.markSubtaskAsPaused.TRIGGER, E.ensureMarkSubtaskAsPaused);
}

export function* watchMarkSubtaskAsResumed() {
  yield takeLatest(
    A.markSubtaskAsResumed.TRIGGER,
    E.ensureMarkSubtaskAsResumed
  );
}

export function* watchPutSubtasksAttachments() {
  yield takeLatest(
    A.putSubtasksAttachments.TRIGGER,
    E.ensurePutSubtasksAttachments
  );
}

export function* watchDeleteSubtaskFilesById() {
  yield takeLatest(
    A.deleteSubtaskFilesById.TRIGGER,
    E.ensureDeleteSubtaskFilesById
  );
}

export function* watchDeleteSubtaskFiles() {
  yield takeLatest(A.deleteSubtaskFiles.TRIGGER, E.ensureDeleteSubtaskFiles);
}

export function* watchMarkSubtaskAsNotReady() {
  yield takeLatest(
    A.markSubtaskAsNotReady.TRIGGER,
    E.ensureMarkSubtaskAsNotReady
  );
}
export function* watchStandaloneSubtask() {
  yield takeLatest(
    A.postStandaloneSubtask.TRIGGER,
    E.ensurePostStandaloneSubtask
  );
  yield takeLatest(
    A.deleteStandaloneSubtask.TRIGGER,
    E.ensureDeleteStandaloneSubtask
  );
  yield takeLatest(
    A.putStandaloneSubtask.TRIGGER,
    E.ensurePutStandaloneSubtask
  );
  yield takeLatest(
    A.getStandaloneSubtask.TRIGGER,
    E.ensureGetStandaloneSubtask
  );
}

export function* watchDeleteProjectFile() {
  yield takeLatest(A.deleteProjectFile.TRIGGER, E.ensureDeleteProjectFile);
}

export function* watchResourceExcel() {
  yield takeLatest(A.resourceExcel.TRIGGER, E.ensureResourceExcel);
}

export function* watchSetScreenDistribution() {
  yield takeLatest(
    A.setScreenDistribution.TRIGGER,
    E.ensureSetScreenDistribution
  );
}

export function* watchInvoicesSearch() {
  yield takeLatest(A.invoicesSearch.TRIGGER, E.ensureInvoicesSearch);
}

export function* watchDelegateSubtask() {
  yield takeLatest(A.delegateSubtask.TRIGGER, E.ensureDelegateSubtask);
}

export function* watchRevokeSubtask() {
  yield takeLatest(A.revokeSubtask.TRIGGER, E.ensureRevokeSubtask);
}

export function* watchGetTaskNotification() {
  yield takeLatest(A.getTaskNotification.TRIGGER, E.ensureGetTaskNotification);
}

export function* watchCreateTaskNotification() {
  yield takeLatest(
    A.createTaskNotification.TRIGGER,
    E.ensureCreateTaskNotification
  );
}

export function* watchUpdateTaskNotification() {
  yield takeLatest(
    A.updateTaskNotification.TRIGGER,
    E.ensureUpdateTaskNotification
  );
}

export function* watchDeleteTaskNotification() {
  yield takeLatest(
    A.deleteTaskNotification.TRIGGER,
    E.ensureDeleteTaskNotification
  );
}

export function* watchGetSubtaskMessages() {
  yield takeLatest(A.getSubtaskMessages.TRIGGER, E.ensureGetSubtaskMessages);
}

export function* watchSendMessageReply() {
  yield takeLatest(A.sendMessageReply.TRIGGER, E.ensureSendMessageReply);
}

export function* watchSendSubtaskMessage() {
  yield takeLatest(A.sendSubtaskMessage.TRIGGER, E.ensureSendSubtaskMessage);
}
export function* watchDeleteSubtaskMessages() {
  yield takeLatest(
    A.deleteSubtaskMessages.TRIGGER,
    E.ensureDeleteSubtaskMessages
  );
}
export function* watchDeleteSubtaskMessage() {
  yield takeLatest(
    A.deleteSubtaskMessage.TRIGGER,
    E.ensureDeleteSubtaskMessage
  );
}
export function* watchUpdateSubtaskMessage() {
  yield takeLatest(
    A.updateSubtaskMessage.TRIGGER,
    E.ensureUpdateSubtaskMessage
  );
}

export function* watchMarkChecklistCompleted() {
  yield takeLatest(
    A.markSubtaskChecklistCompleted.TRIGGER,
    E.ensureMarkChecklistCompleted
  );
}
export function* watchMarkChecklistUncompleted() {
  yield takeLatest(
    A.markSubtaskChecklistUncompleted.TRIGGER,
    E.ensureMarkChecklistUncompleted
  );
}

export function* watchSearchChecklistItems() {
  yield takeLatest(
    A.checklistSearchItems.TRIGGER,
    E.ensureSearchChecklistItems
  );
}

export function* watchDeleteChatMessagesBySubtaskIds() {
  yield takeLatest(
    A.deleteChatMessagesBySubtaskIds.TRIGGER,
    E.ensureDeleteChatMessagesBySubtaskIds
  );
}

export function* watchSubtasksUnreadMessagesCount() {
  yield takeLatest(
    A.subtasksUnreadMessagesCount.TRIGGER,
    E.ensureSubtasksUnreadMessagesCount
  );
}

export function* WatchMarkSubtaskMessagesAsSeen() {
  yield takeLatest(
    A.markSubtaskMessagesAsSeen.TRIGGER,
    E.ensureMarkSubtaskMessagesAsSeen
  );
}

export function* watchForceAssignResource() {
  yield takeLatest(A.forceAssignResource.TRIGGER, E.ensureForceAssignResource);
}
