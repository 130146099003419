import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrap = styled.div<{
  isLast?: boolean;
  $isMessageScrollIntoView?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 13px 7px 10px 7px;
  border: 1px solid transparent;
  border-bottom: 1px solid
    ${({ theme, isLast }) => (isLast ? theme.colors.grayLight : 'none')};

  ${({ $isMessageScrollIntoView }) =>
    $isMessageScrollIntoView &&
    css`
      @keyframes blink {
        50% {
          border-radius: 6px;
          background-color: ${theme.colors.blinkBg};
        }
      }
      & {
        animation: blink 0.9s ease-in-out 2 alternate;
      }
    `}
`;

const infoStyles = css`
  margin-left: 12px;
  line-height: 12px;
`;

export const ResourceName = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightBlue};
  overflow: hidden;
  font-style: normal;
  line-height: 18.5px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  max-width: 180px;
  white-space: pre-line;
  /* margin-bottom: 7px; */
  line-height: 18.5px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const CreatedAt = styled.div`
  font-size: 11px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const MessageWrapper = styled.div<{
  $isYou?: boolean;
}>`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ $isYou }) =>
    $isYou
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

export const MessageInfo = styled.div<{
  $isYou?: boolean;
}>`
  ${infoStyles};
  ${({ $isYou }) =>
    $isYou
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `}
  margin-right: 10px;
`;

export const MessageWrap = styled.div<{
  $isYou?: boolean;
}>`
  display: flex;
  ${({ $isYou }) =>
    $isYou
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`;

export const ReplyWrapper = styled.div`
  cursor: pointer;
  background: ${theme.colors.grayLight};
  padding: 8px;
  width: 180px;
  flex: 1;
  display: flex;
  margin-bottom: 4px;
`;

export const ReplyAuthor = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.lightBlue};
  margin-bottom: 2px;
`;

export const ReplyContent = styled.div`
  text-align: left;
`;

export const ReplyText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  max-width: 150px;
  color: ${theme.colors.mainDarkBlue};
`;

export const ReplyIcon = styled.div`
  width: 11px;
  margin-right: 5px;
`;
