import { SVGProps } from 'react';
import theme from 'styles/theme';

function ReplyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10540_19104)">
        <path
          d="M6.667 6V3.335L2 8.001l4.667 4.666V9.934c3.333 0 5.666 1.067 7.333 3.4-.667-3.333-2.667-6.667-7.333-7.333z"
          fill={theme.colors.primary}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10540_19104">
          <path fill={theme.colors.white} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReplyIcon;
