import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
// redux
import { divideTasksPromiseCreator, workOrderSelector } from 'store/inbound';
// utils
import { getCommonTasks, getContainerTasks, prepareData } from './helpers';
import DivideTable from './DivideTable';
// components
import Heading from './Heading';
import { Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
// styles
import { ButtonWrap, Wrap } from './styles';
import { InboundModalTypesEnum } from 'types';

interface IDivideTasksProps {
  goBack: (type?: InboundModalTypesEnum | null) => void;
}

export interface IValuesObject {
  [key: string]: string;
}

export const DivideTasks = ({ goBack }: IDivideTasksProps): JSX.Element => {
  const [values, setValues] = useState<IValuesObject>({});
  const [isLoading, setLoading] = useState(false);

  const divideTasksHandler = useActionsRoutines(divideTasksPromiseCreator);

  const data = useSelector(workOrderSelector);
  const { _id, tasks, files, ownerEmail } = data;

  const commonTasks = getCommonTasks(tasks);
  const containerTasks = getContainerTasks(tasks);

  const submit = useCallback(() => {
    setLoading(true);
    const newData = prepareData(containerTasks, commonTasks, values, data);
    divideTasksHandler({
      data: newData,
      id: _id,
    })
      .then(() => goBack())
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, [
    values,
    goBack,
    containerTasks,
    divideTasksHandler,
    _id,
    commonTasks,
    data,
  ]);

  const isDisabled = commonTasks.length > Object.keys(values).length;

  return (
    <Wrap>
      <Heading goBack={goBack} ownerEmail={ownerEmail} files={files} />
      <DivideTable
        containerTasks={containerTasks}
        commonTasks={commonTasks}
        values={values}
        setValues={setValues}
      />
      <ButtonWrap>
        <Button
          disabled={isDisabled}
          label="Save"
          onClick={submit}
          height="55px"
          width="362px"
          loading={isLoading}
        />
      </ButtonWrap>
    </Wrap>
  );
};
