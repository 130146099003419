import styled, { css } from 'styled-components';
import { PROJECTS_STATUSES } from 'lib/constants/projects';
import { ProjectStatusesEnum } from 'types';

export const Wrap = styled.div`
  display: flex;
  flex-grow: 0;
  padding: 17px 19px 7px 17px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 133px;
`;

export const WrapItem = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  color: ${({ theme }) => theme.colors.mutedText};
`;

export const Value = styled.div`
  white-space: normal;
  word-break: break-all;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Status = styled.div<{
  status?: ProjectStatusesEnum;
}>`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  background-color: ${({ theme }) => theme.colors.lightTurquoise};
  color: ${({ theme }) => theme.colors.lightDeepBlue};
  padding: 1px 5px;
  border-radius: 4px;
  ${props =>
    props.status === PROJECTS_STATUSES.NOT_STARTED &&
    css`
      background-color: ${({ theme }) => theme.colors.textGrey};
      color: ${({ theme }) => theme.colors.textGreyDark};
    `};
  ${props =>
    props.status === PROJECTS_STATUSES.DELETED &&
    css`
      background-color: ${({ theme }) => theme.colors.lightDanger};
      color: ${({ theme }) => theme.colors.danger};
    `};
  ${props =>
    props.status === PROJECTS_STATUSES.DONE &&
    css`
      background-color: ${({ theme }) => theme.colors.lightEucalyptus};
      color: ${({ theme }) => theme.colors.eucalyptus};
    `}
  ${props =>
    props.status === PROJECTS_STATUSES.DONE &&
    css`
      background-color: ${({ theme }) => theme.colors.lightOrange};
      color: ${({ theme }) => theme.colors.orange};
    `}
  ${props =>
    props.status === PROJECTS_STATUSES.DONE &&
    css`
      background-color: ${({ theme }) => theme.colors.purple};
      color: ${({ theme }) => theme.colors.lightPurple};
    `}
  ${props =>
    props.status === PROJECTS_STATUSES.DONE &&
    css`
      background-color: ${({ theme }) => theme.colors.cruise};
      color: ${({ theme }) => theme.colors.pine};
    `}
`;
