import styled from 'styled-components';
import { Empty } from 'antd';

export const EmptyStyled = styled(Empty)<{ noimage?: string }>`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ noimage }) => (noimage === 'true' ? 0 : '10%')};
  margin-bottom: ${({ noimage }) => (noimage === 'true' ? 0 : '10%')};

  .ant-empty-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.lightBlue};
  }
  .ant-empty-footer {
    margin-top: 12px;
  }
`;
