import styled from 'styled-components';
import { Progress } from 'antd';

export const FileLoadingProgress = styled(Progress)`
  position: absolute;
  top: 0;
  height: 4px;
  border-radius: 7px !important;
  z-index: 5;
  .ant-progress-outer {
    height: 4px;
    border-radius: 7px !important;
  }
  .ant-progress-inner {
    height: 4px;
    margin-top: -20px;
    border-radius: 7px !important;
  }
`;
