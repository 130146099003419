import { takeLatest } from 'redux-saga/effects';
import * as A from './actions';
import * as E from './workers';

export function* watchLogin() {
  yield takeLatest(A.login.TRIGGER, E.ensureLogin);
}

export function* watchForgotPassword() {
  yield takeLatest(A.forgotPassword.TRIGGER, E.ensureForgotPassword);
}

export function* watchResetPassword() {
  yield takeLatest(A.resetPassword.TRIGGER, E.ensureResetPassword);
}

export function* watchSignOutPage() {
  yield takeLatest(A.signOut.TRIGGER, E.ensureSignOut);
}

export function* watchSignUp() {
  yield takeLatest(A.signUp.TRIGGER, E.ensureSignUp);
}

export function* watchVerifyEmail() {
  yield takeLatest(A.verifyEmail.TRIGGER, E.ensureVerifyEmail);
}

export function* watchResendVerificationCode() {
  yield takeLatest(
    A.resendVerificationCode.TRIGGER,
    E.ensureResendVerificationCode
  );
}

export function* watchValidateInviteToken() {
  yield takeLatest(A.validateInvite.TRIGGER, E.ensureValidateInviteToken);
}

export function* watchSignUpWithInvite() {
  yield takeLatest(A.signUpWithInvite.TRIGGER, E.ensureSignUpWithInvite);
}
