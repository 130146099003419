import styled, { css } from 'styled-components';
import { Typography } from 'antd';

const commonStyle = css`
  &.ant-typography {
    margin-bottom: auto;
  }
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin: auto 0;
  font-weight: 500;
`;

export const Text = styled(Typography.Paragraph)<any>`
  ${commonStyle};
  font-size: 14px;
  line-height: 17px;
`;

export const TextSecondary = styled(Typography.Paragraph)`
  ${commonStyle};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const TitleText = styled(Typography.Paragraph)`
  ${commonStyle};
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

export const SmallText = styled(Typography.Paragraph)`
  ${commonStyle};
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
`;
