import { SVGProps } from 'react';

export function ShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.335 4.702V2.667a.667.667 0 00-1.138-.472L1.53 6.862a.666.666 0 000 .942l4.667 4.667A.666.666 0 007.335 12V9.936c.19-.014.377-.02.562-.02 3.617 0 5.493 2.382 5.575 2.487a.664.664 0 00.742.229.667.667 0 00.454-.632c0-6.083-5.39-7.12-7.333-7.298zm.562 3.881c-.431 0-.875.03-1.317.089a.668.668 0 00-.578.661v1.057L2.944 7.333l3.058-3.057v1.057A.68.68 0 006.683 6c.607 0 5.41.134 6.453 4.283a8.732 8.732 0 00-5.239-1.7z"
        fill="#172B4D"
      />
    </svg>
  );
}
