import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
// redux
import { useSelector } from 'react-redux';
import {
  containerOptionsSelector,
  subtaskTypesObjectSelector,
  subtaskTypesSelector,
  updateTask,
} from 'store/admin';
import {
  clearSubtaskDraftFilesBuffer,
  clearSubtaskFilesBuffer,
  getTaskNotificationCreator,
  projectViewModeSelector,
  putProjectPromiseCreator,
  resultSubtaskPromiseCreator,
} from 'store/dashboard';
import {
  getDataForSend,
  getEmptyCommonTasks,
  groupTasksBySubtask,
  isNotCompleted,
  prepareRemoveTask,
  prepareTasks,
} from '../helpers';
import { separatedTasksByType } from 'utils/helpers';
import TasksProgressBar from '../TasksProgressBar/TasksProgressBar';
import AddNewTask from './AddNewTask';
import ContainerInfo from './ContainerInfo';
import EditContainer from './ContainerInfo/EditContainer';
// components
import Expand from './Expand';
import { NotifyModal } from './NotifyModal';
import { SwitchViewMode } from './SwitchViewMode';
import { TasksList } from './TasksList';
import Modal from 'components/Modal';
// hooks
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import update from 'immutability-helper';
// constants
import { MAX_SUBTASKS_COUNT, PROJECTS_STATUSES } from 'lib/constants';
import TaskFormModal from 'pages/Project/Tasks/TaskFormModal';
// utils
import { message } from 'utils/message';
// styles
import {
  Actions,
  ContainerWrapper,
  DividerItem,
  ListStyled,
  TaskWrap,
  TasksTitle,
} from './styles';
import {
  IProjectResponseDto,
  IProjectTaskDto,
  OnProjectUpdateSubmit,
  ProjectViewMode,
  ServerErrorsEnum,
  TaskTypeEnum,
} from 'types';

interface ITasksProps {
  editFilesData?: any;
  setShouldUpdate: (update: boolean) => void;
  data?: IProjectResponseDto;
  setSubtaskIdForResult: ({
    id,
    percent,
    isDone,
  }: {
    id: string;
    percent: number;
    isDone: boolean;
  }) => void;
  toggleCheckResult: (visible: boolean) => void;
  setOpenCheckResult: Dispatch<SetStateAction<boolean>>;
  setResults: Dispatch<SetStateAction<string[]>>;
  closeCheckResult: () => void;
  isDisabledDelete: boolean;
  notAvailableProject: boolean;
  toggleParent: (visible: boolean) => void;
}

const Tasks = ({
  editFilesData,
  setShouldUpdate,
  data,
  setSubtaskIdForResult,
  toggleCheckResult,
  setOpenCheckResult,
  setResults,
  closeCheckResult,
  isDisabledDelete,
  toggleParent,
  notAvailableProject,
}: ITasksProps) => {
  const containerOptions = useSelector(containerOptionsSelector);
  const projectViewMode = useSelector(projectViewModeSelector);
  const subtaskTypes = useSelector(subtaskTypesSelector);
  const subtasksTypesObject = useSelector(subtaskTypesObjectSelector, isEqual);
  const updateContainer = useActions(updateTask);
  const subtaskDraftFilesBufferClear = useActions(clearSubtaskDraftFilesBuffer);
  const subtaskFilesBufferClear = useActions(clearSubtaskFilesBuffer);
  const editProject = useActionsRoutines(putProjectPromiseCreator);
  const getSubtaskResult = useActionsRoutines(resultSubtaskPromiseCreator);
  const getTaskNotificationById = useActionsRoutines(
    getTaskNotificationCreator
  );
  const { invoice, color, locationId } = data;
  const isProjectViewTaskMode = projectViewMode === ProjectViewMode.Tasks;
  const [openEditTaskIndex, setOpenEditTaskIndex] = useState();
  const [expandedTasks, setExpandedTasks] = useState<string[]>([]);
  const [tasksList, setTasksList] = useState(data.tasks);
  const [isOpenTemplateModal, toggleModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [newContainer, setNewContainer] = useState();
  const [taskNotification, setTaskNotification] = useState({
    id: undefined,
    isOpen: false,
    item: undefined,
    template: undefined,
  });
  const [isAddTaskFormOpen, setIsAddTaskFormOpen] = useState(false);
  const memoizedTasksList = useMemoCompare(tasksList);

  useEffect(() => {
    setTasksList(data.tasks);
  }, [data.tasks]);

  const handleUpdateChecklist = useCallback(
    async (subtaskId, newChecklist) => {
      const projectData = {
        ...data,
        tasks: data?.tasks?.map(task => ({
          ...task,
          subtasks: task?.subtasks?.map(subtask =>
            subtask?._id === subtaskId
              ? {
                  ...subtask,
                  checklist: newChecklist,
                }
              : {
                  ...subtask,
                  checklist: subtask.checklist || [],
                }
          ),
        })),
      };

      return editProject({
        id: data._id,
        data: projectData,
        skipContainerUniqueness: true,
      }).catch(e => console.error(e));
    },
    [data, editProject]
  );

  const hasContainer = useMemo(
    () => !!tasksList.find(item => item.type === TaskTypeEnum.Container),
    [tasksList]
  );
  const handleAddContainer = useCallback(
    container => !hasContainer && setNewContainer(container),
    [hasContainer]
  );
  const handleDeleteContainer = useCallback(
    () => setNewContainer(undefined),
    []
  );

  const onSubmit: OnProjectUpdateSubmit = useCallback(
    ({
      formData,
      index,
      setIsSubmitLoading,
      isNewProjectContainer,
      isWorkOrderContainer,
      setProjectConflicts,
      skipContainerUniqueness,
    }) => {
      setIsSubmitLoading(true);

      const projectData = getDataForSend(
        formData,
        data,
        editFilesData,
        index,
        isNewProjectContainer
      );
      editProject({ id: data._id, data: projectData, skipContainerUniqueness })
        .then(() => {
          subtaskDraftFilesBufferClear();
          subtaskFilesBufferClear();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success();
          setShouldUpdate(true);
          setOpenEditTaskIndex(undefined);
          const id = formData.updateId;
          const updateData = { ...formData, container: { ...formData } };
          if (isNewProjectContainer) {
            handleDeleteContainer();
          }
          if (isWorkOrderContainer) {
            updateContainer({ data: { data: updateData, id } });
          }
        })
        .catch(e => {
          if (e.data.errorCode === ServerErrorsEnum.CONTAINER_NUMBER_EXISTS) {
            setProjectConflicts(e.data.conflicts);
          }
          setIsSubmitLoading(false);
        });
    },
    [
      editFilesData,
      data,
      editProject,
      updateContainer,
      setShouldUpdate,
      subtaskDraftFilesBufferClear,
      handleDeleteContainer,
      subtaskFilesBufferClear,
    ]
  );

  const removeTask = useCallback(
    (index, setDeleteLoading) => {
      const projectData = prepareRemoveTask(data, editFilesData, index);
      editProject(projectData)
        .then(() => {
          setOpenEditTaskIndex(undefined);
          setShouldUpdate(true);
        })
        .catch(() => setDeleteLoading(false));
    },
    [editFilesData, data, editProject, setOpenEditTaskIndex, setShouldUpdate]
  );

  const openCheckResult = useCallback(
    (id, percent, isDone) => {
      setSubtaskIdForResult({ id, percent, isDone });
      toggleCheckResult(true);
      setOpenCheckResult(true);
      getSubtaskResult(id)
        .then((res: string[]) => setResults(res))
        .catch(() => closeCheckResult())
        .finally(() => toggleCheckResult(false));
    },
    [
      closeCheckResult,
      getSubtaskResult,
      setSubtaskIdForResult,
      setOpenCheckResult,
      setResults,
      toggleCheckResult,
    ]
  );

  const toggleItemTemplateModal = () => {
    toggleModal(state => !state);
  };

  const { containerTasks, commonTasks } = useMemo(
    () => separatedTasksByType(memoizedTasksList),
    [memoizedTasksList]
  );
  const allCommonTasksIds = useMemo(
    () => commonTasks.map(task => task._id),
    [commonTasks]
  );
  const tasksGroupBySubtask = useMemo(
    () => groupTasksBySubtask(memoizedTasksList, subtasksTypesObject),
    [memoizedTasksList, subtasksTypesObject]
  );
  const groupedTasksNames = useMemo(
    () => tasksGroupBySubtask.map(subtask => subtask.subtaskName),
    [tasksGroupBySubtask]
  );
  const emptyCommonTasks = useMemo(
    () => getEmptyCommonTasks(tasksList),
    [tasksList]
  );
  const allProjectViewTaskModeTasksIdentifier = useMemo(
    () => [...groupedTasksNames, ...emptyCommonTasks.map(task => task._id)],
    [groupedTasksNames, emptyCommonTasks]
  );

  const isExpandedAll =
    expandedTasks.length ===
    (isProjectViewTaskMode
      ? allProjectViewTaskModeTasksIdentifier
      : allCommonTasksIds
    ).length;

  const toggleExpandAll = useCallback(
    (IsOpenAll: boolean) => {
      if (IsOpenAll) {
        const taskIdentifier = isProjectViewTaskMode
          ? allProjectViewTaskModeTasksIdentifier
          : allCommonTasksIds;

        setExpandedTasks(taskIdentifier);
      } else {
        setExpandedTasks([]);
      }
    },
    [
      allCommonTasksIds,
      allProjectViewTaskModeTasksIdentifier,
      isProjectViewTaskMode,
    ]
  );
  const saveTasksOrder = useCallback(() => {
    if (!isOrderChanged) return;
    const tasksForSend = prepareTasks(tasksList);
    const newData = {
      ...data,
      coverUrl: data?.cover?.fileUrl,
      tasks: tasksForSend,
      files: editFilesData,
    };
    editProject({
      id: data._id,
      data: newData,
      skipContainerUniqueness: true,
    })
      .then(() => message.success())
      .catch(err => {
        console.error(err);
        setTasksList(data.tasks);
      })
      .finally(() => setIsOrderChanged(false));
  }, [
    tasksList,
    data,
    editProject,
    isOrderChanged,
    setIsOrderChanged,
    editFilesData,
  ]);

  const moveTask = useCallback(
    (dragIndex, hoverIndex) => {
      const dragTask = tasksList[dragIndex];
      const newTasks = update(tasksList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragTask],
        ],
      });

      setTasksList(
        newTasks.map((item, index: number) => ({ ...item, order: index + 1 }))
      );

      setIsOrderChanged(dragIndex !== hoverIndex);
    },
    [tasksList]
  );

  const commonTasksLength = useMemo(
    () => tasksList.filter(item => item.type === TaskTypeEnum.Common).length,
    [tasksList]
  );

  const addTask = useCallback(
    (formData, notLastItem, onFinishLoading, skipContainerUniqueness) => {
      const newTask = {
        ...formData,
        order: +commonTasksLength + 1,
        type: TaskTypeEnum.Common,
      };
      const shouldStatusChange =
        data.status === PROJECTS_STATUSES.DONE && formData?.subtasks;
      const tasksForSend = prepareTasks([...tasksList, newTask]);
      const newData = {
        ...data,
        coverUrl: data?.cover?.fileUrl,
        tasks: tasksForSend,
        files: editFilesData,
        status: shouldStatusChange
          ? PROJECTS_STATUSES.IN_PROGRESS
          : data.status,
      };
      editProject({
        id: data._id,
        data: newData,
        skipContainerUniqueness,
      })
        .then(() => !notLastItem && setIsAddTaskFormOpen(false))
        .catch(err => console.error(err))
        .finally(() => onFinishLoading());
    },
    [tasksList, data, editProject, editFilesData, commonTasksLength]
  );

  const isAddTaskFormOpenHandler = useCallback(
    newValue => {
      setOpenEditTaskIndex(undefined);
      setIsAddTaskFormOpen(newValue);
    },
    [setOpenEditTaskIndex, setIsAddTaskFormOpen]
  );

  const setOpenEditTaskIndexHandler = useCallback(
    newValue => {
      if (newValue !== undefined) setIsAddTaskFormOpen(false);
      setOpenEditTaskIndex(newValue);
    },
    [setIsAddTaskFormOpen, setOpenEditTaskIndex]
  );

  const handleOpenTaskNotificationModal = useCallback(
    value => {
      if (value.id) {
        getTaskNotificationById(value.id)
          .then(template => setTaskNotification({ ...value, template }))
          .catch(err => console.error(err));
      } else {
        setTaskNotification({
          ...value,
          template: {
            subtasks: [],
          },
        });
      }
    },
    [getTaskNotificationById]
  );

  const handleCloseTaskNotificationModal = useCallback(
    (taskId?, taskNotificationId?) => {
      setTaskNotification({
        id: undefined,
        isOpen: undefined,
        item: undefined,
        template: undefined,
      });

      if (taskId && taskNotificationId) {
        setTasksList(
          tasksList.map(item =>
            item?._id === taskId ? { ...item, taskNotificationId } : item
          )
        );
      }
    },
    [tasksList]
  );

  const getRowKey = useCallback(item => item._id, []);

  const changeExpand = useCallback(
    () => toggleExpandAll(!isExpandedAll),
    [isExpandedAll, toggleExpandAll]
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setExpandedTasks([]);
      setOpenEditTaskIndex(undefined);
    }
  }, [projectViewMode]);

  const showAddTask =
    PROJECTS_STATUSES.DELETED !== data.status &&
    commonTasks.length < MAX_SUBTASKS_COUNT;

  return (
    <TaskWrap>
      {!!containerTasks.length && (
        <>
          <TasksTitle>Container</TasksTitle>
          <DividerItem />
          <ContainerWrapper>
            <ListStyled
              dataSource={tasksList}
              rowKey={getRowKey}
              renderItem={(item: IProjectTaskDto, index) => {
                if (item.type !== TaskTypeEnum.Container) return null;
                return (
                  <div style={{ order: -1 }}>
                    <ContainerInfo
                      setIsOpen={setOpenEditTaskIndexHandler}
                      openTaskIndex={openEditTaskIndex}
                      index={index}
                      key={item._id}
                      removeTask={removeTask}
                      item={item}
                      containerOptions={containerOptions}
                      onSubmit={onSubmit}
                      isCompleted={!isNotCompleted(data)}
                      setShouldUpdate={setShouldUpdate}
                      notAvailableProject={notAvailableProject}
                      subtaskTypes={subtaskTypes}
                      project={data}
                      toggleParent={toggleParent}
                      editProject={editProject}
                      invoice={invoice}
                      locationId={locationId}
                      openCheckResult={openCheckResult}
                      color={color}
                      updateChecklist={handleUpdateChecklist}
                    />
                  </div>
                );
              }}
            />
          </ContainerWrapper>
        </>
      )}
      <TasksTitle>
        Items {!!commonTasks.length && `(${commonTasks.length})`}
        <Actions>
          <SwitchViewMode />
          {commonTasks.length > 1 && (
            <Expand isExpandedAll={isExpandedAll} changeExpand={changeExpand} />
          )}
        </Actions>
      </TasksTitle>
      {newContainer && (
        <EditContainer
          item={newContainer}
          index={0}
          onSubmit={onSubmit}
          containerOptions={containerOptions}
          subtaskTypes={subtaskTypes}
          setIsOpen={handleDeleteContainer}
          removeTask={handleDeleteContainer}
          projectId={data?._id}
          isNewContainer
        />
      )}
      <TasksProgressBar tasks={commonTasks} />
      <TasksList
        isProjectEditing={openEditTaskIndex !== undefined}
        emptyCommonTasks={emptyCommonTasks}
        tasksGroupBySubtask={tasksGroupBySubtask}
        containerTasksCount={containerTasks.length}
        tasksList={tasksList}
        toggleExpandAll={toggleExpandAll}
        setExpandedTasks={setExpandedTasks}
        expandedTasks={expandedTasks}
        removeTask={removeTask}
        moveTask={moveTask}
        saveTasksOrder={saveTasksOrder}
        isDisabledDelete={isDisabledDelete}
        setIsOpenEdit={setOpenEditTaskIndexHandler}
        openNotify={handleOpenTaskNotificationModal}
        onSubmit={onSubmit}
        subtaskTypes={subtaskTypes}
        editProject={editProject}
        project={data}
        toggleParent={toggleParent}
        openTaskIndex={openEditTaskIndex}
        color={color}
        invoice={invoice}
        setShouldUpdate={setShouldUpdate}
        openCheckResult={openCheckResult}
        locationId={locationId}
        notAvailableProject={notAvailableProject}
        updateChecklist={handleUpdateChecklist}
        setEditData={setEditData}
        toggleItemTemplateModal={toggleItemTemplateModal}
      />
      {showAddTask && (
        <AddNewTask
          subtaskTypes={subtaskTypes}
          index={commonTasksLength}
          isOpenForm={isAddTaskFormOpen}
          setIsOpenForm={isAddTaskFormOpenHandler}
          addTask={addTask}
          notAvailableProject={notAvailableProject}
          projectId={data?._id}
          appendContainer={handleAddContainer}
        />
      )}
      <Modal
        title="Email notification"
        visible={taskNotification.isOpen}
        onCancel={handleCloseTaskNotificationModal}
      >
        <NotifyModal
          task={taskNotification.item}
          template={taskNotification.template}
          templateId={taskNotification.id}
          onClose={handleCloseTaskNotificationModal}
        />
      </Modal>
      <Modal
        visible={isOpenTemplateModal}
        onCancel={toggleItemTemplateModal}
        width={670}
        padding="20px 24px 24px"
      >
        <TaskFormModal
          data={editData}
          onSubmit={toggleItemTemplateModal}
          projectId={undefined}
        />
      </Modal>
    </TaskWrap>
  );
};

export default memo(Tasks, isEqual);
