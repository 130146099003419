import { parsePhoneNumber } from 'awesome-phonenumber';
import { transliterate as tr } from 'transliteration';

export const isValidPhoneNumber = (inputNumber: string) => {
  const parsedPhoneNumber = parsePhoneNumber(`+${inputNumber}`);
  const isPhoneNumberValid =
    parsedPhoneNumber.isValid() || parsedPhoneNumber.isMobile();
  return isPhoneNumberValid;
};

export const replaceSpecialSymbols = (str: string) => (str ? tr(str) : '');
