import { useCallback, useState } from 'react';
import ChecklistButton from '../ChecklistButton/ChecklistButton';
import { Hours } from '../Hours';
import CreatePublicLink from 'components/CreatePublicLink';
import { DuplicateProjectSubtasks } from 'components/DuplicateProjectSubtasks';
import Modal from 'components/Modal';
import { SubtaskChangelogForm } from 'components/SubtaskChangelogForm';
import { TABLE_WIDTH } from 'components/SubtaskChangelogForm/constants';
import Chat from 'components/SubtaskViewComponents/Chat';
import Files from 'components/SubtaskViewComponents/Files';
import { BUTTON_TYPES, Button } from 'components/common';
import PopoverWrapper from 'components/common/PopoverWrapper/PopoverWrapper';
import { Link, Notes } from 'lib/icons';
import MenuVerticalIcon from 'lib/icons/MenuVerticalIcon';
import theme from 'styles/theme';
import { Popover } from 'antd';
import {
  Bottom,
  BottomLeft,
  BottomRight,
  ChecklistWrapper,
  MenuItem,
  MenuPopover,
  MenuText,
} from './styles';
import { GeneratedApiKeyDto, IChecklist, ISubtaskItemData } from 'types';

interface IBottomPanelProps {
  data?: ISubtaskItemData;
  projectId: string;
  generatedApiKey: GeneratedApiKeyDto;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  taskId?: string;
  withDnD: boolean;
  isProjectArchived: boolean;
  isProjectDeleted: boolean;
  countSubtasksInTask: number;
  isProjectEditing?: boolean;
}
const BottomPanel = ({
  data,
  projectId,
  taskId,
  generatedApiKey,
  updateChecklist,
  withDnD,
  isProjectArchived,
  isProjectDeleted,
  countSubtasksInTask,
  isProjectEditing,
}: IBottomPanelProps) => {
  const {
    _id,
    checklist,
    actualHours,
    templateHours,
    plannedHours,
    hours,
    files,
  } = data;

  const [isOpenShare, setIsOpenShare] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isMenuPopoverVisible, setIsMenuPopoverVisible] = useState(false);

  const isAssignedToResource = !!data.resource;

  const openShare = useCallback(() => {
    setIsOpenShare(true);
  }, [setIsOpenShare]);

  const handleDoubleClick = useCallback(e => e.stopPropagation(), []);

  const handleCancelClick = useCallback(
    () => setIsOpenShare(false),
    [setIsOpenShare]
  );

  const handleOpenChangelog = useCallback(() => {
    setIsOpenMenu(true);
    setIsMenuPopoverVisible(false);
  }, [setIsOpenMenu, setIsMenuPopoverVisible]);
  const handleCancelChangelog = useCallback(() => {
    setIsOpenMenu(false);
  }, [setIsOpenMenu]);

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  const handleMenuPopoverVisibleChange = useCallback(
    val => setIsMenuPopoverVisible(val),
    [setIsMenuPopoverVisible]
  );

  const menuPopover = (
    <MenuPopover
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <MenuItem onClick={handleOpenChangelog}>
        <MenuText>Changelog</MenuText>
      </MenuItem>
    </MenuPopover>
  );

  return (
    <Bottom $withDnD={withDnD}>
      <BottomLeft>
        <Hours
          actualHours={actualHours}
          templateHours={templateHours}
          plannedHours={plannedHours}
          hours={hours}
        />
        <Files files={files} />
      </BottomLeft>
      <BottomRight>
        <div onDoubleClick={handleDoubleClick}>
          <Modal
            visible={isOpenShare}
            onCancel={handleCancelClick}
            width={481}
            padding="25px 22px 21px 17px"
          >
            <CreatePublicLink
              setIsOpenShare={setIsOpenShare}
              isOpenShare={isOpenShare}
              generatedApiKey={generatedApiKey}
              isSubtask
              taskId={taskId}
              id={_id}
            />
          </Modal>
        </div>
        <DuplicateProjectSubtasks
          countSubtasksInTask={countSubtasksInTask}
          currentSubtask={data}
          taskId={taskId}
          isProjectEditing={isProjectEditing}
        />
        <ChecklistWrapper>
          <ChecklistButton
            updateChecklist={updateChecklist}
            checklist={checklist}
            isRecurring={false}
            projectId={projectId}
            isProjectDeleted={isProjectDeleted}
            id={_id}
          />
        </ChecklistWrapper>

        <Chat
          data={data}
          isInProject
          isProjectDeleted={isProjectDeleted}
          isProjectArchived={isProjectArchived}
        />

        {data?.notes && (
          <PopoverWrapper title="Notes" content={<>{data.notes}</>}>
            <Button
              onClick={null}
              kind={BUTTON_TYPES.PRIMARY}
              icon={<Notes />}
              width="36px"
              height="28px"
              lineheight="0px"
              space="0px"
              padding="0px"
            />
          </PopoverWrapper>
        )}

        <Button
          disabled={isAssignedToResource}
          onClick={openShare}
          kind={generatedApiKey ? BUTTON_TYPES.DEFAULT : BUTTON_TYPES.PRIMARY}
          icon={
            <Link
              width="13px"
              height="13px"
              color={
                generatedApiKey ? theme.colors.white : theme.colors.mainDarkBlue
              }
            />
          }
          style={{ opacity: isAssignedToResource ? 0.4 : 1 }}
          width="36px"
          height="28px"
          lineheight="0px"
          space="0px"
          padding="0px"
        />
        <Popover
          getPopupContainer={handleGetPopupContainer}
          content={menuPopover}
          destroyTooltipOnHide
          overlayClassName="MenuPopover"
          trigger="click"
          placement="left"
          onVisibleChange={handleMenuPopoverVisibleChange}
          visible={isMenuPopoverVisible}
        >
          <Button
            onClick={null}
            kind={BUTTON_TYPES.PRIMARY}
            icon={<MenuVerticalIcon />}
            width="36px"
            height="28px"
            lineheight="0px"
            space="0px"
            padding="0px"
          />
        </Popover>
        <div onDoubleClick={handleDoubleClick}>
          <Modal
            visible={isOpenMenu}
            onCancel={handleCancelChangelog}
            width={TABLE_WIDTH}
          >
            <SubtaskChangelogForm subtask={data} projectId={projectId} />
          </Modal>
        </div>
      </BottomRight>
    </Bottom>
  );
};

export default BottomPanel;
