// constants
import {
  CHANGE_TO_SUBTASK_STATUSES,
  SUBTASK_STATUSES_PROPERTIES,
} from 'lib/constants';
import { SubtaskStatusesEnum } from 'types';

export const getDefaultChangeToStatuses = curentStatus =>
  CHANGE_TO_SUBTASK_STATUSES.filter(item => item.value !== curentStatus);

export const getChangeToStatuses = curentStatus => {
  switch (curentStatus) {
    case SubtaskStatusesEnum.TO_DO:
      return [
        SUBTASK_STATUSES_PROPERTIES.IN_PROGRESS,
        SUBTASK_STATUSES_PROPERTIES.NOT_READY,
      ];
    case SubtaskStatusesEnum.DONE:
      return [
        SUBTASK_STATUSES_PROPERTIES.TO_DO,
        SUBTASK_STATUSES_PROPERTIES.NOT_READY,
      ];
    case SubtaskStatusesEnum.NOT_READY:
      return [SUBTASK_STATUSES_PROPERTIES.TO_DO];
    case SubtaskStatusesEnum.PAUSED:
      return [SUBTASK_STATUSES_PROPERTIES.IN_PROGRESS];
    case SubtaskStatusesEnum.FAILED:
      return [
        SUBTASK_STATUSES_PROPERTIES.TO_DO,
        SUBTASK_STATUSES_PROPERTIES.NOT_READY,
        SUBTASK_STATUSES_PROPERTIES.DONE,
        SUBTASK_STATUSES_PROPERTIES.IN_PROGRESS,
      ];
    case SubtaskStatusesEnum.ON_REVIEW:
      return [
        SUBTASK_STATUSES_PROPERTIES.TO_DO,
        SUBTASK_STATUSES_PROPERTIES.DONE,
        SUBTASK_STATUSES_PROPERTIES.NOT_READY,
      ];
    default:
      return getDefaultChangeToStatuses(SubtaskStatusesEnum.IN_PROGRESS);
  }
};
