export const KEYS = {
  ALT: 'Alt',
  META: 'Meta',
  SHIFT: 'Shift',
  CONTROL: 'Control',
};

export const GANTT_KEYS = [KEYS.ALT, KEYS.CONTROL, KEYS.META, KEYS.SHIFT];

export const DEFAULT_SUBTASK_OFFSET = 30;
