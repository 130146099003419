import styled, { css } from 'styled-components';
import { Button } from 'components/common';
import { Row } from 'antd';

export const Wrap = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 10px;
`;

export const FilesInputWrap = styled.div<{
  isDragEnter?: boolean;
  showUploadList?: boolean;
  multiple?: boolean;
}>`
  cursor: pointer;
  width: 100%;
  height: 52px;
  border: 1px dashed ${({ theme }) => theme.colors.grayDark};
  margin-bottom: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  &:hover,
  &:focus {
    border: 1px dashed ${({ theme }) => theme.colors.grayDark};
    outline: none;
  }
  svg {
    margin-right: 5px;
    margin-bottom: -2px;
  }
  ${({ isDragEnter }) =>
    isDragEnter &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `}
`;

export const BrowseBox = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

export const FilesBlock = styled(Row)`
  display: flex;
  flex-direction: column;
`;

export const FileWrap = styled.div`
  display: flex;
  gap: 11px;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  padding-right: 25px;
  margin-bottom: 29px;
`;

export const IconWrapper = styled.div<{
  showMore?: boolean;
}>`
  position: absolute;
  right: 4px;
  ${({ showMore }) =>
    showMore &&
    css`
      transform: rotate(180deg);
    `}
`;
