import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const sentryInit = () => {
  if (
    process.env.REACT_APP_SENTRY_RELEASE &&
    (process.env.REACT_APP_ENVIRONMENT === 'staging' ||
      process.env.REACT_APP_ENVIRONMENT === 'production')
  ) {
    // eslint-disable-next-line no-console
    console.log('Sentry init');
    Sentry.init({
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      dsn: 'https://6fae36efef3f42e19351c6cdf8e3e560@o369137.ingest.sentry.io/5262650',
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

export default sentryInit;
