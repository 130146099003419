import { FC, useCallback } from 'react';
import { placeholdersOptions } from './constants';
import { EditorState, Modifier } from 'draft-js';
import { Select } from 'antd';
import { MenuItem, SelectItem } from './styles';

const { Option } = Select;

interface PlaceholdersProps {
  editorState?: any;
  onChange?: (item: any) => void;
}

const Placeholders: FC<PlaceholdersProps> = ({ editorState, onChange }) => {
  const handleChange = useCallback(
    value => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        `{{${value}}}`,
        editorState.getCurrentInlineStyle()
      );
      onChange(
        EditorState.push(editorState, contentState, 'insert-characters')
      );
    },
    [editorState, onChange]
  );

  return (
    <SelectItem
      dropdownAlign={{ offset: [-148, 4] }}
      dropdownMatchSelectWidth={false}
      defaultActiveFirstOption={false}
      dropdownRender={menu => <MenuItem>{menu}</MenuItem>}
      value="add"
      width="65px"
      onChange={handleChange}
    >
      {placeholdersOptions.map(option => (
        <Option key={option.placeholder} value={option.placeholder}>
          {option.name}
        </Option>
      ))}
    </SelectItem>
  );
};

export default Placeholders;
