import styled from 'styled-components';
import { Radio } from 'antd';

export const TableRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 116px;
  overflow: hidden;
  width: fit-content;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const TableRowLabel = styled.div`
  width: 264px;
  height: 116px;
  min-width: 263px;
  padding: 6px 50px 6px 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
`;

export const TaskSku = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 4px;
`;

export const TaskDescription = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const RadioStyled = styled(Radio)`
  height: 116px;
  width: 193.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
  margin: 0;
  .ant-radio-inner,
  .ant-radio {
    &:hover {
      border-color: ${({ theme }) => theme.colors.eucalyptus} !important;
    }
    &:after {
      background-color: ${({ theme }) => theme.colors.eucalyptus} !important;
    }
    &-checked {
      border-color: ${({ theme }) => theme.colors.eucalyptus} !important;
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.eucalyptus} !important;
      }
    }
  }
`;
