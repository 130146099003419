import { CSSProperties, useMemo } from 'react';
import { Button } from '../Button';
import { BUTTON_TYPES } from '../Button/constants';
import { ButtonProps } from 'antd';
import { ButtonWrap } from './styles';

interface IButtonSwitchProps extends ButtonProps {
  kind?: BUTTON_TYPES;
  label?: string;
  icon?: JSX.Element;
  height?: string;
  width?: string;
  space?: string;
  fontSize?: string;
  lineheight?: string;
  onClick?: () => void;
  style?: CSSProperties;
  isButtonActive?: boolean;
}

export const ButtonSwitch = ({
  isButtonActive,
  ...props
}: IButtonSwitchProps) => {
  const { label, type } = props;

  return useMemo(
    () => (
      <ButtonWrap>
        <Button
          kind={isButtonActive ? BUTTON_TYPES.DEFAULT : BUTTON_TYPES.SECONDARY}
          fontSize="13px"
          height="30px"
          lineheight="0px"
          space="4px"
          className="switchButton"
          {...props}
          type={type}
        >
          {label}
        </Button>
      </ButtonWrap>
    ),
    [props, isButtonActive, label, type]
  );
};
