import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const predefined = (r: IInitialState) => r.predefined;
export const locationsSelector = createSelector(predefined, r => r.locations);
export const searchSelector = createSelector(predefined, r => r.globalSearch);
export const projectsFilterSelector = createSelector(
  predefined,
  r => r.searchAllProjects
);
