import styled from 'styled-components';
import { Row } from 'antd';

export const RowStyled = styled(Row)`
  display: flex;
  margin-top: 6px;
  .CreateProjectInput {
    @media (max-width: 606px) {
      width: 100%;
    }
  }
`;
