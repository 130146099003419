import { memo, useCallback, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// redux
import {
  autocompletePromiseCreator,
  defaultContainerTemplateSelector,
} from 'store/admin';
import { companySelector } from 'store/company';
import { prepareChecklist } from 'utils/helpers';
// components
import ContainerOptions from './ContainerOptions';
import SubtasksInfo from 'components/TaskForm/SubtasksInfo';
import {
  Autocomplete,
  Input,
  MobileMarginTopWrap,
  TextArea,
} from 'components/common';
// utils
import { useActionsRoutines, useAutocomplete } from 'hooks';
import { v4 as uuidv4 } from 'uuid';
// styles
import { Inputs, InputsItem, SubtasksWrapper, ZoneInputWrap } from './styles';
import {
  ContainerOptionsDto,
  HookFormRecord,
  IProjectTaskDto,
  ISubtaskTypeDto,
} from 'types';

interface IErrorMessage {
  message: string;
}
interface IError {
  name?: IErrorMessage;
  sku?: IErrorMessage;
  num?: IErrorMessage;
  zone?: IErrorMessage;
  description?: IErrorMessage;
  subtasks?: IErrorMessage;
}

interface IContainerProps {
  setIsWorkOrderContainer?: (container: boolean) => void;
  errors?: IError;
  isEdit?: boolean;
  containerOptions?: ContainerOptionsDto;
  item?: IProjectTaskDto;
  name?: string;
  projectId: string;
  subtasksInWrap?: boolean;
  isTemplate?: boolean;
  isInProject?: boolean;
  subtaskTypes?: ISubtaskTypeDto[];
  isNewContainer?: boolean;
  isInbound?: boolean;
}

const ContainerForm = ({
  isEdit,
  errors,
  item,
  setIsWorkOrderContainer,
  name = '',
  containerOptions,
  subtasksInWrap,
  isTemplate,
  isInProject,
  subtaskTypes,
  isNewContainer,
  isInbound,
  projectId,
}: IContainerProps) => {
  const form = useFormContext();
  const { control, register, setValue, trigger, clearErrors } = form;
  const defaultTemplate = useSelector(
    defaultContainerTemplateSelector,
    isEqual
  );
  const company = useSelector(companySelector);
  const autocomplete = useActionsRoutines(autocompletePromiseCreator);
  const [onSearchName, optionsName, setOptionsName, onChangeNameField] =
    useAutocomplete(autocomplete, 'container', 'name');
  const [onSearchSKU, optionsSKU, setOptionsSKU] = useAutocomplete(
    autocomplete,
    'container',
    'sku'
  );

  const defaultTemplateHours = isTemplate ? 1 : 0;

  const subtasks = useFieldArray({
    control,
    name: `${name}subtasks` as HookFormRecord,
  });

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    register(`${name}_id` as HookFormRecord);
    register(`${name}type` as HookFormRecord);
    register(`${name}createdAt` as HookFormRecord);
    register(`${name}updatedAt` as HookFormRecord);
    register(`${name}status` as HookFormRecord);

    if (isEdit) {
      setValue(`${name}type` as HookFormRecord, item.type);
      setValue(`${name}createdAt` as HookFormRecord, item.createdAt);
      setValue(`${name}updatedAt` as HookFormRecord, item.updatedAt);
      setValue(`${name}status` as HookFormRecord, item?.status);
    }
    // @ts-ignore
    setValue(`${name}_id` as HookFormRecord, item?._id);
  }, [name, isEdit, item, register, setValue]);

  useEffect(() => {
    if (isNewContainer) {
      setValue(`${name}sku` as HookFormRecord, '');
      setValue(`${name}num` as HookFormRecord, '');
      setValue(`${name}name` as HookFormRecord, '');
      setValue(`${name}description` as HookFormRecord, '');
      setValue(`${name}condition` as HookFormRecord, '');
      setValue(`${name}size` as HookFormRecord, '');
      setValue(`${name}color` as HookFormRecord, '');
      setValue(`${name}grade` as HookFormRecord, '');
      setValue(`${name}zone` as HookFormRecord, '');
      setValue(`${name}type` as HookFormRecord, '');
      setValue(`${name}createdAt` as HookFormRecord, undefined);
      setValue(`${name}updatedAt` as HookFormRecord, undefined);
      subtasks.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewContainer, name, setValue]);

  useEffect(() => {
    if (isNewContainer || isInbound) {
      const newSubtasks = defaultTemplate?.subtasks.map(item => ({
        id: uuidv4(),
        name: item?.name,
        checklist: prepareChecklist(item?.checklist || []),
        plannedHours: item?.plannedHours || 1,
        actualHours: item?.actualHours || 0,
        templateHours:
          typeof item?.templateHours !== 'undefined'
            ? item?.templateHours || 0
            : defaultTemplateHours,
        order: item?.order,
        isApproveRequired: false,
        isPhotoRequired: false,
        subtaskTypeId: item?.subtaskTypeId,
        notes: undefined,
        files: [],
      }));

      subtasks.append(newSubtasks);
    } else if (item?.subtasks.length) {
      subtasks.replace(item.subtasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isNewContainer,
    isInbound,
    defaultTemplateHours,
    defaultTemplate?.subtasks,
  ]);

  const onChange = useCallback(
    option => {
      if (setIsWorkOrderContainer) {
        setIsWorkOrderContainer(!option?.container);
      }

      register(`${name}updateId` as HookFormRecord);
      setValue(`${name}sku` as HookFormRecord, option.sku);
      setValue(`${name}num` as HookFormRecord, option.container?.num);
      setValue(`${name}name` as HookFormRecord, option.name);
      setValue(`${name}description` as HookFormRecord, option.description);
      setValue(
        `${name}condition` as HookFormRecord,
        option.container?.condition
      );
      setValue(`${name}size` as HookFormRecord, option.container?.size);
      setValue(`${name}color` as HookFormRecord, option.container?.color);
      setValue(`${name}grade` as HookFormRecord, option.container?.grade);
      setValue(`${name}zone` as HookFormRecord, option?.container?.zone);
      setValue(`${name}type` as HookFormRecord, option.type);
      setValue(`${name}_id` as HookFormRecord, item?._id);
      setValue(`${name}updateId` as HookFormRecord, option._id);
      setValue(`${name}createdAt` as HookFormRecord, undefined);
      setValue(`${name}updatedAt` as HookFormRecord, undefined);

      const newSubtasks = option?.subtasks?.map(item => ({
        id: uuidv4(),
        name: item?.name,
        plannedHours: item?.plannedHours,
        actualHours: item?.actualHours || 0,
        templateHours: item?.templateHours || 0,
        order: item?.order,
        isApproveRequired: item?.isApproveRequired,
        isPhotoRequired: item?.isPhotoRequired,
        subtaskTypeId: item?.subtaskTypeId,
        notes: item?.notes,
        files: item?.files,
        checklist: prepareChecklist(item?.checklist || []),
      }));

      subtasks.replace(newSubtasks);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsWorkOrderContainer, trigger, name, item, setValue, register]
  );

  const onNameChange = useCallback(
    (value, option) =>
      onChangeNameField(value, option, onChange, setOptionsSKU),
    [onChange, setOptionsSKU, onChangeNameField]
  );

  const onSKUChange = useCallback(
    (value, option) => {
      const val = value.slice(0, 30).replace(/[^a-zA-Z0-9-]/g, '');
      return onChangeNameField(val, option, onChange, setOptionsName);
    },
    [onChange, setOptionsName, onChangeNameField]
  );

  return (
    <>
      <Inputs>
        <InputsItem isTitle>
          <Controller
            render={({ field }) => (
              <Autocomplete
                value={field.value}
                name={field.name}
                dropdownClassName="autocomplete"
                className="ContainerTitleInput"
                label="Title"
                isRequired
                height="30px"
                options={optionsName}
                error={errors?.name?.message}
                onSearch={onSearchName}
                onChange={(value, option) =>
                  field.onChange(onNameChange(value, option))
                }
                placeholder="Container name"
              />
            )}
            name={`${name}name` as HookFormRecord}
            control={control}
            defaultValue={item?.name}
          />
        </InputsItem>
      </Inputs>
      <Inputs>
        <InputsItem>
          <Controller
            render={({ field }) => (
              <Autocomplete
                value={field.value}
                name={field.name}
                label="SKU"
                width="254px"
                isRequired={isTemplate || company?.isSkuRequired}
                height="30px"
                placeholder="SKU here"
                error={errors?.sku?.message}
                options={optionsSKU}
                onSearch={onSearchSKU}
                onChange={(value, option) =>
                  field.onChange(onSKUChange(value, option))
                }
              />
            )}
            name={`${name}sku` as HookFormRecord}
            control={control}
            defaultValue={item?.sku}
          />
        </InputsItem>
        <MobileMarginTopWrap>
          <InputsItem>
            <Controller
              render={({ field }) => (
                <Input
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  name={field.name}
                  label="Container number"
                  width="254px"
                  height="30px"
                  placeholder="Container number"
                  error={errors?.num?.message}
                />
              )}
              name={`${name}num` as HookFormRecord}
              control={control}
              defaultValue={item?.container?.num}
            />
          </InputsItem>
        </MobileMarginTopWrap>
        <MobileMarginTopWrap>
          <ZoneInputWrap>
            <Controller
              render={({ field }) => (
                <Input
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  name={field.name}
                  label="Zone"
                  width="70px"
                  height="30px"
                  placeholder="Zone"
                  maxLength={4}
                  error={errors?.zone?.message}
                />
              )}
              name={`${name}zone` as HookFormRecord}
              control={control}
              defaultValue={item?.container?.zone}
            />
          </ZoneInputWrap>
        </MobileMarginTopWrap>
      </Inputs>
      <Controller
        render={({ field }) => (
          <TextArea
            onChange={field.onChange}
            value={field.value}
            name={field.name}
            label="Description"
            placeholder="Write description here"
            error={errors?.description?.message}
            style={{ maxWidth: '598px' }}
            width="100%"
            rows={3}
          />
        )}
        name={`${name}description` as HookFormRecord}
        control={control}
        defaultValue={item?.description}
      />
      <ContainerOptions
        errors={errors}
        control={control}
        item={item}
        name={name}
        containerOptions={containerOptions}
      />
      {subtasks && (
        <SubtasksWrapper>
          <SubtasksInfo
            subtasksInWrap={subtasksInWrap}
            isTemplate={isTemplate}
            subtaskTypes={subtaskTypes}
            name={name}
            errors={errors}
            subtasks={subtasks}
            projectId={projectId}
            isInProject={isInProject}
          />
        </SubtasksWrapper>
      )}
    </>
  );
};

export default memo(ContainerForm, isEqual);
