import styled from 'styled-components';
import theme from 'styles/theme';

export const ButtonStyled = styled.span`
  padding-left: 5px;
  color: red;
  cursor: pointer;
  font-weight: 600;
  color: ${theme.colors.lightDeepBlue};
  &:hover {
    text-decoration: underline;
  }
`;
