import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* reportsSaga() {
  yield all([
    fork(W.watchUpdateReport),
    fork(W.watchCreateReport),
    fork(W.watchDeleteReport),
    fork(W.watchGetReportsList),
  ]);
}
