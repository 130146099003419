export enum eventEnum {
  initialized = 'subtask_initialized', // inner backend event
  unblocked = 'subtask_unblocked', // not processed
  assigned = 'subtask_assigned',
  resized = 'subtask_resized',
  reverted = 'subtask_reverted',
  unassigned = 'subtask_unassigned',
  started = 'subtask_started',
  attached = 'subtask_files_attached', // send onReview with files
  validated = 'subtask_validation_requested', // send onReview without files
  rejected = 'subtask_rejected',
  approved = 'subtask_approved',
  finished = 'subtask_finished',
  subtaskPaused = 'subtask_paused',
  subtaskResumed = 'subtask_resumed',
  created = 'project_created',
  updated = 'project_updated',
  patched = 'project_patched', // update project state
  fileAdded = 'project_file_added', // will be in future
  filesDeleted = 'project_files_deleted', // will be in future
  deleted = 'project_deleted', // not processed
  requestedForTakeIn = 'container_requested_for_take_in',
  markAsDelivered = 'container_marked_as_delivered',
  requestForTakeOut = 'container_requested_for_take_out',
  unauthorized = 'Unauthorized',
  standaloneSubtaskCreated = 'standalone_subtask_created',
  standaloneSubtaskUpdated = 'standalone_subtask_updated',
  standaloneSubtaskDeleted = 'standalone_subtask_deleted',
  subtaskLogItemUpdated = 'subtask_log_item_updated',
  undone = 'undone',
  messageAdded = 'message_to_subtask_added',
  messageDeleted = 'subtask_message_deleted',
  messageUpdated = 'subtask_message_edited',
  subtaskUndone = 'subtask_undone',
  systemNotificationsCountUpdated = 'system_notifications_count_updated',
  systemNotificationCreated = 'system_notification_created',
  systemNotificationDeleted = 'system_notification_deleted',
  systemNotificationUpdated = 'system_notification_updated',
  checklistMarkedAsCompleted = 'marked_as_completed',
  checklistMarkedAsUncompleted = 'marked_as_uncompleted',
}
