import { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import {
  resendVerificationCodePromiseCreator,
  verifyEmailPromiseCreator,
} from 'store/auth';
import { ROUTES } from 'store/router';
import ResendAuthCode from 'components/Auth/ResendAuthCode/ResendAuthCode';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { message } from 'utils/message';
import {
  ButtonsWrapper,
  Container,
  Text,
  Title,
  VerificationWrapper,
} from './styles';

const CODE_SIZE = 6;
const ERROR_EMAIL_CONFIRMED = 'Email already confirmed';

const EmailVerification = (): JSX.Element => {
  const verifyEmail = useActionsRoutines(verifyEmailPromiseCreator);
  const resendCode = useActionsRoutines(resendVerificationCodePromiseCreator);

  const query = new URLSearchParams(useLocation().search);
  const userId = query.get('userId');
  const email = query.get('email');
  const history = useHistory();
  const submitButton = useRef(null);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const isButtonEnabled = code.length === CODE_SIZE;

  const handleClearCode = () => {
    setCode('');
    // eslint-disable-next-line no-underscore-dangle
    codeInputRef?.current?.__clearvalues__();
  };

  const handleSubmitCode = async () => {
    try {
      setLoading(true);
      await verifyEmail({ userId, code });
      history.push(ROUTES.HOME);
    } catch (error) {
      if (ERROR_EMAIL_CONFIRMED === error.data.message) {
        setTimeout(() => {
          history.push(ROUTES.HOME);
        }, 1000);
      } else {
        handleClearCode();
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const codeInputRef = useRef(null);
  const changeCode = useCallback(newCode => setCode(newCode), []);

  const handleResendCode = useCallback(async () => {
    try {
      await resendCode({ email });
      handleClearCode();
      await message.success('Verification code has been sent');
    } catch (error) {
      handleClearCode();
      console.error(error);
    }
  }, [email, resendCode]);

  return (
    <AuthWrapper>
      <Container>
        <Title>Verify your email</Title>
        <Text>The verification code has been sent to your email</Text>
        <VerificationWrapper>
          <ReactCodeInput
            ref={codeInputRef}
            fieldWidth={48}
            fieldHeight={56}
            fields={CODE_SIZE}
            className="code-wrapper"
            values={[...code.split('')]}
            onChange={changeCode}
          />
        </VerificationWrapper>

        <ResendAuthCode handleSubmit={handleResendCode} />

        <ButtonsWrapper>
          <Button
            onClick={() => history.push(ROUTES.HOME)}
            kind={BUTTON_TYPES.SECONDARY}
            label="Cancel"
            width="155px"
            height="50px"
            fontSize="16px"
          />
          <Button
            loading={loading}
            onClick={handleSubmitCode}
            ref={submitButton}
            disabled={!isButtonEnabled}
            kind={BUTTON_TYPES.DEFAULT}
            label="Continue"
            width="155px"
            height="50px"
          />
        </ButtonsWrapper>
      </Container>
    </AuthWrapper>
  );
};

export default EmailVerification;
