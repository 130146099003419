import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* profileSaga() {
  yield all([
    fork(W.watchAvatar),
    fork(W.watchUpdateUser),
    fork(W.watchGetProfile),
    fork(W.watchChangePassword),
  ]);
}
