import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import isEqual from 'react-fast-compare';
import { checklistSearchItemsPromiseCreator } from 'store/dashboard';
import { Autocomplete } from './Autocomplete';
import { Checkbox } from 'components/common';
import { useActionsRoutines, useAutocomplete } from 'hooks';
import { RemoveIcon } from 'lib/icons';
import { noop } from 'lodash';
import {
  BottomBorder,
  CheckboxContainer,
  CheckboxWrap,
  DragWrap,
  RemoveIconWrapper,
  Title,
} from './styles';
import { DragTypeEnum, IChecklist } from 'types';

interface ICheckboxItemProps {
  item: IChecklist;
  index: number;
  moveCheckbox: (dragIndex: number, hoverIndex: number) => void;
  deleteCheckbox: (id: string) => void;
  updateCheckbox: (item: IChecklist) => void;
  updateCheckboxTitle: (item: IChecklist) => void;
  disableCheckbox: boolean;
  dragItemStartIndex: number;
  saveSubtasksOrder: (item: IChecklist) => void;
  setDragItemStartIndex: Dispatch<SetStateAction<number>>;
  isProjectDeleted: boolean;
}
const CheckboxItemComponent: FC<ICheckboxItemProps> = ({
  item,
  index,
  moveCheckbox,
  deleteCheckbox,
  updateCheckbox,
  updateCheckboxTitle,
  disableCheckbox,
  dragItemStartIndex,
  saveSubtasksOrder,
  setDragItemStartIndex,
  isProjectDeleted,
}) => {
  const [isChecked, setIsChecked] = useState(item?.isCompleted);
  const [isEdit, setIsEdit] = useState(false);

  const searchChecklist = useActionsRoutines(
    checklistSearchItemsPromiseCreator
  );
  const [onSearch, options, setOptions, onChangeNameField] = useAutocomplete(
    searchChecklist,
    '',
    'text',
    false,
    true
  );
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = useCallback(() => setIsHovered(true), []);
  const handleHoverEnd = useCallback(() => setIsHovered(false), []);
  const handleSetIsChecked = e => {
    if (!disableCheckbox) {
      setIsChecked(e?.target?.checked);
      updateCheckbox({
        ...item,
        isCompleted: e?.target?.checked,
      });
    }
  };

  useEffect(() => {
    if (item.isCompleted !== isChecked) setIsChecked(item?.isCompleted);
  }, [item, isChecked]);

  const input = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isEdit) input?.current?.focus?.();
  }, [isEdit, input]);

  const handleDeleteCheckItem = useCallback(
    () => deleteCheckbox(item?._id),
    [deleteCheckbox, item?._id]
  );

  const handleSetEdit = useCallback(() => {
    if (isProjectDeleted) return;
    setIsEdit(true);
  }, [isProjectDeleted]);

  const handleEndEdit = useCallback(() => {
    // clean empty checkboxItem
    if (item.title.trim().length === 0) handleDeleteCheckItem();

    setIsEdit(false);
  }, [item.title, handleDeleteCheckItem]);

  const refOrder = useRef(null);

  const [{ isDragging }, dragOrder, previewOrder] = useDrag({
    type: DragTypeEnum.checkboxList,
    item: () => {
      setDragItemStartIndex(index);
      return { ...item, index };
    },
    canDrag: !isProjectDeleted,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: () => {
      if (dragItemStartIndex !== index) {
        saveSubtasksOrder({ ...item, position: index + 1 });
      }
    },
  });

  const [, dropOrder] = useDrop({
    accept: DragTypeEnum.checkboxList,
    hover(itemD: any, monitor) {
      if (!refOrder.current) {
        return;
      }
      const dragIndex = itemD.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = refOrder.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCheckbox(dragIndex, hoverIndex);
      itemD.index = hoverIndex;
    },
  });

  dragOrder(dropOrder(refOrder));

  const handleUpdateCheckbox = useCallback(
    (value, option) => {
      updateCheckboxTitle({ ...item, title: value });

      if (value.trim().length > 2) {
        onChangeNameField(value, option, noop, setOptions);
      } else setOptions([]);
    },
    [item, onChangeNameField, setOptions, updateCheckboxTitle]
  );

  const handleSearch = useCallback(
    value => {
      if (value.trim().length > 2) onSearch(value);
      else setOptions([]);
    },
    [onSearch, setOptions]
  );

  return (
    <DragWrap ref={previewOrder} isDragging={isDragging}>
      <CheckboxContainer
        ref={refOrder}
        onMouseOver={handleHover}
        onMouseLeave={handleHoverEnd}
      >
        <CheckboxWrap isEdit={isEdit} $disableCheckbox={disableCheckbox}>
          {!disableCheckbox && (
            <Checkbox
              checked={isChecked}
              onChange={handleSetIsChecked}
              disabled={isProjectDeleted}
            />
          )}
          {isEdit ? (
            <Autocomplete
              style={{ padding: 0 }}
              value={item?.title}
              width={disableCheckbox ? '230px' : '190px'}
              inputref={input}
              onChange={handleUpdateCheckbox}
              onSearch={handleSearch}
              onBlur={handleEndEdit}
              options={options}
            />
          ) : (
            <Title
              $disableCheckbox={disableCheckbox}
              onClick={handleSetEdit}
              $isChecked={isChecked}
            >
              {item?.title}
            </Title>
          )}
        </CheckboxWrap>
        {isHovered && !isProjectDeleted && (
          <RemoveIconWrapper onClick={handleDeleteCheckItem}>
            <RemoveIcon />
          </RemoveIconWrapper>
        )}
      </CheckboxContainer>
      <BottomBorder />
    </DragWrap>
  );
};

export const CheckboxItem = memo(CheckboxItemComponent, isEqual);
