import { call, takeLatest } from 'redux-saga/effects';
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetSubtaskTypes() {
  yield takeLatest(Actions.subtaskTypes.TRIGGER, E.ensureGetSubtaskTypes);
}

export function* watchUpdateSubtaskType() {
  yield call(E.ensureGetSubtaskTypes, {
    type: Actions.updateSubtaskType.TRIGGER,
  });
  yield takeLatest(
    Actions.updateSubtaskType.TRIGGER,
    E.ensureUpdateSubtaskType
  );
}

export function* watchGetSubtaskType() {
  yield takeLatest(Actions.getSubtaskType.TRIGGER, E.ensureGetSubtaskType);
}

export function* watchAddSubtaskType() {
  yield takeLatest(Actions.addSubtaskType.TRIGGER, E.ensureAddSubtaskType);
}

export function* watchDeleteSubtaskType() {
  yield takeLatest(
    Actions.deleteSubtaskType.TRIGGER,
    E.ensureDeleteSubtaskType
  );
}

export function* watchSetSubtaskTypesSearch() {
  yield takeLatest(
    Actions.setSubtaskTypesSearch.TRIGGER,
    E.ensureSetSubtaskTypesSearch
  );
}

export function* watchAddChecklistItem() {
  yield takeLatest(Actions.addChecklistItem.TRIGGER, E.ensureAddChecklistItem);
}

export function* watchAddBulkChecklist() {
  yield takeLatest(Actions.addBulkChecklist.TRIGGER, E.ensureAddBulkChecklist);
}

export function* watchGetChecklistItem() {
  yield takeLatest(Actions.getChecklistItem.TRIGGER, E.ensureGetChecklistItem);
}

export function* watchUpdateChecklistItem() {
  yield takeLatest(
    Actions.updateChecklistItem.TRIGGER,
    E.ensureUpdateChecklistItem
  );
}

export function* watchDeleteChecklistItem() {
  yield takeLatest(
    Actions.deleteChecklistItem.TRIGGER,
    E.ensureDeleteChecklistItem
  );
}

export function* watchAddSubtaskTypeWithChecklist() {
  yield takeLatest(
    Actions.addSubtaskTypeWithChecklist.TRIGGER,
    E.ensureAddSubtaskTypeWithChecklist
  );
}
