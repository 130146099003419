import { CSSProperties } from 'react';
import theme from 'styles/theme';

interface IFilterIconProps {
  color?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const FilterIcon = ({ color, ...props }: IFilterIconProps) => (
  <>
    {/* @ts-ignore */}
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M3.5625 4.75H15.4375V7.125H3.5625V4.75ZM0 0H19V2.375H0V0ZM7.125 9.5H11.875V11.875H7.125V9.5Z"
      />
    </svg>
  </>
);

FilterIcon.defaultProps = {
  color: theme.colors.lightBlue,
};
