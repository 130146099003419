import styled from 'styled-components';
import { Button as BaseButton } from 'components/common';

export const ButtonWrap = styled.div`
  margin-top: 20px;
`;

export const Button = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  border-color: ${({ theme }) => theme.colors.grayDark};
  height: 55px;
  width: 100%;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    border-color: ${({ theme }) => theme.colors.grayDark};
  }
`;
