import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* timesheetsSaga() {
  yield all([
    fork(W.watchTimesheets),
    fork(W.watchResourceTimesheets),
    fork(W.watchEditResourceTime),
    fork(W.watchDeleteShift),
    fork(W.watchGetShiftClosestDates),
    fork(W.watchGetTimesheetsExcel),
    fork(W.watchGetResourceTimesheetsExcel),
  ]);
}
