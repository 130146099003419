export const CloneProject = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" />
    <path
      d="M8.95833 8.4165H7.875V10.0415H6.25V11.1248H7.875V12.7498H8.95833V11.1248H10.5833V10.0415H8.95833V8.4165Z"
      fill="#172B4D"
    />
    <path
      d="M5.16665 14.9167H11.6666C12.2641 14.9167 12.75 14.4308 12.75 13.8333V7.33333C12.75 6.73587 12.2641 6.25 11.6666 6.25H5.16665C4.56919 6.25 4.08331 6.73587 4.08331 7.33333V13.8333C4.08331 14.4308 4.56919 14.9167 5.16665 14.9167ZM5.16665 7.33333H11.6666L11.6677 13.8333H5.16665V7.33333Z"
      fill="#172B4D"
    />
    <path
      d="M13.8333 4.0835H7.33331V5.16683H13.8333V11.6668H14.9166V5.16683C14.9166 4.56937 14.4308 4.0835 13.8333 4.0835Z"
      fill="#172B4D"
    />
  </svg>
);
