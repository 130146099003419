import styled from 'styled-components';

const getColor = color => ({ theme }) => theme.colors[color];

export const ButtonStyled = styled.button`
  --grayDark: ${getColor('grayDark')};
  --mainDarkBlue: ${getColor('mainDarkBlue')};
  --white: ${getColor('white')};
  --grayLight: ${getColor('grayLight')};
  --lightBlue: ${getColor('lightBlue')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid var(--grayDark);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: var(--mainDarkBlue);
  background: var(--white);
  height: 30px;
  padding: 0 50px 0 15px;
  position: relative;
  white-space: nowrap;
  & > div {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    width: 43px;
    background-color: var(--lightBlue);
    height: 30px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
    right: -1px;
    top: -1px;
  }
  &:focus {
    outline: none;
    background: var(--grayLight);
  }
  &:hover {
    border: 1px solid var(--mainDarkBlue);
  }
`;
