import styled from 'styled-components';
import { Button } from 'components/common/Button';

export const ButtonStyled = styled(Button)<{
  [key: string]: any;
}>`
  margin-right: 4px;
  padding: 0;
  padding-left: ${({ label }) => (label ? '3px' : 0)};
  width: ${({ width, size }) => (width ? width : size)}px;
  height: ${({ height, size }) => (height ? height : size)}px;
  background-color: ${({ theme, background }) =>
    background ? background : theme.colors.grayLight}!important;

  svg {
    ${({ label }) => (label ? { 'margin-right': '8px' } : {})}
  }

  span {
    font-size: 16px;
    font-weight: bold;
  }
`;
