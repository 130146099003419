import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const subcontractors = (r: IInitialState) => r.subcontractors;
export const delegatedSubtaskSelector = createSelector(
  subcontractors,
  r => r.delegatedSubtask
);

export const delegatedSubtaskLoadingSelector = createSelector(
  subcontractors,
  r => r.isLoading
);
