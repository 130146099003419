import styled from 'styled-components';

export const SubtaskListItemWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .remove-button {
    visibility: hidden;
  }
  &:hover {
    .remove-button {
      visibility: visible;
    }
  }
`;

export const SubtasksListItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.0801191);
  border-radius: 5px;
`;
