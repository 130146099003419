import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get grades
export const grades = createRoutine('depot/grades');
export const gradesPromiseCreator = promisifyRoutine(grades);
// get grades
export const getGradeById = createRoutine('depot/getGradeById');
export const getGradeByIdPromiseCreator = promisifyRoutine(getGradeById);
// add grade
export const addGrade = createRoutine('depot/addGrade');
export const addGradePromiseCreator = promisifyRoutine(addGrade);
// edit grade
export const editGrade = createRoutine('depot/editGrade');
export const editGradePromiseCreator = promisifyRoutine(editGrade);
// delete grade
export const deleteGrade = createRoutine('depot/deleteGrade');
export const deleteGradePromiseCreator = promisifyRoutine(deleteGrade);
