export const FileIcon = () => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.937 7.18C15.926 7.148 15.917 7.117 15.904 7.086C15.855 6.98 15.794 6.879 15.708 6.793L9.708 0.793C9.622 0.707 9.521 0.646 9.415 0.597C9.385 0.583 9.353 0.575 9.321 0.564C9.237 0.536 9.151 0.518 9.062 0.513C9.04 0.511 9.021 0.5 9 0.5H2C0.897 0.5 0 1.397 0 2.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V7.5C16 7.479 15.989 7.46 15.987 7.438C15.982 7.35 15.965 7.264 15.937 7.18ZM12.586 6.5H10V3.914L12.586 6.5ZM2 18.5V2.5H8V7.5C8 8.053 8.447 8.5 9 8.5H14L14.002 18.5H2Z"
      fill="#737F94"
    />
  </svg>
);
