import { Dispatch, FC, memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { addTasksPromiseCreator } from 'store/admin';
import { DuplicateProjectsTasks } from 'components/DuplicateProjectsTasks';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { Bell } from 'lib/icons/Bell';
import EditIcon from 'lib/icons/EditIcon';
import MenuVerticalIcon from 'lib/icons/MenuVerticalIcon';
import { message } from 'utils/message';
import { Popover } from 'antd';
import { TasksActionsStyled } from './styles';
import {
  MenuItem,
  MenuPopover,
  MenuText,
} from 'components/SubtaskItem/BottomPanel/styles';
import { ButtonEditStyled } from 'pages/Dashboard/Project/ProjectView/styles';
import { IProjectTaskDto } from 'types';

interface TaskActionsProps {
  item: IProjectTaskDto;
  setIsOpenEdit: (index: number) => void;
  index: number;
  tasksCount: number;
  isProjectEditing?: boolean;
  openNotify: ({
    isOpen,
    id,
    item,
  }: {
    isOpen: boolean;
    id: string;
    item: IProjectTaskDto;
  }) => void;
  setEditData?: Dispatch<any>;
  toggleItemTemplateModal?: () => void;
}

const TaskActionsComponent: FC<TaskActionsProps> = ({
  setIsOpenEdit,
  index,
  openNotify,
  item,
  tasksCount,
  isProjectEditing,
  setEditData,
  toggleItemTemplateModal,
}) => {
  const addTemplate = useActionsRoutines(addTasksPromiseCreator);
  const [isMenuPopoverVisible, setIsMenuPopoverVisible] = useState(false);

  const onEditClick = useCallback(
    e => {
      e.stopPropagation();
      setIsOpenEdit(index);
    },
    [setIsOpenEdit, index]
  );

  const handleOpenTaskNotificationModal = useCallback(
    e => {
      e.stopPropagation();
      openNotify({ isOpen: true, id: item?.taskNotificationId, item });
    },
    [item, openNotify]
  );

  const handleSaveTemplate = async () => {
    const data = {
      cost: item.cost,
      descrption: item.description,
      name: item.name,
      price: item.price,
      sku: item.sku,
      subtasks: item.subtasks,
      type: item.type,
    };

    const response = await addTemplate({ data: data });

    if (response) {
      setEditData(response);
      toggleItemTemplateModal();
      message.success(
        `Item template '${item.name}' has been saved successfully!`
      );
    }
    setIsMenuPopoverVisible(false);
  };

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  const handleMenuPopoverVisibleChange = useCallback(
    val => setIsMenuPopoverVisible(val),
    [setIsMenuPopoverVisible]
  );

  const menuPopover = (
    <MenuPopover
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <MenuItem onClick={handleSaveTemplate}>
        <MenuText>Save as a template</MenuText>
      </MenuItem>
    </MenuPopover>
  );

  return (
    <TasksActionsStyled>
      <DuplicateProjectsTasks
        task={item}
        tasksCount={tasksCount}
        isProjectEditing={isProjectEditing}
      />
      <ButtonEditStyled
        kind={BUTTON_TYPES.PRIMARY}
        icon={<Bell size={15} />}
        label="Notify"
        onClick={handleOpenTaskNotificationModal}
      />
      <ButtonEditStyled
        icon={<EditIcon />}
        kind={BUTTON_TYPES.PRIMARY}
        label="Edit"
        onClick={onEditClick}
      />
      <Popover
        getPopupContainer={handleGetPopupContainer}
        content={menuPopover}
        destroyTooltipOnHide
        overlayClassName="MenuPopover"
        trigger="click"
        placement="left"
        onVisibleChange={handleMenuPopoverVisibleChange}
        visible={isMenuPopoverVisible}
      >
        <Button
          onClick={e => {
            e.stopPropagation();
          }}
          kind={BUTTON_TYPES.PRIMARY}
          icon={<MenuVerticalIcon />}
          width="36px"
          height="28px"
          lineheight="0px"
          space="0px"
          padding="0px"
        />
      </Popover>
    </TasksActionsStyled>
  );
};

export const TaskActions = memo(TaskActionsComponent, isEqual);
