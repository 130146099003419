import { takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/sharing';
import * as E from './workers';

export function* watchGetToken() {
  yield takeLatest(Actions.getToken.TRIGGER, E.ensureGetToken);
}

export function* watchGetProjectByToken() {
  yield takeLatest(
    Actions.getProjectByToken.TRIGGER,
    E.ensureGetProjectByToken
  );
}

export function* watchDeleteToken() {
  yield takeLatest(Actions.deleteToken.TRIGGER, E.ensureDeleteToken);
}

export function* watchSendLink() {
  yield takeLatest(Actions.sendLink.TRIGGER, E.ensureSendLink);
}

export function* watchGetSystemNotifications() {
  yield takeLatest(
    Actions.getSystemNotifications.TRIGGER,
    E.ensureGetSystemNotifications
  );
}

export function* watchMarkSystemNotificationAsRead() {
  yield takeLatest(
    Actions.markSystemNotificationsRead.TRIGGER,
    E.ensureMarkSystemNotificationAsRead
  );
}

export function* watchMarkSystemNotificationAsUnread() {
  yield takeLatest(
    Actions.markSystemNotificationsUnread.TRIGGER,
    E.ensureMarkSystemNotificationAsUnread
  );
}

export function* watchGetSystemNotificationUnreadCount() {
  yield takeLatest(
    Actions.getSystemNotificationsUnreadCount.TRIGGER,
    E.ensureGetSystemNotificationsUnreadCount
  );
}

export function* watchDownloadPdf() {
  yield takeLatest(Actions.downloadPdf.TRIGGER, E.ensureDownloadPdf);
}

export function* watchDeleteSystemNotification() {
  yield takeLatest(
    Actions.deleteSystemNotification.TRIGGER,
    E.ensureDeleteSystemNotification
  );
}

export function* watchMarkAllSystemNotificationAsRead() {
  yield takeLatest(
    Actions.markAllSystemNotificationsRead.TRIGGER,
    E.ensureMarkAllSystemNotificationAsRead
  );
}

export function* watchEnsureSyncNotifications() {
  yield takeLatest(
    Actions.syncNotifications.TRIGGER,
    E.ensureSyncNotifications
  );
}
