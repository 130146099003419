import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const signOut = createRoutine('auth/signOut');
export const addAvatar = createRoutine('auth/addAvatar');
export const addAvatarPromiseCreator = promisifyRoutine(addAvatar);
export const getProfile = createRoutine('auth/getProfile');
export const currentLocation = createAction<string>('auth/currentLocation');
export const locationTimezone = createAction<string>('auth/locationTimezone');
// updateUser
export const updateUser = createRoutine('auth/updateUser');
export const updateUserPromiseCreator = promisifyRoutine(updateUser);
export const changePassword = createRoutine('auth/changePassword');
export const changePasswordPromiseCreator = promisifyRoutine(changePassword);
// sign up
export const signUp = createRoutine('auth/signUp');
export const signUpPromiseCreator = promisifyRoutine(signUp);
export const signUpWithInvite = createRoutine('auth/signUpWithInvite');
export const signUpWithInvitePromiseCreator = promisifyRoutine(
  signUpWithInvite
);

// login
export const signIn = createRoutine('auth/signIn');
export const login = createRoutine('auth/login');
export const loginPromiseCreator = promisifyRoutine(login);

// password restore
export const forgotPassword = createRoutine('auth/forgotPassword');
export const forgotPasswordPromiseCreator = promisifyRoutine(forgotPassword);
export const resetPassword = createRoutine('auth/resetPassword');
export const resetPasswordPromiseCreator = promisifyRoutine(resetPassword);

// email verification
export const verifyEmail = createRoutine('auth/verifyEmail');
export const verifyEmailPromiseCreator = promisifyRoutine(verifyEmail);
export const resendVerificationCode = createRoutine(
  'auth/resendVerificationCode'
);
export const resendVerificationCodePromiseCreator = promisifyRoutine(
  resendVerificationCode
);
// invite token validation
export const validateInvite = createRoutine('auth/validateInvite');
export const validateInvitePromiseCreator = promisifyRoutine(validateInvite);
