import styled from 'styled-components';
import theme from 'styles/theme';

export const Wrap = styled.div`
  @keyframes ldio-sqh4xjnahxf-o {
    0% {
      opacity: 1;
      transform: translate(0 0);
    }
    49.99% {
      opacity: 1;
      transform: translate(35px, 0);
    }
    50% {
      opacity: 0;
      transform: translate(35px, 0);
    }
    100% {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
  @keyframes ldio-sqh4xjnahxf {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(35px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .ldio-sqh4xjnahxf div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 35px;
    left: 17px;
  }
  .ldio-sqh4xjnahxf div:nth-child(1) {
    background: ${theme.colors.mainDarkBlue};
    animation: ldio-sqh4xjnahxf 1s linear infinite;
    animation-delay: -0.5s;
  }
  .ldio-sqh4xjnahxf div:nth-child(2) {
    background: ${theme.colors.primary};
    animation: ldio-sqh4xjnahxf 1s linear infinite;
    animation-delay: 0s;
  }
  .ldio-sqh4xjnahxf div:nth-child(3) {
    background: ${theme.colors.mainDarkBlue};
    animation: ldio-sqh4xjnahxf-o 1s linear infinite;
    animation-delay: -0.5s;
  }
  .loadingio-spinner-dual-ball-3ddxoao3mxm {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: ${theme.colors.white};
    border-radius: 25px;
    box-shadow: 8px 10px 23px rgba(0, 0, 0, 0.12);
  }
  .ldio-sqh4xjnahxf {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-sqh4xjnahxf div {
    box-sizing: content-box;
  }
`;
