import styled from 'styled-components';

export const SelectorsWrap = styled.div`
  align-items: flex-start;
  position: relative;
  display: flex;
  gap: 4px;
  /* height: 59px; */
  flex-direction: row;
  padding-top: 7px;
  padding-bottom: 11px;

  input {
    height: 15px !important;
  }

  @media (max-width: 530px) {
    flex-direction: column;
  }

  @media (max-width: 348px) {
    .ant-select-multiple,
    .ant-select-multiple .ant-select-selector {
      max-width: 172px;
    }
  }
`;
