import storageManager from './storageManager';
import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_PATH;

const instance = axios.create({
  baseURL: `${API_URL}`,
});

/* request interceptor */
instance.interceptors.request.use(function (request) {
  Object.assign(request.headers, storageManager.getAuthData());
  return request;
});

/* response interceptor */
instance.interceptors.response.use(
  ({ headers, data }) => {
    if (headers.Authorization) storageManager.setAuthData(headers);
    return data;
  },
  async (responseData = {}) => {
    const { response, message } = responseData;
    const data = response?.data;
    const status = response?.status;

    if (data && data.Authorization) storageManager.setAuthData(data);
    return Promise.reject({ data, message, status });
  }
);

export default instance;
