interface IRemoveProps {
  className?: string;
  color?: string;
}

export const Shape = ({ color }: IRemoveProps) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.298 8.13467L1.0918 4.92847L0 6.01258L4.298 10.3106L13.5245 1.08411L12.4404 0L4.298 8.13467Z"
      fill={color || 'white'}
    />
  </svg>
);
