import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 240px;
`;

export const Title = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 5px;
`;

export const PercentWrap = styled.div`
  margin-bottom: 29px;
`;
