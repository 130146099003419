import { useEffect, useRef } from 'react';

const useOnClickOutside = (callback: () => void) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    const handleClick = event => {
      if (ref.current && !ref.current.contains(event.target)) callback();
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback]);

  return ref;
};

export default useOnClickOutside;
