import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { taskIdSelector } from 'store/gantt';
import EditContainer from './EditContainer';
import ViewContainer from './ViewContainer';
import {
  ContainerOptionsDto,
  IChecklist,
  IProjectResponseDto,
  IProjectTaskDto,
  ISubtaskTypeDto,
  OnProjectUpdateSubmit,
} from 'types';

interface IContainerInfoProps {
  item?: IProjectTaskDto;
  project: IProjectResponseDto;
  index: number;
  removeTask: (
    index: number,
    setDeleteLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => Promise<void>;
  onSubmit: OnProjectUpdateSubmit;
  containerOptions: ContainerOptionsDto;
  isCompleted: boolean;
  setShouldUpdate: (update: boolean) => void;
  editProject: ({
    id,
    data,
  }: {
    id: string;
    data: IProjectResponseDto;
  }) => Promise<void>;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  setIsOpen: (index: number) => void;
  toggleParent: (visible: boolean) => void;
  notAvailableProject?: boolean;
  subtaskTypes?: ISubtaskTypeDto[];
  color: string;
  invoice: string;
  locationId: string;
  openTaskIndex: number;
}
const ContainerInfo = ({
  index,
  removeTask,
  item,
  onSubmit,
  containerOptions,
  isCompleted,
  setShouldUpdate,
  notAvailableProject,
  subtaskTypes,
  editProject,
  project,
  toggleParent,
  openCheckResult,
  color,
  invoice,
  locationId,
  openTaskIndex,
  updateChecklist,
  setIsOpen,
}: IContainerInfoProps) => {
  const taskId = useSelector(taskIdSelector);

  const defaultIsOpenInfo = useMemo(() => item._id === taskId, [item, taskId]);
  const [isOpenInfo, setIsOpenInfo] = useState(false);

  const isOpenContainer = openTaskIndex === index;
  useEffect(() => {
    if (defaultIsOpenInfo) {
      setIsOpenInfo(true);
    }
  }, [defaultIsOpenInfo, item]);

  return isOpenContainer ? (
    <EditContainer
      item={item}
      index={index}
      removeTask={removeTask}
      onSubmit={onSubmit}
      containerOptions={containerOptions}
      setIsOpen={setIsOpen}
      subtaskTypes={subtaskTypes}
      projectId={project?._id}
    />
  ) : (
    <ViewContainer
      item={item}
      isOpenInfo={isOpenInfo}
      setIsOpenInfo={setIsOpenInfo}
      setIsOpen={setIsOpen}
      index={index}
      isCompleted={isCompleted}
      project={project}
      setShouldUpdate={setShouldUpdate}
      notAvailableProject={notAvailableProject}
      editProject={editProject}
      openCheckResult={openCheckResult}
      toggleParent={toggleParent}
      color={color}
      locationId={locationId}
      invoice={invoice}
      updateChecklist={updateChecklist}
    />
  );
};

export default ContainerInfo;
