import { call, put, select } from 'redux-saga/effects';
import { tasksSelector } from 'store/admin';
import { currentLocationSelector } from 'store/auth';
import { deleteSubtaskFiles, ensureDeleteSubtaskFiles } from 'store/dashboard';
import { actions } from 'store/loading';
import { tasksSearchSelector } from '../../admin/selectors';
import { subtaskTemplateFilesBufferSelector } from '../../dashboard/selectors';
// redux
import * as Actions from './actions';
// utils
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IAddTaskPayload,
  IDeleteTaskPayload,
  IEnsureAutocompletePayload,
  IEnsureImportCsvPayload,
  IExportTasksCsvPayload,
  IGetRelatedTasksPayload,
  IPatchTaskPayload,
  ITaskDto,
  UpdateTaskPayload,
} from 'types';

interface IGetTasksPayload {
  payload?: {
    params?: {
      name: string;
    };
    noLoader?: boolean;
  };
}
export function* ensureGetTasks({ payload = {} }: IGetTasksPayload = {}) {
  const currentLocationId = yield select(currentLocationSelector);
  const localStorageLocationId = storageManager.getLocation();
  const stateLocationId = currentLocationId || undefined;

  const locationId = localStorageLocationId || stateLocationId;

  const searchQuery = payload?.params?.name;

  const locationFilter = {
    $or: [{ locationId }, { locationId: { $exists: false } }],
  };

  const searchFilter = {
    $or: [{ name: searchQuery }, { sku: searchQuery }],
  };

  const filter = !!searchQuery
    ? { $and: [locationFilter, searchFilter] }
    : { $and: [locationFilter] };

  const tasks = yield select(tasksSelector);
  const noLoader =
    typeof payload?.noLoader === 'undefined'
      ? !payload?.params && tasks?.length
      : payload?.noLoader;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data: ITaskDto[] = yield call(Api.tasks.tasks, {
      params: {
        filter,
      },
    });
    yield put(Actions.tasks.success({ data, searchQuery }));
  } catch (err) {
    yield put(Actions.tasks.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureContainerOptions() {
  try {
    const data = yield call(Api.tasks.containerOptions);
    yield put(Actions.containerOptions.success(data));
  } catch (err) {
    yield put(Actions.containerOptions.failure(err));
  }
}

export function* ensureDefaultContainerTemplate() {
  try {
    const data = yield call(Api.tasks.defaultContainerTemplate);
    yield put(Actions.defaultContainerTemplate.success(data));
  } catch (err) {
    yield put(Actions.defaultContainerTemplate.failure(err));
  }
}

export function* ensureAutocomplete({
  payload,
}: {
  payload: IEnsureAutocompletePayload;
  type: typeof Actions.autocomplete.TRIGGER;
}) {
  try {
    const currentLocationId = yield select(currentLocationSelector);
    const localStorageLocationId = storageManager.getLocation();
    const stateLocationId = currentLocationId;
    const locationId = localStorageLocationId || stateLocationId;

    const data: ITaskDto[] = yield call(Api.tasks.tasks, {
      params: {
        ...payload,
        filter: {
          $or: [{ locationId }, { locationId: { $exists: false } }],
        },
      },
    });
    yield put(Actions.autocomplete.success(data));
  } catch (err) {
    yield put(Actions.autocomplete.failure(err));
  }
}

export function* ensureDeleteTask({
  payload,
}: {
  payload: IDeleteTaskPayload;
  type: typeof Actions.deleteTask.TRIGGER;
}) {
  try {
    yield call(Api.tasks.deleteTask, payload.id);
    yield call(ensureGetTasks, {
      payload: { params: payload.params, noLoader: true },
    });
    yield put(Actions.deleteTask.success());
  } catch (err) {
    yield put(Actions.deleteTask.failure(err));
  }
}

export function* ensureGetTaskById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getTaskById.TRIGGER;
}) {
  try {
    if (payload) {
      const data: ITaskDto = yield call(Api.tasks.getTaskById, payload);
      yield put(Actions.getTaskById.success(data));
    } else {
      yield put(Actions.getTaskById.success());
    }
  } catch (err) {
    yield put(Actions.getTaskById.failure(err));
  }
}

export function* ensureGetRelatedTasks({
  payload,
}: {
  payload: IGetRelatedTasksPayload;
  type: typeof Actions.getRelatedTasks.TRIGGER;
}) {
  try {
    if (payload) {
      const data: ITaskDto[] = yield call(Api.tasks.getRelatedTasks, payload);
      yield put(Actions.getRelatedTasks.success(data));
    } else {
      yield put(Actions.getRelatedTasks.success());
    }
  } catch (err) {
    yield put(Actions.getRelatedTasks.failure(err));
  }
}

export function* ensureAddTasks({
  payload,
}: {
  payload: IAddTaskPayload;
  type: typeof Actions.addTasks.TRIGGER;
}) {
  try {
    const filesBuffer = yield select(subtaskTemplateFilesBufferSelector);

    if (filesBuffer.length) {
      yield call(ensureDeleteSubtaskFiles, {
        payload: filesBuffer,
        type: deleteSubtaskFiles.TRIGGER,
      });
    }

    const res: ITaskDto = yield call(Api.tasks.postTask, payload.data);
    yield call(ensureGetTasks, {
      payload: { params: payload.params, noLoader: true },
    });
    yield put(Actions.addTasks.success(res));
  } catch (err) {
    yield put(Actions.addTasks.failure(err));
  }
}

export function* ensureUpdateTask({
  payload,
}: {
  payload: UpdateTaskPayload;
  type: typeof Actions.updateTask.TRIGGER;
}) {
  try {
    const filesBuffer = yield select(subtaskTemplateFilesBufferSelector);

    if (filesBuffer.length) {
      yield call(ensureDeleteSubtaskFiles, {
        payload: filesBuffer,
        type: deleteSubtaskFiles.TRIGGER,
      });
    }

    const res: ITaskDto = yield call(Api.tasks.putTask, payload.data);
    yield call(ensureGetTasks, {
      payload: { params: payload.params, noLoader: true },
    });
    yield put(Actions.updateTask.success(res));
  } catch (err) {
    yield put(Actions.updateTask.failure(err));
  }
}

export function* ensurePatchTask({
  payload,
}: {
  payload: IPatchTaskPayload;
  type: typeof Actions.patchTask.TRIGGER;
}) {
  try {
    const tasksSearch = yield select(tasksSearchSelector);
    const params = tasksSearch
      ? { ...payload.params, name: tasksSearch }
      : {
          ...payload.params,
        };

    const res = yield call(Api.tasks.patchTask, payload.data);
    yield call(ensureGetTasks, {
      payload: { params, noLoader: true },
    });
    yield put(Actions.patchTask.success(res));
  } catch (err) {
    yield put(Actions.patchTask.failure(err));
  }
}

export function* ensureExportTasksCsv({
  payload,
}: {
  payload: IExportTasksCsvPayload;
  type: typeof Actions.exportTasksCsv.TRIGGER;
}) {
  try {
    yield put(Actions.exportTasksCsv.request());

    const response = yield call(Api.tasks.exportTasksCsv, payload);

    yield put(Actions.exportTasksCsv.success(response));
  } catch (e) {
    yield put(Actions.exportTasksCsv.failure(e));
  }
}

export function* ensureExportSampleCsv({
  payload,
}: {
  payload: IExportTasksCsvPayload;
  type: typeof Actions.exportSampleCsv.TRIGGER;
}) {
  try {
    yield put(Actions.exportSampleCsv.request());

    const response = yield call(Api.tasks.exportSampleTasksCsv, {
      type: payload.type,
    });

    yield put(Actions.exportSampleCsv.success(response));
  } catch (e) {
    yield put(Actions.exportSampleCsv.failure(e));
  }
}

export function* ensureImportTasksCsv({
  payload,
}: {
  payload: IEnsureImportCsvPayload;
  type: typeof Actions.importTasksCsv.TRIGGER;
}) {
  try {
    yield put(Actions.importTasksCsv.request());

    const response = yield call(Api.tasks.importTasksCsv, payload);

    yield put(Actions.importTasksCsv.success(response));
  } catch (e) {
    yield put(Actions.importTasksCsv.failure(e));
  }
}
