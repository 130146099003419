import styled from 'styled-components';

export const Wrap = styled.div`
  max-width: 1038px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
