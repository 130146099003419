import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMenuPath } from 'utils/helpers';
// hooks
import { useActions } from 'hooks';
// styled
import { MenuStyled } from './styles';

interface ITabsProps {
  items: any[];
}

const Tabs = ({ items }: ITabsProps) => {
  const [selectedKeys, setSelectedKeys] = useState();
  const pushHandler = useActions(push);
  const location = useLocation();

  useEffect(() => {
    setSelectedKeys(getMenuPath(location.pathname, 3));
  }, [location]);

  const handleClick = useCallback(
    ({ key }) => selectedKeys !== key && pushHandler(key),
    [pushHandler, selectedKeys]
  );

  return (
    <MenuStyled
      onClick={handleClick}
      defaultSelectedKeys={[location.pathname]}
      mode="horizontal"
      selectedKeys={[selectedKeys]}
      items={items}
    />
  );
};

export default Tabs;
