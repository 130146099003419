import { ChangeEvent, useState } from 'react';
import ArchiveProjectButton from '../Header/Caption/ArchiveProjectButton';
import CreatePublicLink from 'components/CreatePublicLink';
import Modal from 'components/Modal';
import {
  BUTTON_TYPES,
  Button,
  ConfirmationPopover,
  DuplicateButton,
  IconButton,
} from 'components/common';
import { PROJECTS_STATUSES } from 'lib/constants';
import { CloneProject, ShareIcon, TrashIcon } from 'lib/icons';
import EditIcon from 'lib/icons/EditIcon';
import theme from 'styles/theme';
import { ProjectActionButtons } from './styles';

interface IProjectActionsProps {
  openEdit: (isClone: boolean) => void;
  setCloneCount: (amount: number) => void;
  notAvailableProject: boolean;
  deleteProjectLoading: boolean;
  closeModal: () => void;
  deleteProject: () => void;
  data: any;
}

const ProjectActions = ({
  data: { _id, generatedApiKey, isArchived, status },
  openEdit,
  setCloneCount,
  notAvailableProject,
  deleteProjectLoading,
  closeModal,
  deleteProject,
}: IProjectActionsProps): JSX.Element => {
  const [isOpenShare, setIsOpenShare] = useState(false);
  const isShowShareButton = !isArchived && PROJECTS_STATUSES.DELETED !== status;

  const handleOpenEditWithCloneCount = (amount: number) => {
    setCloneCount(amount);
    openEdit(true);
  };

  return (
    <ProjectActionButtons>
      {!notAvailableProject && (
        <Button
          onClick={() => openEdit(false)}
          kind={BUTTON_TYPES.PRIMARY}
          icon={<EditIcon />}
          label="Edit"
          fontSize="12px"
          height="28px"
          lineheight="0px"
          space="4px"
        />
      )}

      <DuplicateButton
        count={1}
        onConfirm={handleOpenEditWithCloneCount}
        title="Duplicate project"
      />

      {!isArchived && (
        <ArchiveProjectButton title="Archive" id={_id} callback={closeModal} />
      )}

      {isShowShareButton && (
        <Button
          onClick={() => setIsOpenShare(true)}
          kind={BUTTON_TYPES.PRIMARY}
          icon={<ShareIcon />}
          label="Share"
          fontSize="13px"
          height="30px"
          lineheight="0px"
          space="2px"
        />
      )}
      <Modal
        visible={isOpenShare}
        onCancel={() => setIsOpenShare(false)}
        width={481}
        padding="25px 22px 21px 17px"
      >
        <CreatePublicLink
          setIsOpenShare={setIsOpenShare}
          isOpenShare={isOpenShare}
          generatedApiKey={generatedApiKey}
          id={_id}
        />
      </Modal>

      <ConfirmationPopover
        loading={deleteProjectLoading}
        text="Are you sure you want to delete this project?"
        onOk={deleteProject}
      >
        <IconButton
          width={36}
          height={30}
          title="Delete project"
          onClick={null}
          icon={<TrashIcon />}
          background={theme.colors.lightDanger}
          hidden={notAvailableProject}
        />
      </ConfirmationPopover>
    </ProjectActionButtons>
  );
};

export default ProjectActions;
