import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: undefined,
    windowHeight: undefined,
    deviceWidth: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        deviceWidth: window.screen.width,
      });
    }

    function handleResizeDebounce() {
      debounce(() => {
        handleResize();
      }, 200);
    }

    window.addEventListener('resize', handleResizeDebounce);

    handleResize();

    return () => window.removeEventListener('resize', handleResizeDebounce);
  }, []);

  return windowSize;
};

export default useWindowSize;
