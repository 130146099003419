import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get work orders
export const workOrders = createRoutine('inbound/workOrders');
export const workOrdersPromiseCreator = promisifyRoutine(workOrders);
export const workOrdersInfo = createRoutine('inbound/workOrdersInfo');
// get work order by id
export const workOrder = createRoutine('inbound/workOrder');
export const workOrderPromiseCreator = promisifyRoutine(workOrder);
// divide tasks
export const divideTasks = createRoutine('inbound/divideTasks');
export const divideTasksPromiseCreator = promisifyRoutine(divideTasks);
// create project from work order
export const createProjectFormWorkOrder = createRoutine(
  'inbound/createProjectFormWorkOrder'
);
export const createProjectFormWorkOrderPromiseCreator = promisifyRoutine(
  createProjectFormWorkOrder
);
// delete work order
export const deleteWorkOrders = createRoutine('inbound/deleteWorkOrders');
export const deleteWorkOrdersPromiseCreator = promisifyRoutine(
  deleteWorkOrders
);
