import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  employeesValuesSelector,
  getEmployeesValuesPromiseCreator,
} from 'store/company';
import { Input, Label, RadioGroup } from 'components/common';
import PhoneNumber from 'components/common/PhoneNumber/PhoneNumber';
import { RequiredSign } from 'components/common/RequiredSign';
import { useRequest } from 'hooks/useRequest';
import { EmployeesField, SpinItem, Wrapper } from './styles';
import { LoadingOutlinedStyled } from 'components/SubtaskActions/styles';

interface AdditionalInformationProps {
  isUserInvited: boolean;
}

const AdditionalInformation = ({
  isUserInvited,
}: AdditionalInformationProps): JSX.Element => {
  const employeesOptions = useSelector(employeesValuesSelector);
  const [getEmployeesValues, employeesValuesLoading] = useRequest(
    getEmployeesValuesPromiseCreator
  );

  // const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  const phoneNumber = getValues('companyPhoneNumber');

  useEffect(() => {
    if (!!phoneNumber?.length && phoneNumber[0] !== '+') {
      setValue('companyPhoneNumber', `+${phoneNumber}`);
    }
  }, [phoneNumber, setValue]);

  useEffect(() => {
    getEmployeesValues().catch(console.error);
  }, [getEmployeesValues]);
  // TODO - add this back in when we have a terms and conditions
  // const handleCancel = useCallback(() => {
  //   setIsModalVisible(false);
  // }, []);

  // const handleOk = useCallback(() => {
  //   handleCancel();
  //   setValue('acceptTerms', true);
  // }, []);

  // const handleOpenModal = useCallback(() => {
  //   setIsModalVisible(true);
  // }, []);

  // const handleChangeTerms = () => {
  //   if (!getValues('acceptTerms')) {
  //     handleOpenModal();
  //   } else {
  //     setValue('acceptTerms', false);
  //   }
  // };

  // const acceptTermsError = errors?.acceptTerms?.message;
  return (
    <Wrapper>
      <Controller
        render={({ field }) => (
          <Input
            disabled={isUserInvited}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="Company name"
            isRequired
            autocomplete={false}
            width="100%"
            height="30px"
            style={{ paddingBottom: '15px' }}
            placeholder="Enter your company name"
            error={errors?.companyName?.message}
          />
        )}
        name="companyName"
        control={control}
      />

      {!isUserInvited && (
        <>
          <Controller
            render={({ field }) => (
              <PhoneNumber
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                label="Company phone"
                width="100%"
                height="30px"
                isRequired
                style={{ paddingBottom: '15px' }}
                placeholder="+1 (000) 000-0000"
                error={errors?.companyPhoneNumber?.message}
              />
            )}
            name="companyPhoneNumber"
            control={control}
          />

          {employeesValuesLoading ? (
            <SpinItem
              spinning={employeesValuesLoading}
              size="large"
              indicator={
                <LoadingOutlinedStyled style={{ fontSize: 24 }} spin />
              }
            />
          ) : (
            <>
              {!!employeesOptions.length && (
                <EmployeesField>
                  <Label>
                    Employees <RequiredSign> *</RequiredSign>
                  </Label>
                  <Controller
                    name="employees"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        value={field.value}
                        name={field.name}
                        options={employeesOptions}
                        onChange={event => field.onChange(event.target.value)}
                        error={errors?.employees?.message}
                      />
                    )}
                  />
                </EmployeesField>
              )}
            </>
          )}
        </>
      )}

      {/* TODO - add this back in when we have a terms and conditions
      <Terms>
        <Controller
          render={({ field }) => (
            <Checkbox
              withTooltip={false}
              checked={field.value}
              label={
                <div>
                  I agree with{' '}
                  <TermsLink onClick={handleOpenModal}>
                    Terms and Conditions
                  </TermsLink>
                </div>
              }
              valueName="checked"
              onChange={handleChangeTerms}
            />
          )}
          name="acceptTerms"
          control={control}
          defaultValue={false}
        />
        {!!acceptTermsError && <Error>{acceptTermsError}</Error>}
        {isModalVisible && (
          <TermsModal
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
          />
        )}
      </Terms> */}
    </Wrapper>
  );
};

export default memo(AdditionalInformation);
