import {
  markSystemNotificationsReadPromiseCreator,
  markSystemNotificationsUnreadPromiseCreator,
} from 'store/sharing';
import { useRequest } from 'hooks/useRequest';
import theme from 'styles/theme';
import { Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Circle, OrangeDot, Wrapper } from './styles';

interface IToggleViewNotificationProps {
  isSeen: boolean;
  notificationId: string;
}

const ToggleViewNotification = ({
  isSeen,
  notificationId,
}: IToggleViewNotificationProps): JSX.Element => {
  const [markAsRead, markAsReadLoading] = useRequest(
    markSystemNotificationsReadPromiseCreator
  );
  const [markAsUnread, markAsUnreadLoading] = useRequest(
    markSystemNotificationsUnreadPromiseCreator
  );
  const toggleView = async () => {
    try {
      const request = isSeen ? markAsUnread : markAsRead;
      await request(notificationId);
    } catch (error) {
      console.error(error);
    }
  };

  const tooltipText = isSeen ? 'Mark as unread' : 'Mark as read';
  const isLoading = markAsReadLoading || markAsUnreadLoading;
  return (
    <Wrapper>
      <Tooltip
        title={tooltipText}
        destroyTooltipOnHide
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        placement="left"
      >
        <Circle onClick={!isLoading && toggleView}>
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 8, color: theme.colors.orange }}
                  spin
                />
              }
            />
          ) : (
            <OrangeDot $isSeen={isSeen} />
          )}
        </Circle>
      </Tooltip>
    </Wrapper>
  );
};

export default ToggleViewNotification;
