import styled from 'styled-components';
// styles
import { commonInputStyles } from 'components/common/commonInputStyles';
// components
import { Input } from 'antd';

export const TextAreaStyled = styled(Input.TextArea)<any>`
  ${commonInputStyles};
  resize: none;
`;
