import styled from 'styled-components';
import { Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const Wrap = styled.div`
  min-width: 134px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 16px;
`;

export const Status = styled.span<{
  background?: string;
  border?: string;
}>`
  cursor: pointer;
  color: ${props => props.color};
  background: ${props => props.background};
  border: ${props => props.border};
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 0 10px;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    margin-right: 3px;
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoadingOutlinedStyled = styled(LoadingOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const DividerItem = styled(Divider)`
  margin: 8px 0;
`;
