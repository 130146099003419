import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// redux
import { addSubtaskTypeWithChecklistPromiseCreator } from 'store/admin';
import { isAdminSelector, isSuperAdminSelector } from 'store/auth';
import { getOnlyDigits, hoursParser } from 'utils/helpers';
// components
import {
  Checkbox,
  MobileMarginTopWrap,
  NumberInput,
  SelectInput,
} from 'components/common';
// utils
import { useActionsRoutines } from 'hooks';
// styles
import { CheckBoxes, SubtasksListItemForm } from './styles';
import {
  HookFormRecord,
  ISubtask,
  ISubtasksErrors,
  SubtaskStatusesEnum,
} from 'types';

interface IMainInfoProps {
  subtask?: ISubtask;
  name?: string;
  i?: number;
  isTemplate?: boolean;
  subtaskTypes?: any[];
  errors?: ISubtasksErrors;
  setError: any;
  setSubtaskTypeId?: (key: string) => void;
  setSubtaskName?: Dispatch<SetStateAction<string>>;
  defaultHours?: number;
}

const SubtaskForm = ({
  subtask,
  subtaskTypes,
  i,
  name,
  errors,
  setSubtaskTypeId,
  setSubtaskName,
  setError,
  isTemplate,
  defaultHours,
}: IMainInfoProps) => {
  const { setValue, control, watch } = useFormContext();
  const isAdmin = useSelector(isAdminSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);

  const [isCreateSubtaskLoading, setIsCreateSubtaskLoading] = useState(false);
  const addSubtaskTypeWithChecklist = useActionsRoutines(
    addSubtaskTypeWithChecklistPromiseCreator
  );

  const hoursFieldName = useMemo(
    () =>
      isTemplate
        ? `${name}subtasks[${i}].templateHours`
        : (`${name}subtasks[${i}].plannedHours` as any),
    [name, isTemplate, i]
  );
  const templateHoursFieldName =
    `${name}subtasks[${i}].templateHours` as HookFormRecord;

  useEffect(() => {
    if (defaultHours) setValue(hoursFieldName, defaultHours);
  }, [name, defaultHours, hoursFieldName, setValue]);

  const isNotAllowToChangeOrder = useMemo(
    () =>
      [
        SubtaskStatusesEnum.IN_PROGRESS,
        SubtaskStatusesEnum.PAUSED,
        SubtaskStatusesEnum.ON_REVIEW,
        SubtaskStatusesEnum.DONE,
        SubtaskStatusesEnum.FAILED,
      ].includes(subtask.status),
    [subtask.status]
  );

  const isNotAllowToChangeCheckbox = useMemo(
    () =>
      [SubtaskStatusesEnum.ON_REVIEW, SubtaskStatusesEnum.DONE].includes(
        subtask.status
      ),
    [subtask.status]
  );
  const isNotAllowToChangeHours = useMemo(
    () =>
      [
        SubtaskStatusesEnum.ON_REVIEW,
        SubtaskStatusesEnum.DONE,
        SubtaskStatusesEnum.FAILED,
      ].includes(subtask.status),
    [subtask.status]
  );
  const isTemplateHoursEditable = isAdmin || isSuperAdmin;
  const isNewSubtask = !subtask._id;

  const createSubtaskType = useCallback(
    value => {
      if (value.length > 100) {
        return setError(
          `${name}subtasks[${i}].name`,
          'length',
          'Max length is 100 chars'
        );
      }
      setIsCreateSubtaskLoading(true);

      const defaultData = {
        name: value,
      };

      const templateHours = watch(hoursFieldName);

      const data = templateHours
        ? { ...defaultData, defaultHours: templateHours }
        : defaultData;

      const subtaskChecklist = watch(
        `${name}subtasks[${i}].checklist` as HookFormRecord
      );

      addSubtaskTypeWithChecklist({ data, subtaskChecklist })
        .then(res => {
          setValue(
            `${name}subtasks[${i}].subtaskTypeId` as HookFormRecord,
            res._id
          );
          setValue(`${name}subtasks[${i}].name` as HookFormRecord, res.name);
        })
        .catch(err => console.error(err))
        .finally(() => setIsCreateSubtaskLoading(false));
    },
    [
      addSubtaskTypeWithChecklist,
      name,
      i,
      setValue,
      setError,
      setIsCreateSubtaskLoading,
      hoursFieldName,
      watch,
    ]
  );

  const onSubtaskNameChange = (value, valuesObj) => {
    setSubtaskName(value);
    setSubtaskTypeId(valuesObj.key);
    return value;
  };

  const onOrderChange = value => (value < 1 ? 1 : value);

  const subtaskHasResource = !!subtask.resource || !!subtask?.generatedApiKey;

  return (
    <SubtasksListItemForm>
      <div>
        <Controller
          render={({ field }) => (
            <NumberInput
              value={field.value}
              name={field.name}
              label="Order"
              width="65px"
              height="30px"
              disabled={isNotAllowToChangeOrder}
              onChange={value => field.onChange(onOrderChange(value))}
              error={errors?.subtasks && errors?.subtasks[i]?.order?.message}
              parser={getOnlyDigits}
            />
          )}
          control={control}
          name={`${name}subtasks[${i}].order` as HookFormRecord}
          defaultValue={subtask?.order}
        />
      </div>
      <MobileMarginTopWrap maxWidth={400}>
        <div>
          <Controller
            render={({ field }) => (
              <SelectInput
                disabled={subtaskHasResource}
                value={field.value}
                name={field.name}
                label="Task"
                isRequired
                onChange={(value, valueObj) =>
                  field.onChange(onSubtaskNameChange(value, valueObj))
                }
                width="151px"
                placeholder="Select"
                options={subtaskTypes}
                showSearch
                isReturnLabel
                isAllowCreate
                onCreate={createSubtaskType}
                isCreateLoading={isCreateSubtaskLoading}
                error={errors?.subtasks && errors?.subtasks[i]?.name?.message}
              />
            )}
            control={control}
            name={`${name}subtasks[${i}].name` as HookFormRecord}
            defaultValue={subtask?.name}
          />
        </div>
      </MobileMarginTopWrap>
      <MobileMarginTopWrap>
        <div>
          <Controller
            render={({ field }) => (
              <NumberInput
                wrapError
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                label="Hours"
                width="96px"
                isRequired
                height="30px"
                disabled={isNotAllowToChangeHours}
                placeholder="0 hours"
                error={
                  errors?.subtasks &&
                  (errors?.subtasks[i]?.plannedHours?.message ||
                    errors?.subtasks[i]?.templateHours?.message)
                }
                noicon="true"
                parser={hoursParser}
              />
            )}
            name={hoursFieldName}
            control={control}
            defaultValue={
              typeof subtask?.plannedHours === 'number'
                ? subtask?.plannedHours
                : subtask?.templateHours
            }
          />
        </div>
      </MobileMarginTopWrap>
      {!isTemplate && !isNewSubtask && (
        <MobileMarginTopWrap>
          <Controller
            render={({ field }) => (
              <NumberInput
                wrapError
                onChange={field.onChange}
                value={field.value}
                name={field.name}
                label="Template hours"
                width="100px"
                height="30px"
                disabled={!isTemplateHoursEditable}
                placeholder="0 hours"
                error={
                  errors?.subtasks &&
                  errors?.subtasks[i]?.templateHours?.message
                }
                noicon="true"
                parser={hoursParser}
              />
            )}
            name={templateHoursFieldName}
            control={control}
            defaultValue={subtask?.templateHours}
          />
        </MobileMarginTopWrap>
      )}
      <div>
        <CheckBoxes>
          <Controller
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                label="Require approval"
                disabled={isNotAllowToChangeCheckbox}
                onChange={event => field.onChange(event.target.checked)}
              />
            )}
            name={`${name}subtasks[${i}].isApproveRequired` as HookFormRecord}
            control={control}
            defaultValue={subtask?.isApproveRequired}
          />
          <Controller
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                label="Require photo"
                disabled={isNotAllowToChangeCheckbox}
                onChange={event => field.onChange(event.target.checked)}
              />
            )}
            name={`${name}subtasks[${i}].isPhotoRequired` as HookFormRecord}
            control={control}
            defaultValue={subtask?.isPhotoRequired}
          />
        </CheckBoxes>
      </div>
    </SubtasksListItemForm>
  );
};

export default memo(SubtaskForm, isEqual);
