import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { Row } from 'antd';

export const Wrap = styled(Row)`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const WrapLink = styled.div`
  width: 100%;
`;

export const LinkInput = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  box-sizing: border-box;
  align-items: center;
  padding-left: 13px;
  & > button {
    border-radius: 0 5px 5px 0;
    &:hover,
    &:focus {
      border-radius: 0 5px 5px 0;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  padding-left: 0 !important;
  padding-top: 5px;
  text-decoration-line: underline;
  & > span {
    margin-left: 0 !important;
  }
`;
