import styled from 'styled-components';
import { getOpacityBackground } from 'utils/helpers';

export const PreviewWrap = styled.div<any>`
  background: ${({ theme }) => theme.colors.white};
  width: ${props => `${props.width}px`};
  height: 18px;
  border-radius: 3px;
  opacity: 0.999;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
`;

export const PreviewInner = styled.div`
  display: flex;
  align-items: center;
  background: ${props => getOpacityBackground(props.color)};
  color: ${props => props.color};
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 11px;
  overflow: hidden;
  padding: 0 7px;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
