import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/subcontractors';
import * as E from './workers';

export function* watchGetDelegatedSubtask() {
  yield takeLatest(
    Actions.getDelegatedSubtask.TRIGGER,
    E.ensureGetDelegatedSubtask
  );
  yield call(E.ensureGetDelegatedSubtask);
}

export function* watchStartSubtask() {
  yield takeLatest(Actions.startSubtask.TRIGGER, E.ensureStartSubtask);
}

export function* watchSendSubtaskToReview() {
  yield takeLatest(
    Actions.sendSubtaskToReview.TRIGGER,
    E.ensureSendSubtaskToReview
  );
}

export function* watchMarkSubtaskAsDone() {
  yield takeLatest(
    Actions.markSubtaskAsDone.TRIGGER,
    E.ensureMarkSubtaskAsDone
  );
}

export function* watchMarkSubtaskAsPaused() {
  yield takeLatest(
    Actions.markSubtaskAsPaused.TRIGGER,
    E.ensureMarkSubtaskAsPaused
  );
}

export function* watchAttachToSubtask() {
  yield takeLatest(Actions.attachToSubtask.TRIGGER, E.ensureAttachToSubtask);
}
