import {
  ConflictContainer,
  ConflictDescription,
  ConflictName,
  ConflictTitle,
} from './styles';
import { IConflictSubtaskErorr } from 'types';

interface IConflictSubtask {
  conflictSubtask: IConflictSubtaskErorr;
}

export const ConflictSubtask = ({ conflictSubtask }: IConflictSubtask) =>
  conflictSubtask ? (
    <ConflictContainer>
      <ConflictTitle>You already have a subtask in progress</ConflictTitle>
      <ConflictName>
        {conflictSubtask.invoice || conflictSubtask.taskName}{' '}
        {conflictSubtask.subtaskName} in progress.
      </ConflictName>
      <ConflictDescription>
        If you want to start a new subtask, choose completion percentage for the{' '}
        <b>
          {conflictSubtask.invoice || conflictSubtask.taskName}{' '}
          {conflictSubtask.subtaskName}
        </b>{' '}
        in progress first. It will be paused.
      </ConflictDescription>
    </ConflictContainer>
  ) : (
    <>Choose completion percentage for the task</>
  );
