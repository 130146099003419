import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get timesheets
export const timesheets = createRoutine('timesheets/getTimesheets');
export const timesheetsPromiseCreator = promisifyRoutine(timesheets);
// get resource timesheets
export const resourceTimesheets = createRoutine(
  'timesheets/getResourceTimesheets'
);
export const resourceTimesheetsPromiseCreator = promisifyRoutine(
  resourceTimesheets
);
// edit resource time
export const editResourceTime = createRoutine('timesheets/editResourceTime');
export const editResourceTimePromiseCreator = promisifyRoutine(
  editResourceTime
);
// delete shift
export const deleteShift = createRoutine('timesheets/deleteShift');
export const deleteShiftPromiseCreator = promisifyRoutine(deleteShift);
// dates
export const setTimesheetsDates = createRoutine(
  'timesheets/setTimesheetsDates'
);
// search
export const setTimesheetsSearch = createRoutine('timesheets/setSearch');
// get closest dates
export const getShiftClosestDates = createRoutine(
  'timesheets/getShiftClosestDates'
);
export const getShiftClosestDatesPromiseCreator = promisifyRoutine(
  getShiftClosestDates
);
// excel
export const getTimesheetsExcel = createRoutine(
  'timesheets/getTimesheetsExcel'
);
export const getTimesheetsExcelPromiseCreator = promisifyRoutine(
  getTimesheetsExcel
);
export const getResourceTimesheetsExcel = createRoutine(
  'timesheets/getResourceTimesheetsExcel'
);
export const getResourceTimesheetsExcelPromiseCreator = promisifyRoutine(
  getResourceTimesheetsExcel
);
