import { memo } from 'react';
import theme from 'styles/theme';

interface IPlusIconComponentProps {
  color?: string;
}

const PlusIconComponent = ({
  color = theme.colors.mainDarkBlue,
}: IPlusIconComponentProps): JSX.Element => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50357 1.00714C5.50357 0.729028 5.27811 0.503572 5 0.503571C4.72189 0.503571 4.49643 0.729028 4.49643 1.00714L4.49643 4.49643L1.00714 4.49643C0.729028 4.49643 0.503571 4.72189 0.503571 5C0.503571 5.27811 0.729028 5.50357 1.00714 5.50357L4.49643 5.50357L4.49643 8.99286C4.49643 9.27097 4.72189 9.49643 5 9.49643C5.27811 9.49643 5.50357 9.27097 5.50357 8.99286L5.50357 5.50357L8.99286 5.50357C9.27097 5.50357 9.49643 5.27811 9.49643 5C9.49643 4.72189 9.27097 4.49643 8.99286 4.49643L5.50357 4.49643L5.50357 1.00714Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
  </svg>
);

const PlusIcon = memo(PlusIconComponent);

export default PlusIcon;
