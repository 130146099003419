import { memo } from 'react';
import { Error, Label } from 'components/common';
import { InputProps } from 'antd';
// styles
import { InputStyled } from './styles';

interface INumberInputProps extends InputProps {
  placeholder?: string;
  defaultValue?: string;
  label?: string | Element;
  name?: string;
  formatter?: (value: number) => string;
  value?: string | number;
  width?: string;
  height?: string;
  error?: string;
  isPriceInput?: boolean;
  isRequired?: boolean;
  noicon?: string;
  parser?: (value: string) => string;
  disabled?: boolean;
  wrapError?: boolean;
}

export const NumberInput = memo(
  ({
    label,
    name,
    width,
    error,
    isPriceInput,
    isRequired,
    wrapError,
    ...rest
  }: INumberInputProps) => (
    <div style={{ width }}>
      {label && (
        <Label>
          {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : ''}
        </Label>
      )}
      <InputStyled
        {...{
          ...rest,
          width,
          error,
          type: 'text',
          step: 1,
          id: name,
          min: 0,
          value: isPriceInput && rest.value === 0 ? '' : rest.value,
        }}
      />
      {error && <Error wrap={wrapError}>{error}</Error>}
    </div>
  )
);
