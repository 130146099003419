import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const getEmailTemplates = createRoutine('getEmailTemplates');
export const getEmailTemplatesPromiseCreator = promisifyRoutine(
  getEmailTemplates
);

export const getEmailTemplateById = createRoutine('getEmailTemplateById');
export const getEmailTemplateByIdPromiseCreator = promisifyRoutine(
  getEmailTemplateById
);

export const createEmailTemplate = createRoutine('createEmailTemplate');
export const createEmailTemplatePromiseCreator = promisifyRoutine(
  createEmailTemplate
);

export const updateEmailTemplate = createRoutine('updateEmailTemplate');
export const updateEmailTemplatePromiseCreator = promisifyRoutine(
  updateEmailTemplate
);

export const deleteEmailTemplate = createRoutine('deleteEmailTemplate');
export const deleteEmailTemplatePromiseCreator = promisifyRoutine(
  deleteEmailTemplate
);
