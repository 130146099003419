import { useMemo, useState } from 'react';
import { getTimeFromPercent } from 'utils/helpers';
import { Clocks } from 'lib/icons';
import CollapseIcon from 'lib/icons/CollapseIcon';
import { Popover } from 'antd';
import {
  ClockWrap,
  DoubleArrowWrap,
  HoursContainer,
  HoursContent,
  HoursItem,
  HoursWrap,
  SelectValue,
  Time,
  Title,
  Value,
} from './styles';

interface IHoursProps {
  hours?: number;
  actualHours?: number;
  plannedHours?: number;
  templateHours: number;
  inAdmin?: boolean;
  isRecurring?: boolean;
  isMobile?: boolean;
  isReassignment?: boolean;
}

export const Hours = ({
  hours,
  actualHours,
  templateHours,
  plannedHours,
  inAdmin,
  isRecurring,
  isMobile,
  isReassignment,
}: IHoursProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const isTemplate = inAdmin || isRecurring;

  const actualTime = getTimeFromPercent(actualHours);
  const plannedTime = getTimeFromPercent(plannedHours);
  const templateTime = getTimeFromPercent(templateHours);
  const recurringTime = getTimeFromPercent(hours);
  const adminTime = useMemo(() => {
    if (isRecurring) {
      return recurringTime;
    }

    if (inAdmin) {
      return templateTime;
    }

    return plannedTime || templateTime;
  }, [isRecurring, inAdmin, recurringTime, templateTime, plannedTime]);

  const onPopoverVisibleChange = (visible: boolean) => {
    setIsPopoverVisible(visible);
  };

  return (
    <>
      {!isTemplate ? (
        <Popover
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          placement="bottom"
          trigger="click"
          visible={isPopoverVisible}
          onVisibleChange={visible => onPopoverVisibleChange(visible)}
          content={
            <>
              <HoursContent>
                <HoursItem>
                  <Title>Planned</Title>
                  <Value>{plannedTime}</Value>
                </HoursItem>
                <HoursItem>
                  <Title>Actual</Title>
                  <Value>{actualTime}</Value>
                </HoursItem>
                <HoursItem>
                  <Title>Template</Title>
                  <Value>{templateTime || 0}</Value>
                </HoursItem>
              </HoursContent>
            </>
          }
          destroyTooltipOnHide
        >
          <HoursContainer>
            <HoursWrap $isReassignment={isReassignment} isMobile={isMobile}>
              <ClockWrap>
                <Clocks />
              </ClockWrap>
              {!isMobile && <SelectValue>{actualTime}</SelectValue>}
              <DoubleArrowWrap isOpen={isPopoverVisible}>
                <CollapseIcon />
              </DoubleArrowWrap>
            </HoursWrap>
          </HoursContainer>
        </Popover>
      ) : (
        <Time>{adminTime}</Time>
      )}
    </>
  );
};
