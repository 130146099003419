import { call, takeLatest } from 'redux-saga/effects';
// redux
import { addColor, colors, deleteColor } from './actions';
import * as E from './workers';

export function* watchGetColors() {
  yield call(E.ensureGetColors, { type: colors.TRIGGER });
  yield takeLatest(colors.TRIGGER, E.ensureGetColors);
}

export function* watchAddColor() {
  yield takeLatest(addColor.TRIGGER, E.ensureAddColor);
}

export function* watchDeleteColor() {
  yield takeLatest(deleteColor.TRIGGER, E.ensureDeleteColor);
}
