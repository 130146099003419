import { all, call, put, select } from 'redux-saga/effects';
import { currentLocationSelector } from 'store/auth';
import { companySelector } from 'store/company';
import * as Dashboard from 'store/dashboard';
import { actions } from 'store/loading';
import { searchSelector } from 'store/predefined';
import { location } from 'store/router';
import * as Sharing from 'store/sharing';
import {
  getPartialProjectUpdateRequest,
  prepareProjectData,
  sortTasks,
  updateGeneratedKey,
  updateProjectAfterAddingSubtasksFiles,
} from './helpers';
import { getStartEndWeek } from 'utils/helpers';
import { dropFromProjectSelector } from './../gantt/selectors';
import {
  locationsSelector,
  projectsFilterSelector,
} from './../predefined/selectors';
import * as A from './actions';
// redux
import {
  assignConflictDataSelector,
  ganttDatesSelector,
  ganttWeekViewSelector,
  projectSelector,
  projectsSelector,
  projectsSortBySelector,
  projectsTotalSelector,
  subtaskFilesBufferSelector,
  subtaskTemplateFilesBufferSelector,
} from './selectors';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
// utils
import Api from 'utils/api';
import { getDraftProjects } from 'utils/draftHelpers';
import { message } from 'utils/message';
import { prepareGanttData } from 'utils/prepareGanttData';
import storageManager from 'utils/storageManager';
import { getNewTimezoneTime } from 'utils/timezone';
import {
  CreateStandaloneSubtaskDto,
  CreateTaskNotificationDto,
  IAttachmentPayload,
  IDeleteSubtaskFilesByIdPayload,
  IEnsureAddFileToProjectByDropPayload,
  IEnsureAddFileToSubtaskByDropPayload,
  IEnsureAssignSubtaskPayload,
  IEnsureDelegateSubtaskPayload,
  IEnsureDeleteProjectFilePayload,
  IEnsureDeleteSubtaskMessagePayload,
  IEnsureGanttPayload,
  IEnsureGetSubtaskMessagesPayload,
  IEnsureInvoicesSearchPayload,
  IEnsureMarkChecklistPayload,
  IEnsureMarkSubtaskAsPausedPayload,
  IEnsureMarkSubtaskAsToDoPayload,
  IEnsureMarkSubtaskPayload,
  IEnsurePartialProjectUpdatePayload,
  IEnsurePatchProjectWeightPayload,
  IEnsurePostProjectPayload,
  IEnsureProjectPayload,
  IEnsureProjectsPayload,
  IEnsurePutProjectPayload,
  IEnsurePutStandaloneSubtaskPayload,
  IEnsurePutSubtasksAttachmentsPayload,
  IEnsureRejectSubtaskPayload,
  IEnsureRevokeSubtaskPayload,
  IEnsureSearchChecklistItemsPayload,
  IEnsureSendMessageReplyPayload,
  IEnsureSendSubtaskMessagePayload,
  IEnsureSubtaskLogItemsPayload,
  IEnsureSubtaskPayload,
  IEnsureSubtasksUnreadMessagesCountPayload,
  IEnsureUpdateSubtaskMessagePayload,
  IEnsureUpdateTaskNotificationPayload,
  ILocation,
  IProject,
  IProjectResponseDto,
  IResizeSubtaskPayload,
  IUpdateLogItemsPayload,
  ScreenDistributionTypesEnum,
  ServerErrorsEnum,
  TaskNotificationDto,
} from 'types';

export function* ensureProjects({
  payload,
}: {
  type: typeof A.projects.TRIGGER;
  payload?: IEnsureProjectsPayload;
}) {
  const sortBy = yield select(projectsSortBySelector);
  const data: IProject[] = yield select(projectsSelector);
  const search = yield select(searchSelector);
  const searchAll = yield select(projectsFilterSelector);
  const total = yield select(projectsTotalSelector);
  const currentLocationId = yield select(currentLocationSelector);
  const containerSkus = yield select(Dashboard.containerSkusFilterSelector);

  const isNewRequest =
    payload?.isLocationChange ||
    payload?.getAll ||
    payload?.refetch ||
    payload?.post ||
    payload?.searchInit;
  const locations = yield select(locationsSelector);
  const currentLocation = yield select(currentLocationSelector);
  const sortTasksBy = locations?.find(
    item => item?._id === currentLocation
  )?.sortTasksBy;

  if (!payload?.noLoader) yield put(actions.pageLoading(true));
  try {
    const sort =
      sortBy === 'withoutResource' ? `withoutResource=true` : `sort=${sortBy}`;
    let limit = payload?.getAll ? total : payload?.limit || 40;

    if (payload?.refetch) {
      limit = (data.length > 40 ? data.length : 40) || 40;
    }

    if (payload?.post) {
      limit = data.length + 1 || 41;
    }

    const draftProjects = getDraftProjects(
      currentLocationId,
      search,
      containerSkus
    );

    const params = {
      searchAll,
      search,
      currentLocationId,
      sort,
      limit,
      containerSkus,
      tasksSort: sortTasksBy,
      skip:
        isNewRequest || data.length <= 0
          ? 0
          : data.length - draftProjects.length,
    };
    const response = yield call(Api.projects.getProjects, params);
    const newData = response.data.map(project => {
      if (project.allSubtasksCount === 0) {
        return {
          ...project,
          isUntracked: true,
        };
      }
      return project;
    });

    let projects = [];

    if (isNewRequest) {
      projects = [...draftProjects, ...newData];
    } else {
      projects =
        data.length === 0
          ? [...draftProjects, ...newData]
          : [...data, ...newData];
    }

    yield put(
      A.projects.success({
        projects,
        newProjects: newData,
        total: response?.metadata?.total,
      })
    );
  } catch (err) {
    yield put(A.projects.failure(err));
  } finally {
    if (!payload?.noLoader) yield put(actions.pageLoading(false));
  }
}

export function* ensureGantt({
  payload,
}: {
  payload?: IEnsureGanttPayload;
  type: typeof A.gantt.TRIGGER;
}) {
  const currentLocationId = yield select(currentLocationSelector);
  const company = yield select(companySelector);
  const isWeek = yield select(ganttWeekViewSelector);
  const ganttDates = yield select(ganttDatesSelector);
  let startDate: unknown, endDate: unknown;

  const timezone = company?.locations.find(
    companyLocation => companyLocation._id === currentLocationId
  )?.timezone;
  const utcOffset = momentTimezone.tz(timezone).utcOffset();

  momentTimezone.tz.setDefault(timezone);
  storageManager.setTimezone(timezone);
  moment().utcOffset(utcOffset);

  if (!ganttDates?.startDate && !ganttDates?.endDate) {
    const startEndWeek = getStartEndWeek(
      company?.weekStartDay,
      momentTimezone(new Date()).tz(timezone)
    );
    startDate = startEndWeek.startDate;
    endDate = startEndWeek.endDate;
  } else {
    startDate = ganttDates?.startDate;
    endDate = ganttDates?.endDate;
  }

  const gantDatesWithTimezone = getNewTimezoneTime(
    startDate,
    endDate,
    utcOffset,
    timezone
  );

  yield put(
    A.ganttDates({
      ...gantDatesWithTimezone,
      weekStartDay: ganttDates?.weekStartDay || company?.weekStartDay,
    })
  );

  if (!payload?.noLoader) yield put(actions.pageLoading(true));

  try {
    const search = payload?.search;
    const dueDateWeek = moment(gantDatesWithTimezone?.endDate.toDate()).add(
      1,
      'day'
    );
    const dueDate = isWeek ? dueDateWeek : gantDatesWithTimezone?.endDate;
    const params = {
      currentLocationId,
      search,
      startDate: moment(
        gantDatesWithTimezone?.startDate.toDate()
      ).toISOString(),
      dueDate: moment(dueDate.toDate()).toISOString(),
    };

    const data = yield call(Api.projects.getGantt, params);
    const prepareData = prepareGanttData(
      data,
      moment(startDate),
      moment(endDate),
      isWeek
    );
    yield put(A.gantt.success(prepareData));
  } catch (err) {
    yield put(A.gantt.failure(err));
  } finally {
    if (!payload?.noLoader) yield put(actions.pageLoading(false));
  }
}

export function* ensureAssignSubtask({
  payload,
}: {
  payload: IEnsureAssignSubtaskPayload & {
    force?: boolean;
  };
  type: typeof A.assignSubtask.TRIGGER;
}) {
  try {
    const dropFromProject = yield select(dropFromProjectSelector);

    const params = {
      ...(payload.force && { force: payload.force }),
    };

    const data = yield call(Api.subtasks.assignSubtask, {
      id: payload.data.id,
      data: payload.data.data,
      params,
    });

    if (dropFromProject || payload.force) {
      yield ensureProject({
        payload: { id: payload.force ? data.projectId : payload.projectId },
        type: typeof A.project.TRIGGER,
      });
    }

    if (payload.assignAvatar && !payload.force) {
      yield call(ensureGantt, {
        payload: {
          noLoader: true,
        },
        type: A.gantt.TRIGGER,
      });
    }

    yield put(A.assignSubtask.success(data));
  } catch (err) {
    if (!payload.isMultipleAssign) {
      yield call(ensureGantt, {
        payload: {
          noLoader: true,
        },
        type: A.gantt.TRIGGER,
      });
    }

    if (
      err.data.errorCode ===
      ServerErrorsEnum.RESOURCE_ALREADY_HAS_SUBTASK_ASSIGNED_IN_DATE_RANGE
    ) {
      yield put(
        Dashboard.setAssignConflictData({
          ...err,
          requestData: {
            ...payload,
            force: true,
          },
          request: ensureAssignSubtask,
        })
      );
    }

    yield put(A.assignSubtask.failure(err));
  }
}

export function* ensureResizeSubtask({
  payload,
}: {
  payload: IResizeSubtaskPayload;
  type: typeof A.resizeSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtasks.resizeSubtask, payload);
    yield put(A.resizeSubtask.success(data));
  } catch (err) {
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(A.resizeSubtask.failure(err));
  }
}

export function* ensureUnassignSubtask({
  payload,
}: {
  payload: string;
  type: typeof A.unassignSubtask.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const data = yield call(Api.subtasks.unassignSubtask, payload);

    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(
      A.unassignSubtask.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
  } catch (err) {
    yield put(A.unassignSubtask.failure(err));
  }
}

export function* ensureResultSubtask({
  payload,
}: {
  payload: string;
  type: typeof A.resultSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.result.getResult, payload);
    yield put(A.resultSubtask.success(data));
  } catch (err) {
    yield put(A.resultSubtask.failure(err));
  }
}

export function* ensureApproveSubtask({
  payload,
}: {
  payload: string;
  type: typeof A.approveSubtask.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const data = yield call(Api.result.patchApprove, payload);
    yield put(
      A.approveSubtask.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.approveSubtask.failure(err));
  }
}

export function* ensureRejectSubtask({
  payload,
}: {
  payload: IEnsureRejectSubtaskPayload;
  type: typeof A.rejectSubtask.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const data = yield call(Api.result.patchReject, payload);
    yield put(
      A.rejectSubtask.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.rejectSubtask.failure(err));
  }
}

export function* ensureProject({
  payload,
}: {
  payload: IEnsureProjectPayload;
  type: typeof A.project.TRIGGER;
}) {
  try {
    const data: IProjectResponseDto = yield call(
      Api.projects.getProject,
      payload
    );
    const subtasksIds = [];

    data?.tasks.forEach(task =>
      task.subtasks.forEach(subtask => subtasksIds.push(subtask._id))
    );

    if (!!subtasksIds.length) {
      yield call(ensureSubtasksUnreadMessagesCount, {
        payload: { subtasksIds },
        type: A.subtasksUnreadMessagesCount.TRIGGER,
      });
    }

    yield put(A.project.success(prepareProjectData(data)));
  } catch (err) {
    yield put(A.project.failure(err));
  }
}

export function* ensureSubtask({
  payload,
}: {
  payload: IEnsureSubtaskPayload;
  type: typeof A.subtaskDetails.TRIGGER;
}) {
  try {
    const data = yield call(Api.projects.getProject, payload);
    yield put(A.subtaskDetails.success(data));
  } catch (err) {
    yield put(A.subtaskDetails.failure(err));
  }
}
export function* ensureSubtaskLogItems({
  payload,
}: {
  payload: IEnsureSubtaskLogItemsPayload;
  type: typeof A.subtaskLogItems.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtasks.getLogItems, payload);
    yield put(A.subtaskLogItems.success(data));
  } catch (err) {
    yield put(A.subtaskLogItems.failure(err));
  }
}

export function* ensureUpdateSubtaskLogItem({
  payload,
}: {
  payload: IUpdateLogItemsPayload;
  type: typeof A.updateSubtaskLogItem.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtasks.updateLogItems, payload);
    yield put(A.updateSubtaskLogItem.success(data));
    if (!payload.isStandalone) {
      yield call(ensureSubtask, {
        payload: { id: payload.projectId },
        type: A.subtaskDetails.TRIGGER,
      });
    }
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.updateSubtaskLogItem.failure(err));
  }
}

export function* ensurePostProject({
  payload,
}: {
  payload: IEnsurePostProjectPayload;
  type: typeof A.postProject.TRIGGER;
}) {
  try {
    const filesBuffer = yield select(subtaskTemplateFilesBufferSelector);

    if (filesBuffer.length) {
      yield call(ensureDeleteSubtaskFiles, {
        payload: filesBuffer,
        type: A.deleteSubtaskFiles.TRIGGER,
      });
    }

    const data = yield call(Api.projects.postProject, payload);

    yield call(ensureProjects, {
      payload: {
        post: true,
        noLoader: true,
      },
      type: A.projects.TRIGGER,
    });

    yield put(A.postProject.success(prepareProjectData(data)));
  } catch (err) {
    yield put(A.postProject.failure(err));
  }
}
export function* ensurePostDuplicateProject({
  payload,
}: {
  payload: IEnsurePostProjectPayload;
  type: typeof A.postProject.TRIGGER;
}) {
  try {
    const filesBuffer = yield select(subtaskTemplateFilesBufferSelector);

    if (filesBuffer.length) {
      yield call(ensureDeleteSubtaskFiles, {
        payload: filesBuffer,
        type: A.deleteSubtaskFiles.TRIGGER,
      });
    }

    const data = yield call(Api.projects.postDuplicateProject, payload);

    yield call(ensureProjects, {
      payload: {
        post: true,
        noLoader: true,
      },
      type: A.projects.TRIGGER,
    });

    yield put(A.postDuplicateProject.success(prepareProjectData(data)));
  } catch (err) {
    yield put(A.postDuplicateProject.failure(err));
  }
}

export function* ensurePutProject({
  payload,
}: {
  payload: IEnsurePutProjectPayload;
  type: typeof A.putProject.TRIGGER;
}) {
  try {
    const locations: ILocation[] = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;
    const project = yield select(projectSelector);
    const filesBuffer = yield select(subtaskFilesBufferSelector);

    if (filesBuffer.length) {
      yield all(
        filesBuffer.map(file =>
          call(ensureDeleteSubtaskFilesById, {
            payload: {
              id: file.id,
              paths: file.paths,
            },
            type: A.deleteSubtaskFilesById.TRIGGER,
          })
        )
      );
    }

    const data = yield call(Api.projects.putProject, payload);

    const isSameProjectFiles =
      JSON.stringify(data.files) === JSON.stringify(project.files);
    const isSameSubtasksFiles =
      JSON.stringify(project.tasks) === JSON.stringify(data.tasks);

    if (payload.data?.prefix !== data.prefix) {
      yield call(ensureProjects, {
        payload: {
          refetch: true,
          noLoader: true,
          getAll: true,
        },
        type: A.projects.TRIGGER,
      });
    }

    yield put(
      A.putProject.success({
        project: prepareProjectData(data),
        projectListItem: prepareProjectData({
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        }),
        isSameProjectFiles,
        isSameSubtasksFiles,
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.putProject.failure(err));
  }
}

export function* ensurePatchProjectWeight({
  payload: { id, weight },
}: {
  payload: IEnsurePatchProjectWeightPayload;
  type: typeof A.patchProjectWeight.TRIGGER;
}) {
  try {
    const data = yield call(Api.projects.patchProject, {
      id,
      data: { weight },
    });

    yield put(A.patchProjectWeight.success(data));
  } catch (err) {
    yield put(A.patchProjectWeight.failure(err));
  }
}

export function* ensurePutAttachments({
  payload,
}: {
  payload: FormData[];
  type: typeof A.putAttachments.TRIGGER;
}) {
  try {
    const data = yield call(Api.projects.putAttachments, payload);
    yield put(A.putAttachments.success(data));
  } catch (err) {
    yield put(A.putAttachments.failure(err));
  }
}

export function* ensurePutAttachmentsById({
  payload,
}: {
  payload: { id: string; data: FormData[] };
  type: typeof A.putAttachmentsById.TRIGGER;
}) {
  try {
    const data = yield call(Api.projects.putAttachmentsById, payload);
    yield put(A.putAttachmentsById.success(data.files));
  } catch (err) {
    yield put(A.putAttachmentsById.failure(err));
  }
}

export function* ensureDeleteAttachments({
  payload,
}: {
  payload: string[];
  type: typeof A.deleteAttachments.TRIGGER;
}) {
  try {
    const query = new URLSearchParams();
    payload.forEach(item => {
      query.append('paths', item);
    });
    const params = query.toString() as AxiosRequestConfig<IAttachmentPayload>;
    const data = yield call(Api.projects.deleteAttachments, params);
    yield put(A.deleteAttachments.success(data));
  } catch (err) {
    yield put(A.deleteAttachments.failure(err));
  }
}

export function* ensurePartialProjectUpdate({
  payload,
}: {
  payload: IEnsurePartialProjectUpdatePayload;
  type: typeof A.partialProjectUpdate.TRIGGER;
}) {
  try {
    const { type, id } = payload;
    const data = yield call(getPartialProjectUpdateRequest(type), id);
    yield call(ensureProjects, {
      payload: {
        refetch: true,
        noLoader: true,
      },
      type: A.projects.TRIGGER,
    });
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(A.partialProjectUpdate.success(data));
  } catch (err) {
    yield put(A.partialProjectUpdate.failure(err));
  }
}

export function* ensureAddFileToProjectByDrop({
  payload,
}: {
  payload: IEnsureAddFileToProjectByDropPayload;
  type: typeof A.addFileToProjectByDrop.TRIGGER;
}) {
  const { _id, formData, setAttachmentsLoading } = payload;
  try {
    const res = yield call(Api.projects.putAttachmentsById, {
      id: _id,
      data: formData,
    });
    yield put(A.addFileToProjectByDrop.success(res));
    yield message.success();
  } catch (err) {
    yield put(A.addFileToProjectByDrop.failure(err));
  } finally {
    const currentLocation = yield select(location);
    if (currentLocation.pathname === '/dashboard') {
      setAttachmentsLoading(false);
    }
  }
}

export function* ensureAddFileToSubtaskByDrop({
  payload,
}: {
  payload: IEnsureAddFileToSubtaskByDropPayload;
  type: typeof A.addFileToSubtaskByDrop.TRIGGER;
}) {
  const { _id, formData, setAttachmentsLoading } = payload;
  try {
    const { files, taskId } = yield call(Api.subtasks.addFileToSubtask, {
      id: _id,
      data: formData,
    });
    const project = yield select(projectSelector);
    const newProject = updateProjectAfterAddingSubtasksFiles(
      project,
      taskId,
      files,
      _id
    );
    yield put(A.addFileToSubtaskByDrop.success(newProject));
    yield message.success();
  } catch (err) {
    yield put(A.addFileToSubtaskByDrop.failure(err));
  } finally {
    const currentLocation = yield select(location);
    if (currentLocation.pathname === '/dashboard') {
      setAttachmentsLoading(false);
    }
  }
}

export function* ensureMarkSubtaskAsToDo({
  payload,
}: {
  payload: IEnsureMarkSubtaskAsToDoPayload;
  type: typeof A.markSubtaskAsToDo.TRIGGER;
}) {
  try {
    const locations: ILocation[] = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const data = yield call(Api.subtasks.markSubtaskAsToDo, payload);
    yield put(
      A.markSubtaskAsToDo.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsToDo.failure(err));
  }
}

export function* ensureMarkSubtaskAsDone({
  payload,
}: {
  payload: IEnsureMarkSubtaskPayload;
  type: typeof A.markSubtaskAsDone.TRIGGER;
}) {
  try {
    const locations: ILocation[] = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const params = { date: payload.date };
    const data = yield call(Api.subtasks.markSubtaskAsDone, {
      id: payload.id,
      params,
    });
    yield put(
      A.markSubtaskAsDone.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsDone.failure(err));
  }
}

export function* ensureMarkSubtaskAsActive({
  payload,
}: {
  payload: IEnsureMarkSubtaskPayload;
  type: typeof A.markSubtaskAsActive.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const params = { date: payload.date };
    const data = yield call(Api.subtasks.markSubtaskAsActive, {
      id: payload.id,
      params,
    });
    yield put(
      A.markSubtaskAsActive.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsActive.failure(err));
  }
}

export function* ensureMarkSubtaskAsPaused({
  payload,
}: {
  payload: IEnsureMarkSubtaskAsPausedPayload;
  type: typeof A.markSubtaskAsPaused.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;
    const params = { date: payload.date };
    const isConflict = payload.isConflict;
    let actionData;
    actionData = yield call(Api.subtasks.markSubtaskAsPaused, {
      id: payload.id,
      params,
      percent: payload.percent,
    });

    if (isConflict) {
      actionData = {
        ...actionData,
        isConflict,
      };
    }

    yield put(
      A.markSubtaskAsPaused.success({
        project: actionData,
        projectListItem: {
          ...actionData,
          tasks: sortTasks(actionData?.tasks, sortTasksBy),
        },
      })
    );

    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsPaused.failure(err));
  }
}

export function* ensureMarkSubtaskAsResumed({
  payload,
}: {
  payload: IEnsureMarkSubtaskPayload;
  type: typeof A.markSubtaskAsResumed.TRIGGER;
}) {
  try {
    const locations: ILocation[] = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const params = { date: payload.date };
    const data = yield call(Api.subtasks.markSubtaskAsResumed, {
      id: payload.id,
      params,
    });
    yield put(
      A.markSubtaskAsResumed.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsResumed.failure(err));
  }
}

export function* ensurePutSubtasksAttachments({
  payload,
}: {
  payload: IEnsurePutSubtasksAttachmentsPayload;
  type: typeof A.putSubtasksAttachments.TRIGGER;
}) {
  try {
    yield put(actions.isFilesLoading(true));
    const data = yield call(Api.subtasks.putSubtasksAttachments, payload);
    yield put(A.putSubtasksAttachments.success(data));
  } catch (err) {
    yield put(A.putSubtasksAttachments.failure(err));
  } finally {
    yield put(actions.isFilesLoading(false));
  }
}

export function* ensureDeleteSubtaskFilesById({
  payload,
}: {
  payload: IDeleteSubtaskFilesByIdPayload;
  type: typeof A.deleteSubtaskFilesById.TRIGGER;
}) {
  try {
    const { id, paths } = payload;
    yield put(actions.isFilesLoading(true));
    const data = yield call(Api.subtasks.deleteSubtaskFilesById, {
      id,
      params: { paths },
    });
    yield put(A.deleteSubtaskFilesById.success(data));
  } catch (err) {
    yield put(A.deleteSubtaskFilesById.failure(err));
  } finally {
    yield put(actions.isFilesLoading(false));
  }
}

export function* ensureDeleteSubtaskFiles({
  payload,
}: {
  payload: string[];
  type: typeof A.deleteSubtaskFiles.TRIGGER;
}) {
  try {
    yield put(actions.isFilesLoading(true));
    const data = yield call(Api.subtasks.deleteSubtaskFiles, {
      paths: payload,
    });
    yield put(A.deleteSubtaskFiles.success(data));
  } catch (err) {
    yield put(A.deleteSubtaskFiles.failure(err));
  } finally {
    yield put(actions.isFilesLoading(false));
  }
}

export function* ensureMarkSubtaskAsNotReady({
  payload,
}: {
  payload: string;
  type: typeof A.markSubtaskAsNotReady.TRIGGER;
}) {
  try {
    const locations = yield select(locationsSelector);
    const currentLocation = yield select(currentLocationSelector);
    const sortTasksBy = locations?.find(
      item => item?._id === currentLocation
    )?.sortTasksBy;

    const data = yield call(Api.subtasks.markSubtaskAsNotReady, payload);
    yield put(
      A.markSubtaskAsNotReady.success({
        project: data,
        projectListItem: {
          ...data,
          tasks: sortTasks(data?.tasks, sortTasksBy),
        },
      })
    );
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.markSubtaskAsNotReady.failure(err));
  }
}

export function* ensureGetStandaloneSubtask({
  payload,
}: {
  payload: string;
  type: typeof A.getStandaloneSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.standaloneSubtasks.getSubtask, payload);
    yield put(A.getStandaloneSubtask.success(data));
    yield call(ensureSubtasksUnreadMessagesCount, {
      payload: { subtasksIds: [data._id], isStandalone: true },
      type: A.subtasksUnreadMessagesCount.TRIGGER,
    });
  } catch (err) {
    yield put(A.getStandaloneSubtask.failure(err));
  }
}

export function* ensurePostStandaloneSubtask({
  payload,
}: {
  payload: CreateStandaloneSubtaskDto & {
    force?: boolean;
  };
  type: typeof A.postStandaloneSubtask.TRIGGER;
}) {
  try {
    const locationId = storageManager.getLocation();
    const dataForSend = {
      ...payload,
      locationId,
    };

    const { force, ...rest } = payload;

    const lastPayload = force
      ? {
        data: {
          ...rest,
          locationId,
        },
        params: {
          force: true,
        },
      }
      : {
        data: dataForSend,
      };

    const data = yield call(Api.standaloneSubtasks.postSubtask, lastPayload);

    if (!force) {
      yield call(ensureGantt, {
        payload: {
          noLoader: true,
        },
        type: A.gantt.TRIGGER,
      });
    }

    yield put(A.postStandaloneSubtask.success(data));
  } catch (err) {
    if (
      err.data.errorCode ===
      ServerErrorsEnum.RESOURCE_ALREADY_HAS_SUBTASK_ASSIGNED_IN_DATE_RANGE
    ) {
      yield put(
        Dashboard.setAssignConflictData({
          ...err,
          requestData: payload,
          request: ensurePostStandaloneSubtask,
        })
      );
    }
    yield put(A.postStandaloneSubtask.failure(err));
  }
}

export function* ensureDeleteStandaloneSubtask({
  payload,
}: {
  payload: string;
  type: typeof A.deleteStandaloneSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.standaloneSubtasks.deleteSubtask, payload);

    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(A.deleteStandaloneSubtask.success(data));
    yield call(ensureDeleteSubtaskMessages, {
      payload,
      type: A.deleteSubtaskMessages.TRIGGER,
    });
  } catch (err) {
    yield put(A.deleteStandaloneSubtask.failure(err));
  }
}

export function* ensurePutStandaloneSubtask({
  payload,
}: {
  payload: IEnsurePutStandaloneSubtaskPayload;
  type: typeof A.putStandaloneSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.standaloneSubtasks.putSubtask, payload);
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(A.putStandaloneSubtask.success(data));
  } catch (err) {
    yield put(A.putStandaloneSubtask.failure(err));
  }
}

export function* ensureDeleteProjectFile({
  payload,
}: {
  payload: IEnsureDeleteProjectFilePayload;
  type: typeof A.deleteProjectFile.TRIGGER;
}) {
  try {
    const { id, fileArray } = payload;
    yield call(Api.projects.deleteProjectFile, {
      id,
      params: { paths: fileArray },
    });
    yield call(ensureProject, {
      payload: { id },
      type: typeof A.project.TRIGGER,
    });
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
    yield put(A.deleteProjectFile.success({ id, fileArray }));
  } catch (err) {
    yield put(A.deleteProjectFile.failure(err));
  }
}

export function* ensureResourceExcel({
  payload,
}: {
  payload?: string;
  type: typeof A.resourceExcel.TRIGGER;
}) {
  try {
    const { startDate, endDate } = yield select(ganttDatesSelector);
    const locationId = yield select(currentLocationSelector);
    const options = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
      params: {
        resourceId: payload || null,
        locationId,
        'startDate<': endDate.format('MM/DD/YYYY'),
        'dueDate>': startDate.format('MM/DD/YYYY'),
      },
    };
    const request = payload
      ? Api.subtasks.resourceExcel
      : Api.subtasks.resourceExcelAll;
    const data = yield call(request, options);
    yield put(A.resourceExcel.success(data));
  } catch (err) {
    yield put(A.resourceExcel.failure(err));
  }
}

export function* ensureSetScreenDistribution({
  payload,
}: {
  payload: ScreenDistributionTypesEnum;
  type: typeof A.setScreenDistribution.TRIGGER;
}) {
  try {
    yield put(A.setScreenDistribution.success(payload));
  } catch (err) {
    yield put(A.setScreenDistribution.failure(err));
  }
}

export function* ensureInvoicesSearch({
  payload,
}: {
  payload: IEnsureInvoicesSearchPayload;
  type: typeof A.invoicesSearch.TRIGGER;
}) {
  try {
    const locationId = storageManager.getLocation();
    const params = {
      locationId,
      search: payload.invoice,
    };
    const { invoices } = yield call(Api.projects.invoicesSearch, { params });
    yield put(A.invoicesSearch.success(invoices));
  } catch (err) {
    yield put(A.invoicesSearch.failure(err));
  }
}

export function* ensureDelegateSubtask({
  payload,
}: {
  payload: IEnsureDelegateSubtaskPayload;
  type: typeof A.delegateSubtask.TRIGGER;
}) {
  try {
    const project = yield select(projectSelector);
    const { id, taskId } = payload;
    const data = yield call(Api.subcontractors.delegate, payload.id);
    const newProject = updateGeneratedKey(id, data, project, taskId);
    yield put(A.delegateSubtask.success(newProject));
  } catch (err) {
    yield put(A.delegateSubtask.failure(err));
  }
}

export function* ensureRevokeSubtask({
  payload,
}: {
  payload: IEnsureRevokeSubtaskPayload;
  type: typeof A.revokeSubtask.TRIGGER;
}) {
  try {
    const data = yield call(Api.subcontractors.revoke, payload.id);
    yield put(A.revokeSubtask.success(data));
  } catch (err) {
    yield put(A.revokeSubtask.failure(err));
  }
}

export function* ensureGetTaskNotification({
  payload,
}: {
  payload: string;
  type: typeof A.getTaskNotification.TRIGGER;
}) {
  try {
    const data: TaskNotificationDto = yield call(
      Api.tasksNotifications.getById,
      payload
    );

    yield put(A.getTaskNotification.success(data));
  } catch (err) {
    yield put(A.getTaskNotification.failure(err));
  }
}

export function* ensureCreateTaskNotification({
  payload,
}: {
  payload: CreateTaskNotificationDto;
  type: typeof A.createTaskNotification.TRIGGER;
}) {
  try {
    const data = yield call(Api.tasksNotifications.create, payload);

    yield put(A.createTaskNotification.success(data));
  } catch (err) {
    yield put(A.createTaskNotification.failure(err));
  }
}

export function* ensureUpdateTaskNotification({
  payload,
}: {
  payload?: IEnsureUpdateTaskNotificationPayload;
  type: typeof A.updateTaskNotification.TRIGGER;
}) {
  try {
    const data = yield call(
      Api.tasksNotifications.update,
      payload?.id,
      payload?.data
    );

    yield put(A.updateTaskNotification.success(data));
  } catch (err) {
    yield put(A.updateTaskNotification.failure(err));
  }
}

export function* ensureDeleteTaskNotification({
  payload,
}: {
  payload: string;
  type: typeof A.deleteTaskNotification.TRIGGER;
}) {
  try {
    const data = yield call(Api.tasksNotifications.delete, payload);

    yield put(A.deleteTaskNotification.success(data));
  } catch (err) {
    yield put(A.deleteTaskNotification.failure(err));
  }
}

export function* ensureGetSubtaskMessages({
  payload,
}: {
  payload: IEnsureGetSubtaskMessagesPayload;
  type: typeof A.getSubtaskMessages.TRIGGER;
}) {
  try {
    const { subtaskId } = payload;
    const messages = yield call(Api.subtaskChatMessages.get, subtaskId);
    yield put(A.getSubtaskMessages.success({ messages, chatId: subtaskId }));
  } catch (err) {
    yield put(A.getSubtaskMessages.failure(err));
  }
}

export function* ensureSendSubtaskMessage({
  payload,
}: {
  payload: IEnsureSendSubtaskMessagePayload;
  type: typeof A.sendSubtaskMessage.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtaskChatMessages.send, payload);

    yield put(A.sendSubtaskMessage.success(data));
  } catch (err) {
    yield put(A.sendSubtaskMessage.failure(err));
  }
}

export function* ensureSendMessageReply({
  payload,
}: {
  payload: IEnsureSendMessageReplyPayload;
  type: typeof A.sendMessageReply.TRIGGER;
}) {
  try {
    const response = yield call(Api.subtaskChatMessages.sendReply, payload);
    yield put(A.sendMessageReply.success(response));
  } catch (err) {
    yield put(A.sendMessageReply.failure(err));
  }
}

export function* ensureDeleteSubtaskMessages({
  payload,
}: {
  payload: string;
  type: typeof A.deleteSubtaskMessages.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtaskChatMessages.delete, payload);
    yield call(Sharing.ensureGetSystemNotifications, {
      type: typeof Sharing.getSystemNotifications.TRIGGER,
    });
    yield call(Sharing.ensureGetSystemNotificationsUnreadCount);
    yield put(A.deleteSubtaskMessages.success(data));
  } catch (err) {
    yield put(A.deleteSubtaskMessages.failure(err));
  }
}

export function* ensureDeleteSubtaskMessage({
  payload,
}: {
  payload: IEnsureDeleteSubtaskMessagePayload;
  type: typeof A.deleteSubtaskMessage.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChatMessages.deleteMessage, payload);
    yield put(A.deleteSubtaskMessage.success(payload?.messageId));
  } catch (err) {
    yield put(A.deleteSubtaskMessage.failure(err));
  }
}

export function* ensureUpdateSubtaskMessage({
  payload,
}: {
  payload: IEnsureUpdateSubtaskMessagePayload;
  type: typeof A.updateSubtaskMessage.TRIGGER;
}) {
  try {
    const data = yield call(Api.subtaskChatMessages.updateMessage, payload);

    yield put(A.updateSubtaskMessage.success(data));
  } catch (err) {
    yield put(A.updateSubtaskMessage.failure(err));
  }
}

export function* ensureMarkChecklistCompleted({
  payload,
}: {
  payload: IEnsureMarkChecklistPayload;
  type: typeof A.markSubtaskChecklistCompleted.TRIGGER;
}) {
  try {
    const data = payload
      ? yield call(Api.subtaskChecklist.markAsCompleted, payload)
      : {};
    yield put(A.markSubtaskChecklistCompleted.success(data));
  } catch (err) {
    yield put(A.markSubtaskChecklistCompleted.failure(err));
  }
}
export function* ensureMarkChecklistUncompleted({
  payload,
}: {
  payload: IEnsureMarkChecklistPayload;
  type: typeof A.markSubtaskChecklistUncompleted.TRIGGER;
}) {
  try {
    const data = payload
      ? yield call(Api.subtaskChecklist.markAsUncompleted, payload)
      : {};

    yield put(A.markSubtaskChecklistUncompleted.success(data));
  } catch (err) {
    yield put(A.markSubtaskChecklistUncompleted.failure(err));
  }
}

export function* ensureSearchChecklistItems({
  payload,
}: {
  payload: IEnsureSearchChecklistItemsPayload;
  type: typeof A.checklistSearchItems.TRIGGER;
}) {
  try {
    const data = payload
      ? yield call(Api.subtaskChecklist.search, payload.text)
      : {};

    yield put(A.checklistSearchItems.success(data));
  } catch (err) {
    yield put(A.checklistSearchItems.failure(err));
  }
}

export function* ensureDeleteChatMessagesBySubtaskIds({
  payload,
}: {
  payload: string[];
  type: typeof A.deleteChatMessagesBySubtaskIds.TRIGGER;
}) {
  try {
    const params = {
      subtasksIds: payload,
    };
    yield call(Api.subtasks.deleteSubtasksChatMessages, params);
    yield call(Sharing.ensureGetSystemNotifications, {
      type: typeof Sharing.getSystemNotifications.TRIGGER,
    });
    yield call(Sharing.ensureGetSystemNotificationsUnreadCount);
    yield put(A.deleteChatMessagesBySubtaskIds.success());
  } catch (err) {
    yield put(A.deleteChatMessagesBySubtaskIds.failure(err));
  }
}

export function* ensureSubtasksUnreadMessagesCount({
  payload,
}: {
  payload: IEnsureSubtasksUnreadMessagesCountPayload;
  type: typeof A.subtasksUnreadMessagesCount.TRIGGER;
}) {
  try {
    const params = {
      subtasksIds: payload.subtasksIds,
    };
    const data = yield call(Api.subtasks.subtasksUnreadMessagesCount, params);

    yield put(
      A.subtasksUnreadMessagesCount.success({
        data,
        isStandalone: !!payload.isStandalone,
        subtasksId: payload.subtasksIds,
      })
    );
  } catch (err) {
    yield put(A.subtasksUnreadMessagesCount.failure(err));
  }
}

export function* ensureMarkSubtaskMessagesAsSeen({
  payload,
}: {
  payload: string;
  type: typeof A.markSubtaskMessagesAsSeen.TRIGGER;
}) {
  try {
    yield call(Api.subtaskChatMessages.markAsSeen, payload);
    yield put(A.markSubtaskMessagesAsSeen.success());
  } catch (err) {
    yield put(A.markSubtaskMessagesAsSeen.failure(err));
  }
}

export function* ensureForceAssignResource() {
  try {
    const assignConflictData = yield select(assignConflictDataSelector);

    for (let i = 0, len = assignConflictData.length; i < len; i++) {
      const { requestData, request } = assignConflictData[i];
      const payload = {
        ...requestData,
        force: true,
      };
      yield call(request, { payload });
    }

    yield put(A.forceAssignResource.success());
    yield call(ensureGantt, {
      payload: {
        noLoader: true,
      },
      type: A.gantt.TRIGGER,
    });
  } catch (err) {
    yield put(A.forceAssignResource.failure(err));
  }
}
