// icons
import EmptyIcon from './Empty.svg';
import { BUTTON_TYPES, Button } from 'components/common';
// styles
import { EmptyStyled } from './styles';

interface IEmptyProps {
  onClick?: () => void;
  label?: string;
  width?: string;
  buttonEmpty?: any;
  noButton?: boolean;
  noImage?: boolean;
  description?: string;
}

const Empty = ({
  label,
  onClick,
  width = '140px',
  buttonEmpty: ButtonComponent,
  description = 'There no data',
  noButton,
  noImage = false,
}: IEmptyProps) => (
  <EmptyStyled
    image={noImage ? null : EmptyIcon}
    noimage={noImage?.toString()}
    imageStyle={{ height: noImage ? 0 : 138 }}
    description={<span>{description}</span>}
  >
    {!noButton &&
      (ButtonComponent ? (
        <ButtonComponent />
      ) : (
        <Button
          onClick={onClick}
          kind={BUTTON_TYPES.SECONDARY}
          label={label}
          height="36px"
          width={width}
        />
      ))}
  </EmptyStyled>
);

export default Empty;
