import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 17px 19px 7px 17px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Caption = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;
