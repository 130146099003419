import styled from 'styled-components';

export const Preview = styled.img`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const PreviewWrapper = styled.div`
  height: 75px;
  width: 100%;
  box-sizing: content-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
