import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hasErrorSelector, loginPromiseCreator } from 'store/auth';
import { ROUTES } from 'store/router';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button, Input } from 'components/common';
import { InputPassword } from 'components/common/InputPassword/InputPassword';
import { useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { GoogleLogo } from 'lib/icons';
import queryString from 'query-string';
import { message } from 'utils/message';
import { LoginSchema } from 'validation';
import {
  BottomItem,
  DividerItem,
  Error,
  ErrorWrapper,
  FormItem,
  GoogleButton,
  LinkItem,
} from './styles';

export const API_URL = process.env.REACT_APP_API_PATH;

interface ILoginInput {
  email: string;
  password: string;
}

const Login = (): JSX.Element => {
  const login = useActionsRoutines(loginPromiseCreator);
  const hasError = useSelector(hasErrorSelector);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const memoizedHistory = useMemoCompare(history);
  const { errorCode, errorMessage } = queryString.parse(location.search);

  useEffect(() => {
    if (errorCode && errorMessage) {
      const handleError = async () => {
        await message.error([errorMessage as string]);
        memoizedHistory.replace(ROUTES.HOME);
      };
      handleError().catch(console.error);
    }
  }, [errorCode, errorMessage, memoizedHistory]);

  const form = useForm<ILoginInput>({
    mode: 'onBlur',
    resolver: yupResolver(LoginSchema),
    shouldUnregister: true,
  });
  const handleSubmit: SubmitHandler<ILoginInput> = async data => {
    try {
      setLoading(true);
      const { jwt } = await login(data);
      history.push(`/login?jwt=${jwt}`);
    } catch (error) {
      const { userId } = error?.data;
      if (userId) {
        setTimeout(() => {
          history.push(
            `${ROUTES.EMAIL_VERIFICATION}?userId=${userId}&email=${data.email}`
          );
        }, 1000);
      }
    } finally {
      setLoading(false);
    }
  };

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <AuthWrapper>
      {hasError && (
        <ErrorWrapper>
          <Error kind={BUTTON_TYPES.DANGER} label="You have no access" />
        </ErrorWrapper>
      )}
      <FormItem onSubmit={form.handleSubmit(handleSubmit)}>
        <Controller
          render={({ field }) => (
            <Input
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              isRequired
              autocomplete={false}
              width="100%"
              height="50px"
              style={{ paddingBottom: '15px' }}
              placeholder="Email"
              error={errors?.email?.message}
              type="email"
            />
          )}
          name="email"
          control={control}
        />
        <Controller
          render={({ field }) => (
            <InputPassword
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              isRequired
              autocomplete={false}
              width="100%"
              height="50px"
              style={{ paddingBottom: '15px' }}
              placeholder="Password"
              error={errors?.password?.message}
            />
          )}
          name="password"
          control={control}
        />
        <LinkItem to={ROUTES.FORGOT_PASSWORD}>Forgot password?</LinkItem>
        <Button
          loading={loading}
          htmlType="submit"
          kind={BUTTON_TYPES.DEFAULT}
          label="Sign in"
          width="320px"
          height="50px"
          style={{
            marginBottom: '20px',
          }}
        />
      </FormItem>
      <GoogleButton
        icon={<GoogleLogo />}
        href={`${API_URL}/auth/google`}
        label="Continue with Google"
      />
      <DividerItem />
      <BottomItem>
        <span>Not a customer? </span>
        <Button
          onClick={() => history.push(ROUTES.REGISTER)}
          kind={BUTTON_TYPES.SECONDARY}
          label="Try for free"
          width="140px"
          height="36px"
        />
      </BottomItem>
    </AuthWrapper>
  );
};

export default Login;
