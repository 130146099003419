import * as yup from 'yup';
import { password } from './baseSchema';

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().when('$passwordIsSet', (passwordIsSet, schema) => {
    if (passwordIsSet) return password.required('Old password is required!');
    return schema.nullable();
  }),
  newPassword: password.required('New password is required!'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required!'),
});

export default changePasswordSchema;
