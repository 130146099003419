/* eslint-disable no-redeclare */
import { DependencyList, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PromiseCreator, bindPromiseCreators } from 'redux-saga-routines';

function useActionsRoutines(
  actions: PromiseCreator,
  deps?: DependencyList
): (payload?: any) => Promise<any>;

function useActionsRoutines(
  actions: PromiseCreator[],
  deps?: DependencyList
): ((payload?: any) => Promise<any>)[];

function useActionsRoutines(
  actions: PromiseCreator | PromiseCreator[],
  deps?: DependencyList
): ((payload?: any) => Promise<any>) | ((payload?: any) => Promise<any>)[] {
  const dispatch = useDispatch();

  // @ts-ignore
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindPromiseCreators(a, dispatch));
      }
      return bindPromiseCreators(actions, dispatch);
    },
    // eslint-disable-next-line
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export default useActionsRoutines;
