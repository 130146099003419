import { createSlice } from '@reduxjs/toolkit';

export const gantt = createSlice({
  name: 'gantt',
  initialState: {
    msInPixel: 0,
    isClearGanttState: false,
    taskId: undefined,
    subtaskName: undefined,
    subtaskItemId: undefined,
    highlightedSubtaskId: undefined,
    highlightedSubtask: undefined,
    dropFromProject: false,
    mouseOffset: 0,
    isGanttDragging: false,
    isReportSubtaskDisplayed: false,
    isOpenStandaloneSubtask: false,
    isOpenDrawer: false,
    zoomPercent: 100,
    rowVisibleTimeStart: 0,
    rowVisibleTimeEnd: 0,
    canvasTimeStart: 0,

    prevItems: [],
    keyPressed: '',
  },
  reducers: {
    setKeyPressed: (state, action) => {
      state.keyPressed = action.payload;
    },
    setMsInPixel: (state, action) => {
      state.msInPixel = action.payload;
    },
    setHighlightedSubtask: (state, action) => {
      state.highlightedSubtask = action.payload;
    },
    clearGanttState: (state, action) => {
      state.isClearGanttState = action.payload;
    },
    setTaskId: (state, action) => {
      state.taskId = action.payload;
    },
    setSubtaskName: (state, action) => {
      state.subtaskName = action.payload;
    },
    setSubtaskItemId: (state, action) => {
      state.subtaskItemId = action.payload;
    },
    setDropFromProject: (state, action) => {
      state.dropFromProject = action.payload;
    },
    setMouseOffset: (state, action) => {
      state.mouseOffset = action.payload;
    },
    setIsGanttDragging: (state, action) => {
      state.isGanttDragging = action.payload;
    },
    setIsReportSubtaskDisplayed: (state, action) => {
      state.isReportSubtaskDisplayed = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.isOpenDrawer = action.payload;
    },
    toggleStandaloneSubtask: (state, action) => {
      state.isOpenStandaloneSubtask = action.payload;
    },
    setZoomPercent: (state, action) => {
      state.zoomPercent = action.payload;
    },
    setRowVisibleTimeStart: (state, action) => {
      state.rowVisibleTimeStart = action.payload;
    },
    setRowVisibleTimeEnd: (state, action) => {
      state.rowVisibleTimeEnd = action.payload;
    },
    setCanvasTimeStart: (state, action) => {
      state.canvasTimeStart = action.payload;
    },

    setPrevItems: (state, action) => {
      state.prevItems = action.payload;
    },
  },
});

export const {
  actions: {
    setKeyPressed: setKeyPressedAction,
    setMsInPixel: setMsInPixelAction,
    setHighlightedSubtask: setHighlightedSubtaskAction,
    clearGanttState: clearGanttStateAction,
    setTaskId: setTaskIdAction,
    setSubtaskName: setSubtaskNameAction,
    setSubtaskItemId: setSubtaskItemIdAction,
    setDropFromProject: setDropFromProjectAction,
    setMouseOffset: setMouseOffsetAction,
    setIsGanttDragging: setIsGanttDraggingAction,
    setIsReportSubtaskDisplayed: setIsReportSubtaskDisplayedAction,
    toggleDrawer: toggleDrawerAction,
    toggleStandaloneSubtask: toggleStandaloneSubtaskAction,
    setZoomPercent: setZoomPercentAction,
    setRowVisibleTimeStart: setRowVisibleTimeStartAction,
    setRowVisibleTimeEnd: setRowVisibleTimeEndAction,
    setCanvasTimeStart: setCanvasTimeStartAction,
    setPrevItems: setPrevItemsAction,
  },
  reducer,
} = gantt;
