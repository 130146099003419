export const CheckMark = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5017 1.3156L6.58006 11.2372L2.76077 7.40726C2.55669 7.23248 2.29416 7.14115 2.02566 7.15152C1.75717 7.16189 1.50247 7.2732 1.31247 7.4632C1.12247 7.6532 1.01117 7.90789 1.0008 8.17639C0.990425 8.44489 1.08175 8.70741 1.25653 8.9115L5.83327 13.4882C6.03198 13.6853 6.30019 13.7964 6.58006 13.7976C6.72046 13.7984 6.85965 13.7715 6.98963 13.7184C7.11961 13.6653 7.23783 13.5871 7.33752 13.4882L18.0059 2.81985C18.1059 2.72067 18.1853 2.60268 18.2394 2.47267C18.2936 2.34267 18.3215 2.20322 18.3215 2.06239C18.3215 1.92155 18.2936 1.78211 18.2394 1.65211C18.1853 1.5221 18.1059 1.40411 18.0059 1.30493C17.8046 1.10764 17.5334 0.998028 17.2516 1.00003C16.9697 1.00203 16.7001 1.11548 16.5017 1.3156Z"
      fill="white"
      stroke="white"
      strokeWidth="1.88266"
    />
  </svg>
);
