import { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
// redux
import {
  clearAllSubtaskFilesBuffers,
  deleteAttachmentsPromiseCreator,
  deleteSubtaskFilesPromiseCreator,
  setCloneDataAction,
  subtaskDraftFilesBufferSelector,
} from 'store/dashboard';
import {
  workOrderPromiseCreator,
  workOrdersPromiseCreator,
} from 'store/inbound';
// components
import InboundModal from './InboundModal';
import ProjectModal from './ProjectModal';
// hooks
import { useActions, useActionsRoutines } from 'hooks';
import { useMemoCompare } from 'hooks/useMemoCompare';
import { CloneType, InboundModalTypesEnum } from 'types';

interface IModalsProps {
  toggleModal?: (visible: boolean) => void;
  isOpenModal?: boolean;
  setOpenInboundModal?: (open: boolean) => void;
  isOpenInboundModal?: boolean;
  setLoadingModal?: (loading: boolean) => void;
  isLoadingModal?: boolean;
  setOpenInboundType?: (type: InboundModalTypesEnum | null) => void;
  openInboundType?: string;
  openInboundModal: (type: InboundModalTypesEnum | null) => void;
  closeDraftModal: () => void;
}

const Modals = ({
  toggleModal,
  isOpenModal,
  setOpenInboundModal,
  isOpenInboundModal,
  setLoadingModal,
  isLoadingModal,
  setOpenInboundType,
  openInboundType,
  openInboundModal,
  closeDraftModal,
}: IModalsProps) => {
  const [isEditModal, setIsEditModal] = useState(false);
  const [isClone, toggleClone] = useState(false);
  const [cloneCount, setCloneCount] = useState(1);
  const [isOpenProjectViewModal, toggleProjectViewModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [notCloseModal, setCloseModal] = useState(false);
  const deleteFiles = useActionsRoutines(deleteAttachmentsPromiseCreator);
  const subtaskFilesBuffersClear = useActions(clearAllSubtaskFilesBuffers);
  const setCloneData = useActions(setCloneDataAction);
  const subtaskDraftFilesBuffer = useSelector(subtaskDraftFilesBufferSelector);
  const deleteSubtaskFiles = useActionsRoutines(
    deleteSubtaskFilesPromiseCreator
  );
  const getWorkOrders = useActionsRoutines(workOrdersPromiseCreator);
  const getWorkOrderById = useActionsRoutines(workOrderPromiseCreator);
  const memoizedFiles = useMemoCompare(files);

  const closeInboundModal = useCallback(() => {
    if (notCloseModal) return;
    setOpenInboundModal(false);
    setIsEditModal(false);
    toggleClone(false);
    setLoadingModal(false);
    setOpenInboundType(null);

    if (subtaskDraftFilesBuffer.length) {
      const newSubtasksFiles = subtaskDraftFilesBuffer.reduce(
        (acc, item) => (!item.id ? [...acc, item] : [...acc, ...item.paths]),
        []
      );

      if (newSubtasksFiles.length) {
        deleteSubtaskFiles(newSubtasksFiles)
          .then(() => subtaskFilesBuffersClear())
          .catch(err => console.error(err));
      }
    }

    if (
      openInboundType !== InboundModalTypesEnum.CREATE ||
      !memoizedFiles.length
    ) {
      return;
    }
    const data = memoizedFiles.map(item =>
      typeof item === 'string' ? item : item.filePath
    );
    deleteFiles(data)
      .then(() => setFiles([]))
      .catch(err => console.error(err));
  }, [
    notCloseModal,
    setOpenInboundModal,
    deleteSubtaskFiles,
    setIsEditModal,
    toggleClone,
    setLoadingModal,
    setOpenInboundType,
    openInboundType,
    memoizedFiles,
    subtaskDraftFilesBuffer,
    deleteFiles,
    subtaskFilesBuffersClear,
  ]);

  const openDivideTasks = useCallback(
    id => {
      setLoadingModal(true);
      getWorkOrderById(id)
        .then(() => {
          setOpenInboundType(InboundModalTypesEnum.DIVIDE_TASKS);
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingModal(false));
    },
    [setLoadingModal, getWorkOrderById, setOpenInboundType]
  );

  const openCreateModal = useCallback(
    id => {
      setLoadingModal(true);
      getWorkOrderById(id)
        .then(() => setOpenInboundType(InboundModalTypesEnum.CREATE))
        .catch(err => console.error(err))
        .finally(() => setLoadingModal(false));
    },
    [setLoadingModal, getWorkOrderById, setOpenInboundType]
  );

  const closeModal = useCallback(() => {
    if (notCloseModal) return;
    setIsEditModal(false);
    toggleClone(false);
    toggleModal(false);
    toggleProjectViewModal(false);

    if (subtaskDraftFilesBuffer.length) {
      deleteSubtaskFiles(subtaskDraftFilesBuffer)
        .then(() => subtaskFilesBuffersClear())
        .catch(err => console.error(err));
    }

    if (isOpenProjectViewModal || !files.length) return;

    const data = files.map(item =>
      typeof item === 'string' ? item : item.filePath
    );

    deleteFiles(data)
      .then(() => setFiles([]))
      .catch(err => console.error(err));
  }, [
    toggleModal,
    files,
    deleteFiles,
    deleteSubtaskFiles,
    subtaskDraftFilesBuffer,
    subtaskFilesBuffersClear,
    isOpenProjectViewModal,
    setFiles,
    notCloseModal,
    toggleClone,
  ]);

  const openEdit = useCallback(
    isCloneProject => {
      toggleClone(!!isCloneProject);
      setIsEditModal(true);
      toggleProjectViewModal(false);
    },
    [toggleClone, setIsEditModal, toggleProjectViewModal]
  );

  const onCloseEditModal = useCallback(() => {
    toggleModal(false);
    closeInboundModal();
    toggleProjectViewModal(false);
    setIsEditModal(false);
    toggleClone(false);
  }, [
    toggleModal,
    closeInboundModal,
    toggleProjectViewModal,
    setIsEditModal,
    toggleClone,
  ]);

  const closeInboundCreateModal = useCallback(() => {
    setOpenInboundType(InboundModalTypesEnum.DETAILS);
  }, [setOpenInboundType]);

  const openInboundEdit = useCallback(
    isCloneProject => {
      setOpenInboundType(InboundModalTypesEnum.CREATE);
      toggleClone(!!isCloneProject);
      setIsEditModal(true);
    },
    [setOpenInboundType, toggleClone, setIsEditModal]
  );

  useEffect(() => {
    setCloneData({ isClone, cloneType: CloneType.Project });
  }, [isClone, setCloneData]);

  return (
    <div>
      <InboundModal
        openInboundModalType={openInboundType}
        closeInboundModal={closeInboundModal}
        isOpenInboundModal={isOpenInboundModal}
        isLoadingModal={isLoadingModal}
        getWorkOrders={getWorkOrders}
        openDivideTasks={openDivideTasks}
        openCreateModal={openCreateModal}
        isEditModal={isEditModal}
        onCloseEditModal={onCloseEditModal}
        openInboundModal={openInboundModal}
        files={files}
        setFiles={setFiles}
        setCloseModal={setCloseModal}
        openInboundEdit={openInboundEdit}
        setCloneCount={setCloneCount}
        toggleModal={setOpenInboundModal}
        closeInboundCreateModal={closeInboundCreateModal}
        isClone={isClone}
        closeDraftModal={closeDraftModal}
      />
      <ProjectModal
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        isOpenProjectViewModal={isOpenProjectViewModal}
        isEditModal={isEditModal}
        onCloseEditModal={onCloseEditModal}
        setCloseModal={setCloseModal}
        openEdit={openEdit}
        setCloneCount={setCloneCount}
        toggleModal={toggleModal}
        toggleProjectViewModal={toggleProjectViewModal}
        files={files}
        setFiles={setFiles}
        isClone={isClone}
        cloneCount={cloneCount}
        closeDraftModal={closeDraftModal}
      />
    </div>
  );
};

export default memo(Modals, isEqual);
