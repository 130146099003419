import { ChangeEvent, memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
// redux
import { assignSubtaskPromiseCreator, project } from 'store/dashboard';
import { getDataForAssign } from 'components/SubtaskViewComponents/helpers';
// utils
import { getAvatarProps } from 'utils/helpers';
import EmptyList from 'components/Empty';
import { DateAndTimePicker, SearchInput, Text } from 'components/common';
// hooks
import { useActions, useActionsRoutines } from 'hooks';
import moment from 'moment-timezone';
import { Avatar, Spin } from 'antd';
// icons
import { LoadingOutlined } from '@ant-design/icons';
// styles
import { Item, ListStyled, Title, Wrap } from './styles';
import {
  IAssignSubtaskData,
  ListResourceResponseDto,
  ServerErrorsEnum,
} from 'types';

interface IAssignProps {
  findResources?: (event: ChangeEvent<HTMLInputElement>) => void;
  resources?: ListResourceResponseDto[];
  isLoadingResources?: boolean;
  closeModal?: () => void;
  id?: string;
  projectId?: string;
  onAssignResource?: (
    data: IAssignSubtaskData,
    date: moment.Moment
  ) => Promise<void>;
  minStartDate?: moment.Moment;
  isRecurring?: boolean;
  currentResourceId: string;
}

const Assign = ({
  findResources,
  isRecurring,
  resources,
  isLoadingResources,
  closeModal,
  id,
  projectId,
  onAssignResource,
  minStartDate,
  currentResourceId,
}: IAssignProps) => {
  const [indexLoading, setLoading] = useState(null);
  const [date, setDate] = useState(moment(new Date()));
  const assignSubtask = useActionsRoutines(assignSubtaskPromiseCreator);
  const getProject = useActions(project);

  const assignResource = useCallback(
    (resource, index) => {
      setLoading(index);
      const data = getDataForAssign(resource, minStartDate);

      const action = onAssignResource
        ? onAssignResource(data, date)
        : assignSubtask({
            data: { id, data: { resourceId: resource._id, startDate: date } },
            assignAvatar: true,
          });
      action
        .then(() => {
          setLoading(null);
          if (!onAssignResource) {
            getProject({ id: projectId });
          }

          closeModal();
        })
        .catch(err => {
          setLoading(null);
          if (
            err.data.errorCode ===
            ServerErrorsEnum.RESOURCE_ALREADY_HAS_SUBTASK_ASSIGNED_IN_DATE_RANGE
          ) {
            closeModal();
          }
        });
    },
    [
      assignSubtask,
      getProject,
      closeModal,
      projectId,
      id,
      onAssignResource,
      date,
      minStartDate,
    ]
  );

  return (
    <Wrap>
      <Title>Assignee</Title>

      {!isRecurring && (
        <div style={{ margin: '0 0 20px 17px' }}>
          <div> Select date & time</div>
          <DateAndTimePicker setDate={setDate} value={date} />
        </div>
      )}

      <SearchInput
        placeholder="Start typing name"
        onChange={findResources}
        className="search"
        noPrefix={true}
      />
      <Spin
        tip="Loading..."
        spinning={isLoadingResources}
        size="large"
        style={{ marginTop: 20 }}
      />
      <EmptyList noButton={true} hidden={true}>
        <ListStyled
          dataSource={resources}
          rowKey={(item: ListResourceResponseDto) => item._id}
          renderItem={(item: ListResourceResponseDto, index) => {
            const { fullName, color, background } = getAvatarProps(
              item?.fullName
            );
            if (item._id === currentResourceId) return null;
            return (
              <Item onClick={() => assignResource(item, index)}>
                <Avatar
                  shape="square"
                  size={37}
                  className="avatar"
                  src={item?.photoUrl}
                  style={{ color, background }}
                >
                  {fullName}
                </Avatar>
                <Text ellipsis={{ rows: 1 }}>{item?.fullName}</Text>
                <Spin
                  spinning={indexLoading === index}
                  size="large"
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                />
              </Item>
            );
          }}
        />
      </EmptyList>
    </Wrap>
  );
};

export default memo(Assign, isEqual);
