import styled from 'styled-components';

export const ButtonContainer = styled.div<{
  disabled?: boolean;
  active?: boolean;
}>`
  height: 30px;
  width: 55px;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #172b4d;
  margin-right: 5px;

  ${({ active, disabled }) => {
    let backgroundColor = '';
    let cursor = '';
    let color = '';

    if (disabled) {
      backgroundColor = '#EBEBEB';
      cursor = 'default';
      color = '#172B4D';
    } else {
      backgroundColor = active ? '#172B4D' : '#fff';
      cursor = active ? 'default' : 'pointer';
      color = active ? '#fff' : '#172B4D';
    }

    return `
      background-color: ${backgroundColor};
      cursor: ${cursor};
      color: ${color};
    `;
  }};
`;
