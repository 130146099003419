import styled from 'styled-components';

export const Content = styled.div`
  padding: 0 17px 11px;
  margin-top: -10px;
`;

export const ItemInfo = styled.div`
  margin-bottom: 16px;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 9px;
`;

export const MainInfoItem = styled.div`
  font-size: 11px;
  line-height: 13px;
  margin-right: 12px;
  display: flex;
`;

export const MainInfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-right: 5px;
`;

export const MainInfoValue = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Description = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const Subtasks = styled.div``;
