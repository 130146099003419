import { FC, useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import { attachToSubtaskPromiseCreator } from 'store/subcontractors';
import { getButtonsProperties } from '../helpers';
import { useActionsRoutines } from 'hooks';
import { ButtonStyled } from '../styles';

interface FileDropzoneProps {
  id: string;
  status: string;
  isApproveRequired: boolean;
  isPhotoRequired: boolean;
  isButtonInactive: boolean;
}

export const FileDropzone: FC<FileDropzoneProps> = ({
  id,
  status,
  isApproveRequired,
  isPhotoRequired,
  isButtonInactive,
}) => {
  const buttonPreset = getButtonsProperties(
    status,
    isApproveRequired,
    isPhotoRequired
  );

  const [isLoading, setIsLoading] = useState(false);
  const attachToSubtask = useActionsRoutines(attachToSubtaskPromiseCreator);

  const onSelectFiles = useCallback(
    files => {
      setIsLoading(true);
      const formData = new FormData();
      files.map(file => formData.append('files', file));
      attachToSubtask({ id, data: formData })
        .catch(err => console.error(err))
        .finally(() => setIsLoading(false));
    },
    [id, attachToSubtask]
  );

  const { label, Icon, ...stylesPreset } = buttonPreset;
  return (
    <>
      <Dropzone
        accept="image/*"
        onDrop={acceptedFiles => onSelectFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <>
            <input {...getInputProps()} />
            <ButtonStyled
              isbuttoninactive={isButtonInactive.toString()}
              preset={stylesPreset}
              icon={Icon && <Icon />}
              label={label}
              loading={isLoading}
              {...getRootProps()}
            />
          </>
        )}
      </Dropzone>
    </>
  );
};
