import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import * as Actions from './actions';
import { getRole } from 'lib/constants/roles';

const auth = createReducer(initialState.auth, {
  [Actions.signIn.SUCCESS]: (state, action) => {
    state.user = { ...action.payload, ...getRole(action?.payload?.roles) };
    state.isAuthorized = true;
    state.hasError = false;
  },

  [Actions.addAvatar.SUCCESS]: (state, action) => {
    state.user = { ...state.user, photoUrl: action.payload?.fileUrl };
  },
  [String(Actions.currentLocation)]: (state, action) => {
    state.currentLocation = action.payload;
  },
  [String(Actions.locationTimezone)]: (state, action) => {
    state.locationTimezone = action.payload;
  },
  [Actions.updateUser.SUCCESS]: (state, action) => {
    state.user = {
      ...state.user,
      ...action.payload,
      ...getRole(action?.payload?.roles),
    };
  },
  [Actions.getProfile.SUCCESS]: (state, action) => {
    state.user = { ...action.payload, ...getRole(action?.payload?.roles) };
  },
  [Actions.changePassword.SUCCESS]: state => {
    state.user.passwordIsSet = true;
  },
  [Actions.signIn.FAILURE]: (state, action) => {
    state.hasError = action.payload;
    state.isAuthorized = false;
  },
  [Actions.signOut.SUCCESS]: state => {
    state.isAuthorized = false;
  },
});

export { auth as reducer };
