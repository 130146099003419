import styled from 'styled-components';
import { Typography } from 'antd';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding: 17px 19px 7px 17px;
`;

export const PreviewWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 4px 7px 4px;
  width: 58px;
  min-width: 58px;
`;

export const Preview = styled.img`
  cursor: pointer;
  height: 45px;
  max-width: 58px;
`;

export const FileInfoName = styled(Typography.Paragraph)`
  white-space: normal;
  word-break: break-all;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0 !important;
  width: 100%;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const FileItemTopRight = styled.div`
  display: flex;
  border: 1px dashed ${({ theme }) => theme.colors.grayDark};
  box-sizing: border-box;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: 11px;
  height: 54px;
  min-height: 54px;
  align-items: center;
`;
