interface IDoubleArrowProps {
  width?: number;
  height?: number;
}

export const DoubleArrow = ({
  height = 10,
  width = 8,
}: IDoubleArrowProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.175L10 6.17505L8.825 5.00005L5 8.81672L1.175 5.00005L2.10604e-06 6.17505L5 11.175Z"
      fill="#737F94"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6.17505L10 1.17505L8.825 4.98831e-05L5 3.81672L1.175 4.92143e-05L2.10604e-06 1.17505L5 6.17505Z"
      fill="#737F94"
    />
  </svg>
);
