import { call, put } from 'redux-saga/effects';
// redux
import { addAvatar, changePassword, getProfile, updateUser } from 'store/auth';
import { actions } from 'store/loading';
// utils
import Api from 'utils/api';
import {
  ChangePasswordBodyDto,
  FileUploadResDto,
  IEnsureCurrentUserAvatarPayload,
  ILoaderPayload,
  UserDto,
  UserPartialUpdateDto,
} from 'types';

export function* ensureAvatar({
  payload,
}: {
  payload: IEnsureCurrentUserAvatarPayload;
  type: typeof addAvatar.TRIGGER;
}) {
  try {
    const data: FileUploadResDto = yield call(
      Api.user.postCurrentUserAvatar,
      payload
    );
    yield put(addAvatar.success(data));
  } catch (err) {
    yield put(addAvatar.failure(err));
  }
}

export function* ensureUpdateUser({
  payload,
}: {
  payload: UserPartialUpdateDto;
  type: typeof updateUser.TRIGGER;
}) {
  try {
    const res: UserDto = yield call(Api.user.patchCurrentUser, payload);
    yield put(updateUser.success(res));
  } catch (err) {
    yield put(updateUser.failure(err));
  }
}
export function* ensureChangePassword({
  payload,
}: {
  payload: ChangePasswordBodyDto;
  type: typeof changePassword.TRIGGER;
}) {
  try {
    yield call(Api.user.changePassword, payload);
    yield put(changePassword.success());
  } catch (err) {
    yield put(changePassword.failure(err));
  }
}

export function* ensureGetProfile({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof getProfile.TRIGGER;
}) {
  try {
    if (!payload?.noLoader) yield put(actions.tabLoading(true));
    const res = yield call(Api.auth.signIn);
    yield put(getProfile.success(res));
  } catch (err) {
    yield put(getProfile.failure(err));
  } finally {
    if (!payload?.noLoader) yield put(actions.tabLoading(false));
  }
}
