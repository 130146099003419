import { Dispatch, SetStateAction, memo, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
// components
import SubtaskItem from './SubtaskItem';
import { BUTTON_TYPES, Button, Error } from 'components/common';
import { MAX_SUBTASKS_COUNT } from 'lib/constants';
// icons
import { PlusIcon } from 'lib/icons';
import { message } from 'utils/message';
import { prepareDuplicateSubtaskList } from 'utils/prepareDuplicateSubtaskData';
// utils
import { v4 as uuidv4 } from 'uuid';
// styles
import { Subtasks, SubtasksList } from './styles';
import {
  HookFormRecord,
  IProject,
  ISubtask,
  ISubtaskTypeDto,
  ISubtasksErrors,
} from 'types';

interface ISubtasksInfoProps {
  subtasksInWrap: boolean;
  subtaskTypes: ISubtaskTypeDto[];
  name: string;
  errors: ISubtasksErrors;
  subtasks: any;
  isInProject: boolean;
  isTemplate: boolean;
  projectId: string;
  project?: IProject;
  isEdit?: boolean;
  setRemoveChatsIds?: Dispatch<SetStateAction<string[]>>;
}

const SubtasksInfo = ({
  isEdit,
  subtasksInWrap,
  isTemplate,
  subtaskTypes,
  name = '',
  errors,
  subtasks,
  isInProject,
  projectId,
  project,
  setRemoveChatsIds,
}: ISubtasksInfoProps) => {
  const { getValues } = useFormContext();

  const appendSubtask = useCallback(() => {
    const subtaskOrders = subtasks.fields?.map(item => item.order);
    const lastOrder = Math.max(...(subtaskOrders || [0]));
    const newOrder = subtaskOrders?.length < 1 ? 1 : lastOrder + 1;
    subtasks.append({
      id: uuidv4(),
      name: '',
      plannedHours: 1,
      templateHours: isTemplate ? 1 : 0,
      actualHours: 0,
      isApproveRequired: false,
      isPhotoRequired: false,
      subtaskTypeId: '',
      notes: '',
      order: newOrder,
      files: [],
      checklist: [],
    });
  }, [subtasks, isTemplate]);

  const handleDuplicateSubtask = useCallback(
    (index: number, amount: number) => {
      const subtasksFormName = `${name}subtasks`;
      const currentSubtasks = getValues(subtasksFormName as HookFormRecord);

      const updatedSubtasks = prepareDuplicateSubtaskList({
        currentSubtask: currentSubtasks[index],
        amount,
      });

      subtasks.append(updatedSubtasks);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success('Duplication completed');
    },
    [getValues, name, subtasks]
  );

  const subtasksList = useMemo(
    () =>
      subtasks.fields.map((subtask: ISubtask, i) => (
        <SubtaskItem
          subtasksCount={subtasks.fields.length}
          handleDuplicateSubtask={handleDuplicateSubtask}
          setRemoveChatsIds={setRemoveChatsIds}
          isEdit={isEdit}
          isTemplate={isTemplate}
          name={name}
          key={subtask.id}
          subtask={subtask}
          subtaskTypes={subtaskTypes}
          i={i}
          isInProject={isInProject}
          remove={subtasks.remove}
          errors={errors}
          projectId={projectId}
          project={project}
          subtasksValue={subtasks?.fields && (subtasks?.fields[i] as ISubtask)}
        />
      )),
    [
      subtasks,
      isTemplate,
      name,
      subtaskTypes,
      errors,
      project,
      isInProject,
      handleDuplicateSubtask,
      isEdit,
      projectId,
      setRemoveChatsIds,
    ]
  );

  const subtasksCount = subtasks?.fields?.length || 0;

  return (
    <Subtasks subtasksInWrap={subtasksInWrap}>
      <SubtasksList>
        {errors?.subtasks &&
          !Array.isArray(errors?.subtasks) &&
          errors?.subtasks?.message && (
            <Error>{errors?.subtasks?.message}</Error>
          )}
        {subtasksList}
        {subtasksCount < MAX_SUBTASKS_COUNT && (
          <Button
            onClick={appendSubtask}
            label="Add Task"
            kind={BUTTON_TYPES.LINK}
            type="link"
            space="6px"
            icon={<PlusIcon />}
            style={{
              paddingLeft: 0,
              alignItems: 'baseline',
              marginLeft: 'auto',
            }}
          />
        )}
      </SubtasksList>
    </Subtasks>
  );
};

export default memo(SubtasksInfo, isEqual);
