import styled, { css } from 'styled-components';
// components
import { Input } from 'antd';

const globalStyles = css`
  padding: 10px;
  max-width: 355px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.grayDark};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  input {
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.grayDark};
    ::placeholder {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
  }
  .ant-input-prefix {
    margin-top: -2px;
  }
`;

const primaryStyles = css<{
  fontSize?: string;
}>`
  border: none;
  max-width: 242px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.grayLight};
  input {
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.grayLight};
    box-shadow: none;
    font-size: ${props => props.fontSize};
    ::placeholder {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

const borderedStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  max-width: 242px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  input {
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.white};
    ::placeholder {
      color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
  }
`;

const styles = {
  primary: primaryStyles,
  bordered: borderedStyles,
  global: globalStyles,
};

export const InputStyled = styled(Input)<{
  stylestype?: 'primary' | 'bordered' | 'global';
}>`
  align-items: center;
  padding: 5px 10px;
  box-shadow: none;
  border-radius: 56px;
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
  }
  input {
    box-shadow: none;
  }
  .ant-input-prefix {
    margin-right: 15px;
    margin-top: 2px;
  }
  ${props => styles[props.stylestype]}
  ${props => props.width && `width:${props.width};`}
  ${props =>
    props.height && `height:${props.height};`}
`;
