import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetGroups() {
  yield call(E.ensureGetGroups, { type: Actions.groups.TRIGGER });
  yield takeLatest(Actions.groups.TRIGGER, E.ensureGetGroups);
}

export function* watchDeleteGroup() {
  yield takeLatest(Actions.deleteGroup.TRIGGER, E.ensureDeleteGroup);
}

export function* watchOrderGroup() {
  yield takeLatest(Actions.groupsOrder.TRIGGER, E.ensureOrderGroup);
}

export function* watchUpdateGroup() {
  yield takeLatest(Actions.updateGroup.TRIGGER, E.ensureUpdateGroup);
}

export function* watchGetGroup() {
  yield takeLatest(Actions.getGroup.TRIGGER, E.ensureGetGroupById);
}

export function* watchAddGroup() {
  yield takeLatest(Actions.addGroup.TRIGGER, E.ensureAddGroup);
}

export function* watchAddResourceToGroup() {
  yield takeLatest(
    Actions.addResourceToGroup.TRIGGER,
    E.ensureAddResourceToGroup
  );
}

export function* watchRemoveResourceFromGroup() {
  yield takeLatest(
    Actions.removeResourceFromGroup.TRIGGER,
    E.ensureDeleteResourceFromGroup
  );
}
