import {
  defaultGroup,
  defaultItem,
  getActualChangelog,
  getGanttSubtask,
  getGroup,
  getResourceGroup,
  getSubtaskGanttChangelog,
  getSubtaskPosition,
  isOnReview,
} from 'utils/helpersGantt';
import { DEFAULT_SUBTASK_OFFSET } from './../lib/constants/gantt';
import moment from 'moment-timezone';
import { IGanttResourceDto, ISubtaskDto, SubtaskStatusesEnum } from 'types';

const isBetweenDates = (
  date: moment.Moment,
  startDate: moment.Moment,
  endDate: moment.Moment
) => date.isBetween(startDate, endDate, undefined, '[]');

export const prepareGanttData = (
  data: IGanttResourceDto[],
  startDate: moment.Moment,
  endDate: moment.Moment,
  isWeek: boolean
) => {
  let groupId: string;
  const groups = [];
  const subtasks = [];

  const sortedGroups: IGanttResourceDto[] = getSortedGroups(data);

  const startPeriod = moment(startDate);
  const endPeriod = moment(endDate);
  const defaultWorkingTime = { from: 0, to: 24 };
  const ganttResourceWorkingTime = data.reduce(
    (time, item) => {
      if (item.workingTime.from.hours < time.from) {
        time.from = item.workingTime.from.hours;
      }
      if (item.workingTime.to.hours >= time.to) {
        time.to = item.workingTime.to.hours;
        if (item.workingTime.to.minutes > 0) {
          time.to += 1;
        }
      }

      return time;
    },
    { from: 24, to: 0 }
  );
  const ganttDailyWorkingTime = !!data.length
    ? ganttResourceWorkingTime
    : defaultWorkingTime;
  subtasks.push(defaultItem(startPeriod, endPeriod));

  sortedGroups.forEach(item => {
    let needReview = false;
    const groupSubtasks = item?.subtasks || [];

    item.busyDates?.forEach(date => {
      const startPeriod = moment(date).startOf('d');
      const endPeriod = moment(date).endOf('d');
      subtasks.push({
        ...defaultItem(startPeriod, endPeriod, item._id),
        isDisabledDate: true,
      });
    });

    groupSubtasks.forEach(subtask => {
      const subtaskChangelog = getActualChangelog(subtask);

      const changelogLength = subtaskChangelog.length;
      const lastChangelogItem =
        changelogLength && subtaskChangelog[changelogLength - 1];
      const canMove = !changelogLength;
      const subtaskNeedReview = isOnReview(subtask);
      const isSubtaskDone =
        lastChangelogItem?.status === SubtaskStatusesEnum.DONE ||
        subtask.status === SubtaskStatusesEnum.DONE;

      if (subtaskNeedReview) needReview = true;

      const subtaskPosition = getSubtaskPosition(
        isWeek,
        subtask,
        startPeriod,
        endPeriod,
        item?.workingTime,
        isSubtaskDone,
        changelogLength
      );

      if (!subtaskPosition) return;

      const { subtaskStartDate, subtaskEndDate, isCropedLeft, isCropedRight } =
        subtaskPosition;

      const intervals = prepareIntervals(
        subtaskEndDate,
        subtaskStartDate,
        isSubtaskDone,
        isWeek,
        subtask,
        isCropedRight,
        isCropedLeft
      );

      /*
        dividing subtasks by intervals (by days for example)
        cause at the start we have just start date and due date
      */
      intervals.forEach(({ start, end, leftBorder, rightBorder }) => {
        const toDoItem = {
          diff: end.valueOf() - start.valueOf(),
          status: SubtaskStatusesEnum.TO_DO,
        };

        const subtaskGanttChangelog = getSubtaskGanttChangelog(
          subtaskChangelog,
          start,
          end
        );
        const changelog = !changelogLength ? [toDoItem] : subtaskGanttChangelog;
        const updatedStartTime =
          isWeek ||
          isBetweenDates(moment(subtask.startDate), startPeriod, endPeriod) ||
          subtask.status === SubtaskStatusesEnum.PAUSED
            ? start
            : moment(start).set({
                hour: ganttDailyWorkingTime.from,
              });

        const newItem = getGanttSubtask(
          item,
          subtask,
          changelog,
          canMove,
          subtaskNeedReview,
          updatedStartTime,
          end,
          leftBorder,
          rightBorder
        );

        subtasks.push(newItem);
      });
    });

    const newGroup = getGroup(item, needReview);

    if (newGroup?.group?._id && newGroup?.group?._id !== groupId) {
      groupId = newGroup?.group?._id;
      const count = data?.filter(i => i?.group?._id === groupId)?.length || 0;
      groups.push(getResourceGroup(newGroup?.group, count));
      subtasks.push(defaultItem(startPeriod, endPeriod, groupId, 0));
      subtasks.push(defaultItem(startPeriod, endPeriod, groupId, 1));
    }
    groups.push(newGroup);
  });

  groups.push(defaultGroup);

  return {
    groups,
    items: subtasks.sort((a, b) => a.subtaskStart - b.subtaskStart),
    ganttDailyWorkingTime,
  };
};

const getSortedGroups = (data: IGanttResourceDto[]) => {
  const groupsObj = data.reduce((acc, item) => {
    const groupOrder = item?.group?.order || 0;

    acc[groupOrder] =
      typeof acc[groupOrder] !== 'undefined'
        ? [...acc[groupOrder], item]
        : [item];

    return acc;
  }, {});

  return Object.keys(groupsObj)
    .sort((a, b) => +b - +a)
    .reduce((acc, order) => {
      acc.push(
        ...groupsObj[order].sort((a, b) => {
          const nameA =
            (String(a?.firstName) || '').trim() +
            (String(a?.lastName) || '').trim();
          const nameB =
            (String(b?.firstName) || '').trim() +
            (String(b?.lastName) || '').trim();

          return nameA.localeCompare(nameB);
        })
      );

      return acc;
    }, []);
};

const prepareIntervals = (
  subtaskEndDate: moment.Moment,
  subtaskStartDate: moment.Moment,
  isSubtaskDone: boolean,
  isWeek: boolean,
  subtask: ISubtaskDto,
  isCropedRight: boolean,
  isCropedLeft: boolean
) => {
  const intervals = [];
  const intervalsCount = subtaskEndDate.diff(subtaskStartDate, 'days');

  for (let i = 0; i <= intervalsCount; i++) {
    const startSubtask = moment(subtaskStartDate).add(i, 'day');
    const endSubtaskTime = (isSubtaskDone && i === intervalsCount) || !isWeek;
    const startOfDay = moment(startSubtask).startOf('day');
    const startInterval = isWeek ? moment(startOfDay) : moment(startSubtask);
    const endOfInt = moment(startInterval).endOf('day');
    const endInterval = endSubtaskTime
      ? moment(subtaskEndDate)
      : moment(endOfInt);
    const start = moment(startInterval);
    if (isWeek) start.add(DEFAULT_SUBTASK_OFFSET, 'minute');
    const end = endSubtaskTime
      ? moment(subtaskEndDate)
      : moment(endInterval).subtract(DEFAULT_SUBTASK_OFFSET, 'minute');

    const isStartedInInterval = moment(subtask?.startDate).isBetween(
      startInterval,
      endInterval,
      undefined,
      '[]'
    );
    const isAfterToday = subtaskEndDate.isSameOrAfter(endOfInt);
    const isFinishedInInterval = moment(subtask?.dueDate).isBetween(
      startInterval,
      endInterval,
      undefined,
      '[]'
    );
    const isLastInterval = i === intervalsCount;

    const leftBorder = isCropedLeft || !isStartedInInterval;

    const rightBorder =
      isCropedRight ||
      (isWeek && !isLastInterval && !isFinishedInInterval) ||
      subtaskEndDate.isAfter(endOfInt) ||
      (!isWeek && !isFinishedInInterval && isAfterToday);

    intervals.push({
      start,
      end,
      leftBorder,
      rightBorder,
    });
  }

  return intervals;
};
