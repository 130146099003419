import { Triangle } from 'lib/icons';
import theme from 'styles/theme';
import { DateFilterIconsWrap, DateFilterWrap } from './styles';

interface IDateFilterProps {
  isDateFilterUp: boolean;
  onChange: () => void;
}

const DateFilter = ({
  isDateFilterUp,
  onChange,
}: IDateFilterProps): JSX.Element => (
  <DateFilterWrap onClick={onChange}>
    <DateFilterIconsWrap>
      <Triangle color={isDateFilterUp && theme.colors.lightBlue} />
      <Triangle color={!isDateFilterUp && theme.colors.lightBlue} />
    </DateFilterIconsWrap>
    Date
  </DateFilterWrap>
);

export default DateFilter;
