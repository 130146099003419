import styled from 'styled-components';
import { SelectStyled, StyledMenu } from 'components/common/SelectInput/styles';

export const SelectItem = styled(SelectStyled)`
  margin-left: auto;

  &&&& .ant-select-selector {
    position: relative;
    border-color: ${({ theme }) => theme.colors.mutedInputBorderColor};
    border-radius: 0;
    height: 24px;
    &:focus {
      border-color: ${({ theme }) => theme.colors.mutedInputBorderColor};
    }
  }

  &&&&&& .ant-select-arrow {
    top: 43%;
  }
`;

export const MenuItem = styled(StyledMenu)`
  padding: 0;
`;
