export const ScrollIndicator = () => (
  <svg
    width="13"
    height="3"
    viewBox="0 0 13 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.57373 1.42494C0.57373 1.08631 0.656576 0.804125 0.822266 0.578374C0.993874 0.352622 1.24833 0.239746 1.58562 0.239746C1.92292 0.239746 2.17738 0.352622 2.34898 0.578374C2.52651 0.804125 2.61527 1.08631 2.61527 1.42494C2.61527 1.74946 2.52651 2.02107 2.34898 2.23976C2.17738 2.45846 1.92292 2.56781 1.58562 2.56781C1.24833 2.56781 0.993874 2.45846 0.822266 2.23976C0.656576 2.02107 0.57373 1.74946 0.57373 1.42494Z"
      fill="white"
    />
    <path
      d="M5.36691 1.42494C5.36691 1.08631 5.44976 0.804125 5.61545 0.578374C5.78706 0.352622 6.04151 0.239746 6.37881 0.239746C6.71611 0.239746 6.97056 0.352622 7.14217 0.578374C7.31969 0.804125 7.40845 1.08631 7.40845 1.42494C7.40845 1.74946 7.31969 2.02107 7.14217 2.23976C6.97056 2.45846 6.71611 2.56781 6.37881 2.56781C6.04151 2.56781 5.78706 2.45846 5.61545 2.23976C5.44976 2.02107 5.36691 1.74946 5.36691 1.42494Z"
      fill="white"
    />
    <path
      d="M10.1601 1.42494C10.1601 1.08631 10.2429 0.804125 10.4086 0.578374C10.5802 0.352622 10.8347 0.239746 11.172 0.239746C11.5093 0.239746 11.7637 0.352622 11.9353 0.578374C12.1129 0.804125 12.2016 1.08631 12.2016 1.42494C12.2016 1.74946 12.1129 2.02107 11.9353 2.23976C11.7637 2.45846 11.5093 2.56781 11.172 2.56781C10.8347 2.56781 10.5802 2.45846 10.4086 2.23976C10.2429 2.02107 10.1601 1.74946 10.1601 1.42494Z"
      fill="white"
    />
  </svg>
);
