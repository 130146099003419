import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get subtaskTypes
export const subtaskTypes = createRoutine('admin/subtaskTypes');
export const subtaskTypesPromiseCreator = promisifyRoutine(subtaskTypes);

// get subtaskTypes container defaults
export const subtaskTypesDefaults = createRoutine('admin/subtaskTypesDefaults');
export const subtaskTypesDefaultsPromiseCreator =
  promisifyRoutine(subtaskTypesDefaults);

// update subtaskType
export const updateSubtaskType = createRoutine('admin/updateSubtaskType');
export const updateSubtaskTypePromiseCreator =
  promisifyRoutine(updateSubtaskType);
// get subtaskType
export const getSubtaskType = createRoutine('admin/getSubtaskType');
export const getSubtaskTypePromiseCreator = promisifyRoutine(getSubtaskType);
// getResourceSubtaskTypeByLocation
export const getResourceSubtaskTypesByLocation = createRoutine(
  'admin/getResourceSubtaskTypesByLocation'
);
export const getResourceSubtaskTypesByLocationPromiseCreator = promisifyRoutine(
  getResourceSubtaskTypesByLocation
);
// add subtaskType
export const addSubtaskType = createRoutine('admin/addSubtaskType');
export const addSubtaskTypePromiseCreator = promisifyRoutine(addSubtaskType);

// add subtaskType with checklist
export const addSubtaskTypeWithChecklist = createRoutine(
  'admin/addSubtaskTypeWithChecklist'
);
export const addSubtaskTypeWithChecklistPromiseCreator = promisifyRoutine(
  addSubtaskTypeWithChecklist
);

// delete SubtaskType
export const deleteSubtaskType = createRoutine('admin/deleteSubtaskType');
export const deleteSubtaskTypePromiseCreator =
  promisifyRoutine(deleteSubtaskType);
// search
export const setSubtaskTypesSearch = createRoutine(
  'admin/setSubtaskTypesSearch'
);

export const getChecklistItem = createRoutine('admin/getChecklistItem');
export const getChecklistItemPromiseCreator =
  promisifyRoutine(getChecklistItem);
export const addChecklistItem = createRoutine('admin/addChecklistItem');
export const addChecklistItemPromiseCreator =
  promisifyRoutine(addChecklistItem);
export const addBulkChecklist = createRoutine('admin/addBulkChecklist');
export const addBulkChecklistPromiseCreator =
  promisifyRoutine(addBulkChecklist);
export const updateChecklistItem = createRoutine('admin/updateChecklistItem');
export const updateChecklistItemPromiseCreator =
  promisifyRoutine(updateChecklistItem);
export const deleteChecklistItem = createRoutine('admin/deleteChecklistItem');
export const deleteChecklistItemPromiseCreator =
  promisifyRoutine(deleteChecklistItem);
