import styled from 'styled-components';
import { Divider } from 'antd';

export const Container = styled.div`
  padding: 0 38px;
`;

export const DividerItem = styled(Divider)`
  margin: 10px 0;
`;
