import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DateSelect from './DateSelect';
// components
import StatusSelect from './StatusSelect';
import moment from 'moment-timezone';
// constants
import { IConflictSubtaskErorr, SubtaskStatusesEnum } from 'types';

interface ISetStatusProps {
  onSubmit?: (
    newStatus: string,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    date: moment.Moment,
    prevStatus: string,
    percent?: number
  ) => void;
  status?: string;
  subtaskStartDate?: string;
  subtaskDueDate?: string;
  conflictSubtask?: IConflictSubtaskErorr;
  defaultPercent?: number;
  datePickerWidth?: number;
}

const SetStatus = ({
  onSubmit,
  status,
  conflictSubtask,
  defaultPercent,
  datePickerWidth,
}: ISetStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [subtaskPercent, setSubtaskPercent] = useState(defaultPercent);

  useEffect(() => {
    setSubtaskPercent(conflictSubtask?.percent);
  }, [conflictSubtask?.percent]);

  const isNewStatusPaused = useMemo(
    () => newStatus === SubtaskStatusesEnum.PAUSED,
    [newStatus]
  );

  const [date, setDate] = useState<any>(moment());

  const dateNeededStatuses = [
    SubtaskStatusesEnum.DONE,
    SubtaskStatusesEnum.IN_PROGRESS,
    SubtaskStatusesEnum.PAUSED,
  ];

  const onStatusChange = (value, setIsLoading) => {
    const isTimeNeeded = dateNeededStatuses.some(item => item === value);
    if (!isTimeNeeded) {
      setIsLoading(true);
      onSubmit(value, setIsLoading, null, status);
      return;
    }
    setNewStatus(value);
  };

  const onSubmitClick = useCallback(() => {
    setIsLoading(true);
    onSubmit(newStatus, setIsLoading, date.toDate(), status, subtaskPercent);
  }, [setIsLoading, onSubmit, newStatus, date, status, subtaskPercent]);

  const onPercentChange = useCallback(
    percent => {
      setSubtaskPercent(percent);
    },
    [setSubtaskPercent]
  );

  const isAllowToSubmit =
    !!date &&
    (newStatus !== SubtaskStatusesEnum.PAUSED ||
      (newStatus === SubtaskStatusesEnum.PAUSED &&
        typeof subtaskPercent === 'number'));

  return newStatus ? (
    <DateSelect
      isAllowToSubmit={isAllowToSubmit}
      isLoading={isLoading}
      onSubmitClick={onSubmitClick}
      setDate={setDate}
      showPercents={isNewStatusPaused}
      onPercentChange={onPercentChange}
      conflictSubtask={conflictSubtask}
      defaultPercent={defaultPercent}
      value={date}
      width={datePickerWidth}
    />
  ) : (
    <StatusSelect onChange={onStatusChange} status={status} />
  );
};

export default SetStatus;
