import { useCallback, useState } from 'react';
// components
import EditTask from '../EditTask';
import { Button } from 'components/common';
// styles
import { TaskWrap, Wrap } from './styles';
import { IProjectTaskDto, ISubtaskTypeDto, OnProjectUpdateSubmit } from 'types';

interface IAddNewTaskProps {
  subtaskTypes: ISubtaskTypeDto[];
  index: number;
  addTask: (
    data: { data: FormData; id: undefined },
    related: boolean,
    onFinishLoading: () => void,
    skipContainerUniqueness: boolean
  ) => void;
  appendContainer: (container: any) => void;
  isOpenForm: boolean;
  setIsOpenForm: (visible: boolean) => void;
  notAvailableProject?: boolean;
  projectId: string;
}

const AddNewTask = ({
  subtaskTypes,
  index,
  addTask,
  isOpenForm,
  setIsOpenForm,
  notAvailableProject,
  projectId,
  appendContainer,
}: IAddNewTaskProps) => {
  const onButtonClick = useCallback(() => {
    setIsOpenForm(true);
    setTasksList([{}]);
  }, [setIsOpenForm]);
  const [tasksList, setTasksList] = useState<Array<Partial<IProjectTaskDto>>>(
    []
  );

  const removeTask = useCallback(
    (_, setDeleteLoading, removeId) => {
      if (tasksList.length > 1) {
        setTasksList(tasksList.filter(task => task._id !== removeId));
      } else {
        setIsOpenForm(false);
      }
      setDeleteLoading(false);
    },
    [setIsOpenForm, tasksList]
  );

  const handleAddTask: OnProjectUpdateSubmit = ({
    formData,
    setIsSubmitLoading,
    skipContainerUniqueness,
  }) => {
    const newRelated = tasksList.filter(
      item =>
        (formData._id && item._id !== formData._id) ||
        (!formData._id && item._id)
    );
    setIsSubmitLoading(true);
    const onFinishLoading = () => {
      setIsSubmitLoading(false);
      setTasksList(newRelated);
    };

    addTask(
      { ...formData, _id: undefined },
      Boolean(newRelated.length),
      onFinishLoading,
      skipContainerUniqueness
    );
  };

  const onRelatedAdd = tasks => setTasksList(tasks);

  return (
    <Wrap>
      {isOpenForm && tasksList?.length ? (
        <>
          {tasksList?.map((item, itemIndex) => (
            <TaskWrap key={item._id || itemIndex}>
              <EditTask
                item={item}
                isSingle
                index={index + itemIndex}
                removeTask={removeTask}
                subtaskTypes={subtaskTypes}
                onSubmit={handleAddTask}
                notAvailableProject={notAvailableProject}
                withoutCross
                setRelated={onRelatedAdd}
                projectId={projectId}
                appendContainer={appendContainer}
              />
            </TaskWrap>
          ))}
        </>
      ) : (
        <Button
          onClick={onButtonClick}
          label="Add Item"
          className="addNewTaskBtn"
        />
      )}
    </Wrap>
  );
};

export default AddNewTask;
