import * as yup from 'yup';
import {
  availableLocations,
  email,
  firstName,
  lastName,
  locationId,
  roles,
} from './baseSchema';

const editUserSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  locationId,
  roles,
  availableLocations,
});

export default editUserSchema;
