import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { companySelector } from 'store/company';
import { getMenuPath } from 'utils/helpers';
import MenuItem from './MenuItem/MenuItem';
import { ISideBarItem, SettingsMenuLabelsEnum } from 'types';

interface ISideBarMenuProps {
  siderItems: ISideBarItem[];
}

const SideBarMenu = ({ siderItems }: ISideBarMenuProps) => {
  const [selectedKeys, setSelectedKeys] = useState();
  const location = useLocation();
  const company = useSelector(companySelector);
  const isDepotSettingsEnabled = company?.isContainersEnabled;

  useEffect(() => {
    setSelectedKeys(getMenuPath(location.pathname, 2));
  }, [location]);

  return (
    <>
      {siderItems.map(item => {
        if (
          !isDepotSettingsEnabled &&
          item.label === SettingsMenuLabelsEnum.DepotSettings
        ) {
          return null;
        }
        return (
          <MenuItem item={item} key={item.key} selectedKeys={selectedKeys} />
        );
      })}
    </>
  );
};

export default memo(SideBarMenu);
