import { v4 as uuidv4 } from 'uuid';
import { ISubtaskItemData } from 'types';

export const prepareDuplicateSubtaskData = (
  currentSubtask: ISubtaskItemData
) => ({
  actualHours: currentSubtask.actualHours,
  checklist: currentSubtask.checklist.map(item => ({
    title: item.title,
    isCompleted: false,
  })),
  files: currentSubtask.files,
  isApproveRequired: currentSubtask.isApproveRequired,
  isPhotoRequired: currentSubtask.isPhotoRequired,
  name: currentSubtask.name,
  notes: currentSubtask.notes,
  subtaskTypeId: currentSubtask.subtaskTypeId,
  templateHours: currentSubtask.templateHours,
  plannedHours: currentSubtask.plannedHours,
});

interface PrepareDuplicateSubtaskListProps {
  currentSubtask: ISubtaskItemData;
  amount: number;
}

export const prepareDuplicateSubtaskList = ({
  currentSubtask,
  amount,
}: PrepareDuplicateSubtaskListProps) => {
  const replaceSubtaskList = [];
  const subtaskDuplicateData = prepareDuplicateSubtaskData(currentSubtask);

  for (let i = 0; i < amount; i++) {
    replaceSubtaskList.push({
      ...subtaskDuplicateData,
      id: uuidv4(),
      order: currentSubtask.order,
    });
  }

  return replaceSubtaskList;
};
