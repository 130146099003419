import styled from 'styled-components';
import { Row } from 'antd';

export const Wrap = styled(Row)`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 28px;
`;
