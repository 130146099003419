import { Text } from 'components/common';
// styles
import { ButtonStyled } from './styles';

interface IInboundButtonProps {
  onClick?: () => void;
  label?: string;
  param?: number;
  className?: string;
}

export const InboundButton = ({
  label,
  param,
  ...props
}: IInboundButtonProps) => (
  <ButtonStyled {...props}>
    {label}
    <Text>{param}</Text>
  </ButtonStyled>
);
