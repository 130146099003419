import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-select-selection-item {
    padding-right: 31px !important;
  }

  .ant-select-selection-search {
    input {
      width: 133px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-select-selector {
    line-height: 21px;
  }
`;

export const ButtonWrap = styled.div`
  position: absolute;
  top: 7px;
  right: 4px;
`;
