import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchTruckingCompanies() {
  yield call(E.ensureTruckingCompanies, {
    type: Actions.truckingCompanies.TRIGGER,
  });
  yield takeLatest(
    Actions.truckingCompanies.TRIGGER,
    E.ensureTruckingCompanies
  );
}

export function* watchAddTruckingCompany() {
  yield takeLatest(
    Actions.addTruckingCompany.TRIGGER,
    E.ensureAddTruckingCompany
  );
}

export function* watchDeleteTruckingCompany() {
  yield takeLatest(
    Actions.deleteTruckingCompany.TRIGGER,
    E.ensureDeleteTruckingCompany
  );
}

export function* watchTruckingCompaniesById() {
  yield takeLatest(
    Actions.getTruckingCompanyById.TRIGGER,
    E.ensureTruckingCompaniesById
  );
}

export function* watchEditeTruckingCompany() {
  yield takeLatest(
    Actions.editTruckingCompany.TRIGGER,
    E.ensureEditeTruckingCompany
  );
}
