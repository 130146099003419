import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// redux
import { projectForCustomerSelector } from 'store/sharing';
import CompanyInfo from './CompanyInfo';
import Files from './Files';
// components
import Tasks from './Tasks';
// icons
import { LogoBig } from 'lib/icons';
// styles
import {
  Body,
  Description,
  Logo,
  PageWrap,
  ProjectInfoWrap,
  Title,
  TitleWrap,
  Wrap,
} from './styles';

const CustomerView = () => {
  const data = useSelector(projectForCustomerSelector);

  const tasks = useMemo(() => {
    const tasksWithoutSubtasks = data?.tasks?.filter(
      task => task.subtasks.length !== 0
    );
    const result = [...(tasksWithoutSubtasks || [])];

    return result?.sort((a, b) =>
      a.type > b.type ? -1 : a.type < b.type ? 1 : 0
    );
  }, [data]);

  if (!data || !Object.keys(data).length) return null;

  return (
    <PageWrap>
      <Wrap>
        <Logo>
          <LogoBig />
        </Logo>
        <TitleWrap>
          <Title>Hi, {data?.customer}</Title>
          <Description>
            Here you can check progress of your order in real time
          </Description>
        </TitleWrap>
        <Body>
          <Tasks data={tasks} />
          <ProjectInfoWrap>
            <CompanyInfo data={data} />
            <Files files={data?.files} />
          </ProjectInfoWrap>
        </Body>
      </Wrap>
    </PageWrap>
  );
};

export default CustomerView;
