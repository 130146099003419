import styled from 'styled-components';

export const SubtasksListItemForm = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 18px 11px 13px;
  flex-wrap: wrap;
  gap: 10px;
  svg {
    margin-right: 15px;
  }
`;

export const DragAndDropIconWrapper = styled.div`
  margin-top: 25px;
`;

export const CheckBoxes = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 10px;
`;
