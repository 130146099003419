import { TABLE_INNER_WIDTH } from '../constants';
import Header from './Header';
import Row from './Row';
import { Loader } from 'components/common';
import { Body, LoadingWrap, NoDataWrap, Wrap } from './styles';
import { ISubtaskDto } from 'types';

interface ITableProps {
  data: any[];
  subtask: ISubtaskDto;
  isLoading?: boolean;
  updateLogItems: () => void;
  toggleSort: () => void;
  isStandalone: boolean;
  sortDirection: boolean;
  isHistorical?: boolean;
  projectId?: string;
}

const Table = ({
  data,
  isLoading,
  subtask,
  projectId,
  updateLogItems,
  toggleSort,
  sortDirection,
  isStandalone,
  isHistorical,
}: ITableProps) => (
  <Wrap>
    {!isHistorical && (
      <Header sortDirection={sortDirection} toggleSort={toggleSort} />
    )}
    <Body style={{ width: TABLE_INNER_WIDTH }}>
      {isLoading && (
        <LoadingWrap>
          <Loader loading={isLoading} />
        </LoadingWrap>
      )}
      {!isLoading && (
        <>
          {data.length ? (
            data.map(item => (
              <Row
                key={item._id}
                item={item}
                subtask={subtask}
                updateLogItems={updateLogItems}
                isStandalone={isStandalone}
                isHistorical={isHistorical}
                projectId={projectId}
              />
            ))
          ) : (
            <NoDataWrap>There are no items</NoDataWrap>
          )}
        </>
      )}
    </Body>
  </Wrap>
);

export default Table;
