import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  delegateSubtaskPromiseCreator,
  revokeSubtaskPromiseCreator,
} from 'store/dashboard';
// redux
import {
  deleteTokenPromiseCreator,
  getTokenPromiseCreator,
  sendLinkPromiseCreator,
} from 'store/sharing';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import { BUTTON_TYPES, Button, Input, Text, TextArea } from 'components/common';
import { useActionsRoutines } from 'hooks';
// utils
import { Link } from 'lib/icons';
import theme from 'styles/theme';
import { message } from 'utils/message';
import { PublicLinkSchema } from 'validation';
// styles
import { ButtonStyled, LinkInput, Title, Wrap, WrapLink } from './styles';

interface ICreatePublicLink {
  setIsOpenShare?: (open: boolean) => void;
  isOpenShare?: boolean;
  id?: string;
  generatedApiKey?: { value?: string };
  isSubtask?: boolean;
  taskId?: string;
}

const CreatePublicLink = ({
  setIsOpenShare,
  id,
  generatedApiKey,
  isSubtask,
  taskId,
}: ICreatePublicLink) => {
  const [isLoading, toggleLoading] = useState(false);
  const [isSendLoading, toggleSendLoading] = useState(false);
  const createToken = useActionsRoutines(getTokenPromiseCreator);
  const removeToken = useActionsRoutines(deleteTokenPromiseCreator);
  const sendLink = useActionsRoutines(sendLinkPromiseCreator);

  const delegateSubtask = useActionsRoutines(delegateSubtaskPromiseCreator);
  const revokeSubtask = useActionsRoutines(revokeSubtaskPromiseCreator);

  const text = useMemo(
    () =>
      `${window.location.origin}/${
        isSubtask ? 'subtasks' : 'projects'
      }/${id}/sharing/${generatedApiKey?.value}`,
    [generatedApiKey, id, isSubtask]
  );

  const form = useForm({
    resolver: yupResolver(PublicLinkSchema),
    shouldUnregister: true,
  });

  const generateLink = useCallback(() => {
    toggleLoading(true);

    const request = isSubtask
      ? delegateSubtask({ id, taskId })
      : createToken(id);
    request
      .catch(err => console.error(err))
      .finally(() => toggleLoading(false));
  }, [createToken, delegateSubtask, isSubtask, id, taskId]);

  const disabledLink = useCallback(() => {
    toggleLoading(true);
    const request = isSubtask ? revokeSubtask({ id, taskId }) : removeToken(id);
    request
      .catch(err => console.error(err))
      .finally(() => toggleLoading(false));
  }, [id, taskId, isSubtask, revokeSubtask, removeToken]);

  const copyLink = useCallback(() => {
    window.navigator.clipboard
      .writeText(text)
      .then(() => message.success('Text has been copied!'))
      .catch(() => message.error(['Something went wrong!']));
  }, [text]);

  const setLink = useCallback(
    data => {
      toggleSendLoading(true);
      sendLink({ ...data, text })
        .then(() => {
          toggleSendLoading(false);
          setIsOpenShare(false);
        })
        .catch(() => toggleSendLoading(false));
    },
    [sendLink, setIsOpenShare, text]
  );

  const [isTextMarked, setIsTextMarked] = useState(false);

  const onTextDoubleClick = useCallback(() => {
    setIsTextMarked(true);
    copyLink();
  }, [setIsTextMarked, copyLink]);

  const handleOnWrapClick = useCallback(
    () => setIsTextMarked(false),
    [setIsTextMarked]
  );

  const handleFormSubmit = useCallback(async () => {
    setLink(form.getValues() as any);
    await form.trigger();
  }, [form, setLink]);

  return (
    <form>
      <Wrap onClick={handleOnWrapClick}>
        <Title>Create public link</Title>
        {generatedApiKey ? (
          <WrapLink>
            <LinkInput>
              <Text
                onDoubleClick={onTextDoubleClick}
                ellipsis={{ rows: 1 }}
                mark={isTextMarked}
              >
                {text}
              </Text>
              <Button
                kind={BUTTON_TYPES.CANCEL}
                label="copy"
                height="40px"
                width="73px"
                onClick={copyLink}
              />
            </LinkInput>
            <ButtonStyled
              onClick={disabledLink}
              label="Disable link"
              kind={BUTTON_TYPES.LINK}
              type="link"
              loading={isLoading}
            />
          </WrapLink>
        ) : (
          <Button
            label="Generate link"
            height="49px"
            space="3px"
            icon={<Link color={theme.colors.white} />}
            style={{ marginBottom: 25 }}
            onClick={generateLink}
            loading={isLoading}
          />
        )}
        <Controller
          render={({ field }) => (
            <Input
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              name={field.name}
              label="Email"
              placeholder="email"
              error={form?.formState?.errors?.to?.message}
              disabled={!generatedApiKey}
              width="100%"
              style={{ marginBottom: 12 }}
            />
          )}
          name="to"
          control={form.control}
        />
        <Controller
          render={({ field }) => (
            <TextArea
              onChange={field.onChange}
              value={field.value}
              name={field.name}
              label="Email Notes"
              placeholder="Write your notes.."
              error={form?.formState?.errors?.subject?.message}
              rows={2}
              style={{ marginBottom: 24 }}
              disabled={!generatedApiKey}
            />
          )}
          name="subject"
          control={form.control}
        />
        <Button
          label="Send to email"
          height="55px"
          onClick={form.handleSubmit(handleFormSubmit)}
          disabled={!generatedApiKey}
          loading={isSendLoading}
        />
      </Wrap>
    </form>
  );
};

export default CreatePublicLink;
