import { useCallback, useState, ReactNode, MouseEvent } from 'react';
import CloseButton from '../CloseButton/CloseButton';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Content, PopoverContent, PopoverHeader, Title } from './styles';

interface IPopoverWrapperProps {
  content: ReactNode;
  placement?: TooltipPlacement;
  children?: JSX.Element;
  title?: string;
  width?: number;
  isVisible?: boolean;
  setIsVisible?: (visible: boolean) => void;
}

const PopoverWrapper = ({
  content,
  title,
  placement = 'topRight',
  children,
  width,
  isVisible,
  setIsVisible,
}: IPopoverWrapperProps): JSX.Element => {
  const [isMenuPopoverVisible, setIsMenuPopoverVisible] = useState(!!isVisible);
  const handlePopoverVisibleChange = useCallback(
    val => {
      setIsMenuPopoverVisible(val);
      setIsVisible?.(val);
    },
    [setIsMenuPopoverVisible, setIsVisible]
  );

  const handleStopPropagation = useCallback(
    (e: MouseEvent) => e.stopPropagation(),
    []
  );
  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  const handleClosePopover = useCallback(() => {
    setIsMenuPopoverVisible(false);
    setIsVisible?.(false);
  }, [setIsVisible]);

  return (
    <Popover
      getPopupContainer={handleGetPopupContainer}
      content={
        <PopoverContent onClick={handleStopPropagation} $width={width}>
          <PopoverHeader>
            {title && <Title>{title}</Title>}
            <CloseButton onClick={handleClosePopover} />
          </PopoverHeader>
          <Content>{content}</Content>
        </PopoverContent>
      }
      destroyTooltipOnHide
      trigger="click"
      placement={placement}
      onVisibleChange={handlePopoverVisibleChange}
      visible={
        isVisible !== undefined
          ? isVisible && isMenuPopoverVisible
          : isMenuPopoverVisible
      }
    >
      {children}
    </Popover>
  );
};

export default PopoverWrapper;
