import styled, { css } from 'styled-components';

const defaultTextStyles = css`
  font-size: 13px;
  line-height: 16px;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-left: 5px;
`;

export const Wrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px;
  height: 29px;
  min-width: 70px;
  cursor: pointer;
`;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  a {
    ${defaultTextStyles}
  }
`;

export const DoubleArrowWrap = styled.div<{
  isOpen?: boolean;
}>`
  display: inline-flex;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
  svg {
    scale: 0.8;
  }
`;
