import { memo, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  signUpPromiseCreator,
  signUpWithInvitePromiseCreator,
} from 'store/auth';
import { ROUTES } from 'store/router';
import { removeEmptyFieldsFromObject } from 'utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import AdditionalInformation from './components/AdditionalInformation/AdditionalInformation';
import EmailPasswordBlock from './components/EmailPasswordBlock';
import UserDetailsBlock from './components/UserDetailsBlock';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
import queryString from 'query-string';
import { SignUpSchema } from 'validation';
import { BlockContainer, ButtonsWrapper, DividerItem } from './styles';
import { UserInviteDetailsForRegistrationDto } from 'types';

const additionalFields = ['password', 'verifyPassword'];

const baseFields = [
  'firstName',
  'lastName',
  'email',
  'employees',
  'password',
  'verifyPassword',
  // TODO - add this back in when we have a terms and conditions
  // 'acceptTerms',
  'companyName',
  'companyPhoneNumber',
];

interface ISignUpFormProps {
  invitationData: UserInviteDetailsForRegistrationDto;
}

const SignUpForm = ({ invitationData }: ISignUpFormProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const signUp = useActionsRoutines(signUpPromiseCreator);
  const signUpWithInvite = useActionsRoutines(signUpWithInvitePromiseCreator);

  const {
    firstName = '',
    lastName = '',
    email = '',
    token,
  } = queryString.parse(location.search);
  const isUserInvited = !!invitationData;
  const isGoogleSignUp = !token && !!email;

  const requiredFields = isGoogleSignUp
    ? baseFields
    : [...baseFields, ...additionalFields];

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(SignUpSchema),
    context: { isGoogleSignUp, isUserInvited },
    defaultValues: {
      firstName,
      lastName,
      email,
      password: '',
      verifyPassword: '',
      companyName: '',
      // TODO - add this back in when we have a terms and conditions
      // acceptTerms: false,
    },
    shouldUnregister: true,
  });
  const { setValue } = form;

  useEffect(() => {
    if (invitationData) {
      setValue('companyName', invitationData.companyName);
      setValue('email', invitationData.email);
    }
  }, [invitationData, setValue]);

  const allFields = form.watch();
  const isButtonEnabled = Object.entries(allFields)
    .filter(field => requiredFields.includes(String(field[0])))
    .every(field => !!field[1]);

  const submitForm = async data => {
    const filteredData = removeEmptyFieldsFromObject(data);
    try {
      const { userId, jwt } = invitationData
        ? await signUpWithInvite(filteredData)
        : await signUp(filteredData);

      if (jwt) {
        history.push(`${ROUTES.LOGIN}?jwt=${jwt}`);
      } else {
        history.push(
          `${ROUTES.EMAIL_VERIFICATION}?userId=${userId}&email=${data.email}`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitForm)}
        style={{ fontWeight: 400 }}
      >
        <BlockContainer>
          <EmailPasswordBlock
            isUserInvited={isUserInvited}
            isGoogleSignUp={isGoogleSignUp}
            email={email}
          />
        </BlockContainer>
        <DividerItem />
        <BlockContainer>
          <UserDetailsBlock />
        </BlockContainer>
        <DividerItem />
        <BlockContainer>
          <AdditionalInformation isUserInvited={isUserInvited} />
        </BlockContainer>
        <ButtonsWrapper>
          <Button
            onClick={() => history.push(ROUTES.HOME)}
            kind={BUTTON_TYPES.SECONDARY}
            label="Cancel"
            width="155px"
            height="50px"
            fontSize="16px"
          />
          <Button
            loading={form.formState.isSubmitting}
            disabled={!isButtonEnabled}
            htmlType="submit"
            kind={BUTTON_TYPES.DEFAULT}
            label="Sign up"
            width="155px"
            height="50px"
          />
        </ButtonsWrapper>
      </form>
    </FormProvider>
  );
};

export default memo(SignUpForm, isEqual);
