import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { projectViewModeSelector } from 'store/dashboard';
import TaskInfo from '../TaskInfo';
import { TaskMode } from './elements/TaskMode';
import * as Styled from './styles';
import {
  EmptyTaskWithIndex,
  IChecklist,
  IProjectResponseDto,
  IProjectTaskDto,
  OnProjectUpdateSubmit,
  ProjectViewMode,
  SubtaskModeList,
  TaskTypeEnum,
} from 'types';

interface TasksListProps {
  tasksList: IProjectTaskDto[];
  onSubmit: OnProjectUpdateSubmit;
  removeTask: (
    index: number,
    setDeleteLoading: Dispatch<SetStateAction<boolean>>,
    itemId: string
  ) => void;
  setIsOpenEdit?: (open?: any) => void;
  openNotify: ({
    isOpen,
    id,
    item,
  }: {
    isOpen: boolean;
    id: string;
    item: IProjectTaskDto;
  }) => void;
  toggleParent?: (visible: boolean) => void;
  openCheckResult: (id: string, percent: number, isDone: boolean) => void;
  updateChecklist?: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => Promise<void>;
  color?: string;
  invoice?: string;
  editProject: ({
    id,
    data,
  }: {
    id: string;
    data: IProjectResponseDto;
  }) => Promise<void>;
  project: any;
  isDisabledDelete: boolean;
  setShouldUpdate?: (update: boolean) => void;
  locationId?: string;
  openTaskIndex?: number;
  moveTask: (dragIndex: number, hoverIndex: number) => void;
  saveTasksOrder: () => void;
  notAvailableProject?: boolean;
  subtaskTypes?: any[];
  setExpandedTasks: Dispatch<SetStateAction<string[]>>;
  expandedTasks: string[];
  toggleExpandAll: (isOpenAll: boolean) => void;
  containerTasksCount: number;
  tasksGroupBySubtask: SubtaskModeList[];
  emptyCommonTasks: EmptyTaskWithIndex[];
  isProjectEditing?: boolean;
  setEditData: Dispatch<any>;
  toggleItemTemplateModal: () => void;
}

export const TasksList: FC<TasksListProps> = ({
  tasksList,
  onSubmit,
  subtaskTypes,
  setIsOpenEdit,
  openNotify,
  removeTask,
  color,
  invoice,
  isDisabledDelete,
  openCheckResult,
  toggleParent,
  setShouldUpdate,
  locationId,
  moveTask,
  saveTasksOrder,
  openTaskIndex,
  editProject,
  project,
  notAvailableProject,
  updateChecklist,
  setExpandedTasks,
  expandedTasks,
  toggleExpandAll,
  tasksGroupBySubtask,
  emptyCommonTasks,
  isProjectEditing,
  setEditData,
  toggleItemTemplateModal,
}) => {
  const projectViewMode = useSelector(projectViewModeSelector);
  const isProjectViewTaskMode = projectViewMode === ProjectViewMode.Tasks;
  const commonTasksList = tasksList.filter(
    ({ type }) => type === TaskTypeEnum.Common
  );

  if (isProjectViewTaskMode) {
    return (
      <>
        {!!tasksGroupBySubtask.length && (
          <TaskMode
            tasksGroupBySubtask={tasksGroupBySubtask}
            projectId={project._id}
            tasksList={tasksList}
            toggleExpandAll={toggleExpandAll}
            setExpandedTasks={setExpandedTasks}
            expandedTasks={expandedTasks}
            removeTask={removeTask}
            moveTask={moveTask}
            saveTasksOrder={saveTasksOrder}
            isDisabledDelete={isDisabledDelete}
            setIsOpenEdit={setIsOpenEdit}
            openNotify={openNotify}
            onSubmit={onSubmit}
            subtaskTypes={subtaskTypes}
            editProject={editProject}
            project={project}
            toggleParent={toggleParent}
            openTaskIndex={openTaskIndex}
            color={color}
            invoice={invoice}
            setShouldUpdate={setShouldUpdate}
            openCheckResult={openCheckResult}
            locationId={locationId}
            notAvailableProject={notAvailableProject}
            updateChecklist={updateChecklist}
            isProjectEditing={isProjectEditing}
            setEditData={setEditData}
            toggleItemTemplateModal={toggleItemTemplateModal}
          />
        )}

        {!!emptyCommonTasks.length && (
          <Styled.List
            dataSource={emptyCommonTasks}
            rowKey={(item: EmptyTaskWithIndex) => item._id}
            renderItem={({ index, ...item }: EmptyTaskWithIndex) => (
              <TaskInfo
                isProjectEditing={isProjectEditing}
                tasksCount={tasksList.length + emptyCommonTasks.length}
                toggleExpandAll={toggleExpandAll}
                setExpandedTasks={setExpandedTasks}
                expandedTasks={expandedTasks}
                key={item._id}
                removeTask={removeTask}
                moveTask={moveTask}
                saveTasksOrder={saveTasksOrder}
                isDisabledDelete={isDisabledDelete}
                setIsOpenEdit={setIsOpenEdit}
                openNotify={openNotify}
                onSubmit={onSubmit}
                subtaskTypes={subtaskTypes}
                editProject={editProject}
                project={project}
                toggleParent={toggleParent}
                openTaskIndex={openTaskIndex}
                index={index}
                item={item}
                color={color}
                invoice={invoice}
                setShouldUpdate={setShouldUpdate}
                openCheckResult={openCheckResult}
                locationId={locationId}
                notAvailableProject={notAvailableProject}
                isProjectViewTaskMode={false}
                updateChecklist={updateChecklist}
                setEditData={setEditData}
                toggleItemTemplateModal={toggleItemTemplateModal}
              />
            )}
          />
        )}
      </>
    );
  }
  if (projectViewMode === ProjectViewMode.Items) {
    return (
      <Styled.List
        dataSource={tasksList}
        rowKey={(item: IProjectTaskDto) => item._id}
        renderItem={(item: IProjectTaskDto, index) => {
          if (item.type !== TaskTypeEnum.Common) return null;

          const commonTaskIndex =
            commonTasksList.findIndex(({ _id }) => _id === item._id) ?? 0;

          return (
            <TaskInfo
              isProjectEditing={isProjectEditing}
              toggleExpandAll={toggleExpandAll}
              setExpandedTasks={setExpandedTasks}
              expandedTasks={expandedTasks}
              key={item._id}
              removeTask={removeTask}
              moveTask={moveTask}
              saveTasksOrder={saveTasksOrder}
              isDisabledDelete={isDisabledDelete}
              setIsOpenEdit={setIsOpenEdit}
              openNotify={openNotify}
              onSubmit={onSubmit}
              subtaskTypes={subtaskTypes}
              editProject={editProject}
              project={project}
              toggleParent={toggleParent}
              openTaskIndex={openTaskIndex}
              index={index}
              sequenceNumber={commonTaskIndex + 1}
              item={item}
              color={color}
              invoice={invoice}
              setShouldUpdate={setShouldUpdate}
              openCheckResult={openCheckResult}
              locationId={locationId}
              notAvailableProject={notAvailableProject}
              isProjectViewTaskMode={isProjectViewTaskMode}
              updateChecklist={updateChecklist}
              tasksCount={tasksList.length}
              setEditData={setEditData}
              toggleItemTemplateModal={toggleItemTemplateModal}
            />
          );
        }}
      />
    );
  }
};
