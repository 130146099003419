import { CSSProperties, ChangeEvent, useCallback } from 'react';
// components
import { BUTTON_TYPES } from 'components/common';
// icons
import { Tooltip } from 'antd';
// styles
import { ButtonStyled } from './styles';

interface IIconButton {
  title?: string;
  icon?: JSX.Element;
  onClick?: (e: ChangeEvent, arg?: boolean) => void;
  style?: CSSProperties;
  size?: number;
  width?: number;
  height?: number;
  loading?: boolean;
  hidden?: boolean;
  background?: string;
  label?: string;
}

export const IconButton = ({
  title,
  icon,
  onClick,
  style,
  size,
  loading,
  label,
  hidden = false,
  background,
  width,
  height,
}: IIconButton) => {
  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );
  if (hidden) return null;

  return (
    <Tooltip getPopupContainer={handleGetPopupContainer} title={title}>
      <ButtonStyled
        ant-click-animating-without-extra-node="false"
        kind={BUTTON_TYPES.PRIMARY}
        icon={icon}
        size={size}
        width={width}
        height={height}
        loading={loading}
        label={loading ? '' : label}
        onClick={onClick}
        space="0"
        style={style}
        background={background}
      />
    </Tooltip>
  );
};
