import * as yup from 'yup';
import { email, phoneNumber } from './baseSchema';

const zipCodeRegex = RegExp(/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/);

const companySchema = yup.object().shape({
  name: yup.string().required('Name is required!'),
  phone: phoneNumber.required('Phone is required'),
  weekStartDay: yup.number().required('Week start date is required!'),
  workingTime: yup.array().required('Working time is required!'),
  address: yup.object().shape({
    street: yup.string().required('Street is required!'),
    city: yup.string().required('City is required!'),
    state: yup.string().required('State is required!'),
    zip: yup
      .string()
      .matches(zipCodeRegex, 'Invalid zip code')
      .required('Zip is required!'),
  }),
  email,
  roundPrecision: yup
    .number()
    .typeError('Number from 1 to 59 is required')
    .positive('Number from 1 to 59 is required')
    .required('Number from 1 to 59 is required'),
});

export default companySchema;
