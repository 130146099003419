import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const reports = (r: IInitialState) => r.reports;
export const profitPerInvoiceSelector = createSelector(
  reports,
  r => r.profitPerInvoice
);
export const profitPerEmployeesSelector = createSelector(
  reports,
  r => r.profitPerEmployees
);
export const profitPreResourceSelector = createSelector(
  reports,
  r => r.profitPreResource
);
export const reportsDatesSelector = createSelector(
  reports,
  r => r.reportsDates
);
export const searchSelector = createSelector(reports, r => r.search);
export const invoiceSearchSelector = createSelector(
  reports,
  r => r.invoiceSearch
);

export const reportsListSelector = createSelector(reports, r => r.reportsList);
