import { AdministrationsTabsEnum } from 'types';

export const ROUTES = Object.freeze({
  DASHBOARD: '/dashboard',
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_NEW_COMPANY: '/register-new-company',
  FORGOT_PASSWORD: '/forgot-password',
  RESTORE: '/restore',
  RESET_PASSWORD: '/restore-password',
  EMAIL_VERIFICATION: '/email-verification',
  VALIDATE_INVITE: '/validate-invite',
  // settings
  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_PROFILE_INFO: '/settings/profile/info',
  SETTINGS_PROFILE_PASSWORD: '/settings/profile/password',

  SETTINGS_COMPANY_INFO: '/settings/company',

  // settings admin
  SETTINGS_ADMIN_ADMINISTRATORS: '/settings/admin/admins',
  SETTINGS_ADMIN_ADMINISTRATORS_INVITE: `/settings/admin/admins?tab=${AdministrationsTabsEnum.INVITED_USERS}`,
  SETTINGS_ADMIN_RESOURCES: '/settings/admin/resources',
  SETTINGS_ADMIN_GROUPS: '/settings/admin/groups',
  SETTINGS_ADMIN_EMAIL_TEMPLATES: '/settings/admin/email-templates',

  // settings project
  SETTINGS_PROJECT_ITEMS: '/settings/project/items',
  SETTINGS_PROJECT_RECURRING_TASKS: '/settings/project/recurring-tasks',
  SETTINGS_PROJECT_TASK_TYPES: '/settings/project/task-types',
  SETTINGS_PROJECT: '/settings/project/project-settings',
  // settings depot
  SETTINGS_DEPOT_CONTAINERS: '/settings/depot/containers',
  SETTINGS_DEPOT_SIZES: '/settings/depot/sizes',
  SETTINGS_DEPOT_COLORS: '/settings/depot/colors',
  SETTINGS_DEPOT_GRADES: '/settings/depot/grades',
  SETTINGS_DEPOT_CONDITIONS: '/settings/depot/conditions',
  SETTINGS_DEPOT_TRUCKING_COMPANIES: '/settings/depot/companies',

  // settings modules
  SETTINGS_MODULES_FEATURES: '/settings/modules/features',
  SETTINGS_MODULES_INTEGRATIONS: '/settings/modules/integrations',

  INVENTORY: '/inventory',
  // reports
  SETTINGS_REPORTS: '/settings/reports',
  // time sheets
  SETTINGS_TIME_SHEETS: '/settings/timesheets',
});
