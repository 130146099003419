import { call, takeLatest } from 'redux-saga/effects';
// redux
import { addSize, deleteSize, editSize, getSizeById, sizes } from './actions';
import * as E from './workers';

export function* watchGetSizes() {
  yield call(E.ensureGetSizes, {
    type: sizes.TRIGGER,
  });
  yield takeLatest(sizes.TRIGGER, E.ensureGetSizes);
}

export function* watchAddSize() {
  yield takeLatest(addSize.TRIGGER, E.ensureAddSize);
}

export function* watchGetSizeById() {
  yield takeLatest(getSizeById.TRIGGER, E.ensureGetSizeById);
}

export function* watchEditeSize() {
  yield takeLatest(editSize.TRIGGER, E.ensureEditSize);
}

export function* watchDeleteSize() {
  yield takeLatest(deleteSize.TRIGGER, E.ensureDeleteSize);
}
