import styled from 'styled-components';
import theme from 'styles/theme';
import { Input, Select } from 'antd';

export const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GroupStyled = styled(Input.Group)`
  &&.ant-input-group {
    display: flex;
    border-radius: 6px;
    overflow: hidden;
  }
`;

export const SelectStyled = styled(Select)`
  &&&& .ant-select-selector {
    width: 120px;
    height: 30px;
    border: none;
    box-shadow: none;
    font-weight: 500;
    background: ${theme.colors.grayLight};
    font-size: 13px;
    color: #172b4d;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 0;
      width: 1px;
      background: #b9bfc9;
    }
  }
`;

export const OptionItem = styled.span`
  font-weight: 500;
  font-size: 13px;
`;
