import { BUTTON_TYPES, Button, ConfirmationPopover } from 'components/common';
// icons
import { Remove } from 'lib/icons';

interface IDeleteButtonProps {
  remove: () => void;
  text: string;
}

const DeleteButton = ({ remove, text }: IDeleteButtonProps) => (
  <ConfirmationPopover text={text} onOk={remove}>
    <Button
      kind={BUTTON_TYPES.DANGER_ICON}
      icon={<Remove />}
      space="0"
      style={{ minWidth: 28, minHeight: 28, padding: 0 }}
    />
  </ConfirmationPopover>
);

export default DeleteButton;
