import { ChangeEvent } from 'react';
import BottomPanel from './BottomPanel/BottomPanel';
import TopPanel from './TopPanel/TopPanel';
import {
  SignOfAttention,
  SubtaskInner,
  SubtaskRow,
  SubtaskWrap,
} from './styles';
import {
  GeneratedApiKeyDto,
  IChecklist,
  IProjectResponseDto,
  ISubtaskItemData,
  ProjectStatusesEnum,
  ResourceDto,
} from 'types';

interface ISubtaskItemProps {
  opacity?: number;
  onDoubleClick?: (e: ChangeEvent) => void;
  updateChecklist: (
    subtaskId: number,
    newChecklist: IChecklist,
    isContainer: boolean
  ) => void;
  needAttention?: boolean;
  setShouldUpdate?: (update: boolean) => void;
  data?: ISubtaskItemData;
  notAvailableProject?: boolean;
  openCheckResult?: (id: string, percent: number, isDone: boolean) => void;
  locationId?: string;
  generatedApiKey?: GeneratedApiKeyDto;
  projectId?: string;
  project?: IProjectResponseDto;
  hasResource?: boolean;
  resource?: ResourceDto;
  isHighlighted?: boolean;
  assignedDate?: string;
  taskId?: string;
  previewOrder?: Element;
  drag?: any;
  refOrder?: Element;
  isProjectViewTaskMode?: boolean;
  isProjectEditing?: boolean;
}

const SubtaskItem = ({
  isHighlighted,
  opacity,
  previewOrder,
  onDoubleClick,
  needAttention,
  drag,
  refOrder,
  setShouldUpdate,
  data,
  notAvailableProject,
  openCheckResult,
  locationId,
  generatedApiKey,
  projectId,
  project,
  hasResource,
  resource,
  assignedDate,
  taskId,
  updateChecklist,
  isProjectViewTaskMode,
  isProjectEditing,
}: ISubtaskItemProps) => {
  const withDnD = notAvailableProject;

  const isProjectDeleted = project.status === ProjectStatusesEnum.DELETED;

  const countSubtasksInTask = project.tasks.find(task => task._id === taskId)
    ?.subtasks.length;

  return (
    <>
      <SubtaskWrap
        style={{ opacity }}
        ref={previewOrder}
        onDoubleClick={resource && onDoubleClick}
        isHighlighted={isHighlighted}
      >
        <SubtaskRow>
          {needAttention && <SignOfAttention />}
          <SubtaskInner ref={drag}>
            <TopPanel
              isProjectViewTaskMode={isProjectViewTaskMode}
              withDnD={withDnD}
              assignedDate={assignedDate}
              hasResource={hasResource}
              refOrder={refOrder}
              setShouldUpdate={setShouldUpdate}
              data={data}
              locationId={locationId}
              project={project}
              projectId={projectId}
              notAvailableProject={notAvailableProject}
              generatedApiKey={generatedApiKey}
              openCheckResult={openCheckResult}
            />
            <BottomPanel
              countSubtasksInTask={countSubtasksInTask}
              withDnD={withDnD}
              data={data}
              generatedApiKey={generatedApiKey}
              isProjectArchived={project.isArchived}
              isProjectDeleted={isProjectDeleted}
              projectId={projectId}
              taskId={taskId}
              updateChecklist={updateChecklist}
              isProjectEditing={isProjectEditing}
            />
          </SubtaskInner>
        </SubtaskRow>
      </SubtaskWrap>
    </>
  );
};

SubtaskItem.defaultProps = {
  needAttention: false,
  isMobile: false,
  notAvailableProject: false,
  hasResource: false,
  inAdmin: false,
  isRecurring: false,
  isHighlighted: false,
};

export default SubtaskItem;
