import storageManager from './storageManager';
import ColorHash from 'color-hash';
import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import moment from 'moment-timezone';
import { message } from 'utils/message';
import theme from '../styles/theme';
import {
  ContainerOptionsDto,
  IChecklist,
  IContainer,
  IFile,
  ILocation,
  IProjectTaskDto,
  ISizeDto,
  ISubtaskTypeDto,
  ITaskDto,
  RoleEnum,
  TaskTypeEnum,
  UpdatedRolesEnum,
} from 'types';

export const API_URL = process.env.REACT_APP_API_PATH;

export const extractValueFromRegExpString = (regExpString: string) =>
  /^\/(.*)\/([igm]*)$/.exec(regExpString)?.[1] ?? '';

export const replaceForRequest = (val: string) =>
  `/${val.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')}/gi`;

export const replaceNoGlobalRequest = (val: string) =>
  val.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

export const getMenuPath = (path, key: number) =>
  path?.match(new RegExp(`(/[a-z0-9\\-_]+){${key}}`, 'i'))?.[0];

export const hasLocation = (locations: ILocation[], id: string) =>
  !!locations?.find(item => item._id === id);

export const getColor = (hexcolor: string) => {
  let color = hexcolor;
  if (hexcolor?.slice(0, 1) === '#') {
    color = hexcolor?.slice(1);
  }

  const r = parseInt(color?.substr(0, 2), 16);
  const g = parseInt(color?.substr(2, 2), 16);
  const b = parseInt(color?.substr(4, 2), 16);

  return { r, g, b };
};

export const getOpacityBackground = (hexcolor: string, opacity = 0.25) => {
  const { r, g, b } = getColor(hexcolor);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getStartEndWeek = (
  weekStartDay: any,
  currentDay: moment.Moment = moment(),
  isNewWeekStartDayExist?: boolean
) => {
  const currentWeekDay = currentDay.day();

  if (!isNewWeekStartDayExist && weekStartDay > currentWeekDay) {
    const startDate = currentDay
      .startOf('week')
      .subtract(7 - weekStartDay, 'days');
    const endDate = moment(startDate).add(6, 'days').endOf('day');
    return {
      startDate,
      endDate,
    };
  }
  const startDate = currentDay.startOf('week').add(weekStartDay, 'days');
  const endDate = moment(startDate).add(6, 'days').endOf('day');
  return { startDate, endDate };
};

export const getFileSize = (size: string) => {
  const kb = +size / 1000;
  const Mb = kb / 1000;
  return Mb > 1 ? `${Mb.toFixed(2)} Mb` : `${kb.toFixed(2)} kb`;
};

const getFirstLetter = (str: string) => str?.[0]?.toUpperCase() || '';

const getAvatarColor = (str: string) => {
  const colorHash = new ColorHash();
  return colorHash.hex(str);
};

export const getAvatarProps = (str: string) => {
  const fullName = getFirstLetter(str);
  if (!fullName) {
    return { fullName: '', color: 'inherit', background: 'inherit' };
  }
  const color = theme.colors.white;
  const background = getAvatarColor(fullName);
  return { fullName, color, background };
};

export const sortSubtaskTypes = (subtaskTypes: ISubtaskTypeDto[]) =>
  subtaskTypes.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  });

export const formatSecsToTime = (secs: number) => {
  let minutes = Math.round(secs / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  let result = '';
  if (!hours && !minutes) {
    return '0h';
  }
  if (hours > 0) result += `${hours}h`;
  if (minutes > 0) result += ` ${minutes}m`;
  return result;
};

export const hasEmptyProperty = (obj: ContainerOptionsDto) =>
  Object.keys(obj).some(key => !obj[key].length);

export const getWholeWeek = (date: moment.Moment) => ({
  startDate: moment(date).startOf('day'),
  endDate: moment(date).add(6, 'days').endOf('day'),
});

export const getWholeDay = (date: moment.Moment) => ({
  startDate: moment(date).startOf('day'),
  endDate: moment(date).endOf('day'),
});

export const isSameDay = (
  firstDate: moment.Moment,
  secondDate: moment.Moment
) => moment(firstDate).isSame(secondDate, 'day');

export const isMobileDevice = {
  Android() {
    return /Android/i.exec(navigator.userAgent);
  },
  BlackBerry() {
    return /BlackBerry/i.exec(navigator.userAgent);
  },
  iOS() {
    return /iPhone|iPad|iPod/i.exec(navigator.userAgent);
  },
  Opera() {
    return /Opera Mini/i.exec(navigator.userAgent);
  },
  Windows() {
    return /IEMobile/i.exec(navigator.userAgent);
  },
  any() {
    return (
      isMobileDevice.Android() ||
      isMobileDevice.BlackBerry() ||
      isMobileDevice.iOS() ||
      isMobileDevice.Opera() ||
      isMobileDevice.Windows()
    );
  },
};

export const prepareTask = (task: IContainer | ITaskDto) => ({
  ...task,
  subtasks:
    task?.subtasks?.map(subtask => ({
      ...subtask,
      checklist: prepareChecklist(subtask.checklist || []),
    })) ?? [],
});

export const getTimeFromPercent = (percent: number) =>
  `${Math.trunc(percent || 0) !== 0 ? `${Math.trunc(percent || 0)}h` : ''} ${(
    ((percent || 0) * 60) %
    60
  ).toFixed()}min`;

export const prepareChecklist = (checklist: IChecklist[]) =>
  checklist.map(item => ({
    ...item,
    isCompleted: item?.isCompleted || false,
  }));

export const prepareClearChecklist = (checklist: IChecklist[]) =>
  checklist.map(item => ({ ...item, isCompleted: false }));

// execute callback if fields has been changed
export const requestForUpdatedFields = async (
  data,
  defaultValues,
  callback: (object) => Promise<void>
) => {
  const updatedFields = Object.keys(data).reduce((acc, key) => {
    if (data[key] !== defaultValues[key]) {
      acc[key] = data[key];
    }
    return acc;
  }, {});
  if (Object.keys(updatedFields).length !== 0) {
    const data = await callback(updatedFields);
    return data;
  }
};

export const getUpdatedRole = (roles: RoleEnum[]) =>
  roles.map(role => {
    if (role === RoleEnum.Administrator) {
      return UpdatedRolesEnum.ADMIN;
    }
    if (role === RoleEnum.SuperAdministrator) {
      return UpdatedRolesEnum.SUPER_ADMIN;
    }
    return role;
  });

export const separatedTasksByType = (tasksList: IProjectTaskDto[]) => {
  const common = [];
  const container = [];

  tasksList.forEach(item => {
    if (item.type === TaskTypeEnum.Common) {
      common.push(item);
    }
    if (item.type === TaskTypeEnum.Container) {
      container.push(item);
    }
  });

  return {
    containerTasks: container,
    commonTasks: common,
  };
};

export const removeEmptyFieldsFromObject = obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value && { [key]: value }),
    }),
    {}
  );

export const getToken = () => {
  const token = storageManager.getToken();
  return token.replace('Bearer', '').trim();
};

export const downloadBlob = (blob: Blob) => {
  const url = URL.createObjectURL(blob);

  window.location.replace(url);
};

export const compareObjShowDiff = (oldData, newData) =>
  fromPairs(differenceWith(toPairs(oldData), toPairs(newData), isEqual));

interface IUseValidateUploadFile {
  files: IFile[];
  allowedTypes: string[];
  maxFileSize: number;
}

export const validateUploadFile = ({
  files,
  allowedTypes,
  maxFileSize,
}: IUseValidateUploadFile) => {
  const { allowedFiles, errors } = files.reduce(
    (acc, file) => {
      const isFileSizeSupported = file.size <= maxFileSize * 1024 * 1024;
      const isFileTypeSupported =
        allowedTypes.includes(file.type) ||
        allowedTypes.some(type => file.name.toLowerCase().endsWith(type));

      if (isFileSizeSupported && isFileTypeSupported) {
        acc.allowedFiles.push(file);
      } else {
        if (!isFileSizeSupported) {
          acc.errors.push(
            `${file.name} size must be less than ${maxFileSize} MB`
          );
        }
        if (!isFileTypeSupported) {
          acc.errors.push(`${file.name} has unsupported format`);
        }
      }
      return acc;
    },
    {
      allowedFiles: [],
      errors: [],
    }
  );
  if (!!errors.length) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(errors);
  }

  return { allowedFiles };
};

export const getOnlyDigits = (value: string) => {
  const onlyDigits = value.replace(/\D/g, '');
  return onlyDigits;
};

export const hoursParser = value => {
  const res = /[0-9]+\.?[0-9]{0,2}/.exec(value);
  return res && res[0];
};

export const getSizesOptions = (array: ISizeDto[] | undefined = []) =>
  array.map(size => ({ label: size.type, value: size.type }));

export const getSkuOptions = (skus: string[] | undefined = []) =>
  skus.map(s => ({ label: s, value: s }));
