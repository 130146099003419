export const ArrowLeftDouble = ({ onClick, color = '#172B4D' }) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5189 1.87094L10.3416 0.700195L3.8 7.2484L10.3482 13.7966L11.5189 12.6259L6.14148 7.2484L11.5189 1.87094Z"
      fill={color}
    />
    <path d="M0 1H2V13H0V1Z" fill={color} />
  </svg>
);
