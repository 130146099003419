export const ArrowRightDouble = ({ onClick, color = '#172B4D' }) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.48105 1.87094L4.65841 0.700195L11.2 7.2484L4.65179 13.7966L3.48105 12.6259L8.85852 7.2484L3.48105 1.87094Z"
      fill={color}
    />
    <path d="M15 1H13V13H15V1Z" fill={color} />
  </svg>
);
