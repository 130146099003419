import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Item = styled.div<{
  textColor?: string;
  background?: string;
  noAssignee?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ textColor }) => textColor};
  background-color: ${({ background }) => background};
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 10px;
  border-radius: 5px;
  padding-left: ${({ noAssignee }) => noAssignee && '6px'};
  svg {
    color: ${({ textColor }) => textColor};
    margin-right: 3px;
    margin-bottom: -1.5px;
  }
`;
