import * as yup from 'yup';

const subtaskResultSchema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .min(5, 'Reason must be longer than or equal to 5 characters')
    .required('Required!'),
  percent: yup.number().required('Required!'),
});

export default subtaskResultSchema;
