import moment from 'moment-timezone';

export const getUpdatedResourceTimeData = (prevData, updatedItem) =>
  prevData.map(item => {
    if (item._id === updatedItem._id) return updatedItem;
    return item;
  });

export const getDeleteResourceTimeData = (prevData, deletedItemId) =>
  prevData.filter(item => item._id !== deletedItemId);

export const prepareEndDate = (endDate: string) =>
  moment(endDate).endOf('day').toDate();
