export const CoverIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.40049 1.4H6.30049V0H1.40049C0.630488 0 0.000488281 0.63 0.000488281 1.4V6.3H1.40049V1.4ZM5.60049 7.7L2.80049 11.2H11.2005L9.10049 8.4L7.67949 10.297L5.60049 7.7ZM10.5005 4.55C10.5005 3.969 10.0315 3.5 9.45049 3.5C8.86949 3.5 8.40049 3.969 8.40049 4.55C8.40049 5.131 8.86949 5.6 9.45049 5.6C10.0315 5.6 10.5005 5.131 10.5005 4.55ZM12.6005 0H7.70049V1.4H12.6005V6.3H14.0005V1.4C14.0005 0.63 13.3705 0 12.6005 0ZM12.6005 12.6H7.70049V14H12.6005C13.3705 14 14.0005 13.37 14.0005 12.6V7.7H12.6005V12.6ZM1.40049 7.7H0.000488281V12.6C0.000488281 13.37 0.630488 14 1.40049 14H6.30049V12.6H1.40049V7.7Z"
      fill="#737F94"
    />
  </svg>
);
