import { call, put, select } from 'redux-saga/effects';
import { actions } from 'store/loading';
import * as Actions from 'store/reports';
import { prepareEndDate, prepareOneDayReport } from './helpers';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureProfitPerResourcePayload,
  IReportItem,
  ITimesheetsParams,
} from 'types';

export function* ensureProfitPerInvoice() {
  try {
    const invoice = yield select(Actions.invoiceSearchSelector);
    const locationId = storageManager.getLocation();
    const params = {
      locationId,
      invoice,
    };
    let data: unknown;
    if (invoice.length > 2) {
      data = yield call(Api.reports.getProfitPreInvoice, { params });
    } else {
      data = null;
    }
    yield put(Actions.profitPerInvoice.success(data));
  } catch (err) {
    yield put(Actions.profitPerInvoice.failure(err));
  }
}

export function* ensureProfitPerEmployees() {
  try {
    const locationId = storageManager.getLocation();
    const search = yield select(Actions.searchSelector);
    const dates = yield select(Actions.reportsDatesSelector);
    const { startDate, endDate } = dates;

    const params = {
      locationId,
      search: search || null,
      startDate: startDate.toDate(),
      endDate: prepareEndDate(endDate),
    };
    yield put(actions.tabLoading(true));
    const { list } = yield call(Api.reports.getProfitPreEmployees, { params });
    yield put(Actions.profitPerEmployees.success(list));
  } catch (err) {
    yield put(Actions.profitPerEmployees.failure(err));
  } finally {
    yield put(actions.tabLoading(false));
  }
}

export function* ensureProfitPerResource({
  payload,
}: {
  payload: IEnsureProfitPerResourcePayload;
  type: typeof Actions.profitPerResource.TRIGGER;
}) {
  try {
    const locationId = storageManager.getLocation();
    const { dates, id } = payload;
    const { startDate, endDate } = dates;
    const preparedEndDate = endDate
      ? prepareEndDate(endDate)
      : prepareOneDayReport(startDate);

    const params = {
      locationId,
      startDate: moment(startDate).toDate(),
      endDate: preparedEndDate,
    } as AxiosRequestConfig<ITimesheetsParams>;
    yield put(actions.pageLoading(true));
    const data = yield call(Api.reports.getResourceReport, {
      id,
      params,
    });
    yield put(Actions.profitPerResource.success(data));
  } catch (err) {
    yield put(Actions.profitPerResource.failure(err));
  } finally {
    yield put(actions.pageLoading(false));
  }
}

export function* ensureGetReportsList() {
  try {
    yield put(actions.tabLoading(true));
    const response = yield call(Api.company.getReportsList);
    yield put(Actions.getReportsList.success(response));
  } catch (err) {
    yield put(Actions.profitPerEmployees.failure(err));
  } finally {
    yield put(actions.tabLoading(false));
  }
}

export function* ensureDeleteReport({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteReport.TRIGGER;
}) {
  try {
    yield call(Api.company.deleteReport, { id: payload });
    yield put(Actions.deleteReport.success(payload));
  } catch (err) {
    yield put(Actions.deleteReport.failure(err));
  }
}

export function* ensureCreateReport({
  payload,
}: {
  payload: IReportItem;
  type: typeof Actions.createReport.TRIGGER;
}) {
  try {
    const response = yield call(Api.company.createReport, payload);
    yield put(Actions.createReport.success(response));
  } catch (err) {
    yield put(Actions.createReport.failure(err));
  }
}

export function* ensureUpdateReport({
  payload,
}: {
  payload: Partial<IReportItem>;
  type: typeof Actions.updateReport.TRIGGER;
}) {
  try {
    const { _id, ...data } = payload;
    yield call(Api.company.updateReport, { data, _id });
    yield put(Actions.updateReport.success({ data, _id }));
  } catch (err) {
    yield put(Actions.updateReport.failure(err));
  }
}
