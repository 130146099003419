import { lazy } from 'react';
// components
import { tabsWrapper } from './wrappers';
// libs
import {
  adminItems,
  adminItemsProjectManager,
  depotItems,
  managerCompanyItems,
  modulesItems,
  profileItems,
  projectSettingsItems,
  projectSettingsItemsProjectManager,
  schedulerItemsProjectManager,
} from 'lib/constants/menuItems';
import { AdministrationsTabsEnum } from 'types';

// list of routes related to user role
const SubtaskTypes = lazy(async () => import('pages/Project/SubtaskTypes'));
const RecurringTasks = lazy(async () => import('pages/Project/RecurringTasks'));
const Tasks = lazy(async () => import('pages/Project/Tasks'));
const ProjectSettings = lazy(
  async () => import('pages/Project/ProjectSettings/ProjectSettings')
);
const Resources = lazy(async () => import('pages/Admin/Resources'));
const Groups = lazy(async () => import('pages/Admin/ResourcesGroup'));
const EmailTemplates = lazy(async () => import('pages/Admin/EmailTemplates'));
const CompanyInfo = lazy(async () => import('pages/Company/CompanyInfo'));
const Administrators = lazy(async () => import('pages/Company/Administrators'));
const Profile = lazy(async () => import('pages/Profile/MainInfo/MainInfo'));
const ChangePassword = lazy(
  async () => import('pages/Profile/ChangePassword/ChangePassword')
);
const Colors = lazy(async () => import('pages/Depot/Colors'));
const Sizes = lazy(async () => import('pages/Depot/Sizes'));
const Conditions = lazy(async () => import('pages/Depot/Conditions'));
const Grades = lazy(async () => import('pages/Depot/Grades'));
const TruckingCompanies = lazy(
  async () => import('pages/Depot/TruckingCompanies')
);
const Timesheets = lazy(async () => import('pages/Timesheets'));
const ReportsStartingPage = lazy(
  async () => import('pages/Reports/StartingPage')
);
const ReportsIframe = lazy(async () => import('pages/Reports/ReportsIframe'));
const Features = lazy(async () => import('pages/Modules/Features/Features'));

const profileInnerRoutes = [
  {
    exact: false,
    path: '/settings/profile/info',
    component: Profile,
  },
  {
    exact: false,
    path: '/settings/profile/password',
    component: ChangePassword,
  },
];

const projectManagerAdminInnerRoutes = [
  {
    exact: false,
    path: '/settings/admin/admins',
    component: Administrators,
  },
  {
    exact: false,
    path: '/settings/admin/resources',
    component: Resources,
  },
  {
    exact: false,
    path: '/settings/admin/groups',
    component: Groups,
  },
  {
    exact: false,
    path: '/settings/admin/email-templates',
    component: EmailTemplates,
  },
];

const schedulerInnerRoutes = [
  {
    exact: false,
    path: '/settings/admin/resources',
    component: Resources,
  },
  {
    exact: false,
    path: '/settings/admin/groups',
    component: Groups,
  },
  {
    exact: false,
    path: '/settings/admin/email-templates',
    component: EmailTemplates,
  },
];

const adminInnerRoutes = [
  ...projectManagerAdminInnerRoutes,
  {
    exact: false,
    path: '/settings/admin/admins',
    component: Administrators,
  },
];

const projectSettingsRoutes = [
  {
    exact: false,
    path: '/settings/project/task-types',
    component: SubtaskTypes,
  },
  {
    exact: false,
    path: '/settings/project/recurring-tasks',
    component: RecurringTasks,
  },
  {
    exact: false,
    path: '/settings/project/items',
    component: Tasks,
  },
  {
    exact: false,
    path: '/settings/project/containers',
    component: Tasks,
  },
  {
    exact: false,
    path: '/settings/project/project-settings',
    component: ProjectSettings,
  },
];

const projectSettingsRoutesProjectManager = [
  {
    exact: false,
    path: '/settings/project/task-types',
    component: SubtaskTypes,
  },
  {
    exact: false,
    path: '/settings/project/recurring-tasks',
    component: RecurringTasks,
  },
  {
    exact: false,
    path: '/settings/project/containers',
    component: Tasks,
  },
  {
    exact: false,
    path: '/settings/project/project-settings',
    component: ProjectSettings,
  },
];

const depotInnerRoutes = [
  {
    exact: false,
    path: '/settings/depot/containers',
    component: Tasks,
  },
  {
    exact: false,
    path: '/settings/depot/colors',
    component: Colors,
  },
  {
    exact: false,
    path: '/settings/depot/sizes',
    component: Sizes,
  },
  {
    exact: false,
    path: '/settings/depot/conditions',
    component: Conditions,
  },
  {
    exact: false,
    path: '/settings/depot/grades',
    component: Grades,
  },
  {
    exact: false,
    path: '/settings/depot/companies',
    component: TruckingCompanies,
  },
];

const companyInnerRoutesManager = [
  {
    exact: false,
    path: '/settings/company',
    component: CompanyInfo,
  },
];

const settingsModulesRoutes = [
  {
    exact: false,
    path: '/settings/modules/features',
    component: Features,
  },
  // {
  //   exact: false,
  //   path: '/settings/modules/integrations',
  //   component: Integrations,
  // },
];

const reportsInnerRoutes = [
  {
    exact: true,
    path: '/settings/reports',
    component: ReportsStartingPage,
  },
  {
    exact: false,
    path: '/settings/reports/:id',
    component: ReportsIframe,
  },
];

export const settingsRoutesAdmin = [
  {
    exact: false,
    path: '/settings/profile',
    redirect: '/settings/profile/info',
    wrapper: children => tabsWrapper(children, profileItems),
    routes: profileInnerRoutes,
  },

  {
    exact: false,
    path: '/settings/project',
    redirect: '/settings/project/task-types',
    wrapper: children => tabsWrapper(children, projectSettingsItems),
    routes: projectSettingsRoutes,
  },

  {
    exact: false,
    path: '/settings/modules',
    redirect: '/settings/modules/features',
    wrapper: children => tabsWrapper(children, modulesItems),
    routes: settingsModulesRoutes,
  },
  {
    exact: false,
    path: '/settings/admin',
    redirect: `/settings/admin/admins?tab=${AdministrationsTabsEnum.ACTIVE_USERS}`,
    wrapper: children => tabsWrapper(children, adminItems),
    routes: adminInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/depot',
    redirect: '/settings/depot/containers',
    wrapper: children => tabsWrapper(children, depotItems),
    routes: depotInnerRoutes,
  },
  { exact: true, path: '/settings/timesheets', component: Timesheets },
  {
    exact: false,
    path: '/settings/reports',
    redirect: '/settings/reports',
    routes: reportsInnerRoutes,
  },
  { exact: true, path: '/settings/company', component: CompanyInfo },
];

export const settingsRoutesManager = [
  {
    exact: false,
    path: '/settings/profile',
    redirect: '/settings/profile/info',
    wrapper: children => tabsWrapper(children, profileItems),
    routes: profileInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/project',
    redirect: '/settings/project/task-types',
    wrapper: children =>
      tabsWrapper(children, projectSettingsItemsProjectManager),
    routes: projectSettingsRoutesProjectManager,
  },
  {
    exact: false,
    path: '/settings/depot',
    redirect: '/settings/depot/containers',
    wrapper: children => tabsWrapper(children, depotItems),
    routes: depotInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/modules',
    redirect: '/settings/modules/features',
    wrapper: children => tabsWrapper(children, modulesItems),
    routes: settingsModulesRoutes,
  },
  {
    exact: false,
    path: '/settings/admin',
    redirect: `/settings/admin/admins`,
    wrapper: children => tabsWrapper(children, adminItemsProjectManager),
    routes: projectManagerAdminInnerRoutes,
  },
  { exact: true, path: '/settings/timesheets', component: Timesheets },
  {
    exact: false,
    path: '/settings/reports',
    redirect: '/settings/reports',
    routes: reportsInnerRoutes,
  },

  { exact: true, path: '/settings/company', component: CompanyInfo },
];

export const settingsRoutesDepotManager = [
  {
    exact: false,
    path: '/settings/profile',
    redirect: '/settings/profile/info',
    wrapper: children => tabsWrapper(children, profileItems),
    routes: profileInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/depot',
    redirect: '/settings/depot/containers',
    wrapper: children => tabsWrapper(children, depotItems),
    routes: depotInnerRoutes,
  },
  { exact: true, path: '/settings/timesheets', component: Timesheets },
];

export const settingsRoutesSalesManager = [
  { exact: true, path: '/settings/profile', component: Profile },
];

export const settingsRoutesManagerAndDepotManager = [
  {
    exact: false,
    path: '/settings/profile',
    redirect: '/settings/profile/info',
    wrapper: children => tabsWrapper(children, profileItems),
    routes: profileInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/modules',
    redirect: '/settings/modules/features',
    wrapper: children => tabsWrapper(children, modulesItems),
    routes: settingsModulesRoutes,
  },
  {
    exact: false,
    path: '/settings/company',
    redirect: '/settings/company',
    wrapper: children => tabsWrapper(children, managerCompanyItems),
    routes: companyInnerRoutesManager,
  },
  {
    exact: false,
    path: '/settings/admin',
    redirect: `/settings/admin/resources`,
    wrapper: children => tabsWrapper(children, adminItemsProjectManager),
    routes: projectManagerAdminInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/project',
    redirect: '/settings/project/task-types',
    wrapper: children => tabsWrapper(children, projectSettingsItems),
    routes: projectSettingsRoutes,
  },
  {
    exact: false,
    path: '/settings/depot',
    redirect: '/settings/depot/containers',
    wrapper: children => tabsWrapper(children, depotItems),
    routes: depotInnerRoutes,
  },
  { exact: true, path: '/settings/timesheets', component: Timesheets },
  {
    exact: false,
    path: '/settings/reports',
    redirect: '/settings/reports',
    routes: reportsInnerRoutes,
  },
];

export const settingsRoutesScheduler = [
  {
    exact: false,
    path: '/settings/profile',
    redirect: '/settings/profile/info',
    wrapper: children => tabsWrapper(children, profileItems),
    routes: profileInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/admin',
    redirect: `/settings/admin/resources`,
    wrapper: children => tabsWrapper(children, schedulerItemsProjectManager),
    routes: schedulerInnerRoutes,
  },
  {
    exact: false,
    path: '/settings/project',
    redirect: '/settings/project/task-types',
    wrapper: children => tabsWrapper(children, projectSettingsItems),
    routes: projectSettingsRoutes,
  },
  {
    exact: false,
    path: '/settings/depot',
    redirect: '/settings/depot/containers',
    wrapper: children => tabsWrapper(children, depotItems),
    routes: depotInnerRoutes,
  },
  { exact: true, path: '/settings/company', component: CompanyInfo },
];
