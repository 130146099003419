import { CSSProperties, memo } from 'react';
import { isEqual } from 'lodash';

interface IHasCommentsIconProps {
  style: CSSProperties;
  color: string;
}

const HasCommentsIcon = ({
  style,
  color,
}: IHasCommentsIconProps): JSX.Element => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4173 1.33644V8.95844L9.7402 8.28132H1.15748V1.33644H10.4173ZM10.4173 0.178955H1.15748C0.520866 0.178955 0 0.699821 0 1.33644V8.28132C0 8.91793 0.520866 9.4388 1.15748 9.4388H9.25984L11.5748 11.7538V1.33644C11.5748 0.699821 11.0539 0.178955 10.4173 0.178955ZM9.25984 5.96636H2.31496V7.12384H9.25984V5.96636ZM9.25984 4.23014H2.31496V5.38762H9.25984V4.23014ZM9.25984 2.49392H2.31496V3.6514H9.25984V2.49392Z"
      fill={color || 'white'}
    />
  </svg>
);

export const HasComments = memo(HasCommentsIcon, isEqual);
