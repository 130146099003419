import styled from 'styled-components';

export const Wrapper = styled.div<{ isSplited?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 5px;
  margin-left: 7px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  height: 30px;
  font-size: 12px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  ${({ isSplited }) =>
    isSplited &&
    `transform: rotate(90deg);
`}
`;
