import { FC, useCallback, useEffect, useState } from 'react';
import PopoverContent from './PopoverContent';
import { HorizontalMenuIcon } from 'lib/icons/HorizontalMenuIcon';
import theme from 'styles/theme';
import { Popover } from 'antd';
import { MenuWrap, Wrap } from './styles';

interface IMenuPopoverIconProps {
  deleteMessage: () => void;
  editMessage?: () => void;
  replyMessage: () => void;
  isDeleting: boolean;
  isScrolling: boolean;
}

const MenuPopover: FC<IMenuPopoverIconProps> = ({
  editMessage,
  deleteMessage,
  replyMessage,
  isDeleting,
  isScrolling,
}) => {
  const [isMenuPopoverVisible, setIsMenuPopoverVisible] = useState(false);
  const handleClosePopover = useCallback(
    () => setIsMenuPopoverVisible(false),
    []
  );

  useEffect(() => {
    if (isScrolling && isMenuPopoverVisible) handleClosePopover();
  }, [isScrolling, isMenuPopoverVisible, handleClosePopover]);

  return (
    <Wrap>
      <Popover
        content={
          <PopoverContent
            editMessage={editMessage}
            deleteMessage={deleteMessage}
            isDeleting={isDeleting}
            handleClosePopover={handleClosePopover}
            replyMessage={replyMessage}
          />
        }
        destroyTooltipOnHide
        overlayClassName="ChatMenuPopover"
        trigger="click"
        onVisibleChange={setIsMenuPopoverVisible}
        visible={isMenuPopoverVisible}
      >
        <MenuWrap>
          <HorizontalMenuIcon
            color={theme.colors.lightBlue}
          ></HorizontalMenuIcon>
        </MenuWrap>
      </Popover>
    </Wrap>
  );
};

export default MenuPopover;
