import { SVGProps } from 'react';

function EmptyAvatar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7c1.934 0 3.5-1.566 3.5-3.5S8.934 0 7 0a3.499 3.499 0 00-3.5 3.5C3.5 5.434 5.066 7 7 7zm0-5.25c.963 0 1.75.788 1.75 1.75 0 .963-.787 1.75-1.75 1.75S5.25 4.463 5.25 3.5c0-.962.787-1.75 1.75-1.75zm0 7c-2.336 0-7 1.172-7 3.5V14h14v-1.75c0-2.328-4.664-3.5-7-3.5zm-5.25 3.5c.192-.63 2.896-1.75 5.25-1.75 2.363 0 5.075 1.129 5.25 1.75H1.75z"
        fill="#737F94"
      />
    </svg>
  );
}

export default EmptyAvatar;
