import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  delegatedSubtaskLoadingSelector,
  delegatedSubtaskSelector,
  markSubtaskAsDonePromiseCreator,
  sendSubtaskToReviewPromiseCreator,
  startSubtaskPromiseCreator,
} from 'store/subcontractors';
import { getButtonsProperties } from './helpers';
import { FileDropzone } from './FileDropzone';
import Files from './Files';
import MainInfo from './MainInfo';
import { PauseSubtask } from './PauseSubtask';
import { TaskRedirect } from './TaskRedirect';
import { NO_REQUEST_SUBTASK_STATUSES } from './constants';
import { Loader } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { LogoBig } from 'lib/icons';
import {
  AlertItem,
  ButtonStyled,
  ButtonWrap,
  Content,
  IconButtonWrap,
  LoaderWrapper,
  LogoWrap,
  Wrap,
} from './styles';
import { SubtaskStatusesEnum } from 'types';

const Subcontractors = () => {
  const data = useSelector(delegatedSubtaskSelector);
  const isDataLoading = useSelector(delegatedSubtaskLoadingSelector);
  const startSubtask = useActionsRoutines(startSubtaskPromiseCreator);
  const sendSubtaskToReview = useActionsRoutines(
    sendSubtaskToReviewPromiseCreator
  );
  const finishSubtask = useActionsRoutines(markSubtaskAsDonePromiseCreator);

  const [buttonLoading, setButtonLoading] = useState(false);

  const isButtonInactive = NO_REQUEST_SUBTASK_STATUSES.some(
    item => item === data.status
  );

  const requestForInProgress = useCallback(
    async id => {
      if (data?.isApproveRequired) return sendSubtaskToReview(id);
      return finishSubtask(id);
    },
    [sendSubtaskToReview, finishSubtask, data]
  );

  const request = useCallback(async () => {
    switch (data.status) {
      case SubtaskStatusesEnum.TO_DO:
        return startSubtask(data._id);
      case SubtaskStatusesEnum.FAILED:
        return startSubtask(data._id);
      case SubtaskStatusesEnum.IN_PROGRESS:
        return requestForInProgress(data._id);
      default:
        return null;
    }
  }, [data, startSubtask, requestForInProgress]);

  const onButtonClick = useCallback(() => {
    if (isButtonInactive) return;
    setButtonLoading(true);
    request()
      .catch(err => console.error(err))
      .finally(() => setButtonLoading(false));
  }, [request, isButtonInactive]);

  if (isDataLoading) {
    return (
      <LoaderWrapper>
        <Loader loading={isDataLoading} />
      </LoaderWrapper>
    );
  }

  if (!data || !Object.keys(data).length) return null;

  const {
    status,
    projectFiles,
    files,
    isApproveRequired,
    isPhotoRequired,
    nextSubtaskId,
    generatedApiKey,
  } = data;

  const buttonPreset = getButtonsProperties(
    status,
    isApproveRequired,
    isPhotoRequired
  );

  const { label, Icon, ...stylesPreset } = buttonPreset;

  const isTaskFailed =
    data.status === SubtaskStatusesEnum.DONE && !!data.rejectReason;
  const isInProgress = status === SubtaskStatusesEnum.IN_PROGRESS;
  const isButtonDropzone = isPhotoRequired && isInProgress;

  const showUpload = data.fileUrls.length === 0 && isPhotoRequired;

  return (
    <Wrap>
      <Content>
        <LogoWrap>
          <LogoBig />
        </LogoWrap>
        <MainInfo {...data} />
        <Files files={[...projectFiles, ...files]} />
        <ButtonWrap withIconButton={isInProgress}>
          <IconButtonWrap>
            {isInProgress && <PauseSubtask id={data._id} />}
          </IconButtonWrap>
          {isButtonDropzone ? (
            <FileDropzone
              id={data._id}
              status={status}
              isApproveRequired={isApproveRequired}
              isPhotoRequired={isPhotoRequired}
              isButtonInactive={isButtonInactive}
            />
          ) : (
            <>
              {isTaskFailed && (
                <AlertItem
                  message="Comment from manager"
                  description={`${data.rejectReason.message}`}
                  type="error"
                />
              )}
              {nextSubtaskId && generatedApiKey ? (
                <>
                  {showUpload && (
                    <FileDropzone
                      id={data._id}
                      status={SubtaskStatusesEnum.IN_PROGRESS}
                      isApproveRequired={isApproveRequired}
                      isPhotoRequired={isPhotoRequired}
                      isButtonInactive={false}
                    />
                  )}

                  <TaskRedirect
                    isTaskFailed={isTaskFailed}
                    nextSubtaskId={nextSubtaskId}
                    generatedApiKey={generatedApiKey}
                    showUpload={showUpload}
                  />
                </>
              ) : (
                <ButtonStyled
                  isbuttoninactive={isButtonInactive.toString()}
                  preset={stylesPreset}
                  icon={Icon && <Icon />}
                  label={label}
                  onClick={onButtonClick}
                  loading={buttonLoading}
                />
              )}
            </>
          )}
        </ButtonWrap>
      </Content>
    </Wrap>
  );
};

export default Subcontractors;
