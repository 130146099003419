import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { emailTemplatesSelector, resourcesSelector } from 'store/admin';
import { administratorsSelector } from 'store/company';
import {
  createTaskNotificationCreator,
  updateTaskNotificationCreator,
} from 'store/dashboard';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubtasksBlock } from './SubtasksBlock';
import { TaskBlock } from './TaskBlock';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { TaskNotificationSchema } from 'validation';
import { BlockTitle, ButtonsWrap, ModalContent } from './styles';

const NotifyModalComponent = ({ task, template, onClose, templateId }) => {
  const [isLoadingNotify, setIsLoadingNotify] = useState(false);
  const emailTemplates = useSelector(emailTemplatesSelector, isEqual);
  const administrators = useSelector(administratorsSelector, isEqual);
  const resources = useSelector(resourcesSelector, isEqual);
  const createTaskNotification = useActionsRoutines(
    createTaskNotificationCreator
  );
  const updateTaskNotification = useActionsRoutines(
    updateTaskNotificationCreator
  );
  const form = useForm({
    resolver: yupResolver(TaskNotificationSchema),
    defaultValues: template,
  });

  const { setValue, register } = form;
  const recipientsList = useMemo(
    () => [...administrators, ...resources],
    [administrators, resources]
  );

  useEffect(() => {
    register('taskId');
    setValue('taskId', task?._id);
  }, [task?._id, register, setValue]);

  const handleSave = useCallback(
    formData => {
      setIsLoadingNotify(true);
      (!!templateId
        ? updateTaskNotification({ id: templateId, data: formData })
        : createTaskNotification(formData)
      )
        .then(val => onClose(task?._id, val?._id))
        .finally(() => setIsLoadingNotify(false));
    },
    [
      templateId,
      updateTaskNotification,
      createTaskNotification,
      onClose,
      task?._id,
    ]
  );

  return (
    <ModalContent>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSave)}>
          <BlockTitle>Item</BlockTitle>
          <TaskBlock
            form={form}
            task={task}
            templatesList={emailTemplates}
            recipientsList={recipientsList}
            template={template}
          />
          {task?.subtasks?.length ? (
            <>
              <BlockTitle>Tasks</BlockTitle>
              <SubtasksBlock
                form={form}
                templatesList={emailTemplates}
                recipientsList={recipientsList}
                template={template}
                subtasks={task?.subtasks}
              />
            </>
          ) : null}
          <ButtonsWrap>
            <Button
              kind={BUTTON_TYPES.SECONDARY}
              onClick={onClose}
              label="Cancel"
              height="43px"
              width="186px"
            />
            <Button
              kind={BUTTON_TYPES.DEFAULT}
              loading={isLoadingNotify}
              label="Save"
              htmlType="submit"
              height="43px"
              width="186px"
            />
          </ButtonsWrap>
        </form>
      </FormProvider>
    </ModalContent>
  );
};

export const NotifyModal = memo(NotifyModalComponent, isEqual);
