import styled from 'styled-components';

export const SwitchPrefix = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 12px;
  gap: 5px;
`;
