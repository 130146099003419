import styled from 'styled-components';
import theme from 'styles/theme';
import { Button } from 'antd';

export const DeleteButton = styled(Button)`
  font-weight: 600;
  font-size: 11px;
  line-height: 1.6;
  height: auto;
  color: ${theme.colors.lightBlue};
  padding: 0;
  &:hover {
    background: transparent;
    color: ${theme.colors.red};
  }
`;

export const Wrapper = styled.div`
  && .ant-popover-inner {
    min-width: unset;
    width: 300px;
  }
  && .ant-popover-inner-content {
    padding: 15px 20px 10px;
    & > div {
      width: 250px;
    }
  }
`;
