import { BUTTON_TYPES } from 'components/common';
import CollapseButton from 'components/common/CollapseButton/CollapseButton';
import EditIcon from 'lib/icons/EditIcon';
import {
  ContainerShortInfo,
  Heading,
  HeadingBottom,
  HeadingRight,
  HeadingTop,
  InfoDate,
  InfoName,
  ShortInfo,
  Title,
} from './styles';
import { ButtonEditStyled } from 'pages/Dashboard/Project/ProjectView/styles';
import { IProjectTaskDto } from 'types';

interface IHeaderContainerProps {
  setIsOpen?: (index: number) => void;
  index: number;
  isOpenInfo: boolean;
  item: IProjectTaskDto;
  setIsOpenInfo?: (open: boolean) => void;
  notAvailableProject: boolean;
  showCollapse: boolean;
}

const HeaderContainer = ({
  setIsOpen,
  index,
  item,
  setIsOpenInfo,
  isOpenInfo,
  showCollapse,
  notAvailableProject,
}: IHeaderContainerProps) => {
  const onEditClick = e => {
    if (notAvailableProject) return;
    e.stopPropagation();
    setIsOpen(index);
  };

  const handleToggle = () => {
    setIsOpenInfo(!isOpenInfo);
  };

  return (
    <Heading isOpen={isOpenInfo} onClick={handleToggle}>
      <HeadingTop>
        <Title>{item.name}</Title>
        <HeadingRight>
          {!notAvailableProject && (
            <ButtonEditStyled
              style={{ ...(!showCollapse && { marginRight: '0' }) }}
              kind={BUTTON_TYPES.PRIMARY}
              icon={<EditIcon />}
              light="true"
              label="Edit"
              onClick={e => onEditClick(e)}
            />
          )}
          {showCollapse && <CollapseButton isOpen={isOpenInfo} />}
        </HeadingRight>
      </HeadingTop>
      <HeadingBottom>
        {!notAvailableProject && (
          <ContainerShortInfo>
            {item?.sku && (
              <ShortInfo>
                <InfoName>Sku</InfoName>
                <InfoDate>{item.sku}</InfoDate>
              </ShortInfo>
            )}

            <ShortInfo>
              <InfoName>Condition:</InfoName>
              <InfoDate>{item.container?.condition}</InfoDate>
            </ShortInfo>
            <ShortInfo>
              <InfoName>Size:</InfoName>
              <InfoDate>{item.container?.size}</InfoDate>
            </ShortInfo>
            <ShortInfo>
              <InfoName>Grade:</InfoName>
              <InfoDate>{item.container?.grade}</InfoDate>
            </ShortInfo>
            {item?.container?.num && (
              <ShortInfo>
                <InfoName>Container number:</InfoName>
                <InfoDate>{item.container?.num}</InfoDate>
              </ShortInfo>
            )}
          </ContainerShortInfo>
        )}
      </HeadingBottom>
    </Heading>
  );
};

export default HeaderContainer;
