import styled from 'styled-components';
import theme from 'styles/theme';
import { Avatar, Button } from 'antd';

export const AvatarWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .UserActionsPopover {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
`;

export const AvatarStyled = styled(Avatar)<{
  isactive?: string;
  hover?: string;
  onClick?: () => void;
}>`
  cursor: ${({ isactive }) => (isactive === 'true' ? 'pointer' : 'default')};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  img {
    transition: all 0.3s;
    filter: ${props => props.hover === 'true' && 'blur(2px)'};
  }
  svg {
    margin-top: 5px;
  }
`;

export const UnassignSubtask = styled.div<{
  visible?: boolean;
}>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

export const BlueBox = styled.div<{
  visible?: boolean;
}>`
  border-radius: 6px;
  z-index: 1;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.mainDarkBlue};
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const UserActionsItem = styled(Button)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 18px 0 13px;
  width: 100%;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: #f4f5f7;
  }
  svg {
    margin-right: 5px;
  }
`;

export const ButtonItem = styled(UserActionsItem)`
  height: 40px;
  .ant-btn-loading-icon {
    margin-left: 8px;
    order: 2;
  }
`;

export const UserActionsPopover = styled.div`
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  padding: 0;
  align-items: center;
`;

export const UserActionsText = styled.div<{
  isUnassign?: boolean;
}>`
  color: ${({ isUnassign }) =>
    isUnassign ? theme.colors.danger : theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  svg {
    margin-right: 4px;
    fill: ${({ isUnassign }) => (isUnassign ? theme.colors.danger : '#172B4D')};
  }
`;

export const EmptyPersonWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;
