import * as yup from 'yup';
import { checklist, notes, plannedHours } from './baseSchema';

const standAloneSubtaskSchema = yup.object().shape({
  plannedHours,
  name: yup.string().required('Task Name is required!'),
  notes,
  checklist,
});

export default standAloneSubtaskSchema;
