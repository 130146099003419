import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const roles = createRoutine('predefined/roles');
export const locations = createRoutine('predefined/locations');
export const getLocation = createRoutine('predefined/getLocation');
export const globalSearch = createAction('predefined/globalSearch');
export const projectsAllFilter = createAction('predefined/projectsAllFilter');
// postLocation
export const postLocation = createRoutine('predefined/postLocations');
export const postLocationPromiseCreator = promisifyRoutine(postLocation);
// putLocation
export const putLocation = createRoutine('predefined/putLocations');
export const putLocationPromiseCreator = promisifyRoutine(putLocation);
// patchLocation
export const patchLocation = createRoutine('predefined/patchLocations');
export const patchLocationPromiseCreator = promisifyRoutine(patchLocation);
// deleteLocation
export const deleteLocation = createRoutine('predefined/deleteLocations');
export const deleteLocationPromiseCreator = promisifyRoutine(deleteLocation);

// Map
export const uploadMap = createRoutine('predefined/uploadMap');
export const uploadMapPromiseCreator = promisifyRoutine(uploadMap);

export const uploadMapByLocation = createRoutine(
  'predefined/uploadMapByLocation'
);
export const uploadMapByLocationPromiseCreator =
  promisifyRoutine(uploadMapByLocation);

export const deleteMap = createRoutine('predefined/deleteMap');
export const deleteMapPromiseCreator = promisifyRoutine(deleteMap);

export const deleteMapByLocation = createRoutine(
  'predefined/deleteMapByLocation'
);
export const deleteMapByLocationPromiseCreator =
  promisifyRoutine(deleteMapByLocation);

export const checkLocationHasAssignedData = createRoutine(
  'predefined/checkLocationHasAssignedData'
);
export const checkLocationHasAssignedDataPromiseCreator = promisifyRoutine(
  checkLocationHasAssignedData
);
