export const NoResourse = ({ color = 'white', width = 12, height = 12 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 10"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.66669 5.03035C4.83044 5.03035 5.70835 4.15244 5.70835 2.98869C5.70835 1.82494 4.83044 0.947021 3.66669 0.947021C2.50294 0.947021 1.62502 1.82494 1.62502 2.98869C1.62502 4.15244 2.50294 5.03035 3.66669 5.03035ZM4.25002 5.58335H3.08335C1.4751 5.58335 0.166687 6.89177 0.166687 8.50002V9.08335H0.75002H1.33335H6.00002H6.58335H7.16669V8.50002C7.16669 6.89177 5.85827 5.58335 4.25002 5.58335ZM10.8376 2.8376L9.50002 4.17519L8.16244 2.8376L7.3376 3.66244L8.6746 4.99944L7.33702 6.33702L8.16185 7.16186L9.49944 5.82427L10.8376 7.16244L11.6624 6.33761L10.3249 5.00002L11.6624 3.66244L10.8376 2.8376Z" />
  </svg>
);
