import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { validateInvitePromiseCreator } from 'store/auth';
import InviteBlock from './InviteBlock/InviteBlock';
import SignUpForm from './SignUpForm/SignUpForm';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { useActionsRoutines } from 'hooks';
import queryString from 'query-string';
import { UserInviteDetailsForRegistrationDto } from 'types';

const SignUp = (): JSX.Element => {
  const location = useLocation();
  const [invitationData, setInvitationData] =
    useState<UserInviteDetailsForRegistrationDto | null>(null);
  const validateToken = useActionsRoutines(validateInvitePromiseCreator);
  const { apiKey } = queryString.parse(location.search);

  useEffect(() => {
    if (apiKey) {
      validateToken(apiKey)
        .then((data: UserInviteDetailsForRegistrationDto) =>
          setInvitationData(data)
        )
        .catch(console.error);
    }
  }, [apiKey, validateToken]);
  return (
    <AuthWrapper
      message={
        invitationData?.companyName && (
          <InviteBlock companyName={invitationData.companyName} />
        )
      }
    >
      <SignUpForm invitationData={invitationData} />
    </AuthWrapper>
  );
};

export default SignUp;
