import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/inventory';
import * as E from './workers';

export function* watchGetInventory() {
  yield call(E.ensureGetInventory, {
    type: Actions.getInventory.TRIGGER,
  });
  yield takeLatest(Actions.getInventory.TRIGGER, E.ensureGetInventory);
}

export function* watchInventoryPdf() {
  yield takeLatest(Actions.inventoryPdf.TRIGGER, E.ensureInventoryPdf);
}
