import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { StatusCheckbox } from '../StatusCheckbox';
import { v4 as uuidv4 } from 'uuid';
import { BlockItemName, BlockWrap } from '../styles';

const SubtaskBlockComponent = ({
  index,
  subtask,
  form,
  template,
  recipientsList,
  templatesList,
}) => {
  const { register, unregister, setValue } = form;
  const [statuses, setStatuses] = useState({
    statusDone: false,
    statusFailed: false,
    statusOnReview: false,
    statusInProgress: false,
    statusToDo: false,
    resourceWasAssigned: false,
    all: false,
  });
  const itemTemplate = useMemo(
    () => template?.subtasks?.find(item => item?.subtaskId === subtask?._id),
    [template?.subtasks, subtask?._id]
  );

  const mappedTemplate = useMemo(() => {
    if (itemTemplate) {
      const statusDone = itemTemplate?.statusDone
        ? {
            ...itemTemplate?.statusDone,
            template: { ...itemTemplate?.statusDone?.template, _id: uuidv4() },
          }
        : undefined;
      const statusFailed = itemTemplate?.statusFailed
        ? {
            ...itemTemplate?.statusFailed,
            template: {
              ...itemTemplate?.statusFailed?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const statusOnReview = itemTemplate?.statusOnReview
        ? {
            ...itemTemplate?.statusOnReview,
            template: {
              ...itemTemplate?.statusOnReview?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const statusInProgress = itemTemplate?.statusInProgress
        ? {
            ...itemTemplate?.statusInProgress,
            template: {
              ...itemTemplate?.statusInProgress?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const statusToDo = itemTemplate?.statusToDo
        ? {
            ...itemTemplate?.statusToDo,
            template: {
              ...itemTemplate?.statusToDo?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const resourceWasAssigned = itemTemplate?.resourceWasAssigned
        ? {
            ...itemTemplate?.resourceWasAssigned,
            template: {
              ...itemTemplate?.resourceWasAssigned?.template,
              _id: uuidv4(),
            },
          }
        : undefined;
      const all = itemTemplate?.all
        ? {
            ...itemTemplate?.all,
            template: { ...itemTemplate?.all?.template, _id: uuidv4() },
          }
        : undefined;

      return {
        statusDone,
        statusFailed,
        statusOnReview,
        statusInProgress,
        statusToDo,
        resourceWasAssigned,
        all,
      };
    } else {
      return undefined;
    }
  }, [itemTemplate]);

  useEffect(() => {
    if (itemTemplate) {
      const statusDone = mappedTemplate?.statusDone;
      const statusFailed = mappedTemplate?.statusFailed;
      const statusOnReview = mappedTemplate?.statusOnReview;
      const statusInProgress = mappedTemplate?.statusInProgress;
      const statusToDo = mappedTemplate?.statusToDo;
      const resourceWasAssigned = mappedTemplate?.resourceWasAssigned;
      const all = mappedTemplate?.all;

      register(`subtasks[${index}].subtaskId`);
      setValue(`subtasks[${index}].subtaskId`, subtask?._id);

      if (statusDone) {
        register(`subtasks[${index}].statusDone`);
        register(`subtasks[${index}].statusDone.template`);
        register(`subtasks[${index}].statusDone.template.originalId`);
        register(`subtasks[${index}].statusDone.template.title`);
        register(`subtasks[${index}].statusDone.template.body`);
        register(`subtasks[${index}].statusDone.recipients`);
        setValue(`subtasks[${index}].statusDone`, statusDone);
      }

      if (statusFailed) {
        register(`subtasks[${index}].statusFailed`);
        register(`subtasks[${index}].statusFailed.template`);
        register(`subtasks[${index}].statusFailed.template.originalId`);
        register(`subtasks[${index}].statusFailed.template.title`);
        register(`subtasks[${index}].statusFailed.template.body`);
        register(`subtasks[${index}].statusFailed.recipients`);
        setValue(`subtasks[${index}].statusFailed`, statusFailed);
      }

      if (statusOnReview) {
        register(`subtasks[${index}].statusOnReview`);
        register(`subtasks[${index}].statusOnReview.template`);
        register(`subtasks[${index}].statusOnReview.template.originalId`);
        register(`subtasks[${index}].statusOnReview.template.title`);
        register(`subtasks[${index}].statusOnReview.template.body`);
        register(`subtasks[${index}].statusOnReview.recipients`);
        setValue(`subtasks[${index}].statusOnReview`, statusOnReview);
      }

      if (statusInProgress) {
        register(`subtasks[${index}].statusInProgress`);
        register(`subtasks[${index}].statusInProgress.template`);
        register(`subtasks[${index}].statusInProgress.template.originalId`);
        register(`subtasks[${index}].statusInProgress.template.title`);
        register(`subtasks[${index}].statusInProgress.template.body`);
        register(`subtasks[${index}].statusInProgress.recipients`);
        setValue(`subtasks[${index}].statusInProgress`, statusInProgress);
      }

      if (statusToDo) {
        register(`subtasks[${index}].statusToDo`);
        register(`subtasks[${index}].statusToDo.template`);
        register(`subtasks[${index}].statusToDo.template.originalId`);
        register(`subtasks[${index}].statusToDo.template.title`);
        register(`subtasks[${index}].statusToDo.template.body`);
        register(`subtasks[${index}].statusToDo.recipients`);
        setValue(`subtasks[${index}].statusToDo`, statusToDo);
      }

      if (resourceWasAssigned) {
        register(`subtasks[${index}].resourceWasAssigned`);
        register(`subtasks[${index}].resourceWasAssigned.template`);
        register(`subtasks[${index}].resourceWasAssigned.template.originalId`);
        register(`subtasks[${index}].resourceWasAssigned.template.title`);
        register(`subtasks[${index}].resourceWasAssigned.template.body`);
        register(`subtasks[${index}].resourceWasAssigned.recipients`);
        setValue(`subtasks[${index}].resourceWasAssigned`, resourceWasAssigned);
      }

      if (all) {
        register(`subtasks[${index}].all`);
        register(`subtasks[${index}].all.template`);
        register(`subtasks[${index}].all.template.originalId`);
        register(`subtasks[${index}].all.template.title`);
        register(`subtasks[${index}].all.template.body`);
        register(`subtasks[${index}].all.recipients`);
        setValue(`subtasks[${index}].all`, all);
      }

      setStatuses({
        statusDone: !!statusDone,
        statusFailed: !!statusFailed,
        statusOnReview: !!statusOnReview,
        statusInProgress: !!statusInProgress,
        statusToDo: !!statusToDo,
        resourceWasAssigned: !!resourceWasAssigned,
        all: !!all,
      });
    } else {
      register(`subtasks[${index}].subtaskId`);
      setValue(`subtasks[${index}].subtaskId`, subtask?._id);
    }
  }, [mappedTemplate, itemTemplate, index, subtask?._id, register, setValue]);

  const statusesList = useMemo(
    () => [
      `subtasks[${index}].statusDone`,
      `subtasks[${index}].statusFailed`,
      `subtasks[${index}].statusOnReview`,
      `subtasks[${index}].statusInProgress`,
      `subtasks[${index}].statusToDo`,
      `subtasks[${index}].resourceWasAssigned`,
    ],
    [index]
  );

  const handleCheck = useCallback(
    (name, checkboxStatus) => {
      const statusArr = name.split('.');
      const statusName = statusArr[statusArr.length - 1];

      if (checkboxStatus) {
        register(`${name}.template`);
        register(`${name}.template.originalId`);
        register(`${name}.template.title`);
        register(`${name}.template.body`);
        register(`${name}.recipients`);

        setValue(`${name}.template.title`, undefined);
        setValue(`${name}.template.body`, undefined);
        setValue(`${name}.template`, undefined);
        setValue(`${name}.recipients`, undefined);

        const isAll = statusName.toLocaleLowerCase().includes('all');

        if (isAll) {
          statusesList.forEach(status => {
            unregister(`${status}.template`);
            unregister(`${status}.template.originalId`);
            unregister(`${status}.template.title`);
            unregister(`${status}.template.body`);
            unregister(`${status}.recipients`);
            unregister(status);
          });

          setStatuses({
            statusDone: false,
            statusFailed: false,
            statusOnReview: false,
            statusInProgress: false,
            statusToDo: false,
            resourceWasAssigned: false,
            all: checkboxStatus,
          });

          return checkboxStatus;
        } else if (!isAll && statuses.all) {
          unregister(`subtasks[${index}].all.template`);
          unregister(`subtasks[${index}].all.template.originalId`);
          unregister(`subtasks[${index}].all.template.title`);
          unregister(`subtasks[${index}].all.template.body`);
          unregister(`subtasks[${index}].all.recipients`);
          unregister(`subtasks[${index}].all`);

          setStatuses(statuses => ({
            ...statuses,
            [statusName]: checkboxStatus,
            all: false,
          }));
          return checkboxStatus;
        }
      } else {
        setValue(name, undefined);
        unregister(`${name}.template`);
        unregister(`${name}.template.originalId`);
        unregister(`${name}.template.title`);
        unregister(`${name}.template.body`);
        unregister(`${name}.recipients`);
        unregister(name);
      }

      setStatuses(statuses => ({
        ...statuses,
        [statusName]: checkboxStatus,
      }));
      return checkboxStatus;
    },
    [
      statuses.all,
      setStatuses,
      statusesList,
      index,
      register,
      setValue,
      unregister,
    ]
  );

  return (
    <BlockWrap>
      <BlockItemName>{subtask?.name}</BlockItemName>
      <StatusCheckbox
        name={`subtasks[${index}].statusDone`}
        label="When the task status is Done"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusDone}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].statusFailed`}
        label="When the task status is Failed"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusFailed}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].statusOnReview`}
        label="When the task status is On review"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusOnReview}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].statusInProgress`}
        label="When the task status is In Progress"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusInProgress}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].statusToDo`}
        label="When the task status is To Do"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.statusToDo}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].resourceWasAssigned`}
        label="When the Resource was assigned"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.resourceWasAssigned}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
      <StatusCheckbox
        name={`subtasks[${index}].all`}
        label="All"
        form={form}
        itemTemplate={mappedTemplate}
        templatesList={templatesList}
        recipientsList={recipientsList}
        isChecked={statuses.all}
        onChange={handleCheck}
        index={index}
        isSubtask
      />
    </BlockWrap>
  );
};

export const SubtaskBlock = SubtaskBlockComponent;
