// icons
import { Dispatch, SetStateAction } from 'react';
import { Dislike, Like } from 'lib/icons';
import { ButtonApprove, ButtonFailed, RowStyled } from './styles';

interface IButtonGroupProps {
  approve?: () => void;
  reject?: Dispatch<SetStateAction<boolean>>;
  isLoadingSend?: boolean;
  disabled?: boolean;
}

const ButtonGroup = ({
  approve,
  reject,
  isLoadingSend,
  disabled,
}: IButtonGroupProps) => (
  <RowStyled>
    <ButtonFailed
      icon={<Dislike />}
      label="Failed"
      space="23px"
      width="316px"
      height="62px"
      onClick={() => reject(true)}
      disabled={disabled}
    />
    <ButtonApprove
      icon={<Like />}
      label="Approve"
      space="23px"
      width="316px"
      height="62px"
      onClick={approve}
      loading={isLoadingSend}
      disabled={disabled}
    />
  </RowStyled>
);

export default ButtonGroup;
