import styled from 'styled-components';
import { List } from 'antd';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  & > .search {
    width: 100%;
    max-width: 301px;
    margin: 0 17px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 17px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
  & > .avatar {
    background: transparent;
    min-height: 37px;
    min-width: 37px;

    margin-right: 10px;
  }
`;

export const ListStyled = styled(List)`
  margin-top: 19px;
  max-height: 395px;
  overflow: auto;
`;

export const Title = styled(List)`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  padding: 21px 17px 19px;
`;
