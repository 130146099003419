import storageManager from './storageManager';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import {
  IAttachmentInfo,
  IProjectResponseDto,
  ITaskDto,
  TaskTypeEnum,
} from 'types';

export const getDraftProjects = (
  id: string,
  paramForSearch: string,
  containerSkus: string[]
) => {
  const draftProjects = JSON.parse(storageManager.getProjects()) || [];
  const search = paramForSearch?.toLowerCase();
  return draftProjects?.filter(item => {
    const { invoice, account, locationId, customer, tasks } = item;
    const hasLocation = id === locationId;
    const hasInvoice = invoice?.toLowerCase()?.indexOf(search) > -1;
    const hasAccount = account?.toLowerCase()?.indexOf(search) > -1;
    const hasCustomer = customer?.toLowerCase()?.indexOf(search) > -1;
    const hasTasks = tasks?.find(
      task => task?.sku?.toLowerCase()?.indexOf(search) > -1
    );
    const hasContainerSku = containerSkus.length
      ? tasks?.some(
          ({ sku, type }) =>
            type === TaskTypeEnum.Container && containerSkus.includes(sku)
        )
      : true;

    return (
      hasLocation &&
      hasContainerSku &&
      (!search || hasInvoice || hasAccount || hasCustomer || hasTasks)
    );
  });
};

export const draftProject = (
  tasks: ITaskDto,
  formData,
  files: IAttachmentInfo[],
  _id: string,
  searchBy: string,
  currentLocationId: string,
  containerSkus: string[]
) => {
  const data: IProjectResponseDto[] =
    JSON.parse(storageManager.getProjects()) || [];
  const projectExist = !!data?.find(item => item?._id === _id);
  let projectsForSend = [];
  const startDate =
    formData?.startDate && moment(formData?.startDate).toISOString();
  const dueDate = formData?.dueDate && moment(formData?.dueDate).toISOString();
  const values = {
    ...formData,
    startDate,
    dueDate,
    tasks,
    files,
    isDraft: true,
  };
  if (projectExist) {
    projectsForSend = data?.map(item =>
      item?._id === _id ? { ...values, _id: item?._id } : item
    );
  } else {
    projectsForSend = [{ ...values, _id: uuidv4() }, ...data];
  }
  storageManager.setProjects(projectsForSend);
  return getDraftProjects(currentLocationId, searchBy, containerSkus);
};

export const removeDraftProject = (
  id: string,
  currentLocationId: string,
  searchBy: string,
  containerSkus: string[]
) => {
  const data = JSON.parse(storageManager.getProjects()) || [];
  const newData = data?.filter(item => item?._id !== id);
  storageManager.setProjects(newData);
  return getDraftProjects(currentLocationId, searchBy, containerSkus);
};
