import { MIN_PROJECTS_CONTAINER_HEIGHT } from './dashboard/constants';
import {
  ContainerOptionsDto,
  IAuthUser,
  IConditionDto,
  IGanttState,
  IGradeDto,
  IInitialState,
  IProjectResponseDto,
  ISizeDto,
  ISubtaskDto,
  ISubtaskResponseDto,
  ITaskDto,
  ITruckingCompanyDto,
  IUserDto,
  IWorkOrderDto,
  IWorkOrdersInfo,
  ProjectViewMode,
  ResourceDetailedResponseDto,
  ResponseRecurringTaskDto,
  ScreenDistributionTypesEnum,
  ShiftClosestDatesDto,
} from 'types';

const initialState: IInitialState = {
  auth: {
    isAuthorized: false,
    user: {} as IAuthUser,
    hasError: false,
    location: undefined,
    currentLocation: undefined,
    locationTimezone: undefined,
  },
  loading: {
    isLoading: false,
    isPageLoading: 0,
    isTabLoading: false,
    isFilesLoading: false,
  },
  predefined: {
    locations: [],
    globalSearch: '',
    roles: [],
    searchAllProjects: false,
  },
  company: {
    administrators: undefined,
    admin: {} as IUserDto,
    companyInfo: undefined,
    employeesValues: [],
  },
  admin: {
    showAllResources: false,
    isResourcesLoading: false,
    isCsvExporting: false,
    isCsvImporting: false,
    csvImportErrors: [],
    csvInvalidRowNumbers: [],
    isSampleCsvExporting: false,
    invitationsList: [],
    subtaskTypes: [],
    subtaskTypesDefaults: [],
    subtaskTypesList: [],
    subtaskTypesObject: {},
    subtaskTypesSearch: '',
    tasks: [],
    tasksSearch: undefined,
    defaultContainerTemplate: undefined,
    resources: [],
    task: {} as ITaskDto,
    relatedTasks: [],
    resource: {} as ResourceDetailedResponseDto,
    groups: undefined,
    emailTemplates: [],
    recurringTasks: [],
    recurringTask: {} as ResponseRecurringTaskDto,
    containerOptions: {
      colors: [],
      conditions: [],
      grades: [],
      sizes: [],
      skus: [],
    } as ContainerOptionsDto,
    isContainerOptionsLoading: false,
  },
  dashboard: {
    assignConflictData: [],
    isCloneProject: false,
    cloneFiles: [],
    projectViewMode: ProjectViewMode.Tasks,
    isFirstProjectsRequestLoaded: false,
    isProjectCreateModalOpen: false,
    subtasksUnreadMessagesCount: {},
    gantt: undefined,
    isScreenSplited: false,
    projects: [],
    subtaskDraftFilesBuffer: [],
    subtaskFilesBuffer: [],
    subtaskTemplateFilesBuffer: [],
    project: {} as IProjectResponseDto,
    minProjectsContainerHeight: MIN_PROJECTS_CONTAINER_HEIGHT,
    projectsContainerHeight: MIN_PROJECTS_CONTAINER_HEIGHT,
    projectFilesLoader: false,
    standaloneSubtask: {} as ISubtaskDto,
    standaloneSubtaskUnreadCount: 0,
    projectsSortBy: 'dueDate',
    containerSizesFilter: [],
    messages: [],
    ganttWeekView: true,
    ganttDates: {
      startDate: undefined,
      endDate: undefined,
      weekStartDay: 0,
    },
    screenDistribution: ScreenDistributionTypesEnum.HALF,
    projectsTotal: 0,
    openedSubtaskChatId: undefined,
    containerSkusFilter: [],
  },
  inbound: {
    workOrders: [],
    workOrder: {} as IWorkOrderDto,
    workOrdersInfo: {} as IWorkOrdersInfo,
  },
  timesheets: {
    timesheets: [],
    resourceTimesheets: [],
    timesheetsDates: undefined,
    search: '',
    closestDates: {} as ShiftClosestDatesDto,
  },
  depot: {
    colors: [],
    sizes: [],
    size: {} as ISizeDto,
    conditions: [],
    condition: {} as IConditionDto,
    grades: [],
    grade: {} as IGradeDto,
    companies: [],
    company: {} as ITruckingCompanyDto,
  },
  inventory: {
    inventoryList: [],
  },
  sharing: {
    isSystemNotificationsOpened: false,
    projectForCustomer: {} as IProjectResponseDto,
    systemNotifications: { data: undefined, meta: { page: 0, pages: 0 } },
    systemNotificationsUnreadCount: 0,
    isShowOnlyUnreadMessages: false,
  },
  subcontractors: {
    delegatedSubtask: {} as ISubtaskResponseDto,
    delegatedNextSubtask: {} as ISubtaskResponseDto,
    isLoading: false,
  },
  reports: {
    reportsList: undefined,
    profitPreResource: [],
    profitPerInvoice: undefined,
    profitPerEmployees: undefined,
    reportsDates: undefined,
    search: '',
    invoiceSearch: '',
  },
  gantt: {} as IGanttState,
};

export default initialState;
