import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from 'store/reports';
import * as E from './workers';

export function* watchProfitPerInvoice() {
  yield takeLatest(Actions.profitPerInvoice.TRIGGER, E.ensureProfitPerInvoice);
}

export function* watchProfitPerEmployees() {
  yield call(E.ensureProfitPerEmployees);
  yield takeLatest(
    Actions.profitPerEmployees.TRIGGER,
    E.ensureProfitPerEmployees
  );
}

export function* watchProfitPerResource() {
  yield takeLatest(
    Actions.profitPerResource.TRIGGER,
    E.ensureProfitPerResource
  );
}

export function* watchUpdateReport() {
  yield takeLatest(Actions.updateReport.TRIGGER, E.ensureUpdateReport);
}

export function* watchCreateReport() {
  yield takeLatest(Actions.createReport.TRIGGER, E.ensureCreateReport);
}

export function* watchDeleteReport() {
  yield takeLatest(Actions.deleteReport.TRIGGER, E.ensureDeleteReport);
}

export function* watchGetReportsList() {
  yield call(E.ensureGetReportsList);
  yield takeLatest(Actions.getReportsList.TRIGGER, E.ensureGetReportsList);
}
