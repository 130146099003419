import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get trucking companies
export const truckingCompanies = createRoutine('depot/truckingCompanies');
export const truckingCompaniesPromiseCreator = promisifyRoutine(
  truckingCompanies
);
export const getTruckingCompanyById = createRoutine(
  'depot/getTruckingCompanyById'
);
export const getTruckingCompanyByIdPromiseCreator = promisifyRoutine(
  getTruckingCompanyById
);
// add trucking company
export const addTruckingCompany = createRoutine('depot/addTruckingCompany');
export const addTruckingCompanyPromiseCreator = promisifyRoutine(
  addTruckingCompany
);
// edit trucking company
export const editTruckingCompany = createRoutine('depot/editTruckingCompany');
export const editTruckingCompanyPromiseCreator = promisifyRoutine(
  editTruckingCompany
);
// delete trucking company
export const deleteTruckingCompany = createRoutine(
  'depot/deleteTruckingCompany'
);
export const deleteTruckingCompanyPromiseCreator = promisifyRoutine(
  deleteTruckingCompany
);
