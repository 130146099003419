import { SVGProps } from 'react';

export const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_11149_19243)" fill="#fff">
      <path d="M4 .666A3.337 3.337 0 00.667 3.999 3.337 3.337 0 004 7.333a3.337 3.337 0 003.333-3.334A3.337 3.337 0 004 .666zm0 6a2.67 2.67 0 01-2.667-2.667A2.67 2.67 0 014 1.333a2.67 2.67 0 012.667 2.666A2.67 2.67 0 014 6.666z" />
      <path d="M4.333 2.334h-.666v1.805l1.097 1.097.472-.471-.903-.902V2.334z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_11149_19243">
        <path fill="#fff" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </svg>
);
