import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const administrators = createRoutine('auth/administrators');
export const administratorsPromiseCreator = promisifyRoutine(administrators);
export const getCompany = createRoutine('auth/company');
export const getCompanyPromiseCreator = promisifyRoutine(getCompany);
// deleteUser
export const deleteUser = createRoutine('auth/deleteUser');
export const deleteUserPromiseCreator = promisifyRoutine(deleteUser);
// updateCompany
export const updateCompany = createRoutine('company/updateCompany');
export const updateCompanyPromiseCreator = promisifyRoutine(updateCompany);
// patchCompany
export const patchCompany = createRoutine('company/updateCompany');
export const patchCompanyPromiseCreator = promisifyRoutine(updateCompany);
// deleteCompany
export const deleteCompany = createRoutine('company/deleteCompany');
export const deleteCompanyPromiseCreator = promisifyRoutine(deleteCompany);
// restoreCompany
export const restoreCompany = createRoutine('auth/restoreCompany');
export const restoreCompanyPromiseCreator = promisifyRoutine(restoreCompany);
// register new company
export const registerNewCompany = createRoutine('auth/registerNewCompany');
export const registerNewCompanyPromiseCreator = promisifyRoutine(
  registerNewCompany
);
// transferCompany
export const transferCompany = createRoutine('company/transferCompany');
export const transferCompanyPromiseCreator = promisifyRoutine(transferCompany);

// uploadLogo
export const uploadLogo = createRoutine('company/uploadLogo');
export const uploadLogoPromiseCreator = promisifyRoutine(uploadLogo);

// getUserAdmin
export const getUserAdmin = createRoutine('auth/getUserAdmin');
export const getUserAdminPromiseCreator = promisifyRoutine(getUserAdmin);
// updateUserAdmin
export const updateUserAdmin = createRoutine('auth/updateUserAdmin');
export const updateUserAdminPromiseCreator = promisifyRoutine(updateUserAdmin);
// partial update UserAdmin
export const patchUserAdmin = createRoutine('auth/patchUserAdmin');
export const patchUserAdminPromiseCreator = promisifyRoutine(patchUserAdmin);
// updateAvatarAdmin
export const updateAvatarAdmin = createRoutine('auth/updateAvatarAdmin');
// company favicon
export const uploadFavicon = createRoutine('auth/uploadFavicon');
export const uploadFaviconPromiseCreator = promisifyRoutine(uploadFavicon);
// deleteUser
export const deleteSuperAdmin = createRoutine('auth/deleteSuperAdmin');
export const deleteSuperAdminPromiseCreator = promisifyRoutine(
  deleteSuperAdmin
);
export const clearUserAdmin = createAction('company/clearUserAdmin');

export const getEmployeesValues = createRoutine('auth/getEmployeesValues');
export const getEmployeesValuesPromiseCreator = promisifyRoutine(
  getEmployeesValues
);
