import styled, { css } from 'styled-components';

export const SubtaskWrap = styled.div`
  position: relative;
  margin-bottom: 7px;
`;

export const SubtaskContainer = styled.div`
  .ChatPopover {
    padding: 0;

    .ant-popover-inner-content {
      padding: 0;
    }

    &.ant-popover-placement-bottomRight
      .ant-popover-content
      .ant-popover-arrow {
      top: -12px !important;
      right: 6px;
    }

    &.ant-popover-placement-topRight .ant-popover-content .ant-popover-arrow {
      right: 6px;
    }
  }
`;

export const FileDragHover = styled.div<{
  isDragHover?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border: none;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.mainDarkBlue};
  backdrop-filter: blur(2px);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isDragHover }) =>
    isDragHover &&
    css`
      pointer-events: auto;
      opacity: 0.7;
      z-index: 100;
    `}
`;

export const FileDragHoverInner = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 10px;
  }
`;
