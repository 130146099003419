import { takeLatest } from 'redux-saga/effects';
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetInvitationsList() {
  yield takeLatest(
    Actions.getInvitationsList.TRIGGER,
    E.ensureGetInvitationsList
  );
}

export function* watchInviteUser() {
  yield takeLatest(Actions.inviteUser.TRIGGER, E.ensureInviteUser);
}

export function* watchDeleteInvite() {
  yield takeLatest(Actions.deleteInvite.TRIGGER, E.ensureDeleteInvite);
}
