export const Comment = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipComment)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.993 1.9C13.993 1.13 13.37 0.5 12.6 0.5H1.4C0.63 0.5 0 1.13 0 1.9V10.3C0 11.07 0.63 11.7 1.4 11.7H11.2L14 14.5L13.993 1.9ZM12.6 1.9V11.119L11.781 10.3H1.4V1.9H12.6ZM2.8 7.5H11.2V8.9H2.8V7.5ZM2.8 5.4H11.2V6.8H2.8V5.4ZM2.8 3.3H11.2V4.7H2.8V3.3Z"
        fill="#172B4D"
      />
    </g>
    <defs>
      <clipPath id="clipComment">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
