import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import {
  attachToSubtask,
  getDelegatedSubtask,
  markSubtaskAsDone,
  markSubtaskAsPaused,
  sendSubtaskToReview,
  startSubtask,
} from './actions';
import { ISubtaskResponseDto } from 'types';

const subcontractors = createReducer(initialState.subcontractors, {
  [getDelegatedSubtask.REQUEST]: state => {
    state.isLoading = true;
  },
  [getDelegatedSubtask.FAILURE]: state => {
    state.isLoading = false;
  },

  [getDelegatedSubtask.SUCCESS]: (state, { payload }) => {
    if (!payload) {
      state.delegatedSubtask = state.delegatedNextSubtask;
      state.delegatedNextSubtask = {} as ISubtaskResponseDto;
    } else {
      state.delegatedSubtask = payload;
    }
    state.isLoading = false;
  },
  [startSubtask.SUCCESS]: (state, action) => {
    state.delegatedSubtask = action.payload;
  },
  [sendSubtaskToReview.SUCCESS]: (state, action) => {
    state.delegatedSubtask = action.payload;
  },
  [markSubtaskAsDone.SUCCESS]: (state, action) => {
    state.delegatedSubtask = action.payload;
  },
  [markSubtaskAsPaused.SUCCESS]: (state, { payload }) => {
    const { currentSubtask, createdSubtask } = payload;
    const updatedCurrentSubtask = {
      ...currentSubtask,
      nextSubtaskId: createdSubtask._id,
    };
    state.delegatedNextSubtask = createdSubtask;
    state.delegatedSubtask = updatedCurrentSubtask;
  },
  [attachToSubtask.SUCCESS]: (state, action) => {
    const data = state.delegatedSubtask?.nextSubtaskId
      ? {
          nextSubtaskId: state.delegatedSubtask.nextSubtaskId,
          ...action.payload,
        }
      : action.payload;

    state.delegatedSubtask = data;
  },
});

export { subcontractors as reducer };
