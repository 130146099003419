import { useCallback, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import {
  getEmailTemplatesPromiseCreator,
  getRecurringTasks,
  groups,
  resources,
} from 'store/admin';
import { currentLocation, locationTimezone } from 'store/auth';
import { companySelector } from 'store/company';
import {
  gantt,
  ganttDates,
  ganttDatesSelector,
  projects,
  removeProjects,
} from 'store/dashboard';
import { ganttStoreSelector } from 'store/gantt';
import { workOrdersInfo } from 'store/inbound';
import { globalSearch } from 'store/predefined';
import { reportsDatesSelector, setReportsDates } from 'store/reports';
import {
  getSystemNotificationsPromiseCreator,
  isSystemNotificationsOpenedSelector,
  markSystemNotificationsReadPromiseCreator,
  systemNotificationsUnreadCountSelector,
  toggleSystemNotifications,
} from 'store/sharing';
import {
  setTimesheetsDates as setTimesheetsDatesAction,
  timesheetsDatesSelector,
} from 'store/timesheets';
import { getStartEndWeek } from 'utils/helpers';
import { NotificationsContent } from './NotificationsContent';
import { Wrap } from './style';
import { IconButton } from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
import { Bell } from 'lib/icons/Bell';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import theme from 'styles/theme';
import storageManager from 'utils/storageManager';
import { getNewTimezoneTime } from 'utils/timezone';
import { Badge, Popover } from 'antd';

export const NotificationsButton = () => {
  const unreadCount = useSelector(systemNotificationsUnreadCountSelector);
  const isSystemNotificationsOpened = useSelector(
    isSystemNotificationsOpenedSelector
  );
  const company = useSelector(companySelector, isEqual);
  const gantDates = useSelector(ganttDatesSelector, isEqual);
  const timesheetsDates = useSelector(timesheetsDatesSelector, isEqual);
  const reportDates = useSelector(reportsDatesSelector, isEqual);
  const { isOpenDrawer, isOpenStandaloneSubtask } = useSelector(
    ganttStoreSelector,
    isEqual
  );

  const getEmailTemplates = useActionsRoutines(getEmailTemplatesPromiseCreator);
  const getSystemNotifications = useActionsRoutines(
    getSystemNotificationsPromiseCreator
  );
  const markSystemNotificationsRead = useActionsRoutines(
    markSystemNotificationsReadPromiseCreator
  );
  const changeLocation = useActions(currentLocation);
  const getWorkOrdersInfo = useActions(workOrdersInfo);
  const getProjects = useActions(projects);
  const clearProjectsList = useActions(removeProjects);
  const getGantt = useActions(gantt);
  const setGanttDates = useActions(ganttDates);
  const getResources = useActions(resources);
  const toggleVisibleSystemNotifications = useActions(
    toggleSystemNotifications
  );
  const getGroups = useActions(groups);
  const setReportDates = useActions(setReportsDates);
  const setLocationTimezone = useActions(locationTimezone);
  const setTimesheetsDates = useActions(setTimesheetsDatesAction);
  const setSearch = useActions(globalSearch);
  const recurringTasks = useActions(getRecurringTasks);

  useEffect(() => {
    if (
      isSystemNotificationsOpened &&
      (isOpenDrawer || isOpenStandaloneSubtask)
    ) {
      toggleVisibleSystemNotifications(false);
    }
  }, [
    isSystemNotificationsOpened,
    isOpenDrawer,
    isOpenStandaloneSubtask,
    toggleVisibleSystemNotifications,
  ]);

  useEffect(() => {
    if (isSystemNotificationsOpened) {
      getSystemNotifications({ isRefetch: true }).catch(console.error);
    }
  }, [isSystemNotificationsOpened, getSystemNotifications]);

  const handleSetVisible = useCallback(
    v => toggleVisibleSystemNotifications(v),
    [toggleVisibleSystemNotifications]
  );

  const updateTimezone = useCallback(
    newLocation => {
      const timezone = company?.locations?.find(
        CompanyLocation => CompanyLocation._id === newLocation
      )?.timezone;
      const utcOffset = momentTimezone.tz(timezone).utcOffset();

      const gantDatesWithTimezone = getNewTimezoneTime(
        gantDates?.startDate,
        gantDates?.endDate,
        utcOffset,
        timezone
      );

      setGanttDates({
        ...gantDatesWithTimezone,
        weekStartDay: company?.weekStartDay,
      });

      if (!reportDates) {
        const { startDate, endDate } = getStartEndWeek(
          company?.weekStartDay,
          momentTimezone(new Date()).tz(timezone)
        );
        setReportDates({ startDate, endDate });
      } else {
        const reportDatesWithTimezone = getNewTimezoneTime(
          reportDates?.startDate,
          reportDates?.endDate,
          utcOffset,
          timezone
        );

        setReportDates(reportDatesWithTimezone);
      }

      if (!timesheetsDates) {
        const { startDate, endDate } = getStartEndWeek(
          company?.weekStartDay,
          moment()
        );

        setTimesheetsDates({ startDate, endDate });
      } else {
        const timesheetsDatesWithTimezone = getNewTimezoneTime(
          timesheetsDates?.startDate,
          timesheetsDates?.endDate,
          utcOffset,
          timezone
        );

        setTimesheetsDates(timesheetsDatesWithTimezone);
      }

      setLocationTimezone(timezone);
      momentTimezone.tz.setDefault(timezone);
      storageManager.setTimezone(timezone);
      moment().utcOffset(utcOffset);
    },
    [
      company,
      gantDates,
      reportDates,
      timesheetsDates,
      setGanttDates,
      setLocationTimezone,
      setReportDates,
      setTimesheetsDates,
    ]
  );

  const handleChangeLocation = useCallback(
    value => {
      changeLocation(value);
      setSearch('');
      recurringTasks({ locationId: value });
      storageManager.setLocation(value);
      getWorkOrdersInfo();
      getResources();
      updateTimezone(value);

      clearProjectsList();
      getGroups({ locationId: value, noLoader: false });
      getEmailTemplates({ noLoader: false }).catch(e => console.error(e));
      getProjects({ isLocationChange: true });
      getGantt();
    },
    [
      changeLocation,
      getProjects,
      getGantt,
      getWorkOrdersInfo,
      getResources,
      clearProjectsList,
      getEmailTemplates,
      getGroups,
      recurringTasks,
      setSearch,
      updateTimezone,
    ]
  );

  return (
    <Wrap>
      <Popover
        placement="bottom"
        destroyTooltipOnHide
        content={
          <NotificationsContent
            setVisible={handleSetVisible}
            onLocationChange={handleChangeLocation}
            getSystemNotifications={getSystemNotifications}
            markSystemNotificationsRead={markSystemNotificationsRead}
          />
        }
        visible={isSystemNotificationsOpened}
        onVisibleChange={handleSetVisible}
        trigger="click"
        overlayClassName="NotificationsPopover"
        overlayInnerStyle={{
          background: theme.colors.white,
          borderRadius: '10px',
          boxShadow: '0px 4px 31px rgba(0, 0, 0, 0.25)',
          height: `${unreadCount > 0 ? '575px' : '550px'}`,
          width: '345px',
          overflow: 'auto',
        }}
      >
        <Badge count={unreadCount}>
          <IconButton
            icon={<Bell size={22} />}
            background={theme.colors.white}
          />
        </Badge>
      </Popover>
    </Wrap>
  );
};
