import {
  LegacyRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// @ts-ignore
import { formatDate } from 'react-day-picker/moment';
import { DayPickerInputProps } from 'react-day-picker/types/Props';
import { useSelector } from 'react-redux';
import { companySelector } from 'store/company';
import { ganttDatesSelector } from 'store/dashboard';
import { isSameDay } from 'utils/helpers';
import { Error, Label } from 'components/common';
import { ArrowDownFilled, Calendar } from 'lib/icons';
import moment from 'moment-timezone';
import { DatePickerInputStyled, Wrap } from './styles';
import { IDateTime } from 'types';

const InputComponentDecorator = val =>
  forwardRef((props, ref) => (
    <DatePickerInputStyled {...props}>
      <Calendar className="calendar-icon" />
      <input
        {...props}
        readOnly
        value={val}
        ref={ref as LegacyRef<HTMLInputElement>}
      />
      <ArrowDownFilled />
    </DatePickerInputStyled>
  ));

interface IDayWeekPicker {
  width?: string;
  error?: string;
  placeholder?: string;
  label?: string | Element;
  name?: string;
  value?: IDateTime;
  onWeekClick?: (
    weekNumber: number,
    dates: moment.Moment[],
    newWeekStartDay?: moment.Moment
  ) => void;
  onDayClick?: (date: moment.Moment) => void;
  withoutTodayBtn?: boolean;
  onChange?: (day: Date) => void;
}

export const DayWeekPicker = memo(
  ({
    label,
    error,
    onWeekClick,
    onDayClick,
    placeholder = 'mm/dd/yyyy',
    onChange,
    value,
    ...props
  }: DayPickerInputProps & IDayWeekPicker) => {
    const [isOpen, setIsOpen] = useState(false);

    const gantDates = useSelector(ganttDatesSelector);
    const company = useSelector(companySelector);
    const valueFrom = moment(value.startDate).format('MM/DD');
    const valueTo = moment(value.endDate).format('MM/DD');
    const valueDay = useMemo(
      () =>
        value.endDate && !isSameDay(value.startDate, value.endDate)
          ? `${valueFrom} - ${valueTo}`
          : valueFrom,
      [value, valueFrom, valueTo]
    );
    const pickerRef = useRef();
    const selectedDates = useCallback(
      day => {
        if (value.endDate && !isSameDay(value.startDate, value.endDate)) {
          const range = [];

          for (let i = 0; i < 7; i++) {
            range.push(
              moment(value.startDate).startOf('day').add(i, 'days').toString()
            );
          }

          return range.includes(moment(day).startOf('day').toString());
        } else {
          return (
            moment(day).startOf('day').toString() ===
            moment(value.startDate).startOf('day').toString()
          );
        }
      },
      [value]
    );

    const handleWeekClick = useCallback(
      (_, dates) => {
        onWeekClick(_, dates);
        (pickerRef as any)?.current?.hideDayPicker();
      },
      [onWeekClick, pickerRef]
    );

    const handleFocusInput = useCallback(
      e => {
        (pickerRef as any)?.current?.getInput()?.focus();
        const path = e?.nativeEvent?.composedPath();

        if (
          isOpen &&
          !path?.find?.(item => item?.className === 'DayPickerInput-Overlay')
        ) {
          (pickerRef as any)?.current?.hideDayPicker();
        }
      },
      [pickerRef, isOpen]
    );

    const dayPickerProps = {
      showWeekNumbers: true,
      onWeekClick: handleWeekClick,
      onDayClick: onDayClick || onChange,
      onTodayButtonClick: date => {
        if (onDayClick) {
          onDayClick(date);
        } else {
          onChange(date);
        }

        (pickerRef as any)?.current?.hideDayPicker();
      },
      todayButton: 'Today',
      showOutsideDays: true,
      enableOutsideDaysClick: true,
      weekdaysShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      firstDayOfWeek: gantDates?.weekStartDay || company?.weekStartDay || 0,
      selectedDays: selectedDates,
    };

    const handleParseDate = useCallback(
      () =>
        (value.startDate && moment(value.startDate).toDate()) ||
        moment().toDate(),
      [value.startDate]
    );
    const onDayPickerShow = useCallback(() => {
      setIsOpen(true);
    }, []);

    const onDayPickerHide = useCallback(() => {
      setIsOpen(false);
    }, []);

    return (
      <Wrap onClick={handleFocusInput}>
        {label && <Label>{label}</Label>}
        <DayPickerInput
          {...props}
          value={valueDay}
          ref={pickerRef}
          component={InputComponentDecorator(valueDay)}
          format="MM/DD/YYYY"
          formatDate={formatDate}
          parseDate={handleParseDate}
          placeholder={placeholder}
          dayPickerProps={dayPickerProps as any}
          inputProps={{ error }}
          onDayPickerHide={onDayPickerHide}
          onDayPickerShow={onDayPickerShow}
        />
        {error && <Error>{error}</Error>}
      </Wrap>
    );
  }
);
