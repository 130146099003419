import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BUTTON_TYPES, Button } from 'components/common';
import { Divider } from 'antd';

export const PageWrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayLight};
  position: relative;
`;

export const SignInBox = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 31px 40px;
  margin-bottom: 80px;
  @media (max-width: 520px) {
    padding: 31px 20px;
  }
`;

export const GoogleButton = styled(Button)`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  padding: 0;
  width: 320px;
  height: 50px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 15px;
  }
  &:hover {
    box-shadow: 8px 10px 23px rgba(0, 0, 0, 0.12);
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  &:focus {
    background: ${({ theme }) => theme.colors.grayDark};
    border: 1px solid ${({ theme }) => theme.colors.grayDark};
  }
`;

export const Error = styled(Button)<{
  kind?: BUTTON_TYPES;
}>`
  background: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  width: 337px;
  height: 48px;
  position: absolute;
  top: 5%;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const FormItem = styled.form`
  max-width: 320px;
  margin: 0 auto;
  && input {
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const DividerItem = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 22px 0 13px;
  }
`;

export const BottomItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const LinkItem = styled(Link)`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: inline-block;
  margin-bottom: 20px;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
