import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  updateSubtaskChangelog,
  updateSubtaskLogItemPromiseCreator,
} from 'store/dashboard';
import { getAvatarProps, isMobileDevice } from 'utils/helpers';
import { Cell } from '../../common';
import {
  ACTION_CELL_WIDTH,
  EDIT_CELL_WIDTH,
  NAME_CELL_WIDTH,
  PERCENT_CELL_WIDTH,
  STATUS_CELL_WIDTH,
} from '../../constants';
import {
  Button,
  DateAndTimePicker,
  IconButton,
  SelectInput,
} from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
import { SUBTASK_STATUSES_PROPERTIES_LIST } from 'lib/constants';
import { Cross, PencilWithLine } from 'lib/icons';
import moment from 'moment-timezone';
import theme from 'styles/theme';
import { AvatarWrap, SideBar, Status, Wrap } from './styles';
import { AvatarStyled } from 'components/SubtaskViewComponents/AvatarBlock/styles';
import { IChangelogLogItem, ISubtaskDto, PerformerTypeEnum } from 'types';

const PERCENT_BUTTONS_GROUP = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
];

interface IRowProps {
  item: IChangelogLogItem;
  subtask: ISubtaskDto;
  updateLogItems: () => void;
  isStandalone: boolean;
  isHistorical?: boolean;
  projectId?: string;
}

const Row = ({
  item,
  subtask,
  updateLogItems,
  isStandalone,
  projectId,
  isHistorical,
}: IRowProps) => {
  const updateLogItem = useActionsRoutines(updateSubtaskLogItemPromiseCreator);
  const updateProjectLogItem = useActions(updateSubtaskChangelog);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [createdAt, setCreatedAt] = useState(moment(item.createdAt));
  const [percent, setPercent] = useState(item.percent || 0);
  const isMobile = useMemo(() => Boolean(isMobileDevice.any()), []);
  const userType = useMemo(() => item?.performerType, [item]);
  const user = useMemo(() => {
    switch (userType) {
      case PerformerTypeEnum.user:
        return item?.user || { fullName: 'Manager', photoUrl: undefined };
      case PerformerTypeEnum.resource:
        return item?.resource;
      case PerformerTypeEnum.system:
        return { fullName: 'System', photoUrl: undefined };
      case PerformerTypeEnum.subcontractor:
        return { fullName: 'Subcontractor', photoUrl: undefined };
      default:
        return item?.resource;
    }
  }, [userType, item]);
  const { fullName, color, background } = getAvatarProps(user?.fullName);

  const onEditClick = useCallback(() => setIsEdit(true), []);

  const handleCancelClick = useCallback(() => {
    if (!isSaveLoading) {
      setIsEdit(false);
      setPercent(item.percent);
      setCreatedAt(moment(item.createdAt));
    }
  }, [item, isSaveLoading]);

  const onSaveClick = useCallback(() => {
    setIsSaveLoading(true);
    updateLogItem({
      id: subtask._id,
      projectId,
      isStandalone,
      logItemId: item._id,
      data: {
        createdAt,
        percent,
      },
    })
      .then(() => {
        updateLogItems();
        if (!isStandalone) {
          updateProjectLogItem({
            subtaskId: subtask?._id,
            logItemId: item._id,
            createdAt: createdAt.utc().format(),
            percent,
          });
        }
      })
      .catch(() => {
        setPercent(item.percent);
        setCreatedAt(moment(item.createdAt));
      })
      .finally(() => {
        setIsEdit(false);
        setIsSaveLoading(false);
      });
  }, [
    setIsSaveLoading,
    createdAt,
    percent,
    subtask,
    item,
    isStandalone,
    projectId,
    updateLogItem,
    updateLogItems,
    updateProjectLogItem,
  ]);

  const handlePercentChange = useCallback(
    val => {
      setPercent(val);
    },
    [setPercent]
  );

  const statusPreset = SUBTASK_STATUSES_PROPERTIES_LIST.find(
    status => status.value === item.status
  );
  const StatusIcon = (statusPreset as any)?.icon;

  const avatarStyles = {
    color,
    background,
  };

  return (
    <Wrap>
      <SideBar isEdit={isEdit}>
        {isEdit ? (
          <DateAndTimePicker
            value={moment(createdAt)}
            height={25}
            format="MM/DD/YY HH:mm A"
            width={isMobile ? 140 : undefined}
            setDate={setCreatedAt}
          />
        ) : (
          moment(item.createdAt).format('MM/DD/YY HH:mm a')
        )}
      </SideBar>
      <Cell width={STATUS_CELL_WIDTH}>
        <Status statusPreset={statusPreset}>
          {statusPreset.icon && <StatusIcon />}
          {item.status}
        </Status>
      </Cell>
      <Cell width={ACTION_CELL_WIDTH}>{item.action}</Cell>
      <Cell width={PERCENT_CELL_WIDTH}>
        {isEdit ? (
          <SelectInput
            options={PERCENT_BUTTONS_GROUP}
            value={percent}
            defaultValue={percent}
            width="70px"
            onChange={handlePercentChange}
          />
        ) : (
          item.percent
        )}
      </Cell>
      <Cell width={NAME_CELL_WIDTH}>
        <AvatarWrap>
          <AvatarStyled
            shape="square"
            size={26}
            // icon={avatarIcon}
            className="avatar"
            src={user?.photoUrl}
            style={avatarStyles}
          >
            {fullName}
          </AvatarStyled>
        </AvatarWrap>
        {user?.fullName}
      </Cell>

      <Cell flexEnd width={EDIT_CELL_WIDTH}>
        {!isHistorical &&
          (isEdit ? (
            <>
              <Button
                height="30px"
                label="Save"
                loading={isSaveLoading}
                onClick={onSaveClick}
              />
              <Cross
                style={{
                  cursor: isSaveLoading ? 'default' : 'pointer',
                  minWidth: '11px',
                  margin: '0 13px 0 12px',
                }}
                size={11}
                onClick={handleCancelClick}
              />
            </>
          ) : (
            <IconButton
              onClick={onEditClick}
              background={theme.colors.grayDark}
              icon={<PencilWithLine />}
              size={30}
            />
          ))}
      </Cell>
    </Wrap>
  );
};

export default memo(Row, isEqual);
