import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get conditions
export const conditions = createRoutine('depot/conditions');
export const conditionsPromiseCreator = promisifyRoutine(conditions);
// get condition
export const getConditionById = createRoutine('depot/getConditionById');
export const getConditionByIdPromiseCreator = promisifyRoutine(
  getConditionById
);
// add condition
export const addCondition = createRoutine('depot/addCondition');
export const addConditionPromiseCreator = promisifyRoutine(addCondition);
// edit condition
export const editCondition = createRoutine('depot/editCondition');
export const editConditionPromiseCreator = promisifyRoutine(editCondition);
// delete condition
export const deleteCondition = createRoutine('depot/deleteCondition');
export const deleteConditionPromiseCreator = promisifyRoutine(deleteCondition);
