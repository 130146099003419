interface IRemoveProps {
  className?: string;
  color?: string;
}
export const Remove = ({ color }: IRemoveProps) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8569 4.85713V12H2.14261V4.85713H7.8569ZM6.78547 0.571411H3.21404L2.49976 1.2857H-0.000244141V2.71427H9.99976V1.2857H7.49976L6.78547 0.571411ZM9.28547 3.42855H0.714042V12C0.714042 12.7857 1.3569 13.4286 2.14261 13.4286H7.8569C8.64261 13.4286 9.28547 12.7857 9.28547 12V3.42855Z"
        fill={color || '#FF3939'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect y="0.571411" width="10" height="12.8571" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
