import { css } from 'styled-components';

interface ICommonSelectStylesProps {
  width?: string;
  height?: string;
  muted?: boolean;
  defaultValue?: string;
  value?: string | number;
  error?: boolean;
  disabled?: boolean;
  selectwidth?: string;
}

export const commonSelectStyles = css<ICommonSelectStylesProps>`
  width: ${props => (props.width ? props.width : '280px')};
  height: ${props => (props.height ? props.height : '30px')};
  * {
    &::-webkit-scrollbar {
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.grayDark};
      border-radius: 23px;
      width: 357px;
    }
  }

  &.ant-select-multiple .ant-select-selector {
    flex-wrap: nowrap;
  }

  &.ant-select-multiple .ant-select-selection-item {
    line-height: 11px !important;
    height: 14px;
  }

  .ant-select-selection-overflow {
    align-items: center;
  }

  &&& .ant-select-selector:not(.ant-select-borderless) {
    width: ${props => (props.width ? props.width : '280px')};
    align-items: center;
    height: ${props => (props.height ? props.height : '30px')};
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    border-color: ${({ theme }) => theme.colors.mutedInputBorderColor};
    border-radius: 6px;
    box-shadow: none !important;
    font-size: 12px;
    &:hover {
      border-color: ${({ theme }) => theme.colors.lightBlue};
    }
    ${props =>
      props.muted &&
      css`
        border-color: ${({ theme }) => theme.colors.grayDark};
      `}
    ${props =>
      props.disabled &&
      css`
        border-color: ${({ theme }) => theme.colors.grayDark};
        background-color: ${({ theme }) => theme.colors.grayLight};
      `}
    ${props =>
      (props.defaultValue || props.value || props.value === 0) &&
      css`
        border-color: ${({ theme }) => theme.colors.mainDarkBlue};
      `}
    ${props =>
      props.error &&
      css`
        border-color: ${({ theme }) => theme.colors.danger};
        &:hover,
        &:focus {
          border-color: ${({ theme }) => theme.colors.danger};
        }
      `}
  }
  &.ant-select-focused:not(.ant-select-borderless) {
    outline: none;
    .ant-select-selector {
      border-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
    }
  }

  &&& .ant-select-selection-item {
    line-height: 28px;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 12px;
  }

  &&&.ant-select-disabled {
    .ant-select-selector {
      border-color: #d9d9d9;
    }
    .ant-select-selection-item {
      color: rgb(0 0 0 / 25%);
      border: none;
    }
    .ant-select-arrow {
      color: rgb(0 0 0 / 25%);
    }
  }
  &&& .ant-select-selection-placeholder {
    opacity: 1;
    font-weight: 500;
    line-height: 28px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.mutedInputTextColor};
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
`;
