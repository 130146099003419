import moment from 'moment';
import {
  GanttResourceDto,
  ISubtask,
  IWorkingTime,
  ResourceGroupDto,
  SubtaskStatusesEnum,
} from 'types';

export type WorkingTime = {
  from: { hours?: number; minutes?: number };
  to: { hours?: number; minutes?: number };
};

export interface IGanttPreparedItem {
  id: string | number;
  canChangeGroup?: boolean;
  canMove: boolean;
  canResize: boolean;
  changelog: IChangelog[];
  color: string;
  end_time: number;
  start_time: number;
  group: number | string;
  isCanMove: boolean;
  isDefault: boolean;
  needReview: boolean;
  groupData?: IGanttGroup;
  _id?: string;
  hasComments?: boolean;
  hasFiles?: boolean;
  hasImages?: boolean;
  hasNotes?: boolean;
  invoice?: string;
  isStandalone?: boolean;
  leftBorder?: boolean;
  percent?: number;
  plannedHours?: number;
  popover?: string;
  projectId?: string;
  rightBorder?: boolean;
  status?: SubtaskStatusesEnum;
  subtaskEnd?: moment.Moment;
  subtaskId?: string;
  subtaskName?: string;
  subtaskStart?: number;
  subtaskTypeId?: string;
  taskId: string;
  taskName?: string;
  templateHours?: number;
  title: string;
  type: string;
  workingTime?: WorkingTime;
}

export interface IChangelog {
  diff: number;
  status: string;
}

export interface IGanttGroup {
  email: string;
  firstName: string;
  group?: string;
  lastName: string;
  phoneNumber: string;
  photoUrl: string;
  shift: {
    isEnabled: boolean;
  };
  subtaskTypes: string[];
  subtasks: ISubtask[];
  workingTime: IWorkingTime;
}

export interface IGanttPreparedGroup {
  _id: string;
  id: string;
  title: string;
  stackItems: boolean;
  isParent: boolean;
  order: number;
  root: boolean;
  count: number;
  photoUrl: string;
  needReview: boolean;
  subtaskTypes: string[];
  workingTime: IWorkingTime;
  group?: ResourceGroupDto;
  shift: {
    isEnabled: boolean;
    isClockedIn?: boolean;
    transitionDate?: string;
    timeWorked?: number;
  };
  forSend?: {
    _id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
    email: string;
    phoneNumber: string;
  };
}

export enum IGanttItemPositionEnum {
  left = 'left',
  right = 'right',
}

export interface IGanttSettingProps {
  startDate: moment.Moment | Date;
  endDate: moment.Moment | Date;
  week: boolean;
}

export interface IGanttResource extends GanttResourceDto {
  group: ResourceGroupDto;
}
