import styled from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  display: flex;
  padding: 14px 40px 14px 16px;
  background: ${theme.colors.lightDanger};
  border-left: 2px solid ${theme.colors.danger};
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 17px;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const Title = styled.h3`
  margin-top: 4px;
  font-weight: 600;
  color: ${theme.colors.danger};
`;
