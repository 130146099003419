import styled, { css } from 'styled-components';

export const Heading = styled.div<{
  notAvailableProject?: boolean;
  isOpen?: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${({ notAvailableProject }) =>
    notAvailableProject ? '10px 17px' : '16px'};
`;

export const HeadingTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .drag-and-drop-icon {
    margin-bottom: -5px;
    margin-right: 4px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;

export const HeadingBottom = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

export const ArrowIconWrap = styled.span<{
  isOpen?: boolean;
}>`
  transition: all 0.2s;
  ${props =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
      margin-bottom: 13px;
    `};
`;

export const HeadingRightInner = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Title = styled.div<{ isOpenInfo?: boolean }>`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  max-width: 290px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 551px) {
    max-width: ${({ isOpenInfo }) => (isOpenInfo ? 180 : 260)}px;
  }
  @media (max-width: 350px) {
    max-width: ${({ isOpenInfo }) => (isOpenInfo ? 140 : 200)}px;
  }
`;
