import { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { ConflictSubtask } from './ConflictContainer';
import { PercentButton } from './PercentButton';
import { ButtonsGroupContainer, Wrap } from './styles';
import { IConflictSubtaskErorr } from 'types';

export const PERCENT_BUTTONS_GROUP = [
  { value: 0, text: '0%' },
  { value: 25, text: '25%' },
  { value: 50, text: '50%' },
  { value: 75, text: '75%' },
];

interface IPercentButtonsGroup {
  onChange: (percent: number) => void;
  conflictSubtask?: IConflictSubtaskErorr;
  defaultPercent?: number;
}

const PercentButtonsGroupComponent = ({
  onChange,
  conflictSubtask,
  defaultPercent,
}: IPercentButtonsGroup) => {
  const [selectedButon, setSelectedButton] = useState(0);
  const percent = conflictSubtask
    ? conflictSubtask?.percent || 0
    : defaultPercent || 0;

  useEffect(() => {
    const percent = conflictSubtask
      ? conflictSubtask?.percent || 0
      : defaultPercent || 0;
    setSelectedButton(percent);
    onChange(percent);
  }, [defaultPercent, conflictSubtask, onChange]);

  const handleClick = useCallback(
    value => {
      setSelectedButton(value);
      onChange(value);
    },
    [onChange]
  );
  return (
    <Wrap>
      <ConflictSubtask conflictSubtask={conflictSubtask} />
      <ButtonsGroupContainer>
        {PERCENT_BUTTONS_GROUP.map(item => (
          <PercentButton
            key={item.value}
            text={item.text}
            value={item.value}
            isActive={item?.value === selectedButon}
            isDisabled={item.value < percent}
            onClick={handleClick}
          />
        ))}
      </ButtonsGroupContainer>
    </Wrap>
  );
};

export const PercentButtonsGroup = memo(PercentButtonsGroupComponent, isEqual);
