import styled, { css } from 'styled-components';
import { TableHeaderColumnsEnum } from 'types';

export const TableHeaderWrap = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
`;

export const TableHeaderCol = styled.div<{
  colName: TableHeaderColumnsEnum;
}>`
  padding: 20px 0 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlue};
  ${props => {
    switch (props.colName) {
      case 'Date':
        return css`
          padding-left: 35px;
          padding-right: 45px;
          padding-bottom: 13px;
          width: 121px;
          border-right: 1px solid ${({ theme }) => theme.colors.grayDark};
        `;
      case 'Order #':
        return css`
          padding-left: 43px;
          padding-right: 111px;
        `;
      case 'Client':
        return css`
          padding-right: 190px;
        `;
      case 'Owner Email':
        return css`
          padding-right: 160px;
          white-space: nowrap;
        `;
      default:
        return css``;
    }
  }}
`;

export const DateFilterWrap = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DateFilterIconsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 9px;
  svg {
    &:first-child {
      margin-left: 1px;
    }
    &:last-child {
      margin-top: 1px;
      transform: rotate(180deg);
    }
  }
`;
