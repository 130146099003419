import { useCallback, useState } from 'react';
// redux
import {
  approveSubtaskPromiseCreator,
  rejectSubtaskPromiseCreator,
} from 'store/dashboard';
import ButtonGroup from './ButtonGroup';
// components
import Main from './Main';
import Reason from './Reason';
import Modal from 'components/Modal';
import { Loader } from 'components/common';
// utils
import { useActionsRoutines } from 'hooks';
// styles
import { LoadingWrap, ModalTitle, Wrap } from './styles';
import { ISubtaskForResult } from 'types';

interface ICheckResultProps {
  subtask?: ISubtaskForResult;
  results: any[];
  closeCheckResult: () => void;
  checkResultLoading?: boolean;
  isOpenCheckResult?: boolean;
}

const CheckResult = ({
  subtask,
  results,
  closeCheckResult,
  checkResultLoading,
  isOpenCheckResult,
}: ICheckResultProps) => {
  const [showReason, setShowReason] = useState(false);
  const [isLoadingSend, toggleIsLoadingSend] = useState(false);
  const [isImageLoading, setImageLoaded] = useState(0);
  const approve = useActionsRoutines(approveSubtaskPromiseCreator);
  const reject = useActionsRoutines(rejectSubtaskPromiseCreator);

  const closeModal = useCallback(() => {
    toggleIsLoadingSend(false);
    closeCheckResult();
    setShowReason(false);
    setImageLoaded(0);
  }, [toggleIsLoadingSend, setShowReason, closeCheckResult]);

  const setApprove = useCallback(() => {
    toggleIsLoadingSend(true);
    approve(subtask.id)
      .then(closeModal)
      .catch(() => toggleIsLoadingSend(false));
  }, [toggleIsLoadingSend, approve, closeModal, subtask]);

  const setReject = useCallback(
    data => {
      toggleIsLoadingSend(true);
      reject({ id: subtask.id, data })
        .then(closeModal)
        .catch(() => toggleIsLoadingSend(false));
    },
    [toggleIsLoadingSend, reject, closeModal, subtask]
  );

  return (
    <Modal
      visible={isOpenCheckResult}
      onCancel={closeModal}
      width={760}
      padding="31px 57px 40px"
      bodyStyle={{ textAlign: 'center' }}
    >
      {showReason ? (
        <Reason setReject={setReject} isLoadingSend={isLoadingSend} />
      ) : (
        <Wrap>
          <ModalTitle>{subtask.isDone ? 'Result' : 'Check result'}</ModalTitle>
          <LoadingWrap>
            <Loader loading={checkResultLoading} />
          </LoadingWrap>
          {!checkResultLoading && (
            <Main
              results={results}
              isImageLoading={isImageLoading}
              setImageLoaded={setImageLoaded}
              isLoading={isImageLoading !== results.length}
            />
          )}
          {!subtask.isDone && (
            <ButtonGroup
              approve={setApprove}
              reject={setShowReason}
              isLoadingSend={isLoadingSend}
              disabled={checkResultLoading || isImageLoading !== results.length}
            />
          )}
        </Wrap>
      )}
    </Modal>
  );
};

export default CheckResult;
