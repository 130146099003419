import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { SEARCH_INPUT_TYPES } from './constants';
import { SearchIcon } from 'lib/icons';
import { InputProps } from 'antd';
import { InputStyled } from './styles';

interface ISearchInputProps extends InputProps {
  placeholder?: string;
  name?: string;
  value?: string;
  noPrefix?: boolean;
  type?: SEARCH_INPUT_TYPES;
  className?: string;
  stylestype?: string;
  fontSize?: string;
  defaultValue?: string;
  width?: string;
  height?: string;
}

const SearchInputComponent =
  ({ type, noPrefix, ...rest }: ISearchInputProps) => (
    <InputStyled
      {...{
        ...rest,
        type: 'text',
        stylestype: type,
        prefix: noPrefix ? null : <SearchIcon />,
      }}
    />
  );

SearchInputComponent.defaultProps = {
  placeholder: 'Search',
  type: SEARCH_INPUT_TYPES.PRIMARY,
  noPrefix: false,
};

export const SearchInput = memo(SearchInputComponent, isEqual);
