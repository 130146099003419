import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const getRecurringTasks = createRoutine('admin/getRecurringTasks');
export const getRecurringTasksPromiseCreator = promisifyRoutine(
  getRecurringTasks
);

export const postRecurringTask = createRoutine('admin/postRecurringTask');
export const postRecurringTaskPromiseCreator = promisifyRoutine(
  postRecurringTask
);

export const putRecurringTask = createRoutine('admin/putRecurringTask');
export const putRecurringTaskPromiseCreator = promisifyRoutine(
  putRecurringTask
);

export const deleteRecurringTask = createRoutine('admin/deleteRecurringTask');
export const deleteRecurringTaskPromiseCreator = promisifyRoutine(
  deleteRecurringTask
);

export const getRecurringTaskById = createRoutine('admin/getRecurringTaskById');
export const getRecurringTaskByIdPromiseCreator = promisifyRoutine(
  getRecurringTaskById
);
