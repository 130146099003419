import { SVGProps } from 'react';

export function AdminIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={17}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10411_8035)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6.028l-5.752-.496L8 .236 5.752 5.54 0 6.028l4.368 3.784-1.312 5.624L8 12.452l4.944 2.984-1.304-5.624L16 6.028zm-8 4.928l-3.008 1.816.8-3.424-2.656-2.304L6.64 6.74 8 3.516l1.368 3.232 3.504.304-2.656 2.304.8 3.424L8 10.956z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10411_8035">
          <path transform="translate(0 .236)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
