import { SVGProps } from 'react';

export function RemoveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_9289_19841)">
        <path
          d="M8 1.334a6.67 6.67 0 00-6.667 6.667A6.669 6.669 0 008 14.667a6.669 6.669 0 006.666-6.666A6.67 6.67 0 008 1.334zm3.333 7.333H4.666V7.334h6.667v1.333z"
          fill="#FF3939"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_9289_19841">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
