import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetConditions() {
  yield call(E.ensureGetConditions, { type: Actions.conditions.TRIGGER });
  yield takeLatest(Actions.conditions.TRIGGER, E.ensureGetConditions);
}

export function* watchAddCondition() {
  yield takeLatest(Actions.addCondition.TRIGGER, E.ensureAddCondition);
}

export function* watchEditeCondition() {
  yield takeLatest(Actions.editCondition.TRIGGER, E.ensureEditCondition);
}

export function* watchGetConditionById() {
  yield takeLatest(Actions.getConditionById.TRIGGER, E.ensureGetConditionById);
}

export function* watchDeleteCondition() {
  yield takeLatest(Actions.deleteCondition.TRIGGER, E.ensureDeleteCondition);
}
