import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react';
import { BUTTON_TYPES, Button } from 'components/common';
import { ExclamationMark } from 'lib/icons';
import theme from 'styles/theme';
import { Popover } from 'antd';
import * as Styled from './styles';

interface ConfirmationWarningPopoverProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  withPopover?: boolean;
  children: ReactNode;
}

export const ConfirmationWarningPopover: FC<
  ConfirmationWarningPopoverProps
> = ({ withPopover, onConfirm, onCancel, children }) => {
  const [open, setOpen] = useState(false);
  const handleCancel = useCallback(() => {
    if (withPopover) {
      setOpen(false);
    }
    onCancel?.();
  }, [withPopover, onCancel]);

  const handlePopoverConfirm = useCallback(() => {
    onConfirm?.();
    setOpen(false);
  }, [onConfirm]);

  const handleVisibleChange = useCallback((visible: boolean) => {
    setOpen(visible);
  }, []);

  const handleStopPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Styled.Container>
      {withPopover ? (
        <Popover
          visible={open}
          placement="bottomRight"
          onVisibleChange={handleVisibleChange}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          content={
            <Styled.Content>
              <Styled.Text>
                <div>
                  <ExclamationMark fill={String(theme.colors.primary)} />
                </div>
                <p>
                  You will lose all the data for resource in this location.
                  Proceed with the changes?
                </p>
              </Styled.Text>
              <Styled.ButtonsWrap>
                <Button
                  kind={BUTTON_TYPES.BORDERED}
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Styled.SubmitButton
                  kind={BUTTON_TYPES.DANGER}
                  onClick={handlePopoverConfirm}
                  label="Yes"
                />
              </Styled.ButtonsWrap>
            </Styled.Content>
          }
          trigger="click"
        >
          <span onClick={handleStopPropagation}>{children}</span>
        </Popover>
      ) : (
        children
      )}
    </Styled.Container>
  );
};
