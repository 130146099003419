import { memo, useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
// redux
import {
  deleteAttachmentsPromiseCreator,
  deleteProjectFilePromiseCreator,
  projectFilesLoaderSelector,
  putAttachmentsByIdPromiseCreator,
  putAttachmentsPromiseCreator,
} from 'store/dashboard';
import { validateUploadFile } from 'utils/helpers';
// components
import File from './File';
import { BUTTON_TYPES } from 'components/common';
// utils
import { useActionsRoutines } from 'hooks';
import { MAX_FILE_SIZE_MB, PROJECT_UPLOAD_FILE_TYPES } from 'lib/constants';
// icons
import { Upload } from 'lib/icons';
import ChevronDown from 'lib/icons/ChevronDown';
import theme from 'styles/theme';
import { Spin } from 'antd';
// styles
import {
  BrowseBox,
  ButtonStyled,
  FileWrap,
  FilesBlock,
  FilesInputWrap,
  IconWrapper,
  Title,
  Wrap,
} from './styles';
import { IAttachmentInfo, IFile } from 'types';

interface IProjectFilesProps {
  files?: Partial<IAttachmentInfo>[];
  setCoverFile?: (
    file: Partial<IAttachmentInfo>,
    isCover: boolean
  ) => Promise<void>;
  setCloseParent?: (close: boolean) => void;
  setDisabled?: (disabled: boolean) => void;
  setFiles?: (files: FormData[], values: FormData[]) => void;
  deleteFiles?: (file: FormData) => void;
  notAvailableProject?: boolean;
  projectId?: string;
  isDirectly?: boolean;
  isDrawer?: boolean;
  isClone?: boolean;
}

const ProjectFiles = ({
  isDrawer,
  files,
  setFiles,
  setCoverFile,
  deleteFiles,
  setCloseParent,
  setDisabled,
  notAvailableProject,
  projectId,
  isDirectly,
  isClone,
}: IProjectFilesProps) => {
  const putAttachments = useActionsRoutines(putAttachmentsPromiseCreator);
  const putAttachmentsById = useActionsRoutines(
    putAttachmentsByIdPromiseCreator
  );
  const deleteAttachments = useActionsRoutines(deleteAttachmentsPromiseCreator);
  const deleteFileDirectly = useActionsRoutines(
    deleteProjectFilePromiseCreator
  );
  const isProjectFilesLoading = useSelector(projectFilesLoaderSelector);

  const [isLoading, setLoading] = useState(false);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  const onSelectFile = useCallback(
    (files: IFile[]) => {
      const { allowedFiles } = validateUploadFile({
        files,
        allowedTypes: PROJECT_UPLOAD_FILE_TYPES,
        maxFileSize: MAX_FILE_SIZE_MB,
      });

      if (notAvailableProject || !allowedFiles.length) return;
      setIsDragEnter(false);
      setLoading(true);
      if (setDisabled) setDisabled(true);
      if (setCloseParent) setCloseParent(true);
      const formData = new FormData();

      allowedFiles.forEach(file => formData.append('files', file));

      const attachmentsPromise = projectId
        ? putAttachmentsById({ id: projectId, data: formData })
        : putAttachments(formData);

      attachmentsPromise
        .then(res => setFiles && setFiles(res, allowedFiles))
        .catch(err => console.error(err))
        .finally(() => {
          setLoading(false);
          if (setDisabled) setDisabled(false);
          if (setCloseParent) setCloseParent(false);
        });
    },
    [
      putAttachments,
      putAttachmentsById,
      setFiles,
      setLoading,
      setCloseParent,
      setDisabled,
      notAvailableProject,
      projectId,
    ]
  );

  const onDeleteFile = useCallback(
    file => {
      if (notAvailableProject) return;
      if (!isClone) {
        setLoading(true);
        if (setDisabled) setDisabled(true);

        const fileArray = [file.filePath];
        const action = isDirectly
          ? deleteFileDirectly({ id: projectId, fileArray })
          : deleteAttachments(fileArray);

        action
          .then(() => deleteFiles(file))
          .catch(err => console.error(err))
          .finally(() => {
            setLoading(false);
            if (setDisabled) setDisabled(false);
          });
      } else {
        deleteFiles(file);
      }
    },
    [
      deleteAttachments,
      setLoading,
      deleteFiles,
      isDirectly,
      projectId,
      deleteFileDirectly,
      setDisabled,
      notAvailableProject,
      isClone,
    ]
  );

  const onDragLeave = e => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragEnter(false);
  };

  const onDragEnter = e => {
    if (isDragEnter || notAvailableProject) return;
    if (e.dataTransfer.types.includes('Files')) {
      setIsDragEnter(true);
    }
  };

  const handleOnDrop = useCallback(
    acceptedFiles => onSelectFile(acceptedFiles),
    [onSelectFile]
  );

  const sortedFiles = useMemo(
    () => [...files].sort((a, b) => +b.isCover - +a.isCover),
    [files]
  );
  const itemsToShow = useMemo(
    () => (showMore ? sortedFiles : sortedFiles.slice(0, 3)),
    [sortedFiles, showMore]
  );

  return (
    <Wrap>
      {!!files?.length && <Title>Files ({files.length})</Title>}
      {!notAvailableProject && (
        <Dropzone onDrop={handleOnDrop}>
          {({ getRootProps, getInputProps }) => (
            <FilesInputWrap
              {...getRootProps()}
              showUploadList={false}
              multiple={true}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              isDragEnter={isDragEnter}
            >
              <input
                {...getInputProps()}
                accept={PROJECT_UPLOAD_FILE_TYPES.join(',')}
              />
              <Spin tip="Loading..." spinning={isLoading}>
                <Upload color={theme.colors.lightBlue} />
                Drop files to attach, or <BrowseBox> browse.</BrowseBox>
              </Spin>
            </FilesInputWrap>
          )}
        </Dropzone>
      )}
      <FilesBlock>
        {isProjectFilesLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Spin tip="Loading..." spinning={true}></Spin>
          </div>
        ) : (
          <FileWrap>
            {itemsToShow.map((file, index) => (
              <File
                isDrawer={isDrawer}
                key={index}
                setCoverFile={setCoverFile}
                onDeleteFile={onDeleteFile}
                file={file}
                notAvailableProject={notAvailableProject}
              />
            ))}
          </FileWrap>
        )}
        {files?.length > 3 && (
          <ButtonStyled
            width="106px"
            height="30px"
            icon={
              <IconWrapper showMore={showMore}>
                <ChevronDown />
              </IconWrapper>
            }
            onClick={handleShowMore}
            kind={BUTTON_TYPES.SECONDARY}
            label={showMore ? 'Show less' : 'Show more'}
            lineheight="0px"
            space="3px"
          />
        )}
      </FilesBlock>
    </Wrap>
  );
};

export default memo(ProjectFiles, isEqual);
