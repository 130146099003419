import { call, put } from 'redux-saga/effects';
import { actions } from 'store/loading';
// redux
import * as Actions from './actions';
// utils
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureGetRecurringTasksPayload,
  IEnsurePostRecurringTaskPayload,
  IEnsurePutRecurringTaskPayload,
} from 'types';

export function* ensureGetRecurringTasks({
  payload = {},
}: {
  payload?: IEnsureGetRecurringTasksPayload;
  type: typeof Actions.getRecurringTasks.TRIGGER;
}) {
  try {
    const locationId = payload.locationId || storageManager.getLocation();

    if (!payload.noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.recurringTasks.getRecurringTasks, {
      params: {
        ...payload.params,
        filter: { locationId },
      },
    });
    yield put(Actions.getRecurringTasks.success(data));
  } catch (err) {
    yield put(Actions.getRecurringTasks.failure(err));
  } finally {
    if (!payload.noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensurePostRecurringTask({
  payload = {},
}: {
  payload?: IEnsurePostRecurringTaskPayload;
  type: typeof Actions.postRecurringTask.TRIGGER;
}) {
  try {
    const { search, resourceId, data } = payload;
    const res = yield call(Api.recurringTasks.postRecurringTask, data);
    yield call(ensureGetRecurringTasks, {
      payload: {
        noLoader: true,
        params: { name: search, resourceId },
      },
      type: Actions.getRecurringTasks.TRIGGER,
    });
    yield put(Actions.postRecurringTask.success(res));
  } catch (err) {
    yield put(Actions.postRecurringTask.failure(err));
  }
}

export function* ensurePutRecurringTask({
  payload = {} as IEnsurePutRecurringTaskPayload,
}: {
  payload?: IEnsurePutRecurringTaskPayload;
  type: typeof Actions.putRecurringTask.TRIGGER;
}) {
  try {
    const { search, resourceId } = payload;
    const data = yield call(Api.recurringTasks.putRecurringTask, payload);
    yield call(ensureGetRecurringTasks, {
      payload: { noLoader: true, params: { name: search, resourceId } },
      type: Actions.getRecurringTasks.TRIGGER,
    });
    yield put(Actions.putRecurringTask.success(data));
  } catch (err) {
    yield put(Actions.putRecurringTask.failure(err));
  }
}

interface IEnsureDeleteRecurringTaskPayload {
  payload?: {
    search?: string;
    resourceId?: string;
    id?: string;
  };
}
export function* ensureDeleteRecurringTask({
  payload = {},
}: IEnsureDeleteRecurringTaskPayload = {}) {
  try {
    const { id, search, resourceId } = payload;
    const data = yield call(Api.recurringTasks.deleteRecurringTask, id);
    yield call(ensureGetRecurringTasks, {
      payload: {
        noLoader: true,
        params: { name: search, resourceId: resourceId },
      },
      type: Actions.getRecurringTasks.TRIGGER,
    });
    yield put(Actions.deleteRecurringTask.success(data));
  } catch (err) {
    yield put(Actions.deleteRecurringTask.failure(err));
  }
}

export function* ensureGetRecurringTaskById({
  payload,
}: {
  payload?: string;
  type: typeof Actions.getRecurringTaskById.TRIGGER;
}) {
  try {
    const data = payload
      ? yield call(Api.recurringTasks.getRecurringTaskById, payload)
      : {};
    yield put(Actions.getRecurringTaskById.success(data));
  } catch (err) {
    yield put(Actions.getRecurringTaskById.failure(err));
  }
}
