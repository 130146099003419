import styled from 'styled-components';
import theme from 'styles/theme';

export const Bottom = styled.div<{
  $withDnD?: boolean;
}>`
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ChatPopover {
    .ant-popover-arrow {
      right: 4px;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
  .MenuPopover {
    .ant-popover-inner {
      min-width: 170px;
    }
    .ant-popover-inner-content {
      padding: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  padding: ${({ $withDnD }) => ($withDnD ? '0 4px 0 6px' : '0 4px 0 10px')};
`;

export const InfoIconWrap = styled.span`
  margin-top: 7px;
  margin-right: 10px;
  cursor: pointer;
`;

export const MenuWrap = styled.div<{
  isMobile?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : '6px')};
  margin-top: 3px;
  margin-right: ${({ isMobile }) => (isMobile ? '2px' : '-3px')};
  cursor: pointer;
  /* width: 11px; */
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 0;
  cursor: pointer;
  align-items: center;
  padding: 16px 37px 16px 13px;
  & .ant-btn {
    padding: 0;
    height: auto;
    border: none;
    color: ${theme.colors.danger};
    background: none;
  }
  &:hover {
    background-color: ${theme.colors.grayLight};
  }
`;

export const MenuText = styled.span`
  padding-left: 9px;
`;

export const MenuPopover = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
`;

export const BottomLeft = styled.div`
  display: flex;
  gap: 5px;
`;

export const BottomRight = styled.div`
  display: flex;
  gap: 4px;
`;

export const RecurringInfo = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-size: 12px;
  line-height: 15px;
`;

export const ChecklistWrapper = styled.span`
  &&&&&& .ant-popover-inner-content {
    padding: 12px 16px;
  }
`;
