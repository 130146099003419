import { Fragment } from 'react';
import { PROJECTS_STATUSES_STYLES } from 'lib/constants';
// icons
import { Spin } from 'antd';
// styles
import {
  LoaderWrap,
  LoadingOutlinedStyled,
  Status,
  Title,
  Wrap,
} from './styles';
import { ProjectStatusesEnum } from 'types';

interface IStatusSelectProps {
  status: string;
  onChange: (value: string) => void;
  isLoading: boolean;
}
const StatusSelect = ({ status, onChange, isLoading }: IStatusSelectProps) => {
  const possibleStatuses = [
    ProjectStatusesEnum.ON_HOLD,
    ProjectStatusesEnum.IN_PROGRESS,
    ProjectStatusesEnum.WAITING_FOR_CTNR,
    ProjectStatusesEnum.WAITING_FOR_MATERIALS,
    ProjectStatusesEnum.WAITING_FOR_SALES_RE_WORK,
  ].filter(possibleStatus => possibleStatus !== status);

  return (
    <Wrap onMouseDown={e => e.preventDefault()}>
      <Title>Change status to:</Title>
      {!isLoading ? (
        possibleStatuses.map(status => {
          return (
            <Fragment key={status}>
              <Status
                color={PROJECTS_STATUSES_STYLES[status].color}
                background={PROJECTS_STATUSES_STYLES[status].background}
                onClick={() => onChange(status)}
              >
                {status}
              </Status>
            </Fragment>
          );
        })
      ) : (
        <LoaderWrap>
          <Spin indicator={<LoadingOutlinedStyled spin />} />
        </LoaderWrap>
      )}
    </Wrap>
  );
};

export default StatusSelect;
