import { ReactNode } from 'react';
import { LogoBig } from 'lib/icons';
import {
  BoxWrapper,
  Container,
  LogoItem,
  MessageWrapper,
  Wrapper,
} from './styles';

interface IAuthWrapperProps {
  children: ReactNode;
  isLarge?: boolean;
  message?: ReactNode;
}

const AuthWrapper = ({
  children,
  isLarge = false,
  message,
}: IAuthWrapperProps): JSX.Element => (
  <Wrapper>
    <Container isLarge={isLarge}>
      <LogoItem>
        <LogoBig />
      </LogoItem>
      {message && <MessageWrapper>{message}</MessageWrapper>}
      <BoxWrapper>{children}</BoxWrapper>
    </Container>
  </Wrapper>
);

export default AuthWrapper;
