import { prepareDuplicateSubtaskData } from './prepareDuplicateSubtaskData';
import { v4 as uuidv4 } from 'uuid';
import { IProjectTaskDto, TaskTypeEnum } from 'types';

export const prepareDuplicateTaskData = (task: IProjectTaskDto) => ({
  type: TaskTypeEnum.Common,
  cost: task.cost,
  description: task.description,
  name: task.name,
  price: task.price,
  sku: task.sku,
  subtasks:
    task.subtasks?.map(subtask => {
      const data = prepareDuplicateSubtaskData(subtask);

      return { ...data, id: uuidv4(), order: subtask.order };
    }) ?? [],
});

interface PrepareDuplicateTaskListProps {
  currentTask: IProjectTaskDto;
  amount: number;
}

export const prepareDuplicateTaskList = ({
  currentTask,
  amount,
}: PrepareDuplicateTaskListProps) => {
  const replaceTasksList = [];

  for (let i = 0; i < amount; i++) {
    const duplicateData = prepareDuplicateTaskData(currentTask);

    replaceTasksList.push({
      ...duplicateData,
      order: currentTask.order,
    });
  }

  return replaceTasksList;
};
