import styled from 'styled-components';

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingBlock = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`;

export const CloseWrapper = styled.div`
  margin-bottom: 11px;
`;
export const HeadingLeft = styled.div`
  max-width: 418px;
  width: 100%;
`;

export const HeadingRight = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 1;
`;

export const HeadInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeadingTop = styled.div`
  display: flex;
  justify-content: space-between;
`;
