import styled from 'styled-components';
import theme from 'styles/theme';

export const Wrap = styled.div``;

export const MenuWrap = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 11px;
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 0;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 9px 18px;
  width: 136px;
  &:hover {
    background-color: #f4f5f7;
  }
`;

export const MenuText = styled.span`
  padding-left: 9px;
`;

export const DangerText = styled(MenuText)`
  color: ${theme.colors.danger};
`;

export const MenuPopoverWrap = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
`;
