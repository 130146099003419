export const Duplicate = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33333 5.6665H5V7.6665H3V8.99984H5V10.9998H6.33333V8.99984H8.33333V7.6665H6.33333V5.6665Z"
      fill="#172B4D"
    />
    <path
      d="M1.66683 13.6667H9.66683C10.4022 13.6667 11.0002 13.0687 11.0002 12.3333V4.33333C11.0002 3.598 10.4022 3 9.66683 3H1.66683C0.931496 3 0.333496 3.598 0.333496 4.33333V12.3333C0.333496 13.0687 0.931496 13.6667 1.66683 13.6667ZM1.66683 4.33333H9.66683L9.66816 12.3333H1.66683V4.33333Z"
      fill="#172B4D"
    />
    <path
      d="M12.3335 0.333496H4.3335V1.66683H12.3335V9.66683H13.6668V1.66683C13.6668 0.931496 13.0688 0.333496 12.3335 0.333496Z"
      fill="#172B4D"
    />
  </svg>
);
