import styled, { css } from 'styled-components';

export const SubtaskWrap = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  margin-bottom: 6px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }

  .MenuPopover {
    .ant-popover-inner-content {
      padding: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      @keyframes blink {
        50% {
          border-color: ${theme.colors.danger};
        }
      }
      & {
        animation: blink 0.9s ease-in-out 5 alternate;
      }
    `}
`;

export const SignOfAttention = styled.div`
  top: -5.5px;
  left: -5.5px;
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: ${({ theme }) => theme.colors.danger};
  border: 3px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 50%;
`;

export const SubtaskInner = styled.div<{ withDnD?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Assigned = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  padding: 0;
  margin-bottom: 5px;
  & > span {
    font-weight: 600;
  }
`;

export const SubtaskRow = styled.div`
  display: flex;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  display: inline-flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-right: 10px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubtaskOrder = styled.div`
  width: 19px;
  height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  background: ${({ theme }) => theme.colors.grayLight};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 4px;
  margin-right: 7px;
`;

export const ActionIconsWrap = styled.div`
  display: flex;
  align-items: center;
`;
