import { CSSProperties, forwardRef, useMemo } from 'react';
// constants
import { BUTTON_TYPES } from './constants';
import { ButtonProps } from 'antd/lib/button';
// styles
import { ButtonStyled } from './styles';

export interface IButton extends ButtonProps {
  kind?: BUTTON_TYPES;
  label?: string;
  icon?: JSX.Element;
  height?: string;
  width?: string;
  space?: string;
  padding?: string;
  fontSize?: string;
  lineheight?: string;
  background?: string;
  onClick?: any;
  style?: CSSProperties;
  withdefaulticoncolor?: string;
}

export const Button = forwardRef((props: IButton, ref) => {
  const { label, type } = props;
  return useMemo(
    () => (
      // @ts-ignore
      <ButtonStyled ref={ref} {...props} type={type}>
        {label}
      </ButtonStyled>
    ),
    [props, ref, label, type]
  );
});

Button.defaultProps = {
  kind: BUTTON_TYPES.DEFAULT,
};
