export enum ScreenDistributionTypesEnum {
  HALF = 'half',
  FULL_GANTT = 'fullGantt',
  FULL_PROJECTS = 'fullProjects',
}

export enum SubtaskStatusesEnum {
  TO_DO = 'To do',
  IN_PROGRESS = 'In progress',
  ON_REVIEW = 'On review',
  DONE = 'Done',
  NOT_READY = 'Not ready',
  FAILED = 'Failed',
  RESET = 'Reset',
  PAUSED = 'Paused',
}

export interface ISubtaskDraftFilesBuffer {
  id: string;
  paths: string[];
}
