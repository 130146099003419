import { SVGProps } from 'react';

function ChevronDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.138 6.195L8 9.057l2.862-2.862.943.943L8 10.943 4.195 7.138l.943-.943z"
        fill="#172B4D"
      />
    </svg>
  );
}

export default ChevronDown;
