import { call, put } from 'redux-saga/effects';
import { actions } from 'store/loading';
// redux
import * as Actions from './actions';
import API from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureCreateEmailTemplatePayload,
  IEnsureGetEmailTemplatesPayload,
  IEnsureUpdateEmailTemplatePayload,
} from 'types';

export function* ensureGetEmailTemplates({
  payload = {},
}: {
  payload?: IEnsureGetEmailTemplatesPayload;
  type: typeof Actions.getEmailTemplates.TRIGGER;
}) {
  const locationId = storageManager.getLocation();
  const noLoader =
    typeof payload?.noLoader !== 'undefined'
      ? payload?.noLoader
      : !payload?.params;

  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(API.emailTemplates.getEmailTemplates, {
      ...payload,
      params: {
        ...payload?.params,
        filter: {
          $or: [{ locationId }, { locationId: { $exists: false } }],
        },
      },
    });
    yield put(Actions.getEmailTemplates.success(data));
  } catch (err) {
    yield put(Actions.getEmailTemplates.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetEmailTemplateById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getEmailTemplateById.TRIGGER;
}) {
  try {
    const data = yield call(API.emailTemplates.getEmailTemplateById, payload);
    yield put(Actions.getEmailTemplateById.success(data));
  } catch (err) {
    yield put(Actions.getEmailTemplateById.failure(err));
  }
}

export function* ensureCreateEmailTemplate({
  payload,
}: {
  payload: IEnsureCreateEmailTemplatePayload;
  type: typeof Actions.createEmailTemplate.TRIGGER;
}) {
  try {
    const data = yield call(
      API.emailTemplates.postEmailTemplate,
      payload?.body
    );
    yield call(ensureGetEmailTemplates, {
      type: typeof Actions.getEmailTemplates.TRIGGER,
    });
    yield put(Actions.createEmailTemplate.success(data));
  } catch (err) {
    yield put(Actions.createEmailTemplate.failure(err));
  }
}

export function* ensureUpdateEmailTemplate({
  payload,
}: {
  payload: IEnsureUpdateEmailTemplatePayload;
  type: typeof Actions.updateEmailTemplate.TRIGGER;
}) {
  try {
    const data = yield call(API.emailTemplates.updateEmailTemplate, {
      id: payload?.id,
      data: payload?.body,
    });
    yield call(ensureGetEmailTemplates, {
      type: typeof Actions.getEmailTemplates.TRIGGER,
    });
    yield put(Actions.updateEmailTemplate.success(data));
  } catch (err) {
    yield put(Actions.updateEmailTemplate.failure(err));
  }
}

export function* ensureDeleteEmailTemplate({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteEmailTemplate.TRIGGER;
}) {
  try {
    yield call(API.emailTemplates.deleteEmailTemplate, payload);
    yield put(Actions.deleteEmailTemplate.success(payload));
  } catch (err) {
    yield put(Actions.deleteEmailTemplate.failure(err));
  }
}
