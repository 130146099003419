import { CSSProperties, ChangeEvent } from 'react';

interface ICrossProps {
  style?: CSSProperties;
  onClick?: (e?: ChangeEvent) => void;
  color?: string;
  size?: number;
}
export const Cross = ({ style, onClick, color, size = 6 }: ICrossProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick as any}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646445 0.646445C0.84171 0.451185 1.15829 0.451185 1.35356 0.646445L3 2.2929L4.64645 0.646445C4.8417 0.451185 5.1583 0.451185 5.35355 0.646445C5.5488 0.84171 5.5488 1.15829 5.35355 1.35356L3.7071 3L5.35355 4.64645C5.5488 4.8417 5.5488 5.1583 5.35355 5.35355C5.1583 5.5488 4.8417 5.5488 4.64645 5.35355L3 3.7071L1.35356 5.35355C1.15829 5.5488 0.84171 5.5488 0.646445 5.35355C0.451185 5.1583 0.451185 4.8417 0.646445 4.64645L2.2929 3L0.646445 1.35356C0.451185 1.15829 0.451185 0.84171 0.646445 0.646445Z"
      fill={color || '#172B4D'}
    />
  </svg>
);
