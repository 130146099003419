import { memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import SetStatus from '../SetStatus';
import { PROJECTS_STATUSES, PROJECTS_STATUSES_STYLES } from 'lib/constants';
import CollapseIcon from 'lib/icons/CollapseIcon';
import { Popover } from 'antd';
import { DoubleArrowWrap, Status } from './styles';
import { IProjectResponseDto, ProjectStatusesEnum } from 'types';

interface ISetStatusPopoverProps {
  data: IProjectResponseDto;
  notAvailableProject: boolean;
}
const SetStatusPopoverComponent = ({
  data,
  notAvailableProject,
}: ISetStatusPopoverProps) => {
  const { _id, status } = data;

  const [isSetStatusVisible, setSetStatusVisible] = useState(false);

  const handleVisibleChange = useCallback(val => {
    setSetStatusVisible(val);
  }, []);

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  const popoverContent = (
    <SetStatus
      status={status}
      data={data}
      handleVisibleChange={handleVisibleChange}
    />
  );

  const statusPreset = PROJECTS_STATUSES_STYLES[status];

  const isDeleted = status === PROJECTS_STATUSES.DELETED;

  return (
    <Popover
      getPopupContainer={handleGetPopupContainer}
      placement="bottomRight"
      visible={isSetStatusVisible && !notAvailableProject && !isDeleted}
      onVisibleChange={handleVisibleChange}
      content={popoverContent}
      destroyTooltipOnHide
      trigger="click"
    >
      <Status status={data.status as ProjectStatusesEnum}>
        {data.status}
        <DoubleArrowWrap
          statusPreset={statusPreset}
          isOpen={isSetStatusVisible}
        >
          <CollapseIcon />
        </DoubleArrowWrap>
      </Status>
    </Popover>
  );
};

export const SetStatusPopover = memo(SetStatusPopoverComponent, isEqual);
