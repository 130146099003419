import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const depot = (r: IInitialState) => r.depot;
export const colorsSelector = createSelector(depot, r => r.colors);
export const sizesSelector = createSelector(depot, r => r.sizes);
export const sizeSelector = createSelector(depot, r => r.size);
export const conditionsSelector = createSelector(depot, r => r.conditions);
export const conditionSelector = createSelector(depot, r => r.condition);
export const gradesSelector = createSelector(depot, r => r.grades);
export const gradeSelector = createSelector(depot, r => r.grade);
export const companiesSelector = createSelector(depot, r => r.companies);
export const companySelector = createSelector(depot, r => r.company);
