import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import ContainerForm from 'components/ContainerForm';
import { ProjectConflictsModal } from 'components/ProjectConflictsModal';
import { ConfirmationPopover } from 'components/common';
// icons
import { Cross } from 'lib/icons';
import DeleteButton from 'pages/Dashboard/Project/common/DeleteButton';
import { ContainerSchema } from 'validation';
import { ContainerWrap, Heading, HeadingRight, Headingleft } from './styles';
// styles
import { ButtonSaveStyled } from 'pages/Dashboard/Project/ProjectView/styles';
import {
  ContainerOptionsDto,
  IProjectConflict,
  IProjectTaskDto,
  ISubtaskTypeDto,
  OnProjectUpdateSubmit,
} from 'types';

interface IEditContainerProps {
  item: IProjectTaskDto;
  index: number;
  removeTask: (
    index: number,
    setDeleteLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
  onSubmit: OnProjectUpdateSubmit;
  containerOptions: ContainerOptionsDto;
  setIsOpen?: (index: number) => void;
  subtaskTypes: ISubtaskTypeDto[];
  projectId: string;
  isNewContainer?: boolean;
}

const EditContainer = ({
  item,
  index,
  removeTask,
  onSubmit,
  containerOptions,
  setIsOpen,
  subtaskTypes,
  projectId,
  isNewContainer,
}: IEditContainerProps) => {
  const form = useForm<any>({
    resolver: yupResolver(ContainerSchema),
    defaultValues: item,
    shouldUnregister: true,
  });

  const [isWorkOrderContainer, setIsWorkOrderContainer] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [projectConflicts, setProjectConflicts] = useState<IProjectConflict[]>(
    []
  );

  const handleCancel = useCallback(() => setProjectConflicts([]), []);
  const handleContinue = useCallback(() => {
    setProjectConflicts([]);
    void form.handleSubmit(formData =>
      onSubmit(
        {
          formData,
          index,
          setIsSubmitLoading,
          isWorkOrderContainer,
          isNewProjectContainer: isNewContainer,
          skipContainerUniqueness: true,
          setProjectConflicts,
        }
      )
    )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, isNewContainer, isWorkOrderContainer, onSubmit]);
  const onRemoveClick = useCallback(() => {
    setDeleteLoading(true);
    removeTask(index, setDeleteLoading);
  }, [setDeleteLoading, removeTask, index]);
  const handleSubmitForm = useCallback((formData) => {
    const skipContainerUniqueness = formData?.num ?
      formData.num === item.container?.num :
      true;

    return onSubmit(
      {
        formData,
        index,
        setIsSubmitLoading,
        isWorkOrderContainer,
        isNewProjectContainer: isNewContainer,
        skipContainerUniqueness,
        setProjectConflicts,
      }
    );
  }, [
    index,
    isNewContainer,
    isWorkOrderContainer,
    item.container?.num,
    onSubmit,
  ]);

  return (
    <ContainerWrap>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <Heading>
            <Headingleft>Edit container</Headingleft>
            <HeadingRight>
              <DeleteButton
                title={'Are you sure you want to delete this container?'}
                remove={onRemoveClick}
                loading={isDeleteLoading}
              />
              <ButtonSaveStyled
                loading={isSubmitLoading}
                htmlType="submit"
                label="Save"
              />
              <ConfirmationPopover
                text="Are you sure you want to close edit?"
                onOk={setIsOpen}
                placement="bottomRight"
              >
                <div style={{ cursor: 'pointer' }}>
                  <Cross style={{ marginRight: 11 }} size={13} />
                </div>
              </ConfirmationPopover>
            </HeadingRight>
          </Heading>
          <ContainerForm
            setIsWorkOrderContainer={setIsWorkOrderContainer}
            isEdit={true}
            errors={form?.formState?.errors}
            item={item}
            isInProject
            subtaskTypes={subtaskTypes}
            containerOptions={containerOptions}
            projectId={projectId}
          />
        </form>
      </FormProvider>
      {!!projectConflicts.length && <ProjectConflictsModal
        open
        conflicts={projectConflicts}
        containerNumber={form.getValues('num')}
        onCancel={handleCancel}
        onContinue={handleContinue}
      />}
    </ContainerWrap>
  );
};

export default EditContainer;
