import TableBodyItem from './TableBodyItem';
import TableHeader from './TableHeader';
import Empty from 'components/Empty/EmptyComponent';
import { TableWrap, Wrap } from './styles';
import { IWorkOrderDto } from 'types';

interface ITableProps {
  deleteWorkOrder: (id: string) => Promise<void>;
  data: IWorkOrderDto[];
  isDateFilterUp: boolean;
  openDivideTasks: (id: string) => void;
  openCreateModal: (id: string) => void;
  onDateFilterChange: () => void;
}
const Table = ({
  deleteWorkOrder,
  data,
  isDateFilterUp,
  onDateFilterChange,
  openDivideTasks,
  openCreateModal,
}: ITableProps): JSX.Element => (
  <Wrap>
    <TableWrap>
      <TableHeader
        isDateFilterUp={isDateFilterUp}
        onDateFilterChange={onDateFilterChange}
      />
      <div>
        {data.length ? (
          data.map(item => (
            <TableBodyItem
              key={item._id}
              item={item}
              deleteWorkOrder={deleteWorkOrder}
              openDivideTasks={openDivideTasks}
              openCreateModal={openCreateModal}
            />
          ))
        ) : (
          <Empty noButton />
        )}
      </div>
    </TableWrap>
  </Wrap>
);

export default Table;
