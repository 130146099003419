import styled from 'styled-components';
// constants
import { DESKTOP_WIDTH_PX } from '../../constants';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  margin-bottom: 10px;
  height: 54px;
  width: 172px;
  padding: 5px 7px 4px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    margin-right: 8px;
    width: 249px;
  }
`;

export const PreviewWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 100%;
  margin-right: 4px;
`;

export const Preview = styled.img`
  height: 45px;
  max-width: 58px;
`;

export const FileName = styled.a`
  display: block;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 1px;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    max-width: 150px;
  }
`;
