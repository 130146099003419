import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { IValuesObject } from '../..';
import { Radio, Tooltip } from 'antd';
import { TableTitle } from '../styles';
// styles
import {
  RadioStyled,
  TableRow,
  TableRowLabel,
  TaskDescription,
  TaskSku,
} from './styles';
import { IWorkOrdersTasks } from 'types';

interface IItemProps {
  item: IWorkOrdersTasks;
  containerTasks: IWorkOrdersTasks[];
  values: IValuesObject;
  setValues: Dispatch<SetStateAction<IValuesObject>>;
}

const Item = ({
  item,
  containerTasks,
  values,
  setValues,
}: IItemProps): JSX.Element => {
  const [value, setValue] = useState();

  const onChange = useCallback(
    e => {
      setValue(e.target.value);
      setValues({ ...values, [item.mapId]: e.target.value });
    },
    [setValue, setValues, values, item]
  );

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );

  return (
    <TableRow key={item.mapId}>
      <TableRowLabel>
        <TableTitle>{item.name}</TableTitle>
        {item.sku && <TaskSku>SKU: {item.sku}</TaskSku>}
        <Tooltip
          getPopupContainer={handleGetPopupContainer}
          placement="topRight"
          title={item.description}
        >
          <TaskDescription>{item.description}</TaskDescription>
        </Tooltip>
      </TableRowLabel>
      <Radio.Group
        onChange={onChange}
        style={{ display: 'flex', backgroundColor: 'inherit' }}
        value={value}
      >
        {containerTasks.map(container => (
          <RadioStyled value={container.mapId} key={container.mapId} />
        ))}
      </Radio.Group>
    </TableRow>
  );
};

export default Item;
