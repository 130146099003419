import { SVGProps } from 'react';

const EmptyProjectsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={141}
    height={141}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_10576_21404)">
      <path
        d="M70.5 126.431c31.149 0 56.4-25.251 56.4-56.4 0-31.149-25.251-56.4-56.4-56.4-31.149 0-56.4 25.251-56.4 56.4 0 31.149 25.251 56.4 56.4 56.4z"
        fill="#DFE1E6"
      />
      <g filter="url(#prefix__filter0_d_10576_21404)">
        <rect
          x={41.83}
          y={29.872}
          width={58.532}
          height={72.864}
          rx={7.52}
          fill="#fff"
        />
      </g>
      <rect
        x={47}
        y={39.273}
        width={23.5}
        height={4.606}
        rx={2.303}
        fill="#F96302"
      />
      <rect
        x={47}
        y={52.338}
        width={47}
        height={4.606}
        rx={2.303}
        fill="#DFE1E6"
      />
      <rect
        x={47}
        y={65.404}
        width={47}
        height={4.606}
        rx={2.303}
        fill="#DFE1E6"
      />
      <rect
        x={47}
        y={78.471}
        width={47}
        height={4.606}
        rx={2.303}
        fill="#DFE1E6"
      />
      <rect
        x={47}
        y={91.536}
        width={47}
        height={4.606}
        rx={2.303}
        fill="#DFE1E6"
      />
      <g filter="url(#prefix__filter1_d_10576_21404)">
        <path
          d="M135.43 21.82h-28.237c-1.316 0-2.383 1.123-2.383 2.508v13.968c0 1.385 1.067 2.507 2.383 2.507h28.237c1.316 0 2.383-1.122 2.383-2.507V24.328c0-1.385-1.067-2.508-2.383-2.508z"
          fill="#fff"
        />
      </g>
      <path
        d="M111.86 34.31a2.82 2.82 0 100-5.64 2.82 2.82 0 000 5.64z"
        fill="#F96302"
      />
      <rect
        x={118.44}
        y={28.67}
        width={15.04}
        height={5.64}
        rx={2.82}
        fill="#DFE1E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.468 42.852a8.908 8.908 0 00-.261-2.027c-.59-2.389-3.221-3.922-6.021-4.283-2.8-.36-5.71.46-6.832 2.538-.642 1.188-.73 2.212-.468 3.075.26.858.88 1.57 1.721 2.125 2.345 1.545 6.45 1.86 8.324 1.214.866-.299 1.712-.66 2.537-1.072-.472 2.7-2.227 5.259-4.57 7.564-5.094 5.011-12.996 8.814-17.3 10.212a.461.461 0 00-.288.573.435.435 0 00.549.302c4.389-1.426 12.446-5.31 17.639-10.42 2.683-2.64 4.595-5.61 4.921-8.734 6.065-3.384 11.046-9.525 15.305-14.693a.472.472 0 00-.047-.646.426.426 0 00-.618.049c-4.085 4.956-8.826 10.852-14.591 14.223zm-.882.49a8.008 8.008 0 00-.229-2.29c-.51-2.067-2.854-3.29-5.279-3.602-1.485-.19-3.013-.033-4.212.526-.753.352-1.374.861-1.748 1.554-.49.91-.597 1.688-.396 2.347.202.665.702 1.199 1.353 1.627 2.137 1.41 5.875 1.708 7.58 1.12a20.625 20.625 0 002.931-1.281z"
        fill="#172B4D"
      />
      <circle cx={133.95} cy={86.481} r={2.82} fill="#DFE1E6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.44 105.936c.504-.189 1.034-.439 1.442-.803.485-.431.682-.986.815-1.571.17-.751.238-1.552.444-2.304.076-.279.223-.385.286-.432.16-.118.32-.15.472-.138.18.014.427.085.589.402a.834.834 0 01.073.209c.015.069.025.287.04.376.04.22.072.441.103.662.102.738.16 1.364.483 2.042.438.92.876 1.483 1.47 1.732.576.241 1.263.196 2.142.007.083-.022.166-.04.248-.055.387-.071.757.196.833.602.076.406-.17.802-.553.891-.08.018-.16.036-.238.052-1.187.309-2.56 1.413-3.36 2.38-.245.297-.606 1.131-.973 1.662-.272.392-.577.651-.833.742a.738.738 0 01-.864-.28.885.885 0 01-.142-.349 2.754 2.754 0 01-.014-.333c-.075-.271-.166-.536-.233-.81-.16-.653-.472-1.066-.844-1.612a2.945 2.945 0 00-1.267-1.089c-.071-.018-.645-.166-.848-.251a.922.922 0 01-.488-.445.893.893 0 01-.08-.496.82.82 0 01.269-.521.93.93 0 01.431-.216c.149-.032.543-.051.598-.054zm3.369-1.064c.026.062.055.125.085.188.64 1.348 1.358 2.1 2.229 2.465l.029.012a9.911 9.911 0 00-1.514 1.452c-.166.201-.385.618-.623 1.046-.216-.738-.569-1.26-1.012-1.913-.34-.498-.694-.873-1.13-1.179a4.99 4.99 0 00.942-.647c.469-.417.779-.9.994-1.424z"
        fill="#B9BFC9"
      />
    </g>
    <defs>
      <filter
        id="prefix__filter0_d_10576_21404"
        x={27.73}
        y={25.172}
        width={86.733}
        height={101.063}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={9.4} />
        <feGaussianBlur stdDeviation={7.05} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.570833 0 0 0 0 0.570833 0 0 0 0 0.570833 0 0 0 0.19 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10576_21404"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_10576_21404"
          result="shape"
        />
      </filter>
      <filter
        id="prefix__filter1_d_10576_21404"
        x={101.52}
        y={17.59}
        width={45.223}
        height={31.202}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={2.82} dy={1.88} />
        <feGaussianBlur stdDeviation={3.055} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10576_21404"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_10576_21404"
          result="shape"
        />
      </filter>
      <clipPath id="prefix__clip0_10576_21404">
        <path fill="#fff" d="M0 0h141v141H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyProjectsIcon;
