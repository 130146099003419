import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { resourcesListPromiseCreator } from 'store/admin/resources';
// redux
import {
  deleteSubtaskMessagesCreator,
  unassignSubtaskPromiseCreator,
} from 'store/dashboard';
import { getAvatarProps } from 'utils/helpers';
// component
import AssignModal from '../AssignModal';
// utils
import { useActionsRoutines } from 'hooks';
// icons
import { Link } from 'lib/icons';
import EmptyPersonIcon from 'lib/icons/EmptyPersonIcon';
import theme from 'styles/theme';
import { getProjectResourseAvatarUrl } from 'utils/images';
import { Popover, Tooltip } from 'antd';
// styles
import {
  AvatarStyled,
  AvatarWrap,
  ButtonItem,
  EmptyPersonWrapper,
  UserActionsPopover,
  UserActionsText,
} from './styles';
import { IAssignSubtaskData, ISubtaskDto, SubtaskStatusesEnum } from 'types';

interface IAvatarBlockProps {
  data?: ISubtaskDto;
  projectId?: string;
  locationId?: string;
  hasGeneratedApiKey?: boolean;
  notAvailableProject?: boolean;
  isRecurring?: boolean;
  isReassignment?: boolean;
  onAssignResource?: (
    data: IAssignSubtaskData,
    date?: moment.Moment
  ) => Promise<void>;
  minStartDate?: string;
}

const AvatarBlock = ({
  data,
  locationId,
  projectId,
  hasGeneratedApiKey,
  notAvailableProject,
  onAssignResource,
  isReassignment,
  minStartDate,
}: IAvatarBlockProps) => {
  const { fullName, color, background } = useMemo(
    () => getAvatarProps(data?.resource?.firstName),
    [data?.resource?.firstName]
  );
  const unassignSubtask = useActionsRoutines(unassignSubtaskPromiseCreator);
  const getResources = useActionsRoutines(resourcesListPromiseCreator);
  const deleteSubtaskMessages = useActionsRoutines(
    deleteSubtaskMessagesCreator
  );

  const [isOpenModal, toggleModal] = useState(false);
  const [unassignSubtasksLoading, setUnassignSubtasks] = useState(false);
  const [isLoadingResources, setResourcesLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [isAvatarHover, setAvatarHover] = useState(false);
  const [isUserActionsPopoverVisible, setIsUserActionsPopoverVisible] =
    useState(false);
  const [isStatusAllowToUnassign, setIsStatusAllowToUnassign] = useState(
    data?.status === SubtaskStatusesEnum.TO_DO ||
      data?.status === SubtaskStatusesEnum.NOT_READY
  );
  const [isStatusAllowToReassign, setIsStatusAllowToReassign] = useState(
    [
      SubtaskStatusesEnum.TO_DO,
      SubtaskStatusesEnum.NOT_READY,
      SubtaskStatusesEnum.PAUSED,
      SubtaskStatusesEnum.FAILED,
    ].includes(data?.status)
  );
  const [allowToUnassign, setAllowToUnassign] = useState(
    isStatusAllowToUnassign && !notAvailableProject
  );
  const isAllowedToUnassignReassign = useMemo(
    () => allowToUnassign || isStatusAllowToReassign,
    [allowToUnassign, isStatusAllowToReassign]
  );

  useEffect(() => {
    setIsStatusAllowToUnassign(
      data?.status === SubtaskStatusesEnum.TO_DO ||
        data?.status === SubtaskStatusesEnum.NOT_READY
    );
    setIsStatusAllowToReassign(
      [
        SubtaskStatusesEnum.TO_DO,
        SubtaskStatusesEnum.NOT_READY,
        SubtaskStatusesEnum.PAUSED,
        SubtaskStatusesEnum.FAILED,
      ].includes(data?.status)
    );
  }, [data.status]);

  useEffect(() => {
    setAllowToUnassign(isStatusAllowToUnassign && !notAvailableProject);
  }, [isStatusAllowToUnassign, notAvailableProject]);

  const closeModal = useCallback(() => {
    toggleModal(false);
    setResourcesLoading(false);
    setResources([]);
  }, [toggleModal, setResources]);
  const avatarUrl = useMemo(
    () => getProjectResourseAvatarUrl(data?.resource?.photoUrl),
    [data?.resource?.photoUrl]
  );

  const avatarHandlerClick = useCallback(() => {
    if (
      data?.resource?._id ||
      !allowToUnassign ||
      hasGeneratedApiKey ||
      notAvailableProject
    ) {
      return;
    }
    toggleModal(true);
    setResourcesLoading(true);
    getResources({
      locationId,
      [`subtaskTypes._id`]: data?.subtaskTypeId,
      isActive: true,
    })
      .then(res => setResources(res))
      .catch(err => console.error(err))
      .finally(() => setResourcesLoading(false));
  }, [
    data?.resource?._id,
    data?.subtaskTypeId,
    allowToUnassign,
    hasGeneratedApiKey,
    getResources,
    locationId,
    notAvailableProject,
  ]);

  const unassignSubtasks = useCallback(() => {
    setUnassignSubtasks(true);
    unassignSubtask(data._id)
      .then(async () =>
        deleteSubtaskMessages(data._id).catch(err => console.error(err))
      )
      .then(() => {
        setIsUserActionsPopoverVisible(false);
        setAvatarHover(false);
      })
      .catch(err => console.error(err))
      .finally(() => setUnassignSubtasks(false));
  }, [unassignSubtask, data, deleteSubtaskMessages]);

  const avatarIcon = useMemo(() => {
    if (hasGeneratedApiKey) {
      return <Link width="14px" height="12px" color={theme.colors.white} />;
    } else {
      return null;
    }
  }, [hasGeneratedApiKey]);

  const avatarStyles = {
    color,
    background: hasGeneratedApiKey ? theme.colors.lightBlue : background,
  };

  const handleGetPopupContainer = useCallback(
    triggerNode => triggerNode.parentNode,
    []
  );
  const handleDoubleClick = useCallback(e => e.stopPropagation(), []);
  const handleMouseLeave = useCallback(() => setAvatarHover(false), []);
  const handleMouseEnter = useCallback(
    () => !!data?.resource?._id && setAvatarHover(true),
    [data?.resource?._id]
  );

  const userActionsPopover = (
    <UserActionsPopover onClick={e => e.stopPropagation()}>
      {allowToUnassign && (
        <ButtonItem
          loading={unassignSubtasksLoading}
          onClick={unassignSubtasks}
        >
          <UserActionsText isUnassign>Unassign resource</UserActionsText>
        </ButtonItem>
      )}
    </UserActionsPopover>
  );

  const handleUserActionsPopoverVisibleChange = useCallback(
    val => {
      if (
        isAllowedToUnassignReassign &&
        ((data?.resource?._id && val) || !val)
      ) {
        setAvatarHover(false);
        setIsUserActionsPopoverVisible(val);
      }
    },
    [data, isAllowedToUnassignReassign]
  );

  return (
    <AvatarWrap
      onDoubleClick={handleDoubleClick}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <Popover
        getPopupContainer={handleGetPopupContainer}
        content={userActionsPopover}
        destroyTooltipOnHide
        overlayClassName="UserActionsPopover"
        trigger="click"
        placement="left"
        onVisibleChange={handleUserActionsPopoverVisibleChange}
        visible={isUserActionsPopoverVisible && !isReassignment}
      >
        {data?.resource?._id || hasGeneratedApiKey ? (
          <Tooltip
            getPopupContainer={handleGetPopupContainer}
            placement="top"
            visible={isAvatarHover && !isUserActionsPopoverVisible}
            title={
              data?.resource?._id &&
              `${data?.resource?.firstName} ${data?.resource?.lastName}`
            }
          >
            <AvatarStyled
              shape="circle"
              isactive={Boolean(isAllowedToUnassignReassign).toString()}
              size={28}
              icon={avatarIcon}
              src={!!avatarUrl.length && avatarUrl}
              onClick={avatarHandlerClick}
              style={avatarStyles}
            >
              {fullName}
            </AvatarStyled>
          </Tooltip>
        ) : (
          <EmptyPersonWrapper onClick={avatarHandlerClick}>
            <EmptyPersonIcon />
          </EmptyPersonWrapper>
        )}
      </Popover>

      <AssignModal
        currentResourceId={data.resource?._id}
        isRecurring={false}
        closeModal={closeModal}
        isOpenModal={isOpenModal}
        getResources={getResources}
        locationId={locationId}
        subtaskTypeId={data?.subtaskTypeId}
        setResources={setResources}
        isLoadingResources={isLoadingResources}
        resources={resources}
        projectId={projectId}
        _id={data?._id}
        onAssignResource={onAssignResource}
        minStartDate={minStartDate}
      />
    </AvatarWrap>
  );
};

export default memo(AvatarBlock, isEqual);
