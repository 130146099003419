import { SVGProps } from 'react';

function UploadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 5.033A6.242 6.242 0 0010 0a6.246 6.246 0 00-5.542 3.367A4.995 4.995 0 000 8.333c0 2.759 2.242 5 5 5h10.833c2.3 0 4.167-1.866 4.167-4.166 0-2.2-1.708-3.984-3.875-4.134zm-.292 6.634H5a3.332 3.332 0 01-3.333-3.334 3.311 3.311 0 012.966-3.308l.892-.092.417-.791A4.557 4.557 0 0110 1.667a4.586 4.586 0 014.492 3.691l.25 1.25 1.275.092a2.483 2.483 0 012.316 2.467c0 1.375-1.125 2.5-2.5 2.5zM6.667 7.5h2.125V10h2.416V7.5h2.125L10 4.167 6.667 7.5z"
        fill="#737F94"
      />
    </svg>
  );
}

export default UploadIcon;
