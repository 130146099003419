import { memo, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { partialProjectUpdatePromiseCreator } from 'store/dashboard';
import {
  BUTTON_TYPES,
  Button,
  ConfirmationPopover,
  IconButton,
} from 'components/common';
import { useRequest } from 'hooks/useRequest';
import { PARTIAL_PROJECT_UPDATE_TYPES } from 'lib/constants';
import { Archive } from 'lib/icons';

interface IArchiveButton {
  id: string;
  callback?: () => void;
  title?: string;
}

const ArchiveProjectButton = ({
  id,
  callback,
  title,
}: IArchiveButton): JSX.Element => {
  const [archiveProject, archiveLoading] = useRequest(
    partialProjectUpdatePromiseCreator
  );

  const handleArchive = useCallback(async () => {
    try {
      await archiveProject({ id, type: PARTIAL_PROJECT_UPDATE_TYPES.ARCHIVE });

      if (callback) callback();
    } catch (error) {
      console.error(error);
    }
  }, [archiveProject, callback, id]);

  return (
    <ConfirmationPopover
      loading={archiveLoading}
      text={`Are you sure you want to archive this project?`}
      onOk={handleArchive}
      destroyTooltipOnHide
    >
      {title ? (
        <Button
          onClick={null}
          kind={BUTTON_TYPES.PRIMARY}
          icon={<Archive />}
          label={title}
          fontSize="13px"
          height="30px"
          lineheight="0px"
          space="2px"
        />
      ) : (
        <IconButton
          title={`Archive project`}
          onClick={null}
          icon={<Archive />}
        />
      )}
    </ConfirmationPopover>
  );
};

export default memo(ArchiveProjectButton, isEqual);
