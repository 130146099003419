import theme from 'styles/theme';
import { ContainerTaskStatusesEnum } from 'types';

export const COMMON_TASK_STATUSES = {
  DONE: 'Done',
  TO_DO: 'To do',
};

export const TASK_STATUSES = {
  TO_DO: 'To do',
  DONE: 'Done',
  IN_PROGRESS: 'In progress',
};

export const CONTAINER_TAGS_PRESETS = [
  {
    label: 'Not grounded',
    color: theme.colors.textGreyDark,
    statuses: [ContainerTaskStatusesEnum.TO_DO],
  },
  {
    label: 'Requested',
    color: theme.colors.lightDeepBlue,
    statuses: [
      ContainerTaskStatusesEnum.REQUESTED,
      ContainerTaskStatusesEnum.REQUESTED_FOR_TAKE_OUT,
    ],
  },
  {
    label: 'Grounded',
    color: theme.colors.eucalyptus,
    statuses: [
      ContainerTaskStatusesEnum.DELIVERED,
      ContainerTaskStatusesEnum.DONE,
    ],
  },
];
