import styled from 'styled-components';
import theme from 'styles/theme';
import { Button } from 'antd';

export const ButtonItem = styled(Button)`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  height: auto;
  color: ${theme.colors.lightBlue};
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    background: transparent;
  }

  &:hover {
    color: ${theme.colors.mainDarkBlue};
  }
`;
