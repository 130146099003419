import styled, { css } from 'styled-components';

export const Status = styled.div<{
  statusPreset?: any;
  $notAvailableProject?: boolean;
  $isPaused: boolean;
  $disabled?: boolean;
}>`
  cursor: ${({ $isPaused }) => ($isPaused ? 'default' : 'pointer')};
  height: 28px;
  padding: 0 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-around;
  ${({ statusPreset, $disabled }) => css`
    color: ${$disabled ? '#BFBFBF' : statusPreset.color};
    background-color: ${$disabled ? '#F1F1F1' : statusPreset.background};
    border: ${statusPreset.border};
  `}
  ${({ $notAvailableProject }) =>
    $notAvailableProject &&
    css`
      cursor: unset;
    `};
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

export const DoubleArrowWrap = styled.div<{
  statusPreset?: any;
  isOpen?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
  svg {
    scale: 0.8;
    ${({ statusPreset, disabled }) => css`
      fill: ${disabled ? '#BFBFBF' : statusPreset.color};
    `}
  }
`;
