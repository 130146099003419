import styled from 'styled-components';
// styles
import { commonSelectStyles } from 'components/common/commonSelectStyles';
// components
import { AutoComplete } from 'antd';

export const AutoCompleteStyled = styled(AutoComplete)<{
  selectwidth?: string;
  width?: string;
}>`
  ${commonSelectStyles};
  align-items: center;
  padding: 0;
  line-height: 30px;

  .autocomplete {
    width: ${props => props.selectwidth || props.width || '280px'} !important;
    padding: 11px 0;
    border-radius: 4px;
  }

  .autocomplete .ant-select-item-option-content {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0;
  }

  .autocomplete .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }

  .autocomplete .ant-select-item {
    padding: 10px;
  }

  &.ant-select > .ant-select-selector > .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.mutedInputTextColor};
    font-weight: 500;
    font-size: 12px;
  }

  & > .ant-select-selection-search-input {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 10px;
  }
`;
