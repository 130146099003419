import { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16357 1.54261L6.96874 0.354492L0.330078 6.99987L6.97546 13.6453L8.16357 12.4571L2.7063 6.99987L8.16357 1.54261Z"
      fill="#172B4D"
    />
  </svg>
);
