import { call, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetRecurringTasks() {
  yield call(E.ensureGetRecurringTasks, {
    type: Actions.getRecurringTasks.TRIGGER,
  });
  yield takeLatest(
    Actions.getRecurringTasks.TRIGGER,
    E.ensureGetRecurringTasks
  );
}

export function* watchPostRecurringTask() {
  yield takeLatest(
    Actions.postRecurringTask.TRIGGER,
    E.ensurePostRecurringTask
  );
}

export function* watchPutRecurringTask() {
  yield takeLatest(Actions.putRecurringTask.TRIGGER, E.ensurePutRecurringTask);
}

export function* watchDeleteRecurringTask() {
  yield takeLatest(
    Actions.deleteRecurringTask.TRIGGER,
    E.ensureDeleteRecurringTask
  );
}

export function* watchGetRecurringTaskById() {
  yield takeLatest(
    Actions.getRecurringTaskById.TRIGGER,
    E.ensureGetRecurringTaskById
  );
}
