// components
import File from './File';
// styles
import { Caption, Wrap } from './styles';
import { ProjectFileUploadedDto } from 'types';

interface IFilesProps {
  files: ProjectFileUploadedDto[];
}

const Files = ({ files }: IFilesProps) => {
  if (!files || !files.length) return null;

  return (
    <Wrap>
      <Caption>Files</Caption>
      {files.map((file, index) => (
        <File file={file} key={index} />
      ))}
    </Wrap>
  );
};

export default Files;
