import { SVGProps } from 'react';

function MenuVerticalIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.334 8c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.333 0 .734.6 1.334 1.334 1.334.733 0 1.333-.6 1.333-1.334zm4 0c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.333 0 .734.6 1.334 1.334 1.334.733 0 1.333-.6 1.333-1.334zm-8 0c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.333 0 .734.6 1.334 1.334 1.334.733 0 1.333-.6 1.333-1.334z"
        fill="#172B4D"
      />
    </svg>
  );
}

export default MenuVerticalIcon;
