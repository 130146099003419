import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
// redux
import { useSelector } from 'react-redux';
import { administratorsPromiseCreator } from 'store/company';
import {
  partialProjectUpdatePromiseCreator,
  projectSelector,
} from 'store/dashboard';
import Preview from 'components/PreviewAttachment';
import CheckResult from 'components/SubtaskViewComponents/CheckResult';
import { Button } from 'components/common/Button';
// hooks
import { useActionsRoutines } from 'hooks';
import {
  PARTIAL_PROJECT_UPDATE_TYPES,
  PROJECTS_STATUSES,
} from 'lib/constants/projects';
// components
import Header from 'pages/Dashboard/Project/ProjectView/Header';
import Tasks from 'pages/Dashboard/Project/ProjectView/Tasks';
// styles
import { Wrap } from './styles';
import { ISubtaskForResult, TaskTypeEnum } from 'types';

interface IProjectViewProps {
  openEdit?: (isClone: boolean) => void;
  setCloneCount: (amount: number) => void;
  setCloseParent?: Dispatch<SetStateAction<boolean>>;
  toggleParent?: (visible: boolean) => void;
  closeModal?: () => void;
  shouldUpdate?: boolean;
  isDrawer?: boolean;
}
const ProjectView = ({
  openEdit,
  setCloneCount,
  toggleParent,
  isDrawer,
  closeModal,
}: IProjectViewProps) => {
  const data = useSelector(projectSelector);
  const partialProjectUpdate = useActionsRoutines(
    partialProjectUpdatePromiseCreator
  );

  const { files, _id, status, isArchived } = data;
  const getAdministrators = useActionsRoutines(administratorsPromiseCreator);

  useEffect(() => {
    getAdministrators().catch(e => console.error(e));
  }, [getAdministrators]);

  useEffect(() => {
    if (!window.location.search.includes('projectId')) {
      window.history.replaceState(null, null, `/dashboard?projectId=${_id}`);
    }
    if (window.location.search) {
      return () => {
        window.history.replaceState(null, null, '/dashboard');
      };
    }
  }, [_id]);

  const [showPreview, setShowPreview] = useState(false);
  const [isDisabledDelete, setDisabledDelete] = useState(false);
  const [markProjectAsReadyLoading, setMarkProjectAsReadyLoading] =
    useState(false);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [isOpenCheckResult, setOpenCheckResult] = useState(false);
  const [subtaskForResult, setSubtaskIdForResult] = useState<ISubtaskForResult>(
    {} as ISubtaskForResult
  );
  const [checkResultLoading, toggleCheckResult] = useState(false);
  const [isRestoreProjectLoading, setRestoreProjectLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const cover = useMemo(() => files?.find(item => item.isCover), [files]);
  const notAvailableProject = useMemo(
    () => [PROJECTS_STATUSES.DELETED].includes(status),
    [status]
  );
  const commonTasks = useMemo(
    () => data.tasks.filter(item => item.type === TaskTypeEnum.Common),
    [data.tasks]
  );

  const closePreview = useCallback(
    e => {
      e.stopPropagation();
      setShowPreview(false);
    },
    [setShowPreview]
  );

  const openPreview = useCallback(
    e => {
      e.stopPropagation();
      setShowPreview(true);
    },
    [setShowPreview]
  );

  useEffect(() => {
    setDisabledDelete(commonTasks.length < 2);
  }, [commonTasks.length, shouldUpdate]);

  const markAsReady = useCallback(() => {
    setMarkProjectAsReadyLoading(true);
    partialProjectUpdate({ id: _id, type: PARTIAL_PROJECT_UPDATE_TYPES.DONE })
      .then(() => (isDrawer ? toggleParent(false) : closeModal()))
      .catch(() => setMarkProjectAsReadyLoading(false));
  }, [
    setMarkProjectAsReadyLoading,
    partialProjectUpdate,
    _id,
    isDrawer,
    toggleParent,
    closeModal,
  ]);

  const handleCloseProject = useCallback(() => {
    if (isDrawer) toggleParent(false);
    else closeModal();
  }, [isDrawer, toggleParent, closeModal]);

  const restoreProject = useCallback(async () => {
    try {
      setRestoreProjectLoading(true);
      await partialProjectUpdate({
        id: _id,
        type: PARTIAL_PROJECT_UPDATE_TYPES.RESTORE,
      });
      handleCloseProject();
    } catch (error) {
      console.error(error);
    } finally {
      setRestoreProjectLoading(false);
    }
  }, [partialProjectUpdate, setRestoreProjectLoading, _id, handleCloseProject]);

  const deleteProject = useCallback(() => {
    setDeleteProjectLoading(true);
    partialProjectUpdate({ id: _id, type: PARTIAL_PROJECT_UPDATE_TYPES.DELETE })
      .then(() => (isDrawer ? toggleParent(false) : closeModal()))
      .catch(() => setDeleteProjectLoading(false));
  }, [
    setDeleteProjectLoading,
    partialProjectUpdate,
    _id,
    isDrawer,
    toggleParent,
    closeModal,
  ]);

  const UnArchiveProject = useCallback(() => {
    setArchiveLoading(true);
    partialProjectUpdate({
      id: _id,
      type: PARTIAL_PROJECT_UPDATE_TYPES.UNARCHIVE,
    })
      .then(handleCloseProject)
      .finally(() => setArchiveLoading(false));
  }, [_id, partialProjectUpdate, handleCloseProject]);

  const closeCheckResult = useCallback(() => setOpenCheckResult(false), []);

  return (
    <Wrap isDrawer={isDrawer}>
      <CheckResult
        subtask={subtaskForResult}
        results={results}
        closeCheckResult={closeCheckResult}
        checkResultLoading={checkResultLoading}
        isOpenCheckResult={isOpenCheckResult}
      />
      <Preview
        showPreview={showPreview}
        closePreview={closePreview}
        file={cover || undefined}
      />
      <Header
        data={data}
        openEdit={openEdit}
        setCloneCount={setCloneCount}
        deleteProject={deleteProject}
        deleteProjectLoading={deleteProjectLoading}
        markProjectAsReadyLoading={markProjectAsReadyLoading}
        isRestoreProjectLoading={isRestoreProjectLoading}
        markAsReady={markAsReady}
        restoreProject={restoreProject}
        openPreview={openPreview}
        notAvailableProject={notAvailableProject}
        closeModal={handleCloseProject}
        toggleParent={toggleParent}
        isDrawer={isDrawer}
      />
      <Tasks
        editFilesData={files}
        setShouldUpdate={setShouldUpdate}
        data={data}
        setSubtaskIdForResult={setSubtaskIdForResult}
        toggleCheckResult={toggleCheckResult}
        setOpenCheckResult={setOpenCheckResult}
        setResults={setResults}
        closeCheckResult={closeCheckResult}
        isDisabledDelete={isDisabledDelete}
        toggleParent={toggleParent}
        notAvailableProject={notAvailableProject}
      />

      {isArchived && (
        <Button
          label="Unarchive"
          height="55px"
          style={{ marginTop: 10 }}
          loading={archiveLoading}
          onClick={UnArchiveProject}
        />
      )}
    </Wrap>
  );
};

ProjectView.defaultProps = {
  shouldUpdate: false,
};

export default memo(ProjectView, isEqual);
