import { useCallback, useEffect, useState } from 'react';
import { subtaskLogItemsPromiseCreator } from 'store/dashboard';
import Table from './Table';
import { Loader } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { ActualTableWrap, SpinItem, TableWrap, Title } from './styles';
import { ISubtaskDto } from 'types';

interface ISubtaskChangelogFormProps {
  subtask: ISubtaskDto;
  projectId?: string;
  isStandalone?: boolean;
}

export const SubtaskChangelogForm = ({
  subtask,
  projectId,
  isStandalone,
}: ISubtaskChangelogFormProps) => {
  const [subtaskActualLogItems, setSubtaskActualLogItems] = useState([]);
  const [subtaskHistoricalLogItems, setSubtaskHistoricalLogItems] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState(true);
  const getSubtaskLogItems = useActionsRoutines(subtaskLogItemsPromiseCreator);

  const itemsSortByNewest = (a, b) => {
    if (a?._id < b?._id) return 1;
    if (a?._id > b?._id) return -1;
    return 0;
  };
  const itemsSortByOldest = (a, b) => {
    if (a?._id > b?._id) return 1;
    if (a?._id < b?._id) return -1;
    return 0;
  };

  const updateLogItemsList = useCallback(
    newSort => {
      const sortBy = newSort ? itemsSortByOldest : itemsSortByNewest;
      setSubtaskActualLogItems(subtaskActualLogItems.sort(sortBy));
      setSubtaskHistoricalLogItems(subtaskHistoricalLogItems.sort(sortBy));
    },
    [subtaskActualLogItems, subtaskHistoricalLogItems]
  );

  const fetchLogItems = useCallback(() => {
    setIsLoading(true);
    getSubtaskLogItems({
      id: subtask._id,
      sort: 'createdAt',
    })
      .then(val => {
        setSubtaskActualLogItems(
          val.actual?.sort(sort ? itemsSortByOldest : itemsSortByNewest)
        );
        setSubtaskHistoricalLogItems(
          val.historical?.sort(sort ? itemsSortByOldest : itemsSortByNewest)
        );
      })
      .finally(() => setIsLoading(false));
  }, [subtask._id, sort, getSubtaskLogItems]);

  const handleToggleSort = useCallback(() => {
    setSort(!sort);
    updateLogItemsList(!sort);
  }, [sort, updateLogItemsList]);

  useEffect(() => {
    fetchLogItems();
  }, [fetchLogItems]);

  return (
    <TableWrap>
      <Title>
        #{subtask?.order} {subtask?.name} changelog
      </Title>
      <SpinItem spinning={isLoading} indicator={<Loader loading={true} />}>
        <ActualTableWrap>
          <Table
            data={subtaskActualLogItems}
            subtask={subtask}
            updateLogItems={fetchLogItems}
            toggleSort={handleToggleSort}
            sortDirection={sort}
            isStandalone={isStandalone}
            projectId={projectId}
          ></Table>
        </ActualTableWrap>

        {subtaskHistoricalLogItems?.length ? (
          <Table
            data={subtaskHistoricalLogItems}
            subtask={subtask}
            updateLogItems={fetchLogItems}
            toggleSort={handleToggleSort}
            sortDirection={sort}
            isStandalone={isStandalone}
            isHistorical
          ></Table>
        ) : null}
      </SpinItem>
    </TableWrap>
  );
};
