import * as yup from 'yup';

const REGEX_ZOHO_URL = /^(https?:\/\/)?([\w-]+\.)?zoho\.com(\/.*)?$/;

const reportSchema = yup.object().shape({
  title: yup.string().trim().required('Title is required!'),
  url: yup
    .string()
    .trim()
    .required('Url is required!')
    .matches(
      REGEX_ZOHO_URL,
      'Not a valid Zoho URL. Please check the URL and try again.'
    ),
});

export default reportSchema;
