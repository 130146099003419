export enum BUTTON_TYPES {
  PRIMARY = 'primary',
  DANGER = 'danger',
  DANGER_ACTIVE = 'dangerActive',
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  LINK = 'link',
  ICON = 'icon',
  SETTINGS = 'settings',
  LOGOUT = 'logout',
  DANGER_ICON = 'dangerIcon',
  BORDERED = 'bordered',
  SAVE_ICON = 'saveIcon',
  REMOVE = 'remove',
  CANCEL = 'cancel',
  DELETE = 'delete',
  TEXT = 'text',
  ACTION = 'action',
}
