import { memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { downloadPdfPromiseCreator } from 'store/sharing';
// components
import Preview from 'components/PreviewAttachment';
import { useActionsRoutines } from 'hooks';
import { IMAGES_MIME_TYPES } from 'lib/constants';
// icons
import { Cross, PaperClip } from 'lib/icons';
// styles
import theme from 'styles/theme';
import { Wrap } from './styles';
import { ProjectFileUploadedDto } from 'types';

interface IFileProps {
  file: ProjectFileUploadedDto;
  removeFile: (filePath: string) => void;
}

const File = ({ file, removeFile }: IFileProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const { filePath: href } = file;
  const isImage = useMemo(
    () => IMAGES_MIME_TYPES.includes(file.mimetype),
    [file]
  );

  const onWrapClick = useCallback(() => {
    if (!isImage || !file.fileUrl) return;
    setIsPreviewOpen(true);
  }, [file.fileUrl, isImage]);

  const closePreview = useCallback(
    e => {
      e.stopPropagation();
      setIsPreviewOpen(false);
    },
    [setIsPreviewOpen]
  );

  const onRemoveClick = useCallback(
    e => {
      e.stopPropagation();
      removeFile(file.filePath);
    },
    [removeFile, file]
  );

  const handleClosePreview = useCallback(e => closePreview(e), [closePreview]);
  const handleDownloadPdf = useCallback(() => {
    if (href && !isImage) {
      downloadPdf({ data: href, originalName: file.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file.originalName, downloadPdf, isImage]);

  return (
    <Wrap onClick={onWrapClick}>
      <Preview
        showPreview={isPreviewOpen}
        closePreview={handleClosePreview}
        file={file}
      />
      <PaperClip />
      <a onClick={handleDownloadPdf}>{file.originalName}</a>
      <Cross
        onClick={onRemoveClick}
        style={{
          width: 9,
          height: 9,
          marginTop: 2,
          marginLeft: 3,
          cursor: 'pointer',
        }}
        color={theme.colors.lightBlue}
      />
    </Wrap>
  );
};

export default memo(File, isEqual);
