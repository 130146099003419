import { SVGProps } from 'react';

export const ArrowDownFilled = (props: SVGProps<SVGSVGElement> = {}) => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="#737F94"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.285022 0C0.22865 0.00012297 0.173578 0.0158155 0.126737 0.0451023C0.0798962 0.074389 0.0433812 0.115961 0.0217888 0.164584C0.000196339 0.213207 -0.00550833 0.266709 0.00539288 0.318353C0.0162941 0.369997 0.0433139 0.417476 0.0830508 0.454812L3.79831 3.92242C3.85184 3.97211 3.92425 4 3.99972 4C4.0752 4 4.14761 3.97211 4.20113 3.92242L7.91695 0.454812C7.95672 0.417444 7.98375 0.369918 7.99463 0.318225C8.00552 0.266533 7.99977 0.212989 7.9781 0.164344C7.95644 0.1157 7.91984 0.0741333 7.87292 0.0448861C7.82599 0.0156388 7.77084 2.0298e-05 7.71442 0H0.285022Z"
      fill="#737F94"
    />
  </svg>
);
