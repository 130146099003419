import { ReactNode, memo } from 'react';
import { Error } from '../Error';
// icons
import { SortBy } from 'lib/icons';
import { SelectProps } from 'antd';
// styles
import {
  IconWrap,
  Prefix,
  SelectStyled,
  SortBySelectWrap,
  StyledMenu,
} from './styles';

const { Option } = SelectStyled;

const PrefixWrap = () => (
  <Prefix>
    <IconWrap>
      <SortBy />
    </IconWrap>
    <span>Sort by:</span>
  </Prefix>
);

interface ISortBySelectProps extends SelectProps {
  placeholder?: string;
  name?: string;
  options: any[];
  label?: string | JSX.Element;
  width?: string;
  value?: string;
  withPrefix?: boolean;
  showSearch?: boolean;
  autoClearSearchValue?: boolean;
  isAllowCreate?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  error?: string;
  customPrefix?: ReactNode;
}

const SortBySelectComponent = ({
  options,
  withPrefix,
  customPrefix,
  error,
  ...rest
}: ISortBySelectProps) => (
  <>
    <SortBySelectWrap>
      {customPrefix}
      {withPrefix && <PrefixWrap />}
      <SelectStyled
        {...rest}
        defaultActiveFirstOption={false}
        dropdownRender={menu => <StyledMenu>{menu}</StyledMenu>}
        bordered={false}
        withprefix={withPrefix.toString()}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </SelectStyled>
    </SortBySelectWrap>
    <Error>{error}</Error>
  </>
);

SortBySelectComponent.defaultProps = {
  placeholder: 'Search',
  withPrefix: false,
};

export const SortBySelect = memo(SortBySelectComponent);
