import { useCallback, useState } from 'react';
import { useActionsRoutines } from 'hooks';

export const useRequest = function <T = any>(
  promiseCreator
): [(data?) => Promise<T>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const request = useActionsRoutines(promiseCreator);

  const handleRequest = useCallback(
    async (data?) => {
      try {
        setIsLoading(true);
        const response = await request(data);
        return response;
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [request]
  );

  return [handleRequest, isLoading];
};
