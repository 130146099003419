import { MutableRefObject } from 'react';
import styled from 'styled-components';
import { commonSelectStyles } from './../common/commonSelectStyles';
import theme from 'styles/theme';
import { AutoComplete } from 'antd';

export const ChecklistContainer = styled.div<{
  isSubtaskType?: boolean;
  ref?: MutableRefObject<boolean>;
}>`
  height: auto;
  width: 270px;
  padding: ${({ isSubtaskType }) => (isSubtaskType ? 0 : '4px 0')};

  & .ant-checkbox-inner {
    border-radius: 19px !important;
    height: 19px;
    width: 19px;
  }

  & #label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }

  &&&& .ant-select-selection-placeholder {
    color: ${theme.colors.lightBlue};
  }

  .ScrollbarsCustom-Track {
    background: none !important;
    width: 0px !important;
  }

  .ScrollbarsCustom-Wrapper {
    overflow: unset !important;
  }

  & button:focus {
    color: ${({ theme }) => theme.colors.lightDeepBlue}!important;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const BottomBorder = styled.div`
  border: 1px solid #f4f5f7;
`;

export const CheckboxContainer = styled.div`
  min-height: 41px;
  align-items: center;
  flex-direction: row;
  display: flex;
`;

export const CheckboxWrap = styled.div<{
  isEdit?: boolean;
  $disableCheckbox?: boolean;
}>`
  display: flex;
  align-items: ${({ isEdit }) => (isEdit ? 'center' : 'flex-start')};
  .ant-checkbox:hover .ant-checkbox-inner {
    border: 1px solid ${theme.colors.lightDeepBlue};
  }
  .ant-checkbox-checked:after {
    border: 1px solid ${theme.colors.lightDeepBlue};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    &:hover {
      border-color: ${theme.colors.lightDeepBlue};
    }
    background-color: ${theme.colors.lightDeepBlue};
    border-color: ${theme.colors.lightDeepBlue};
    &:after {
      left: 4px;
    }
  }

  &&&& .ant-select-selection-search {
    width: ${({ $disableCheckbox }) => ($disableCheckbox ? '210px' : '190px')};
  }
`;

export const PreviewWrap = styled.div``;
export const PreviewInner = styled.div``;

export const Title = styled.div<{
  $isChecked?: boolean;
  $disableCheckbox?: boolean;
}>`
  text-decoration: ${({ $isChecked }) =>
    $isChecked ? 'line-through' : 'inherit'};
  user-select: none;
  width: ${({ $disableCheckbox }) => ($disableCheckbox ? '230px' : '190px')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DragWrap = styled.div<{ isDragging?: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
`;

export const FormTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const AutoCompleteStyled = styled(AutoComplete)`
  ${commonSelectStyles};
  align-items: center;
  padding: 0;
  line-height: 30px;

  .autocomplete {
    width: ${props => props.selectwidth || props.width || '280px'} !important;
    padding: 11px 0;
    border-radius: 4px;
  }

  &.ant-select > .ant-select-selector {
    border: none;
    padding: 0;
  }

  & .ant-select-selection-search {
    display: flex;
    padding: 0;
    left: 0 !important;
    width: 210px;
    font-size: 14px !important;
  }

  .autocomplete .ant-select-item-option-content {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0;
  }

  .autocomplete .ant-select-item-option-active {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }

  .autocomplete .ant-select-item {
    padding: 10px;
  }

  &.ant-select > .ant-select-selector > .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.mutedInputTextColor};
    font-weight: 500;
    font-size: 12px;
  }

  & > .ant-select-selection-search-input {
    color: ${({ theme }) => theme.colors.mainDarkBlue};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 10px;
  }
`;

export const RemoveIconWrapper = styled.div`
  margin-left: 7px;
  cursor: pointer;
`;

export const ChecklistWrapper = styled.div`
  max-height: 169px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 9px;
  &&& .ant-select-selection-placeholder,
  &&& .ant-select-selection-search-input {
    padding: 0 5px;
  }
`;
