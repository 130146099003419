const IMAGE_SIZE = {
  GANTT_USER_AVATAR: '_18_18',
  PROJECT_FILE_PREVIEW: '_58_45',
  PROJECT_LIST_COVER_PREVIEW: '_198_137',
  PROJECT_COVER: '_1086_745',
  PROJECT_COVER_PREVIEW: '_187_85',
  RESOURSE_AVATAR: '_38_38',
  PROJECT_RESOURSE_AVATAR: '_38_38',
};

export const getResizedImage = (url, size) => (url ? `${url}${size}` : '');

export const getGanttAvatarUrl = url =>
  getResizedImage(url, IMAGE_SIZE.GANTT_USER_AVATAR);

export const getProjectListCoverPreviewUrl = url =>
  getResizedImage(url, IMAGE_SIZE.PROJECT_LIST_COVER_PREVIEW);

export const getProjectCoverUrl = url =>
  getResizedImage(url, IMAGE_SIZE.PROJECT_COVER);

export const getProjectCoverPreviewUrl = url =>
  getResizedImage(url, IMAGE_SIZE.PROJECT_COVER_PREVIEW);

export const getProjectFilePreviewUrl = url =>
  getResizedImage(url, IMAGE_SIZE.PROJECT_FILE_PREVIEW);

export const getResourseAvatarUrl = url =>
  getResizedImage(url, IMAGE_SIZE.RESOURSE_AVATAR);

export const getProjectResourseAvatarUrl = url =>
  getResizedImage(url, IMAGE_SIZE.PROJECT_RESOURSE_AVATAR);
