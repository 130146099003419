import { Tooltip } from 'antd';
import { ProgressItem } from './styles';

interface IProgressBarProps {
  tasks: any[];
}

const TasksProgressBar = ({ tasks }: IProgressBarProps): JSX.Element => {
  const { doneCount, inProgressCount, todoCount, tasksCount } = tasks.reduce(
    (acc, task) => ({
      ...acc,
      doneCount: +task.doneCount + +acc.doneCount,
      inProgressCount: +task.inProgressCount + +acc.inProgressCount,
      todoCount: +task.todoCount + +acc.todoCount,
      tasksCount: +task.subtasks.length + +acc.tasksCount,
    }),
    {
      doneCount: 0,
      inProgressCount: 0,
      todoCount: 0,
      tasksCount: 0,
    }
  );

  const totalDonePercent: number =
    Math.round((doneCount / tasksCount) * 100) || 0;
  const totalInProgressPercent =
    Math.round((inProgressCount / tasksCount) * 100) || 0;

  const percent = totalDonePercent + totalInProgressPercent;
  const ProgressText = `${totalDonePercent || 0}% Done`;

  return (
    <div>
      <Tooltip
        title={`${doneCount} done / ${inProgressCount} in progress / ${todoCount} to do`}
      >
        <span>
          <ProgressItem
            format={() => ProgressText}
            percent={percent}
            success={{ percent: totalDonePercent }}
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default TasksProgressBar;
