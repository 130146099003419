import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Divider, Layout } from 'antd';

export const SiderStyled = styled(Layout.Sider)`
  padding: 17px 0 33px;
  background: ${({ theme }) => theme.colors.mainDarkBlue};
  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const DividerItem = styled(Divider)`
  margin: 14px 0 24px;
  &&&&.ant-divider {
    border-top-color: #1c345f;
  }
`;

export const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 8px;
  padding: 0 12px;
  color: ${theme.colors.white};
  &:hover {
    color: ${theme.colors.white};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 30px;
  display: flex;
`;

export const Bottom = styled.div``;

export const DeveloperLink = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  margin-bottom: 49px;
  padding-left: 20px;
  color: ${theme.colors.white};
`;

export const DeveloperText = styled.span`
  margin-left: 9px;
  position: relative;
`;

export const NewTabIconWrapper = styled.div`
  position: absolute;
  right: -10px;
  bottom: -10px;
`;
