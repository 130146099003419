import styled from 'styled-components';
import { Layout } from 'antd';

export const HeaderStyled = styled(Layout.Header)`
  --grayDark: ${({ theme }) => theme.colors.grayDark};
  --white: ${({ theme }) => theme.colors.white};
  --mainDarkBlue: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grayDark);
  height: 40px;
  background: var(--white);
  padding: 0 10px 2px;
  width: 100%;
  overflow-y: visible;
  position: relative;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    flex-wrap: nowrap;
    width: 100%;
  }
  .left-col {
    display: flex;
    align-items: center;
    & > .logo-url {
      border-radius: 5px;
      min-height: 36px;
      min-width: 36px;
      background: transparent;
    }
    & > * {
      margin-right: 10px;
    }
    & > .logo {
      min-width: 143px;
    }
    & > .separator {
      min-width: 1px;
      max-width: 1px;
      width: 1px;
      min-height: 20px;
      max-height: 20px;
      height: 20px;
    }
    & > .search {
      height: 30px;
      width: 13vw;
      min-width: 13vw;
      max-width: 13vw;
      @media (max-width: 1200px) {
        width: 20vw;
        min-width: 20vw;
        max-width: 20vw;
      }
      @media (max-width: 600px) {
        width: 70vw;
        min-width: 70vw;
        max-width: 70vw;
      }
    }
  }
  .right-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* overflow: hidden; */
    & > .separator-right {
      min-width: 1px;
      max-width: 1px;
      width: 1px;
      height: 20px;
      min-height: 20px;
      max-height: 20px;
    }
    & > .margin {
      margin-left: 10px;
    }
    & > .avatar {
      display: flex;
      margin-top: 1px;
      align-items: center;
      justify-content: center;
      margin-left: 9px;
      min-width: 32px;
      min-height: 32px;
      background: transparent;
      cursor: pointer;
    }
  }
`;
