import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import { workOrder, workOrders, workOrdersInfo } from './actions';

const inbound = createReducer(initialState.inbound, {
  [workOrders.SUCCESS]: (state, action) => {
    state.workOrders = action.payload;
  },
  [workOrder.SUCCESS]: (state, action) => {
    state.workOrder = action.payload;
  },
  [workOrdersInfo.SUCCESS]: (state, action) => {
    state.workOrdersInfo = action.payload;
  },
});

export { inbound as reducer };
