export const sortTypes = [
  {
    value: 'dueDate',
    label: 'End date',
  },
  {
    value: 'startDate',
    label: 'Start date',
  },
  {
    value: 'withoutResource',
    label: 'No resource',
  },
  {
    value: 'weight',
    label: 'Relevant',
  },
];
