import { CSSProperties, memo } from 'react';
import { isEqual } from 'lodash';

interface IHasPhotoIconProps {
  style: CSSProperties;
  color: string;
}

const HasPhotoIcon = ({ style, color }: IHasPhotoIconProps) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8635 1.46504V10.4677H1.86092V1.46504H10.8635ZM10.8635 0.178955H1.86092C1.15357 0.178955 0.574829 0.757695 0.574829 1.46504V10.4677C0.574829 11.175 1.15357 11.7538 1.86092 11.7538H10.8635C11.5709 11.7538 12.1496 11.175 12.1496 10.4677V1.46504C12.1496 0.757695 11.5709 0.178955 10.8635 0.178955ZM7.73835 5.87633L5.80921 8.36491L4.4331 6.69943L2.50396 9.18158H10.2205L7.73835 5.87633Z"
      fill={color || 'white'}
    />
  </svg>
);

export const HasPhoto = memo(HasPhotoIcon, isEqual);
