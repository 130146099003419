import { FC, memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { projectSelector, putProjectPromiseCreator } from 'store/dashboard';
import { DuplicateButton } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { message } from 'utils/message';
import { prepareDuplicateTaskList } from 'utils/prepareDuplicateTaskData';
import { IProjectTaskDto } from 'types';

interface DuplicateProjectsTasksProps {
  task: IProjectTaskDto;
  tasksCount: number;
  handler?: (tasks: IProjectTaskDto[]) => void | Promise<void>;
  isProjectEditing?: boolean;
}

const DuplicateProjectsTasksComponent: FC<DuplicateProjectsTasksProps> = ({
  task,
  tasksCount,
  handler,
  isProjectEditing,
}) => {
  const editProject = useActionsRoutines(putProjectPromiseCreator);
  const project = useSelector(projectSelector, isEqual);
  const [isLoading, setIsLoading] = useState(false);

  const handleDuplicateTask = async (amount: number) => {
    try {
      setIsLoading(true);

      const newTasks = prepareDuplicateTaskList({
        amount,
        currentTask: task,
      });

      if (handler) {
        await handler(newTasks);
      } else {
        await editProject({
          id: project._id,
          data: {
            ...project,
            tasks: [...project.tasks, ...newTasks],
          },
        });
      }

      return message.success('Duplication completed');
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DuplicateButton
      isLoading={isLoading}
      count={tasksCount}
      onConfirm={handleDuplicateTask}
      title="Duplicate item"
      disabled={isProjectEditing}
      tooltipTitle={
        isProjectEditing && 'Cannot duplicate item while editing other item'
      }
    />
  );
};

export const DuplicateProjectsTasks = memo(
  DuplicateProjectsTasksComponent,
  isEqual
);
