import styled, { css } from 'styled-components';

export const ExpandWrap = styled.div<{
  isExpandedAll?: boolean;
}>`
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.lightBlue};
  svg {
    margin-top: 2px;
    margin-left: 4px;
    margin-bottom: -1px;
    ${({ isExpandedAll }) =>
      isExpandedAll &&
      css`
        margin-bottom: 0;
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: 4px;
      `}
  }
`;
