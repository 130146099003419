import styled, { css } from 'styled-components';
import { Button } from 'components/common';
import { Switch } from 'antd';

export const ButtonSaveWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 28px;
`;

export const Title = styled.div`
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const ButtonCloneStyled = styled(Button)`
  margin-left: 15px;
  background: ${({ theme }) => theme.colors.grayLight};
  padding: 0 11px;
  height: 26px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  svg {
    margin-right: 5px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const SwitchTextItem = styled.div<{ isActive?: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.mutedText};
  ${props =>
    props.isActive &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.mainDarkBlue};
    `}
`;

export const SwitchStyled = styled(Switch)`
  margin: 0 14px !important;
  background-image: none !important;
  background-color: ${({ theme }) => theme.colors.white} !important;
  border: 1px solid ${({ theme }) => theme.colors.mainDarkBlue} !important;
  & .ant-switch-handle {
    top: 50%;
    transform: translateY(-50%);
    &::before {
      background-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
      top: 0px;
    }
  }
`;

export const LocationSelectContainer = styled.div`
  margin-left: 10px;
`;

export const TooltipItem = styled.span`
  cursor: not-allowed;
`;
