import { call, put, select } from 'redux-saga/effects';
import {
  containerOptions,
  defaultContainerTemplate,
  ensureContainerOptions,
  ensureDefaultContainerTemplate,
  ensureGetSubtaskTypesDefaults,
  subtaskTypesDefaults,
} from 'store/admin';
import {
  currentLocation,
  ensureSignOut,
  getProfile,
  updateUser,
} from 'store/auth';
import * as Actions from 'store/company';
import { ganttDates, ganttWeekView } from 'store/dashboard';
import { ensureWorkOrdersInfo } from 'store/inbound';
import { actions } from 'store/loading';
import { ensureGetProfile } from 'store/profile/workers';
import { getStartEndWeek } from 'utils/helpers';
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  CompanyResponseDto,
  CreateCompanyBodyDto,
  CreateCompanyResponseDto,
  EmployeesValueDto,
  FileUploadResDto,
  PartialUpdateCompanyReqDto,
} from 'types';

interface IEnsureCompanyPayload {
  payload?: {
    noLoader?: boolean;
    search?: string;
  };
}

export function* ensureCompany({ payload }: IEnsureCompanyPayload = {}) {
  const company = yield select(Actions.companySelector);
  const noLoader = payload?.noLoader || (!payload?.noLoader && !!company);

  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data: CompanyResponseDto = yield call(Api.company.getCompany);

    yield put(Actions.getCompany.success(data));
  } catch (err) {
    yield put(Actions.getCompany.failure(err));
  } finally {
    yield put(actions.tabLoading(false));
  }
}

export function* ensurePatchCompany({
  payload,
}: {
  payload: PartialUpdateCompanyReqDto;
  type: typeof Actions.updateCompany.TRIGGER;
}) {
  try {
    const res: CompanyResponseDto = yield call(
      Api.company.patchCompany,
      payload
    );

    const { isContainersEnabled, isInboundWOEnabled } = payload;
    const { weekStartDay } = res;
    const { startDate, endDate } = getStartEndWeek(weekStartDay);
    yield put(Actions.patchCompany.success(res));
    yield put(ganttWeekView(true));
    yield put(ganttDates({ startDate, endDate, weekStartDay }));

    if (isContainersEnabled) {
      yield call(ensureContainerOptions);
      yield call(ensureGetSubtaskTypesDefaults);
      yield call(ensureDefaultContainerTemplate);
    }
    if (typeof isContainersEnabled !== 'undefined' && !isContainersEnabled) {
      yield put(subtaskTypesDefaults.success([]));
      yield put(defaultContainerTemplate.success({}));
      yield put(containerOptions.success({}));
    }

    if (isInboundWOEnabled) {
      yield call(ensureWorkOrdersInfo);
    }
  } catch (err) {
    yield put(Actions.patchCompany.failure(err));
  }
}

export function* ensureUpdateLogo({
  payload,
}: {
  payload: FormData;
  type: typeof Actions.uploadLogo.TRIGGER;
}) {
  try {
    const res: FileUploadResDto = yield call(Api.company.postLogo, payload);

    yield put(Actions.uploadLogo.success(res));
  } catch (err) {
    yield put(Actions.uploadLogo.failure(err));
  }
}

export function* ensureUploadFavicon({
  payload,
}: {
  payload: FormData;
  type: typeof Actions.uploadFavicon.TRIGGER;
}) {
  try {
    const data = yield call(Api.company.putFavicon, payload);
    yield put(Actions.uploadFavicon.success(data));
  } catch (err) {
    yield put(Actions.uploadFavicon.failure(err));
  }
}

export function* ensureDeleteCompany() {
  try {
    yield call(Api.company.delete);
    yield put(Actions.deleteCompany.success());
    yield ensureSignOut();
  } catch (err) {
    yield put(Actions.deleteCompany.failure(err));
  }
}

export function* ensureDeleteSuperAdmin({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteSuperAdmin.TRIGGER;
}) {
  try {
    const data = { transferToId: payload };
    yield call(Api.user.superAdmin.delete, data);

    yield put(Actions.deleteSuperAdmin.success());
    yield ensureSignOut();
  } catch (err) {
    yield put(Actions.deleteSuperAdmin.failure(err));
  }
}

export function* ensureTransferCompany({
  payload,
}: {
  payload: string;
  type: typeof Actions.transferCompany.TRIGGER;
}) {
  try {
    const data = { transferToId: payload };
    yield call(Api.user.superAdmin.transfer, data);

    yield ensureGetProfile({ type: typeof getProfile.TRIGGER });
    yield call(Actions.ensureAdministrators, {
      payload: { noLoader: true },
      type: typeof Actions.administrators.TRIGGER,
    });

    yield put(Actions.transferCompany.success());
  } catch (err) {
    yield put(Actions.transferCompany.failure(err));
  }
}

export function* ensureRestoreCompany() {
  try {
    yield call(Api.company.restore);
    yield put(Actions.restoreCompany.success());
  } catch (err) {
    yield put(Actions.restoreCompany.failure(err));
  }
}

export function* ensureRegisterNewCompany({
  payload,
}: {
  payload: CreateCompanyBodyDto;
  type: typeof Actions.registerNewCompany.TRIGGER;
}) {
  try {
    const res: CreateCompanyResponseDto = yield call(
      Api.company.registerNewCompany,
      payload
    );
    // @ts-ignore
    yield put(currentLocation(res.user.locationId));
    yield put(updateUser.success(res.user));
    storageManager.setLocation(res.user.locationId);

    yield put(Actions.registerNewCompany.success(res));
  } catch (err) {
    yield put(Actions.registerNewCompany.failure(err));
  }
}

export function* ensureGetEmployeesValues() {
  try {
    const data: EmployeesValueDto = yield call(Api.system.employeesValues);
    yield put(Actions.getEmployeesValues.success(data));
  } catch (err) {
    yield put(Actions.getEmployeesValues.failure(err));
  }
}
