import { Dispatch, SetStateAction, useRef } from 'react';
import Assign from './Assign';
// components
import Modal from 'components/Modal';
// utils
import { debounce } from 'lodash';
import { IAssignSubtaskData, ListResourceResponseDto } from 'types';

interface IGetResourcesProps {
  fullName?: string;
  locationId?: string;
  [`subtaskTypes._id`]: string;
}

interface IAssignModalProps {
  closeModal?: () => void;
  isOpenModal?: boolean;
  getResources?: (props: IGetResourcesProps) => Promise<any>;
  locationId?: string;
  subtaskTypeId?: string;
  setResources?: Dispatch<SetStateAction<ListResourceResponseDto[]>>;
  isLoadingResources?: boolean;
  resources?: ListResourceResponseDto[];
  isRecurring?: boolean;
  projectId?: string;
  _id?: string;
  onAssignResource?: (
    data: IAssignSubtaskData,
    date: moment.Moment
  ) => Promise<void>;
  minStartDate?: any;
  currentResourceId?: string;
}

const AssignModal = ({
  isRecurring,
  currentResourceId,
  closeModal,
  isOpenModal,
  getResources,
  locationId,
  subtaskTypeId,
  setResources,
  isLoadingResources,
  resources,
  projectId,
  _id,
  onAssignResource,
  minStartDate,
}: IAssignModalProps) => {
  const delayedQuery = useRef(
    debounce((val, locationId, subtaskTypeId) => {
      getResources({
        fullName: `/${val.replace(/[.*\-?^${}()|[\]\\]/g, '\\$&')}/gi`,
        locationId,
        [`subtaskTypes._id`]: subtaskTypeId,
      })
        .then((res: ListResourceResponseDto[]) => setResources(res))
        .catch(err => console.error(err));
    }, 500)
  ).current;

  const findResources = e => {
    delayedQuery(e.target.value, locationId, subtaskTypeId);
  };

  return (
    <Modal
      onCancel={closeModal as any}
      visible={isOpenModal}
      width="335px"
      padding="0 0 19px"
    >
      <Assign
        currentResourceId={currentResourceId}
        isRecurring={isRecurring}
        findResources={findResources}
        isLoadingResources={isLoadingResources}
        resources={resources}
        closeModal={closeModal}
        id={_id}
        projectId={projectId}
        onAssignResource={onAssignResource}
        minStartDate={minStartDate}
      />
    </Modal>
  );
};

export default AssignModal;
