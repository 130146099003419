import { call, put, select } from 'redux-saga/effects';
import * as Actions from 'store/timesheets';
import { prepareEndDate } from './helpers';
import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import Api from 'utils/api';
import storageManager from 'utils/storageManager';
import {
  IEnsureEditResourceTimePayload,
  IEnsureGetResourceTimesheetsExcelPayload,
  IEnsureResourceTimesheetsPayload,
  IResourceTimesheetsExcelOptions,
  ITimesheetsExcelOptions,
  ITimesheetsParams,
} from 'types';

export function* ensureTimesheets() {
  try {
    const locationId = storageManager.getLocation();
    const search = yield select(Actions.searchSelector);
    const dates = yield select(Actions.timesheetsDatesSelector);
    if (dates) {
      const { startDate, endDate } = dates;
      const params = {
        search,
        locationId,
        startDate: startDate.toDate(),
        endDate: prepareEndDate(endDate),
      };
      const { groups } = yield call(Api.timesheets.getTimesheets, { params });
      yield put(Actions.timesheets.success(groups));
    }
  } catch (err) {
    yield put(Actions.timesheets.failure(err));
  }
}

export function* ensureResourceTimesheets({
  payload,
}: {
  payload: IEnsureResourceTimesheetsPayload;
  type: typeof Actions.resourceTimesheets.TRIGGER;
}) {
  try {
    const { id, dates } = payload;
    const { startDate, endDate } = dates;
    const params = {
      startDate: moment(startDate).toDate(),
      endDate: prepareEndDate(endDate),
      locationId: storageManager.getLocation(),
    } as AxiosRequestConfig<ITimesheetsParams>;
    const { list } = yield call(Api.timesheets.getResourceTimesheets, {
      id,
      params,
    });
    yield put(Actions.resourceTimesheets.success(list));
  } catch (err) {
    yield put(Actions.resourceTimesheets.failure(err));
  }
}

export function* ensureEditResourceTime({
  payload,
}: {
  payload: IEnsureEditResourceTimePayload;
  type: typeof Actions.editResourceTime.TRIGGER;
}) {
  try {
    const data = yield call(Api.timesheets.editResourceTime, payload);
    yield call(ensureTimesheets);
    yield put(Actions.editResourceTime.success(data));
  } catch (err) {
    yield put(Actions.editResourceTime.failure(err));
  }
}

export function* ensureDeleteShift({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteShift.TRIGGER;
}) {
  try {
    yield call(Api.timesheets.deleteShift, payload);
    yield call(ensureTimesheets);
    yield put(Actions.deleteShift.success(payload));
  } catch (err) {
    yield put(Actions.deleteShift.failure(err));
  }
}

export function* ensureGetShiftClosestDates({
  payload,
}: {
  payload: string;
  type: typeof Actions.getShiftClosestDates.TRIGGER;
}) {
  try {
    const locationId = storageManager.getLocation();
    const params = {
      locationId,
    } as AxiosRequestConfig;

    const data = yield call(Api.timesheets.getShiftClosestDates, {
      id: payload,
      params,
    });
    yield call(ensureTimesheets);
    yield put(Actions.getShiftClosestDates.success(data));
  } catch (err) {
    yield put(Actions.getShiftClosestDates.failure(err));
  }
}

export function* ensureGetTimesheetsExcel() {
  try {
    const locationId = storageManager.getLocation();
    const search = yield select(Actions.searchSelector);
    const dates = yield select(Actions.timesheetsDatesSelector);
    const { startDate, endDate } = dates;
    const options = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
      params: {
        search,
        locationId,
        startDate: startDate.toDate(),
        endDate: prepareEndDate(endDate),
      },
    } as AxiosRequestConfig<ITimesheetsExcelOptions>;
    const data = yield call(Api.timesheets.getTimesheetsExcel, options);
    yield put(Actions.getTimesheetsExcel.success(data));
  } catch (err) {
    yield put(Actions.getTimesheetsExcel.failure(err));
  }
}

export function* ensureGetResourceTimesheetsExcel({
  payload,
}: {
  payload: IEnsureGetResourceTimesheetsExcelPayload;
  type: typeof Actions.getResourceTimesheetsExcel.TRIGGER;
}) {
  try {
    const { startDate, endDate } = payload.dates;
    const options = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
      params: {
        startDate: moment(startDate).toDate(),
        endDate: prepareEndDate(endDate),
        locationId: storageManager.getLocation(),
      },
    } as AxiosRequestConfig<IResourceTimesheetsExcelOptions>;
    const data = yield call(Api.timesheets.getResourceTimesheetsExcel, {
      id: payload.resourceId,
      options,
    });
    yield put(Actions.getResourceTimesheetsExcel.success(data));
  } catch (err) {
    yield put(Actions.getResourceTimesheetsExcel.failure(err));
  }
}
