import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const Wrapper = styled.div`
  .ant-tooltip-arrow-content {
    left: -1px;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid ${theme.colors.grayDark};
  border-radius: 50%;
  &:hover {
    border-color: ${theme.colors.mainDarkBlue};
  }
  & .ant-spin {
    display: flex;
  }
`;
export const OrangeDot = styled.div<{
  $isSeen: boolean;
}>`
  width: 0;
  height: 0;
  background-color: ${theme.colors.orange};
  border-radius: 50%;
  transition: all 0.3s ease;
  ${({ $isSeen }) =>
    !$isSeen &&
    css`
      width: 8px;
      height: 8px;
    `}
`;
