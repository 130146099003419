import { FC, useCallback, useState } from 'react';
import { markSubtaskAsPausedPromiseCreator } from 'store/subcontractors';
import { Button } from 'components/common';
import { PercentButtonsGroup } from 'components/common/PercentButtonsGroup';
import { useRequest } from 'hooks/useRequest';
import { SplitIcon } from 'lib/icons';
import theme from 'styles/theme';
import { Popover } from 'antd';
import * as Styled from './styles';

interface PauseSubtaskProps {
  id: string;
}

export const PauseSubtask: FC<PauseSubtaskProps> = ({ id }) => {
  const [percentage, setPercentage] = useState(0);
  const [pauseSubtask, isLoading] = useRequest(
    markSubtaskAsPausedPromiseCreator
  );

  const handlePauseSubtask = useCallback(() => {
    pauseSubtask({ id, percent: percentage }).catch(e => console.error(e));
  }, [id, percentage, pauseSubtask]);

  const handlePercentChange = useCallback(
    (percent: number) => setPercentage(percent),
    []
  );

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide
      content={
        <>
          <PercentButtonsGroup onChange={handlePercentChange} />
          <Styled.ButtonWrap>
            <Button
              loading={isLoading}
              width="137px"
              label="Submit"
              onClick={handlePauseSubtask}
            />
          </Styled.ButtonWrap>
        </>
      }
    >
      <Styled.Button
        icon={
          <SplitIcon width={16} height={16} fill={theme.colors.mainDarkBlue} />
        }
        label="Split"
        loading={isLoading}
      />
    </Popover>
  );
};
