import * as yup from 'yup';
import { itemName } from './baseSchema';

export const subtaskTypesSchema = yup.object().shape({
  name: itemName,
  defaultHours: yup
    .number()
    .min(0.01, 'Should be greater than 0')
    .max(999.99, 'Should be less than 999.99')
    .required('Duration is required!')
    .typeError('Duration is required!'),
});

export default subtaskTypesSchema;
