import styled, { css } from 'styled-components';
// components
import { TimePicker } from 'antd';

const { RangePicker } = TimePicker;

export const Wrap = styled.div`
  *::-webkit-scrollbar {
    display: none;
  }
  label {
    margin-right: 5px;
  }
`;

export const StyledRangePicker = styled(RangePicker)<{
  [key: string]: any;
}>`
  width: ${({ width }) => width || '205px'};
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-radius: 6px;
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }
  input {
    color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
    font-size: 12px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.lightBlue};
  }
  &.ant-picker-focused {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  .ant-picker-active-bar {
    background-color: ${({ theme }) => theme.colors.mainDarkBlue} !important;
  }
  ${props =>
    (props.defaultValue || props.value) &&
    css`
      border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    `};
`;
