import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPasswordPromiseCreator } from 'store/auth';
import { ROUTES } from 'store/router';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordRequirements from 'components/Auth/PasswordRequirements/PasswordRequirements';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button } from 'components/common';
import { InputPassword } from 'components/common/InputPassword/InputPassword';
import { useActionsRoutines } from 'hooks';
import { message } from 'utils/message';
import { ResetPasswordSchema } from 'validation';
import { Container, RequirementsWrapper, Text, Title } from './styles';

interface IResetPasswordInput {
  password: string;
  verifyPassword: string;
}

const RestorePassword = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const resetPassword = useActionsRoutines(resetPasswordPromiseCreator);
  const form = useForm<IResetPasswordInput>({
    mode: 'onBlur',
    resolver: yupResolver(ResetPasswordSchema),
    shouldUnregister: true,
  });
  if (!token) {
    history.push(ROUTES.FORGOT_PASSWORD);
  }
  const handleSubmit: SubmitHandler<IResetPasswordInput> = async ({
    password,
  }) => {
    try {
      await resetPassword({ password, token });
      await message.success('Password has been changed');
      setTimeout(() => {
        history.push(ROUTES.HOME);
      }, 1000);
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        history.push(ROUTES.FORGOT_PASSWORD);
      }, 1000);
    }
  };

  const {
    control,
    formState: { errors },
  } = form;
  return (
    <AuthWrapper>
      <Container>
        <Title>Reset password</Title>
        <Text>Enter your new password below</Text>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Controller
            render={({ field }) => (
              <InputPassword
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                isRequired
                autocomplete={false}
                width="100%"
                height="50px"
                style={{ paddingBottom: '15px' }}
                placeholder="Password"
                error={errors?.password?.message}
              />
            )}
            name="password"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <InputPassword
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                isRequired
                autocomplete={false}
                width="100%"
                height="50px"
                style={{ paddingBottom: '15px' }}
                placeholder="Confirm new password"
                error={errors?.verifyPassword?.message}
              />
            )}
            name="verifyPassword"
            control={control}
          />
          <RequirementsWrapper>
            <PasswordRequirements />
          </RequirementsWrapper>

          <Button
            loading={form.formState.isSubmitting}
            htmlType="submit"
            kind={BUTTON_TYPES.DEFAULT}
            label="Continue"
            width="100%"
            height="50px"
          />
        </form>
      </Container>
    </AuthWrapper>
  );
};

export default RestorePassword;
