import * as yup from 'yup';
import { availableLocations, email, locationId, roles } from './baseSchema';

const inviteUserSchema = yup.object().shape({
  email,
  locationId,
  roles,
  availableLocations,
});

export default inviteUserSchema;
