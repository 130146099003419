import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* watchLocationsActions() {
  yield all([
    fork(W.watchLocations),
    fork(W.watchLocation),
    fork(W.watchPutLocation),
    fork(W.watchPostLocations),
    fork(W.watchDeleteLocations),
    fork(W.watchUploadMap),
    fork(W.watchUploadMapByLocation),
    fork(W.watchDeleteMap),
    fork(W.watchDeleteMapByLocation),
    fork(W.watchCheckLocationHasAssignedData),
  ]);
}
