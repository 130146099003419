import { call, select, takeLatest } from 'redux-saga/effects';
import { companySelector } from 'store/company';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetTasks() {
  yield call(E.ensureGetTasks);
  yield takeLatest(Actions.tasks.TRIGGER, E.ensureGetTasks);
}

export function* watchGetTasksAutocomplete() {
  yield takeLatest(Actions.autocomplete.TRIGGER, E.ensureAutocomplete);
}

export function* watchContainerOptions() {
  const { isContainersEnabled } = yield select(companySelector);
  if (isContainersEnabled) {
    yield call(E.ensureContainerOptions);
  }
  yield takeLatest(Actions.containerOptions.TRIGGER, E.ensureContainerOptions);
}

export function* watchDeleteTask() {
  yield takeLatest(Actions.deleteTask.TRIGGER, E.ensureDeleteTask);
}

export function* watchGetTaskById() {
  yield takeLatest(Actions.getTaskById.TRIGGER, E.ensureGetTaskById);
}

export function* watchGetRelatedTasks() {
  yield takeLatest(Actions.getRelatedTasks.TRIGGER, E.ensureGetRelatedTasks);
}

export function* watchAddTasks() {
  yield takeLatest(Actions.addTasks.TRIGGER, E.ensureAddTasks);
}

export function* watchUpdateTask() {
  yield takeLatest(Actions.updateTask.TRIGGER, E.ensureUpdateTask);
}

export function* watchPatchTask() {
  yield takeLatest(Actions.patchTask.TRIGGER, E.ensurePatchTask);
}

export function* watchExportTasksCsv() {
  yield takeLatest(Actions.exportTasksCsv.TRIGGER, E.ensureExportTasksCsv);
}

export function* watchExportSampleCsv() {
  yield takeLatest(Actions.exportSampleCsv.TRIGGER, E.ensureExportSampleCsv);
}

export function* watchImportTasksCsv() {
  yield takeLatest(Actions.importTasksCsv.TRIGGER, E.ensureImportTasksCsv);
}
