import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ButtonsWrapper } from 'pages/Auth/RestorePassword/styles';
import { registerNewCompanyPromiseCreator } from 'store/company';
import { ROUTES } from 'store/router';
import { getToken } from 'utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthWrapper from 'components/AuthWrapper/AuthWrapper';
import { BUTTON_TYPES, Button } from 'components/common';
import { useActionsRoutines } from 'hooks';
import AdditionalInformation from 'pages/Auth/Register/SignUpForm/components/AdditionalInformation/AdditionalInformation';
import { RegisterNewCompanySchema } from 'validation';
import { Container } from './styles';

// TODO - add this back in when we have a terms and conditions "'acceptTerms'"
const requiredFields = ['companyName', 'companyPhoneNumber'];

const RegisterNewCompany = (): JSX.Element => {
  const registerNewCompany = useActionsRoutines(
    registerNewCompanyPromiseCreator
  );
  const history = useHistory();
  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(RegisterNewCompanySchema),
    defaultValues: {
      password: '',
      verifyPassword: '',
      companyName: '',
      // TODO - add this back in when we have a terms and conditions
      // acceptTerms: false,
    },
    shouldUnregister: true,
  });
  const {
    watch,
    formState: { isSubmitting },
  } = form;
  const allFields = watch();
  const isButtonEnabled = Object.entries(allFields)
    .filter(field => requiredFields.includes(String(field[0])))
    .every(field => !!field[1]);

  const submitForm = async data => {
    try {
      const dataPayload = {
        employees: data.employees,
        name: data.companyName,
        phone: data.companyPhoneNumber,
      };
      await registerNewCompany(dataPayload);
      const jwt = getToken();
      history.push(`${ROUTES.LOGIN}?jwt=${jwt}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthWrapper>
      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitForm)}>
            <AdditionalInformation isUserInvited={false} />
            <ButtonsWrapper>
              <Button
                onClick={() => history.push(ROUTES.RESTORE)}
                kind={BUTTON_TYPES.SECONDARY}
                label="Cancel"
                width="155px"
                height="50px"
                fontSize="16px"
              />
              <Button
                loading={isSubmitting}
                disabled={!isButtonEnabled}
                htmlType="submit"
                kind={BUTTON_TYPES.DEFAULT}
                label="Register"
                width="155px"
                height="50px"
              />
            </ButtonsWrapper>
          </form>
        </FormProvider>
      </Container>
    </AuthWrapper>
  );
};

export default RegisterNewCompany;
