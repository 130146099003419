import { all, call, fork, select } from 'redux-saga/effects';
import * as Router from 'store/router';
import * as W from './watchers';
import { ensureGetInvitationsList } from './workers';

export function* invitationsSaga() {
  const location = yield select(Router.location);
  if (
    Router.ROUTES.SETTINGS_ADMIN_ADMINISTRATORS_INVITE ===
    `${location.pathname}${location.search}`
  ) {
    yield call(ensureGetInvitationsList);
  }
  yield all([
    fork(W.watchGetInvitationsList),
    fork(W.watchDeleteInvite),
    fork(W.watchInviteUser),
  ]);
}
