import { useCallback } from 'react';
import { ButtonContainer } from './styles';

interface IPercentButtonProps {
  isActive: boolean;
  isDisabled: boolean;
  text: string;
  value: number;
  onClick: (value: number) => void;
}

export const PercentButton = ({
  isActive,
  isDisabled,
  text,
  value,
  onClick,
}: IPercentButtonProps) => {
  const handleClick = useCallback(() => {
    if (isDisabled) return;

    onClick(value);
  }, [value, onClick, isDisabled]);

  return (
    <ButtonContainer
      active={isActive}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {text}
    </ButtonContainer>
  );
};
