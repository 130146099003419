import FileItem from '../FileItem';
import { FileWrap } from './styles';
import { IAttachmentInfo } from 'types';

interface IPopoverContentProps {
  files: Array<Partial<IAttachmentInfo>>;
}

const PopoverContent = ({ files }: IPopoverContentProps) => (
  <div>
    {files.map((file: Partial<IAttachmentInfo>) => (
      <FileWrap key={typeof file !== 'string' && file.filePath}>
        <FileItem file={file} isPopover />
      </FileWrap>
    ))}
  </div>
);

export default PopoverContent;
