import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import { sortSubtaskTypes } from 'utils/helpers';
import * as Actions from './actions';
import { AxiosResponse } from 'axios';
import { ITaskDto, ServerErrorsEnum } from 'types';

const admin = createReducer(initialState.admin, {
  [String(Actions.toggleShowAllResources)]: (state, action) => {
    state.showAllResources = action.payload;
  },
  [Actions.getInvitationsList.SUCCESS]: (state, action) => {
    state.invitationsList = action.payload;
  },

  [Actions.inviteUser.SUCCESS]: (state, { payload }) => {
    const findInvitation = state.invitationsList.find(
      item => item.email === payload.email
    );
    if (!!findInvitation) {
      state.invitationsList = state.invitationsList.map(item => {
        if (item.email === payload.email) {
          return { ...payload, expired: false };
        }
        return item;
      });
    } else {
      const newInvitationsList = [
        { ...payload, expired: false },
        ...state.invitationsList,
      ];

      state.invitationsList = newInvitationsList;
    }
  },
  [Actions.subtaskTypes.SUCCESS]: (state, action) => {
    const { data, withSearch } = action.payload;
    const subtaskTypesToState = data.map(item => ({
      ...item,
      label: item.name,
      value: item._id,
    }));
    const subtasksTypesObject = data.reduce((acc, type) => {
      acc[type._id] = type.order;

      return acc;
    }, {});
    state.subtaskTypesList = subtaskTypesToState;
    state.subtaskTypesObject = subtasksTypesObject;

    if (!withSearch) state.subtaskTypes = subtaskTypesToState;
  },
  [Actions.subtaskTypesDefaults.SUCCESS]: (state, action) => {
    state.subtaskTypesDefaults = action.payload;
  },
  [Actions.addSubtaskType.SUCCESS]: (state, action) => {
    const newItem = {
      ...action.payload,
      label: action.payload.name,
      value: action.payload._id,
    };
    state.subtaskTypes = sortSubtaskTypes([...state.subtaskTypes, newItem]);
  },
  [Actions.addSubtaskTypeWithChecklist.SUCCESS]: (state, action) => {
    const newItem = {
      ...action.payload,
      label: action.payload.name,
      value: action.payload._id,
    };
    state.subtaskTypes = sortSubtaskTypes([...state.subtaskTypes, newItem]);
  },
  [Actions.updateSubtaskType.SUCCESS]: (state, action) => {
    const updatedItem = {
      ...action.payload,
      label: action.payload.name,
      value: action.payload._id,
    };
    const getUpdatedData = prevData =>
      sortSubtaskTypes(
        prevData.map(item => {
          if (item._id === updatedItem._id) return updatedItem;
          return item;
        })
      );
    state.subtaskTypesList = getUpdatedData(state.subtaskTypesList);
    state.subtaskTypes = getUpdatedData(state.subtaskTypes);
  },
  [Actions.deleteSubtaskType.SUCCESS]: (state, action) => {
    const removeItem = prevData =>
      sortSubtaskTypes(prevData.filter(item => item._id !== action.payload.id));
    state.subtaskTypesList = removeItem(state.subtaskTypesList);
    state.subtaskTypes = removeItem(state.subtaskTypes);
  },
  [Actions.setSubtaskTypesSearch.SUCCESS]: (state, action) => {
    state.subtaskTypesSearch = action.payload;
  },
  [Actions.tasks.SUCCESS]: (state, action) => {
    state.tasks = action.payload.data;
    state.tasksSearch = action.payload.searchQuery;
  },
  [Actions.getTaskById.SUCCESS]: (state, action: PayloadAction<ITaskDto>) => {
    state.task = action.payload;
  },
  [String(Actions.setTask)]: (state, action) => {
    state.task = action.payload;
  },
  [Actions.getRelatedTasks.SUCCESS]: (state, action) => {
    state.relatedTasks = action.payload;
  },
  [String(Actions.setRelatedTasks)]: (state, action) => {
    state.relatedTasks = action.payload;
  },
  [Actions.resources.REQUEST]: state => {
    state.isResourcesLoading = true;
  },
  [Actions.resources.FAILURE]: state => {
    state.isResourcesLoading = false;
  },
  [Actions.resources.SUCCESS]: (state, action) => {
    state.isResourcesLoading = false;
    state.resources = [...action.payload];
  },
  [Actions.getResourceById.SUCCESS]: (state, action) => {
    state.resource = action.payload;
  },
  [Actions.markAsActiveResource.SUCCESS]: (state, { payload }) => {
    state.resources = state.resources.map(resource => {
      if (resource._id === payload) {
        return { ...resource, isActive: true };
      }
      return resource;
    });
    state.resource = { ...state.resource, isActive: true };
  },
  [Actions.markAsInactiveResource.SUCCESS]: (state, { payload }) => {
    state.resources = state.resources.map(resource => {
      if (resource._id === payload) {
        return { ...resource, isActive: false };
      }
      return resource;
    });
    state.resource = { ...state.resource, isActive: false };
  },
  [Actions.containerOptions.SUCCESS]: (state, action) => {
    state.containerOptions = action.payload;
    state.isContainerOptionsLoading = false;
  },
  [Actions.containerOptions.FAILURE]: state => {
    state.isContainerOptionsLoading = false;
  },
  [Actions.containerOptions.REQUEST]: state => {
    state.isContainerOptionsLoading = true;
  },
  [Actions.updateResource.SUCCESS]: (state, action) => {
    state.resource = action.payload;
  },
  [Actions.groups.SUCCESS]: (state, action) => {
    state.groups = action.payload.map(item => ({
      ...item,
      label: item.name,
      value: item._id,
    }));
  },
  [Actions.getRecurringTasks.SUCCESS]: (state, action) => {
    state.recurringTasks = action.payload;
  },
  [Actions.getRecurringTaskById.SUCCESS]: (state, action) => {
    state.recurringTask = action.payload;
  },
  [Actions.defaultContainerTemplate.SUCCESS]: (state, action) => {
    state.defaultContainerTemplate = action.payload;
  },
  [Actions.getEmailTemplates.SUCCESS]: (state, action) => {
    state.emailTemplates = action.payload;
  },
  [Actions.deleteEmailTemplate.SUCCESS]: (state, action) => {
    state.emailTemplates = state.emailTemplates.filter(
      item => item._id !== action.payload
    );
  },
  [Actions.removeResourceFromGroup.SUCCESS]: (state, action) => {
    const updatedGroups = state.groups.map(group => {
      const newResources = group.resources.filter(
        resource => resource._id !== action.payload
      );
      return { ...group, resources: newResources };
    });
    state.groups = updatedGroups;
  },
  [Actions.deleteInvite.SUCCESS]: (state, action: PayloadAction<string>) => {
    state.invitationsList = state.invitationsList.filter(
      item => item._id !== action.payload
    );
  },
  [Actions.exportTasksCsv.REQUEST]: state => {
    state.isCsvExporting = true;
  },
  [Actions.exportTasksCsv.SUCCESS]: state => {
    state.isCsvExporting = false;
  },
  [Actions.exportTasksCsv.FAILURE]: state => {
    state.isCsvExporting = false;
  },
  [Actions.exportSampleCsv.REQUEST]: state => {
    state.isSampleCsvExporting = true;
  },
  [Actions.exportSampleCsv.SUCCESS]: state => {
    state.isSampleCsvExporting = false;
  },
  [Actions.exportSampleCsv.FAILURE]: state => {
    state.isSampleCsvExporting = false;
  },
  [Actions.importTasksCsv.REQUEST]: state => {
    state.isCsvImporting = true;
  },
  [Actions.importTasksCsv.SUCCESS]: state => {
    state.isCsvImporting = false;
  },
  [Actions.importTasksCsv.FAILURE]: (
    state,
    action: PayloadAction<AxiosResponse>
  ) => {
    state.isCsvImporting = false;

    if (Array.isArray(action.payload.data.message)) {
      state.csvImportErrors = action.payload.data.message;
    }
  },
  [Actions.importTasksCsv.FAILURE]: (
    state,
    action: PayloadAction<AxiosResponse>
  ) => {
    state.isCsvImporting = false;

    if (action.payload.data.errorCode === ServerErrorsEnum.INVALID_TASK_TYPE) {
      state.csvInvalidRowNumbers = action.payload.data.rowNumbers;
    }

    if (Array.isArray(action.payload.data.message)) {
      state.csvImportErrors = action.payload.data.message;
    }
  },
  [Actions.clearCsvImportErrors.TRIGGER]: state => {
    state.csvImportErrors = [];
  },
  [Actions.clearCsvInvalidRowNumbersErrors.TRIGGER]: state => {
    state.csvInvalidRowNumbers = [];
  },
});

export { admin as reducer };
