import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import * as A from './actions';

const sharing = createReducer(initialState.sharing, {
  [String(A.clearSystemNotifications)]: state => {
    state.systemNotifications = {
      data: undefined,
      meta: { page: 0, pages: 0 },
    };
  },
  [String(A.toggleSystemNotifications)]: (state, { payload }) => {
    state.isSystemNotificationsOpened = payload;
  },

  [String(A.setShowOnlyUnreadNotificationMessages)]: (state, { payload }) => {
    state.isShowOnlyUnreadMessages = payload;
  },
  [A.getProjectByToken.SUCCESS]: (state, action) => {
    state.projectForCustomer = action.payload;
  },
  [A.syncNotifications.SUCCESS]: (state, { payload }) => {
    state.systemNotifications = {
      ...state.systemNotifications,
      data: payload.data,
    };
  },

  [A.deleteSystemNotification.SUCCESS]: (state, { payload }) => {
    state.systemNotificationsUnreadCount = payload;
  },
  [A.markAllSystemNotificationsRead.SUCCESS]: state => {
    state.systemNotificationsUnreadCount = 0;
    const data = state.isShowOnlyUnreadMessages
      ? []
      : state.systemNotifications.data.map(item => ({
          ...item,
          isSeen: true,
        }));
    state.systemNotifications = {
      ...state.systemNotifications,
      data,
    };
  },
  [A.getSystemNotifications.SUCCESS]: (state, action) => {
    state.systemNotifications = action.payload;
  },
  [A.getSystemNotificationsUnreadCount.SUCCESS]: (state, action) => {
    state.systemNotificationsUnreadCount = action.payload;
  },

  [A.markSystemNotificationsRead.SUCCESS]: (state, { payload }) => {
    state.systemNotificationsUnreadCount = payload.count;
    state.systemNotifications = {
      ...state.systemNotifications,
      data: state.systemNotifications.data.map(item =>
        item?._id === payload?.id ? { ...item, isSeen: true } : item
      ),
    };

    const isShowOnlyUnread = state.isShowOnlyUnreadMessages;
    state.systemNotificationsUnreadCount = payload.count;
    const data = isShowOnlyUnread
      ? state.systemNotifications.data.filter(item => item?._id !== payload?.id)
      : state.systemNotifications.data.map(item =>
          item?._id === payload?.id ? { ...item, isSeen: true } : item
        );
    state.systemNotifications = {
      ...state.systemNotifications,
      data,
    };
  },
  [A.markSystemNotificationsUnread.SUCCESS]: (state, { payload }) => {
    state.systemNotificationsUnreadCount = payload.count;
    state.systemNotifications = {
      ...state.systemNotifications,
      data: state.systemNotifications.data.map(item =>
        item?._id === payload?.id ? { ...item, isSeen: false } : item
      ),
    };
  },
});

export { sharing as reducer };
