import theme from 'styles/theme';

export const LogOut = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4999 1H3.49991C2.38991 1 1.49991 1.9 1.49991 3V4.5H3.49991V3H17.4999V17H3.49991V16H1.49991V17C1.49991 18.1 2.38991 19 3.49991 19H17.4999C18.5999 19 19.4999 18.1 19.4999 17V3C19.4999 1.9 18.5999 1 17.4999 1Z"
      fill={theme.colors.white}
    />
    <path
      d="M5.41 15L6.82 13.59L4.24 11H13.91V9H4.24L6.82 6.41L5.41 5L0.41 10L5.41 15Z"
      fill={theme.colors.white}
    />
  </svg>
);
