import { useMemo } from 'react';
import { getFilePreviewUrl } from 'lib/constants';
import { FileIcon } from 'lib/icons';
import { getProjectListCoverPreviewUrl } from 'utils/images';
import { Preview, PreviewWrapper } from './styles';
import { IAttachmentInfo } from 'types';

interface IPreviewWrapProps {
  setShowPreview: (value: boolean) => void;
  file: Partial<IAttachmentInfo>;
}

const PreviewWrap = ({
  setShowPreview,
  file,
}: IPreviewWrapProps): JSX.Element => {
  const openPreview = e => {
    e.stopPropagation();
    if (!!previewUrl) {
      setShowPreview(true);
    }
  };

  const previewUrl = useMemo(
    () => getFilePreviewUrl(file, getProjectListCoverPreviewUrl),
    [file]
  );
  return (
    <>
      {previewUrl ? (
        <PreviewWrapper>
          <Preview src={previewUrl} onClick={openPreview} />
        </PreviewWrapper>
      ) : (
        <PreviewWrapper onClick={openPreview}>
          <FileIcon />
        </PreviewWrapper>
      )}
    </>
  );
};

export default PreviewWrap;
