import { all, fork } from 'redux-saga/effects';
// redux
import * as W from './watchers';

export function* depotColorsSaga() {
  yield all([
    fork(W.watchGetColors),
    fork(W.watchAddColor),
    fork(W.watchDeleteColor),
  ]);
}
