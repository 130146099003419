import { useCallback, useMemo, useState } from 'react';
import KeyboardIcon from 'lib/icons/KeyboardIcon';
import {
  ModalItem,
  ShortCutCombination,
  ShortCutItem,
  ShortCutTitle,
  ShortCutsItem,
} from './styles';

const shortCuts = [
  {
    title: 'User can Zoom Gantt',
    shortcut: 'Alt/Ctrl/Cmd + Scroll',
  },
  {
    title: 'Users can select a few tasks and drag and drop them',
    shortcut: 'Ctrl + Click',
  },
  {
    title: 'User can scroll the Gantt',
    shortcut: 'Shift + Scroll',
  },
  {
    title: 'User can see additional information about the task',
    shortcut: 'Shift + Hover',
  },
];

const ShortCuts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = useCallback(() => setIsModalVisible(false), []);

  const showModal = useCallback(() => setIsModalVisible(true), []);

  const shortCutsMemo = useMemo(
    () =>
      shortCuts
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(({ title, shortcut }) => (
          <ShortCutItem key={title}>
            <ShortCutTitle>{title}</ShortCutTitle>
            <ShortCutCombination>{shortcut}</ShortCutCombination>
          </ShortCutItem>
        )),
    []
  );

  return (
    <>
      <ShortCutsItem onClick={showModal}>
        <KeyboardIcon />
      </ShortCutsItem>
      <ModalItem
        title="Keyboard shortcuts"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        {shortCutsMemo}
      </ModalItem>
    </>
  );
};

export default ShortCuts;
