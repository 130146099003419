import styled from 'styled-components';
import { Row } from 'antd';

export const Photos = styled(Row)`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Image = styled.img`
  max-width: 645px;
  @media (max-width: 700px) {
    max-width: 100%;
  }
`;

export const ImageWrap = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const NoResult = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
