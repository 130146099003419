import ProgressBar from 'pages/Dashboard/Project/common/ProgressBar';
// styles
import { Caption, ListItem, ListRow, ListStyled, Title, Wrap } from './styles';
import { IProjectTaskDto, TypeEnum } from 'types';

interface ITasksProps {
  data: IProjectTaskDto[];
}

const Tasks = ({ data }: ITasksProps) => (
  <Wrap>
    <Caption>Tasks</Caption>
    <ListStyled
      dataSource={data}
      rowKey={(item: IProjectTaskDto) => item._id}
      renderItem={(item: IProjectTaskDto) => {
        if (item.type === TypeEnum.Container) {
          return (
            <ListItem>
              <ListRow>
                <Title> Container name: </Title>
                {item.name}
              </ListRow>
              {item.container?.num && (
                <ListRow>
                  <Title>Container number: </Title>
                  {item.container?.num}
                </ListRow>
              )}
            </ListItem>
          );
        }
        return (
          <ListItem>
            <ProgressBar item={item} withTitle={true} isLarge={true} />
          </ListItem>
        );
      }}
    />
  </Wrap>
);

export default Tasks;
