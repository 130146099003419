export const GoogleLogo = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7083 12.0623H28.5V12H15V18H23.4773C22.2405 21.4928 18.9172 24 15 24C10.0297 24 6 19.9702 6 15C6 10.0297 10.0297 6 15 6C17.2943 6 19.3815 6.8655 20.9708 8.27925L25.2135 4.0365C22.5345 1.53975 18.951 0 15 0C6.71625 0 0 6.71625 0 15C0 23.2838 6.71625 30 15 30C23.2838 30 30 23.2838 30 15C30 13.9943 29.8965 13.0125 29.7083 12.0623Z"
      fill="#FFC107"
    />
    <path
      d="M1.72949 8.01825L6.65774 11.6325C7.99124 8.331 11.2207 6 15 6C17.2942 6 19.3815 6.8655 20.9707 8.27925L25.2135 4.0365C22.5345 1.53975 18.951 0 15 0C9.23849 0 4.24199 3.25275 1.72949 8.01825Z"
      fill="#FF3D00"
    />
    <path
      d="M15 30.0005C18.8745 30.0005 22.395 28.5178 25.0567 26.1065L20.4142 22.178C18.8576 23.3618 16.9556 24.0021 15 24.0005C11.0985 24.0005 7.78574 21.5128 6.53774 18.041L1.64624 21.8098C4.12874 26.6675 9.17024 30.0005 15 30.0005Z"
      fill="#4CAF50"
    />
    <path
      d="M29.7083 12.0623H28.5V12H15V18H23.4773C22.8857 19.6623 21.82 21.1149 20.412 22.1782L20.4142 22.1768L25.0568 26.1052C24.7283 26.4037 30 22.5 30 15C30 13.9943 29.8965 13.0125 29.7083 12.0623Z"
      fill="#1976D2"
    />
  </svg>
);
