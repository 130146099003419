import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const getInvitationsList = createRoutine('admin/getInvitationsList');
export const getInvitationsListPromiseCreator = promisifyRoutine(
  getInvitationsList
);

export const deleteInvite = createRoutine('administrations/deleteInvite');
export const deleteInvitePromiseCreator = promisifyRoutine(deleteInvite);

export const inviteUser = createRoutine('auth/inviteUser');
export const inviteUserPromiseCreator = promisifyRoutine(inviteUser);
