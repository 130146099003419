import * as yup from 'yup';
import { checklist, notes } from './baseSchema';

const subtaskSchema = yup.object().shape({
  plannedHours: yup
    .number()
    .min(0.01, 'Should be greater than 0')
    .max(999.99, 'Should be less than 999.99')
    .required('Required!')
    .typeError('Hours is required!'),
  name: yup.string().required('Task Name is required!'),
  notes,
  checklist,
});

export default subtaskSchema;
