import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { projectViewModeSelector, setProjectViewMode } from 'store/dashboard';
import { SwitchPrefix } from './elements/SwitchPrefix';
import { SortBySelect } from 'components/common';
import { useActions } from 'hooks';
import { projectViewModeOptions } from 'lib/constants';
import * as Styled from './styles';
import { ProjectViewMode } from 'types';

export const SwitchViewMode: FC = () => {
  const projectViewMode = useSelector(projectViewModeSelector);
  const changeProjectViewMode = useActions(setProjectViewMode);

  const handleChange = useCallback(
    (value: ProjectViewMode) => changeProjectViewMode(value),
    [changeProjectViewMode]
  );

  return (
    <Styled.SwitchWrapper>
      <SortBySelect
        onChange={handleChange}
        customPrefix={<SwitchPrefix />}
        options={projectViewModeOptions}
        value={projectViewMode}
        showSearch={false}
        bordered={false}
        width="200px"
      />
    </Styled.SwitchWrapper>
  );
};
