import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
`;

export const OwnerEmail = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-left: 50px;
`;

export const OwnerEmailTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 133.49%;
  margin-bottom: 5px;
`;

export const OwnerEmailValue = styled.div`
  font-size: 14px;
  line-height: 17px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 10px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const FileList = styled.div`
  display: flex;
  margin-bottom: 31px;
  flex-wrap: wrap;
`;

export const FileListItem = styled.div`
  padding: 5px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 4px;
  margin-right: 7px;
  margin-bottom: 5px;
  a {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.mainDarkBlue};
  }
  svg {
    margin-right: 6px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Back = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  cursor: pointer;
  margin-bottom: 12px;
  svg {
    margin-top: 1.5px;
    margin-right: 8px;
  }
`;
