import theme from 'styles/theme';

interface IMenuIconProps {
  color?: string;
}

export const MenuIcon = ({ color }: IMenuIconProps) => (
  <svg
    width="4"
    height="18"
    viewBox="0 0 4 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9C0 10.1027 0.897333 11 2 11C3.10267 11 4 10.1027 4 9C4 7.89733 3.10267 7 2 7C0.897333 7 0 7.89733 0 9Z"
      fill={color}
    />
    <path
      d="M0 16C0 17.1027 0.897333 18 2 18C3.10267 18 4 17.1027 4 16C4 14.8973 3.10267 14 2 14C0.897333 14 0 14.8973 0 16Z"
      fill={color}
    />
    <path
      d="M0 2C0 3.10267 0.897333 4 2 4C3.10267 4 4 3.10267 4 2C4 0.897333 3.10267 0 2 0C0.897333 0 0 0.897333 0 2Z"
      fill={color}
    />
  </svg>
);

MenuIcon.defaultProps = {
  color: theme.colors.mainDarkBlue,
};
