import styled, { css } from 'styled-components';
import { BUTTON_TYPES } from './constants';
import { Button } from 'antd';

const getColor =
  color =>
  ({ theme }) =>
    theme.colors[color];

const textStyle = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const defaultSecondaryStyle = css`
  --color: ${getColor('mainDarkBlue')};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--color);
  &:hover,
  &:focus {
    color: var(--color);
  }
`;

const primaryDangerStyle = css`
  border: none;
  &:hover,
  &:focus {
    border: none;
  }
`;

const defaultStyles = css`
  ${defaultSecondaryStyle};
  --color: ${getColor('white')};
  --background: ${getColor('primary')};
  --hoverColor: ${getColor('primaryLight')};
  --focusFolor: ${getColor('primaryDark')};
  border: 1px solid var(--background);
  color: var(--color);
  background: var(--background);
  &:hover {
    background: var(--hoverColor);
    border: 1px solid var(--hoverColor);
  }
  &:focus {
    background: var(--focusFolor);
    border: 1px solid var(--focusFolor);
  }
`;

const dangerStyles = css<{ icon?: boolean }>`
  --color: ${getColor('danger')};
  --background: ${getColor('lightDanger')};
  --focusColor: ${getColor('white')};
  --focusBackground: ${getColor('red')};
  ${textStyle};
  ${primaryDangerStyle};
  color: var(--color);
  background: var(--background);
  &:hover,
  &:focus {
    color: var(--focusColor);
  }
  &:hover {
    background: var(--color);
  }
  &:focus {
    background: var(--focusBackground);
  }
  ${props =>
    props.icon &&
    css`
      &:hover path,
      &:focus path {
        fill: var(--focusColor);
      }
      & path {
        fill: var(--color);
      }
    `}
`;

const dangerActiveStyles = css<{ icon?: string }>`
  --color: ${getColor('white')};
  --focusColor: ${getColor('white')};
  --background: ${getColor('danger')};
  ${textStyle};
  ${primaryDangerStyle};
  color: var(--color);
  background: var(--background);
  &:hover,
  &:focus {
    background: var(--background);
  }
  &:focus {
    background: var(--background);
  }
  ${props =>
    props.icon &&
    css`
      &:hover path,
      &:focus path {
        fill: var(--focusColor);
      }
      & path {
        fill: var(--color);
      }
    `}
`;

const removeStyles = css`
  --mainDarkBlue: ${getColor('mainDarkBlue')};
  --grayDark: ${getColor('grayDark')};
  --white: ${getColor('white')};
  --lightDanger: ${getColor('lightDanger')};
  --danger: ${getColor('danger')};
  --red: ${getColor('red')};
  ${primaryDangerStyle};
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--mainDarkBlue);
  background: var(--white);
  border: 1px solid var(--grayDark);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px;
  &:focus,
  &:hover {
    font-size: 13px;
    border: 1px solid transparent;
  }
  &:hover {
    background: var(--lightDanger);
    color: var(--danger);
  }
  &:focus {
    background: var(--red);
    color: var(--white);
  }
`;

const deleteStyles = css`
  --mainDarkBlue: ${getColor('mainDarkBlue')};
  --grayDark: ${getColor('grayDark')};
  --white: ${getColor('white')};
  --lightDanger: ${getColor('lightDanger')};
  --danger: ${getColor('danger')};
  --red: ${getColor('red')};
  ${primaryDangerStyle};
  font-style: normal;
  font-weight: 500;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--danger);
  background: var(--white);
  border: 1px solid var(--grayDark);
  box-sizing: border-box;
  border-radius: 6px;
  height: 35px;
  padding: 9px;

  &:hover {
    background: var(--lightDanger);
    color: var(--danger);
    border: 1px solid var(--lightDanger);
  }
  &:focus:hover {
    background: var(--lightDanger);
    color: var(--danger);
    border: 1px solid var(--lightDanger);
  }
  &:focus {
    background: var(--white);
    color: var(--danger);
    border: 1px solid var(--grayDark);
  }
`;

const primaryStyles = css`
  --color: ${getColor('mainDarkBlue')};
  --background: ${getColor('grayLight')};
  --hoverBackground: ${getColor('grayDark')};
  --focusBackground: ${getColor('grayDark')};
  ${textStyle};
  ${primaryDangerStyle};
  color: var(--color);
  background: var(--background);
  &:hover,
  &:focus {
    color: var(--color);
  }
  &:hover {
    background: var(--hoverBackground);
  }
  &:focus {
    background: var(--focusBackground);
  }
`;

const iconStyles = css`
  --hoverBackground: ${getColor('grayLight')};
  --focusBackground: ${getColor('grayDark')};
  background: none;
  border: none;
  padding: 12px;
  &:hover,
  &:focus {
    border-radius: 50%;
  }
  &:hover {
    background: var(--hoverBackground);
  }
  &:focus {
    background: var(--focusBackground);
  }
`;

const secondaryStyles = css`
  --borderColor: ${getColor('grayDark')};
  --white: ${getColor('white')};
  --grayLight: ${getColor('grayLight')};
  --mainDarkBlue: ${getColor('mainDarkBlue')};
  ${defaultSecondaryStyle};
  font-size: 13px;
  line-height: 16px;
  background: ${getColor('white')};
  border: 1px solid var(--borderColor);
  &:hover {
    background: var(--white);
    border-color: var(--mainDarkBlue);
  }
  &:focus {
    background: var(--grayLight);
    border-color: var(--mainDarkBlue);
  }
  &:focus {
    border: 1px solid var(--mainDarkBlue);
  }
`;

// use with type='link'
const linkStyles = css`
  --color: ${getColor('mainDarkBlue')};
  --waikawaGray: ${getColor('waikawaGray')};
  ${textStyle};
  text-decoration-line: none;
  color: var(--color);
  &:hover,
  &:not([disabled]):hover,
  &:focus {
    color: var(--color);
    text-decoration-line: underline;
  }
`;

const settingStyles = css`
  ${linkStyles};
  justify-content: flex-start;
  padding: 0;
  text-decoration-line: none;
  margin-bottom: 27px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  &:hover,
  &:not([disabled]):hover,
  &:focus {
    text-decoration-line: none;
  }
`;

const logoutStyles = css`
  ${settingStyles};
  --color: ${getColor('white')};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 0 20px;
  color: var(--color);
`;

const dangerIconStyles = css<{ withdefaulticoncolor?: string }>`
  --danger: ${getColor('danger')};
  --lightDanger: ${getColor('lightDanger')};
  --white: ${getColor('white')};
  --red: ${getColor('red')};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: var(--lightDanger);
  border: none;
  & > svg {
    margin: 0;
    & path {
      fill: ${props =>
        props.withdefaulticoncolor !== 'true' && 'var(--danger)'};
    }
  }
  &:hover {
    background: var(--danger);
  }
  &:focus {
    background: var(--red);
  }
  &:hover,
  &:focus {
    border-radius: 50%;
    & path {
      fill: var(--white);
    }
  }
`;

const saveIconStyles = css<{ space?: string }>`
  --caribbeanGreen: ${getColor('caribbeanGreen')};
  --white: ${getColor('white')};
  --space: ${props => props?.space || '10px'};
  ${dangerIconStyles};
  background: var(--caribbeanGreen);
  margin-right: var(--space);
  & > svg > path {
    fill: var(--white);
  }
  &:hover,
  &:focus {
    background: var(--caribbeanGreen);
  }
`;

const borderedStyles = css`
  border: 1px solid ${getColor('grayDark')};
  background-color: ${getColor('white')};
  color: ${getColor('mainDarkBlue')};
  &:hover,
  &:focus {
    border: 1px solid ${getColor('grayDark')};
    background-color: ${getColor('white')};
    color: ${getColor('mainDarkBlue')};
  }
`;

const cancelStyles = css`
  ${defaultSecondaryStyle};
  --background: ${getColor('grayLight')};
  --hoverColor: ${getColor('blue')};
  --focusFolor: ${getColor('secondaryBlue')};
  border: none;
  background: var(--background);
  &:hover {
    background: var(--hoverColor);
    border: none;
  }
  &:focus {
    background: var(--focusFolor);
    border: none;
  }
`;

export const actionStyles = css`
  background: ${getColor('white')};
  font-weight: 500;
  border: none;
  &:hover,
  &:focus {
    color: ${getColor('mainDarkBlue')};
  }
`;

const styles = {
  default: defaultStyles,
  danger: dangerStyles,
  dangerActive: dangerActiveStyles,
  primary: primaryStyles,
  icon: iconStyles,
  secondary: secondaryStyles,
  link: linkStyles,
  settings: settingStyles,
  logout: logoutStyles,
  dangerIcon: dangerIconStyles,
  bordered: borderedStyles,
  saveIcon: saveIconStyles,
  remove: removeStyles,
  cancel: cancelStyles,
  delete: deleteStyles,
  action: actionStyles,
};

export const ButtonStyled = styled(Button)<{
  width: string;
  height: string;
  space?: string;
  padding?: string;
  lineheight?: string;
  background?: string;
  kind?: BUTTON_TYPES;
  fontSize?: string;
}>`
  --width: ${props => props?.width || 'auto'};
  --height: ${props => props?.height || 'auto'};
  --space: ${props => props?.space || '10px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: ${({ padding }) => (padding ? padding : '8px 14px')};
  box-shadow: none;
  text-shadow: none;
  box-sizing: border-box;
  width: var(--width);
  height: var(--height);
  & > svg {
    margin-right: var(--space);
  }
  &:after {
    content: none;
  }
  &:hover,
  &:focus {
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
  }
  ${props => styles[props.kind]};

  line-height: ${props => props.lineheight};
  background-color: ${props => props.background};
  font-size: ${props => props.fontSize};

  ${props =>
    props.shape === 'circle' &&
    css`
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin: 0;
        padding: 0;
      }
      &:hover,
      &:focus {
        border-radius: 50%;
      }
    `}
`;
