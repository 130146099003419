import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import {
  getDeleteResourceTimeData,
  getUpdatedResourceTimeData,
} from 'store/timesheets/helpers';
import {
  deleteShift,
  editResourceTime,
  getShiftClosestDates,
  resourceTimesheets,
  setTimesheetsDates,
  setTimesheetsSearch,
  timesheets,
} from './actions';

const timesheetsReducer = createReducer(initialState.timesheets, {
  [timesheets.SUCCESS]: (state, action) => {
    state.timesheets = action.payload;
  },
  [resourceTimesheets.SUCCESS]: (state, action) => {
    state.resourceTimesheets = action.payload;
  },
  [editResourceTime.SUCCESS]: (state, action) => {
    state.resourceTimesheets = getUpdatedResourceTimeData(
      state.resourceTimesheets,
      action.payload
    );
  },
  [deleteShift.SUCCESS]: (state, action) => {
    state.resourceTimesheets = getDeleteResourceTimeData(
      state.resourceTimesheets,
      action.payload
    );
  },
  [String(setTimesheetsDates)]: (state, action) => {
    state.timesheetsDates = action.payload;
  },
  [String(setTimesheetsSearch)]: (state, action) => {
    state.search = action.payload;
  },
  [getShiftClosestDates.SUCCESS]: (state, action) => {
    state.closestDates = action.payload;
  },
});

export { timesheetsReducer as reducer };
