import styled from 'styled-components';

export const ContainerWrap = styled.div`
  order: 1;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  padding: 9px 17px 19px;
  margin-bottom: 5px;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  width: 100%;
`;

export const Headingleft = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-right: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  display: flex;
  align-items: center;
  width: 87%;
`;

export const HeadingRight = styled.div`
  display: flex;
  align-items: center;
`;

export const MainInfo = styled.div`
  display: flex;
  margin-bottom: 7px;
`;
