import styled from 'styled-components';
import theme from 'styles/theme';

export const InfoBlocksWrap = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15x;
  border: 1px solid ${theme.colors.grayDark};
  border-radius: 6px;
  padding: 16px 20px 4px 20px;
  .ant-divider-horizontal {
    margin: 16px 0;
  }
`;

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
`;

export const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const InfoBlockName = styled.div`
  color: ${theme.colors.lightBlue};
`;
export const InfoBlockData = styled.div`
  color: ${theme.colors.mainDarkBlue};
  text-align: right;
`;

export const DateCreated = styled(InfoBlockName)`
  margin-top: 3px;
`;

export const InvoiceStatus = styled.span`
  padding: 4px 6px;
  background: ${theme.colors.grayLight};
  border-radius: 6px;
`;
