import { memo, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { downloadPdfPromiseCreator } from 'store/sharing';
import PreviewWrap from './PreviewWrap';
import PreviewFile from 'components/PreviewAttachment';
import { BUTTON_TYPES, Button, ConfirmationPopover } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { COVER_MIME_TYPES } from 'lib/constants/files';
import { CoverIcon, Cross, RemoveIcon } from 'lib/icons';
import theme from 'styles/theme';
import { Spin } from 'antd';
// styles
import {
  Cover,
  CoverText,
  FileInfoName,
  FileItem,
  FileItemBottom,
  FileItemTop,
  RemoveFileWrapper,
} from './styles';
import { LoadingOutlinedStyled } from 'components/SubtaskActions/styles';
import { IAttachmentInfo } from 'types';

interface FileProps {
  setCoverFile: (
    file: Partial<IAttachmentInfo>,
    isCover: boolean
  ) => Promise<void>;
  onDeleteFile: (file: Partial<IAttachmentInfo>) => void;
  file: Partial<IAttachmentInfo>;
  notAvailableProject: boolean;
  isDrawer?: boolean;
}

const File = ({
  setCoverFile,
  onDeleteFile,
  file,
  isDrawer,
  notAvailableProject,
}: FileProps): JSX.Element => {
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const [showPreview, setShowPreview] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { filePath: href } = file;

  const closePreview = (e: MouseEvent) => {
    e.stopPropagation();
    setShowPreview(false);
  };

  const handleSetCover = useCallback(() => {
    setShowLoader(true);

    setCoverFile(file, file.isCover)?.then(() => setShowLoader(false));
  }, [setCoverFile, file]);

  const handleDownloadPdf = useCallback(() => {
    if (href) {
      downloadPdf({ data: href, originalName: file.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file.originalName, downloadPdf]);

  const isShowCoverButton =
    file.fileUrl &&
    !notAvailableProject &&
    COVER_MIME_TYPES.includes(file.mimetype) &&
    !file.isCover;

  return (
    <FileItem key={file.filePath}>
      <PreviewFile
        showPreview={showPreview}
        closePreview={closePreview}
        file={file}
      />
      <FileItemTop>
        <PreviewWrap file={file} setShowPreview={setShowPreview} />
        {!isDrawer && (
          <RemoveFileWrapper>
            <ConfirmationPopover
              text="Are you sure you want to delete this file?"
              onOk={() => onDeleteFile(file)}
            >
              <RemoveIcon />
            </ConfirmationPopover>
          </RemoveFileWrapper>
        )}

        {file.isCover && (
          <Cover onClick={handleSetCover}>
            {showLoader ? (
              <Spin indicator={<LoadingOutlinedStyled spin />} />
            ) : (
              <>
                <CoverText>Cover</CoverText>
                <Cross size={8} color={theme.colors.white} />
              </>
            )}
          </Cover>
        )}
      </FileItemTop>

      <FileItemBottom>
        <FileInfoName onClick={handleDownloadPdf} ellipsis={{ rows: 1 }}>
          {file.originalName}
        </FileInfoName>
        {isShowCoverButton && (
          <Button
            onClick={handleSetCover}
            kind={BUTTON_TYPES.LINK}
            icon={<CoverIcon />}
            type="link"
            space="3px"
            loading={showLoader}
            fontSize="12px"
            style={{
              padding: 0,
              fontSize: 11,
            }}
          />
        )}
      </FileItemBottom>
    </FileItem>
  );
};

export default memo(File, isEqual);
