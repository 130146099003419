import { Row } from 'antd';
// styles
import { Image, ImageWrap, NoResult, Photos } from './styles';

interface IMainProps {
  results: any[];
  isImageLoading?: number;
  setImageLoaded?: (isImageLoading: number) => void;
  isLoading?: boolean;
}

const Main = ({
  results,
  isImageLoading,
  setImageLoaded,
  isLoading,
}: IMainProps) => (
  <Row>
    {!results.length ? (
      <NoResult>Photos were not required!</NoResult>
    ) : (
      <Photos>
        {results.map((result, index) => (
          <ImageWrap key={index} style={isLoading ? { display: 'none' } : {}}>
            <Image
              src={result}
              onLoad={() => setImageLoaded(isImageLoading + 1)}
              onError={() => setImageLoaded(isImageLoading + 1)}
            />
          </ImageWrap>
        ))}
      </Photos>
    )}
    {results.every(result => result === null) && (
      <NoResult>Photo is not found!</NoResult>
    )}
  </Row>
);

export default Main;
