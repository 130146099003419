import styled from 'styled-components';

export const Wrap = styled.div`
  overflow: scroll;
  margin-left: -45px;
  padding-left: 45px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TableWrap = styled.div`
  width: 1108px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
`;
