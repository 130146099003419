import ReadMore from 'components/common/ReadMore/ReadMore';
import { NotesBlock, NotesText, NotesTitle } from './styles';

interface INotesProps {
  notes: string;
}

const Notes = ({ notes }: INotesProps): JSX.Element => (
  <NotesBlock>
    {notes && (
      <>
        <NotesTitle>Notes</NotesTitle>
        <NotesText>
          <ReadMore limit={200} text={notes} />
        </NotesText>
      </>
    )}
  </NotesBlock>
);

export default Notes;
