import { deleteSystemNotificationPromiseCreator } from 'store/sharing';
import { ConfirmationPopover } from 'components/common';
import { useRequest } from 'hooks/useRequest';
import { DeleteButton, Wrapper } from './styles';

interface DeleteNotificationProps {
  notificationId: string;
}

const DeleteNotification = ({
  notificationId,
}: DeleteNotificationProps): JSX.Element => {
  const [deleteNotification, loading] = useRequest(
    deleteSystemNotificationPromiseCreator
  );

  const handleDeleteNotification = async () => {
    try {
      await deleteNotification(notificationId);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Wrapper>
      <ConfirmationPopover
        text="Are you sure you want to remove notification?"
        onOk={handleDeleteNotification}
        loading={loading}
        placement="bottomRight"
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        <DeleteButton type="text">Delete</DeleteButton>
      </ConfirmationPopover>
    </Wrapper>
  );
};

export default DeleteNotification;
