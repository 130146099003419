import styled from 'styled-components';

export const Error = styled.div<{
  wrap?: boolean;
}>`
  font-size: 11px;
  line-height: 13px;
  padding-top: 3px;
  color: ${({ theme }) => theme.colors.danger};
  white-space: ${({ wrap }) => (wrap ? 'normal' : 'nowrap')};
`;
