import styled from 'styled-components';
import { Button } from 'antd';

export const ResendWrapper = styled.div`
  margin-bottom: 25px;
  font-size: 12px;
`;

export const ResendButton = styled(Button)`
  &.ant-btn {
    height: auto;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    padding: 0;
    &:hover {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
    }
  }
`;
