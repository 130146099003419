import styled, { css } from 'styled-components';

export const Inputs = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
`;

export const inputsItemStyles = css<{
  isTitle?: boolean;
}>`
  margin-right: 10px;
  &:last-child {
    margin: 0;
  }
  ${({ isTitle }) =>
    isTitle &&
    css`
      .ContainerTitleInput {
        width: 100%;
        .ant-select-selector {
          width: 100% !important;
        }
      }
      width: 100%;
      @media (min-width: 755px) {
        width: 598px;
      }
    `}
`;

export const InputsItem = styled.div<{
  isTitle?: boolean;
}>`
  ${inputsItemStyles}
`;

export const ZoneInputWrap = styled.div`
  ${inputsItemStyles};

  margin-left: 10px !important;
`;

export const Info = styled.div`
  margin-top: 29px;
`;

export const InfoItem = styled.div`
  margin-bottom: 10px;
`;

export const InfoItemTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  margin-bottom: 7px;
`;

export const ColorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ColorItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 60px;
  background-color: ${props => props.color};
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

export const SubtasksWrapper = styled.div`
  width: 100%;
`;
