import styled from 'styled-components';

export const List = styled.ul`
  margin: 0;
  padding-inline-start: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;
