import {
  getFileSize,
  hasLocation,
  prepareClearChecklist,
  prepareTask,
} from 'utils/helpers';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { IAttachmentInfo, IContainer, ILocation, TaskTypeEnum } from 'types';

export const getDefaultValues = (
  defaultData,
  withDefaultValues: boolean,
  isClone: boolean
) => {
  if (!withDefaultValues) {
    return { tasks: [], startDate: moment(), dueDate: moment() };
  }
  if (isClone) {
    return {
      account: defaultData?.account || '',
      files: defaultData?.files || [],
      cover: defaultData?.cover || {},
      customer: defaultData?.customer || '',
      dueDate: defaultData.dueDate && moment(defaultData.dueDate),
      startDate:
        (defaultData?.startDate || undefined) && moment(defaultData.startDate),
      invoice: defaultData?.invoice,
      locationId: defaultData?.locationId,
      notes: defaultData?.notes || '',
      estimatedTimeOfArrival: defaultData?.estimatedTimeOfArrival,
      bookingNumber: defaultData?.bookingNumber || '',
      tasks: defaultData?.tasks
        ?.filter(item => item.type === TaskTypeEnum.Common)
        ?.map(current => ({
          cost: current?.cost || 0,
          description: current?.description,
          name: current?.name,
          order: current?.order,
          price: current?.price || 0,
          sku: current?.sku,
          status: current?.status,
          taskNotificationId: current?.taskNotificationId,
          type: TaskTypeEnum.Common,
          subtasks: current?.subtasks
            ?.filter(subtask => !subtask.createdFromId)
            .map(subtask => ({
              files: subtask?.files,
              templateHours: subtask?.templateHours || 0,
              plannedHours: subtask?.plannedHours,
              isApproveRequired: subtask?.isApproveRequired,
              isPhotoRequired: subtask?.isPhotoRequired,
              subtaskTypeId: subtask?.subtaskTypeId,
              waitForRequiredPhoto: subtask?.waitForRequiredPhoto,
              name: subtask?.name,
              order: subtask?.order,
              notes: subtask?.notes,
              checklist: prepareClearChecklist(subtask?.checklist || []),
            })),
        })),
      container: defaultData?.tasks
        ?.filter(item => item.type === TaskTypeEnum.Container)
        ?.map(current => ({
          container: {
            condition: current?.container?.condition,
            size: current?.container?.size,
            grade: current?.container?.grade,
            color: current?.container?.color,
          },
          color: current?.color,
          condition: current?.condition,
          grade: current?.grade,
          size: current?.size,
          description: current?.description,
          name: current?.name,
          sku: current?.sku,
          type: TaskTypeEnum.Container,
          subtasks: current?.subtasks
            ?.filter(subtask => !subtask.createdFromId)
            .map(subtask => ({
              files: subtask?.files,
              checklist: prepareClearChecklist(subtask?.checklist || []),
              templateHours: subtask?.templateHours || 0,
              plannedHours: subtask?.plannedHours,
              isApproveRequired: subtask?.isApproveRequired,
              isPhotoRequired: subtask?.isPhotoRequired,
              waitForRequiredPhoto: subtask?.waitForRequiredPhoto,
              subtaskTypeId: subtask?.subtaskTypeId,
              name: subtask?.name,
              order: subtask?.order,
              notes: subtask?.notes,
            })),
        }))[0],
    };
  }

  const { container, tasks } = defaultData.tasks?.reduce(
    (acc, task) => {
      if (task.type === TaskTypeEnum.Container) {
        acc.container = prepareTask(task);
      } else {
        acc.tasks.push(prepareTask(task));
      }

      return acc;
    },
    { tasks: [] }
  );

  return {
    ...defaultData,
    invoice: defaultData?.invoice,
    customer: defaultData?.customer || '',
    account: defaultData?.account || '',
    notes: defaultData?.notes || '',
    tasks,
    container,
    dueDate: defaultData.dueDate && moment(defaultData.dueDate),
    startDate:
      (defaultData?.startDate || undefined) && moment(defaultData.startDate),
  };
};

export const taskCountError = [
  'taskCount',
  'require',
  'Add at least one task!',
];

export const emptyTask = (order: number) => ({
  id: uuidv4(),
  order: order + 1,
  sku: '',
  name: '',
  cost: 0,
  price: 0,
  description: '',
  subtasks: [],
});

export const getContainer = (container: IContainer) =>
  container && {
    ...container,
    type: TaskTypeEnum.Container,
    subtasks: container?.subtasks,
    container: {
      condition: container.condition,
      size: container.size,
      grade: container.grade,
      color: container.color,
      num: container.num || '',
      zone: container.zone || '',
    },
  };

export const getTasks = (data: any) =>
  data?.tasks?.map((item, index: number) => ({
    ...item,
    type: item.type || TaskTypeEnum.Common,
    order: index + 1,
    subtasks:
      item.subtasks.map(subtask => ({
        ...subtask,
        templateHours: subtask.templateHours ?? 0,
        checklist: subtask?.checklist?.map(checkItem => ({
          ...checkItem,
          isCompleted: checkItem.isCompleted || false,
        })),
      })) || [],
  }));

export const coverFiles = (projectFiles, file, removeCover) =>
  projectFiles.map(item => ({
    ...item,
    isCover: file.fileUrl === item.fileUrl && !removeCover,
  }));

export const filesForSend = (
  file: IAttachmentInfo,
  value: IAttachmentInfo
) => ({
  filePath: file.filePath,
  fileUrl: file.fileUrl,
  previewPath: file.previewPath,
  previewUrl: file.previewUrl,
  originalName: value.name,
  mimetype: file.mimetype,
  size: getFileSize(value.size),
  isCover: false,
});

export const getLocation = (
  locations: ILocation[],
  currentLocationId: string
) =>
  (hasLocation(locations, currentLocationId) && currentLocationId) || undefined;
