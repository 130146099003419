import { SVGProps } from 'react';

export const Back = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="#172B4D"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83349 1.18811L6.63867 0L0 6.64538L6.64538 13.2908L7.83349 12.1026L2.37623 6.64538L7.83349 1.18811Z"
    />
  </svg>
);
