import { SVGProps } from 'react';

export function ProfileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10411_7646)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zM4.056 13.024C4.4 12.304 6.496 11.6 8 11.6s3.608.704 3.944 1.424A6.314 6.314 0 018 14.4a6.314 6.314 0 01-3.944-1.376zm9.032-1.16C11.944 10.472 9.168 10 8 10c-1.168 0-3.944.472-5.088 1.864A6.36 6.36 0 011.6 8c0-3.528 2.872-6.4 6.4-6.4 3.528 0 6.4 2.872 6.4 6.4a6.36 6.36 0 01-1.312 3.864zM8 3.2A2.793 2.793 0 005.2 6c0 1.552 1.248 2.8 2.8 2.8 1.552 0 2.8-1.248 2.8-2.8 0-1.552-1.248-2.8-2.8-2.8zm0 4c-.664 0-1.2-.536-1.2-1.2 0-.664.536-1.2 1.2-1.2.664 0 1.2.536 1.2 1.2 0 .664-.536 1.2-1.2 1.2z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10411_7646">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
