import { CSSProperties, memo } from 'react';
import { isEqual } from 'lodash';

interface IHasDescriptionsIconProps {
  style: CSSProperties;
  color: string;
}

const HasDescriptionsIcon = ({
  style,
  color,
}: IHasDescriptionsIconProps): JSX.Element => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M15.903 6.586C15.854 6.48 15.793 6.379 15.707 6.293L9.707 0.293C9.621 0.207 9.52 0.146 9.414 0.0969999C9.384 0.0829999 9.352 0.0749999 9.32 0.0639999C9.236 0.0359999 9.15 0.018 9.061 0.013C9.04 0.011 9.021 0 9 0H2C0.897 0 0 0.897 0 2V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V7C16 6.979 15.989 6.96 15.987 6.938C15.982 6.849 15.965 6.763 15.936 6.679C15.926 6.647 15.917 6.616 15.903 6.586ZM12.586 6H10V3.414L12.586 6ZM2 18V2H8V7C8 7.553 8.447 8 9 8H14L14.002 18H2Z"
      fill={color || 'white'}
    />
    <path
      d="M4 10H12V12H4V10ZM4 14H12V16H4V14ZM4 6H6V8H4V6Z"
      fill={color || 'white'}
    />
  </svg>
);

export const HasDescriptions = memo(HasDescriptionsIcon, isEqual);
