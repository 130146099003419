import styled, { css } from 'styled-components';
// constants
import { DESKTOP_WIDTH_PX } from './constants';
// components
import { Button } from 'components/common';
import { Alert } from 'antd';

export const Wrap = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grayLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 44px;
  max-width: 746px;
  width: 100%;
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    margin-top: 68px;
  }
`;

export const ButtonStyled = styled(Button)<{
  isbuttoninactive?: string;
  preset?: any;
}>`
  width: 100%;
  height: 55px;
  margin-bottom: 20px;
  ${({ preset, theme }) => css`
    &,
    &:hover,
    &:focus {
      color: ${preset.color};
      background-color: ${preset.background};
      border: ${preset.border || preset.borderColor
        ? css`1px solid ${preset.borderColor || preset.color}`
        : 'none'};
    }

    &:disabled {
      background-color: ${theme.colors.textGrey}!important;
    }
  `};
  ${({ isbuttoninactive }) =>
    isbuttoninactive === 'true' &&
    css`
      cursor: auto;
    `}
`;

export const ButtonWrap = styled.div<{ withIconButton?: boolean }>`
  width: 100%;

  ${({ withIconButton }) =>
    withIconButton &&
    css`
      display: flex;
      flex-direction: row;
    `}
`;

export const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IconButtonWrap = styled.div`
  margin-right: 7px;
  width: 133px;
`;

export const AlertItem = styled(Alert)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.danger};
  margin-bottom: 15px;

  & .ant-alert-message {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  & .ant-alert-description {
    font-size: 13px;
  }
  &.ant-alert-with-description.ant-alert-no-icon {
    padding: 9px 11px 14px;
  }
  &.ant-alert-error {
    background: ${({ theme }) => theme.colors.lightDanger};
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
