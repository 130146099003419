export enum SearchByEnum {
  Projects = 'projects',
  AllProjects = 'allProjects',
}

export const searchBy = [
  {
    value: SearchByEnum.Projects,
    label: 'Projects',
  },
  {
    value: SearchByEnum.AllProjects,
    label: 'All projects',
  },
];
