import styled from 'styled-components';

export const Wrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-radius: 6px;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1020px) {
    overflow: auto;
  }
`;

export const Body = styled.div``;

export const NoDataWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 20px 0;
`;

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;
