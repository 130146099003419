import styled from 'styled-components';

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;

  .switchButton {
    height: 30px;
    margin-left: 10px;
  }
`;
