import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.div`
  font-weight: 500;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
