import styled from 'styled-components';
import theme from 'styles/theme';
import { Typography } from 'antd';

export const FileItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  width: 132px;
  border-radius: 6px;
  position: relative;
`;

export const FileItemTop = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
`;

export const FileItemBottom = styled.div`
  text-align: left;
  padding: 0 6px;
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileInfoName = styled(Typography.Paragraph)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  margin: 0 !important;
  max-width: 90px;
  color: ${({ theme }) => theme.colors.lightBlue};
`;

export const Cover = styled.div`
  cursor: pointer;
  padding: 0 6px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.primary};
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${theme.colors.white};
  position: absolute;
  text-align: center;
  top: 7px;
  left: 7px;
  svg {
    display: flex;
  }
  .ant-spin.ant-spin-spinning .anticon {
    color: ${theme.colors.white};
    font-size: 14px;
  }
`;

export const CoverText = styled.span`
  position: relative;
  padding-right: 9px;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 8px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    background: ${theme.colors.white};
  }
`;

export const RemoveFileWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
`;
