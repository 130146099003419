import { call, put, select } from 'redux-saga/effects';
import { gradesSelector } from 'store/depot';
import { actions } from 'store/loading';
// redux
import * as Actions from './actions';
// utils
import Api from 'utils/api';
import { GradeDto, IEnsureEditGradePayload, ILoaderPayload } from 'types';

export function* ensureGetGrades({
  payload,
}: {
  payload?: ILoaderPayload;
  type: typeof Actions.grades.TRIGGER;
}) {
  const gradesList = yield select(gradesSelector);
  const noLoader = payload?.noLoader || gradesList?.length;
  try {
    if (!noLoader) yield put(actions.tabLoading(true));
    const data = yield call(Api.grades.getGrades);
    yield put(Actions.grades.success(data));
  } catch (err) {
    yield put(Actions.grades.failure(err));
  } finally {
    if (!noLoader) yield put(actions.tabLoading(false));
  }
}

export function* ensureGetGradeById({
  payload,
}: {
  payload: string;
  type: typeof Actions.getGradeById.TRIGGER;
}) {
  try {
    const data = yield call(Api.grades.getGradeById, payload);
    yield put(Actions.getGradeById.success(data));
  } catch (err) {
    yield put(Actions.getGradeById.failure(err));
  }
}

export function* ensureAddGrade({
  payload,
}: {
  payload: GradeDto;
  type: typeof Actions.addGrade.TRIGGER;
}) {
  try {
    const res = yield call(Api.grades.postGrade, payload);
    yield call(ensureGetGrades, {
      payload: { noLoader: true },
      type: Actions.grades.TRIGGER,
    });
    yield put(Actions.addGrade.success(res));
  } catch (err) {
    yield put(Actions.addGrade.failure(err));
  }
}

export function* ensureDeleteGrade({
  payload,
}: {
  payload: string;
  type: typeof Actions.deleteGrade.TRIGGER;
}) {
  try {
    yield call(Api.grades.deleteGrade, payload);
    yield call(ensureGetGrades, {
      payload: { noLoader: true },
      type: Actions.grades.TRIGGER,
    });
    yield put(Actions.deleteGrade.success());
  } catch (err) {
    yield put(Actions.deleteGrade.failure(err));
  }
}

export function* ensureEditGrade({
  payload,
}: {
  payload: IEnsureEditGradePayload;
  type: typeof Actions.editGrade.TRIGGER;
}) {
  try {
    const data = yield call(Api.grades.putGrade, payload);
    yield call(ensureGetGrades, {
      payload: { noLoader: true },
      type: Actions.grades.TRIGGER,
    });
    yield put(Actions.editGrade.success(data));
  } catch (err) {
    yield put(Actions.editGrade.failure(err));
  }
}
