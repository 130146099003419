import { fork, takeLatest } from 'redux-saga/effects';
// redux
import * as Actions from './actions';
import * as E from './workers';

export function* watchGetTemplates() {
  yield fork(E.ensureGetEmailTemplates, {
    type: Actions.getEmailTemplates.TRIGGER,
  });
  yield takeLatest(
    Actions.getEmailTemplates.TRIGGER,
    E.ensureGetEmailTemplates
  );
}

export function* watchGetTemplateById() {
  yield takeLatest(
    Actions.getEmailTemplateById.TRIGGER,
    E.ensureGetEmailTemplateById
  );
}

export function* watchCreateTemplate() {
  yield takeLatest(
    Actions.createEmailTemplate.TRIGGER,
    E.ensureCreateEmailTemplate
  );
}

export function* watchUpdateTemplate() {
  yield takeLatest(
    Actions.updateEmailTemplate.TRIGGER,
    E.ensureUpdateEmailTemplate
  );
}

export function* watchDeleteTemplate() {
  yield takeLatest(
    Actions.deleteEmailTemplate.TRIGGER,
    E.ensureDeleteEmailTemplate
  );
}
