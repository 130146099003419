import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const gantt = (r: IInitialState) => r.gantt;
export const msInPixelSelector = createSelector(gantt, r => r.msInPixel);
export const isClearGanttStateSelector = createSelector(
  gantt,
  r => r.isClearGanttState
);
export const taskIdSelector = createSelector(gantt, r => r.taskId);
export const subtaskNameSelector = createSelector(gantt, r => r.subtaskName);
export const subtaskItemIdSelector = createSelector(
  gantt,
  r => r.subtaskItemId
);
export const highlightedSubtaskIdSelector = createSelector(
  gantt,
  r => r.highlightedSubtaskId
);
export const highlightedSubtaskSelector = createSelector(
  gantt,
  r => r.highlightedSubtask
);
export const prevItemsSelector = createSelector(gantt, r => r.prevItems);
export const dropFromProjectSelector = createSelector(
  gantt,
  r => r.dropFromProject
);
export const mouseOffsetSelector = createSelector(gantt, r => r.mouseOffset);
export const isGanttDraggingSelector = createSelector(
  gantt,
  r => r.isGanttDragging
);
export const isReportSubtaskDisplayedSelector = createSelector(
  gantt,
  r => r.isReportSubtaskDisplayed
);
export const isOpenStandaloneSubtaskSelector = createSelector(
  gantt,
  r => r.isOpenStandaloneSubtask
);
export const isOpenDrawerSelector = createSelector(gantt, r => r.isOpenDrawer);
export const zoomPercentSelector = createSelector(gantt, r => r.zoomPercent);
export const rowVisibleTimeStartSelector = createSelector(
  gantt,
  r => r.rowVisibleTimeStart
);
export const rowVisibleTimeEndSelector = createSelector(
  gantt,
  r => r.rowVisibleTimeEnd
);
export const canvasTimeStartSelector = createSelector(
  gantt,
  r => r.canvasTimeStart
);
export const keyPressedSelector = createSelector(gantt, r => r.keyPressed);
export const ganttStoreSelector = createSelector(gantt, r => r);
