import { createReducer } from '@reduxjs/toolkit';
import initialState from 'store/initialState';
import * as Actions from './actions';

const depot = createReducer(initialState.depot, {
  [Actions.colors.SUCCESS]: (state, action) => {
    state.colors = action.payload;
  },
  [Actions.sizes.SUCCESS]: (state, action) => {
    state.sizes = action.payload;
  },
  [Actions.getSizeById.SUCCESS]: (state, action) => {
    state.size = action.payload;
  },
  [Actions.conditions.SUCCESS]: (state, action) => {
    state.conditions = action.payload;
  },
  [Actions.getConditionById.SUCCESS]: (state, action) => {
    state.condition = action.payload;
  },
  [Actions.grades.SUCCESS]: (state, action) => {
    state.grades = action.payload;
  },
  [Actions.getGradeById.SUCCESS]: (state, action) => {
    state.grade = action.payload;
  },
  [Actions.truckingCompanies.SUCCESS]: (state, action) => {
    state.companies = action.payload;
  },
  [Actions.getTruckingCompanyById.SUCCESS]: (state, action) => {
    state.company = action.payload;
  },
});

export { depot as reducer };
