import { createAction } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// get resources
export const resources = createRoutine('admin/resources');
export const getResourceById = createRoutine('admin/getResourceById');
export const getResourceByIdPromiseCreator = promisifyRoutine(getResourceById);
// add resources
export const addResource = createRoutine('admin/addResource');
export const addResourcePromiseCreator = promisifyRoutine(addResource);
// update resource
export const updateResource = createRoutine('admin/updateResource');
export const updateResourcePromiseCreator = promisifyRoutine(updateResource);
// delete resource
export const deleteResource = createRoutine('admin/deleteResources');
export const deleteResourcePromiseCreator = promisifyRoutine(deleteResource);
// upload resource photo
export const uploadPhoto = createRoutine('admin/uploadPhoto');
export const uploadPhotoPromiseCreator = promisifyRoutine(uploadPhoto);
// get resource for assign
export const resourcesList = createRoutine('admin/resourcesList');
export const resourcesListPromiseCreator = promisifyRoutine(resourcesList);
// toggle resource status
export const markAsActiveResource = createRoutine('admin/markAsActiveResource');
export const markAsActiveResourcePromiseCreator =
  promisifyRoutine(markAsActiveResource);
export const markAsInactiveResource = createRoutine(
  'admin/markAsInactiveResource'
);
export const markAsInactiveResourcePromiseCreator = promisifyRoutine(
  markAsInactiveResource
);

export const toggleShowAllResources = createAction<boolean>(
  'dashboard/toggleShowAllResources'
);
