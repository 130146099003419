import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { companySelector } from 'store/company';
import { isMobileDevice } from 'utils/helpers';
import { Error, Input, Label } from 'components/common';
import moment from 'moment-timezone';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/en_GB';
import {
  DatePickerMobileStyled,
  DatePickerStyled,
  MobileWrapper,
  PickerInput,
} from './styles';

interface IDateAndTimePickerProps {
  setDate: Dispatch<SetStateAction<moment.Moment>>;
  label?: string;
  format?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  width?: number;
  height?: number;
  widthMarginBottom?: boolean;
  disabledDate?: (value: moment.Moment) => boolean;
  disabledTime?: (value: moment.Moment) => boolean;
  value?: moment.Moment;
}

const defaultFormat = 'MM-DD-YYYY h:mm a';

const DateAndTimePickerComponent = ({
  setDate,
  format = defaultFormat,
  placeholder = 'MM.DD.YYYY HH:MM',
  label,
  value,
  error,
  disabled = false,
  width,
  height,
  widthMarginBottom = false,
  disabledDate,
  disabledTime,
}: IDateAndTimePickerProps) => {
  const { weekStartDay } = useSelector(companySelector, isEqual);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const isMobile = useMemo(
    () => isMobileDevice.any() && format === defaultFormat,
    [format]
  );
  const onMobileChange = useCallback(
    e => setDate(moment(e.target.value)),
    [setDate]
  );

  const onOpenPickerChange = useCallback(
    (open: boolean) => {
      if (open && weekStartDay !== 0 && !isMobile) {
        // Change start of week according to antd documentation https://ant.design/components/date-picker
        moment.updateLocale('en-gb', {
          week: {
            dow: weekStartDay,
          },
        });
      }
      setIsPickerOpen(open);
    },
    [weekStartDay, isMobile]
  );

  useEffect(
    () => () => {
      if (!isPickerOpen && weekStartDay !== 0 && !isMobile) {
        moment.updateLocale('en-gb', {
          week: {
            dow: 0,
          },
        });
      }
    },
    [isMobile, isPickerOpen, weekStartDay]
  );
  const mobileDateRef = useRef<HTMLInputElement>(null);

  const handleMobileClick = useCallback(
    () => mobileDateRef?.current?.focus(),
    []
  );

  return (
    <ConfigProvider locale={locale}>
      {label && (
        <div>
          <Label>{label}</Label>
        </div>
      )}
      {isMobile ? (
        <MobileWrapper>
          <DatePickerMobileStyled
            ref={mobileDateRef}
            type="datetime-local"
            value={value.format('YYYY-MM-DDTHH:mm')}
            onChange={onMobileChange}
            placeholder={placeholder}
            disabled={disabled}
            width={width}
            height={height}
            widthMarginBottom={widthMarginBottom}
            disabledDate={disabledDate}
          />
          <PickerInput onClick={handleMobileClick}>
            <Input
              value={value.format('MM-DD-YYYY HH:mm')}
              placeholder={placeholder}
              width={String(width)}
              height={`${String(height || 30)}px`}
              disabled={disabled}
            />
          </PickerInput>
        </MobileWrapper>
      ) : (
        <DatePickerStyled
          showTime={{ use12Hours: true, format: 'h:mm a' }}
          format={format}
          onOk={setDate}
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          onChange={setDate}
          placeholder={placeholder}
          value={value}
          onOpenChange={onOpenPickerChange}
          disabled={disabled}
          width={width}
          height={height}
          widthMarginBottom={widthMarginBottom}
        />
      )}
      {error && <Error>{error}</Error>}
    </ConfigProvider>
  );
};

export const DateAndTimePicker = memo(DateAndTimePickerComponent, isEqual);
