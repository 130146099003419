import { CSSProperties, SVGProps } from 'react';
import theme from 'styles/theme';

interface ISendProps extends SVGProps<SVGSVGElement> {
  style?: CSSProperties;
  color?: string;
  size?: number;
}
export const Send = ({
  style,
  onClick,
  onBlur,
  onFocus,
  color,
  size = 6,
}: ISendProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 18"
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    <path
      d="M18.4261 8.09496L1.42611 0.0949635C1.07611 -0.0690365 0.663114 -0.0180366 0.365114 0.227963C0.0661136 0.472963 -0.0628864 0.867963 0.0301136 1.24196L1.24211 6.09096L9.00011 8.99996L1.24211 11.909L0.0301136 16.758C-0.0638864 17.132 0.0661136 17.528 0.365114 17.773C0.548114 17.923 0.772114 18 1.00011 18C1.14511 18 1.29011 17.969 1.42611 17.905L18.4261 9.90496C18.7761 9.73996 19.0001 9.38796 19.0001 8.99996C19.0001 8.61196 18.7761 8.25996 18.4261 8.09496Z"
      fill={color || theme.colors.mainDarkBlue}
    />
  </svg>
);
