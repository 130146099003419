interface IExclamationMarkProps {
  fill?: string;
}

export const ExclamationMark = ({
  fill = '#F96302',
}: IExclamationMarkProps): JSX.Element => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3433 0C4.82326 0 0.343262 4.48 0.343262 10C0.343262 15.52 4.82326 20 10.3433 20C15.8633 20 20.3433 15.52 20.3433 10C20.3433 4.48 15.8633 0 10.3433 0ZM11.3433 15H9.34326V13H11.3433V15ZM11.3433 11H9.34326V5H11.3433V11Z"
      fill={fill}
    />
  </svg>
);
