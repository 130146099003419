import styled from 'styled-components';
// constants
import { DESKTOP_WIDTH_PX } from '../constants';
import { List } from 'antd';

export const Wrap = styled.div`
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 11px 14px;
  margin-bottom: 22px;
  width: 100%;
  max-width: 764px;
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    padding: 10px 32px 20px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 10px;
  display: none;
  @media (min-width: ${DESKTOP_WIDTH_PX}) {
    display: block;
  }
`;

export const ListStyled = styled(List)`
  .ant-list-items {
    @media (min-width: ${DESKTOP_WIDTH_PX}) {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;
