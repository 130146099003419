import { memo, useCallback } from 'react';
import { useDragLayer } from 'react-dnd';
import type { Identifier } from 'dnd-core';

interface IPreviewProps {
  generator: (itemType: Identifier, item: any, getStyle: any) => any;
}

export const BodyDragPreview = memo(({ generator }: IPreviewProps) => {
  const { currentOffset, isDragging, itemType, item } = useDragLayer(
    monitor => ({
      currentOffset: monitor.getSourceClientOffset() || {
        x: 0,
        y: 0,
      },
      isDragging: monitor.isDragging() || false,
      itemType: monitor.getItemType() || '',
      item: monitor.getItem() || {},
    })
  );
  const getStyle = useCallback(() => {
    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;

    return {
      pointerEvents: 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      transform,
      WebkitTransform: transform,
    };
  }, [currentOffset]);

  if (!isDragging || currentOffset === null) {
    return null;
  }

  return generator(itemType, item, getStyle());
});
