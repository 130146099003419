import styled, { css } from 'styled-components';

export const TaskWrap = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  margin-bottom: 5px;
`;

export const FormWrap = styled.div`
  padding: 10px 17px;
`;

export const Headingleft = styled.div<{
  isOpen?: boolean;
}>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-right: 17px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  ${props =>
    props.isOpen &&
    css`
      max-width: 100%;
    `};
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayDark};
  margin-bottom: 6px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
`;
