import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { isSchedulerSelector } from 'store/auth';
import {
  checklistSearchItemsPromiseCreator,
  markSubtaskChecklistCompletedPromiseCreator,
  markSubtaskChecklistUncompletedPromiseCreator,
} from 'store/dashboard';
import { prepareChecklist } from './helpers';
import { Autocomplete } from './Autocomplete';
import { CheckboxItem } from './CheckboxItem';
import { BUTTON_TYPES, Button } from 'components/common';
import CloseButton from 'components/common/CloseButton/CloseButton';
import { useActionsRoutines, useAutocomplete } from 'hooks';
import update from 'immutability-helper';
import { noop } from 'lodash';
import theme from 'styles/theme';
import { v4 as uuidv4 } from 'uuid';
import {
  BottomBorder,
  ChecklistContainer,
  ChecklistWrapper,
  FormTitle,
  InputWrapper,
  TitleWrap,
} from './styles';
import { IChecklist } from 'types';

interface IChecklistForm {
  items: IChecklist[];
  onFinishEditing?: (subtaskId: string, checklist: IChecklist[]) => void;
  subtaskId: string;
  disableCheckbox?: boolean;
  projectId?: string;
  isSubtaskType?: boolean;
  isSubtaskTypeChecklist?: boolean;
  createItem?: (title: string) => void;
  updateItem?: (item: IChecklist) => void;
  deleteItem?: (id: string) => void;
  moveItem?: (item: IChecklist) => void;
  isEdit?: boolean;
  handleCloseChecklist?: () => void;
  isProjectArchived?: boolean;
  isProjectDeleted?: boolean;
}
const ChecklistFormComponent: FC<IChecklistForm> = ({
  isEdit,
  items,
  onFinishEditing,
  subtaskId,
  disableCheckbox,
  projectId,
  isSubtaskType,
  isSubtaskTypeChecklist,
  createItem,
  updateItem,
  deleteItem,
  moveItem,
  handleCloseChecklist,
  isProjectDeleted,
}) => {
  const [checklist, setChecklist] = useState<IChecklist[]>(items || []);
  const [newCheckItemTitle, setNewCheckItemTitle] = useState('');
  const [shouldScrollBottom, setShouldScrollBottom] = useState(false);
  const [dragItemStartIndex, setDragItemStartIndex] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);
  const isScheduler = useSelector(isSchedulerSelector);
  const scrollRef = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);
  const markAsCompleted = useActionsRoutines(
    markSubtaskChecklistCompletedPromiseCreator
  );
  const searchChecklist = useActionsRoutines(
    checklistSearchItemsPromiseCreator
  );
  const markAsUncompleted = useActionsRoutines(
    markSubtaskChecklistUncompletedPromiseCreator
  );

  const [onSearch, options, setOptions, onChangeNameField] = useAutocomplete(
    searchChecklist,
    '',
    'text',
    false,
    true
  );
  const autocomplete = useRef<HTMLInputElement>();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setChecklist(
      (items || []).map(item => {
        const newItem = (checklist || []).find(
          newItem => newItem?._id === item?._id
        );

        return {
          ...item,
          _id: item._id || uuidv4(),
          isNew: item._id ? undefined : true,
          isCompleted: item._id ? newItem?.isCompleted : item.isCompleted,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (isSubtaskType && isChanged && onFinishEditing) {
      onFinishEditing(subtaskId, prepareChecklist(checklist));
    }

    return () => {
      if (
        !mounted.current &&
        !isSubtaskTypeChecklist &&
        isChanged &&
        onFinishEditing
      ) {
        onFinishEditing(subtaskId, prepareChecklist(checklist));
      }
    };
  }, [
    subtaskId,
    checklist,
    mounted,
    isChanged,
    isSubtaskType,
    isSubtaskTypeChecklist,
    onFinishEditing,
  ]);

  const moveSubtasks = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCheckbox = checklist[dragIndex];
      const newChecklist = update(checklist, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCheckbox],
        ],
      });

      setIsChanged(true);
      setChecklist(newChecklist);
    },
    [checklist, setChecklist]
  );

  const handleDeleteCheckItem = useCallback(
    id => {
      const newChecklist = checklist.filter(item => item._id !== id);
      setChecklist(newChecklist);
      setIsChanged(true);

      if (isSubtaskType && subtaskId) {
        deleteItem(id);
      }
    },
    [checklist, setChecklist, isSubtaskType, deleteItem, subtaskId]
  );

  const handleChangeNewCheckItem = useCallback(
    (value, option) => {
      setNewCheckItemTitle(value);
      setIsAutocompleteFocused(true);

      if (value.length > 2) {
        onChangeNameField(value, option, noop, noop);
      } else {
        setOptions([]);
      }
    },
    [onChangeNameField, setOptions]
  );

  const handleSelectNewCheckItem = useCallback(
    value => {
      setNewCheckItemTitle(value);
      setIsAutocompleteFocused(false);
      autocomplete?.current?.blur();
    },
    [autocomplete]
  );

  const handleUpdateCheckbox = useCallback(
    updatedItem => {
      const newChecklist = checklist.map(item =>
        item._id === updatedItem._id ? updatedItem : item
      );

      setChecklist(newChecklist);

      if (
        !updatedItem?.isNew &&
        !isEdit &&
        subtaskId &&
        projectId &&
        !disableCheckbox &&
        !isSubtaskType &&
        !isSubtaskTypeChecklist
      ) {
        if (updatedItem?.isCompleted) {
          markAsCompleted({
            subtaskId,
            checklistId: updatedItem?._id,
            projectId,
          }).catch(e => console.error(e));
        } else {
          markAsUncompleted({
            subtaskId,
            checklistId: updatedItem?._id,
            projectId,
          }).catch(e => console.error(e));
        }
      }

      setIsChanged(true);

      if (isSubtaskType && subtaskId) updateItem(updatedItem);
    },
    [
      checklist,
      subtaskId,
      disableCheckbox,
      projectId,
      isSubtaskType,
      isEdit,
      isSubtaskTypeChecklist,
      markAsUncompleted,
      markAsCompleted,
      updateItem,
    ]
  );

  const handleUpdateCheckboxTitle = useCallback(
    updatedItem => {
      const newChecklist = checklist.map(item =>
        item._id === updatedItem._id ? updatedItem : item
      );

      setChecklist(newChecklist);
      setIsChanged(true);

      if (isSubtaskType && subtaskId) updateItem(updatedItem);
    },
    [checklist, subtaskId, isSubtaskType, updateItem]
  );

  const handleAddCheckItem = useCallback(() => {
    if (newCheckItemTitle && newCheckItemTitle.trim().length > 0) {
      const newChecklist = [
        ...checklist,
        {
          _id: uuidv4(),
          title: newCheckItemTitle,
          isCompleted: false,
          isNew: true,
        },
      ];

      setChecklist(newChecklist);
      setNewCheckItemTitle('');
      setOptions([]);
      setIsChanged(true);
      setShouldScrollBottom(true);

      if (isSubtaskType && subtaskId) createItem(newCheckItemTitle);
    }
  }, [
    checklist,
    setChecklist,
    newCheckItemTitle,
    isSubtaskType,
    createItem,
    setOptions,
    subtaskId,
  ]);

  useEffect(() => {
    if (shouldScrollBottom) {
      scrollRef.current.scrollIntoView({ block: 'nearest' });
      setShouldScrollBottom(false);
    }
  }, [checklist.length, shouldScrollBottom]);

  const handlePreventDoubleClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handlePressEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        handleAddCheckItem();

        setOptions([]);
      }
    },
    [handleAddCheckItem, setOptions]
  );

  const handleSaveSubtaskOrder = useCallback(
    updatedItem => {
      if (isSubtaskType && subtaskId) moveItem(updatedItem);
    },
    [isSubtaskType, subtaskId, moveItem]
  );

  const handleSearch = useCallback(
    value => {
      if (value.trim().length > 2) {
        onSearch(value);
      } else {
        setOptions([]);
      }
    },
    [onSearch, setOptions]
  );

  const handleFocusAutocomplete = useCallback(
    value => setIsAutocompleteFocused(value),
    []
  );
  const handleBlurAutocomplete = useCallback(
    () => setIsAutocompleteFocused(false),
    []
  );

  return (
    <ChecklistContainer
      onDoubleClick={handlePreventDoubleClick}
      ref={mounted as any}
      isSubtaskType={isSubtaskType}
    >
      <TitleWrap>
        <FormTitle>Checklist</FormTitle>
        {handleCloseChecklist && <CloseButton onClick={handleCloseChecklist} />}
      </TitleWrap>
      <ChecklistWrapper>
        {checklist?.map((item, index) => (
          <CheckboxItem
            key={item?._id || index}
            item={item}
            index={index}
            moveCheckbox={moveSubtasks}
            deleteCheckbox={handleDeleteCheckItem}
            updateCheckbox={handleUpdateCheckbox}
            updateCheckboxTitle={handleUpdateCheckboxTitle}
            disableCheckbox={disableCheckbox}
            setDragItemStartIndex={setDragItemStartIndex}
            dragItemStartIndex={dragItemStartIndex}
            saveSubtasksOrder={handleSaveSubtaskOrder}
            isProjectDeleted={isProjectDeleted}
          />
        ))}
        <div ref={scrollRef}></div>
      </ChecklistWrapper>
      <InputWrapper>
        <Autocomplete
          width="210px"
          disabled={isProjectDeleted}
          inputref={autocomplete}
          value={newCheckItemTitle}
          placeholder="Type new item"
          onChange={handleChangeNewCheckItem}
          onSearch={handleSearch}
          onKeyDown={handlePressEnter}
          onSelect={handleSelectNewCheckItem}
          onBlur={handleBlurAutocomplete}
          onFocus={handleFocusAutocomplete}
          options={options}
          open={
            isAutocompleteFocused &&
            options.length &&
            newCheckItemTitle?.length > 2
          }
        />
        <Button
          padding="6px 10px"
          color={theme.colors.lightDeepBlue}
          kind={BUTTON_TYPES.SECONDARY}
          label="Add"
          onClick={handleAddCheckItem}
          disabled={
            newCheckItemTitle.trim().length === 0 ||
            isProjectDeleted ||
            isScheduler
          }
        />
      </InputWrapper>

      <BottomBorder />
    </ChecklistContainer>
  );
};

export const ChecklistForm = memo(ChecklistFormComponent, isEqual);
