import { prepareDuplicateSubtaskList } from 'utils/prepareDuplicateSubtaskData';
import { IProjectResponseDto, ISubtaskItemData } from 'types';

interface PrepareDuplicatePayloadProps {
  currentSubtask: ISubtaskItemData;
  projectData: IProjectResponseDto;
  amount: number;
  taskId: string;
}

export const prepareDuplicatePayload = ({
  currentSubtask,
  amount,
  projectData,
  taskId,
}: PrepareDuplicatePayloadProps) => {
  const duplicatedSubtasks = prepareDuplicateSubtaskList({
    currentSubtask,
    amount,
  });

  const updatedProject = {
    ...projectData,
    tasks: projectData.tasks.map(task => {
      if (task._id === taskId) {
        return {
          ...task,
          subtasks: [...task.subtasks, ...duplicatedSubtasks],
        };
      }
      return task;
    }),
  };

  return updatedProject;
};
