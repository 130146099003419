import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { downloadPdfPromiseCreator } from 'store/sharing';
// components
import Preview from 'components/PreviewAttachment';
import { useActionsRoutines } from 'hooks';
import { IMAGES_MIME_TYPES } from 'lib/constants';
// styles
import { FileListItem } from './styles';
import { FileUploadResDto } from 'types';

interface IFileItemProps {
  file: FileUploadResDto;
}

const FileItem = ({ file }: IFileItemProps): JSX.Element => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const downloadPdf = useActionsRoutines(downloadPdfPromiseCreator);
  const { filePath: href } = file;
  const isImage = useMemo(
    () => IMAGES_MIME_TYPES.includes(file.mimetype),
    [file]
  );

  const onItemClick = () => {
    if (!isImage || !file.fileUrl) return;
    setIsPreviewOpen(true);
  };

  const onPreviewClose = (e: ChangeEvent) => {
    e.stopPropagation();
    setIsPreviewOpen(false);
  };

  const handleDownloadPdf = useCallback(() => {
    if (href) {
      downloadPdf({ data: href, originalName: file?.originalName }).catch(e =>
        console.error(e)
      );
    }
  }, [href, file?.originalName, downloadPdf]);

  return (
    <FileListItem onClick={onItemClick}>
      <Preview
        showPreview={isPreviewOpen}
        closePreview={onPreviewClose}
        file={file}
      />
      <a onClick={handleDownloadPdf}>{file.originalName}</a>
    </FileListItem>
  );
};

export default memo(FileItem, isEqual);
