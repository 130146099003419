import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useFormContext } from 'react-hook-form';
import SubtaskActions from 'components/SubtaskActions';
import SubtaskForm from 'components/SubtaskForm';
// components
import DeleteButton from 'components/TaskForm/DeleteButton';
// styles
import { SubtaskListItemWrap, SubtasksListItem } from './styles';
import {
  HookFormRecord,
  IProject,
  ISubtask,
  ISubtaskTypeDto,
  ISubtasksErrors,
} from 'types';

interface ISubtaskItemProps {
  subtask: ISubtask;
  name: string;
  i: number;
  subtaskTypes: ISubtaskTypeDto[];
  remove: (id: number) => void;
  subtasksValue: ISubtask;
  isInProject: boolean;
  isTemplate: boolean;
  errors: ISubtasksErrors;
  projectId: string;
  project?: IProject;
  isEdit: boolean;
  setRemoveChatsIds?: Dispatch<SetStateAction<string[]>>;
  subtasksCount: number;
  handleDuplicateSubtask: (index: number, amount: number) => void;
}

const SubtaskItem = ({
  subtask,
  subtaskTypes,
  i,
  remove,
  name,
  errors,
  subtasksValue,
  isInProject,
  isTemplate,
  projectId,
  project,
  isEdit,
  setRemoveChatsIds,
  subtasksCount,
  handleDuplicateSubtask,
}: ISubtaskItemProps) => {
  const form = useFormContext();

  const { setValue, register, clearErrors, control, setError } = form;

  const [subtaskTypeId, setSubtaskTypeId] = useState();
  const [subtaskName, setSubtaskName] = useState();

  const defaultHours = useMemo(
    () => subtaskTypes.find(item => item.name === subtaskName)?.defaultHours,
    [subtaskTypes, subtaskName]
  );

  const [checklist, setChecklist] = useState(subtask?.checklist);
  const [isSubtaskTypeChecklist, setIsSubtaskTypeChecklist] = useState(false);

  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  useEffect(() => {
    register(`${name}subtasks[${i}].subtaskTypeId` as HookFormRecord);
    register(`${name}subtasks[${i}]._id` as HookFormRecord);
    register(`${name}subtasks[${i}].assignmentDate` as HookFormRecord);
    register(`${name}subtasks[${i}].status` as HookFormRecord);
    register(`${name}subtasks[${i}].fileUrls` as HookFormRecord);
    register(`${name}subtasks[${i}].changelog` as HookFormRecord);
    register(`${name}subtasks[${i}].resource` as HookFormRecord);
    register(`${name}subtasks[${i}].startDate` as HookFormRecord);
    register(`${name}subtasks[${i}].dueDate` as HookFormRecord);

    register(`${name}subtasks[${i}].notes` as HookFormRecord);
    register(`${name}subtasks[${i}].files` as HookFormRecord);
    register(`${name}subtasks[${i}].comments` as HookFormRecord);
    register(`${name}subtasks[${i}].estimatedStartDate` as HookFormRecord);
    register(`${name}subtasks[${i}].estimatedDueDate` as HookFormRecord);

    register(`${name}subtasks[${i}].actualHours` as HookFormRecord);
    register(`${name}subtasks[${i}].templateHours` as HookFormRecord);
    register(`${name}subtasks[${i}].checklist` as HookFormRecord);

    setValue(`${name}subtasks[${i}].status` as HookFormRecord, subtask?.status);
    setValue(`${name}subtasks[${i}]._id` as HookFormRecord, subtask?._id);
    setValue(
      `${name}subtasks[${i}].fileUrls` as HookFormRecord,
      subtask?.fileUrls
    );
    setValue(
      `${name}subtasks[${i}].changelog` as HookFormRecord,
      subtask?.changelog
    );
    setValue(
      `${name}subtasks[${i}].resource` as HookFormRecord,
      subtask?.resource
    );
    setValue(
      `${name}subtasks[${i}].startDate` as HookFormRecord,
      subtask?.startDate
    );
    setValue(
      `${name}subtasks[${i}].dueDate` as HookFormRecord,
      subtask?.dueDate
    );
    setValue(`${name}subtasks[${i}].notes` as HookFormRecord, subtask?.notes);
    setValue(`${name}subtasks[${i}].files` as HookFormRecord, subtask?.files);
    setValue(
      `${name}subtasks[${i}].comments` as HookFormRecord,
      subtask?.comments
    );
    setValue(
      `${name}subtasks[${i}].assignmentDate` as HookFormRecord,
      subtask?.assignmentDate
    );
    setValue(
      `${name}subtasks[${i}].actualHours` as HookFormRecord,
      subtask?.actualHours || 0
    );
    setValue(
      `${name}subtasks[${i}].templateHours` as HookFormRecord,
      subtask?.templateHours
    );
    setValue(
      `${name}subtasks[${i}].checklist` as HookFormRecord,
      subtask?.checklist || []
    );
    setValue(
      `${name}subtasks[${i}].estimatedStartDate` as HookFormRecord,
      subtask?.estimatedStartDate
    );
    setValue(
      `${name}subtasks[${i}].estimatedDueDate` as HookFormRecord,
      subtask?.estimatedDueDate
    );

    if (subtask?.generatedApiKey) {
      register(`${name}subtasks[${i}].generatedApiKey` as HookFormRecord);
      setValue(
        `${name}subtasks[${i}].generatedApiKey` as HookFormRecord,
        subtask?.generatedApiKey
      );
    }

    if (subtask?.rejectReason) {
      register(`${name}subtasks[${i}].rejectReason` as HookFormRecord);
      setValue(
        `${name}subtasks[${i}].rejectReason` as HookFormRecord,
        subtask?.rejectReason
      );
    }

    if (subtask?.waitForRequiredPhotos) {
      register(`${name}subtasks[${i}].waitForRequiredPhotos` as HookFormRecord);
      setValue(
        `${name}subtasks[${i}].waitForRequiredPhotos` as HookFormRecord,
        subtask.waitForRequiredPhotos
      );
    }
  }, [i, name, register, setValue, subtask]);

  useEffect(() => {
    // @ts-ignore
    setValue(
      `${name}subtasks[${i}].subtaskTypeId` as HookFormRecord,
      subtaskTypeId || subtask?.subtaskTypeId
    );
    setValue(
      `${name}subtasks[${i}].name` as HookFormRecord,
      subtaskName || subtask?.name
    );
  }, [subtask, subtaskTypeId, setValue, i, name, subtaskName]);
  const handleRemoveSubtask = useCallback(() => {
    remove(i);
    if (subtask?._id && setRemoveChatsIds) {
      setRemoveChatsIds(prevState => [...prevState, subtask._id]);
    }
  }, [remove, i, setRemoveChatsIds, subtask._id]);

  const handleSetSubtaskTypeId = useCallback(
    id => {
      const subtaskType = subtaskTypes.find(item => item._id === id);

      setSubtaskTypeId(id);
      setChecklist(
        subtaskType?.checklistTemplate?.map(item => ({
          title: item.title,
          isCompleted: false,
          _id: item._id,
        })) || []
      );
      if (!isInProject) setIsSubtaskTypeChecklist(true);
    },
    [isInProject, subtaskTypes]
  );

  return (
    <SubtaskListItemWrap>
      <SubtasksListItem>
        <SubtaskForm
          setError={setError}
          subtask={subtask}
          subtaskTypes={subtaskTypes}
          i={i}
          name={name}
          errors={errors}
          setSubtaskName={setSubtaskName}
          defaultHours={defaultHours}
          setSubtaskTypeId={handleSetSubtaskTypeId}
          isTemplate={isTemplate}
        />
        <SubtaskActions
          index={i}
          handleDuplicateSubtask={handleDuplicateSubtask}
          subtasksCount={subtasksCount}
          isEdit={isEdit}
          isTemplate={isTemplate}
          subtaskId={subtask._id}
          name={`${name}subtasks[${i}].`}
          notesValue={subtask?.notes}
          notesError={errors?.subtasks && errors?.subtasks[i]?.notes?.message}
          filesValue={subtasksValue?.files || subtask?.files || []}
          setValue={setValue}
          control={control}
          setChecklist={setChecklist}
          isInProject={isInProject}
          disableChecklist={!isInProject}
          checklist={checklist}
          projectId={projectId}
          project={project}
          isSubtaskTypeChecklist={isSubtaskTypeChecklist}
        />
      </SubtasksListItem>
      <DeleteButton
        remove={handleRemoveSubtask}
        text="Are you sure you want to delete this task?"
      />
    </SubtaskListItemWrap>
  );
};

export default memo(SubtaskItem, isEqual);
