import styled, { css } from 'styled-components';

export const ProgressStyled = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayDark};
`;

export const Title = styled.div<{ isOpen?: boolean; large: string }>`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: ${({ large }) => (large ? '14px' : '12px')};
  line-height: ${({ large }) => (large ? '17px' : '15px')};
  max-width: ${({ large }) => (large ? '100%' : '290px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.isOpen &&
    css`
      max-width: 360px;
    `};
`;

export const ProgressSegment = styled.div<{ type: string; width: number }>`
  height: 3px;
  width: ${({ width }) => width}%;
  background-color: ${({ theme, type }) =>
    type === 'Done'
      ? theme.colors.eucalyptus
      : type === 'In progress'
      ? theme.colors.lightDeepBlue
      : theme.colors.orange};
`;

export const Percent = styled.div<{
  roundPercent?: number;
}>`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 10%;
  line-height: 16px;
  margin-left: 7px;
  color: ${({ theme, roundPercent }) =>
    roundPercent === 0
      ? theme.colors.textGreyDark
      : roundPercent === 100
      ? theme.colors.eucalyptus
      : theme.colors.lightDeepBlue};
`;

export const ProgressRow = styled.div<{ large?: string }>`
  display: flex;
  margin-top: ${({ large }) => (large ? '5px' : '3px')};
  align-items: center;
`;
