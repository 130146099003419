import { SVGProps } from 'react';

export function TimesheetsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_10411_7632)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.992 0C3.576 0 0 3.584 0 8s3.576 8 7.992 8C12.416 16 16 12.416 16 8s-3.584-8-8.008-8zM8 14.4A6.398 6.398 0 011.6 8c0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4zM8.4 4H7.2v4.8l4.2 2.52.6-.984L8.4 8.2V4z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_10411_7632">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
