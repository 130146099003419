import moment from 'moment-timezone';
import { IResource } from '../../types/common/resource';
import { IAssignSubtaskData } from 'types';

const isSafari = /^((?!chrome|android).)*safari/i.test(
  window.navigator.userAgent
);

export const getDataForAssign = (
  resource: IResource,
  minStartDate?: moment.Moment
): IAssignSubtaskData => {
  const { workingTime } = resource;
  let startDate = moment().set({
    hour: workingTime.from.hours,
    minute: workingTime.from.minutes,
    second: 0,
    millisecond: 0,
  });

  if (minStartDate) {
    if (startDate.isBefore(moment(minStartDate))) {
      startDate = moment(minStartDate).set({
        hour: workingTime.from.hours,
        minute: workingTime.from.minutes,
        second: 0,
        millisecond: 0,
      });
    }
  }

  return { startDate, resource };
};

export const getItemStyles = (initialOffset, clientOffset) => {
  if (!initialOffset || !clientOffset) return { display: 'none' };
  const { x, y } = clientOffset;
  let transform = `translate3d(${x}px, ${y}px, 0)`;

  if (isSafari) {
    transform = `translate3d(0, 0, 0)`;
  }

  return {
    transform,
    WebkitTransform: transform,
  };
};
