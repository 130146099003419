import styled from 'styled-components';

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const TitleCompanyInfo = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const TitleCreateProject = styled.div`
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-right: 25px;
  margin-top: 8px;
`;
