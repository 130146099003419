import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'components/common';

const UserDetailsBlock = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <>
      <Controller
        render={({ field }) => (
          <Input
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="First name"
            isRequired
            autocomplete={false}
            width="100%"
            height="30px"
            style={{ paddingBottom: '15px' }}
            placeholder="Type first name"
            error={errors?.firstName?.message}
          />
        )}
        name={`firstName`}
        control={control}
      />
      <Controller
        render={({ field }) => (
          <Input
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="Last name"
            isRequired
            autocomplete={false}
            width="100%"
            height="30px"
            style={{ paddingBottom: '15px' }}
            placeholder="Type last name"
            error={errors?.lastName?.message}
          />
        )}
        name={`lastName`}
        control={control}
      />

      <Controller
        render={({ field }) => (
          <Input
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            label="Job title"
            autocomplete={false}
            width="100%"
            height="30px"
            style={{ paddingBottom: '15px' }}
            placeholder="Enter job title"
            error={errors?.jobTitle?.message}
          />
        )}
        name="jobTitle"
        control={control}
      />
    </>
  );
};

export default UserDetailsBlock;
