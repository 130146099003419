interface IAssigneeUserProps {
  size?: string;
}
export const AssigneeUser = ({
  size = '27',
}: IAssigneeUserProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3329 19.9998H15.3329C14.6329 19.9998 14.1663 19.5332 14.1663 18.8332C14.1663 18.1332 14.6329 17.6665 15.3329 17.6665H22.3329C23.0329 17.6665 23.4996 18.1332 23.4996 18.8332C23.4996 19.5332 23.0329 19.9998 22.3329 19.9998Z"
      fill="#172B4D"
    />
    <path
      d="M18.8329 23.4998C18.1329 23.4998 17.6663 23.0332 17.6663 22.3332V15.3332C17.6663 14.6332 18.1329 14.1665 18.8329 14.1665C19.5329 14.1665 19.9996 14.6332 19.9996 15.3332V22.3332C19.9996 23.0332 19.5329 23.4998 18.8329 23.4998Z"
      fill="#172B4D"
    />
    <path
      d="M18.8329 26.9998C14.2829 26.9998 10.6663 23.3832 10.6663 18.8332C10.6663 14.2832 14.2829 10.6665 18.8329 10.6665C23.3829 10.6665 26.9996 14.2832 26.9996 18.8332C26.9996 23.3832 23.3829 26.9998 18.8329 26.9998ZM18.8329 12.9998C15.5663 12.9998 12.9996 15.5665 12.9996 18.8332C12.9996 22.0998 15.5663 24.6665 18.8329 24.6665C22.0996 24.6665 24.6663 22.0998 24.6663 18.8332C24.6663 15.5665 22.0996 12.9998 18.8329 12.9998Z"
      fill="#172B4D"
    />
    <path
      d="M11.8331 14.1665C10.7831 14.1665 9.84976 13.8165 9.03309 13.3498C7.04976 12.0665 5.99976 9.38317 5.99976 5.99984C5.99976 2.73317 8.56642 0.166504 11.8331 0.166504C15.0998 0.166504 17.6664 2.73317 17.6664 5.99984C17.6664 9.38317 16.6164 12.0665 14.6331 13.3498C13.8164 13.8165 12.8831 14.1665 11.8331 14.1665ZM11.8331 2.49984C9.84976 2.49984 8.33309 4.0165 8.33309 5.99984C8.33309 8.5665 9.03309 10.5498 10.3164 11.3665C11.2498 11.9498 12.4164 11.9498 13.3498 11.3665C14.6331 10.5498 15.3331 8.5665 15.3331 5.99984C15.3331 4.0165 13.8164 2.49984 11.8331 2.49984Z"
      fill="#172B4D"
    />
    <path
      d="M11.8329 25.8331C9.14959 25.8331 0.16626 25.4831 0.16626 21.1664C0.16626 16.8497 3.31626 15.3331 6.81626 14.2831C7.04959 14.1664 7.98293 13.8164 8.33293 12.0664L10.6663 12.5331C10.3163 14.6331 9.14959 15.9164 7.51626 16.4997C4.01626 17.6664 2.49959 18.5997 2.49959 21.1664C2.49959 22.4497 7.39959 23.4997 11.8329 23.4997C11.8329 23.4997 13.1163 23.4997 14.6329 23.3831L14.8663 25.7164C13.2329 25.8331 11.8329 25.8331 11.8329 25.8331Z"
      fill="#172B4D"
    />
    <path
      d="M13.3498 13.8164C13.1164 13.3497 12.9998 12.8831 12.9998 12.5331L15.3331 12.0664C15.3331 12.2997 15.4498 12.6497 15.5664 12.9997L13.3498 13.8164Z"
      fill="#172B4D"
    />
  </svg>
);
