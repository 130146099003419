import { Status, Title, Value, Wrap, WrapItem } from './styles';

interface ICompanyInfoProps {
  data: any;
}

const CompanyInfo = ({ data }: ICompanyInfoProps) => (
  <Wrap>
    <WrapItem>
      <Title>Client:</Title>
      <Value>{data?.customer}</Value>
    </WrapItem>
    <WrapItem>
      <Title>Account:</Title>
      <Value>{data?.account}</Value>
    </WrapItem>
    <WrapItem>
      <Title>Order #:</Title>
      <Value>{data?.invoice}</Value>
    </WrapItem>
    <WrapItem>
      <Title>Status:</Title>
      <Status status={data?.status}>{data?.status}</Status>
    </WrapItem>
  </Wrap>
);

export default CompanyInfo;
