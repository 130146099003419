import CollapseIcon from 'lib/icons/CollapseIcon';
import { ArrowIconWrap } from './styles';

interface ICollapseButton {
  isOpen: boolean;
}

const CollapseButton = ({ isOpen }: ICollapseButton): JSX.Element => (
  <ArrowIconWrap $isOpen={isOpen}>
    <CollapseIcon />
  </ArrowIconWrap>
);

export default CollapseButton;
