import { Dispatch, SetStateAction, useCallback } from 'react';
import { isMobileDevice } from 'utils/helpers';
import { PercentButtonsGroup } from '../../../common/PercentButtonsGroup';
// components
import { Button, DateAndTimePicker } from 'components/common';
import { PercentWrap, Title, Wrap } from './styles';
import { IConflictSubtaskErorr } from 'types';

interface IDateSelectProps {
  isAllowToSubmit: boolean;
  isLoading: boolean;
  showPercents: boolean;
  setDate: Dispatch<SetStateAction<moment.Moment>>;
  onPercentChange?: (percent: number) => void;
  onSubmitClick: () => void;
  conflictSubtask: IConflictSubtaskErorr;
  defaultPercent?: number;
  width?: number;
  value: moment.Moment;
}

const DateSelect = ({
  setDate,
  onSubmitClick,
  isLoading,
  isAllowToSubmit,
  showPercents,
  onPercentChange,
  conflictSubtask,
  value,
  defaultPercent,
  width,
}: IDateSelectProps) => {
  const handleMouseDown = useCallback(
    e => (isMobileDevice.any() ? undefined : e.preventDefault()),
    []
  );

  return (
    <Wrap onMouseDown={handleMouseDown}>
      <Title>Select date & time</Title>
      <DateAndTimePicker
        widthMarginBottom
        width={width}
        setDate={setDate}
        value={value}
      />
      {(showPercents || conflictSubtask) && (
        <PercentWrap>
          <PercentButtonsGroup
            defaultPercent={defaultPercent}
            conflictSubtask={conflictSubtask}
            onChange={onPercentChange}
          />
        </PercentWrap>
      )}
      <Button
        loading={isLoading}
        disabled={!isAllowToSubmit}
        width="137px"
        label="Submit"
        onClick={onSubmitClick}
      />
    </Wrap>
  );
};

export default DateSelect;
