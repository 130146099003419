import styled from 'styled-components';
import { Button } from 'components/common';
import { PROJECTS_STATUSES_STYLES } from 'lib/constants';
import theme from 'styles/theme';
import { ProjectStatusesEnum } from 'types';

export const StatusWrap = styled.div`
  display: flex;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 16px;
  gap: 8px;
`;

export const Status = styled.div<{
  status: ProjectStatusesEnum;
}>`
  width: 109px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => PROJECTS_STATUSES_STYLES[status].color};
  background: ${({ status }) => PROJECTS_STATUSES_STYLES[status].background};
  border-radius: 4px;
`;

export const ButtonStyled = styled(Button)`
  width: 164px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 0;
  background-color: ${theme.colors.white};
  color: ${theme.colors.mainDarkBlue};
  border: 1px solid ${theme.colors.grayDark};
  border-radius: 6px;
  svg {
    margin-right: 4px;
  }
  &:hover,
  &:focus {
    background-color: ${theme.colors.white};
    color: ${theme.colors.mainDarkBlue};
    border: 1px solid ${theme.colors.eucalyptus};
  }
`;
export const UndoButton = styled(Button)`
  height: 30px;
  font-size: 12px;
`;
