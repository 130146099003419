import { createSelector } from '@reduxjs/toolkit';
import { IInitialState, TaskTypeEnum } from 'types';

const admin = (r: IInitialState) => r.admin;
export const subtaskTypesSelector = createSelector(admin, r => r.subtaskTypes);
export const subtaskTypesDefaultsSelector = createSelector(
  admin,
  r => r.subtaskTypesDefaults
);
export const subtaskTypesListSelector = createSelector(
  admin,
  r => r.subtaskTypesList
);
export const subtaskTypesObjectSelector = createSelector(
  admin,
  r => r.subtaskTypesObject
);

export const subtaskTypesSearchSelector = createSelector(
  admin,
  r => r.subtaskTypesSearch
);
export const defaultContainerTemplateSelector = createSelector(
  admin,
  r => r.defaultContainerTemplate
);
export const tasksSelector = createSelector(admin, r =>
  r.tasks.filter(({ type }) => type === TaskTypeEnum.Common)
);
export const containersSelector = createSelector(admin, r =>
  r.tasks.filter(({ type }) => type === TaskTypeEnum.Container)
);
export const taskSelector = createSelector(admin, r => r.task);
export const relatedTasksSelector = createSelector(admin, r => r.relatedTasks);
export const resourcesSelector = createSelector(admin, r => r.resources);
export const resourceSelector = createSelector(admin, r => r.resource);
export const groupsSelector = createSelector(admin, r => r.groups);
export const emailTemplatesSelector = createSelector(
  admin,
  r => r.emailTemplates
);
export const recurringTasksSelector = createSelector(
  admin,
  r => r.recurringTasks
);
export const recurringTaskSelector = createSelector(
  admin,
  r => r.recurringTask
);
export const containerOptionsSelector = createSelector(
  admin,
  r => r.containerOptions
);
export const isContainerOptionsLoadingSelector = createSelector(
  admin,
  r => r.isContainerOptionsLoading
);

export const tasksSearchSelector = createSelector(admin, r => r.tasksSearch);

export const invitationsListSelector = createSelector(
  admin,
  r => r.invitationsList
);

export const showAllResourcesSelector = createSelector(
  admin,
  r => r.showAllResources
);

export const resourcesLoadingSelector = createSelector(
  admin,
  r => r.isResourcesLoading
);

export const csvExportingSelector = createSelector(
  admin,
  r => r.isCsvExporting
);

export const sampleCsvExportLoadingSelector = createSelector(
  admin,
  r => r.isSampleCsvExporting
);

export const csvImportingSelector = createSelector(
  admin,
  r => r.isCsvImporting
);

export const csvImportErrorsSelector = createSelector(
  admin,
  r => r.csvImportErrors
);

export const csvInvalidRowNumbersSelector = createSelector(
  admin,
  r => r.csvInvalidRowNumbers
);
