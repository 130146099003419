import styled from 'styled-components';

export const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NotificationDate = styled.div`
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightBlue};
`;
