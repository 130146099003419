import { createSelector } from '@reduxjs/toolkit';
import { IInitialState } from 'types';

const timesheets = (r: IInitialState) => r.timesheets;
export const timesheetsSelector = createSelector(timesheets, r => r.timesheets);
export const timesheetsDatesSelector = createSelector(
  timesheets,
  r => r.timesheetsDates
);
export const resourceTimesheetsSelector = createSelector(
  timesheets,
  r => r.resourceTimesheets
);
export const searchSelector = createSelector(timesheets, r => r.search);
export const closestDatesSelector = createSelector(
  timesheets,
  r => r.closestDates
);
