import theme from 'styles/theme';

interface IPaperClipProps {
  width?: string;
  height?: string;
  color?: string;
}

export const PaperClip = ({
  width = '7',
  height = '14',
  color = theme.colors.mainDarkBlue,
}: IPaperClipProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 15"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22727 3.38834V11.2292C7.22727 12.7361 6.00682 13.9565 4.5 13.9565C2.99318 13.9565 1.77273 12.7361 1.77273 11.2292V2.70652C1.77273 1.76561 2.53636 1.00198 3.47727 1.00198C4.41818 1.00198 5.18182 1.76561 5.18182 2.70652V9.86561C5.18182 10.2406 4.875 10.5474 4.5 10.5474C4.125 10.5474 3.81818 10.2406 3.81818 9.86561V3.38834H2.79545V9.86561C2.79545 10.8065 3.55909 11.5702 4.5 11.5702C5.44091 11.5702 6.20455 10.8065 6.20455 9.86561V2.70652C6.20455 1.1997 4.98409 -0.020752 3.47727 -0.020752C1.97045 -0.020752 0.75 1.1997 0.75 2.70652V11.2292C0.75 13.302 2.42727 14.9792 4.5 14.9792C6.57273 14.9792 8.25 13.302 8.25 11.2292V3.38834H7.22727Z"
    />
  </svg>
);
