import momentTimezone from 'moment-timezone';

export const getNewTimezoneTime = (startDate, endDate, utcOffset, timezone) => {
  const preparedStartTime =
    utcOffset >= 0
      ? momentTimezone(startDate).startOf('day')
      : momentTimezone(startDate).endOf('day');
  const preparedEndTime =
    utcOffset >= 0
      ? momentTimezone(endDate).startOf('day')
      : momentTimezone(endDate).endOf('day');
  const startDateWithTimezone = preparedStartTime.tz(timezone);
  const endDateWithTimezone = preparedEndTime.tz(timezone);
  const isReportWeek = preparedEndTime.diff(preparedStartTime, 'days') > 2;

  return {
    startDate: startDateWithTimezone.startOf('day'),
    endDate: isReportWeek
      ? endDateWithTimezone.endOf('day')
      : endDateWithTimezone.startOf('day'),
  };
};
