import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { Input } from 'antd';

export const Wrap = styled.div<{
  messagesInit?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 7px 15px;
  min-height: 345px;
  justify-content: space-between;
  width: 302px;
  &::-webkit-scrollbar {
    display: none;
  }

  .ScrollbarsCustom-Track {
    background: none !important;
    width: 4px !important;
    height: calc(100% - 10px) !important;
  }

  ${props =>
    props.messagesInit &&
    css`
      .ScrollbarsCustom-Content {
        align-items: center !important;
        display: flex !important;
        justify-content: center !important;
        flex-direction: column;
      }
    `}
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 4px;
  padding-top: 6px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;

export const CloseWrap = styled.div`
  padding-top: 6px;
  cursor: pointer;
`;

export const InputWrap = styled.div`
  padding: 10px 0 16px 0;
  display: flex;
  position: relative;
`;

export const SendIconWrap = styled.div<{
  isActive?: boolean;
}>`
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  position: absolute;
  right: 11px;
  bottom: 7px;
  height: 18px;
`;

export const ChatWrap = styled.div`
  position: relative;
`;

export const MessagesCoutner = styled.div`
  font-size: 8px;
  line-height: 0;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 30px;
`;

export const ContentWrap = styled.div<{
  $isInProject?: boolean;
}>`
  height: 100%;
  .ScrollbarsCustom-Scroller {
    margin-right: ${({ $isInProject }) =>
      $isInProject ? '0 !important' : 'unset'};
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const MessagesWrap = styled.div`
  height: 185px;
  overflow: auto;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextAreaItem = styled(Input.TextArea)`
  resize: none;
  display: flex;
  padding: 5px 25px 5px 10px;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.mutedInputBorderColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
  width: 100%;
  outline: none;
  line-height: 2.5;
  min-height: 32px !important;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.mainDarkBlue};
    box-shadow: none;
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
`;

export const ReplyForInput = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px;
  width: 100%;
  margin-bottom: -2px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: ${theme.colors.grayLight};
`;
export const ReplyContent = styled.div`
  flex: 1;
`;

export const ReplyCreator = styled.div`
  padding-top: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  word-break: break-word;
  color: ${theme.colors.lightBlue};
`;

export const ArrowWrapper = styled.div`
  margin-right: 7px;
`;
