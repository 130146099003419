import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { projects } from 'store/dashboard';
import {
  globalSearch,
  projectsAllFilter,
  searchSelector,
} from 'store/predefined';
import { SearchByEnum, searchBy } from './constants';
import { SearchInput } from 'components/common';
import { useActions } from 'hooks';
import { debounce } from 'lodash';
import { Select } from 'antd';
import { GroupStyled, OptionItem, SearchWrap, SelectStyled } from './styles';

const { Option } = Select;

const Search = (): JSX.Element => {
  const getProjects = useActions(projects);
  const setSearch = useActions(globalSearch);
  const search = useSelector(searchSelector);
  const setProjectsFilter = useActions(projectsAllFilter);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!search) setSearchValue('');
  }, [search]);

  const delayedQuery = useRef(
    debounce(val => {
      setSearch(val || '');
      getProjects({ noLoader: true, searchInit: true });
    }, 300)
  ).current;

  const handleSearch = useCallback(
    e => {
      const value = e.target.value;
      setSearchValue(value);
      delayedQuery(value);
    },
    [delayedQuery]
  );

  const handleChangeSearchType = (value: SearchByEnum) => {
    if (value === SearchByEnum.AllProjects) {
      setProjectsFilter(true);
      getProjects({ noLoader: true, refetch: true });
    }

    if (value === SearchByEnum.Projects) {
      setProjectsFilter(false);
      getProjects({ noLoader: true, refetch: true });
    }
  };

  return (
    <SearchWrap>
      <GroupStyled compact>
        <SearchInput
          onChange={handleSearch}
          value={searchValue}
          className="search"
          fontSize="13px"
          width='150px'
        />
        <SelectStyled
          defaultValue={SearchByEnum.Projects}
          onChange={handleChangeSearchType}
        >
          {searchBy.map(searchEl => (
            <Option value={searchEl.value} key={searchEl.value}>
              <OptionItem>{searchEl.label}</OptionItem>
            </Option>
          ))}
        </SelectStyled>
      </GroupStyled>
    </SearchWrap>
  );
};

export default Search;
