import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  clearAllSubtaskFilesBuffers,
  deleteChatMessagesBySubtaskIdsCreator,
} from 'store/dashboard';
// redux
import { isFilesLoadingSelector } from 'store/loading';
import { yupResolver } from '@hookform/resolvers/yup';
import TaskForm from 'components/TaskForm';
import { ConfirmationPopover } from 'components/common';
import { useActions, useActionsRoutines } from 'hooks';
// icons
import { Cross } from 'lib/icons';
// components
import DeleteButton from 'pages/Dashboard/Project/common/DeleteButton';
import { ProjectTaskSchema } from 'validation';
import { ButtonsWrap, FormHeader, FormWrap, Headingleft } from './styles';
// styles
import { ButtonSaveStyled } from 'pages/Dashboard/Project/ProjectView/styles';
import { IProjectTaskDto, ISubtaskTypeDto, OnProjectUpdateSubmit } from 'types';

interface IEditTaskProps {
  index: number;
  sequenceNumber?: number;
  item?: any;
  removeTask: (
    index: number,
    setDeleteLoading: Dispatch<SetStateAction<boolean>>,
    itemId: string
  ) => void;
  onSubmit: OnProjectUpdateSubmit;
  subtaskTypes: ISubtaskTypeDto[];
  handleOpenInfo?: (open: boolean) => void;
  notAvailableProject?: boolean;
  setIsOpenEdit?: () => void;
  withoutCross?: boolean;
  isDisabledDelete?: boolean;
  setRelated?: (tasks: IProjectTaskDto[]) => void;
  appendContainer?: (container: IProjectTaskDto) => void;
  isSingle?: boolean;
  projectId: string;
}

const EditTask = ({
  index,
  sequenceNumber = index + 1,
  item,
  removeTask,
  subtaskTypes,
  onSubmit,
  isDisabledDelete,
  handleOpenInfo,
  notAvailableProject,
  setIsOpenEdit,
  withoutCross,
  setRelated,
  isSingle,
  projectId,
  appendContainer,
}: IEditTaskProps) => {
  const isFilesLoading = useSelector(isFilesLoadingSelector);
  const deleteChatMessagesBySubtaskIds = useActionsRoutines(
    deleteChatMessagesBySubtaskIdsCreator
  );
  const subtaskFilesBuffersClear = useActions(clearAllSubtaskFilesBuffers);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [removeChatsIds, setRemoveChatsIds] = useState([]);

  const form = useForm({
    resolver: yupResolver(ProjectTaskSchema),
    shouldUnregister: true,
  });
  const { register, setValue } = form;

  const onRemoveClick = () => {
    setDeleteLoading(true);
    if (handleOpenInfo) handleOpenInfo(false);

    removeTask(index, setDeleteLoading, item._id);

    if (!!item?.subtasks?.length) {
      const subtaskIds = item.subtasks.reduce((acc, subtask) => {
        if (subtask?.id) {
          acc.push(subtask.id);
        }
        return acc;
      }, []);

      if (!!subtaskIds.length) {
        deleteChatMessagesBySubtaskIds(subtaskIds).catch(err =>
          console.error(err)
        );
      }
    }
  };

  useEffect(() => {
    if (item?.taskNotificationId) {
      register('taskNotificationId');
      setValue('taskNotificationId', item.taskNotificationId);
    }
  }, [item?.taskNotificationId, register, setValue]);

  useEffect(
    () => () => subtaskFilesBuffersClear(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOk = useCallback(() => setIsOpenEdit(), [setIsOpenEdit]);

  if (notAvailableProject) return null;

  const handleSubmitForm = async formData => {
    try {
      onSubmit({
        formData,
        index,
        setIsSubmitLoading,
        skipContainerUniqueness: true,
      });

      if (!!removeChatsIds.length) {
        await deleteChatMessagesBySubtaskIds(removeChatsIds);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...form}>
      <FormWrap>
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <FormHeader>
            <Headingleft>#{sequenceNumber}</Headingleft>
            <ButtonsWrap>
              {!isDisabledDelete && (
                <DeleteButton
                  title="Are you sure you want to delete this item?"
                  remove={onRemoveClick}
                  loading={isDeleteLoading}
                />
              )}
              <ButtonSaveStyled
                loading={isSubmitLoading}
                htmlType="submit"
                label="Save"
                disabled={isFilesLoading}
              />
              {!withoutCross && (
                <ConfirmationPopover
                  text="Are you sure you want to close edit?"
                  onOk={handleOk}
                  placement="bottomRight"
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Cross style={{ marginRight: 11 }} size={13} />
                  </div>
                </ConfirmationPopover>
              )}
            </ButtonsWrap>
          </FormHeader>
          <TaskForm
            setRemoveChatsIds={setRemoveChatsIds}
            isEdit
            isSingle={isSingle}
            isInProject
            item={item}
            index={index}
            errors={form.formState.errors}
            subtaskTypes={subtaskTypes}
            setRelated={setRelated}
            projectId={projectId}
            appendContainer={appendContainer}
          />
        </form>
      </FormWrap>
    </FormProvider>
  );
};

export default memo(EditTask, isEqual);
