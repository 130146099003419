import styled from 'styled-components';

export const BlockTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlue};
  padding-bottom: 11px;
  margin: 5px 0 14px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grayDark}`};
`;

export const BlockWrap = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.grayDark}`};
  width: 100%;
  height: auto;
  padding: 13px 11px 11px 14px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div``;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const BlockItemName = styled.div`
  text-transform: capitalize;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.mainDarkBlue};
`;
