import { useState } from 'react';
import PopoverContent from './PopoverContent';
import { PaperClip } from 'lib/icons';
import CollapseIcon from 'lib/icons/CollapseIcon';
import { Popover } from 'antd';
import { DoubleArrowWrap, Wrap } from './styles';
import { IAttachmentInfo } from 'types';

interface IFilesProps {
  files: Array<Partial<IAttachmentInfo>>;
}

const Files = ({ files }: IFilesProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  if (!files?.length) return null;

  const onPopoverVisibleChange = (visible: boolean) => {
    setIsPopoverVisible(visible);
  };

  return (
    <Popover
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      placement="bottom"
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={visible => onPopoverVisibleChange(visible)}
      content={<PopoverContent files={files} />}
      destroyTooltipOnHide
    >
      <Wrap>
        <PaperClip />
        <span>{files.length}</span>
        <DoubleArrowWrap isOpen={isPopoverVisible}>
          <CollapseIcon />
        </DoubleArrowWrap>
      </Wrap>
    </Popover>
  );
};

export default Files;
