import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { projectSelector, putProjectPromiseCreator } from 'store/dashboard';
import { prepareDuplicatePayload } from './utils/prepareDuplicatePayload';
import { DuplicateButton } from 'components/common';
import { useActionsRoutines } from 'hooks';
import { message } from 'utils/message';
import { ISubtaskItemData } from 'types';

interface DuplicateProjectSubtasksProps {
  countSubtasksInTask: number;
  currentSubtask: ISubtaskItemData;
  taskId: string;
  isProjectEditing?: boolean;
}

export const DuplicateProjectSubtasks: FC<DuplicateProjectSubtasksProps> = ({
  countSubtasksInTask,
  currentSubtask,
  taskId,
  isProjectEditing,
}) => {
  const editProject = useActionsRoutines(putProjectPromiseCreator);
  const projectData = useSelector(projectSelector);
  const [isLoading, setIsLoading] = useState(false);

  const handleDuplicateSubtask = async (amount: number) => {
    try {
      setIsLoading(true);
      const preparedPayloadData = prepareDuplicatePayload({
        currentSubtask,
        amount,
        projectData,
        taskId,
      });

      await editProject({ id: projectData._id, data: preparedPayloadData });

      return message.success('Duplication completed');
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DuplicateButton
      isLoading={isLoading}
      count={countSubtasksInTask}
      onConfirm={handleDuplicateSubtask}
      disabled={isProjectEditing}
      tooltipTitle={
        isProjectEditing && 'Cannot duplicate task while editing other task'
      }
    />
  );
};
