// constants
import { ChangeEvent, MouseEvent } from 'react';
import { BUTTON_TYPES } from 'components/common';
// icons
import { ExclamationMark } from 'lib/icons';
import theme from 'styles/theme';
import { ButtonsWrap, CancelButton, OkButton, Title, Wrap } from './styles';

interface IPopoverContentProps {
  text: string;
  onOk: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (e: ChangeEvent) => void;
  loading?: boolean;
}

export const PopoverContent = ({
  loading,
  text,
  onOk,
  onCancel,
}: IPopoverContentProps) => (
  <Wrap>
    <Title>
      <ExclamationMark fill={String(theme.colors.primary)} />
      {text}
    </Title>
    <ButtonsWrap>
      <CancelButton
        kind={BUTTON_TYPES.BORDERED}
        onClick={onCancel}
        label="Cancel"
      />
      <OkButton
        loading={loading}
        kind={BUTTON_TYPES.DANGER}
        onClick={onOk}
        label="Yes"
      />
    </ButtonsWrap>
  </Wrap>
);
