import styled, { css } from 'styled-components';
import { PROJECTS_STATUSES_STYLES } from 'lib/constants';
import { ProjectStatusesEnum } from 'types';

export const Status = styled.div<{
  status: ProjectStatusesEnum;
}>`
  width: 109px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ status }) => PROJECTS_STATUSES_STYLES[status].color};
  background: ${({ status }) => PROJECTS_STATUSES_STYLES[status].background};
  border-radius: 4px;
  padding: 0 5px;
  cursor: pointer;
  text-wrap: balance;
`;

export const DoubleArrowWrap = styled.div<{
  statusPreset?: any;
  isOpen?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
  svg {
    scale: 0.8;
    ${({ statusPreset }) => css`
      fill: ${statusPreset.color};
    `}
  }
`;
