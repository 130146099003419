import { ReactNode, Suspense } from 'react';
import Tabs from 'components/Tabs';
import { Loader } from 'components/common/Loader';
// components
import SideBar from 'pages/SideBar';
import { Row } from 'antd';
// styles
import LayoutStyled, { Wrap } from 'pages/styles';
import { ISideBarItem } from 'types';

export const profileWrapper = (children: ReactNode, items: ISideBarItem[]) => (
  <LayoutStyled>
    <SideBar siderItems={items} />
    <LayoutStyled>
      <Suspense fallback={null} children={children} />
    </LayoutStyled>
  </LayoutStyled>
);

export const tabsWrapper = (children: ReactNode, items) => (
  <Row>
    <Tabs items={items} />
    <Suspense
      fallback={
        <Wrap>
          <Loader loading />
        </Wrap>
      }
      children={children}
    />
  </Row>
);
