import styled, { css } from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)<{
  loading?: boolean;
  padding?: string;
  paddingHeader?: string;
}>`
  &.RelatedTasksModal {
    .ant-modal-content {
      padding-top: 12px;
    }

    .ant-modal-close-x {
      width: auto;
    }

    .ant-modal-close {
      top: 10px;
      right: 44px;
    }
  }

  --white: ${({ theme }) => theme.colors.white};
  --mainDarkBlue: ${({ theme }) => theme.colors.mainDarkBlue};
  ${props =>
    props.loading &&
    css`
      display: inline-flex !important;
      justify-content: center !important;
    `}
  .ant-modal {
    background: var(--white);
    border: 1px solid rgba(224, 224, 224, 0.15);
    box-shadow: 0 10px 23px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: inline-flex !important;
    justify-content: center !important;
  }
  .ant-modal-wrap {
    z-index: 1032;
  }
  .ant-modal-header {
    border: none;
    padding-top: 26px;
    ${props => props.paddingHeader && { padding: props.paddingHeader }};
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--mainDarkBlue);
  }
  .ant-modal-header {
    display: ${props => props.loading && 'none'};
  }
  .ant-modal-body {
    padding-top: 0;
    ${props => props.padding && { padding: props.padding }};
    ${props =>
      props.loading &&
      css`
        padding: 0;
        height: 100px;
        width: 100px;
      `}
  }
  .ant-modal-close-x {
    display: ${props => props.loading && 'none'};
  }
  .ant-modal-content {
    border-radius: ${props => props.loading && '25px;'};
    ${props =>
      props.loading &&
      css`
        height: 100px;
        width: 100px;
      `}
  }
`;
