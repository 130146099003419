import { CSSProperties, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RequiredSign } from '../RequiredSign';
import { Error, Label } from 'components/common';
import { PhoneWrapper } from './styles';
import { HookFormRecord } from 'types';

interface IPhoneNumberProps {
  placeholder?: string;
  label?: string | JSX.Element;
  name?: string;
  value?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  isRequired?: boolean;
  style?: CSSProperties;
  error?: string;
  id?: string;
  className?: string;
  maxLength?: number;
  onChange: (phone: string) => void;
  onBlur: (e: ChangeEvent) => void;
}

const PhoneNumber = ({
  placeholder,
  label,
  name,
  onChange,
  value,
  width,
  height,
  error,
  disabled,
  style,
  isRequired,
}: IPhoneNumberProps): JSX.Element => {
  const { getValues } = useFormContext();

  const phoneNumber = getValues(name as HookFormRecord);

  return (
    <PhoneWrapper
      $disabled={disabled}
      $isDirty={phoneNumber?.length > 1}
      $error={error}
      $height={height}
      $width={width}
      style={{ ...style }}
    >
      {label && (
        <Label htmlFor={name}>
          {label}
          {isRequired && <RequiredSign> *</RequiredSign>}
        </Label>
      )}
      <PhoneInput
        inputProps={{
          name,
          id: name,
        }}
        country={'us'}
        value={value}
        disabled={disabled}
        onChange={phone => onChange(`+${phone}`)}
        placeholder={placeholder}
      />
      {error && <Error>{error}</Error>}
    </PhoneWrapper>
  );
};

export default PhoneNumber;
