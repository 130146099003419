import * as React from 'react';

function KeyboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0H2C.9 0 .01.9.01 2L0 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM9 3h2v2H9V3zm0 3h2v2H9V6zM6 3h2v2H6V3zm0 3h2v2H6V6zM5 8H3V6h2v2zm0-3H3V3h2v2zm9 7H6v-2h8v2zm0-4h-2V6h2v2zm0-3h-2V3h2v2zm3 3h-2V6h2v2zm0-3h-2V3h2v2z"
        fill="#737F94"
      />
    </svg>
  );
}

export default KeyboardIcon;
